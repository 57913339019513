<div class="templatesContainer">
<!--  @if (dataService?.templates) {-->
      <div class="templates-content">
        <div class="templates-items">
          <div class="template-header">
            <div class="title-left">
              <img src="/assets/images/forms-icon.png" class="forms-logo" alt=""/>
              <h4><span>{{ "FORMS" | translate }}</span></h4>
            </div>
            @if(dataService?.project) {
              <div class="title-right">
                @if(!store?.isMCAdmin) {
                  <button [disabled]="!dataService.template.getValue() || dataService.templates.getValue().length === 0" [matTooltip]="'DATA' | translate" (click)="dataService.openFeatureData()"><img src="/assets/images/template-list.svg" alt="List" [class]="!dataService.template.getValue() || dataService.templates.getValue().length === 0 ? 'template-icons disabled' : store.formMode === DATA_MODE ? 'template-icons selected-mode' : 'template-icons'"/></button>
                  <button [disabled]="dataService.templates.getValue().length === 0" [matTooltip]="'MAP' | translate" (click)="dataService.openMapData()"><img src="/assets/images/template-map.svg" alt="Map" [class]="dataService.templates.getValue().length === 0 ? 'template-icons disabled' : store.formMode === MAP_MODE ? 'template-icons selected-mode' : 'template-icons'"/></button>
                  <button [disabled]="getUser().id !== dataService.project?.created_by || !dataService.template.getValue() || dataService.templates.getValue().length === 0" [matTooltip]="'DESIGN' | translate" (click)="dataService.openTemplateSchema($event)"><img src="/assets/images/template-design.svg" alt="Schema" [class]="getUser().id !== dataService.project.created_by || !dataService.template.getValue() || dataService.templates.getValue().length === 0 ? 'template-icons disabled' : store.formMode === SCHEMA_MODE ? 'template-icons selected-mode' : 'template-icons'"/></button>
                }
                <button [disabled]="getUser().id !== dataService.project?.created_by" class="add-button" [matTooltip]="'ADD FORM' | translate" [matMenuTriggerFor]="templMenu"><img src="/assets/images/add-icon.svg" alt="Add"/></button>
                <mat-menu class="text-black" #templMenu="matMenu">
                  <button (click)="openAddTemplateDialog()" mat-menu-item><span>{{ "NEW BLANK FORM" | translate }}</span></button>
                  <button (click)="openAddTemplateFromDomainDialog()" mat-menu-item><span>{{ "NEW FORM FROM DOMAIN" | translate }}</span></button>
                </mat-menu>
              </div>
            }
          </div>
            <div class="template-items">
                <ng-container *ngFor="let t of dataService?.templates.getValue(); trackBy: trackById">
                  <app-template-item
                    class="templateItem"
                    (click)="onSelectedTemplate(t, $event)"
                    [template]="t">
                  </app-template-item>
                </ng-container>
            </div>
        </div>
      </div>
<!--  }-->
</div>
