<div fxFlexLayout="row">
  <div fxFlex>
    <!--
    <h3 class="pb-3"><span>{{ "FIELDSET DATA" | translate }}</span></h3>
    -->
    <form novalidate [formGroup]="fieldsetDataForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      @if (showMessage) {
        <app-message-box style="margin-left: -10px;" #messageBox message="{{message}}"></app-message-box>
      }
      @for (f of fieldset.fields; track f) {
        <div class="full-width">
          <!--
          <span class="half-width">
            {{f.label}}
          </span>
          -->
          @if (isTextField(f)) {
            <div>
              <mat-form-field>
                <input matInput formControlName="{{f.name}}" placeholder="{{f.description}}" type="text" required>
                @if (f.required && fieldsetDataForm.get(f.name).hasError('required') && fieldsetDataForm.get(f.name).touched) {
                  <mat-error>
                    {{f.label}} is required
                  </mat-error>
                }
              </mat-form-field>
            </div>
          }
          @if (isFileField(f)) {
            <div>
              <input formControlName="{{f.name}}" placeholder="{{f.description}}" type="file">
              <!--
              <ngx-mat-file-input formControlName="{{f.name}}" placeholder="{{f.description}}" [multiple]="multiple">
                <mat-icon ngxMatFileInputIcon>folder</mat-icon>
              </ngx-mat-file-input>
              <label for="singleFile"><span>{{ "UPLOAD FILE" | translate }}</span></label>
              <input id="singleFile" type="file" [fileUploadInputFor]= "fileUploadQueue"/>
              <br>
                <mat-file-upload-queue #fileUploadQueue
                  [fileAlias]="'file'"
                  [httpUrl]="'http://localhost:8180/jax-rs-jersey-application-sample'">
                  <mat-file-upload [file]="file" [id]="i" *ngFor="let file of fileUploadQueue.files; let i = index"></mat-file-upload>
                </mat-file-upload-queue>
                -->
              </div>
            }
            @if (isNumberField(f)) {
              <div>
                <mat-form-field>
                  <input matInput formControlName="{{f.name}}" placeholder="{{f.description}}" type="number" required>
                  @if (f.required && fieldsetDataForm.get(f.name).hasError('required') && fieldsetDataForm.get(f.name).touched) {
                    <mat-error>
                      {{f.label}} is required
                    </mat-error>
                  }
                </mat-form-field>
              </div>
            }
            @if (isBooleanField(f)) {
              <div>
                <mat-form-field>
                  <input matInput formControlName="{{f.name}}" placeholder="{{f.description}}" type="checkbox">
                </mat-form-field>
              </div>
            }
            @if (isTimeField(f)) {
              <div>
                <mat-form-field>
                  <input matInput formControlName="{{f.name}}" placeholder="{{f.description}}" type="time">
                </mat-form-field>
              </div>
            }
            @if (isDateField(f)) {
              <div>
                <mat-form-field>
                  <!--
                  <input matInput formControlName="{{f.name}}" placeholder="{{f.description}}" type="text" required>
                  -->
                  <input matInput formControlName="{{f.name}}" placeholder="{{f.description}}" [matDatepicker]="picker">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  @if (f.required && fieldsetDataForm.get(f.name).hasError('required') && fieldsetDataForm.get(f.name).touched) {
                    <mat-error>
                      {{f.label}} is required
                    </mat-error>
                  }
                </mat-form-field>
              </div>
            }
          </div>
        }
        <div class="mt-3">
          <span class="flex-spacer"></span>
          <button mat-button type="button" class="btn-border mr-3" (click)="closeRecord()"><span>{{ "CANCEL" | translate }}</span></button>
          <button mat-button type="submit" class="background-primary text-floral-white" [disabled]="fieldsetDataForm.invalid">{{ mode.text }}</button>
        </div>
      </form>
    </div>
  </div>
