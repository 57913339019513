import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Template} from 'src/app/share/template';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import {AttributeSet} from 'src/app/share/feature/attributes';
import {getChoosenCRSType, getTemplateFieldsNameAndTypes, getToken, persistChoosenCRSType} from 'src/app/share/utils';
import {ReportTemplateService} from 'src/app/services/report-template.service';
import {MessageBoxComponent} from '../../message-box/message-box.component';
import {StoreService} from 'src/app/services/store.service';
import {CommonModule, DOCUMENT} from '@angular/common';
import {ReportsService} from '../../../services/reports.service';
import {MatStepperModule} from '@angular/material/stepper';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule} from '@angular/material/core';
import {MatTable, MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastService} from '../../../services/toast.service';
import {DataService} from '../../../services/data.service';
import {CookieService} from 'ngx-cookie-service';

export interface Criteria {
  criteria: string;
}

export interface PlaceHolder {
  name: string;
  value: string;
}

const ELEMENT_DATA: Criteria[] = [];
const RPH_ELEMENT_DATA: PlaceHolder[] = [];

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatStepperModule,
    MessageBoxComponent,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatTableModule,
    FormsModule,
    MatDatepickerModule,
    MatSelectModule,
    MatButtonModule,
    CommonModule,
  ],
  selector: 'app-export-data-criteria-dialog',
  templateUrl: './export-data-criteria-dialog.component.html',
  styleUrls: ['./export-data-criteria-dialog.component.scss', '../dialog-btns.component.scss']
})
export class ExportDataCriteriaDialogComponent implements OnInit {
  headers: string[] = [];

  optrs: any[] = [
    {value: '=', text: '='},
    {value: '≠', text: '≠'},
    {value: '>', text: '>'},
    {value: '>=', text: '>='},
    {value: '<', text: '<'},
    {value: '<=', text: '<='},
    {value: 'contains', text: 'Contains'},
    {value: 'notcontain', text: 'not contain '}
  ];

  outputs: any[] = [
    {format_id: 'HTML', description: 'HTML', hasTemplates: true},
    {format_id: 'PDF', description: 'PDF', hasTemplates: true},
    {format_id: 'DOCX', description: 'DOCX', hasTemplates: true},
    {format_id: 'Shape', description: 'Shape', hasTemplates: false},
    {format_id: 'CSV', description: 'CSV', hasTemplates: false},
    {format_id: 'XLSX', description: 'MS Excel', hasTemplates: false},
    {format_id: 'GeoJSON', description: 'GeoJSON', hasTemplates: false}
  ];

  outputsNoGeometry: any[] = [
    {format_id: 'HTML', description: 'HTML'},
    {format_id: 'PDF', description: 'PDF'},
    {format_id: 'DOCX', description: 'DOCX'},
    {format_id: 'CSV', description: 'CSV'},
    {format_id: 'XLSX', description: 'MS Excel'},
  ]

  crs: any[] = [
    {
      name: 'Geodetic',
      title: 'Geodetic',
      selected: false
    },
    {
      name: 'Lambert72',
      title: 'Lambert 72',
      selected: false
    },
    {
      name: 'Lambert2008',
      title: 'Lambert 2008',
      selected: false
    },
    {
      name: 'RDNAP',
      title: 'RD',
      selected: true
    },
    {
      name: 'UTM',
      title: 'UTM',
      selected: false
    },
    {
      name: 'BGS2005',
      title: 'BGS2005',
      selected: false
    }
  ];

  displayedColumns: string[] = ['select', 'criteria'];

  dataSource = new MatTableDataSource<Criteria>(ELEMENT_DATA);
  selection = new SelectionModel<Criteria>(true, []);
  @ViewChild(MatTable, {static: true}) criteriatable: MatTable<any>;

  rptdisplayedColumns: string[] = ['name', 'value'];
  rptdataSource = new MatTableDataSource<PlaceHolder>(RPH_ELEMENT_DATA);
  @ViewChild(MatTable, {static: true}) placeholderstable: MatTable<any>;

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  template: Template;
  criteriaForm: FormGroup;
  fromCreatedDateChecked = false;
  toCreatedDateChecked = false;
  fromUpdatedDateChecked = false;
  toUpdatedDateChecked = false;

  criteriaList: string[];
  data1: any[];
  searchCriteria: any;
  columnItems: any[] = [];
  selectedColumns: any[] = [];
  criteriaObjects: any[] = [];
  selectedReportTemplateName: string;

  datesValues: any;
  criteriasArray: any[] = [] ;
  outPut: any;
  coordinateRef: any;

  fromCreatedDateValue: any;
  toCreatedDateValue: any;

  fromUpdatedDateValue: any;
  toUpdatedDateValue: any;

  selectedSortColumn: any;
  sortingType: any;
  selectedSortColumn2: any;
  sortingType2: any;
  sortingObject: any;

  templateId: string;
  selectedReportTemplate: any;
  reportsTemplates: any[];
  criteriaDelete: any[];
  value: any;
  descendingValue: boolean = false;
  ascendingValue: boolean = true;
  descendingValue2: boolean = false;
  ascendingValue2: boolean = true;
  csvFile: boolean;
  templateloaded: boolean;
  selectOrDeselectAll: boolean = true;

  target_format: string;
  hasTemplate: boolean;
  currentVisibility: Visibility = Visibility.NONE;

  events: string[] = [];

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private reportTempalteService: ReportTemplateService,
    private reportsService: ReportsService,
    public translate: TranslateService,
    private cookieService: CookieService,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) data,
    @Inject(DOCUMENT) private document: Document,
    private dialogRef: MatDialogRef<ExportDataCriteriaDialogComponent>,
    public toastService: ToastService,
  ) {
    if (data !== null) {
      this.template = data.template;
      this.constructHeaderList(this.template);
    }
    this.fromCreatedDateChecked = false;
    this.toCreatedDateChecked = false;
    this.data1 = [];
    this.criteriaList = [];
    this.datesValues = {};
    this.templateloaded = true;
    let crs = this.cookieService.get('CRSValue');
    if(crs !== null && crs !== undefined) {
      this.coordinateRef = crs;
      persistChoosenCRSType(crs);
    } else {
      crs = getChoosenCRSType();
      if(crs !== null && crs !== undefined) {
        this.coordinateRef = crs;
      } else {
        persistChoosenCRSType('Geodetic');
        this.coordinateRef = 'Geodetic';
      }

    }
    this.createForm();
    if (this.criteriatable) {
      this.dataSource.data = [];
      this.criteriatable.renderRows();
      this.criteriaDelete = [];
    } else {
      this.dataSource.data = [];
    }
    this.criteriaObjects = [];
    this.criteriaList = [];
    this.criteriasArray = [];
  }

  ngOnInit() {
    this.reportsService.getOutputFormats(getToken()).subscribe(
      res => {
        // @ts-ignore
        this.outputs = res.formats;
        if(localStorage.getItem("selectedOutput"))
          this.outPutChanged(localStorage.getItem("selectedOutput"))
      },
      err => {
        this.toastService.errorToast(err);
      });

    document.addEventListener('keydown', event => this.eventListerner(event));
    document.addEventListener('keyup', event => this.eventListerner(event));
    this.outPut = '';
    const pvSearch = this.reportsService.lastExportedTemplate;
    const template = this.dataService.template.getValue();
    if (pvSearch && pvSearch.template === template.id) {
      this.resetAllObjects();
      this.searchCriteria = pvSearch.advancedRequest;
      this.datesValues = pvSearch.datesValues;
      this.criteriaObjects = pvSearch.criteriaObjects;
      this.criteriasArray = pvSearch.criteriasArray;
      this.outPut = pvSearch.outputType;
      this.sortingObject = pvSearch.sortingObject;

      if (this.criteriaObjects && this.criteriaObjects.length > 0) {
        const leng = this.criteriaObjects.length;
        const obj = this.criteriaObjects[leng - 1];

        this.criteriaForm.get('column_value').setValue(obj.value);
        this.criteriaForm.get('operator').setValue(obj.operator);
        this.criteriaForm.get('column_name').setValue(obj.column);

        for (let i = 0; i < leng; i++) {
          const criteria = this.criteriaObjects[i];
          const text = `${criteria.column} ${criteria.operator} ${criteria.value}`;
          const crit = {
            criteria: text
          };
          this.criteriaList.push(text);
          this.dataSource.data.push(crit);
        }

      }

      if (this.datesValues !== null) {
        const from = this.datesValues.fromDate;
        if (from !== null && from !== undefined) {
          this.fromDateChecked(true, "create");
          this.fromCreatedDateValue = from;
        } else {
          this.fromDateChecked(false, "create");
        }

        const to = this.datesValues.toDate;
        if (to !== null && to !== undefined) {
          this.toDateChecked(true, "create");
          this.toCreatedDateValue = to;
        } else {
          this.toDateChecked(false, "create");
        }
      }

      if (this.sortingObject !== null && this.sortingObject !== undefined) {
        const column1 = this.sortingObject.column;
        const column2 = this.sortingObject.column2;

        const sorting = this.sortingObject.sortingType;
        const sorting2 = this.sortingObject.sortingType2;

        if (column1 !== null && column1 !== undefined) {
          this.selectedSortColumn = column1;
          if (sorting === 'ascending') {
            this.ascendingChecked(true);
          } else if (sorting === 'descending') {
            this.descendingChecked(true);
          }
        } else {
          this.ascendingChecked(false);
          this.descendingChecked(false);
        }

        if (column2 !== null && column2 !== undefined) {
          this.selectedSortColumn2 = column2;
          if (sorting2 === 'ascending') {
            this.ascendingChecked2(true);
          } else if (sorting === 'descending') {
            this.descendingChecked2(true);
          }
        } else {
          this.descendingChecked2(false);
          this.ascendingChecked2(false);
        }
      }
      let crs = this.cookieService.get('CRSValue');
      if (crs !== null && crs !== undefined && crs !== '') {
        this.coordinateRef = crs;
      } else {
        crs = getChoosenCRSType();
        if(crs !== null && crs !== undefined) {
          this.coordinateRef = crs;
        } else {
          this.coordinateRef = 'Geodetic';
        }
      }

      const elementSelected = pvSearch.ColumsValues;
      this.selectedColumns = elementSelected;
      if (elementSelected !== null && elementSelected !== undefined && elementSelected.length > 0) {
        this.columnItems.map(element => {
          if (!this.elementInArray(element.value, elementSelected)) {
            element.check = false;
          }
        });
      } else {
        for (const column of this.columnItems) {
          this.selectedColumns.push(column.value);
        }
      }

      if (this.outPut === 'pdf' || this.outPut === 'html') {
        this.hasTemplate = true;
        const reportObj = pvSearch.reportObject;
        if (reportObj !== null && reportObj !== undefined) {
          if (reportObj.templateType === 'private') {
            // this.privateValue = true;
            this.currentVisibility = Visibility.PRIVATE
            this.handleCheckbox(Visibility.PRIVATE);
            this.getUserReportTemplates();
          } else if (reportObj.templateType === 'public') {
            // this.publicValue = true;
            this.currentVisibility = Visibility.PUBLIC
            this.handleCheckbox(Visibility.PUBLIC);
            this.getUserReportTemplates();
          }

          this.selectedReportTemplate = reportObj.selectedReport;
          this.selectedReportTemplateName = this.selectedReportTemplate.name;
          // this.updatePlaceHolderTable(this.selectedReportTemplate);
          this.rptdataSource.data = reportObj.holders;
          if (this.placeholderstable && this.rptdataSource) {
            this.placeholderstable.renderRows();
          }
        }
      }
      if (this.criteriatable !== null && this.criteriatable !== undefined) {
        this.criteriatable.renderRows();
      }
    } else {
      let crs = this.cookieService.get('CRSValue');
      if (crs !== null && crs !== undefined && crs !== '') {
        this.coordinateRef = crs;
      } else {
        crs = getChoosenCRSType();
        if(crs !== null && crs !== undefined) {
          this.coordinateRef = crs;
        } else {
          this.coordinateRef = 'Geodetic';
        }
      }
      if(pvSearch) {
        this.outPut = pvSearch.outputType;
      } else {
        this.outPut = '';
      }

      for (const column of this.columnItems) {
        this.selectedColumns.push(column.value);
      }
    }
  }

  createForm() {
    this.criteriaForm = this.fb.group({
      column_name: '',
      operator: '',
      column_value: '',
    });
  }

  fromDateChecked(val: boolean, type: string) {
    if (type === "create") {
      this.fromCreatedDateChecked = val;
    } else if(type === "update") {
      this.fromUpdatedDateChecked = val;
    }
  }

  toDateChecked(val: boolean, type: string) {
    if(type === "create") {
      this.toCreatedDateChecked = val;
    } else if(type === "update") {
      this.toUpdatedDateChecked = val;
    }
  }

  showToDateValue(event) {
    this.toCreatedDateValue = event.value;
    if (this.toCreatedDateValue !== null && this.toCreatedDateValue !== undefined) {
      this.toCreatedDateValue.setUTCHours(23, 59, 59);
      //alert(this.toDateValue);
      this.toCreatedDateValue = this.toCreatedDateValue.toISOString();
      // alert(this.toDateValue);
    }
  }

  showFromDateValue(event) {
    this.fromCreatedDateValue = event.value;
    if (this.fromCreatedDateValue !== null && this.fromCreatedDateValue !== undefined) {
      this.fromCreatedDateValue = this.fromCreatedDateValue.toISOString();
    }
  }

  showToUpdatedDateValue(event) {
    this.toUpdatedDateValue = event.value;
    if (this.toUpdatedDateValue !== null && this.toUpdatedDateValue !== undefined) {
      this.toUpdatedDateValue.setUTCHours(23, 59, 59);
      //alert(this.toDateValue);
      this.toUpdatedDateValue = this.toUpdatedDateValue.toISOString();
      // alert(this.toDateValue);
    }
  }

  showFromUpdatedDateValue(event) {
    this.fromUpdatedDateValue = event.value;
    if (this.fromUpdatedDateValue !== null && this.fromUpdatedDateValue !== undefined) {
      this.fromUpdatedDateValue = this.fromUpdatedDateValue.toISOString();
    }
  }

  closeDialog() {
    this.resetDialog();
    this.dialogRef.close();
  }

  resetAllObjects() {
    this.datesValues = {};
    this.searchCriteria = {};
    this.criteriaObjects = [];
    this.criteriasArray = [];
    this.outPut = '';
    this.dataSource.data = [];
  }

  eventListerner(event) {
    const key = event.key; // Or const {key} = event; in ES6+

    if (key === 'Escape') {
      this.closeDialog();
    }
  }

  resetDialog() {
    this.dataSource.data = [];
    this.criteriatable.renderRows();
    this.initializeDialog();
    this.selection.clear();
  }

  addCriteria() {
    const value = this.criteriaForm.value;
    const columnName = value.column_name.trim();
    const operator = value.operator;
    const columnValue = value.column_value.trim();
    const text = `${columnName} ${operator} ${columnValue}`;
    const index = this.criteriaList.indexOf(text);
    if (index >= 0) {
      this.toastService.warningToast('The given criteria already exist')
      return;
    }

    if(this.addSearchCriteriaItem(value)) {
      this.criteriaList.push(text);
      const crit = {
        criteria: text
      };
      const data = this.dataSource.data;
      data.push(crit);
      this.dataSource.data = data;
      if (this.criteriatable !== null && this.criteriatable !== undefined) {
        this.criteriatable.renderRows();
      }
    }
  }

  // for creating a search item and add it to the object.
  addSearchCriteriaItem(item: any) {
    const fieldName = item.column_name;
    const operator = item.operator;
    this.value = item.column_value.trim();

    this.searchCriteria = {};

    const type = this.getAttributeType(fieldName).toLowerCase();

    if (type !== null) {
      switch (type) {
        case 'integer' :
          this.value = parseInt(this.value);
          break;

        case 'double' :
          this.value = parseFloat(this.value);
          break;

        case 'decimal' :
          this.value = parseFloat(this.value);
          break;

        case 'boolean':
          this.value = JSON.parse(this.value);
          break;

        case 'date' :
          const d = new Date(this.value);
          const val = {
            $dateFromString: {
              dateString: d.toISOString()
            }
          };

          const oper = this.getCompareType(operator);
          const value = {};
          value[oper]  = ['$attributes.' + fieldName + '.value', val];
          this.searchCriteria['$expr'] = value;
          break;

        default :
      }
    }

    if (type !== 'date') {
      switch (operator) {
        case '=' :
          this.searchCriteria[fieldName] = this.value;
          break;

        case '≠' :
          this.searchCriteria[fieldName] = {
            $ne: this.value
          };
          break;

        case '<' :
          this.searchCriteria[fieldName] = {
            $lt: this.value
          };
          break;

        case '<=' :
          this.searchCriteria[fieldName] = {
            $lte: this.value
          };
          break;

        case '>' :
          this.searchCriteria[fieldName] = {
            $gt: this.value
          };
          break;

        case '>=' :
          this.searchCriteria[fieldName] = {
            $gte: this.value
          };
          break;

        case 'notcontain' :
          const contValue = this.findContainInCriterias();
          if (contValue === null) {
            this.toastService.errorToast('Operator \'contains\' must go First.')
            return false;
          }
          this.searchCriteria['$text'] = {
            $search: contValue + ' -' + this.value
          };
          break;

        case 'contains' :
          this.searchCriteria['$text'] = {
            $search: this.value
          };
          break;
      }
    }
    const criteria = {
      column : fieldName,
      operator,
      value: this.value
    };

    this.criteriaObjects.push(criteria);

    if (operator === 'notcontain') {
      for (let i = this.criteriasArray.length - 1; i >= 0; i--) {
        const obj = this.criteriasArray[i];
        if (typeof obj['$text'] === 'object') {
          this.criteriasArray.splice(i, 1);
        }
      }
    }
    // this.criteriaList.push(criteria);
    this.criteriasArray.push(this.searchCriteria);
    return true;
  }

  constructHeaderList(templ: Template) {
    if (templ) {
      const name = 'name';
      const _class = '_class';
      const schema = templ.feature;
      const geometry = schema.geometry_type;
      this.headers = [];
      if (geometry !== null && geometry !== undefined) {
        const val = {
          check: true,
          value: 'Coordinates'
        };
        this.columnItems.push(val);
      }
      const attributes = schema.attributes;
      for (const attribute of attributes) {
        const typeAttr = attribute[_class];
        if (typeAttr === 'attribute' || typeAttr === 'arrayattributeset') {
          const val1 = {
            check: true,
            value: attribute[name]
          };
          this.columnItems.push(val1);
          this.headers.push(attribute[name])
        } else if (typeAttr === 'attributeset') {
          this.constructAttributesetHeaderList(attribute as AttributeSet, attribute[name]);
        }
      }
    } else {
      this.columnItems = [];
      this.toastService.errorToast("Template empty")
    }
  }

  constructAttributesetHeaderList(atributeset: AttributeSet, parent= '') {
    const name = 'name';
    const _class = '_class';

    for (const attribute of atributeset.attributes) {
      const typeAttr = attribute[_class];
      if (typeAttr === 'attribute' || typeAttr === 'arrayattributeset') {
        const val = {
          check: true,
          value: `${parent}.${attribute[name]}`
        };
        this.columnItems.push(val);
        //this.headers.push(`${parent}.${attribute[name]}`);
      } else if (typeAttr === 'attributeset') {
        const nested_parent =  `${parent}.${attribute[name]}`;
        this.constructAttributesetHeaderList(attribute as AttributeSet, nested_parent);
      }
    }
  }

  handleSelectAll(event) {
    this.selectOrDeselectAll = event.checked;
    if(event.checked === true){
      for(let i = 0; i < this.columnItems.length; i++) {
        const val = this.columnItems[i];
        this.selectedColumns.push(val.value);
        this.columnItems[i].check = true;
      }
    } else {
      this.selectedColumns = [];
      for(let i = 0; i < this.columnItems.length; i++) {
        this.columnItems[i].check = false;
      }
    }
  }

  containsField(header) {
    return this.selectedColumns.find(x => x === header.value);
  }

  handleSelect(value, event){
    let index = this.selectedColumns.indexOf(value.value);
    let columnItem = this.columnItems.find(x => x.value === value.value);
    if(value.check && index !== -1) {
      this.selectedColumns.splice(index, 1)
      columnItem.check = false;
    } else if(!value.check && index === -1) {
      this.selectedColumns.push(value.value);
      columnItem.check = true;
    }
  }

  initializeDialog() {
    this.dataSource.data = [];

    if (this.criteriatable) {
      this.dataSource.data = [];
      this.criteriatable.renderRows();
      this.criteriaDelete = [];
    }
    this.criteriaObjects = [];
    this.criteriaList = [];
    this.criteriasArray = [];
    this.selection.clear();
  }

  // remove the criteria in the selecting list.
  removeCriteria() {
    if (this.criteriaDelete.length < 1) {
      this.toastService.warningToast('Select criteria to remove')
      return;
    }
    const deleteLen = this.criteriaDelete.length;

    for (let k = 0; k < this.criteriaDelete.length; k++){
      delete this.searchCriteria[this.criteriaDelete[k].column];
    }

    for (let i = deleteLen - 1; i >= 0; i--) {
      for (let j = 0; j < this.criteriaObjects.length; j++) {
        if (this.criteriaDelete[i].column === this.criteriaObjects[j].column) {
          this.criteriaObjects.splice(j, 1);
          this.criteriasArray.splice(j, 1);
        }
      }
      this.criteriaDelete.splice(i, 1);
    }

    if (this.selection.selected.length > 0) {
      let data = this.dataSource.data;
      for (const s of this.selection.selected) {
        this.criteriaList = this.criteriaList.filter(res => res !== s.criteria);
        data = data.filter(res => res !== s);
      }
      this.dataSource.data = data;
      this.selection.clear();
      if (this.criteriatable !== null && this.criteriatable !== undefined) {
        this.criteriatable.renderRows();
      }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.map(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Criteria): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    const index = this.dataSource.data.indexOf(row);
    const criteria = this.criteriaObjects[index];
    if (this.criteriaDelete === null || this.criteriaDelete === undefined) {
      this.criteriaDelete = [];
    }
    if (this.selection.isSelected(row)) {
      if (index > -1) {
        this.criteriaDelete.push(criteria);
      }
    } else {
      const itemIndex = this.criteriaDelete.indexOf(criteria);
      if (itemIndex > -1) {
        this.criteriaDelete = this.criteriaDelete.splice(itemIndex, 1);
      }
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.criteria + 1}`;
  }

  isEmpty(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  getReportTemplateByName(name){
    let reportTemplate = null;

    this.reportsTemplates.map(repTemplate => {
      if(repTemplate.name === name){
        reportTemplate = repTemplate;
      }
    });

    return reportTemplate;
  }

  chooseReportTemplateById() {
    const chooseId = '';
    this.reportsService.displayReportTemplatePlaceholders('');
    for (let i = 0; i < this.reportsTemplates.length; i++) {
      if (this.reportsTemplates[i].id === chooseId) {
        this.selectedReportTemplate = this.reportsTemplates[i];
        this.reportsService.displayReportTemplatePlaceholders(this.reportsTemplates[i]);
        break;
      }
    }
  }

  outPutChanged(value: string) {
    if(value) {
      this.target_format = value;
      let hasTemplate = this.outputs?.find(x => x.format_id === value).hasTemplate;

      if (this.outPut) {
        this.hasTemplate = !!this.outputs?.find(output => output.format_id === this.outPut).show_report_templates;
        this.csvFile = this.outPut === 'CSV';
      }

      if(this.currentVisibility !== Visibility.NONE && hasTemplate) {
        this.getUserReportTemplates();
      }
    }
  }

  crsChanged(value) {
    persistChoosenCRSType(value);
  }

  handleCheckbox(newVisibility: Visibility) {
    this.currentVisibility = newVisibility;
    this.getUserReportTemplates();
  }

  getUserReportTemplates() {
    const token = getToken() || {};
    this.templateloaded = false;
    this.reportTempalteService.getCompatibleReportTemplates(this.dataService.template.getValue().id, this.currentVisibility === Visibility.PUBLIC, this.target_format, token).subscribe(
      res => {
        this.reportsTemplates = res.filter(item => item.name && item.name.trim() !== "")
          .sort((a, b) => a.name.localeCompare(b.name));
        if (this.currentVisibility === Visibility.PRIVATE) {this.chooseReportTemplateById();}
        this.templateloaded = true;
      },
      err => {
        this.toastService.errorToast(err);
      });
  }

  updatePlaceHolderTable(reportTemplate: any) {
    const placeholders = reportTemplate.place_holders;
    const data = [];
    if (placeholders !== null && placeholders !== undefined) {
      if (placeholders.length > 0) {
        for (const placeholder of placeholders) {
          const keys = Object.keys(placeholder);
          const name = keys[0];
          const value = placeholder[name];
          const obj = {
            name,
            value
          };
          data.push(obj);
        }
        if (this.placeholderstable !== null  && this.placeholderstable !== undefined) {
          this.rptdataSource.data = [];
        }
      }
    }
    this.rptdataSource.data = data;
    this.placeholderstable.renderRows();
  }

  updatePlaceHolderValue(event, element, value) {
    event.preventDefault();
    const val = event.target.data;
    element['value'] = value;
    event.stopPropagation();
  }

  getCompareType(value) {
    if (value !== null) {
      switch (value) {
        case '=':
          return '$eq';
        case '≠' :
          return '$ne';
        case '<' :
          return '$lt';
        case '<=' :
          return '$lte';
        case '>' :
          return '$gt';
        case '>=' :
          return '$gte';
      }
    }

    return null;
  }

  findContainInCriterias() {
    const len = this.criteriaObjects.length;
    if (len > 0) {
      for (let i = 0; i < len; i++) {
        if (this.criteriaObjects[i].operator === 'contains') {
          return this.criteriaObjects[i].value;
        }
      }
    }
    return null;
  }

  // getting the attribute type by the attribute name
  getAttributeType(name) {
    const fieldnameAndTypes = getTemplateFieldsNameAndTypes();
    return fieldnameAndTypes[name];
  }

  ascendingChecked(selectedElt) {
    if (selectedElt) {
      this.descendingValue = false;
      this.ascendingValue = true;
    } else {
      this.ascendingValue = false;
      this.descendingValue = true;
    }
  }

  descendingChecked(selectedElt) {
    if (selectedElt) {
      this.descendingValue = true;
      this.ascendingValue = false;
    } else {
      this.descendingValue = false;
      this.ascendingValue = true;
    }
  }

  ascendingChecked2(selectedElt) {
    if (selectedElt) {
      this.descendingValue2 = false;
      this.ascendingValue2 = true;
    } else {
      this.ascendingValue2 = false;
      this.descendingValue2 = true;
    }
  }

  descendingChecked2(selectedElt) {
    if (selectedElt) {
      this.descendingValue2 = true;
      this.ascendingValue2 = false;
    } else {
      this.descendingValue2 = false;
      this.ascendingValue2 = true;
    }
  }

  outReportTemplateChanged(selectedRepTem) {
    if(selectedRepTem) {
      this.selectedReportTemplate= this.getReportTemplateByName(selectedRepTem);
      this.updatePlaceHolderTable(this.selectedReportTemplate);
    }

  }

  /**
   * building the object of the colums' name to show and the search criteria to apply to the data
   */
  submitResearchData() {
    const sortingData = {};
    let sortVal1 = 1;
    let sortVal2 = 1;
    let outputType;
    if (this.selectedColumns.length === 0) {
      this.toastService.warningToast('Select at least one column');
      return;
    }
    if(!this.outPut || this.outPut.length <= 0) {
      this.toastService.warningToast('Choose an output format');
      return;
    }

    if(this.hasTemplate) {
      if(!this.selectedReportTemplate) {
        this.toastService.errorToast('Please choose a document template for the report');
        return;
      }
    }


    if (this.fromCreatedDateValue) {
      this.datesValues['fromDate'] = this.fromCreatedDateValue;
    } else {
      delete this.datesValues.fromDate;
    }

    if (this.toCreatedDateValue) {
      this.datesValues['toDate'] = this.toCreatedDateValue;
    } else {
      delete this.datesValues.toDate;
    }

    if(this.fromUpdatedDateValue) {
      this.datesValues['fromUpdatedDate'] = this.fromUpdatedDateValue;
    } else {
      delete this.datesValues.fromUpdatedDateValue;
    }
    if(this.toUpdatedDateValue) {
      this.datesValues['toUpdatedDate'] = this.toUpdatedDateValue;
    } else {
      delete this.datesValues.toUpdatedDate;
    }

    let columnValue = this.selectedSortColumn;  // this.$.sortColumnId.value;
    let columnValue2 = this.selectedSortColumn2;  // this.$.sortColumnId2.value;

    if (columnValue === undefined || columnValue === '') {
      columnValue = '';
    } else if (this.ascendingValue === false && this.descendingValue === false) {
      this.ascendingValue = true;
    }

    if (columnValue2 === undefined || columnValue2 === '') {
      columnValue2 = '';
    } else if (this.ascendingValue2 === false && this.descendingValue2 === false) {
      this.ascendingValue2 = true;
    }

    this.sortingObject = {
      column: columnValue,
      column2: columnValue2
    };

    if (columnValue !== undefined && columnValue !== '') {
      if (this.ascendingValue) {
        this.sortingObject.sortingType = 'ascending';
        sortVal1 = 1;
      } else if (this.descendingValue) {
        this.sortingObject.sortingType = 'descending';
        sortVal1 = -1;
      }
    }

    if (columnValue2 !== undefined && columnValue2 !== '') {
      if (this.ascendingValue2) {
        this.sortingObject.sortingType2 = 'ascending';
        sortVal2 = 1;
      } else if (this.descendingValue2) {
        this.sortingObject.sortingType2 = 'descending';
        sortVal2 = -1;
      }
    }
    if (columnValue !== undefined && columnValue !== '') {
      const newKey = 'attributes.' + columnValue + '.value';
      sortingData[newKey] = sortVal1;
    }

    if (columnValue2 !== undefined && columnValue2 !== '') {
      const newK = 'attributes.' + columnValue2 + '.value';
      sortingData[newK] = sortVal2;
    }

    outputType = this.outPut;
    const arrayCrit = this.criteriasArray.slice();
    const hiddenColumns = this.getColumnsToHide(this.selectedColumns);
    const aggregateBody = this.buildAggregation(arrayCrit.slice());


    if (aggregateBody.length === 0) {
      let tempObj = {};
      tempObj = {
        $match: {
          template_id: this.template.id
        }
      };

      aggregateBody.push(tempObj);
    }

    if (!this.isEmpty(sortingData)) {
      aggregateBody.push({
        $sort: sortingData
      });
    }

    const templates = [];
    const holders = [];

    if (this.selectedReportTemplate !== undefined && this.selectedReportTemplate !== null) {
      const data = this.rptdataSource.data;
      if (data !== null && data !== undefined) {
        for (const el of data) {
          const  key = el.name;
          let val = el.value;
          const obj = {};
          if (val === null || val === undefined || val === '') {
            val = '';
          }
          obj[key] = val;
          holders.push(obj);
        }
      }
      const template = {
        template_id: this.selectedReportTemplate.id,
        place_holders: holders
      };
      templates.push(template);
    }

    const templateId = this.dataService.template.getValue().id;

    let reportTempType = '';
    if (this.currentVisibility === Visibility.PRIVATE) {
      reportTempType = 'private';
    } else {
      reportTempType = 'public';
    }

    const reportObject = {
      selectedReport: this.selectedReportTemplate,
      templateType: reportTempType,
      holders: this.rptdataSource.data
    };

    const objectSend = {
      selectedColumns: hiddenColumns,
      advancedRequest: this.searchCriteria,
      datesValues: this.datesValues,
      template: templateId,
      criteriaObjects: this.criteriaObjects,
      sortingObject: this.sortingObject,
      ColumsValues: this.selectedColumns,
      criteriasArray: this.criteriasArray,
      aggregateBody,
      outputType,
      reportObject: reportObject,
      reportTemplate: templates,
      selectedFormat: this.outputs.find(format => format.format_id === this.outPut)
    };

    this.reportsService.lastExportedTemplate = objectSend;
    // persistSearchObject(objectSend);
    const result = {
      event: 'search',
      advanced_request: objectSend
    };

    this.currentVisibility = Visibility.NONE;
    localStorage.setItem("selectedOutput", this.outPut);

    this.resetDialog();
    this.dialogRef.close(result);
  }

  buildAggregation(dataArray: string | any[]) {
    const aggregate = [];
    let andValue = [];
    let orValue = [];
    const matchValue = {};
    let tempObj = {};
    if (this.store.features && this.store.features.length > 0){
      for(const feat of this.store.features) {
        const oId = { _id: {"$oid": feat['id']} }
        orValue.push(oId);
      }
      andValue = null;
    } else {
      tempObj = {
        template_id: this.template.id
      };
      andValue.push(tempObj);
      orValue = null;
    }
    if (dataArray.length > 0) {
      for (let i = 0; i < dataArray.length; i++) {
        const obj = dataArray[i];
        const key = Object.keys(obj)[0];

        const keyValues = key.split('.');

        if (key !== '$text' && key !== '$expr' && keyValues[0] !== 'attributes') {
          const newKey = 'attributes.' + key + '.value';

          obj[newKey] = obj[key];
          delete obj[key];
        }
        andValue.push(obj);
      }
    }

    if (this.fromCreatedDateValue) {
      if (this.datesValues.fromDate !== null) {
        const d = new Date(this.datesValues.fromCreatedDate);
        const val = {
          $dateFromString: {
            dateString: d.toISOString(),
          }
        };
        const value = {
          $expr: {
            $gte : ['$created_at', val]
          }
        };
        andValue.push(value);
      }
    }

    if (this.toCreatedDateValue) {
      if (this.datesValues.toDate !== null) {
        const d = new Date(this.datesValues.toDate);
        d.setDate(d.getDate() + 1);

        const val = {
          $dateFromString: {
            dateString: d.toISOString(),
          }
        };

        const value = {
          $expr: {
            $lte : ['$created_at', val]
          }
        };
        andValue.push(value);
      }
    }

    if(this.fromUpdatedDateValue) {
      if (this.datesValues.fromUpdatedDate !== null) {
        const d = new Date(this.datesValues.fromUpdatedDate);
        const val = {
          $dateFromString: {
            dateString: d.toISOString(),
          }
        };
        const value = {
          $expr: {
            $gte : ['$updated_at', val]
          }
        };
        andValue.push(value);
      }
    }

    if(this.toUpdatedDateValue) {
      if (this.datesValues.toUpdatedDate !== null) {
        const d = new Date(this.datesValues.toUpdatedDate);
        d.setDate(d.getDate() + 1);

        const val = {
          $dateFromString: {
            dateString: d.toISOString(),
          }
        };

        const value = {
          $expr: {
            $lte : ['$updated_at', val]
          }
        };
        andValue.push(value);
      }
    }

    const $match = {};
    if (andValue !== undefined && andValue !== null) {
      $match['$and'] = andValue
    }

    if(orValue !== undefined && orValue !== null) {
      $match['$or'] = orValue
    }

    aggregate.push({
      '$match': $match
    });

    return aggregate;
  }

  // getting the index of the attributes names to hide in the table.
  getColumnsToHide(arrayToShow) {
    const hideColumns = [];
    if (arrayToShow.length === 0) {
      for (let i = 0; i < this.columnItems.length; i++) {
        hideColumns.push(this.columnItems.indexOf(this.columnItems[i]));
      }
      return hideColumns;
    }

    for (let i = 0; i < this.columnItems.length; i++) {
      if (!this.elementInArray(this.columnItems[i].value, arrayToShow)) {
        hideColumns.push(this.columnItems.indexOf(this.columnItems[i]));
      }
    }

    return hideColumns;
  }

  // check if the array contains
  elementInArray(element, array) {
    if (array.length === 0 || element === null) {
      return false;
    }

    for (let i = 0; i < array.length; i++) {
      if (array[i] === element) {
        return true;
      }
    }
    return false;
  }

  protected readonly Visibility = Visibility;
}

export enum Visibility {
  NONE,
  PUBLIC,
  PRIVATE
}
