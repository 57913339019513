import * as uuid from 'uuid';

export class Item {
  name: string;
  uId: string;
  children: Item[];
  isContainer: boolean;

  //Field properties
  _class: string;
  label: string;
  description: string;
  enabled: boolean;
  visible: boolean;
  required: boolean;
  use_previous_value: boolean;
  constraints: object[];
  event_actions: object;
  default_value_expression: string;
  default_value: string;
  show_value_on_map: boolean;
  on_same_screen_as_parent: boolean;
  step: number;
  initial_value: number;

  constructor(options: {
    name: string,
    children?: Item[],
    isContainer?: boolean,
    _class: string,
    label: string,
    description: string,
    enabled: boolean,
    visible: boolean,
    required: boolean,
    use_previous_value: boolean,
    constraints: object[],
    event_actions: object,
    default_value_expression: string,
    default_value: string,
    show_value_on_map:boolean,
    on_same_screen_as_parent: boolean,
    step?: number,
    initial_value?:number
  }) {
    this.name = options.name;
    this.uId = uuid.v4();
    this.children = options.children || [];
    this.isContainer = options.isContainer ? options.isContainer : false;
    this._class = options._class;
    this.label = options.label;
    this.description = options.description;
    this.enabled = options.enabled;
    this.visible = options.visible;
    this.required = options.required;
    this.use_previous_value = options.use_previous_value;
    this.constraints = options.constraints;
    this.event_actions = options.event_actions;
    this.default_value_expression = options.default_value_expression;
    this.default_value = options.default_value;
    this.show_value_on_map = options.show_value_on_map;
    this.on_same_screen_as_parent = options.on_same_screen_as_parent;
    this.step = options.step;
    this.initial_value = options.initial_value;
  }
}
