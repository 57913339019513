<div fxFlexLayout="row">
  <div fxFlex fxFlexOffset="0px">
    <h3><span>{{ "SETTINGS" | translate }}</span></h3>
    <mat-divider class="mb-4"></mat-divider>
    <form novalidate>
      <mat-dialog-content>
        <div class="crsSetting">
        <h3>CRS</h3>
          <mat-select [value]="cookieService.get('CRSValue') || 'Geodetic'" (selectionChange)="switchCrs($event.value)"  [placeholder]="'CRS' | translate">
            @for (c of crsList; track c) {
              <mat-option [value]="c.value">{{ c.text }}</mat-option>
            }
          </mat-select>
        </div>
        <div class="languageSetting">
          <h3>{{ "LANGUAGE" | translate }}</h3>
          <mat-select [value]="cookieService.get('appLang') || 'en'" (selectionChange)="translation.switchLanguage($event.value)">
            <mat-option [value]="'en'">{{ "ENGLISH" | translate }}</mat-option>
            <mat-option [value]="'es'">{{ "SPANISH" | translate }}</mat-option>
            <mat-option [value]="'de'">{{ "GERMAN" | translate }}</mat-option>
            <mat-option [value]="'nl'">{{ "DUTCH" | translate }}</mat-option>
            <mat-option [value]="'fr'">{{ "FRENCH" | translate }}</mat-option>
          </mat-select>
        </div>

      </mat-dialog-content>
      <mat-dialog-actions class="button-group">
        <button class="submit-btn" mat-dialog-close mat-button>{{"OK" | translate}}</button>
      </mat-dialog-actions>
    </form>
  </div>
</div>
