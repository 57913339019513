import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import { UserService } from '../../services/user.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import {DomainService} from '../../services/domain.service';
import {Project} from '../../share/projects';
import {Country, ExternalService} from '../../services/external.service';
import {CommonModule} from '@angular/common';
import {MatLabel} from '@angular/material/form-field';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {ToastService} from '../../services/toast.service';

@Component({
  selector: 'app-signup',
  imports: [TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    MatLabel,
    MatProgressSpinner,
  ],
  templateUrl: './signup.component.html',
  standalone: true,
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  form: FormGroup;
  errMsg: string;
  showMessage: boolean;
  message: string;
  private messageBox: MessageBoxComponent;
  domains: Project[];
  countries: object[];
  submitMessage: boolean = false;
  isMessageError: boolean = false;
  submitMessageContent: string;
  preSelectedDomain: string;
  isLoading: boolean = false;

  @ViewChild('messageBox', { static: false }) set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }

  constructor(
    private userService: UserService,
    private domainService: DomainService,
    private externalService: ExternalService,
    public store: StoreService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      company: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      industry: new FormControl('', [Validators.required])
    }, {
      validators: this.passwordMatchValidator
    });
  }

  passwordMatchValidator(control: AbstractControl) {
    const passwordControl = control.get('password');
    const confirmPasswordControl = control.get('confirmPassword');

    if (!passwordControl || !confirmPasswordControl) {
      return null; // If either control is not found, consider the validation to pass
    }

    const password = passwordControl.value;
    const confirmPassword = confirmPasswordControl.value;

    return password === confirmPassword ? null : { mismatch: true };
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => this.preSelectedDomain = params['caller']);

    this.domainService.getDomains().subscribe(
      res => {
        this.domains = res;
      }
    );

    this.externalService.getCountriesInRegion('europe').subscribe(
      (res: Country[]) => {
        this.countries = res;

        this.countries = this.countries.sort((a: Country,b: Country)=> {
          const a1 = a.name.common.toLowerCase();
          const b1 = b.name.common.toLowerCase();
          return a1<b1 ?-1:a1> b1? 1 :0;
        })
      }
    );
  }

  onSubmit(checkbox, repeat) {
    if(checkbox.checked || checkbox.value != 1 || repeat.value != "")
    {
      this.isMessageError = true;
      this.submitMessage = true;
      this.submitMessageContent = "Something went wrong and user could not be created. Please try again.";
    }
    else {
      const user = {
        username: this.form.value.username,
        email: this.form.value.email,
        company: this.form.value.company,
        password: this.form.value.password,
        country: this.form.value.country,
        industry: this.form.value.industry,
        activation_url: window.location.host
      }
      if(user.password.length < 6)
      {
        this.isMessageError = true;
        this.submitMessage = true;
        this.toastService.errorToast('The password is too short, please make sure it is at least 6 characters long.')
      } else if(user.username.length < 3)
      {
        this.isMessageError = true;
        this.submitMessage = true;
        this.toastService.errorToast('The username is too short, please make sure it is at least 3 characters long.')
      }
      else
      {
        this.isLoading = true;
        this.userService.signupTokenlessUser(user).subscribe(
          _res => {
            this.isLoading = false;
            this.isMessageError = false;
            this.submitMessage = true;
            this.submitMessageContent = "User created, please check your email to verify your account."
          },
          err => {
            this.isLoading = false;
            this.isMessageError = true;
            this.submitMessage = true;

            if(err.status ===  429)
            {
              this.submitMessageContent = "Too many requests, please try again later."
            } else {
              this.submitMessageContent = err.error.error.message;
            }
          }
        );
      }

    }
  }

  openLogin(event) {
      event.preventDefault();
      this.router.navigate(['/login']);
      event.stopPropagation();
  }
}
