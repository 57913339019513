import { TranslateModule } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { User } from '../../share/user';
import { FormsModule } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import {getToken, isCollaborator, isTokenValid, logoutUser, persistApiURL, persistToken, persistUser} from 'src/app/share/utils';
import { ConfigServerUrlComponent } from '../dialogs/config-server-url/config-server-url.component';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { environment } from 'src/environments/environment';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import {ToastService} from '../../services/toast.service';
import {CookieService} from 'ngx-cookie-service';

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatToolbarModule,
    MessageBoxComponent,
    FormsModule,
  ],
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('fform') feedFormDirectives: any;

  errMsg: string;


  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  user: User = {
    name: '',
    password: '',
    remember: false,
    email: '',
    managed_users_ids: [],
    manager_id: '',
    customer_id: '',
    user_type: '',
    is_activated: false,
    mc_marxact: [],
    temporary_user: false,
    id: '',
    created_at: '',
    updated_at: '',
    deleted_at: '',
    created_by: '',
    updated_by: '',
    deleted_by: '',
  };

  constructor(
    private store: StoreService,
    private router: Router,
    private userService: UserService,
    private cookieService: CookieService,
    private dialog: MatDialog,
    public toastService: ToastService,
  ) { }

  ngOnInit() {
    const token = getToken();
    if (token && isTokenValid(token)) {
      this.router.navigate(['/projects']);
    } else {
      logoutUser();
      this.store.token = null;
    }
    persistApiURL(this.cookieService.get("lastPrivateServer"), this.cookieService.get("privateServerEnabled") === "true");
  }

  onSubmit() {
    this.store.showLoading();
    //Extend token durability
    this.user.remember = true;
    this.userService.loginUser(this.user).subscribe(
      res => {
        persistToken(res);
        this.store.saveToken(res);
        this.store.isMCAdmin = false;
        this.getUser(res);
      },
      errmess => {
        this.store.hideLoading();
        this.showMessage = true;
        if (errmess === 'invalid credentials.') {
          this.toastService.errorToast('Invalid username or password');
        } else {
          this.toastService.errorToast(errmess);
        }
      }
    );
  }

  getWebAppVersion(){
    return environment.webAppVersion;
  }

  getUser(token) {
    this.userService.getUser(token, token.user_id).subscribe(
      res => {
        persistUser(res);
        this.store.isMCAdmin = false;
        this.user = res;
        if(isCollaborator(res) && this.isCollaboratorNoCreatePermission(this.user)){
          this.store.isCollaborator = true;
        } else {
          this.store.isCollaborator = false;
        }
        // Get admin user name if the connected user is a collaborator

        this.store.persistUser(res);
        this.store.proClass = 'project';
        this.store.changeProClassVisArchi('project', 'private', false);

        this.router.navigate(['/projects']);
        this.store.hideLoading();
      },
      err => {
        this.toastService.errorToast(err);
        this.store.hideLoading();
        return;
      }
    );
  }

  isCollaboratorNoCreatePermission(user) {
    const permission = user.permissions;
    if (permission !== null && permission !== undefined) {
      const projPerm = permission.projects;
      if (projPerm[0] === "create") {
        return false;
      }
    }
    return true;
  }

  /**
   * This method redirect the user to the resseting page
   */
  openPasswordResetingPage(event) {
    event.preventDefault();
    this.router.navigate(['/reset-password']);
    event.stopPropagation();
  }

  /**
   * This method redirect the user to the private server.
   */
  openPrivateServer(event) {
    event.preventDefault();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
    };
    const dialogRef = this.dialog.open(ConfigServerUrlComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
      }
    });
    event.stopPropagation();
  }

  /**
   * This method redirect the user to the signup page
   */
  openSignUp(event) {
    event.preventDefault();
    this.router.navigate(['/signup']);
    event.stopPropagation();
  }
}
