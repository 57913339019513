import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { url } from 'src/app/share/utils';
import { FileServiceService } from 'src/app/services/file-service.service';
import {ToastService} from '../../services/toast.service';

@Component({
  standalone: true,
  selector: 'app-image-drop',
  templateUrl: './image-drop.component.html',
  imports: [
    TranslateModule
  ],
  styleUrls: ['./image-drop.component.scss']
})
export class ImageDropComponent implements OnInit {

  @ViewChild('icon') img;
  @Input() image: string;
  @Input() helpMessage: string;
  @Input() width: number;
  @Input() height: number;

  @Output() invalidIconDropped = new EventEmitter();

  remove: boolean;

  constructor(
    private fileService: FileServiceService,
    public toastService: ToastService,
  ) {
   }

  ngOnInit() {
    this.width = 100;
    this.height = 100;
    this.helpMessage = 'Set item icon by dragging and dropping and image in the space';
    // this.helpMessage = 'Set icon: drag image here.';
    this.image = this.setImage();
    this.remove = this.isImageExist('');
  }

  isImageExist(image) {
    if(image !== '' && image !== null && image !== undefined){
      return false;
    } else {
      return true;
    }
  }

  setImage() {
    if (this.image !== '' && this.image !== null && this.image !== undefined) {
      return url(this.image);
    } else {
      return '';
    }
  }

  dragStart(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  dragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  dragOver(event: DragEvent) {
    event.preventDefault();
    //this.img.style.borderColor = '#424242';
    event.stopPropagation();
  }

  dragLeave(event: DragEvent) {
    //this.img.style.borderColor = '#9e9e9e';
  }

  drop(event: DragEvent) {
    event.preventDefault();

    const files = event.dataTransfer.files;
    if (files.length < 0) {
      return;
    }

    const file = files[0];
    if (!file.type.match(/image.*/)) {
      this.invalidIconDropped.emit(true);
      this.toastService.warningToast('Invalid file');
      return;
    }

    const imag = this.img;
    imag.file = file;

    const reader = new FileReader();
    reader.onload = ((i) => {
      return (e) => {
        i.src = e.target.result;
        this.image = i.src;
      };
    })(imag);

    this.remove = true;
    reader.readAsDataURL(file);
    event.stopPropagation();
  }

  removeImage() {
    this.image = '';
    this.remove = false;
    this.helpMessage = '';
  }
}
