<div id="dropbox">
  <img id="icon" #icon class="rounded-circle text-center" draggable="true" src="{{image}}" width="{{width}}" height="{{height}}" (dragstart)="dragStart($event)" (dragenter)="dragEnter($event)" (dragover)="dragOver($event)" (drop)="drop($event)" (dragleave)="dragLeave($event)"
    />
  <div id="dropboxMessage">
    @if (isImageExist(image)) {
      <span> {{ helpMessage }} </span>
    }
    @if (!isImageExist(image)) {
      <span><a (click)="removeImage()"><span>{{ "REMOVE THE IMAGE" | translate }}</span></a></span>
    }
  </div>
</div>
