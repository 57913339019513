import { TranslateModule } from '@ngx-translate/core';
import {Component, OnInit, ViewChild, Input, Inject, HostListener} from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { AudioField } from 'src/app/share/form/audio-field';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import { FieldSet } from 'src/app/share/form/fieldset';
import { StoreService } from 'src/app/services/store.service';
import { isFieldAlreadyExist, removeSpecialCharacters } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import {DialogActionsComponent} from '../dialog-actions/dialog-actions.component';

@Component({
  standalone: true,
  imports: [TranslateModule,
    ReactiveFormsModule,
    MessageBoxComponent,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    MatDialogActions,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    DialogActionsComponent,
  ],
  selector: 'app-create-audio-field',
  templateUrl: './create-audio-field.component.html',
  styleUrls: ['./create-audio-field.component.scss', '../dialog-btns.component.scss']
})
export class CreateAudioFieldComponent {
  audioFieldForm: FormGroup;
  audioField: AudioField;
  parent: FieldSet;
  @Input() mode = {
    text: 'Create',
    function: 'Create'
  };

  @ViewChild('fform') audioFieldFormDirectives;
  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  nameLengthExceed10 = false;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CreateAudioFieldComponent>,
    public toastService: ToastService,
  ) {
    if (data !== undefined && data !== null) {
      this.parent = data.parent;
      this.mode = data.mode;
      this.audioField = data.field;
    }
    this.createForm();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  createForm() {
    if (this.mode.function === 'Create') {
      this.audioFieldForm = this.fb.group({
        name: ['', Validators.required],
        label: ['', Validators.required],
        description: '',
        enabled: true,
        visible: true,
        required: false
      });
    } else {
      this.audioFieldForm = this.fb.group({
        name: [this.audioField.name, Validators.required],
        label: [this.audioField.label, Validators.required],
        description: this.audioField.description,
        enabled: this.audioField.enabled,
        visible: this.audioField.visible,
        required: this.audioField.required
      });
    }
  }

  onNameChange() {
    if (this.audioFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  onLabelChange() {
    this.audioFieldForm.controls.name.setValue(removeSpecialCharacters(this.audioFieldForm.value.label));
    if (this.audioFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  onSubmit() {
    if (this.audioField) {
      this.audioField = {
        ...this.audioField,
        ...this.audioFieldForm.value
      };
    } else {
      this.audioField = this.audioFieldForm.value;
    }
    // this.audioField = this.audioFieldForm.value;
    this.audioField._class = 'audiofield';

    if (this.audioField.name === this.parent.name) {
      this.toastService.errorToast('Field cannot take the same name as the active fieldset')
      return;
    }
    const result = {
      action: 'Create',
      field: this.audioField
    };
    if (this.mode.function === 'Create') {
        if (isFieldAlreadyExist(this.audioField, this.parent)) {
          this.toastService.errorToast('Another field with the same name/label already exists')
          return;
        }
        this.store.addField(this.audioField);
    } else if (this.mode.function === 'Update') {
      result.action = 'Update';
    }
    this.dialogRef.close(result);
    // Add the update to the parent fields
  }
}
