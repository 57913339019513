import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Template } from 'src/app/share/template';
import { FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { AttributeSet } from 'src/app/share/feature/attributes';
import { getToken, persistEnexisSetting, getEnexisSetting } from 'src/app/share/utils';
import { ReportTemplateService } from 'src/app/services/report-template.service';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { DataService } from 'src/app/services/data.service';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import {ReportsService} from '../../../services/reports.service';

export interface Criteria {
  criteria: string;
}

export interface PlaceHolder {
  name: string;
  value: string;
}

const ELEMENT_DATA: Criteria[] = [];
const RPH_ELEMENT_DATA: PlaceHolder[] = [];

@Component({
  standalone: true,
  imports: [TranslateModule,
    MessageBoxComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatTableModule,
    MatButtonModule,
  ],
  selector: 'app-enexis-export-crit-dialog',
  templateUrl: './enexis-export-crit-dialog.component.html',
  styleUrls: ['./enexis-export-crit-dialog.component.scss', '../dialog-btns.component.scss']
})
export class EnexisExportCritDialogComponent implements OnInit {
  headers: string[] = [];
  crs: any[] = [
    {
      name: 'Geodetic',
      title: 'Geodetic',
      selected: false
    },
    {
      name: 'Lambert72',
      title: 'Lambert 72',
      selected: false
    },
    {
      name: 'Lambert2008',
      title: 'Lambert 2008',
      selected: false
    },
    {
      name: 'RDNAP',
      title: 'RD',
      selected: true
    },
    {
      name: 'UTM',
      title: 'UTM',
      selected: false
    },
    {
      name: 'BGS2005',
      title: 'BGS2005',
      selected: false
    }
  ];

  displayedColumns: string[] = ['select', 'criteria'];

  dataSource = new MatTableDataSource<Criteria>(ELEMENT_DATA);
  selection = new SelectionModel<Criteria>(true, []);
  @ViewChild(MatTable, {static: true}) criteriatable: MatTable<any>;

  rptdisplayedColumns: string[] = ['name', 'value'];
  rptdataSource = new MatTableDataSource<PlaceHolder>(RPH_ELEMENT_DATA);
  @ViewChild(MatTable, {static: true}) placeholderstable: MatTable<any>;

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  template: Template;
  criteriaForm: FormGroup;
  criteriaList: string[];
  columnItems: any[] = [];
  criteriaObjects: any[] = [];

  datesValues: any;
  criteriasArray: any[] = [] ;

  sortingType: any;
  sortingType2: any;
  sortingObject: any;

  templateId: string;
  selectedReportTemplate: any;
  reportsTemplates: any[];
  criteriaDelete: any[];
  value: any;
  pdfOrHtml: boolean;
  csvFile: boolean;
  templateloaded: boolean;
  templates = []

  publicEnexisReport: boolean = false;
  caseNumber: string;

  events: string[] = [];
  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private reportTempalteService: ReportTemplateService,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<EnexisExportCritDialogComponent>,
    private reportsService: ReportsService,
    public toastService: ToastService,
  ) {
    if (data !== null) {
      this.publicEnexisReport = false;
      this.template = data.template;
    } else {
      const project = this.dataService.project;
      const token = getToken();
      this.publicEnexisReport = true;

      //FLAW
      if(project) {
        this.dataService.requestTemplates(token, project.id).subscribe(
          (res) => {
            this.templates = res;
          },
          (error) => {
            this.templates = [];
          }
        )
      }

    }
    this.createForm();
  }

  createForm() {
    this.criteriaForm = this.fb.group({
      column_name: '',
      operator: '',
      column_value: '',
    });
  }

  closeDialog() {
    this.resetDialog();
    this.dialogRef.close();
  }

  eventListerner(event) {
    const key = event.key; // Or const {key} = event; in ES6+

    if (key === 'Escape') {
      this.closeDialog();
    }
  }

  ngOnInit() {
    document.addEventListener('keydown', event => this.eventListerner(event));

    this.pdfOrHtml = true;
    this.getUserReportTemplateById('656a027328a993500e20a989');
  }

  resetDialog() {
    this.dataSource.data = [];
    this.criteriatable.renderRows();
    this.initializeDialog();
    this.selection.clear();
  }

  constructAttributesetHeaderList(atributeset: AttributeSet, parent= '') {
    const name = 'name';
    const _class = '_class';

    for (const attribute of atributeset.attributes) {
      const typeAttr = attribute[_class];
      if (typeAttr === 'attribute' || typeAttr === 'arrayattributeset') {
        const val = {
          check: true,
          value: `${parent}.${attribute[name]}`
        };
        this.columnItems.push(val);
        this.headers.push(`${parent}.${attribute[name]}`);
      } else if (typeAttr === 'attributeset') {
        const nested_parent =  `${parent}.${attribute[name]}`;
        this.constructAttributesetHeaderList(attribute as AttributeSet, nested_parent);
      }
    }
  }

  initializeDialog() {
    this.dataSource.data = [];

    if (this.criteriatable) {
      this.dataSource.data = [];
      this.criteriatable.renderRows();
      this.criteriaDelete = [];
    }
    this.criteriaObjects = [];
    this.criteriaList = [];
    this.criteriasArray = [];
    this.selection.clear();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  getReportTemplateById(chooseId) {
    this.reportsService.displayReportTemplatePlaceholders('');
    for (let i = 0; i < this.reportsTemplates.length; i++) {
      if (this.reportsTemplates[i].id === chooseId) {
        // alert(JSON.stringify(this.reportsTemplates[i]));
        this.selectedReportTemplate = this.reportsTemplates[i];
        this.updatePlaceHolderTable(this.reportsTemplates[i]);
        break;
      }
    }
  }

  chooseReportTemplateById() {
    const chooseId = ''; // this.$.reportsTemplatesSelect.value;
    this.reportsService.displayReportTemplatePlaceholders('');
    for (let i = 0; i < this.reportsTemplates.length; i++) {
      if (this.reportsTemplates[i].id === chooseId) {
        // alert(JSON.stringify(this.reportsTemplates[i]));
        this.selectedReportTemplate = this.reportsTemplates[i];
        break;
      }
    }
  }

  getUserReportTemplateById(templateId) {
    const token = getToken() || {};
    this.templateloaded = false;
    this.reportTempalteService.getReportTemplateById(templateId, token).subscribe(
      res => {
        this.reportsTemplates = [res];
        this.selectedReportTemplate = res;
        this.outReportTemplateChanged(templateId);
        this.templateloaded = true;
      },
      err => {
        this.templateloaded = true;
        this.toastService.errorToast(err);

      });
  }

  updatePlaceHolderTable(reportTemplate: any) {
    const savedPlaceHolders = getEnexisSetting();
    let placeholders = reportTemplate.place_holders;
    if (savedPlaceHolders) {
      placeholders = savedPlaceHolders;
    }
    const data = [];
    if (placeholders !== null && placeholders !== undefined) {
      if (placeholders.length > 0) {
        for (const placeholder of placeholders) {
          const keys = Object.keys(placeholder);
          const name = keys[0];
          const value = placeholder[name];
          const obj = {
            name,
            value
          };
          data.push(obj);
        }
        if (this.placeholderstable !== null  && this.placeholderstable !== undefined) {
          this.rptdataSource.data = [];
        }
      }
    }
    this.rptdataSource.data = data;
    this.placeholderstable.renderRows();
  }

  updatePlaceHolderValue(event, element, value) {
    event.preventDefault();
    const val = event.target.data;
    element['value'] = value;
    event.stopPropagation();
  }

  outReportTemplateChanged(selectedRepTem) {
    this.getReportTemplateById(selectedRepTem);
    // this.updatePlaceHolderTable(this.selectedReportTemplate);
  }

  /**
   * building the object of the colums' name to show and the search criteria to apply to the data
   */

  complete_request(aggrBody) {
    let request_pipeline = {};
    if(this.caseNumber && this.caseNumber.length) {
      request_pipeline = {
        "$lookup": {
            "from": "features",
            "let": {
                "templateIdStr": {
                  "$toString": "$_id"
                }
            },
            "pipeline": [
                {
                  "$match": {
                    "$expr": {
                      "$eq": [
                          "$template_id",
                          "$$templateIdStr"
                      ]
                    },
                    "attributes.Case_nr.value": this.caseNumber
                  }
                }
            ],
            "as": "relatedFeatures"
          }
      };
    } else {
      request_pipeline = {
        "$lookup": {
            "from": "features",
            "let": {
                "templateIdStr": {
                  "$toString": "$_id"
                }
            },
            "pipeline": [
                {
                  "$match": {
                    "$expr": {
                      "$eq": [
                          "$template_id",
                          "$$templateIdStr"
                      ]
                    }
                  }
                }
            ],
            "as": "relatedFeatures"
          }
      };
    }
    const unwind = {
      "$unwind": "$relatedFeatures"
    };
    const resplaceroot = {
        "$replaceRoot": {
          "newRoot": "$relatedFeatures"
        }
    }
    aggrBody.push(request_pipeline);
    aggrBody.push(unwind);
    aggrBody.push(resplaceroot);
  }

  submitResearchData() {
    let outputType = 'XLSX';
    let splitType = 'separate_files';
    const project = this.dataService.project;

    const aggregateBody =[];
    let tempObj = {};
    if(this.caseNumber && this.caseNumber.length > 0){
      tempObj = {
        $match: {
          project_id: project.id
        }
      };
    } else {
      tempObj = {
        $match: {
          project_id: project.id,
        }
      };
    }

    aggregateBody.push(tempObj);
    this.complete_request(aggregateBody)
    const holders = [];


    if (this.selectedReportTemplate !== undefined && this.selectedReportTemplate !== null) {
      const data = this.rptdataSource.data;
      if (data !== null && data !== undefined) {
        for (const el of data) {
          const  key = el.name;
          let val = el.value;
          const obj = {};
          if (val === null || val === undefined || val === '') {
            val = '';
          }
          obj[key] = val;
          holders.push(obj);
        }
      }

    }

    const templateId = '';
    if(!this.selectedReportTemplate) {
      this.toastService.warningToast("Report template required");
      return;
    }

    const objectSend = {
      template: templateId,
      aggregateBody,
      outputType,
      splitType,
      report_template_id: this.selectedReportTemplate.id,
      report_template_rtid: "xlsx.public.enexis.openbare_verlichting",
      place_holders: holders
    };

    persistEnexisSetting(holders)

    this.reportsService.lastExportedTemplate = objectSend;
    const result = {
      event: 'search',
      advanced_request: objectSend
    };
    this.resetDialog();
    this.dialogRef.close(result);
  }
}
