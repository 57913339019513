import { TranslateModule } from '@ngx-translate/core';
import { Component, HostListener, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Feature } from 'src/app/share/feature/feature';
import { Project } from 'src/app/share/projects';
import { Template } from 'src/app/share/template';
import { ProjectItemComponent } from '../project-item/project-item.component';
import { TemplateItemComponent } from '../template-item/template-item.component';
import { FoundFeaturesComponent } from '../found-features/found-features.component';

@Component({
  standalone: true,
  imports: [TranslateModule,
    ProjectItemComponent,
    TemplateItemComponent,
    FoundFeaturesComponent,
  ],
  selector: 'app-found-projects',
  templateUrl: './found-projects.component.html',
  styleUrls: ['./found-projects.component.scss']
})
export class FoundProjectsComponent implements OnInit {

  project: Project;
  template: Template;
  projects: Project[] = [];
  templates: Template[] = [];
  features: Feature[] = [];
  scrHeight: any;
  scrWidth: any;

  constructor(
    public store: StoreService,
    ) {
      // this.dataService.template.next(null);
      //
      //removeTemplate();
      this.store.listFoundTemplates = null;
      this.store.listFoundFeatures = null;
      //this.store.selectedFoundProject.next(null);
      //this.store.selectedFoundTemplate.next(null);
      this.projects = this.store.foundProjects['projects'];
  }


  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight - 127;
    this.scrWidth = window.innerWidth;
  }

  ngOnInit() {
    this.getScreenSize();
    this.store.selectedFoundProject.subscribe(p => {
      if(p) {

       // this.store.listFoundTemplates = [];
        //this.template = null;
        //this.setProject(p);
        this.project = p;
        this.store.listFoundTemplates = this.store.foundProjects['templates'].filter(t => t.project_id === p.id);

      }
    });

    this.store.selectedFoundTemplate.subscribe(t => {

      if(t) {
        this.template = t;
        this.store.listFoundFeatures = [];
        this.store.listFoundFeatures = this.store.foundProjects['features'].filter(f => f.template_id === t.id);

      } else {
        this.store.listFoundFeatures = [];
      }
     });
  }

  onSelected(p, event) {
    this.templates = [];
    this.template = null;
    this.features = [];
    this.store.listFoundFeatures = [];
    this.store.listFoundTemplates = [];
    event.preventDefault();
    this.store.selectedFoundProject.next(p);
    //this.setProject(p);
    if(this.store.copiedFeatures && this.store.copiedFeatures.length > 0) {
      this.store.destinationProject = p;
    }
    event.stopPropagation();
  }


  setProject(p) {
    this.project = p;
    this.store.listFoundTemplates = this.store.foundProjects['templates'].filter(t => t.project_id === p.id);
  }

  onSelectedTemplate(t, event) {
    //event.preventDefault();
    this.store.selectedFoundTemplate.next(t);
    if(this.store.copiedFeatures && this.store.copiedFeatures.length > 0) {
      this.store.destinationTemplate = t;
    } else {
      this.store.copiedFeatures = [];
    }
    //this.template = t;
    //this.features = this.store.foundProjects['features'].filter(f => f.template_id == t.id);
    //event.stopPropagation();
  }

}
