import { TranslateModule } from '@ngx-translate/core';
import {Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef} from '@angular/core';
import { Template } from 'src/app/share/template';
import {FormGroup, FormBuilder, ReactiveFormsModule, FormsModule, Validators} from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import {
  getChoosenCRSType, getToken, getImportCSVFileSetting, persitImportCSVFileSetting, removeImportCSVFileSetting
} from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import {CommonModule} from '@angular/common';
import {MatStepper, MatStepperModule} from '@angular/material/stepper';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule} from '@angular/material/core';
import {MatTable, MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatRadioChange, MatRadioModule} from '@angular/material/radio';
import {Project} from '../../../share/projects';
import {User} from '../../../share/user';
import {BehaviorSubject} from 'rxjs';
import {DataService} from '../../../services/data.service';
import {Papa} from 'ngx-papaparse';
import {ImportErrorDlgComponent} from '../import-error-dlg/import-error-dlg.component';
import {ToastService} from '../../../services/toast.service';
import {ReportsService} from '../../../services/reports.service';
import {CookieService} from 'ngx-cookie-service';

export interface Criteria {
  criteria: string;
}

const ELEMENT_DATA: Criteria[] = [];

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatRadioModule,
    MatStepperModule,
    MessageBoxComponent,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatTableModule,
    FormsModule,
    MatDatepickerModule,
    MatSelectModule,
    MatButtonModule,
    CommonModule,
  ],
  selector: 'app-import-data-dialog',
  templateUrl: './import-CSV-dialog.component.html',
  styleUrls: ['./import-CSV-dialog.component.scss', '../dialog-btns.component.scss']
})
export class ImportCSVDialogComponent implements OnInit {
  project: Project;
  template: Template;
  @Input() user: User;
  @Input() mode = {
    text: 'Create',
    function: 'Create'
  };
  loading = false;
  loadingText = '';
  @Output() invalidIconDropped = new EventEmitter<boolean>();
  @ViewChild('imgIcon') img;
  @ViewChild('tabRef') private tabRef: MatStepper;


  selectedIndex: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  fileInBase64: string;
  fileToUpload: File;
  importInNewTemplate = false;
  importInExistingTemplate = false;
  isValidFile = true;
  importFileForm: FormGroup;
  delimiter = ',';
  decimalSeparator = '.';
  utmZones = [];
  importDataError: any;
  featureSize = 0;

  crsList = [
    {value: 'Geodetic', text: 'Geodetic'},
    {value: 'Lambert72', text: 'Lambert 72'},
    {value: 'Lambert2008', text: 'Lambert 2008'},
    {value: 'RDNAP', text: 'RD'},
    {value: 'UTM', text: 'UTM'},
    {value: 'BGS2005', text: 'BGS2005'}
  ];

  fieldTypes = [
    {value: 'stringfield',  text: 'Text'},
    {value: 'integerfield', text: 'Integer'},
    {value: 'decimalfield', text: 'Decimal'},
    {value: 'booleanfield', text: 'Boolean'},
    {value: 'notefield',    text: 'Note'},
    {value: 'datefield', text: 'Date'},
    {value: 'timefield', text: 'Time'}
  ];

  coordsList = [
    'Latitude',
    'Longitude',
    'X', 'Y', 'Z',
    'N', 'E',
    'x','y','z',
    'n', 'e',
    'latitude',
    'longitude'
  ]


  fields = [];
  showFieldContainer = false;

  errMsg: string;
  templates: any[];
  noFileIsSelected = false;
  isCrsUTM: any = false;

  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatTable) dataToImportTable: MatTable<any>;

  displayedColumns: string[];
  tempTemplateName: string;

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = 'Test of message';
  showMessage = false;

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fform') importFileFormDirective;

  constructor(
    private fb: FormBuilder,
    public store: StoreService,
    private dataService: DataService,
    private cookieService: CookieService,
    private papaParser: Papa,
    private dialogRef: MatDialogRef<ImportCSVDialogComponent>,
    private dialog: MatDialog,
    private reportService: ReportsService,
    public toastService: ToastService,
    ) {

  }

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.closeDialog();
      }
    });


    if (this.store.importDataInNewForm) {
      this.importInNewTemplate = this.store.importDataInNewForm;
    } else {
      this.importInExistingTemplate = !this.store.importDataInNewForm;
    }
    this.createForm();
    this.getListTemplates();
    this.selectedIndex.subscribe(res => {
    });
  }

  typeTemplateChange(event: MatRadioChange, data) {
    if (data === 'new_template') {
      this.importInNewTemplate = true;
      this.importInExistingTemplate = false;
      if(this.tempTemplateName && this.tempTemplateName.length > 0) {
        this.importFileForm.patchValue({
          template: this.tempTemplateName
        });
      }
    } else {
      if(this.importFileForm.value && this.importFileForm.value['template']){
        this.tempTemplateName = this.importFileForm.value['template'];
      }
      const t = this.dataService.template.getValue();
      if(t !== null) {
        this.importFileForm.patchValue({
          template: t.name
        });
      } else {
        this.importFileForm.patchValue({
          template: null
        });
      }
      this.importInNewTemplate = false;
      this.importInExistingTemplate = true;
    }
  }

  createForm() {
    let templateName = this.importInExistingTemplate ? this.dataService.template?.getValue().name : '';
    const csvConfig = getImportCSVFileSetting();
    let crs = csvConfig?.['crs'] || this.cookieService.get('CRSValue') || 'Geodetic';

    if (this.mode.function === 'Create') {
      this.importFileForm = this.fb.group({
        csvFile: ['', Validators.required],
        crs: [crs, Validators.required],
        template: [templateName, Validators.required],
        typetemplate: '',
      });
    }
  }

  goToNextStep() {
    this.tabRef.next();
  }

  constructCoordinateColumnsNbr(): any[] {
    if (this.fields && this.fields.length > 0) {
      const tempCoordColumns = [];
      let index = 0;
      let xIndex = null;
      let yIndex = null;
      let zIndex = null;
      for (const field of this.fields) {
        ++index;
        if (field.isCoordinate) {
          if (field.typeCoordinate.toLowerCase() === 'lat' || field.typeCoordinate.toLowerCase() === 'latitude'
            || field.typeCoordinate.toLowerCase() === 'x' || field.typeCoordinate.toLowerCase() === 'n' ) {
            xIndex = index;
          } else if (field.typeCoordinate.toLowerCase() === 'lon' || field.typeCoordinate.toLowerCase() === 'longitude'
            || field.typeCoordinate.toLowerCase() === 'y' || field.typeCoordinate.toLowerCase() === 'e') {
            yIndex = index;
          } else if (field.typeCoordinate.toLowerCase() === 'z') {
            zIndex = index;
          }
        }
      }
      if (xIndex) {
        tempCoordColumns.push(xIndex);
      }
      if (yIndex) {
        tempCoordColumns.push(yIndex);
      }
      if (zIndex) {
        tempCoordColumns.push(zIndex);
      }
      return tempCoordColumns;
    }
    return null;
  }

  constructDataFields(): any[] {
    if (this.fields && this.fields.length > 0) {
      const locTempFields = [];
      for (const field of this.fields) {
        if (!field.isCoordinate) {
          const tempField = {
            _class: field._class,
            name: field.name,
            label: field.label,
            description: field.description,
            enabled: true,
            visible: true,
            required: false,
            use_previous_value: false,
            constraints: [],
            event_actions: null,
            default_value_expression: null
          };
          locTempFields.push(tempField);
        }
      }
      return locTempFields;
    }
    return [];
  }

  checkCoordinateType(event, field): void {
    for (const f of this.fields) {
      if (f.isCoordinate) {
        if (f.name !== field.name) {
          if (f.typeCoordinate === event) {
            this.toastService.errorToast('This coordinate type is already chosen');
            field.typeCoordinate = null;
            event = null;
            return;
          }
        }
      }
    }
  }

  closePanel() {
    this.showMessage = false;
  }


  showErrorDlg(): void {
    const dialogRef = this.dialog.open(ImportErrorDlgComponent, {
      width: '500px',
      data: {
        error: this.importDataError
      }
    });
    dialogRef.afterClosed().subscribe(result  => {

    });
  }

  onSubmit() {
    if (!this.fileToUpload && !this.fileInBase64) {
      this.noFileIsSelected = true;
      this.toastService.errorToast('No file is selected');
      return;
    }
    this.showMessage = false;
    const importData = this.importFileForm.value;

    if(importData.template.trim().length === 0) {
      this.toastService.errorToast("Name is empty");
      return;
    }

    const fileNamesParts = importData.csvFile.split('.');
    let fileType = '';
    if (fileNamesParts && fileNamesParts.length > 0) {
      fileType = fileNamesParts[fileNamesParts.length - 1];
    }
    const p = this.dataService.project;
    const value = {
      template: {
        name: importData.template,
        description: importData.template,
        project_id: p.id,
        image_file_url: null
      },
      data: {
        base64_encoded_content: this.fileInBase64,
        data_separator : this.delimiter,
        encoding: 'UTF8',
        type: 'CSV'
      }
    };
    const coordColumns = this.constructCoordinateColumnsNbr();

    value['data']['decimal_separator'] = this.decimalSeparator;

    if (coordColumns && coordColumns.length > 0 && importData.crs) {
      value['data']['geometry'] =  'Point';
      value['data']['crs'] =  importData.crs;
      value['data']['coordinate_columns'] =  coordColumns;
    } else {
      value['data']['geometry'] =  null;
    }

    const constructedfields = this.constructDataFields();
    if (this.importInNewTemplate) {
      value['data']['fields'] =  constructedfields;
    }
    const csvConfig = {};
    csvConfig['filename'] = importData.csvFile;
    const fs = [];
    if (constructedfields && constructedfields.length > 0) {
      for(const constfield of constructedfields) {
        const f = {
          name: constfield['name'],
          _class: constfield['_class']
        }
        fs.push(f);
      }
      csvConfig['fields'] = fs;
    }
    persitImportCSVFileSetting(csvConfig);
    this.store.showLoading();
    this.loadingText = 'Sending import data';
    const token = getToken();
    this.dataService.importDataInTemplate(value, token, p.id).subscribe(
      res => {
        this.toastService.successToast(`Import succeeded for form: ${res.name}`);
        if (this.importInNewTemplate) {
          this.dataService.createTemplateLocally(res);
          this.dataService.readTemplate(res);
        } else {
          this.dataService.createTemplateLocally(res);

          this.dataService.readTemplate(res);
          this.store.hideLoading();
        }
        this.store.hideLoading();
        this.dialogRef.close();
      },
      error => {
        this.loading = false;
        this.store.hideLoading();

        if (error instanceof Array) {
          this.importDataError = error;
        } else {
          this.importDataError = [error]
        }
        let msg = error ? `${error}` : `Importation failed, please retry later`;
        if (error instanceof Array) {
          const record = error.length > 1 ? 'records' : 'record';
          msg = `${error.length} ${record} could not be imported. No data of CSV has been imported` ;
        }
        this.toastService.errorToast(msg);
      }
    );
  }

  onlyDigits(str) {
    const pattern = /^\d+$/;
    return pattern.test(str);  // returns a boolean
  }

  fileAddedFlag = false;

  setFileData(event): void {
    event.preventDefault();
    this.showFieldContainer = false;

    if (event.target.files && event.target.files.length > 0) {
      const tempFile = event.target.files[0];
      this.isValidFile = true;
      const filename = tempFile.name;
      const filenameParts = filename ? filename.split('.') : [];
      if (filenameParts && filenameParts.length > 1) {
        this.importFileForm.patchValue({
          template: filenameParts[0]
        });
      }
      this.fileToUpload = event.target.files[0];
      this.fileAddedFlag = true;
      this.papaParser.parse(this.fileToUpload, {
        dynamicTyping: true,
        worker: true,
        skipEmptyLines: true,
        chunk: (chunk) => {
          try {
            this.displayedColumns  = chunk.data.splice(0, 1)[0];
            this.delimiter = chunk.meta.delimiter;

            if (this.delimiter === ';') {
              this.decimalSeparator = ',';
            }
            this.fields  = [];
            let checkConfig = false;
            const csvConfig = getImportCSVFileSetting();
            let saveFields = [];

            if (csvConfig) {
              const filename = csvConfig['filename'];
              if (filename && filename === this.fileToUpload.name) {
                checkConfig = true;
                saveFields = csvConfig['fields'];
              } else {
                removeImportCSVFileSetting();
              }
            }

            if (this.displayedColumns && this.displayedColumns.length > 0) {
              for (const i of this.displayedColumns) {
                if (i) {
                  if (this.onlyDigits(i)) {
                    this.displayedColumns = [];
                    this.dataSource.data = [];
                    this.fields = [];
                    this.showFieldContainer = false;
                    this.isValidFile = false;
                    break;
                  } else {
                    const val2 = i.toString().trim().toLowerCase();
                    if (val2 === 'x' || val2 === 'y' || val2 === 'z' ||
                      val2 === 'lat' || val2 === 'lon' || val2 === 'latitude' ||
                      val2 === 'longitude' || val2 === 'n' || val2 === 'e') {
                      this.fields.push( {
                        name: val2,
                        label: val2,
                        description: val2,
                        _class: 'decimalfield',
                        isCoordinate: true,
                        typeCoordinate: val2
                      });
                    } else {
                      let _class = 'stringfield';
                      if(checkConfig && saveFields.length > 0) {
                        for(const f of saveFields) {
                          if (f['name'] === i) {
                            _class = f['_class'];
                            break;
                          }
                        }
                      }
                      this.fields.push({
                        name: i,
                        label: i,
                        description: i,
                        _class: _class,
                        isCoordinate: false,
                        typeCoordinate: ''
                      });
                    }
                  }
                } else {
                  this.displayedColumns = [];
                  this.dataSource.data = [];
                  this.fields = [];
                  this.showFieldContainer = false;
                  this.isValidFile = false;
                  break;
                }
              }
            }
            if (this.isValidFile) {
              this.showFieldContainer = true;
              const results = chunk.data;
              const data = [];
              this.featureSize = results.length;
              for (const d of results) {
                const item = {};
                if (data.length === 25) {
                  break;
                }
                for (let i = 0; i < this.displayedColumns.length; i++) {
                  const key = this.displayedColumns[i];
                  const value = d[i];
                  item[key] = value;
                }
                data.push(item);
              }
              if(data.length > 0) {
                const firstItem = data[0]
                const keys = Object.keys(firstItem);
                let pointSeparatorFound = false;
                for(const key of keys) {
                  if (key.toLowerCase() === 'x' || key.toLowerCase() === 'y' || key.toLowerCase() === 'z' ||
                    key.toLowerCase() === 'lat' || key.toLowerCase() === 'lon' || key.toLowerCase() === 'latitude' ||
                    key.toLowerCase() === 'longitude' || key.toLowerCase() === 'n' || key.toLowerCase() === 'e') {
                    const val = firstItem[key].toString();
                    if(val.indexOf(".") >= 0) {
                      pointSeparatorFound = true;
                    }
                  }
                }
                if (pointSeparatorFound) {
                  this.decimalSeparator = "."
                }
              }

              this.dataSource.data = data;
            } else {
              this.message = 'Error in CSV file';
              this.importDataError = ['The header of the file is missing or is not correct.'];
              this.isValidFile = true;
              this.showMessage = true;
              return;
            }
          } catch (error) {
            this.message = 'Error in CSV file';
            this.importDataError = ['The header of the file is missing or is not correct.'];
            this.showMessage = true;
          }
        },
        complete: (_results, file) => {
          this.reportService.convertFileToBase64(this.fileToUpload).then(base64String => {
            this.fileInBase64 = base64String;
          }).catch(err => {
            this.toastService.errorToast(err);
          })
          this.importFileForm.get('csvFile').setValue(this.fileToUpload.name);
        },
        error: (err) => {
          this.toastService.errorToast(err);
        }
      });
      event.stopPropagation();
    }
  }

  changedFieldType(fieldType, f): void {
    if (fieldType && fieldType === 'decimalfield') {
      const name = f.name;
      const val = this.dataSource.data[0][name];
      if (val && val.indexOf(',') >= 0) {
        this.decimalSeparator = ',';
      } else {
        this.decimalSeparator = '.';
      }
    } else if (fieldType && fieldType === 'notefield') {
      f['html'] = '';
    }
  }

  getListTemplates(): void {
    const token = getToken();
    const prjt = this.dataService.project;
    this.loadingText = 'Loading existed templates ....';
    this.loading = true;
    this.dataService.requestTemplates(token, prjt.id).subscribe(
      res => {
        this.templates = res;
        this.loading = false;
      },
      err => {
        this.loading = false;
      }
    );
  }

  resetDialog() {
    this.selectedIndex= new BehaviorSubject<number>(null);
    this.fileInBase64 = '';
    this.fileToUpload = null;
    this.importInNewTemplate = false;
    this.importInExistingTemplate = false;
    this.isValidFile = true;
  }

  closeDialog() {
    this.resetDialog();
    this.dialogRef.close();
  }
}
