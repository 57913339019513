<div class="import-excel-container">
    <div fxFlex="95%">
        <h3 class="pb-3 pl-2"><span>{{ "IMPORT EXCEL FILE" | translate }}</span></h3>
        <mat-horizontal-stepper [linear]="true" #tabRef>
            <mat-step [completed]="isValidFile" label="Choose Excel file">
                <div class="card mb-2">
                    <div class="card-body">
                        <form novalidate [formGroup]="importFileForm" #fform="ngForm">
                            <p>
                                <mat-form-field class="full-width">
                                    <input matInput class="h4" [placeholder]="'FILE TO IMPORT (EXCEL)' | translate" readonly>
                                    <input type="file" class="btn btn-small mb-0" id="userFile" (change)="onFileChange($event)" #fileInput accept=".xlsx">
                                </mat-form-field>

                                <mat-error *ngIf="noFileIsSelected"><span>{{ "FILE IS REQUIRED" | translate }}</span></mat-error>
                            </p>
                        </form>
                    </div>
                </div>

                <div class="button-group">
                    <button mat-stroked-button mat-dialog-close type="button" class="cancel-btn"><span>{{ "CANCEL" | translate }}</span></button>
                    <button mat-stroked-button class="submit-btn" [disabled]="!isValidFile" matStepperNext><span>{{ "NEXT" | translate }}</span></button>
                </div>
            </mat-step>
            <mat-step label="Form">
                <form novalidate [formGroup]="importFileForm">
                    <h3 style="font-weight: 800;"><span>{{ "COORDINATE REFERENCE SYSTEM" | translate }}</span></h3>
                    <p class="mb-2" style="margin-bottom: 20px !important;">
                        <mat-select class="ml-3 mb-2" class="half-width" formControlName="crs" [placeholder]="'COORDINATE REFERENCE SYSTEM' | translate">
                            <mat-option [value]="null">None</mat-option>
                            @for(coordRef of crsList; track coordRef){
                                <mat-option  [value]="coordRef.value">{{ coordRef.text }}</mat-option>
                            }

                        </mat-select>
                        @if(isCrsUTM){
                            <mat-select  class="ml-3 mb-2" class="half-width" [placeholder]="'COORDINATE REFERENCE SYSTEM' | translate">
                                <mat-option></mat-option>
                                @for(utmzone of utmZones; track utmzone) {
                                    <mat-option [value]="utmzone">{{ utmzone }}</mat-option>
                                }
                            </mat-select>
                        }

                    </p>
                    <p class="form-group">
                        <mat-radio-group class="radio-group" formControlName="typetemplate">
                            <mat-radio-button color="primary" style="display: block; color:black !important;" [value]="'new_template'" [checked]="store.importDataInNewForm" (change)="typeTemplateChange($event, 'new_template')"><span>{{ "IMPORT IN NEW FORM" | translate }}</span></mat-radio-button>
                            <mat-radio-button color="primary" style="display: block; color:black !important;" [value]="'existing_template'" [checked]="!store.importDataInNewForm" (change)="typeTemplateChange($event, 'existing_template')"><span>{{ "IMPORT IN EXISTING FORM" | translate }}</span></mat-radio-button>
                        </mat-radio-group>
                    </p>
                    <div [hidden]='!importInExistingTemplate'>
                        <strong class="half-width"><span>{{ "FORM" | translate }}</span></strong>
                        <p>
                            <mat-select class="ml-3" class="half-width" formControlName="template" required [placeholder]="'CHOOSE FORM' | translate">
                                <mat-option></mat-option>
                                @for(templa of templates; track templa) {
                                    <mat-option [value]="templa.name">{{ templa.name }}</mat-option>
                                }

                            </mat-select>
                            @if(importFileForm.get('template').hasError('required')) {
                                <mat-error>
                                    Form name is required
                                </mat-error>
                            }
                        </p>
                    </div>
                    <p [hidden]='!importInNewTemplate'>
                        <mat-form-field class="full-width">
                            <input matInput autocomplete="off" formControlName="template" [placeholder]="'FORM NAME' | translate" type="text" required>
                            @if (importFileForm.get('template').hasError('required') && importFileForm.get('template').touched) {
                                <mat-error >
                                    {{"FORM NAME IS REQUIRED" | translate}}
                                </mat-error>
                            }
                        </mat-form-field>
                    </p>
                </form>
                @if (showFieldContainer) {
                    <div class="mb-2">
                        <table class="table table-hover table-bordered mb-2">
                            <thead>
                                <tr>
                                  <th class="text-center" scope="col" style="font-weight: 800;"><span>{{ "FIELD NAME" | translate }}</span></th>
                                  <th class="text-center" scope="col" style="font-weight: 800;"><span>{{ "FIELD LABEL" | translate }}</span></th>
                                  <th class="text-center" scope="col" style="font-weight: 800;"><span>{{ "FIELD TYPE" | translate }}</span></th>
                                  <th class="text-center" scope="col" style="font-weight: 800;"><span>{{ "COORDINATE" | translate }}</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                @for (f of fields; track f) {
                                    <tr >
                                        <th scope="row">
                                            <input type="text" name="name" [(ngModel)]="f.name" disabled class="form-control">
                                        </th>
                                        <td>
                                            <input matInput [(ngModel)]="f.label" required name="label" type="text" class="form-control">
                                        </td>
                                        <td>
                                            <select class="custom-select" [(ngModel)]="f._class" (ngModelChange)="changedFieldType(f._class, f)">
                                                @for(fieldType of fieldTypes; track fieldType){
                                                    <option [value]="fieldType.value">{{ fieldType.text }}</option>
                                                }
                                            </select>
                                        </td>
                                        <td>
                                            <label class="custom-checkbox text-left">
                                            <mat-checkbox class="text-left" class="ml-2" [(ngModel)]="f.isCoordinate"></mat-checkbox>
                                            </label>
                                            @if (f.isCoordinate) {
                                                <select class="ml-2" (ngModelChange)="checkCoordinateType($event, f)" [(ngModel)]="f.typeCoordinate">
                                                    @for(typeCoord of coordsList; track typeCoord){
                                                        <option [value]="typeCoord">{{ typeCoord }}</option>
                                                    }
                                                </select>
                                            }

                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                }
              <div class="button-group">
                    <button mat-stroked-button matStepperPrevious><span>{{ "BACK" | translate }}</span></button>
                    <button mat-stroked-button mat-dialog-close type="button" class="cancel-btn" ><span>{{ "CANCEL" | translate }}</span></button>
                    <button mat-stroked-button class="submit-btn" (click)="goToNextStep()"><span>{{ "NEXT" | translate }}</span></button>
                </div>
            </mat-step>
            <mat-step label="Data preview ">
                <div fxLayout="row " class="mt-2 ">
                    <div fxFlex>
                        <table class="table table-hover table-bordered mb-2">
                        <thead>
                            <tr>
                                @for (col of header; track col) {
                                    <th>{{ col }}</th>
                                }

                            </tr>
                          </thead>
                          <tbody>
                            @for (row of data; track row) {
                                <tr >
                                    @for (col of headers_index; track col) {
                                        <td>{{ row[col] }}</td>
                                    }
                                </tr>
                            }
                          </tbody>
                    </table>
                    </div>
                </div>
              <div class="button-group">
                <button mat-stroked-button matStepperPrevious><span>{{ "BACK" | translate }}</span></button>
                <button class="cancel-btn" mat-dialog-close mat-stroked-button type="button"><span>{{ "CANCEL" | translate }}</span></button>
                <button *ngIf="!isFileProcessing" mat-button [disabled]="isFileProcessing || importFileForm.get('template').hasError('required')" (click)="onSubmit()" class="submit-btn"><span>{{ "IMPORT DATA" | translate }}</span></button>
                <button *ngIf="isFileProcessing" mat-button [disabled]="isFileProcessing" class="submit-btn"><fa-icon class="fa fa-spinner fa-spin" [icon]="faSpinner"></fa-icon><span class="import-data-text">{{ "IMPORT DATA" | translate }}</span></button>

              </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>


