import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { StoreService } from 'src/app/services/store.service';

@Component({
  standalone: true,
  imports: [TranslateModule, 
    MatButtonModule,
  ],
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  templatesOpen: boolean;
  projectsOpen: boolean;

  constructor(
    public store: StoreService,
  ) { }

  ngOnInit() {
    this.templatesOpen = false;
    this.projectsOpen = false;
  }

  openProjectsPageContainer() {
    this.projectsOpen = false;
    this.store.hideProjectsContainer = this.projectsOpen;
  }

  closeProjectsPageContainer() {
    this.projectsOpen = true;
    this.store.hideProjectsContainer = this.projectsOpen;
  }

  closeTemplatesPageContainer() {
    this.templatesOpen = true;
    this.store.hideTemplatesContainer = this.templatesOpen;
  }

  openTemplatesPageContainer() {
    this.templatesOpen = false;
    this.store.hideTemplatesContainer = this.templatesOpen;
  }
}
