import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { getToken } from 'src/app/share/utils';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { User } from 'src/app/share/user';
import { DataService, ProjectToken } from 'src/app/services/data.service';
import { Project } from 'src/app/share/projects';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../services/toast.service';
import {HeaderService} from '../../services/header.service';

const ELEMENT_DATA: Project[] = [];

@Component({
  standalone: true,
  imports: [TranslateModule,
    MessageBoxComponent,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatButtonModule,
  ],
  selector: 'app-user-projects-page',
  templateUrl: './user-projects-page.component.html',
  styleUrls: ['./user-projects-page.component.scss']
})

export class UserProjectsPageComponent implements OnInit {
  user: User;
  displayedColumns: string[] = ['projects', 'members'];
  dataSource = new MatTableDataSource<Project>(ELEMENT_DATA);
  selection = new SelectionModel<Project>(true, []);
  searchValue: string;
  message = '';
  showMessage: boolean;
  proClass: string = null;
  visibility: string = null;
  archived: boolean = false;

  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }

  constructor(
    private dialog: MatDialog,
    public store: StoreService,
    private projectSevice: DataService,
    public toastService: ToastService,
    public headerService: HeaderService
  ) {
    this.store.showLoading();
    const token = getToken();
    const user = this.store.user;
    const proToken: ProjectToken = {
      key: 'X-Auth-Token',
      value: token.key
    };
    this.proClass = this.store.proClass;
    this.visibility = this.store.visibility;
    this.archived = this.store.archived;

    if (user !== undefined && user !== null) {
      if (user['user_type'] === 'collaborator' || (user['manager_id'] !== null && user['manager_id'] !== undefined)) {
        this.projectSevice.getCollaboratorProjects(token, this.proClass, this.visibility, this.archived).subscribe(
          res => {
            let data = this.dataSource.data;
            data = [];
            for (const p of res) {
              data.push(p);
            }
            this.dataSource.data = data.filter(p => p.created_by === user.id).sort(
              (p1, p2) => {
              if (p1.name < p2.name) { return -1; }
              if (p1.name > p2.name) { return 1; }
              return 0;
            });
            this.store.hideLoading();
          },
          errmess => {
            this.toastService.errorToast(errmess);
            this.dataSource.data = [];
            this.store.hideLoading();
          }
        );
        return;
      }
    }
    this.projectSevice.getProjects(proToken, this.proClass, this.visibility, this.archived).subscribe(
      projects => {
        let data = this.dataSource.data;
        data = [];
        for (const p of projects) {
          data.push(p);
        }
        this.dataSource.data = data.sort(
          (p1, p2) => {
          if (p1.name < p2.name) { return -1; }
          if (p1.name > p2.name) { return 1; }
          return 0;
        });
        this.store.hideLoading();
      },
      err => {
        this.toastService.errorToast(err);
        this.dataSource.data = [];
        this.store.hideLoading();
      }
    );
  }

  ngOnInit() {
    // this.user = getUser();
    this.user = this.store.user;
  }
}
