import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Component, OnDestroy, OnInit} from '@angular/core';
import { Template } from '../../share/template';
import { DataService} from '../../services/data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateTemplateComponent } from '../dialogs/create-template/create-template.component';
import {
  persistLastMenu,
  removeSearchValue,
  removeCurrentFeaturesUri,
  removeGeomFormToShow,
  persistGeomFormToShow,
  getUser
} from 'src/app/share/utils';
import { StoreService, SCHEMA_MODE, MAP_MODE, DATA_MODE } from 'src/app/services/store.service';
import { TemplateItemComponent } from '../template-item/template-item.component';
import {HeaderService} from '../../services/header.service';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {MatDivider} from '@angular/material/divider';
import {ToastService} from '../../services/toast.service';
import {UnsavedChangesDialogComponent} from '../dialogs/unsaved-changes-dialog/unsaved-changes-dialog.component';
import {MatTooltip} from '@angular/material/tooltip';
import {ReportsService} from '../../services/reports.service';
import { data } from 'jquery';
import {NgForOf} from '@angular/common';
import {Subject, Subscription} from 'rxjs';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    TemplateItemComponent,
    MatMenuTrigger,
    MatDivider,
    MatMenu,
    MatMenuItem,
    MatTooltip,
    NgForOf,
  ],
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit, OnDestroy {

  selectedTemplate: Template;
  fields: any[];

  constructor(
    public store: StoreService,
    private dialog: MatDialog,
    private headerService: HeaderService,
    public dataService: DataService,
    private translate: TranslateService,
    private reportsService: ReportsService,
    public toastService: ToastService,
  ) { }

  ngOnInit() {
    const currentTemplate = this.dataService.template.getValue();
    if (currentTemplate) {
      this.onSelectedTemplateAfterCheck(currentTemplate);
    }
  }

  ngOnDestroy() {
    if (this.selectTemplateSubscription) {
      this.selectTemplateSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  private destroy$ = new Subject<void>();
  private selectTemplateSubscription: Subscription;

  trackById(index: number, item: any): any {
    return item.id; // Replace `id` with the unique identifier property of your `templates`
  }

  openAddTemplateFromDomainDialog() {
    if(this.store.archived) {
      const projectClass = this.headerService.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is not allowed on an archived ${projectClass}`)
      return;
    }
    this.checkIfFormIsUpdated(() => {
      const user = getUser();
      const project = this.dataService.project;
      if (project != null && user.id === project.created_by) {
        this.headerService.openAddTemplateFromDomainDialogAfterCheck();
      } else {
        const projectClass = this.headerService.titlecasePipe.transform(this.store.proClass);
        this.toastService.errorToast(`Please, choose a  ${projectClass}`)
        return;
      }
    });
  }

  onSelectedTemplate(template, event) {
    event.preventDefault();
    this.dataService.selectTemplate(template);
  }

  onSelectedTemplateAfterCheck(template) {
    this.store.features = [];
    this.selectedTemplate = template;

    this.dataService.readTemplate(template);
    const obj = {};
    const templId = template['id'];
    obj[templId] = true;

    if(obj) {
      removeGeomFormToShow();
      persistGeomFormToShow(obj);
    }
    this.store.features = null;
    let menu = this.store.formMode;
    if (this.store.proClass === "template" || this.store.proClass === "domain") {
      menu = SCHEMA_MODE;
    } else if (template.feature.geometry_type === null || template.feature.geometry_type === undefined) {
      menu = DATA_MODE;
    }
    this.reportsService.lastExportedTemplate = null;
    removeSearchValue();
    removeCurrentFeaturesUri();

    if (menu === SCHEMA_MODE) {
      this.openTemplateSchema();
    } else if (menu === MAP_MODE) {
      this.dataService.hideTemplate();
      this.dataService.openMapData();
    } else {
      this.dataService.openFeatureData();
      if (this.store.copiedFeatures && this.store.copiedFeatures.length > 0) {
        this.store.destinationTemplate = template;
      }
    }
  }

  openAddTemplateDialog() {
    this.checkIfFormIsUpdated(() => {
      this.openAddTemplateDialogAfterCheck();
    });
  }

  openAddTemplateDialogAfterCheck() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      mode: {
        text: this.translate.instant('CREATE'),
        function: 'Create'
      },
    };
    this.dialog.open(CreateTemplateComponent, dialogConfig);
  }

  checkIfFormIsUpdated(action) {
    const templ = this.dataService.template.getValue();
    const updatedForm = this.store.updatedForm;
    if ((templ) && (updatedForm)) {
      const dialogRef = this.dialog.open(UnsavedChangesDialogComponent, {
        data: {
          formName: templ.name,
        }
      });

      dialogRef.afterClosed().subscribe(result  => {
        if (result) {

          this.store.updatedForm = false;
          this.store.changeFormUpdatingState(false);
          action();
        } else {
          this.dataService.selectedTemplate.next(templ.name);
        }
      });
    } else {
      action();
    }
  }


  openTemplateSchema() {
   // this.testIfUpdatingForm(() => {
      this.openTemplateSchemaAfterCheck();
   // });
  }

  openTemplateSchemaAfterCheck() {
    this.dataService.showTemplateInfo();
    persistLastMenu('info');
  }

  protected readonly DATA_MODE = DATA_MODE;
  protected readonly MAP_MODE = MAP_MODE;
  protected readonly SCHEMA_MODE = SCHEMA_MODE;
  protected readonly getUser = getUser;
  protected readonly data = data;
}
