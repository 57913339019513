<div fxFlexLayout="row">
  <div fxFlex fxFlexOffset="20px">
    <h3 class="pb-3">{{ mode.text }} {{ "TIME FIELD" | translate }}</h3>
    <form novalidate [formGroup]="timeFieldForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      @if (showMessage) {
        <app-message-box style="margin-left: -10px;" #messageBox message="{{message}}"></app-message-box>
      }
      @if (nameLengthExceed10) {
        <div class="alert alert-warning" role="alert">
          {{"NAME_ADVICE_FOR_SHAPE_FILE_1" | translate }} <br />
          {{"NAME_ADVICE_FOR_SHAPE_FILE_2" | translate }}
        </div>
      }
      <mat-dialog-content>
        <p>
          <mat-form-field class="full-width">
            <mat-label><span>{{ "LABEL" | translate }}</span></mat-label>
            <input matInput autocomplete="off" (keyup)="onLabelChange();" formControlName="label" [placeholder]="'LABEL' | translate" type="text" required>
            @if (timeFieldForm.get('label').hasError('required') && timeFieldForm.get('label').touched) {
              <mat-error><span>{{ "FIELD LABEL IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="full-width">
            <mat-label><span>{{ "NAME" | translate }}</span></mat-label>
            <input matInput autocomplete="off" formControlName="name" (keyup)="onNameChange();" [placeholder]="'NAME' | translate" type="text" required>
            @if (timeFieldForm.get('name').hasError('required') && timeFieldForm.get('name').touched) {
              <mat-error><span>{{ "FIELD NAME IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
          <small style="margin-top: -5px;" class="form-text text-muted ">The name is used by MyCumulus .</small>
        </p>

        <p>
          <mat-form-field class="full-width">
            <mat-label><span>{{ "DESCRIPTION" | translate }}</span></mat-label>
            <input matInput autocomplete="off" formControlName="description" [placeholder]="'DESCRIPTION' | translate" type="text">
          </mat-form-field>
        </p>
        <p>
          <span class="half-width"><span>{{ "DEFAULT VALUE EXPRESSION" | translate }}</span></span>
          <mat-select class="ml-3" class="half-width" formControlName="default_value_expression" [placeholder]="'DEFAULT VALUE EXPRESSION' | translate">
            <mat-option></mat-option>
            @for (exp of timeDefaultValueExpression; track exp) {
              <mat-option [value]="exp.value">{{ exp.name }}</mat-option>
            }
          </mat-select>
        </p>

        <p>
          <span class="half-width"><span>{{ "EVENT ACTION" | translate }}</span></span>
          <mat-select class="ml-3" class="half-width" formControlName="event_actions" [placeholder]="'EVENT ACTION' | translate">
            <mat-option></mat-option>
            @for (d of eventActionslist; track d) {
              <mat-option [value]="d">{{ d }}</mat-option>
            }
          </mat-select>
        </p>
        <p>
          <mat-checkbox color="primary" formControlName="enabled" class="full-width" checked name="enabled"><span>{{ "ENABLED" | translate }}</span></mat-checkbox>
        </p>
        <p>
          <mat-checkbox color="primary" formControlName="use_previous_value" class="full-width" name="use_previous_value"><span>{{ "USE PREVIOUS VALUE" | translate }}</span></mat-checkbox>
        </p>
        <p>
          <mat-checkbox color="primary" formControlName="required" class="full-width" name="required"><span>{{ "REQUIRED" | translate }}</span></mat-checkbox>
        </p>
      </mat-dialog-content>
      <app-dialog-actions [mode]="mode" [formCheck]="timeFieldForm"></app-dialog-actions>
    </form>
  </div>
</div>
