import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/share/user';
import { MatDialogActions, MatDialogContent, MatDialogModule } from '@angular/material/dialog';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';

export interface Device {
  device_id: string;
  device_name: string;
}

const ELEMENT_DATA: Device[] = [];

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatDialogContent,
    MatTableModule,
    MatCheckboxModule,
    MatDialogActions,
    MatDialogModule,
    MatButtonModule,
  ],
  selector: 'app-assignmarxactdevices',
  templateUrl: './assignmarxactdevices.component.html',
  styleUrls: ['./assignmarxactdevices.component.scss', '../dialog-btns.component.scss']
})
export class AssignmarxactdevicesComponent implements OnInit {
  user: User;
  displayedColumns: string[] = ['select', 'device_name', 'device_id'];

  dataSource = new MatTableDataSource<Device>(ELEMENT_DATA);
  selection = new SelectionModel<Device>(true, []);
  searchValue: string;

  constructor() { }

  ngOnInit() {
  }

  assignAndOrDeassignDevices() {

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.map(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Device): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.device_name + 1}`;
  }

}
