import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { getAdminToken, getUser } from 'src/app/share/utils';
import { Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';
import $ from 'jquery';
import { MatDividerModule } from '@angular/material/divider';
import { McUsersPageComponent } from '../mc-users-page/mc-users-page.component';
import { ResetPasswordPageComponent } from '../reset-password-page/reset-password-page.component';
import { AdminInfoPageComponent } from '../admin-info-page/admin-info-page.component';
import { CommonModule } from '@angular/common';
import {DataService} from '../../services/data.service';

export const USER_INFORMATION   = 'USER_INFORMATION';
export const RESET_PASSWORD     = 'RESET_PASSWORD';
export const MANAGE_MEMBER      = 'MANAGE_MEMBER';
export const MANAGE_USER        = 'MANAGE_USER';

@Component({
  standalone: true,
  imports: [TranslateModule, 
    MatDividerModule,
    McUsersPageComponent,
    ResetPasswordPageComponent,
    AdminInfoPageComponent,
    CommonModule,
  ],
  selector: 'app-mcadmin-page',
  templateUrl: './mcadmin-page.component.html',
  styleUrls: ['./mcadmin-page.component.scss']
})
export class McadminPageComponent implements OnInit {
  showUsersPage: boolean;
  showResetPasswordPage: boolean;
  showAdminInfoPage: boolean;
  hasManagedUsers: boolean;
  selectedMenu: string = USER_INFORMATION;

  constructor(
    private route: Router,
    public store: StoreService,
    public dataService: DataService,
  ) {}

  ngOnInit() {
    const token = getAdminToken();
    if (token === null || token === undefined) {
      this.store.token = null;
      this.route.navigate(['/login']);
      return;
    }
    const admin = getUser();
    this.store.hideProjectsContainer = true;
    this.store.hideTemplatesContainer = true;
    this.store.hideProjects();
    if (admin.admin_type === 'super_user' || admin.admin_type === 'system_administrator') {
      if (admin.admin_type === 'system_administrator') {
        this.store.isMCAdmin = true;
      }
      this.hasManagedUsers = true;
      this.selectedMenu = MANAGE_USER;
      this.displayAdminUsers();
    } else {
      this.hasManagedUsers = false;
      this.selectedMenu = USER_INFORMATION;
      this.displayAdminInfo();
    }
  }

  displayResetPassword() {
    this.showUsersPage = false;
    this.showResetPasswordPage = true;
    this.handleClick(RESET_PASSWORD);
    this.showAdminInfoPage = false;
  }

  displayAdminInfo() {
    this.showUsersPage = false;
    this.handleClick(USER_INFORMATION);
    this.showResetPasswordPage = false;
    this.showAdminInfoPage = true;
  }

  displayAdminUsers() {
    this.showUsersPage = true;
    this.handleClick(MANAGE_USER);
    this.showResetPasswordPage = false;
    this.showAdminInfoPage = false;
  }

  handleClick(activeMenu) {
    const $items = $('.menu');
    const classHighlight = "selected-admin";
    $items.removeClass(classHighlight);
    this.selectedMenu = activeMenu;
  }

  displayMyCumulusCustomer() {

  }

  hideResetPassword(event) {

  }
}
