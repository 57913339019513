import { TranslateModule } from '@ngx-translate/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatDialogModule,
    MatButtonModule,
  ],
  selector: 'app-import-error-dlg',
  templateUrl: './import-error-dlg.component.html',
  styleUrls: ['./import-error-dlg.component.scss', '../dialog-btns.component.scss']
})
export class ImportErrorDlgComponent implements OnInit {

  error: any[];
  constructor(
    private dialogRef: MatDialogRef<ImportErrorDlgComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    ) {
      if (data) {
        this.error = data.error;
      }
    }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
