import { TranslateModule } from '@ngx-translate/core';
import {Component, OnInit, Input, ViewChild, Output, EventEmitter, Inject, HostListener} from '@angular/core';
import { Project } from 'src/app/share/projects';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { User } from 'src/app/share/user';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { getAdminToken, getToken, equalsIgnoringCase, getUser } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { SysAdminUserServiceService } from 'src/app/services/sys-admin-user-service.service';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import {DialogActionsComponent} from '../dialog-actions/dialog-actions.component';
@Component({
  standalone: true,
  imports: [TranslateModule,
    MessageBoxComponent,
    ReactiveFormsModule,
    MatDialogContent,
    MatRadioButton,
    MatOptionModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogActions,
    MatSelectModule,
    MatDialogModule,
    MatButtonModule,
    DialogActionsComponent, MatRadioGroup,
  ],
  selector: 'app-import-project-from-domain',
  templateUrl: './import-project-from-domain.component.html',
  styleUrls: ['./import-project-from-domain.component.scss', '../dialog-btns.component.scss']
})
export class ImportProjectFromDomainComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  @Input() project: Project;
  projectDialogForm: FormGroup;

  project_id: string;
  user_id: string;
  project_class: string;
  name: string;
  description: string;
  projects: Project[];
  users: User[];
  visibility: string = "public";
  @Output() invalidIconDropped = new EventEmitter<boolean>();
  @Input() user: User;
  @Input() mode = {
    text: 'Create',
    function: 'Create'
  };
  @ViewChild('pjtIcon') img;
  @ViewChild('fform') projectFormDirective;

  metadata: any;
  moreUsers: any;

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;

  constructor(
    private store: StoreService,
    private fb: FormBuilder,
    private mcUserService: SysAdminUserServiceService,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ImportProjectFromDomainComponent>,
    public toastService: ToastService,
  ) {
    this.projects = [];
    this.users = [];
    this.visibility = "public";
    this.project_class = this.store.proClass;
    this.createForm();
  }

  ngOnInit() {
    this.getProjectOnVisibilityChange();
  }

  onCancel(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close();
  }

  createForm() {
    this.projectDialogForm = this.fb.group({
      visibility: this.visibility,
      project_id: ['', Validators.required],
      name: "",
      description: ''
    });
  }

  onSubmit() {

    const temp = this.projectDialogForm.value;
    const projectId = temp['project_id'];
    let selectedProject = {
      name: '',
      description: ''

    };
    if(projectId !== null && projectId !== undefined) {
      selectedProject = this.projects.find(p => p.id == projectId);
    }
    const token = getToken();
    const proToken = {
      'key': 'key',
      'value': token.key
    }
    const user = getUser();
    const project = {
      user_id: user.id,
      project_id: temp.project_id,
      name: temp.name,
      description: temp.description
    };
    this.store.showLoading();
    this.dataService.copyProjectFromTemplate(project, proToken).subscribe(
      res => {

        this.dataService.createProjectLocally(res);
        this.dataService.templates.next([]);
        this.dataService.showTemplates();
        this.dialogRef.close(res);
        this.store.hideLoading();
      },
      errmess => {
        this.toastService.errorToast(errmess);
        this.store.hideLoading();
    });
  }

  getProjectOnVisibilityChange(obj = null) {

    this.visibility = obj !== null ? obj.value : "public";

    if(this.visibility === null || this.visibility === undefined) {
      this.visibility = "private";
    }
    let isMyCumulusAdministrator = false;
    let token = getToken();
    let proToken = {
      key: 'X-Auth-Token',
      value: token.key
    };
    const proClass = "template";
    if(token === null || token === undefined) {
      token = getAdminToken();
      if(token !== null && token !== undefined) {
        isMyCumulusAdministrator = true;
        proToken = {
          key: 'X-Admin-Auth-Token',
          value: token.key
        };
      }
    }

    const archived = false;
    this.store.showLoading();
    this.dataService.getProjects(proToken, proClass, this.visibility, archived, isMyCumulusAdministrator).subscribe(
      res => {
        if(res !== null) {
          this.projects = res.sort(
            (a, b) => {
              return equalsIgnoringCase(a.name, b.name);
            });
        }
        this.store.hideLoading();
      },
      errmess => {
        this.toastService.errorToast(errmess);
        this.store.hideLoading();
    });
  }

  getProjectOnChange(obj) {

    const projectId = obj.value;
    const customerId = null;
    if(projectId !== null && projectId !== undefined) {
      const project = this.projects.find(p => p.id == projectId);
      if(project !== null && project !== undefined) {
        this.name = project.name;
        this.description = project.description;
        this.projectDialogForm.controls.name.setValue(project.name);
        this.projectDialogForm.controls.description.setValue(project.description);
      }
    }
  }

  displayUsers(res){
    this.metadata = res.metadata;
    if(this.metadata.uris.next !== null && this.metadata.uris.next !== undefined){
      this.moreUsers = false;
    } else {
      this.moreUsers = true;
    }
    for (const collaborator of  res.instances) {
      if (collaborator.managed_users_ids !== null && collaborator.managed_users_ids !== undefined) {
        this.users.push(collaborator);
      }
    }
    this.users = this.users.sort(
      (a, b) => {
        return equalsIgnoringCase(a.name, b.name);
      });
    this.store.hideLoading();
  }

  loadUsers(uri){

    const token = getAdminToken();
    this.store.showLoading();
    this.mcUserService.getAdminUsers(token, uri).subscribe(
      res => {
        this.displayUsers(res);

        if(this.moreUsers === false)
          this.loadMoreUsers();
      },
      err => {
        this.toastService.errorToast(err);
        this.store.hideLoading();
      });
  }

  loadMoreUsers(){
    if(this.metadata !== null && this.metadata !== undefined){
      const uris = this.metadata.uris;

      if(uris.next !== null && uris.next !== undefined){
        let linkValue = uris.next;
        for (let i = 0; i < 4; i++) {
          linkValue = linkValue.substring(1);
        }

        this.loadUsers(linkValue);
      }
    }
  }

}
