<div fxFlexLayout="row">
  <div fxFlex fxFlexOffset="20px">
    <h3><span>{{ "COPY PROJECT TO PROJECT TEMPLATE" | translate }}</span></h3>
    <p>The selected project will be copied into a new Project Template.</p>

    @if (showMessage) {
      <app-message-box #messageBox message="{{message}}"></app-message-box>
    }
    <form novalidate [formGroup]="projectDialogForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      <mat-dialog-content>
        <p>
          <mat-form-field class="full-width">
            <input matInput autocomplete="off" formControlName="name" value="{{name}}" [placeholder]="'NAME' | translate" type="text" required>
            @if (projectDialogForm.get('name').hasError('required') && projectDialogForm.get('name').touched) {
              <mat-error><span>{{ "PROJECT NAME IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="full-width">
            <input matInput autocomplete="off" value="{{description}}" formControlName="description" [placeholder]="'DESCRIPTION' | translate" type="text">
          </mat-form-field>
        </p>

        <p>
          <mat-checkbox  (change)="deleteProjectAfter($event)"
          (click)="$event.stopPropagation()" color="primary" class="full-width"><span>{{ "DELETE ORIGINAL PROJECT" | translate }}</span></mat-checkbox>
        </p>
      </mat-dialog-content>
      <app-dialog-actions [mode]="mode" [formCheck]="projectDialogForm"></app-dialog-actions>
    </form>
  </div>
</div>
