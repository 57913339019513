import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Component, OnInit, HostListener, ElementRef, ViewChild, Renderer2, OnDestroy} from '@angular/core';
import { Project } from 'src/app/share/projects';
import { DataService, PROJECTS, ProjectToken } from 'src/app/services/data.service';
import { Token } from 'src/app/share/token';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { getToken,
  isTokenValid, logoutUser,
  isCollaborator,
  removeFieldToShowOnMap,
  removeSearchValue, removeCurrentFeaturesUri,
  getAdminToken,
  getProject} from 'src/app/share/utils';
import { Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';
import { ProjectItemComponent } from '../project-item/project-item.component';
import { TemplatesComponent } from '../templates/templates.component';
import { TemplateSchemaComponent } from '../schema/template-schema/template-schema.component';
import { FeatureDataComponent } from '../feature-data/feature-data.component';
import { RecordDataFormComponent } from '../record/record-data-form/record-data-form.component';
import { ImportFileDialogComponent } from '../dialogs/import-file-dialog/import-file-dialog.component';
import { ImportImklFileComponent } from '../dialogs/import-imkl-file/import-imkl-file.component';
import { ImportExcelFileComponent } from '../dialogs/import-excel-file/import-excel-file.component';
import {FeatureMultipleFormDataMapComponent} from '../feature-multiple-form-data-map/feature-multiple-form-data-map.component';
import {ProjectComponent} from '../project/project.component';
import { ImportShapeFileComponent } from '../dialogs/import-shape-file/import-shape-file.component';
import {ReportsService} from '../../services/reports.service';
import {UnsavedChangesDialogComponent} from '../dialogs/unsaved-changes-dialog/unsaved-changes-dialog.component';
import {Subject, Subscription, takeUntil} from 'rxjs';

@Component({
  standalone: true,
  imports: [TranslateModule,
    ProjectItemComponent,
    TemplatesComponent,
    TemplateSchemaComponent,
    FeatureDataComponent,
    RecordDataFormComponent,
    ImportFileDialogComponent,
    ImportImklFileComponent,
    ImportExcelFileComponent,
    ImportShapeFileComponent,
    FeatureMultipleFormDataMapComponent,
    ProjectComponent,
  ],
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy {
  updatingForm: boolean;
  message = ' ';
  currentProject: Project;
  fieldset;
  selectedProject: string;
  scrHeight: any;
  scrWidth: any;
  proToken: ProjectToken;
  visibility: string = null;
  archived: boolean = false;

  constructor(
    public store: StoreService,
    private router: Router,
    public dataService: DataService,
    private dialog: MatDialog,
    private reportsService: ReportsService,
    private translate: TranslateService,
    private renderer: Renderer2,
    ) {
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight - 127;
    this.scrWidth = window.innerWidth;
  }

  private projectSubscription: Subscription;
  private collabSubscription: Subscription;

  private destroy$ = new Subject<void>();

  ngOnDestroy() {
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
    if(this.collabSubscription) {
      this.collabSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.getScreenSize();
    this.dataService.readProjects([]);
    this.currentProject = this.dataService.project;
    this.selectedProject = 'nothing';

      this.projectSubscription = this.store.visibleProject.pipe(takeUntil(this.destroy$)).subscribe(pc => {
        this.dataService.readTemplates([]);
        if(isCollaborator(this.store.user) ) {
          this.getCollaboratorProjects();
        } else {
          this.getProjectOnProClassChange();
          this.dataService.readTemplates([]);
        }
      });

    this.store.showOrHideProjectsContainer(false);
    this.store.showOrHideTemplatesContainer(false);
  }

  getCollaboratorProjects() {
    const token: Token = getToken();
    this.dataService.proClass = this.store.proClass;
    this.visibility = this.store.visibility;
    this.archived = this.store.archived;
    this.store.showLoading();
    this.collabSubscription = this.dataService.getCollaboratorProjects(token, this.dataService.proClass, this.visibility, this.archived).pipe(takeUntil(this.destroy$))
      .subscribe({
      next: res => this.handleUpdateResponse(res, token, null),
      error: errmess => this.handleError(errmess)
    });
  }

  handleUpdateResponse(res, token, user?) {
    let p = null;
    if (this.dataService.proClass === 'template' || this.dataService.proClass === 'domain') {
      let filteredList;
      if(user !== null)
        filteredList = this.filterProjects(user.id, res);
      else
        filteredList = this.filterProjects(token.user_id, res);
      this.dataService.readProjects(filteredList);
    } else {
      this.dataService.readProjects(res);
      if(res.length !== 0) {
        //If we have an id stored, and the db list contains it, we select the result,
        //else, ignore what is persisted and pick the first item from the db list.
        if(getProject() && res.some(x => x.id === getProject())) {
          p = res.find(x => x.id === getProject());
        } else {
          p = res[0]
        }
      }
    }
    this.store.show_Projects();
    this.store.showOrHideProjectsContainer(false);
    this.setProject(p ? p : res[0]);
  }

  handleError(errmess) {
    this.showMessageDialog(errmess);
    this.dataService.readProjects(PROJECTS);
    this.store.hideLoading();
  }

  getProjectOnProClassChange() {
    let token: Token = getToken();
    this.store.showLoading();
    let isUserOrMCAdminConnected;
    let isMyCumulusAdministrator = false;

    this.dataService.proClass = this.store.proClass;
    this.visibility = this.store.visibility;
    this.archived = this.store.archived;
    if (token === null || token === undefined) {
      token = getAdminToken();
      if (token === null || token === undefined) {
        isUserOrMCAdminConnected = false
      }  else {
        if(isTokenValid(token)) {
          isUserOrMCAdminConnected = true;
          isMyCumulusAdministrator = true;
          this.dataService.hideTemplate();
        } else {
          isUserOrMCAdminConnected = false
        }
      }
    } else {
      if(isTokenValid(token)) {
        isUserOrMCAdminConnected = true;
      } else {
        isUserOrMCAdminConnected = false
      }
    }

    if (!isUserOrMCAdminConnected) {
      logoutUser();
      this.store.logout();
      this.store.token = null;
      this.store.hideLoading();
      this.store.hideProjects();
      this.showMessageDialog('You activation token is expired or you are not connected');
      this.router.navigate(['/login']);
      return;
    }
    const user = this.store.user;
    this.proToken = {
      key: 'X-Auth-Token',
      value: token.key
    };
    this.projectSubscription = this.dataService.getProjects(this.proToken, this.dataService.proClass, this.visibility, this.archived, isMyCumulusAdministrator).pipe(takeUntil(this.destroy$))
      .subscribe({
      next: res => this.handleUpdateResponse(res, token, user),
      error: errmess => this.handleError(errmess)
    });
  }

  filterProjects(userId: string, res : Project[]){
    return res.filter(p => p.created_by === userId);
  }

  showMessageDialog(msg) {
    this.message = msg;
    this.store.showMessage = true;
    setTimeout(() => {
      this.store.showMessage = false;
    }, (10000));
  }

  setProject(p) {
    const templ = this.dataService?.template.getValue();
    if (templ && !templ.form) {
      const message = `The form "${templ.name}" should contain at least one field. \nContinue?`;
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: {
          title: 'MyCumulus',
          message
        }
      });

      dialogRef.afterClosed().subscribe(result  => {
        if (result) {
          this.openTemplatePage(p);
        } else {
          const olp = this.dataService.project;
          this.store.selectedProject.next(olp.name);
        }
      });
    } else {
      this.updatingForm = this.store.updatedForm;
      if (this.updatingForm) {
        if (templ === null || templ === undefined) {
          // const mes = `Do you really want to stop import of CSV?`;
          const dlgRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '400px',
            data: {
              title: this.translate.instant("CANCEL IMPORT?"),
              message: ''
            }
          });
          dlgRef.afterClosed().subscribe(result  => {
            if (result) {
              this.store.updatedForm = false;
              this.store.changeFormUpdatingState(false);
              this.openTemplatePage(p);
            } else {
              return;
            }
          });
          return;
        } else {
          const dialogRef = this.dialog.open(UnsavedChangesDialogComponent, {
            data: {
              message: this.translate.instant("SOME CHANGES TO THE FORM") + " " + templ.name + " " + this.translate.instant("HAVE BEEN MADE") + ". \n" + this.translate.instant("DO YOU WANT TO CONTINUE WITHOUT SAVING THE CHANGES?"),
              title: this.translate.instant('SAVE CHANGES?')
            }
          });
          dialogRef.afterClosed().subscribe(result  => {
            if (result) {

              this.store.updatedForm = false;
              this.store.changeFormUpdatingState(false);
              this.openTemplatePage(p);
            } else {
              const olp = this.dataService.project;
              this.store.selectedProject.next(olp.name);
            }
          });
        }
      } else {
        this.openTemplatePage(p);
      }
    }
  }

  openTemplatePage(p) {
    removeFieldToShowOnMap();
    this.reportsService.lastExportedTemplate = null;
    removeSearchValue();
    removeCurrentFeaturesUri();
    this.store.features = null;
    this.currentProject = p;
    this.dataService.readProject(p);
    this.dataService.template.next(null);
    this.dataService.selectedTemplate.next('');
    this.store.selectedFieldSet.next('');
    // this.dataService.hideTemplate();
    if(p !== null && p !== undefined) {
      this.dataService.getTemplates(p);
      this.dataService.showTemplates();
    } else {
      this.dataService.hideTemplate();
    }
  }

  imklFileImported(a){
    const project = this.dataService.project;
    if(project !== null && project !== undefined) {
      this.dataService.getTemplates(project);
      this.dataService.showTemplates();
    } else {
      this.dataService.hideTemplate();
    }
  }

  @ViewChild('leftSide', { static: false }) leftSide: ElementRef;
  @ViewChild('rightSide', { static: false }) rightSide: ElementRef;

  isResizing = false;

  ngAfterViewInit(): void {}

  startResizing(event: MouseEvent): void {
    this.isResizing = true;
    document.body.style.cursor = 'ew-resize';
    event.preventDefault(); // Prevent default behavior

    // Disable text selection
    this.renderer.setStyle(document.body, 'user-select', 'none');
  }

  stopResizing(): void {
    this.isResizing = false;
    document.body.style.cursor = 'default';

    // Re-enable text selection
    this.renderer.removeStyle(document.body, 'user-select');
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (!this.isResizing) return;

    const containerWidth = this.leftSide.nativeElement.parentElement.clientWidth;
    const newLeftWidth = (event.clientX / containerWidth) * 100;

    // Limit resizing between 5% and 50% of the container width (also limited in CSS)
    if (newLeftWidth >= 5 && newLeftWidth <= 50) {
      this.leftSide.nativeElement.style.flexBasis = `${newLeftWidth}%`;
      this.rightSide.nativeElement.style.flexBasis = `${100 - newLeftWidth}%`;
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(): void {
    if (this.isResizing) {
      this.stopResizing();
    }
  }
}
