<div id="chooseGeometryListPage"  class="dialog-content" fxLayout="column" fxLayoutGap="2px">
    <div fxFlex="10">
        <h4 class="pb-1">
            Project: {{ dataService?.project.name }}
        </h4>
        <h5><span>{{ "SHOW FORMS" | translate }}</span></h5>
    </div>

    <div class="ml-2" fxFlex="90">
        <mat-dialog-content>
            <table mat-table [dataSource]="dataSource" class="table table-striped table-bordered">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th *matHeaderCellDef>
                        <mat-checkbox color="primary"
                        (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()"><span>{{ "SELECT ALL" | translate }}</span></mat-checkbox>

                    </th>
                    <td *matCellDef="let row">
                        <mat-checkbox color="primary"
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Field Label Column -->
                <ng-container matColumnDef="label">
                    <th *matHeaderCellDef><span>{{ "FORM" | translate }}</span></th>
                    <td *matCellDef="let element"> {{element.label}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>
        </mat-dialog-content>
        <mat-dialog-actions class="button-group">
            <span class="flex-spacer"></span>
            <button mat-button type="submit" (click)="submit()" class="btn submit-btn"><span>{{ "OK" | translate }}</span></button>
            <button mat-button class="btn cancel-btn" mat-dialog-close><span>{{ "CANCEL" | translate }}</span></button>
        </mat-dialog-actions>
    </div>
</div>
