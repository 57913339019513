<div fxFlexLayout="row">
  <div fxFlex fxFlexOffset="20px">
    <h3 class="pb-3">{{ mode.text }} {{ "AUTO INCREMENT FIELD" | translate }}</h3>
    <form novalidate [formGroup]="autoIncrementFieldForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      @if (showMessage) {
        <app-message-box style="margin-left: -10px;" #messageBox message="{{message}}"></app-message-box>
      }
      @if (nameLengthExceed10) {
        <div class="alert alert-warning" role="alert">
          {{"NAME_ADVICE_FOR_SHAPE_FILE_1" | translate }} <br />
          {{"NAME_ADVICE_FOR_SHAPE_FILE_2" | translate }}
        </div>
      }
      <mat-dialog-content>
        <p>
          <mat-form-field class="full-width">
            <mat-label><span>{{ "LABEL" | translate }}</span></mat-label>
            <input matInput autocomplete="off" formControlName="label" (keyup)="onLabelChange();" [placeholder]="'LABEL' | translate" type="text" required>
            @if (autoIncrementFieldForm.get('label').hasError('required') && autoIncrementFieldForm.get('label').touched) {
              <mat-error><span>{{ "FIELD LABEL IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="full-width">
            <mat-label><span>{{ "NAME" | translate }}</span></mat-label>
            <input matInput autocomplete="off" formControlName="name"  (keyup)="onNameChange();" [placeholder]="'NAME' | translate" type="text" required>
            @if (autoIncrementFieldForm.get('name').hasError('required') && autoIncrementFieldForm.get('name').touched) {
              <mat-error><span>{{ "FIELD NAME IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
          <small style="margin-top: -5px;" class="form-text text-muted ">{{ "THE NAME IS USED BY MYCUMULUS" | translate }}</small>
        </p>

        <p>
          <mat-form-field class="full-width">
            <mat-label><span>{{ "DESCRIPTION" | translate }}</span></mat-label>
            <input matInput autocomplete="off" formControlName="description" [placeholder]="'DESCRIPTION' | translate" type="text">
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="full-width">
            <mat-label><span>{{ "INITIAL VALUE" | translate }}</span></mat-label>
            <input matInput autocomplete="off" formControlName="initial_value" [placeholder]="'INITIAL VALUE' | translate" type="number" required>
            @if (autoIncrementFieldForm.get('initial_value').hasError('required') && autoIncrementFieldForm.get('initial_value').touched) {
              <mat-error><span>{{ "FIELD LABEL IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="full-width">
            <mat-label><span>{{ "STEP" | translate }}</span></mat-label>
            <input matInput autocomplete="off" formControlName="step" [placeholder]="'STEP' | translate" type="number" required>
            @if (autoIncrementFieldForm.get('step').hasError('required') && autoIncrementFieldForm.get('step').touched) {
              <mat-error><span>{{ "FIELD LABEL IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
        </p>
        <p>
          <mat-checkbox color="primary" formControlName="enabled" class="full-width" checked name="enabled"><span>{{ "ENABLED" | translate }}</span></mat-checkbox>
        </p>
        <p>
          <mat-checkbox color="primary"  formControlName="show_value_on_map" class="full-width" name="show_value_on_map">{{ "SHOW VALUE ON MAP" | translate }}</mat-checkbox>
        </p>
      </mat-dialog-content>
      <app-dialog-actions [mode]="mode" [formCheck]="autoIncrementFieldForm"></app-dialog-actions>
    </form>
  </div>

</div>
