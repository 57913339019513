<div class="admin-page">
  <div id="admin-container-div" class="admin-container">
    <div class="content-row">
      <div id="adminMenuSide" class="admin-menu">
        <div class="sidebar-templates"><span>{{ "REPORT TEMPLATES" | translate }}</span></div>
        <ul id="navAdmin">
        </ul>
      </div>
      <div class="vertical-divider"></div>
      <div id="adminContent" class="admin-content">
        <div id="userspage" class="users-page">
          <div class="search-section">
            <div class="search-row">
              <span class="search-input">

              </span>
              <span class="add-button">
<!--                <button  mat-button (click)="openCreateReportTemplateDialog()" mat-flat-button color="primary" class="btn-border"><span>{{ "ADD" | translate }}</span></button>-->
              </span>
            </div>
          </div>
          <div class="table-section">
            <div class="table-row">
              <table mat-table [dataSource]="dataSource" class="table table-striped table-bordered">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th *matHeaderCellDef style="padding-left: 10px !important;"><span>{{ "NAME" | translate }}</span></th>
                  <td *matCellDef="let element" class="table-cells"> {{element.name}} </td>
                </ng-container>

                <!-- Created date Column -->
                <ng-container matColumnDef="created_at" style="width: 250px !important;">
                  <th *matHeaderCellDef  style="width: 250px !important; padding-left: 10px !important;">
                    <span class="ml-2"><span>{{ "DATE" | translate }}</span></span>
                  </th>
                  <td *matCellDef="let element" class="action-link table-cells">
                    <span class="ml-2">
                      {{element.created_at |  date: 'yyyy-MM-dd'}}
                    </span>
                  </td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                  <th *matHeaderCellDef style="width: 200px !important; padding-left: 10px !important;">
                    <span class="ml-2"><span>{{ "ACTIONS" | translate }}</span></span>
                  </th>
                  <td *matCellDef="let element" class="action-link table-cells" style="width: 200px !important; padding-left: 10px !important;">
                    <button mat-button class="btn-border ml-2 buttons" (click)="openUpdateReportTemplateDialog(element)">
                      <fa-icon [icon]="faPenToSquare"></fa-icon>
                    </button>
                    <button class="btn-border ml-2 buttons" mat-button (click)="OpenDeleteReportTemplateConfirmation(element)">
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

