<mat-toolbar #toolbar class="app-toolbar">
  <div class="top-bar"></div>
  <nav class="toolbar-nav" [ngClass]="!store?.token ? 'space-between' : ''">
    @if(!store?.token)
    {
      <a href="#" class="logo-link" [routerLink]="['/login']" routerLinkActive="router-link-active">
        <img src="/assets/images/logo-mycumulus-color.svg" class="logo-img"/>
      </a>
    } @else
    {
      <a href="#" class="logo-link" (click)="reload($event)" routerLinkActive="router-link-active">
        <img src="/assets/images/logo-mycumulus-color.svg" class="logo-img"/>
      </a>
    }
    @if(!store?.token) {
      <div class="header-divider"></div>
      <a href="https://play.google.com/store/apps/details?id=com.mycumulus&hl=nl&pli=1" target="_blank" class="playstore-logo">
        <p><span>{{ "MOBILE APP ON" | translate }}</span><img src="/assets/images/google-play-logo.png" class="logo-img"/></p>
      </a>
    }
    @if (store?.token) {
    <span class="menu-btn">
      <button id="projMenu" class="btn-space" mat-button [matMenuTriggerFor]="projectmenu">
        <span>{{ "PROJECTS" | translate }}</span>
        <fa-icon [icon]="faCaretDown"></fa-icon>
      </button>
      <button class="header-btn" id="actmenu" mat-button [matMenuTriggerFor]="actionmenu">
        <span>{{ "ACTIONS" | translate }}</span>
        <fa-icon [icon]="faCaretDown"></fa-icon>
      </button>
      <button class="header-btn" id="cpypastmenu" mat-button [matMenuTriggerFor]="copyandpastemenu">
        <span>{{ "EDIT" | translate }}</span>
        <fa-icon [icon]="faCaretDown"></fa-icon>
      </button>
      <button class="header-btn" [disabled]="proClass !== 'Projects' || !isProjectsPage()" id="expMenu" mat-button [matMenuTriggerFor]="exportmenu">
        <span>{{ "EXPORT" | translate }}</span>
        <fa-icon [icon]="faCaretDown"></fa-icon>
      </button>
      <button class="import-header header-btn" [disabled]="getUser().id !== dataService.project?.created_by || proClass !== 'Projects' || !isProjectsPage()" id="impMenu" mat-button [matMenuTriggerFor]="importmenu">
        <span>{{ "IMPORT" | translate }}</span>
        <fa-icon [icon]="faCaretDown"></fa-icon>
      </button>
      <button class="header-btn" id="repMenu" mat-button [matMenuTriggerFor]="reportmenu">
        <span>{{ "REPORTS" | translate }}</span>
        <fa-icon [icon]="faCaretDown"></fa-icon>
      </button>
      <button class="header-btn" id="styMenu" mat-button [matMenuTriggerFor]="stylemenu">
        <span>{{ "STYLES" | translate }}</span>
        <fa-icon [icon]="faCaretDown"></fa-icon>
      </button>
      <mat-menu #projectmenu="matMenu">
        @if (!store?.isMCAdmin) {
          <button (click)="goToProjects()" mat-menu-item>
            <mat-icon>folder_open</mat-icon>
            <span>{{ "PROJECTS" | translate }}</span>
          </button>
          <button (click)="goToUserProjectsTemplate()" mat-menu-item>
            <mat-icon>domain</mat-icon>
            <span>{{ "PROJECT TEMPLATES" | translate }}</span>
          </button>
          <button (click)="goToUserDomains()" mat-menu-item>
            <mat-icon>library_books</mat-icon>
            <span>{{ "DOMAINS" | translate }}</span>
          </button>
          <mat-divider></mat-divider>
          <button (click)="goToArchivedProjects()" mat-menu-item>
            <mat-icon>folder_zip</mat-icon>
            <span>{{ "ARCHIVED PROJECTS" | translate }}</span>
          </button>
          <button (click)="goToUserArchivedProjectsTemplate()" mat-menu-item>
            <mat-icon>domain_disabled</mat-icon>
            <span>{{ "ARCHIVED PROJECT TEMPLATES" | translate }}</span>
          </button>
          <button (click)="goToUserArchivedDomains()" mat-menu-item>
            <mat-icon>archive</mat-icon>
            <span>{{ "ARCHIVED DOMAINS" | translate }}</span>
          </button>
        }
        @if (store?.isMCAdmin) {
          <button (click)="goToProjectsTemplates()" mat-menu-item>
            <mat-icon>domain</mat-icon>
            <span>{{ "PROJECT TEMPLATES" | translate }}</span>
          </button>
          <button (click)="goToProjectsDomains()" mat-menu-item>
            <mat-icon>library_books</mat-icon>
            <span>{{ "DOMAINS" | translate }}</span>
          </button>
          <mat-divider></mat-divider>
          <button (click)="goToArchivedProjectsTemplates()" mat-menu-item>
            <mat-icon>domain_disabled</mat-icon>
            <span>{{ "ARCHIVED PROJECT TEMPLATES" | translate }}</span>
          </button>
          <button (click)="goToArchivedProjectsDomains()" mat-menu-item>
            <mat-icon>archive</mat-icon>
            <span>{{ "ARCHIVED DOMAINS" | translate }}</span>
          </button>
        }
      </mat-menu>
      <mat-menu #copyandpastemenu="matMenu">
        <button class="header-btn" [disabled]="proClass !== 'Projects' || !isProjectsPage()" (click)="copySelectedFeatures()" mat-menu-item>
          <mat-icon>content_copy</mat-icon>
          <span>{{ "COPY" | translate }}</span>
        </button>
        @if (!store?.isMCAdmin) {
        <button class="header-btn" [disabled]="proClass !== 'Projects' || !isProjectsPage()" (click)="pasteCopiedFeatures()" mat-menu-item>
          <mat-icon>content_paste</mat-icon>
          <span>{{ "PASTE" | translate }}</span>
        </button>
        <button class="header-btn" [disabled]="proClass !== 'Projects' || !isProjectsPage()" (click)="deleteSelectedFeatures()" mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>{{ "DELETE" | translate }}</span>
        </button>
        }
        <button class="header-btn" [disabled]="proClass !== 'Projects' || !isProjectsPage()" (click)="duplicateSelectedFeatures()" mat-menu-item>
          <mat-icon>content_copy</mat-icon>
          <span>{{ "DUPLICATE" | translate }}</span>
        </button>
      </mat-menu>
      <mat-menu #exportmenu="matMenu">
        <button (click)="showCustomExport()" mat-menu-item [disabled]="!dataService.selectedTemplate.value">
          <mat-icon>description</mat-icon>
          <span>{{ "CUSTOM EXPORT" | translate }}</span>
        </button>
        <button (click)="showStedinExportDialog()" mat-menu-item [disabled]="!dataService.selectedTemplate.value">
          <mat-icon>description</mat-icon>
          <span>{{ "STEDIN CSV" | translate }}</span>
        </button>
        <button (click)="showEnexisExportDialog()" mat-menu-item>
          <mat-icon>description</mat-icon>
          <span>{{ "ENEXIS TGA" | translate }}</span>
        </button>
      </mat-menu>
       <mat-menu #importmenu="matMenu">
        <button mat-menu-item (click)="showCSVImportPage()">
          <mat-icon>description</mat-icon>
          <span>CSV</span>
        </button>
        <button mat-menu-item (click)="showIMLKImportPage()">
          <mat-icon>description</mat-icon>
          <span>KLIC</span>
        </button>
        <button mat-menu-item (click)="showExcelImportPage()">
          <mat-icon>description</mat-icon>
          <span>Excel</span>
        </button>
        <button mat-menu-item (click)="showShapeImportPage()">
          <mat-icon>description</mat-icon>
          <span>Shape</span>
        </button>
      </mat-menu>
      <mat-menu #reportmenu="matMenu">
        @if (!store?.isMCAdmin) {
        <button (click)="goToReports()" mat-menu-item>
          <mat-icon>dehaze</mat-icon>
          <span>{{ "REPORTS" | translate }}</span>
        </button>
        }
        <!-- <button (click)="goToReportTemplates()" mat-menu-item>
          <mat-icon>wysiwyg</mat-icon>
          <span>{{ "REPORT TEMPLATES" | translate }}</span>
        </button> -->
      </mat-menu>
      <mat-menu #actionmenu="matMenu">
        @if (!inArchivedMode && !store?.isCollaborator) {
        <span>
          @if (this.headerService.currentClass === ActiveClass.PROJECT || this.headerService.currentClass === ActiveClass.APROJECT) {
          <button class="header-btn" [disabled]="!isProjectsPage()" (click)="convertProjectToTemplate()" mat-menu-item>
            <mat-icon>copy_all</mat-icon>
            <span>{{ "COPY TO PROJECT TEMPLATES" | translate }}</span>
          </button>

          <button class="header-btn" [disabled]="!isProjectsPage()" (click)="convertProjectToDomain()" mat-menu-item>
            <mat-icon>copy_all</mat-icon>
            <span>{{ "COPY TO DOMAINS" | translate }}</span>
          </button>
          }
        </span>
        }
        @if (this.headerService.currentClass === ActiveClass.PROJECT || this.headerService.currentClass === ActiveClass.APROJECT) {
        <span>
          @if (!store?.archived) {
          <button class="header-btn" [disabled]="!isProjectsPage()" (click)="archivedProject()" mat-menu-item>
            <mat-icon>archive</mat-icon>
            <span>{{ "ARCHIVE PROJECT" | translate }}</span>
          </button>
          }
          @if (store?.archived) {
          <button class="header-btn" [disabled]="!isProjectsPage()" (click)="unArchivedProject()" mat-menu-item>
            <mat-icon>unarchive</mat-icon>
            <span>{{ "UNARCHIVE PROJECT" | translate }}</span>
          </button>
          }
        </span>
        }
        @if (this.headerService.currentClass === ActiveClass.TEMPLATE || this.headerService.currentClass === ActiveClass.ATEMPLATE) {
        <span>
          @if (!store?.archived) {
          <button class="header-btn" [disabled]="!isProjectsPage()" (click)="archivedProjectTemplate()" mat-menu-item>
            <mat-icon>archive</mat-icon>
            <span>{{ "ARCHIVE PROJECT TEMPLATE" | translate }}</span>
          </button>
          }
          @if (store?.archived) {
          <button class="header-btn" [disabled]="!isProjectsPage()" (click)="unArchivedProject()" mat-menu-item>
            <mat-icon>unarchive</mat-icon>
            <span>{{ "UNARCHIVE PROJECT TEMPLATE" | translate }}</span>
          </button>
          }
        </span>
        }
        @if (this.headerService.currentClass === ActiveClass.DOMAIN || this.headerService.currentClass === ActiveClass.ADOMAIN) {
        <span>
          @if (!store?.archived) {
          <button class="header-btn" [disabled]="!isProjectsPage()" (click)="archivedDomain()" mat-menu-item>
            <mat-icon>archive</mat-icon>
            <span>{{ "ARCHIVE DOMAIN" | translate }}</span>
          </button>
          }
          @if (store?.archived) {
          <button class="header-btn" [disabled]="!isProjectsPage()" (click)="unArchivedProject()" mat-menu-item>
            <mat-icon>unarchive</mat-icon>
            <span>{{ "UNARCHIVE DOMAIN" | translate }}</span>
          </button>
          }
        </span>
        }
      </mat-menu>
      <mat-menu #stylemenu="matMenu">
        <button (click)="goToTemplatestyle()" mat-menu-item>
          <mat-icon>dehaze</mat-icon>
          <span>{{ "STYLES" | translate }}</span>
        </button>
      </mat-menu>
    </span>
    }
    <span class="flex-spacer"></span>
    @if (store?.token) {
    <div class="search-form">
        <input class="global-searchBar" type="text" [placeholder]="'SEARCH...' | translate" #globalSearch matInput [matAutocomplete]="auto" (keydown.enter)="filter($event)">
        <mat-autocomplete hideSingleSelectionIndicator="true" panelWidth="auto" #auto="matAutocomplete">
          <mat-option class="global-option" *ngFor="let result of filteredResults; trackBy: trackByFn" (click)="tempClick(result, globalSearch)" [value]="result">
            {{ result.text }}
          </mat-option>
        </mat-autocomplete>
    </div>

    <span class="user-button">
      <button class="btn-menu username" mat-button [matMenuTriggerFor]="menu">
        <span>{{ store?.user?.name }}</span>
        <img src="/assets/images/user-icon.png" class="user-icon"/>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="goToAccountInfo()" mat-menu-item>
          <mat-icon>perm_identity</mat-icon>
          <span>{{ "ACCOUNT" | translate }}</span>
        </button>
        <button (click)="openCRSSettingPage()" mat-menu-item>
          <mat-icon>settings</mat-icon>
          <span>{{ "SETTINGS" | translate }}</span>
        </button>
        <button (click)="logout()" mat-menu-item>
          <mat-icon>cancel</mat-icon>
          <span>{{ "LOGOUT" | translate }}</span>
        </button>
      </mat-menu>
    </span>

    <img *ngIf="getAtStartup()" [matTooltip]="getApiAURL()" class="non-prod-icon" src="assets/images/server-cog.svg" alt="">
    }

  </nav>
</mat-toolbar>


<ng-template>
  <mat-progress-bar class="progress-bar" mode="indeterminate"></mat-progress-bar>
</ng-template>
