import { TranslateModule } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialogContent, MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { StoreService } from 'src/app/services/store.service';
import { DataService } from 'src/app/services/data.service';
import { Project } from 'src/app/share/projects';
import { Template } from 'src/app/share/template';
import { Token } from 'src/app/share/token';
import { getGeomFormToShow, getToken, persistGeomFormToShow, removeGeomFormToShow } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import {CommonModule} from '@angular/common';

export class Field {
  id: string;
  label: string;
}

const ELEMENT_DATA = [];
@Component({
  standalone: true,
  imports: [TranslateModule,
    MatDialogContent,
    MatCheckboxModule,
    MatTableModule,
    MatDialogActions,
    MatDialogModule,
    MatButtonModule,
    CommonModule
  ],
  selector: 'app-geom-forms-list',
  templateUrl: './geom-forms-list.component.html',
  styleUrls: ['./geom-forms-list.component.scss', '../dialog-btns.component.scss']
})

export class GeomFormsListComponent implements OnInit {
  template: Template;
  templates: Template[] = [];
  displayedColumns: string[] = ['select', 'label'];
  fields: Field[];
  dataSource = new MatTableDataSource<Field>(ELEMENT_DATA);
  selection = new SelectionModel<Field>(true, []);
  searchValue: string;
  private messageBox: MessageBoxComponent;

  constructor(
    public store: StoreService,
    public dataService: DataService,
    private dialogRef: MatDialogRef<GeomFormsListComponent>,
    public toastService: ToastService
  )
  {
      this.templates = this.dataService.templates.getValue();
  }

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;

  ngOnInit() {
    const token = getToken();
    // const project: Project = JSON.parse(localStorage.getItem('project'));
    let project = this.dataService.project;
    this.store.showLoading();
    this.dataService.requestTemplates(token, project.id).subscribe(
      (result) => {
        this.templates = result;
        this.fields = [];
        this.store.showLoading();
        this.constructHeaderList();
        this.dataSource.data = this.fields;
        this.store.hideLoading();
      }, (err) => {
        this.store.hideLoading();
      });
    // this.fields = [];
    // this.store.showLoading();
    // this.constructHeaderList();
    // this.dataSource.data = this.fields;
    // this.store.hideLoading();
  }

  constructHeaderList() {
    const token: Token = JSON.parse(localStorage.getItem('token'));
    const project: Project = this.dataService.project;

    if(this.templates.length === 0) {
      this.store.showLoading();
      this.dataService.requestTemplates(token, project.id).subscribe(
        res => {
          this.templates = res;
          this.dataService.readTemplates(res);
          this.store.hideLoading();
          this.fillDatatable();
        },
        errmess => {
          this.store.hideLoading();
          this.toastService.errorToast(errmess);
      });
    } else {
      this.fillDatatable();
    }
  }

  fillDatatable() {
    const savedfields = getGeomFormToShow();
    const tempsFields = [];
    let index = 0;
    for (const templItem of this.templates) {
      const label = {
        id: templItem['id'],
        label: templItem['name']
      };

      if(savedfields && index < Object.keys(savedfields).length) {
        const choosedTempl = savedfields[templItem['id']];
        if (choosedTempl) {
          index++;
          this.selection.selected.push(label);
          this.selection.toggle(label);
        }
      }
      tempsFields.push(label);
    }
    this.fields = tempsFields;
  }

  submit() {
    const fields = this.selection.selected;
    const labels = [];
    for (const field of fields) {
      labels.push(field['id']);
    }
    const obj = {};
    if(labels && labels.length > 0) {

      for(const templName of labels) {
        obj[templName] = true;
      }
      if(obj) {
        persistGeomFormToShow(obj);
      }
      this.store.changedMultipleformsonmap.next(labels.length);
    } else {
      removeGeomFormToShow();
    }

    this.dialogRef.close(obj);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.map(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Field): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.label + 1}`;
  }
}
