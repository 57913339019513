<div class="container">
  <div class="sub-container">
    <div class="content">
        <div class="content-header" >
          <div class="logo">
            <img width="250px" src="assets/images/logo-mycumulus-color.svg">
          </div>
        </div>

        <div class="divider"></div>
        <div class="content-top">
          <div class="activation-info">
            <h3>Your MyCumulus Account has been activated.</h3>
            <p>
              Welcome to MyCumulus. You can now log in on the MyCumulus website and design your own forms.
              Once you have your own forms you and your collaborators can start collecting data with the MyCumulus Android app available on the Google Play Store.
            <p>
              A project with a few forms has been created as an example, so that you can quickly see the possibilities of MyCumulus. You can start immediately collecting data, experiment with the forms by adding and removing fields, etc.
            </p>
            <p>
              We recommend that you view the demo videos and download the documentation available on the MyCumulus website. <br>
              Discover how to create and edit forms, learn how to collect data, and finally how to view your data on the website, make reports, download data, etc. <br>
            </p>
            <p>
              Enjoy using MyCumulus.
            </p>

            <div class="login-button">
              <a href="#/login"><span>{{ "LOG IN" | translate }}</span></a>
            </div>
          </div>
        </div>
      </div>

  </div>
</div>
