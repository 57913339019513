<div fxFlexLayout="row">
  <div fxFlex fxFlexOffset="20px">
    <h3>{{ mode.text }} {{'style ' | titlecase}}</h3>
    <span style="margin-top: -20px !important;">{{'from JSON' | titlecase}}</span>
    @if (showMessage) {
      <app-message-box #messageBox message="{{message}}"></app-message-box>
    }
    <form novalidate [formGroup]="styleDialogForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      <mat-dialog-content>
        <p>
          <mat-form-field class="full-width">
            <input matInput class="h4" [placeholder]="'FILE TO IMPORT (JSON)' | translate" readonly>
            <input type="file" class="btn btn-small mb-0" id="userFile" (change)="setFileData($event)" accept=".json">
          </mat-form-field>

          @if (noFileIsSelected) {
            <mat-error><span>{{ "FILE IS REQUIRED" | translate }}</span></mat-error>
          }
        </p>
      </mat-dialog-content>
      <mat-dialog-actions class="button-group">
        <span class="flex-spacer"></span>
        <button mat-button mat-dialog-close class="btn-border cancel-btn"><span>{{ "CANCEL" | translate }}</span></button>
        <button mat-button type="submit" class="submit-btn">{{ mode.text }}</button>
      </mat-dialog-actions>
    </form>
  </div>
</div>
