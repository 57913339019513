import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldSet } from 'src/app/share/form/fieldset';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { Template } from 'src/app/share/template';
import { FieldsetDataComponent } from '../fieldset-data/fieldset-data.component';
import {DataService} from '../../../services/data.service';

@Component({
  standalone: true,
  imports: [TranslateModule,
    MessageBoxComponent,
    FieldsetDataComponent,
  ],
  selector: 'app-record-data-form',
  templateUrl: './record-data-form.component.html',
  styleUrls: ['./record-data-form.component.scss']
})
export class RecordDataFormComponent implements OnInit {

  rootFieldset: FieldSet;
  activeTemplate: Template;
  featureData: any;

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  constructor(
    private store: StoreService,
    public dataService: DataService,
  ) { }

  ngOnInit() {
    if(this.store.archived) {
      return;
    }
    this.activeTemplate = this.dataService.template.getValue();
    this.initializeTemplateAndField(this.activeTemplate);
    this.dataService.template.subscribe(templ => {
      // if (templ instanceof Template) {
        this.initializeTemplateAndField(templ);
      // }
    });
  }

  initializeTemplateAndField(template: Template): void {
    this.rootFieldset = new FieldSet();

    this.rootFieldset.fields = this.activeTemplate.form.fields;
    this.rootFieldset.name = '';
    this.rootFieldset.label = this.activeTemplate.name;
  }


  uploadRecord(data: any) {
    this.featureData = data;
    alert(this.featureData);
  }

  closeForm(): void {
    this.dataService.hideTemplate();
  }

}
