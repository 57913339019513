<div fxFlexLayout="row">
  <div fxFlex fxFlexOffset="20px">
    <h3>New Form : from Domain</h3>
    @if (showMessage) {
      <app-message-box #messageBox message="{{message}}"></app-message-box>
    }
    <form novalidate [formGroup]="formDialogForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      <mat-dialog-content>

        <p class="mt-3">
          <mat-radio-group aria-label="Select visibility" formControlName="visibility">
            <mat-radio-button (change)="getDomainsOnVisibilityChange($event)"
            [checked]="true" color="primary" value="public" class="mr-4"><span>{{ "PUBLIC" | translate }}</span></mat-radio-button>
            <mat-radio-button (change)="getDomainsOnVisibilityChange($event)" color="primary" value="private"><span>{{ "PRIVATE" | translate }}</span></mat-radio-button>
          </mat-radio-group>
        </p>
        <p>
          <span class="half-width"><span>{{ "DOMAIN" | translate }}</span></span>
          <mat-select class="ml-3 half-width" formControlName="domain_id" required (selectionChange)="getDomainsOnChange($event)"  [placeholder]="'SELECT DOMAIN' | translate">
            <mat-option></mat-option>
            @for (domain of domains; track domain) {
              <mat-option [value]="domain.id">{{ domain.name }}</mat-option>
            }
          </mat-select>
        </p>
        <p>
          <span class="half-width"><span>{{ "FORM TEMPLATE" | translate }}</span></span>
          <mat-select class="ml-3 half-width" formControlName="form_id" (selectionChange)="getFormOnChange($event)" required  [placeholder]="'SELECT FORM' | translate">
            <mat-option></mat-option>
            @for (form of forms; track form) {
              <mat-option [value]="form.id">{{ form.name }}</mat-option>
            }
          </mat-select>
        </p>
      </mat-dialog-content>
      <app-dialog-actions [mode]="mode" [formCheck]="formDialogForm"></app-dialog-actions>
    </form>
  </div>
</div>
