<div fxLayout="row wrap" fxLayoutAlign="center stretch" class="mt-3" fxLayoutGap="0px">
  <div id="resetPassContainer" fxFlex="40%">
    <mat-card appearance="outlined" class="box mb-3">
      <mat-toolbar id="resetPassHeader" style="padding-left: 0 !important;">
        <mat-card-header>
          <mat-card-title id="resetPassTitle"><strong><span>{{ "RESET PASSWORD" | translate }}</span></strong></mat-card-title>
        </mat-card-header>
      </mat-toolbar>
      @if (showMessage) {
        <app-message-box class="ml-3 mr-3" #messageBox message="{{message}}"></app-message-box>
      }
      @if (initPassSent) {
        <div>
          <mat-card-content>
            <div class=" m-2 panel panel-default">
              <p>An email with instructions how to reset your password has been sent.</p>
              <p class="mt-2">If you do not receive the email within a few minutes, verify the email address you have given and try again.</p>
            </div>
          </mat-card-content>
          <div class="button-group text-right align-items-end mb-2 mr-2">
            <span class="flex-spacer"></span>
            <button class="text-right mb-2  btn-border btn btn-default" mat-button (click)="closePan()"><span>{{ "CLOSE" | translate }}</span></button>
          </div>
        </div>
      }
      @if (!initPassSent) {
        <form id="resetPassForm" novalidate #resetPassForm="ngForm" (ngSubmit)="onSubmit()">
          <mat-card-content>
            <mat-form-field class="full-width">
              <input matInput fxflex autocomplete="off" [placeholder]="'ENTER YOUR EMAIL ADDRESS TO RESET PASSWORD' | translate" type="email" [(ngModel)]="user.email" name="email" #email="ngModel" required>
              @if (email.errors?.required) {
                <mat-error><span>{{ "THE EMAIL IS REQUIRES" | translate }}</span></mat-error>
              }
            </mat-form-field>
          </mat-card-content>
          <button mat-button id="submitBtn" type="submit" class="text-floral-white mr-3" [disabled]="resetPassForm.form.invalid"><span>{{ "SEND RESET EMAIL" | translate }}</span></button>
          <button mat-button (click)="cancelResetPassword()" class="btn btn-default btn-border"><span>{{ "CANCEL" | translate }}</span></button>
        </form>
      }
    </mat-card>
  </div>
</div>
