<div id="content" fxFlexLayout="row"  class="dialog-content">
  <div fxFlex fxFlexOffset="5px">
    <h3 id="title"><span>{{ "ADD NEW USER" | translate }}</span></h3>
    <form novalidate [formGroup]="addCollaboratorForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      <mat-dialog-content>
        <p>
          <mat-form-field class="full-width">
            <input matInput autocomplete="off" formControlName="name" [placeholder]="'NAME' | translate" type="text" required>
            @if (addCollaboratorForm.get('name').hasError('required') && addCollaboratorForm.get('name').touched) {
              <mat-error><span>{{ "USER NAME IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="full-width">
            <input matInput autocomplete="off" formControlName="email" placeholder="email@example.com" type="email" required>
            @if (addCollaboratorForm.get('email').hasError('required') && addCollaboratorForm.get('email').touched) {
              <mat-error><span>{{ "EMAIL IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="full-width">
            <input matInput autocomplete="off" formControlName="password" [placeholder]="'PASSWORD' | translate" type="password" required>
            @if (addCollaboratorForm.get('password').hasError('required') && addCollaboratorForm.get('password').touched) {
              <mat-error><span>{{ "PASSWORD IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
        </p>
        <!-- <p>
        <mat-checkbox *ngIf="isAdminUser" color="primary" formControlName="may_create_projects" class="full-width" checked name="may_create_projects"><span>{{ "USER MAY CREATE PROJECTS" | translate }}</span></mat-checkbox>
      </p> -->
    </mat-dialog-content>
    <mat-dialog-actions class="button-group">
      <span class="flex-spacer"></span>
      <button mat-button class="btn cancel-btn" mat-dialog-close  ><span>{{ "CANCEL" | translate }}</span></button>
      <button mat-button type="submit" class="btn submit-btn" [disabled]="addCollaboratorForm.invalid"><span>{{ "ADD" | translate }}</span></button>
    </mat-dialog-actions>
  </form>
</div>
</div>
