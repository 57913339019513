import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root', // Ensure this service is available globally
})
export class TranslationService {
  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
  ) {
    const chosenLang = cookieService.get('appLang');
    if(chosenLang) {
      this.translate.use(chosenLang);
      return;
    }

    const browserLanguage = navigator.language;
    if(browserLanguage.includes('nl')) {
      this.translate.setDefaultLang('nl');
      return;
    } else if(browserLanguage.includes('fr')) {
      this.translate.setDefaultLang('fr');
      return;
    } else {
      this.translate.setDefaultLang('en');
      return;
    }
  }

  switchLanguage(lang: string) {
    this.cookieService.set('appLang', lang, 365)
    this.translate.use(lang); // Dynamically switch the language
  }

  get currentLang() {
    return this.translate.currentLang;
  }

  translateKey(key: string) {
    return this.translate.instant(key); // Instantly translate the key
  }
}
