<div id="userspage"  class="dialog-content" fxLayout="column" fxLayoutGap="2px">

  <div fxFlex="10">
    @if (users.length > 1) {
      <h3><span>{{ "GROUP PERMISSIONS" | translate }}</span></h3>
    } @else {
      <h3>{{ "PERMISSIONS OF USER:" | translate }} {{" " + users[0].name}}</h3>
    }
    <ng-template>
      <h3>{{ "PERMISSIONS OF USER:" | translate }} {{" " + users[0].name}}</h3>
    </ng-template>
  </div>
  <div class="ml-2" fxFlex="90">
    <mat-dialog-content>
      <table mat-table [dataSource]="dataSource" class="table table-striped table-bordered full-width">
        <!-- Project member Column -->
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef class="table-cells">
            <span class="ml-2">
              <span>{{ "FORMS" | translate }}</span>
            </span>
          </th>
          <td *matCellDef="let element; let i = index" class="table-cells">
            <span class="ml-2">{{element.template.name}}</span>
          </td>
        </ng-container>

        <!-- Read Column -->
        <ng-container matColumnDef="read">
          <th *matHeaderCellDef class="table-cells">
            <span class="ml-2">
              <mat-checkbox  name="readAllCol"
                [checked]="getAllColReadPermission()"
                (change)="setReadAllColPermission($event.checked)"
                (click)="$event.stopPropagation()">
              </mat-checkbox>
              <span>{{ "READ" | translate }}</span>
            </span>
          </th>
          <td *matCellDef="let element; let i = index" class="action-link table-cells">
            <span class="ml-2">
              <mat-checkbox  name="read" [checked]="element.permissions.read" (click)="$event.stopPropagation()" (change)="setReadPermission(element, $event.checked)"> </mat-checkbox>
            </span>
          </td>
        </ng-container>
        <!-- Read Column -->
        <ng-container matColumnDef="create">
          <th *matHeaderCellDef>
            <span class="ml-2">

              <mat-checkbox  class="ml-2"
                name="createAllCol"
                [checked]="getAllColCreatePermission()"
                (change)="setCreateAllColPermission($event.checked)"
              (click)="$event.stopPropagation()" > </mat-checkbox><span>{{ "CREATE" | translate }}</span></span>
          </th>
          <td *matCellDef="let element; let i = index" class="action-link  table-cells">
            <span class="ml-2">
              <mat-checkbox  name="create" [checked]="element.permissions.create" (click)="$event.stopPropagation()" (change)="setCreatePermission(element, $event.checked)"> </mat-checkbox>
            </span>
          </td>
        </ng-container>

        <!-- Read Column -->
        <ng-container matColumnDef="update">
          <th *matHeaderCellDef>
            <span class="ml-2">
              <span class="ml-2"></span>
              <mat-checkbox  name="updateAllCol"
                [checked]="getAllColUpdatePermission()"
                (change)="setUpdateAllColPermission($event.checked)"
              (click)="$event.stopPropagation()" > </mat-checkbox><span>{{ "UPDATE" | translate }}</span></span>
          </th>
          <td *matCellDef="let element; let i = index" class="action-link table-cells ">
            <span class="ml-2">
              <mat-checkbox  name="update" [checked]="element.permissions.update" (click)="$event.stopPropagation()" (change)="setUpdatePermission(element, $event.checked)"> </mat-checkbox>
            </span>
          </td>
        </ng-container>

        <!-- Read Column -->
        <ng-container matColumnDef="delete">
          <th *matHeaderCellDef>
            <span class="ml-2">
              <span class="ml-2"></span>
              <mat-checkbox  name="deleteAllCol"
                [checked]="getAllColDeletePermission()"
                (change)="setDeleteAllColPermission($event.checked)"
              (click)="$event.stopPropagation()" > </mat-checkbox><span>{{ "DELETE" | translate }}</span></span>
          </th>
          <td *matCellDef="let element; let i = index" class="action-link table-cells">
            <span class="ml-2">
              <mat-checkbox  #deletecheckbox name="delete"
                [checked]="element.permissions.delete"
                (click)="$event.stopPropagation()"
              (change)="setDeletePermission(element, deletecheckbox.checked)"> </mat-checkbox>
            </span>
          </td>
        </ng-container>

        <!-- Read Column -->
        <ng-container matColumnDef="all">
          <th *matHeaderCellDef>
            <span class="ml-3">
              <mat-checkbox
                (change)="setAllPermissions($event.checked)"
                [checked]="initializeAllPermissions()"
                (click)="$event.stopPropagation()">
              </mat-checkbox>
            </span>
          </th>
          <td *matCellDef="let element; let i = index" class="action-link table-cells ">
            <span class="ml-2">
              <mat-checkbox  name="all"
                [checked]="initializeAllPermission(element)"
                (click)="$event.stopPropagation()"
              (change)="setAllPermission(element, i, $event.checked)"> </mat-checkbox>
            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>
    </mat-dialog-content>
    <mat-dialog-actions class="button-group">
      <span class="flex-spacer"></span>
      <button mat-button class="btn-border cancel-btn" (click)="toastService.warningToast('No permissions changed')" mat-dialog-close><span>{{ "CLOSE" | translate }}</span></button>
      <button mat-button type="submit" class="submit-btn" (click)="onSubmit()"><span>{{ "OK" | translate }}</span></button>
    </mat-dialog-actions>
  </div>
</div>
