import { TranslateModule } from '@ngx-translate/core';
import {CdkDragDrop, DragDropModule, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Item} from '../../../share/form/item';
import {CommonModule} from '@angular/common';
import {ListItemComponent} from '../list-item/list-item.component';
import {StoreService} from '../../../services/store.service';
import {FieldSet} from '../../../share/form/fieldset';
import {DataService} from '../../../services/data.service';
import {Subject, Subscription, takeUntil} from 'rxjs';

@Component({
  selector: 'root-container',
  templateUrl: 'root-container.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    DragDropModule,
    ListItemComponent,
  ],
  styleUrls: ['root-container.component.scss']
})
export class RootContainerComponent implements OnInit, OnDestroy {
  @Input() root: Item;
  public get connectedTo(): string[] {
    return this.getIdsRecursive(this.root).reverse();
  }

  constructor(
    public store: StoreService,
    public dataService: DataService,
    ) {
  }

  private destroy$ = new Subject<void>();
  private updateRootSubscription: Subscription;


  public ngOnInit() {
    const rootFieldset = new FieldSet();
    rootFieldset.name = 'rootFieldset';

    if(this.dataService.template.getValue().form)
      rootFieldset.fields = this.dataService.template.getValue().form.fields;

    rootFieldset._class = 'fieldset';
    this.root.children = this.store.transformToItems(rootFieldset).children;

    this.store.changedRoot = this.root

    //Awaits an update on an element to update the changedRoot, that way when saved we also save the updated element.
    this.updateRootSubscription = this.dataService.event$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.store.changedRoot = this.root
    });
  }

  ngOnDestroy() {
    if (this.updateRootSubscription) {
      this.updateRootSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  onDragDrop = (event: CdkDragDrop<Array<Item>>) => {
    if (event.previousContainer === event.container) {
      this.store.updatedForm = true;
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      if(this.store.findItemById(this.root, event.container.id).isContainer) {
        this.store.updatedForm = true;
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }
    }

    this.store.changedRoot = this.root;
  };

  private getIdsRecursive(item: Item): string[] {
    if (item.isContainer)
    {
      let ids = [item.uId];
      item.children.map(childItem => {
        ids = ids.concat(this.getIdsRecursive(childItem));
      });
      return ids;
    } else {
      return [];
    }
  }

  trackById(index: number, item: any): string {
    return item.uId; // Assuming each item has a unique 'id' property
  }

}
