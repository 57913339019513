import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/share/user';
import { getUser } from 'src/app/share/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';
import $ from 'jquery';
import { AppComponent } from 'src/app/app.component';
import { CommonModule } from '@angular/common';
import { AdminInfoPageComponent } from '../admin-info-page/admin-info-page.component';
import { ResetPasswordPageComponent } from '../reset-password-page/reset-password-page.component';
import { UsersPageComponent } from '../users-page/users-page.component';
import { UserProjectsPageComponent } from '../user-projects-page/user-projects-page.component';
import { MatButtonModule } from '@angular/material/button';
import { faInfoCircle, faRotateLeft, faUserGroup} from '@fortawesome/free-solid-svg-icons';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';

export const USER_INFORMATION   = 'USER_INFORMATION';
export const RESET_PASSWORD     = 'RESET_PASSWORD';
export const MANAGE_MEMBER      = 'MANAGE_MEMBER';
export const MANAGE_USER        = 'MANAGE_USER';

@Component({
  standalone: true,
  imports: [TranslateModule, 
    AppComponent,
    CommonModule,
    ResetPasswordPageComponent,
    UsersPageComponent,
    UserProjectsPageComponent,
    MatButtonModule,
    AdminInfoPageComponent,
    FaIconComponent,
  ],
  selector: 'app-system-admin-page',
  templateUrl: './system-admin-page.component.html',
  styleUrls: ['./system-admin-page.component.scss']
})
export class SystemAdminPageComponent implements OnInit {
  searchValue: string;
  users;




  userType: string;
  user: User;



  isAdminUser: boolean;
  selectedMenu: string = USER_INFORMATION;

  /**
   * Variable allowing to hide or show page
   */
  showUsersPage: boolean;
  showResetPasswordPage: boolean;
  showAdminInfoPage: boolean;
  showUserProjectsPage: boolean;

  constructor(
    private route: ActivatedRoute,
    public store: StoreService,
    private router: Router
  ) { }

  ngOnInit() {
    this.user = getUser();
    this.hideAllPages();

    const admin = getUser();
    if (admin.user_type !== 'collaborator' && admin.user_type !== null && admin.user_type !== undefined) {
      this.isAdminUser = true;
      this.showUsersPage = true;
    } else {
      this.isAdminUser = false;
      this.showUsersPage = false;
    }

    this.route.queryParamMap.subscribe(queryParams => {
      const type = queryParams.get('type');
      this.hideAllPages();
      if (type === 'user-info') {
        this.showAdminInfoPage = true;
        this.selectedMenu = USER_INFORMATION;
      } else if (type === 'reset-password') {
        this.showResetPasswordPage = true;
        this.selectedMenu = RESET_PASSWORD;
      } else if (type === 'projects-members') {
        this.showUserProjectsPage = true;
        this.selectedMenu = MANAGE_MEMBER;
      } else if (type === 'collaborator') {
        this.showUsersPage = true;
        this.selectedMenu = MANAGE_USER;
      } else {
        this.showAdminInfoPage = true;
        this.selectedMenu = USER_INFORMATION;
      }
    });
  }

  checkUserType(user) {
    const adminInfo = {
      user_type: ''
    };
    if ( user === 'admin' && adminInfo.user_type !== 'super_user' || adminInfo.user_type === 'collaborator') {
      return true;
    }
    return false;
  }

  displayUsers() {
    this.hideAllPages();
    if (!this.showUsersPage) {
      this.showUsersPage = true;
      this.handleClick(MANAGE_USER);
      this.router.navigate(['/users'], { queryParams: {type: 'collaborator' } });
    }
  }

  handleClick(activeMenu) {
    const $items = $('.menu');
    const classHighlight = "selected-admin";
    $items.removeClass(classHighlight);
    this.selectedMenu = activeMenu;
  }

  displayProjects() {
    if (!this.showUserProjectsPage) {
      this.hideAllPages();
      this.handleClick(MANAGE_MEMBER);
      this.showUserProjectsPage = true;
      this.router.navigate(['/users'], { queryParams: { type: 'projects-members' } });
    }
  }

  displayAdminInfo() {
    if (!this.showAdminInfoPage) {
      this.hideAllPages();
      this.handleClick(USER_INFORMATION);
      this.showAdminInfoPage = true;
      this.router.navigate(['/users'], { queryParams: { type: 'user-info' } });
    }
  }


  displayResetPassword() {
    if (!this.showResetPasswordPage) {
      this.hideAllPages();
      this.handleClick(RESET_PASSWORD);
      this.showResetPasswordPage = true;
      this.router.navigate(['/users'], { queryParams: { type: 'reset-password' } });
    }
    // this.showResetPasswordPage = true;
  }

  hideResetPassword(event) {
    event.preventDefault();
    this.hideAllPages();
    this.router.navigate(['/users'], { queryParams: { type: 'user-info' } });
    // this.showAdminInfoPage = true;
    event.stopPropagation();
  }

  hideAllPages() {
    this.showUsersPage = false;
    this.showResetPasswordPage = false;
    this.showAdminInfoPage = false;
    this.showUserProjectsPage = false;
  }


  protected readonly faInfoCircle = faInfoCircle;
  protected readonly faRotateLeft = faRotateLeft;
  protected readonly faUserGroup = faUserGroup;
}
