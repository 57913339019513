<div id="resetpassword" fxFlexLayout="row">
  <div>
    <div fxFlex fxFlexOffset="20px">
      <h3 class="mt-2 pb-3"><span>{{ "RESET PASSWORD" | translate }}</span></h3>
      @if (showMessage) {
        <app-message-box style="margin-left: -6px;" #messageBox message="{{message}}"></app-message-box>
      }
      <form novalidate [formGroup]="resetPassForm" (ngSubmit)="resetPassword()">
        <mat-dialog-content>
          <p>
            <mat-form-field class="full-width">
              <mat-label><span>{{ "CURRENT PASSWORD" | translate }}</span></mat-label>
              <input matInput #cur_pass autocomplete="off" formControlName="password" [placeholder]="'CURRENT PASSWORD' | translate" type="password" required>
              @if (resetPassForm.get('password').hasError('required') && resetPassForm.get('password').touched) {
                <mat-error><span>{{ "THE CURRENT PASSWORD IS REQUIRED" | translate }}</span></mat-error>
              }
            </mat-form-field>
          </p>
          <p>
            <mat-form-field class="full-width">
              <mat-label><span>{{ "NEW PASSWORD" | translate }}</span></mat-label>
              <input matInput autocomplete="off" formControlName="new_password" [placeholder]="'NEW PASSWORD' | translate" type="password" required>
              @if (resetPassForm.get('new_password').hasError('required') && resetPassForm.get('new_password').touched) {
                <mat-error><span>{{ "THE NEW PASSWORD IS REQUIRED" | translate }}</span></mat-error>
              }
            </mat-form-field>
          </p>
          <p>
            <mat-form-field class="full-width">
              <mat-label><span>{{ "CONFIRM PASSWORD" | translate }}</span></mat-label>
              <input matInput autocomplete="off" formControlName="confirmpassword" [placeholder]="'CONFIRM PASSWORD' | translate" type="password" required>
              @if (resetPassForm.get('confirmpassword').hasError('required') && resetPassForm.get('confirmpassword').touched) {
                <mat-error><span>{{ "CONFIRM THE NEW PASSWORD" | translate }}</span></mat-error>
              }
            </mat-form-field>
          </p>
        </mat-dialog-content>
        <mat-dialog-actions class="button-group">
          <button mat-button class="mr-4 btn-border" mat-dialog-close (click)="cancelResetPassword($event)">
            <span style="font-size: 1.2em;" ><span>{{ "CANCEL" | translate }}</span></span>
          </button>
          <button mat-button type="submit" class="background-primary text-floral-white" [disabled]="resetPassForm.invalid">
            <span style="font-size: 1.2em;"><span>{{ "CHANGE PASSWORD" | translate }}</span></span>
          </button>

          <span class="flex-spacer"></span>
        </mat-dialog-actions>
      </form>
    </div>
  </div>
</div>
