<div id="userspage" fxLayout="column" fxLayoutGap="2px">
    <div class="ml-2" fxFlex="20">
        <h3>Marxact Device(s) assigned to <span>{{user.name}}</span></h3>
    </div>

    <div class="ml-2" fxFlex="80">
        <mat-dialog-content>
            <table mat-table [dataSource]="dataSource" class="full-width">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox color="primary" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Device Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef><span>{{ "MARXACT DEVICE NAME" | translate }}</span></th>
                    <td mat-cell *matCellDef="let element"> {{element.device_name}} </td>
                </ng-container>

                <!-- Device Id Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef><span>{{ "MARXACT DEVICE ID" | translate }}</span></th>
                    <td mat-cell *matCellDef="let element"> {{element.device_id}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>
        </mat-dialog-content>
        <mat-dialog-actions class="button-group">
            <span class="flex-spacer"></span>
            <button mat-button class="btn cancel-btn" mat-dialog-close mat-dialog-close><span>{{ "CLOSE" | translate }}</span></button>
            <button mat-button type="submit" (click)="assignAndOrDeassignDevices()" class="btn submit-btn"><span>{{ "OK" | translate }}</span></button>
        </mat-dialog-actions>
    </div>
</div>
