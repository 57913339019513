<div id="formDataContainer" class="column">
      @if (showMessage) {
      <app-message-box #messageBox class="message-box" message="{{message}}"></app-message-box>
    }
  <div class="data-header">
      <div class="actions-container">
        <span mat-button (click)="deleteSelectedFeatures()" [matTooltip]="'DELETE SELECTED RECORDS' | translate" class="trash-icon">
          <fa-icon [icon]="faTrash"></fa-icon>
        </span>
        <span class="input-group-btn">
          <button type="button" class="btn btn-primary ml-3" (click)="resetSelectedFeature()" id="copyBtn" [matTooltip]="'CLEAR CLIPBOARD' | translate">
          <fa-icon [icon]="faClose"></fa-icon>

          </button>
        </span>
      </div>
      <div id="buttonContainer" class="counter-container">
        <span>{{initValue}}</span>-<span>{{pageFeatures}}</span><span>{{ "OF" | translate }}</span><span>{{totalFeatures}}</span>
      </div>
  </div>

  <div class="data-container">
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" class="table table-striped table-bordered">
        @for (column of displayedColumns; track column) {
          <ng-container [matColumnDef]="column">
            <th *matHeaderCellDef>
              @if (isSelectFeatureHeader(column)) {
                <mat-checkbox color="primary" class="header-checkbox" (change)="selectAllFeatures($event)" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
              } @else {
                <span [innerHTML]="getHeaderInSplitMode(column)"></span>
              }
            </th>
            <td *matCellDef="let element" class="align-middle" (dblclick)="openEditFeatureValue(column, element)">
              @if (isSelectFeatureData(column, element[column])) {
                <span>
                  <mat-checkbox class="data-checkbox" color="primary" (change)="selectFeature($event, element)" [checked]="selection.isSelected(element)" [aria-label]="checkboxLabel(element)">
                  </mat-checkbox>
                </span>
              } @else {
                @if (isDataIsFile(column, element[column])) {
                  @for (item of getFilesUrl(element[column]); track item) {
                    <a href="{{item}}" (click)="openMediaShowingDialog($event, item)" alt="">
                      <img src="{{dataService.getIcon(item)}}">
                    </a>
                  }
                } @else {
                  @if (checkIfColumnIsGeometry(column)) {
                    <span class="geomelement" (click)="displayCoordinates(column, element)">{{displayItemValue(column, element[column])}}</span>
                  } @else {
                    @if (checkIfCoordinatesField(column)) {
                      <span class="geomelement" (click)="displayGPSCoordinates(column, element)">{{displayItemValue(column, element[column])}}</span>
                    } @else {
                      @if (checkIfAttributesetArray(column)) {
                        <span class="geomelement" (click)="openAttributesetArrayDialog(column, element)">{{displayAttSetArrayValue(column)}}</span>
                      } @else {
                        <span>{{displayItemValue(column, element[column])}}</span>
                      }
                    }
                  }
                }
              }
            </td>
          </ng-container>
        }
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
