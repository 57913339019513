<div id="wrapper" [style.display]="wrapperstatus" class="{{type}}">
    <div id="wrapperdata" class="wrapper-data">
        <span id="content" class="content">
          <content class="text-left">{{message}}</content>
        </span>
        <button id="dismissMessageButton" class="dismiss-btn" mat-button (click)="hide()">
          <span>
            <strong>X</strong>
          </span>
        </button>
    </div>
</div>
