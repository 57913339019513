<div fxFlexLayout="row" class="mb-2">
  <div fxFlex fxFlexOffset="20px">
    <h3>{{ title }}</h3>
    <mat-dialog-content>
      <p>
        <!-- Include the image drag and drop elements here-->
      </p>
      <p>
        {{ description }}
      </p>
      <p>
      </p>
    </mat-dialog-content>
    <mat-dialog-actions class="button-group">
      <span class="flex-spacer"></span>
      <button mat-button (click)="saveAndExit($event)" cdkFocusRegionstart class="btn submit-btn"><span>{{ "SAVE AND EXIT" | translate }}</span></button>
      <button mat-button (click)="cancel($event)" cdkFocusRegionstart class="btn"><span>{{ "KEEP EDITING" | translate }}</span></button>
      <button mat-button mat-dialog-close (click)="exitWithoutSaving($event)" class="btn cancel-btn"><span>{{ "EXIT" | translate }}</span></button>
    </mat-dialog-actions>
  </div>
</div>
