import { Injectable } from '@angular/core';
import { User } from '../share/user';
import { Project } from '../share/projects';
import { Feature } from '../share/feature/feature';
import { FieldSet } from '../share/form/fieldset';
import { Field } from '../share/form/field';
import { getToken, getAdminToken, getUser, isCollaborator } from '../share/utils';
import { Template } from '../share/template';
import { BehaviorSubject } from 'rxjs';
import { Token } from '../share/token';
import {Item} from '../share/form/item';
import {FieldsetArray} from '../share/form/fieldset-array';
import { AutoIncrementField } from '../share/form/auto-increment-field';

export const MAP_MODE = 'MAP_MODE';
export const SCHEMA_MODE = 'SCHEMA_MODE';
export const DATA_MODE = 'DATA_MODE';
export const IMPORT_DATA_MODE = 'IMPORT_DATA_MODE';
export const RECORD_DATA_MODE = 'RECORD_DATA_MODE';


@Injectable({
  providedIn: 'root'
})
export class StoreService {
  user: User | any;
  selectedProject: BehaviorSubject<string>;
  selectedFieldSet: BehaviorSubject<string>;
  typeOfIMKLFile: BehaviorSubject<string>;
  typeOfExcelFile: BehaviorSubject<string>;
  typeOfShapeFile: BehaviorSubject<string>;
  changedMultipleformsonmap: BehaviorSubject<number>;
  feature: Feature;
  features: Feature[];
  parentFieldSet: FieldSet;
  fieldSet: FieldSet;
  fields: Field[];
  token: Token;
  isMCAdmin: boolean;
  isCollaborator: boolean;
  loading: boolean;
  updatedForm: boolean;
  showprojects: boolean;
  hideProjectsContainer: boolean;
  hideTemplatesContainer: boolean;
  showMessage: boolean;
  importDataInNewForm: boolean;
  importIKMLFile: boolean;
  importExcelFile: boolean;
  importShapeFile: boolean;
  showmultipleformsonmap: boolean;
  formMode: string;
  mapLabel: any[];
  currentFieldSet: Item | null;
  typeProject: string;
  visibleProject: BehaviorSubject<string>;
  proClass: string;
  visibility: string;
  archived: boolean;
  foundProjects: any;
  copiedFeatures: Feature[];
  deleteFeaturesAfterPasting: boolean;
  selectedFoundTemplate: BehaviorSubject<Template>;
  selectedFoundProject: BehaviorSubject<Project>;
  listFoundTemplates: Template[];
  listFoundFeatures: Feature[];
  searchMode: boolean;
  destinationProject: Project;
  destinationTemplate: Template;
  selectedFeatures: Feature[];
  customExport: BehaviorSubject<boolean>;
  changedRoot: Item;
  selectedItemId: string;

  constructor() {
    this.initializeStore();
  }

  initializeStore() {
    this.user = getUser();
    this.selectedProject = new BehaviorSubject<string>('');
    this.selectedFieldSet = new BehaviorSubject<string>('');
    this.typeOfIMKLFile = new BehaviorSubject<string>('');
    this.typeOfExcelFile = new BehaviorSubject<string>('');
    this.typeOfShapeFile = new BehaviorSubject<string>('');
    this.changedMultipleformsonmap = new BehaviorSubject<number>(null);
    this.feature = null;
    this.features = null;
    this.typeProject = null;
    this.parentFieldSet = null;
    this.currentFieldSet = null;
    this.fieldSet = null;
    this.fields = null;
    this.token = getToken() != null ? getToken() : getAdminToken();
    this.isMCAdmin = (getAdminToken() !== null) && (getAdminToken() !== undefined);
    this.isCollaborator = isCollaborator(this.user);
    this.loading = false;
    this.updatedForm = false;
    this.showprojects = (getToken() !== null || getAdminToken() !== null) && (getToken() !== undefined || getAdminToken() !== undefined);
    this.hideProjectsContainer = false;
    this.hideTemplatesContainer = false;
    this.showMessage = false;
    this.mapLabel = null;
    this.formMode = DATA_MODE;
    this.importDataInNewForm = true;
    this.visibleProject = new BehaviorSubject<string>('private active project');
    this.visibility= 'private';
    this.archived = false;
    this.proClass = 'project';
    this.foundProjects = [];
    this.copiedFeatures = [];
    this.selectedFeatures = [];
    this.selectedFoundTemplate = new BehaviorSubject<Template>(null);
    this.selectedFoundProject = new BehaviorSubject<Project>(null);
    this.searchMode = false;
    this.listFoundTemplates = null;
    this.listFoundFeatures  = null;
    this.destinationProject = null;
    this.destinationTemplate = null;
    this.deleteFeaturesAfterPasting = false;
    this.customExport = new BehaviorSubject<boolean>(false);
  }



  setFieldToShowOnMap(fields) {
    this.mapLabel = [];
    // this.mapLabel = this.mapLabel.concat(fields);
    this.mapLabel = fields;
  }

  changeProClassVisArchi(proClass, vis, arch = false) {
    this.proClass = proClass;
    this.visibility = vis;
    this.archived = arch;
  }


  showOrHideProjectsContainer(status) {
    this.hideProjectsContainer = status;
  }

  changeFormUpdatingState(value) {
    this.updatedForm = value;
  }

  showOrHideTemplatesContainer(status) {
    this.hideTemplatesContainer = status;
  }

  persistUser(u) {
    this.user = u;
  }

  login(token, u ) {
    this.token = token;
    this.user = u;
  }



  equalsIgnoringCase(text, other) {
    return text.localeCompare(other, undefined, { sensitivity: 'base' });
  }

  addField(f) {
    let parent = this.parentFieldSet;
    if (parent === undefined || parent === null) {
      if (this.fields === null || this.fields === undefined) {
        this.fields = [];
      }
      parent = {
        ...parent,
        fields: this.fields.concat(Object.assign({}, f))
      };
    } else {
      parent.fields.concat(Object.assign({}, f));
    }
    this.parentFieldSet = parent;
    this.fields = this.fields.concat(Object.assign({}, f));
  }

  updateField(f) {

  }

  saveToken(tok) {
    this.token = tok;
  }

  showLoading() {
  this.loading = false;
  }

  hideLoading() {
    this.loading = true;
  }

  show_Projects() {
    this.showprojects = true;
  }

  hideProjects() {
    this.showprojects = false;
  }

  logout() {
    this.initializeStore();
    this.showprojects = false;
  }


  public transformToItems(field: Field | FieldSet | FieldsetArray): Item {
    if ('fields' in field && (field._class === 'fieldset' || field._class === 'arrayfieldset')) {
      // TypeScript now understands that 'fields' exists on 'FieldSet' and 'FieldsetArray'
      const children: Item[] = field.fields.map(child => this.transformToItems(child));
      return new Item({
        name: field.name,
        children: children,
        isContainer: true,
        _class: field._class,
        constraints: field.constraints,
        default_value: field.default_value,
        default_value_expression: field.default_value_expression,
        description: field.description,
        enabled: field.enabled,
        event_actions: field.event_actions,
        label: field.label,
        required: field.required,
        show_value_on_map: field.show_value_on_map,
        use_previous_value: field.use_previous_value,
        visible: field.visible,
        on_same_screen_as_parent: field.on_same_screen_as_parent,
      });
    } else {
      let step =  null;
      let initial_value = null;
      if(field._class === 'autoincrementintegerfield') {
        step = (field as AutoIncrementField).step;
        initial_value = (field as AutoIncrementField).initial_value;
      }
      // If the input is a simple Field, return an Item with no children
      return new Item({
        name: field.name,
        children: [],  // Simple fields do not have children
        isContainer: false,
        _class: field._class,
        constraints: field.constraints,
        default_value: field.default_value,
        default_value_expression: field.default_value_expression,
        description: field.description,
        enabled: field.enabled,
        event_actions: field.event_actions,
        label: field.label,
        required: field.required,
        show_value_on_map: field.show_value_on_map,
        use_previous_value: field.use_previous_value,
        visible: field.visible,
        on_same_screen_as_parent: null,
        step: step,
        initial_value: initial_value
      });
    }
  }

  transformToFields(item: Item): Field | FieldSet | FieldsetArray {
    if (item._class === 'fieldset') {
      const fieldset = new FieldSet();
      fieldset._class = item._class;
      fieldset.name = item.name;
      fieldset.label = item.label;
      fieldset.description = item.description;
      fieldset.enabled = item.enabled;
      fieldset.visible = item.visible;
      fieldset.on_same_screen_as_parent = item.on_same_screen_as_parent;
      fieldset.fields = item.children.map(child => this.transformToFields(child)) as Field[];
      return fieldset;
    } else if (item._class === 'arrayfieldset') {
      const fieldsetArray = new FieldsetArray();
      fieldsetArray._class = item._class;
      fieldsetArray.name = item.name;
      fieldsetArray.label = item.label;
      fieldsetArray.description = item.description;
      fieldsetArray.enabled = item.enabled;
      fieldsetArray.visible = item.visible;
      fieldsetArray.on_same_screen_as_parent = item.on_same_screen_as_parent;
      fieldsetArray.fields = item.children.map(child => this.transformToFields(child)) as Field[];
      return fieldsetArray;
    } else {
      const field = new Field();
      
      field._class = item._class;
      field.name = item.name;
      field.label = item.label;
      field.description = item.description;
      field.enabled = item.enabled;
      field.visible = item.visible;
      field.required = item.required;
      field.use_previous_value = item.use_previous_value;
      field.event_actions = item.event_actions;
      field.default_value_expression = item.default_value_expression;
      field.show_value_on_map = item.show_value_on_map;
      field.constraints = item.constraints;
      field.default_value = item.default_value;
     
      if(item._class === 'autoincrementintegerfield') {
        (field as AutoIncrementField).step = item.step ;
        (field as AutoIncrementField).initial_value  = item.initial_value;
      }
      return field;
    }
  }

  public findItemById(root: Item, id: string): Item | undefined {
    if (root.uId === id) {
      return root;
    }

    if (root.children) {
      for (const child of root.children) {
        const found = this.findItemById(child, id);
        if (found) {
          return found;
        }
      }
    }

    return undefined;
  }
}
