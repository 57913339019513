<div fxFlexLayout="row">
  <div fxFlex fxFlexOffset="20px">
    <h3><span>{{ "STYLE" | translate }}</span></h3>
    @if (showMessage) {
      <app-message-box #messageBox message="{{message}}"></app-message-box>
    }
    <form novalidate [formGroup]="styleDialogForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      <mat-dialog-content>
        @if (!isMyCumulusAdministrator) {
          <p class="mt-3">
            <mat-radio-group aria-label="Select visibility" formControlName="visibility">
              <mat-radio-button (change)="getTemplateStyleOnVisibilityChange($event)"
              [checked]="publicVisibility" color="primary" value="public" class="mr-4"><span>{{ "PUBLIC" | translate }}</span></mat-radio-button>
              <mat-radio-button (change)="getTemplateStyleOnVisibilityChange($event)"
              [checked]="privateVisibility" color="primary"  value="private" ><span>{{ "PRIVATE" | translate }}</span></mat-radio-button>
            </mat-radio-group>
          </p>
        }

        <p>
          <span class="half-width"><span>{{ "STYLE" | translate }}</span></span>
          <mat-select class="ml-3" class="half-width" formControlName="style_id" [placeholder]="'SELECT STYLE' | translate">
            <mat-option></mat-option>
            @for (s of styles; track s) {
              <mat-option [value]="s.id">{{ s.name }}</mat-option>
            }
          </mat-select>
        </p>

      </mat-dialog-content>
      <app-dialog-actions [mode]="mode" [formCheck]="styleDialogForm"></app-dialog-actions>
    </form>
  </div>
</div>
