<div class="project-container">
  <div class="projects-header">
    @if(headerService.currentClass === ActiveClass.PROJECT) {
      <div class="header-left">
        <img src="/assets/images/icon-projects.png" alt="" class="header-image"/>
        <h4><span>{{ "PROJECT" | translate }}</span></h4>
      </div>
    } @else if (headerService.currentClass === ActiveClass.APROJECT) {
      <div class="header-left">
        <mat-icon class="materialIcons">folder_zip</mat-icon>
        <h4><span>{{ "ARCHIVED PROJECT" | translate }}</span></h4>
      </div>
    } @else if (headerService.currentClass === ActiveClass.TEMPLATE) {
      <div class="header-left">
        <mat-icon class="materialIcons">domain</mat-icon>
        <h4><span>{{ "TEMPLATE" | translate }}</span></h4>
      </div>
    } @else if (headerService.currentClass === ActiveClass.ATEMPLATE) {
      <div class="header-left">
        <mat-icon class="materialIcons">domain_disabled</mat-icon>
        <h4><span>{{ "ARCHIVED TEMPLATE" | translate }}</span></h4>
      </div>
    } @else if (headerService.currentClass === ActiveClass.DOMAIN) {
      <div class="header-left">
        <mat-icon class="materialIcons">library_books</mat-icon>
        <h4><span>{{ "DOMAIN" | translate }}</span></h4>
      </div>
    } @else if (headerService.currentClass === ActiveClass.ADOMAIN) {
      <div class="header-left">
        <mat-icon class="materialIcons">archive</mat-icon>
        <h4><span>{{ "ARCHIVED DOMAIN" | translate }}</span></h4>
      </div>
    }

    <div class="header-right">
      <button #openSearch [matTooltip]="'BROWSE PROJECTS' | translate" #buttonRef id="project" (click)="toggleProjectSearch()">
        <img src="/assets/images/search-project.svg" alt="Search" class="header-image"/>
      </button>
      @if(projectSearchActive) {
        <div #searchDiv class="project-search-menu" [ngStyle]="{'top.px': menuPosition.top, 'left.px': menuPosition.left}">
          <div class="search-header">
            <button (click)="toggleProjectSearch()">
              <img src="/assets/images/search-project-white.svg" alt="Search" class="header-image"/>
            </button>
            <h4>{{ "PROJECT BROWSER" | translate }}</h4>
            <button (click)="toggleProjectSearch()">
              <img src="/assets/images/close-icon.svg" alt="Search" class="header-image"/>
            </button>
          </div>
          <div class="search-body">
          <div class="search-searchbar">
            <input #searchInput matInput [value]="typedSearch" (keyup)="applySearch(searchInput.value)" [placeholder]="'SEARCH...' | translate" type="text" autocomplete="off" />
          </div>
          @if(!isSearchActive) {
            <div class="search-content">
              @for (p of dataService?.projects; track p) {
                <app-project-search-item (click)="onSelected(p, $event)" [project]="p"></app-project-search-item>
              }
            </div>
          } @else {
            <div class="search-content">
              @for (p of foundProjects; track p) {
                <app-project-search-item (click)="onSelected(p, $event)" [project]="p"></app-project-search-item>
              }
            </div>
          }
          </div>
        </div>
      }
      @if(!disabledAddCreateProjectTemplateBtn()) {
        <button [disabled]="getUser().id !== dataService.project?.created_by && !getUser().permissions?.projects.includes('create')" class="add-button" [matTooltip]="'ADD PROJECT' | translate" [matMenuTriggerFor]="projMenu" >
          <img src="/assets/images/add-icon.svg" alt="Add" class="header-image-left"/>
        </button>
      }

      <mat-menu class="menu-text" #projMenu="matMenu">
        @if (headerService.currentClass === ActiveClass.PROJECT) {
          <span>
              <button *ngIf="!store?.isMCAdmin" (click)="openCreateProjectDialog()" mat-menu-item><span>{{ "NEW BLANK PROJECT" | translate }}</span></button>
              <button *ngIf="!store?.isMCAdmin" (click)="openImportDomainProjectDialog()" mat-menu-item><span>{{ "NEW PROJECT FROM PROJECT TEMPLATE" | translate }}</span></button>
              <button *ngIf="store?.isMCAdmin" (click)="openImportProjectDialog()" mat-menu-item><span>{{ "IMPORT FROM USER PROJECT" | translate }}</span></button>
          </span>
        }
        @if (headerService.currentClass === ActiveClass.TEMPLATE) {
          <span>
            <button (click)="openCreateProjectDialog()" mat-menu-item><span>{{ "NEW BLANK PROJECT TEMPLATE" | translate }}</span></button>
            <button (click)="openImportProjectDialog()" mat-menu-item>Copy user's Project Template</button>
          </span>
        }
        @if (headerService.currentClass === ActiveClass.DOMAIN) {
          <span>
            <button (click)="openCreateProjectDialog()" mat-menu-item><span>{{ "NEW BLANK DOMAIN" | translate }}</span></button>
          </span>
        }
      </mat-menu>
    </div>
  </div>
  <app-project-item (click)="resetTemplateSelection()" [project]="dataService?.project"></app-project-item>
</div>
