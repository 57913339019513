import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { getAdminToken } from 'src/app/share/utils';
import { UserService } from 'src/app/services/user.service';
import { MatButtonModule } from '@angular/material/button';

export interface Collaborator {
  id: string;
  name: string;
}

const ELEMENT_DATA: Collaborator[] = [];

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatDialogContent,
    MatTableModule,
    MatDialogActions,
    MatButtonModule,
  ],
  selector: 'app-collaborator-list-dialog',
  templateUrl: './collaborator-list-dialog.component.html',
  styleUrls: ['./collaborator-list-dialog.component.scss', '../dialog-btns.component.scss']
})

export class CollaboratorListDialogComponent implements OnInit {
  admin: any;
  displayedColumns: string[] = ['name'];
  dataSource = new MatTableDataSource<Collaborator>(ELEMENT_DATA);
  loading: boolean = false;

  @ViewChild(MatTable, {static: true}) table: MatTable<any>;

  constructor(
    private dialogRef: MatDialogRef<CollaboratorListDialogComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if(data !== null && data !== undefined)
      this.admin = data.admin;

    this.dataSource.data = [];
  }

  ngOnInit() {
    this.loading = true;
    this.getCollaborators();
  }

  getCollaborators(){
    let data = this.dataSource.data;
    data = [];
    const token = getAdminToken();

    const users = this.admin.users;
    if(users !== null && users !== undefined && users.length > 0){
      for(let i = 0; i < users.length; i++){
        this.loading = true;
        this.userService.getUser(token, users[i], true).subscribe(
          res => {
            const el = {
              id: res.id,
              name: res.name
            }
            data.push(el);

            this.dataSource.data = data;
            this.table.renderRows();
            this.loading = false;
          }, error => {
            this.loading = false;
          });
      }
    }

  }

  closeDialog(){
    this.dialogRef.close();
  }
}
