<div fxFlexLayout="row">
  <div fxFlex fxFlexOffset="20px">
    <h3>{{ mode.text }} {{proClass | titlecase}}</h3>
    <form novalidate [formGroup]="projectDialogForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      <mat-dialog-content>
        <p id="dropbox">
          <app-image-drop [(image)]="image" #pjtIcon [width]="100" [height]="100" (invalidIconDropped)="invalidIconDrop($event)"></app-image-drop>
        </p>
        <p>
          <mat-form-field class="full-width">
            <input matInput autocomplete="off" formControlName="name" value="{{name}}" [placeholder]="'NAME' | translate" type="text" required>
            @if (projectDialogForm.get('name').hasError('required') && projectDialogForm.get('name').touched) {
              <mat-error><span>{{ "PROJECT NAME IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="full-width">
            <input matInput autocomplete="off" value="{{description}}" formControlName="description" [placeholder]="'DESCRIPTION' | translate" type="text">
          </mat-form-field>
        </p>
      </mat-dialog-content>
      <app-dialog-actions [mode]="mode" [formCheck]="projectDialogForm"></app-dialog-actions>
    </form>
  </div>
</div>
