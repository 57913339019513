import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { catchError } from 'rxjs/operators';
import { map  } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class ExternalService {

  constructor(
    private http: HttpClient,
    private processHttpMsgService: ProcessHttpmsgService
  ) { }

  getCountriesInRegion(region: string) {
    return this.http.get<object[]>('https://restcountries.com/v3.1/region/' + region)
      .pipe(map(result => {
        return result;
      }))
      .pipe(catchError(this.processHttpMsgService.handleError));
  }
}

export interface Country {
  name: {
    common: string;
  };
}
