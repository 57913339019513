@if (template) {
@if(dataService?.isSelectedTemplate(template)) {
  <div class="templateHighlight" matTooltip="{{template.name}}" matTooltipPosition="right">
    <div [id]="template.name" class="template-item" (mousemove)="onMouseMove($event)" >
          <div class="imgContainer">
            @if(dataService?.setCircleLetters(template).length > 2) {
              <img class="rounded-circle" [src]="dataService.setCircleLetters(template)" alt="">
            } @else {
              <h4 class="rounded-circle">{{dataService.setCircleLetters(template)}}</h4>
            }
          </div>

          <div class="tempTextContainer">
            @if(template.description) {
              @if(template.feature.geometry_type) {
                <h4>{{template.name}} - {{template.feature.geometry_type}}</h4>
              } @else {
                <h4>{{template.name}}</h4>
              }
              <h6>{{template.description}}</h6>
            } @else {
              @if(template.feature.geometry_type) {
                <h4>{{template.name}} - {{template.feature.geometry_type}}</h4>
              } @else {
                <h4>{{template.name}}</h4>
              }
            }
          </div>
    </div>
    <div class="template-threeDots">
      <button (click)="test($event)" [matMenuTriggerFor]="projMenu" class="project-settings">
        <fa-icon [icon]="faEllipsisVertical" size="xl"></fa-icon>
      </button>
      <mat-menu class="template-menu" #projMenu="matMenu">
        <button [disabled]="getUser().id !== dataService.project?.created_by" class="template-menu-btn" mat-menu-item (click)="editTemplate($event)">{{ "EDIT" | translate }}</button>
        <button class="template-menu-btn" mat-menu-item (click)="duplicateTemplate($event)">{{ "DUPLICATE" | translate }}</button>
        <button [disabled]="getUser().id !== dataService.project?.created_by" class="template-menu-btn" mat-menu-item (click)="deleteTemplate($event)">{{ "DELETE" | translate }}</button>
      </mat-menu>
    </div>
  </div>
}


@else {
  <div class="template-wrapper" matTooltip="{{template.name}}" matTooltipPosition="right">
    <div [id]="template.name" class="template-item" (click)="handleTemplateClicked(template, $event)">
      <div class="tempImgContainer">
        <div class="imgContainer">
          @if(dataService.setCircleLetters(template).length > 2) {
            <img class="rounded-circle" [src]="dataService.setCircleLetters(template)" alt="">
          } @else {
            <h4 class="rounded-circle">{{dataService.setCircleLetters(template)}}</h4>
          }
        </div>
      </div>
      <div class="tempDataContainerNotSelected">
        @if(template.description) {
          <h4>{{template.name}}</h4>
          <h6>{{template.description}}</h6>
        } @else {
          <h4>{{template.name}}</h4>
        }
      </div>
    </div>
    <div class="template-threeDots">
      <button (click)="test($event)" [matMenuTriggerFor]="projMenu" class="project-settings">
        <fa-icon [icon]="faEllipsisVertical" size="xl"></fa-icon>
      </button>

      <mat-menu class="template-menu" #projMenu="matMenu">
        <button [disabled]="getUser().id !== dataService.project?.created_by" class="template-menu-btn" mat-menu-item (click)="editTemplate($event)">{{ "EDIT" | translate }}</button>
        <button class="template-menu-btn" mat-menu-item (click)="duplicateTemplate($event)">{{ "DUPLICATE" | translate }}</button>
        <button [disabled]="getUser().id !== dataService.project?.created_by" class="template-menu-btn" mat-menu-item (click)="deleteTemplate($event)">{{ "DELETE" | translate }}</button>
      </mat-menu>
    </div>
  </div>
}

}
