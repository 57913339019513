import { TranslateModule } from '@ngx-translate/core';
import {Component, OnInit, Inject, ViewChild, ChangeDetectorRef, ElementRef} from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { Feature } from 'src/app/share/feature/feature';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { FileServiceService } from 'src/app/services/file-service.service';
import {
  url,
  getToken,
  getFieldToShowOnMap,
} from 'src/app/share/utils';
import { BehaviorSubject } from 'rxjs';
import { CrsService } from 'src/app/services/crs.service';
import { AttributeSet} from 'src/app/share/feature/attributes';
import { Template } from 'src/app/share/template';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatCardImage} from '@angular/material/card';
import {CdkDrag, CdkDragHandle} from '@angular/cdk/drag-drop';
import {CommonModule} from '@angular/common';
import {ToastService} from '../../../services/toast.service';
import {DataService} from '../../../services/data.service';
import {EditMapValueComponent} from '../edit-map-value/edit-map-value.component';
import {FeatureService} from '../../../services/feature.service';
import {CookieService} from 'ngx-cookie-service';

export class Data {
  key: string;
  value: string;
  file: boolean;
  fileloading: boolean;
}
@Component({
  imports: [TranslateModule,
    MatDialogModule,
    MatButtonModule,
    MessageBoxComponent,
    MatCardImage,
    CdkDrag,
    CdkDragHandle,
    CommonModule
  ],
  selector: 'app-map-item-dialog',
  templateUrl: './map-item-dialog.component.html',
  standalone: true,
  styleUrls: ['./map-item-dialog.component.scss', '../dialog-btns.component.scss']
})
export class MapItemDialogComponent implements OnInit {

  feature: Feature;
  items: Data[];
  file: BehaviorSubject<boolean>;
  medialink: string;
  imageFile: boolean;
  audioFile: boolean;
  videoFile: boolean;
  extension: string;
  filetype: string;
  fileloading: boolean;
  fields = [];
  templateName = '';
  template: Template;

  //Elements to retain scroll after the image view dialog is closed
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  scrollPosition: any

  constructor(
    public store: StoreService,
    private dialog: MatDialog,
    private conversionCoordinateService: CrsService,
    @Inject(MAT_DIALOG_DATA) data,
    private fileService: FileServiceService,
    private cookieService: CookieService,
    private dialogRef: MatDialogRef<MapItemDialogComponent>,
    public dataService: DataService,
    public toastService: ToastService,
    public featureService: FeatureService,
  ) {
    this.items = [];
    if (data !== null && data !== undefined) {
      this.feature = data.feature;
      this.templateName = data.template.name;
      this.template = data.template;
    }

    this.initializeAttributes();
  }

  ngOnInit() {
  }

  trackByFn(index: number, item: any): any {
    return item.key; // or a unique identifier for your item
  }


  editFormValue(item) {
    if(!item.file) {
      let attribute = this.template.feature.attributes.find(x => x['name'] === item.key);
      let type = null;
      if(attribute) {
        type = attribute['type'];
      }
      if(!type || type === 'coordinates') {
        this.toastService.warningToast("Editing geometries not available on the map view. Please try from the Data View page.")
        return
      }

      let dialogConfig = {
        data: {
          item: item,
          type: this.dataService.checkIfDate(item.value) ? 'date' : this.dataService.checkIfTime(item.value) ? 'time' : type,
          template: this.template
        },
      }

      const dialogRef = this.dialog.open(EditMapValueComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if(result?.isRes) {
          this.feature.attributes[item.key] = result.output;
          this.featureService.updateFeature(this.feature, this.dataService.project, getToken()).subscribe(
            res => {
              this.items = [];
              this.initializeAttributes();
            },
            err => {
              this.toastService.errorToast(err);
            }
          );
        }
      })
    }
  }

  initializeAttributes() {
    if (this.feature === null || this.feature === undefined) {
      return;
    }
    const geom = this.feature.geometry;
    if(geom.type === 'Point') {
      const crs = this.cookieService.get('CRSValue');
      if(!(crs === null || crs === undefined || crs === '' || crs === 'Geodetic')) {
        this.constructAsyncCoord(geom.coordinates, crs, this.items);
      } else {
        const geomItems = this.constructCoordinate(geom.coordinates);
        this.items = [
          ...geomItems
        ]
        this.constructNonGeomData();
      }
    } else {
      this.constructNonGeomData();
    }
  }

  setFieldToShow(feature: Feature) {
    const templs = this.dataService.templates.getValue().filter(t => t.id == feature.template_id);

    if(templs && templs.length > 0) {
      const selectedTemplate = templs[0];
      this.saveFieldsAndLabels(selectedTemplate.feature.attributes, selectedTemplate);
      const labels = [];
      const keys = Object.keys(this.fields);
      for (const key of keys) {
        const value = this.fields[key];
        if (value['template_to_show'] !== null) {
          const fieldname = value['label'];
          labels.push(fieldname);
        }
      }
      this.store.setFieldToShowOnMap(labels);
    }

  }

  saveFieldsAndLabels(attributes: any[], template: Template) {
    this.fields = [];
    this.fields.push({
      template_to_show: template.id
    });
    this.saveFields(attributes);
  }

  saveFields(attributes: any, parent= '') {
    const name = 'name';
    const _class = '_class';
    for (const attribute of attributes) {
      const typeAttr = attribute[_class];
      if (typeAttr === 'attribute') {
        const value = parent === '' ? attribute[name] : `${parent}.${attribute[name]}`;
        const label = {
          label: value
        };
        this.fields.push(label);
      } else if (typeAttr === 'attributeset') {
        const nested_parent = parent === '' ? attribute[name] : `${parent}.${attribute[name]}`;
        this.saveFields((attribute as AttributeSet).attributes, nested_parent);
      }
    }
  }

  openMedia(event, item :string) {
    this.scrollPosition = this.scrollContainer.nativeElement.scrollTop;

    this.dataService.openMediaShowingDialog(event, item).afterClosed().subscribe(result => {
      this.scrollContainer.nativeElement.scrollTop = this.scrollPosition;
    })
  }

  constructNonGeomData() {
    const attributes = {};
    this.convertObjectToTabular(null, this.feature.attributes, attributes);

    this.setFieldToShow(this.feature);
    const keys = this.store.mapLabel;
    if(keys) {
      for (const key of keys) {
        if (key === null || key === undefined || key === 'template_to_show') {
        } else {
          let value = attributes[key];
          if (!(value instanceof Object) ) {
            const item = {
              key,
              value,
              file: false,
              fileloading: false
            };
            this.items.push(item);
          } else if (value instanceof Array) {
            let val = '';
            if (value.length > 0) {
              for(val of value) {
                if (this.isFileValue(val)) {
                  this.fileloading = true;
                  this.medialink = val;
                  const item = {
                    key,
                    value: val,
                    file: this.isFileValue(val),
                    fileloading: true,
                  };
                  this.items.push(item);
                  this.getPrivateFile(val, item);
                } else {
                  let item = {
                    key: key,
                    value: val,
                    file: false,
                    fileloading: false,
                  };
                  if(value.length === 4 || value.length === 3) {
                    item.value = `${value[0].toFixed(8)}, ${value[1].toFixed(8)}, ${value[2].toFixed(8)}`
                  }
                  this.items.push(item);
                }
              }
            } else {
              value = '';
              const item = {
                key,
                value,
                file: false,
                fileloading: false
              };
              this.items.push(item);
            }
          }
        }
      }
    }
  }

  convertObjectToTabular(parentKey, obj, feature) {
    const keys = Object.keys(obj);
    for (const k of keys) {
      const value = obj[k];
      let key = k;
      if (parentKey && parentKey.length > 0) {
        key = parentKey + '.' + k;
      }

      if (!(value instanceof Object) ) {
        feature[key] = value;
      } else if (value instanceof Array) {
        feature[key] = value;
      } else {
        this.convertObjectToTabular(key, value, feature);
      }
    }

  }

  constructAsyncCoord(coordinates, crs, items) {
    const token = getToken();
    this.store.showLoading();
    const coords =  [
      {
        "latitude": coordinates[0],
        "longitude": coordinates[1],
        "altitude": coordinates[2],
        "accuracy": "?"
      }
    ];

    this.conversionCoordinateService.convertCRS(token, crs, coords).subscribe(
      result => {
        let res = {};
        if(result.length > 0) {
          res = result[0];
          const key = 'X, Y, Z';
          const value = `${res['X'].toFixed(2)}, ${res['Y'].toFixed(2)}, ${res['Z'].toFixed(2)}`
          const coordValue = {
            key: key,
            value: value,
            file: false,
            fileloading: false
          }

          items.push(coordValue);
        }
        this.constructNonGeomData();

        this.store.hideLoading();
      },
      err => {
        this.store.hideLoading();
      }
    );
  }

  constructCoordinate(coordinates): any[] {
    const geomItems = [];
    const crs = this.cookieService.get('CRSValue');
    if(crs === null || crs === undefined || crs === '' || crs === 'Geodetic') {
      const key = 'Lat, Long, Alt';
      const value = `${coordinates[0].toFixed(8)}, ${coordinates[1].toFixed(8)}, ${coordinates[2].toFixed(8)}`
      const coordValue = {
        key: key,
        value: value,
        file: false,
        fileloading: false
      }
      geomItems.push(coordValue);
    }
    return geomItems;
  }

  isFileValue(item) {
    return this.ifMediaIsImage(item) || this.ifMediaIsVideo(item) || this.ifMediaIsAudio(item);
  }

  getValue(data): string {
    if (this.dataService.checkIfDate(data)) {
      return data.split('T')[0];
    }
    return data;
  }


  getPrivateFile(medialink, item) {
    this.checkMediaType();
    this.filetype = this.getMime();
    const token = getToken();
    const project = this.dataService.project;
    // this.store.showLoading();
    item.fileloading = true;
    const link = url(medialink);
    this.fileService.getPrivateFile(link, token, project).subscribe(
      res => {
        this.loadImage(res, item.key);
        if (this.imageFile) {
          this.loadImage(res, 'img_full');
        }
        item.fileloading = false;
        this.store.hideLoading();
      },
      err => {
        // this.fileloading = false;
        this.toastService.errorToast(err);
        item.fileloading = false;
        this.store.hideLoading();
      });
  }

  closeDialog(event) {
    event.preventDefault();
    this.dialogRef.close();
  }

  getMime(): string {
    let t = '';
    if (this.audioFile) {
      t = `audio/${this.extension}`;
    } else if (this.videoFile) {
      t = `video/${this.extension}`;
    }
    return t;
  }

  checkMediaType() {
    const fileAndType = this.medialink.split('.');
    if (fileAndType !== null && fileAndType.length > 1) {
      this.extension = fileAndType[fileAndType.length - 1];

      if (this.ifMediaIsImage(this.medialink)) {
        this.imageFile = true;
        this.audioFile = false;
        this.videoFile = false;
      } else if (this.ifMediaIsVideo(this.medialink)) {
        this.imageFile = false;
        this.audioFile = false;
        this.videoFile = true;
      } else if (this.ifMediaIsAudio(this.medialink)) {
        this.imageFile = false;
        this.audioFile = true;
        this.videoFile = false;
      }
    }
  }

  ifMediaIsImage(value) {
    if(typeof(value) !== 'string') {
      return false;
    }
    const reg = value.match(/\.(jpeg|jpg|gif|png)$/);
    return(reg !== null);
  }

  ifMediaIsAudio(value) {
    if(typeof(value) !== 'string') {
      return false;
    }
    const reg = value.match(/\.(mp3|wav|raw|ogg)$/);
    return(reg !== null);
  }

  ifMediaIsVideo(value) {
    if(typeof(value) !== 'string') {
      return false;
    }
    const reg = value.match(/\.(mp4|3gp|avi|vob|flv|mpeg)$/);
    return(reg !== null);
  }

  loadImage(response, key) {
    let image = null ;
    if (this.imageFile) {
      image = document.getElementById(key);
    } else if (this.audioFile) {
      image = document.getElementById(key);
    } else if (this.videoFile) {
      image = document.getElementById(key);
    }
    if (this.audioFile || this.videoFile) {
      image.src = window.URL.createObjectURL(response);
      image.load();
      image.loop = true;
      this.medialink = window.URL.createObjectURL(response);
    } else {
      const reader = new FileReader();
      reader.onload = ( (i) => {
        return function(e) {
          if (i != null ) {
            i.src = e.target.result;
          }
        };
      })(image);
      reader.readAsDataURL(response);
    }
  }

}
