<div fxFlexLayout="row">
  <div fxFlex fxFlexOffset="20px">
    <h3>New Project: from Project Template</h3>
    @if (showMessage) {
      <app-message-box #messageBox message="{{message}}"></app-message-box>
    }
    <form novalidate [formGroup]="projectDialogForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      <mat-dialog-content>
        <p class="mt-3">
          <mat-radio-group aria-label="Select visibility" formControlName="visibility">
            <mat-radio-button (change)="getProjectOnVisibilityChange($event)" color="primary" value="public" class="mr-4" [checked]="true"><span>{{ "PUBLIC" | translate }}</span></mat-radio-button>
            <mat-radio-button (change)="getProjectOnVisibilityChange($event)" color="primary" value="private"><span>{{ "PRIVATE" | translate }}</span></mat-radio-button>
          </mat-radio-group>
        </p>
        <p>
          <span class="half-width"><span>{{ "PROJECT TEMPLATE" | translate }}</span></span>
          <mat-select class="ml-3" class="half-width" (selectionChange)="getProjectOnChange($event)" required formControlName="project_id" [placeholder]="'SELECT PROJECT' | translate">
            <mat-option></mat-option>
            @for (project of projects; track project) {
              <mat-option [value]="project.id">{{ project.name }}</mat-option>
            }
          </mat-select>
        </p>
        <p>
          <mat-form-field class="full-width">
            <input matInput autocomplete="off" formControlName="name" value="{{name}}" [placeholder]="'NAME' | translate" type="text" required>
            @if (projectDialogForm.get('name').hasError('required') && projectDialogForm.get('name').touched) {
              <mat-error><span>{{ "PROJECT NAME IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="full-width">
            <input matInput autocomplete="off" formControlName="description" value="{{description}}" [placeholder]="'DESCRIPTION' | translate" type="text">
          </mat-form-field>
        </p>
      </mat-dialog-content>
      <app-dialog-actions [mode]="mode" [formCheck]="projectDialogForm"></app-dialog-actions>
    </form>
  </div>
</div>
