<div fxFlexLayout="row">
  <div fxFlex fxFlexOffset="20px">
    <h3 class="pb-3">{{ mode.text }} {{ 'AUDIO FIELD' | translate }}</h3>
    <form novalidate [formGroup]="audioFieldForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      @if (showMessage) {
        <app-message-box style="margin-left: -10px;" #messageBox message="{{message}}"></app-message-box>
      }
      @if (nameLengthExceed10) {
        <div class="alert alert-warning" role="alert">
          {{"NAME_ADVICE_FOR_SHAPE_FILE_1" | translate }} <br />
          {{"NAME_ADVICE_FOR_SHAPE_FILE_2" | translate }}
        </div>
      }
      <mat-dialog-content>
        <p>
          <mat-form-field class="full-width">
            <mat-label><span>{{ "LABEL" | translate }}</span></mat-label>
            <input matInput autocomplete="off" formControlName="label" [placeholder]="'LABEL' | translate" (keyup)="onLabelChange();" type="text" required>
            @if (audioFieldForm.get('label').hasError('required') && audioFieldForm.get('label').touched) {
              <mat-error><span>{{ "FIELD LABEL IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="full-width">
            <mat-label><span>{{ "NAME" | translate }}</span></mat-label>
            <input matInput autocomplete="off" formControlName="name" [placeholder]="'NAME' | translate" (keyup)="onNameChange();" type="text" required>
            @if (audioFieldForm.get('name').hasError('required') && audioFieldForm.get('name').touched) {
              <mat-error><span>{{ "FIELD NAME IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
          <small style="margin-top: -5px;" class="form-text text-muted ">The name is used by MyCumulus .</small>
        </p>

        <p>
          <mat-form-field class="full-width">
            <mat-label><span>{{ "DESCRIPTION" | translate }}</span></mat-label>
            <input matInput autocomplete="off" formControlName="description" [placeholder]="'DESCRIPTION' | translate" type="text">
          </mat-form-field>
        </p>
        <p>
          <mat-checkbox color="primary" formControlName="visible" class="full-width" checked name="visible"><span>{{ "VISIBLE" | translate }}</span></mat-checkbox>
        </p>
        <p>
          <mat-checkbox color="primary" formControlName="enabled" class="full-width" checked name="enabled"><span>{{ "ENABLED" | translate }}</span></mat-checkbox>
        </p>
        <p>
          <mat-checkbox color="primary" formControlName="required" class="full-width" name="required"><span>{{ "REQUIRED" | translate }}</span></mat-checkbox>
        </p>
      </mat-dialog-content>
      <app-dialog-actions [mode]="mode" [formCheck]="audioFieldForm"></app-dialog-actions>
    </form>
  </div>
</div>
