<ng-container *ngIf="item">
  <!-- Non-Container Item -->
  <ng-container *ngIf="!item.isContainer; else containerTemplate">
    <div class="item" cdkDrag [id]="item.uId">
      <div class="item-content" style="display: flex; flex-direction: row">
        <p class="name">{{ item.name }}</p>
        <p class="label">{{ item.label }}</p>
        <p class="class">{{ item._class }}</p>
        <p class="description">{{ item.description || '' }}</p>
        <div id="constraints" class="constraints">
          <ng-container *ngIf="isFieldHaveConstraint(); else noConstraintTemplate">
            <ng-container *ngFor="let constraint of item.constraints; trackBy: trackByConstraint">
              <span
                (click)="openFieldConstraintPage(item)"
                [matTooltip]="getConstraintText(constraint['_class'])"
                class="mr-2 btn-pointer"
              >
                {{ getConstraintText(constraint['_class']) }}
              </span>
            </ng-container>
          </ng-container>
          <ng-template #noConstraintTemplate>
            <strong>
              <button mat-button class="icon-button" (click)="openFieldConstraintPage(item)">
                <fa-icon [icon]="faPlus"></fa-icon>
              </button>
            </strong>
          </ng-template>
        </div>
        <div class="action-btns">
          <div class="upBtn">
            <button class="menu-btn" (click)="moveUp()">
              <fa-icon class="itemIcon" [icon]="faAngleUp"></fa-icon>
            </button>
          </div>
          <div class="downBtn">
            <button class="menu-btn" (click)="moveDown()">
              <fa-icon class="itemIcon" [icon]="faAngleDown"></fa-icon>
            </button>
          </div>
          <div class="editBtn">
            <button class="menu-btn" (click)="openUpdateField(item)">
              <fa-icon class="itemIcon" [icon]="faPenToSquare"></fa-icon>
            </button>
          </div>
          <div class="deleteBtn">
            <button class="menu-btn" (click)="openDeleteConfirmationDialog(item)">
              <fa-icon class="itemIcon" [icon]="faDeleteLeft"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #containerTemplate>
    <div
      [class]="item.uId === store.selectedItemId ? 'fieldset selectedFs' : 'fieldset'"
      cdkDrag
      cdkDropList
      [id]="item.uId"
      [cdkDropListData]="item.children"
      [cdkDropListConnectedTo]="connectedTo"
      (cdkDropListDropped)="onDragDrop($event)"
    >
      <div
        (click)="selectFieldset()"
        [ngClass]="{ 'no-hover': isMouseDown }"
        class="fieldsetInfoBar"
      >
        <p>
          <fa-icon class="grip-icon" [icon]="faGrip" size="2xs"></fa-icon>
          <ng-container *ngIf="item._class === 'fieldset'; else arrayIcon">
            <fa-icon [icon]="faObjectGroup" size="1x"></fa-icon>
          </ng-container>
          <ng-template #arrayIcon>
            <fa-icon [icon]="faThLarge" size="1x"></fa-icon>
          </ng-template>
          {{ item.name | titlecase }}
        </p>
        <div class="action-btns-fieldset">
          <div class="upBtn">
            <button class="menu-btn-container" (click)="moveUp()">
              <fa-icon [icon]="faAngleUp"></fa-icon>
            </button>
          </div>
          <div class="downBtn">
            <button class="menu-btn-container" (click)="moveDown()">
              <fa-icon [icon]="faAngleDown"></fa-icon>
            </button>
          </div>
          <div class="editBtn">
            <button class="menu-btn-container" (click)="openUpdateField(item)">
              <fa-icon [icon]="faPenToSquare"></fa-icon>
            </button>
          </div>
          <div class="deleteBtn">
            <button class="menu-btn-container" (click)="openDeleteConfirmationDialog(item)">
              <fa-icon [icon]="faDeleteLeft"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <ng-container class="childContainer">
        <ng-container
          *ngFor="let nextItem of item.children; let i = index; trackBy: trackById"
        >
          <div [class]="nextItem.isContainer ? 'sub-container' : 'sub-item'">
            <list-item
              [item]="nextItem"
              [parent]="item"
              [index]="i"
              [connectedTo]="connectedTo"
              (itemDrop)="onDragDrop($event)"
            ></list-item>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</ng-container>
