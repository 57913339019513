<div class="rootContainer" cdkDropList [id]="root.uId" [cdkDropListData]="root.children" [cdkDropListConnectedTo]="connectedTo"
     (cdkDropListDropped)="onDragDrop($event)">
  <ng-container *ngFor="let item of root.children; trackBy: trackById; let i = index">
    <list-item class="listItem"
               [item]="item"
               [parent]="root"
               [index]="i"
               [connectedTo]="connectedTo"
               (itemDrop)="onDragDrop($event)">
    </list-item>
  </ng-container>
</div>
