<div fxFlexLayout="row"  class="dialog-content" style="padding: 0px !important" cdkDragHandle cdkDrag cdkDragRootElement=".cdk-overlay-pane" >
    <div fxFlex style="width: 100% !important; margin: 0px !important; padding: 0px !important">
        <h3 style="justify-content: start !important; margin-left: 2px;"><span>{{templateName}}</span></h3>
        <hr style="border: 1px solid #9e9d99 !important; background-color: #9e9d99 !important;" />
        <mat-dialog-content #scrollContainer>
            <table style="border-collapse: collapse !important; margin-top: -2px !important; width: 100% !important;">
                <tr style="margin-bottom: auto !important; border: 1px solid #aaa9a7 !important; background-color: #c7c7c4 !important;">
                    <th style="width: 33% !important; ">
                        <h6 class="mt-2" style="justify-content: start !important; margin-left: 2px;"><span>{{ "FIELD" | translate }}</span></h6>
                    </th>
                    <th style="width: auto !important; border-left: 1px solid #aaa9a7 !important;">
                        <h6 class="mt-2" style="justify-content: start !important; margin-left: 6px;"><span>{{ "VALUE" | translate }}</span></h6>
                    </th>
                </tr>
                <tr *ngFor="let item of items; let i = index; trackBy: trackByFn" style="margin-bottom: auto !important; border: 1px solid #aaa9a7 !important;">
                    <td  style="width: 33% !important;">
                        <h6 class="mt-2 mr-2" style="justify-content: start !important; margin-left: 2px;">
                            {{item.key}}
                        </h6>
                    </td>
                    <td (dblclick)="editFormValue(item)" style="width: auto !important; border-left: 1px solid #aaa9a7 !important; padding-right: 10px !important;">
                        <ng-template *ngIf="item.file; then fileContainer; else textContainer"></ng-template>
                        <ng-template #textContainer>
                            <span class="text-left" style="float: left; margin-left: 6px; cursor: text;"> {{ getValue(item.value)}}</span>
                        </ng-template>

                        <ng-template #fileContainer>
                            <span *ngIf="item.fileloading">File loading ...., please wait</span>
                            <ng-template *ngIf="audioFile; then audiocontainer; else mimecontainer"></ng-template>
                            <ng-template #audiocontainer>
                                <audio controls>
                                        <source [id]="item.key" src="" type="{{filetype}}" />
                                    Your browser does not support the audio element.
                                </audio>
                            </ng-template>
                            <ng-template #mimecontainer>
                                <ng-template *ngIf="imageFile; then imagecontainer; else videocontainer"> </ng-template>
                                <ng-template #imagecontainer>
                                  <a href="{{item}}" (click)="openMedia($event, item.value)" alt="">
                                    <img *ngIf="!item.fileloading" src="/assets/images/photo_display.png">
                                  </a>
                                </ng-template>
                                <ng-template #videocontainer>
                                    <ng-template *ngIf="videoFile; then vidcontainer;"> </ng-template>
                                    <ng-template #vidcontainer>
                                      <video width="400" title="" color="accent" spinner="spin" poster="image.jpg">
                                          <source [id]="item.key" src="" type="video/mp4" />
                                      </video>
                                    </ng-template>
                                </ng-template>
                            </ng-template>
                        </ng-template>
                    </td>
                </tr>
            </table>
        </mat-dialog-content>
        <mat-dialog-actions class="button-group">
<!--            <span class="flex-spacer"></span>-->
            <button mat-button mat-dialog-close class="btn-border">{{ "CLOSE" | translate }}</button>
        </mat-dialog-actions>
    </div>
</div>
