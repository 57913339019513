<div class="import-csv-container">
  @if (showMessage) {
    <div class="alert-box">
      <strong>{{message}}</strong><br/>
      @if (importDataError && importDataError.length > 0) {
        <button type="button" class="close-btn" (click)="closePanel()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <span class="details-btn" (click)="showErrorDlg()"><span>{{ "CLICK HERE FOR MORE DETAILS" | translate }}</span></span>
      }
    </div>
  }

  <div class="main-content">
    <h3 class="title"><span>{{ "IMPORT CSV" | translate }}</span></h3>
    <mat-horizontal-stepper #tabRef>
      <mat-step label="Choose CSV file">
        <div class="card">
          <div class="card-body">
            <form novalidate [formGroup]="importFileForm" #fform="ngForm">
              <p>
                <mat-form-field class="full-width">
                  <input matInput class="h4" [placeholder]="'FILE TO IMPORT (CSV)' | translate" readonly>
                  <input type="file" class="file-input" id="userFile" (change)="setFileData($event)" #fileInput accept=".csv, .txt">
                </mat-form-field>

                @if (noFileIsSelected) {
                  <mat-error><span>{{ "FILE IS REQUIRED" | translate }}</span></mat-error>
                }
              </p>
            </form>
          </div>
        </div>

        <div class="button-group">
          <button mat-stroked-button type="button" class="button"  ><span>{{ "CANCEL AND CLOSE" | translate }}</span></button>
          <button mat-stroked-button class="button" [disabled]="!showFieldContainer" matStepperNext><span>{{ "NEXT" | translate }}</span></button>
        </div>
      </mat-step>
      <mat-step label="Form">
        <form novalidate [formGroup]="importFileForm">
          <h3 class="section-title"><span>{{ "COORDINATE REFERENCE SYSTEM" | translate }}</span></h3>
          <p class="form-group">
            <mat-select class="select-input" formControlName="crs" [placeholder]="'COORDINATE REFERENCE SYSTEM' | translate">
              <mat-option [value]="null"><span>{{ "NONE" | translate }}</span></mat-option>
              @for (coordRef of crsList; track coordRef) {
                <mat-option [value]="coordRef.value">{{ coordRef.text }}</mat-option>
              }
            </mat-select>
            @if (isCrsUTM) {
              <mat-select class="select-input" [placeholder]="'COORDINATE REFERENCE SYSTEM' | translate">
                <mat-option></mat-option>
                @for (utmzone of utmZones; track utmzone) {
                  <mat-option [value]="utmzone">{{ utmzone }}</mat-option>
                }
              </mat-select>
            }
          </p>
          <p class="form-group">
            <mat-radio-group class="radio-group" formControlName="typetemplate">
              <mat-radio-button color="primary" class="radio-button" [value]="'new_template'" [checked]="store.importDataInNewForm" (change)="typeTemplateChange($event, 'new_template')"><span>{{ "IMPORT IN NEW FORM" | translate }}</span></mat-radio-button>
              <mat-radio-button color="primary" class="radio-button" [value]="'existing_template'" [checked]="!store.importDataInNewForm" (change)="typeTemplateChange($event, 'existing_template')"><span>{{ "IMPORT IN EXISTING FORM" | translate }}</span></mat-radio-button>
            </mat-radio-group>
          </p>
          <div [hidden]='!importInExistingTemplate'>
            <strong class="form-label"><span>{{ "FORM" | translate }}</span></strong>
            <p>
              <mat-select class="select-input" formControlName="template" required [placeholder]="'CHOOSE FORM' | translate">
                <mat-option></mat-option>
                @for (templa of templates; track templa) {
                  <mat-option [value]="templa.name">{{ templa.name }}</mat-option>
                }
              </mat-select>
              @if (importFileForm.get('template').hasError('required')) {
                <mat-error><span>{{ "FORM NAME IS REQUIRED" | translate }}</span></mat-error>
              }
            </p>
          </div>
          <p [hidden]='!importInNewTemplate'>
            <mat-form-field class="full-width">
              <input matInput autocomplete="off" formControlName="template" [placeholder]="'FORM NAME' | translate" type="text" required>
              @if (importFileForm.get('template').hasError('required') && importFileForm.get('template').touched) {
                <mat-error><span>{{ "FORM NAME IS REQUIRED" | translate }}</span></mat-error>
              }
            </mat-form-field>
          </p>
        </form>
        @if (showFieldContainer) {
          <div class="table-container">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center"><span>{{ "FIELD NAME" | translate }}</span></th>
                  <th class="text-center"><span>{{ "FIELD LABEL" | translate }}</span></th>
                  <th class="text-center"><span>{{ "FIELD TYPE" | translate }}</span></th>
                  <th class="text-center"><span>{{ "COORDINATE" | translate }}</span></th>
                </tr>
              </thead>
              <tbody>
                @for (f of fields; track f) {
                  <tr>
                    <th scope="row">
                      <input type="text" name="name" [(ngModel)]="f.name" disabled class="form-control">
                    </th>
                    <td>
                      <input matInput [(ngModel)]="f.label" required name="label" type="text" class="form-control">
                    </td>
                    <td>
                      <select class="custom-select" [(ngModel)]="f._class" (ngModelChange)="changedFieldType(f._class, f)">
                        @for (fieldType of fieldTypes; track fieldType) {
                          <option [value]="fieldType.value">{{ fieldType.text }}</option>
                        }
                      </select>
                    </td>
                    <td>
                      <label class="custom-checkbox">
                        <mat-checkbox [(ngModel)]="f.isCoordinate"></mat-checkbox>
                      </label>
                      @if (f.isCoordinate) {
                        <select (ngModelChange)="checkCoordinateType($event, f)" [(ngModel)]="f.typeCoordinate">
                          @for (typeCoord of coordsList; track typeCoord) {
                            <option [value]="typeCoord">{{ typeCoord }}</option>
                          }
                        </select>
                      }
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        }
        <div class="button-group">
          <button mat-stroked-button type="button" class="button"  ><span>{{ "CANCEL AND CLOSE" | translate }}</span></button>
          <button mat-stroked-button class="button" matStepperPrevious><span>{{ "BACK" | translate }}</span></button>
          <button mat-stroked-button class="button" [disabled]="importFileForm.invalid" (click)="goToNextStep()"><span>{{ "NEXT" | translate }}</span></button>
        </div>
      </mat-step>
      <mat-step label="Data preview">
        <div class="table-container mt-2">
          <table mat-table class="table" [dataSource]="dataSource">
            @for (column of displayedColumns; track column) {
              <ng-container [matColumnDef]="column">
                <th class="table-header" *matHeaderCellDef> {{column}} </th>
                <td class="table-cell" *matCellDef="let element"> <span> {{element[column]}}</span></td>
              </ng-container>
            }
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <div class="button-group">
          <button mat-stroked-button class="button" matStepperPrevious><span>{{ "BACK" | translate }}</span></button>
          <button class="button cancel-btn" mat-stroked-button type="button"><span>{{ "CANCEL" | translate }}</span></button>
          <button *ngIf="!isFileProcessing" mat-button [disabled]="isFileProcessing || importFileForm.get('template').hasError('required')" (click)="onSubmit()" class="submit-btn"><span>{{ "IMPORT DATA" | translate }}</span></button>
          <button *ngIf="isFileProcessing" mat-button [disabled]="isFileProcessing" class="submit-btn"><fa-icon class="fa fa-spinner fa-spin" [icon]="faSpinner"></fa-icon><span class="import-data-text">{{ "IMPORT DATA" | translate }}</span></button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
