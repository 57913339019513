<div fxFlexLayout="row">
  <div fxFlex>
    <mat-dialog-content>
      <mat-card appearance="outlined" class="media-content text-center ml-3 mr-3">
        @if (showMessage) {
          <app-message-box #messageBox message="{{message}}"></app-message-box>
        }
        @if (fileloading) {
          <span>File loading ...., please wait</span>
        }
        <mat-card-content [hidden]="fileloading">
          @if (audioFile) {
            <audio id="audioPlayer" controls>
              <source [src]="imageLing" type="{{filetype}}">
              Your browser does not support the audio element.
            </audio>
          } @else {
            @if (imageFile) {
              <img id="myImage" mat-card-image  [src]="imageLing" alt="Photo of a Shiba Inu">
            } @else {
              @if (videoFile) {
                <video id="myVideo" width="400" height="400" controls>
                </video>
            } @else {
              <span>{{ "NOT SUPPORTED FILE EXTENSION" | translate }}</span>
            }
          }
        }
      </mat-card-content>
    </mat-card>
  </mat-dialog-content>
</div>
</div>
