import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { StoreService } from 'src/app/services/store.service';
import { StyleServiceService } from 'src/app/services/style-service.service';
import { Style } from 'src/app/share/style';
import { User } from 'src/app/share/user';
import { getAdminToken, getToken, getUser } from 'src/app/share/utils';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { StyleNewComponent } from '../dialogs/style-new/style-new.component';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { MatIcon } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../services/toast.service';

const ELEMENT_DATA: Style[] = [];
@Component({
  standalone: true,
  imports: [TranslateModule,
    MatIcon,
    MatDividerModule,
    MessageBoxComponent,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    CommonModule,
    MatButtonModule,
  ],
  selector: 'app-template-style-list',
  templateUrl: './template-style-list.component.html',
  styleUrls: ['./template-style-list.component.scss']
})
export class TemplateStyleListComponent implements OnInit {

  user: User;
  isReportAdmin: boolean;
  displayedColumns: string[] = ['name', 'description', 'created_at', 'actions'];
  listStyles: Style[] = [];
  dataSource = new MatTableDataSource<Style>(ELEMENT_DATA);
  selection = new SelectionModel<Style>(true, []);
  searchValue = '';
  visibility: string = null;




  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;
  constructor(
    private dialog: MatDialog,
    public store: StoreService,
    private translate: TranslateService,
    private styleService: StyleServiceService,
    public toastService: ToastService,
  ) { }

  ngOnInit() {
    const admin = getUser();

    if(admin.admin_type === 'report_template_administrator'){
      this.isReportAdmin = true;
    } else {
      this.isReportAdmin = false;
    }
    this.visibility = null;
    this.getListeStyles();
  }

  openCreateTemplateStyleDialog(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      mode: {
        text: this.translate.instant('CREATE'),
        function: 'Create'
      },
    };
    const dialogRef = this.dialog.open(StyleNewComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Add') {
        const templateStyleData = result.data;
        this.store.showLoading();
        const data = this.dataSource.data;
        data.push(templateStyleData);
        this.dataSource.data = data;
        this.table.renderRows();
        this.store.updatedForm = false;
        this.store.hideLoading();
      } else if (result.event === 'Cancel') {
        this.store.hideLoading();
      }
    });
  }

  canEditOrDelete(element): boolean {
    const user = getUser();
    if(element.created_by === user.id) {
        return true;
    }

    return false;
  }

  getListeStyles(): void {
    let token = getToken();
    let isMyCumulusAdministrator = false;
    if(token === null || token === undefined) {
      token = getAdminToken();
      isMyCumulusAdministrator = true;
    }
    this.store.showLoading();
    this.styleService.getStyles(token, this.visibility, isMyCumulusAdministrator).subscribe(
      res => {
        this.listStyles = res;
        const data = this.listStyles.filter(style => style.created_by === token.user_id);
        this.dataSource.data = data;
        this.table.renderRows();
        this.store.hideLoading();
      },
      errmess => {
        this.store.hideLoading();
        this.toastService.errorToast(errmess);
    });
  }

  downloadTemplateStyleDialog(style) {
    this.downloadJson(style.name, style);
    // let token = getToken();
    // let isMyCumulusAdministrator = false;
    // if(token === null || token === undefined) {
    //   token = getAdminToken();
    //   isMyCumulusAdministrator = true;
    // }
    // this.store.showLoading();
    // this.styleService.getStyle(style.id, token, isMyCumulusAdministrator).subscribe(
    //   res => {

    //     this.store.hideLoading();
    //   },
    //   errmess => {
    //     this.store.hideLoading();
    //     this.toastService.errorToast(errmess);
    // });
  }

  downloadJson(stylename, jsonData) {
    const jsonStr = JSON.stringify(jsonData, null, 2); // Convert JSON object to string with indentation
    const blob = new Blob([jsonStr], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${stylename}.json`; // The name of the downloaded file
    a.click();
    window.URL.revokeObjectURL(url); // Clean up
  }

  openUpdateTemplateStyleDialog(style) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      mode: {
        text: this.translate.instant('UPDATE'),
        function: 'Update'
      },
      style: style
    };
    const dialogRef = this.dialog.open(StyleNewComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Update') {
        const templateStyle = result.data;
        const data = this.dataSource.data;
        const index = data.findIndex(res => res.id === templateStyle.id);

        if (index >= 0) {
          const deletedData = data.splice(index, 1, templateStyle);
          this.dataSource.data = data;
          this.table.renderRows();
          this.store.updatedForm = false;
        }
        this.store.hideLoading();
      } else if (result.event === 'Cancel') {
        this.store.hideLoading();
      }
    });
  }

  openDeleteTemplateStyleConfirmation(element) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        title: this.translate.instant("DELETE THE STYLE") + " " + element.name + "?",
        message: this.translate.instant("DO YOU REALLY WANT TO DELETE THE STYLE") + " " + element.name + "?"
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        let token = getToken();
        let isMyCumulusAdministrator = false;
        if(token === null || token === undefined) {
          token = getAdminToken();
          isMyCumulusAdministrator = true;
        }
        this.store.showLoading();
        this.styleService.deleteStyle(element, token, isMyCumulusAdministrator).subscribe(
          res => {
            this.store.hideLoading();
            this.getListeStyles();
          },
          errmes => {
            this.store.hideLoading();
            this.toastService.errorToast(errmes);
          }
        );
      }
    });
  }

  applyFilter(event) {
    event.preventDefault();
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    event.stopPropagation();
  }

}
