<div class="formDataContainer">
  <div class="table-header">
    <app-message-box #messageBox class="" *ngIf="reportService.showMessage"
                     message="{{reportService.message}}"></app-message-box>
    <div class="row-container">
      <div class="delete-div">
        <span class="delete-btn" mat-button (click)="removeSelectedFeature()"
              [matTooltip]="'DELETE SELECTED RECORDS' | translate">
           <img src="/assets/images/trash-can.svg" alt="Delete"/>
        </span>
      </div>

<!--      <div class="search-div">-->
<!--        <div id="iconDiv">-->
<!--          <input class="search-bar" type="text" autocomplete="off"-->
<!--                 (keyup)="getValue($event.target.value)"-->
<!--                 [placeholder]="'SEARCH...' | translate"-->
<!--                 [(ngModel)]="searchValue">-->
<!--        </div>-->
<!--      </div>-->

      <div class="header-counter">
        <button class="view-btn" [matTooltip]="'VIEW' | translate" (click)="showViewDialog()">
          <fa-icon [icon]="faEye" size="2x"></fa-icon>
        </button>

        <span *ngIf="reportService.pageFeatures && reportService.totalFeatures"
              class="tracker"><span>{{ reportService.initValue }}</span>-<span>{{ reportService.pageFeatures + " " }}</span>{{ "OF" | translate }}<span>{{ " " + reportService.totalFeatures }}</span></span>
        <ng-template *ngIf="isMorePages(); then display; else hide"></ng-template>
        <ng-template class="page-nav" #display>
          <button class="prevFeatures" [disabled]="reportService.prevDisabled" (click)="loadPrevFeatures()"> <</button>
          <mat-select (selectionChange)="this.openPage($event.value)" class="page-select"
                      [(ngModel)]="reportService.selectedPage">
            <mat-option class="page-option" *ngFor="let item of reportService.allPages; trackBy: trackByFn" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
          <button class="nextFeatures" [disabled]="reportService.nextDisabled" (click)="loadNextFeatures()"> ></button>
        </ng-template>
        <ng-template #hide></ng-template>
      </div>
    </div>
  </div>

  @if (dataService.template?.value) {
    @if (!dataService.selectingTemplate) {
      @if(reportService.displayedColumns.length === 0) {
        <div class="table-body">
          <div class="cloud-container">
            <div class="m-3">
              <span class="loader"></span>
              <span class="text-nodata">{{ "NO DATA" | translate }}</span>
            </div>
          </div>
        </div>
      } @else {
        <div class="table-body">
          <div class="dataContainer">
              <table mat-table [dataSource]="reportService.dataSource"
                     class="table custom-table">
                <ng-container [matColumnDef]="column" *ngFor="let column of reportService.displayedColumns; trackBy: trackByFn">
                  <th *matHeaderCellDef>
                    <ng-template *ngIf="isSelectFeatureHeader(column); then selectheader ; else dataheader"></ng-template>
                    <ng-template #selectheader>
                      <mat-checkbox
                        class="select-checkbox header-checkbox"
                        (change)="selectAllFeatures($event)"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
                      </mat-checkbox>
                    </ng-template>
                    <ng-template #dataheader>
                      <span [innerHTML]="getHeaderInSplitMode(column)"></span>
                    </ng-template>
                  </th>
                  <td *matCellDef="let element" (dblclick)="openEditFeatureValue(column, element)">
                    <ng-template
                      *ngIf="isSelectFeatureData(column, element[column]); then selecttemplate; else datatempl;"></ng-template>
                    <ng-template #selecttemplate>
                  <span>
                    <mat-checkbox class="select-checkbox" (change)="selectFeature($event, element)"
                                  [checked]="selection.isSelected(element)"
                                  [aria-label]="checkboxLabel(element)"></mat-checkbox>
                  </span>
                    </ng-template>
                    <ng-template #datatempl>
                      <ng-template
                        *ngIf="isDataIsFile(column, element[column]); then fileblock; else attdata;"></ng-template>
                      <ng-template #fileblock>
                        <a href="{{item}}" *ngFor="let item of getFilesUrl(element[column]); trackBy: trackByFn"
                           (click)="dataService.openMediaShowingDialog($event, item)" alt="">
                          <img src="{{dataService.getIcon(item)}}" alt="">
                        </a>
                      </ng-template>
                      <ng-template #attdata>
                        <ng-template *ngIf="checkIfColumnIsGeometry(column); then geom; else simpledata"></ng-template>
                        <ng-template #geom>
                        <span class="geomelement"
                              (click)="displayCoordinates(column, element)">{{ displayItemValue(column, element[column]) }}</span>
                        </ng-template>
                        <ng-template #simpledata>
                          <ng-template
                            *ngIf="checkIfCoordinatesField(column); then coordinates; else displaydata"></ng-template>
                          <ng-template #coordinates>
                          <span class="geomelement"
                                (click)="displayGPSCoordinates(column, element)">{{ displayItemValue(column, element[column]) }}</span>
                          </ng-template>
                          <ng-template #displaydata>
                            <ng-template
                              *ngIf="checkIfAttributesetArray(column); then attribsetarray; else otherdata"></ng-template>
                            <ng-template #attribsetarray>
                            <span class="geomelement"
                                  (click)="openAttributesetArrayDialog(column, element)">{{ displayAttSetArrayValue(column) }}</span>
                            </ng-template>
                            <ng-template #otherdata>
                              <span>{{ displayItemValue(column, element[column]) }}</span>
                            </ng-template>
                          </ng-template>
                        </ng-template>
                      </ng-template>
                    </ng-template>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="reportService.displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: reportService.displayedColumns;"></tr>
              </table>
            </div>
        </div>
      }
    } @else {
      <div class="table-body">
        <div class="cloud-container">
          <div class="m-3">
            <span class="loader"></span>
            <span class="loading-loader">L &nbsp; ading</span>
          </div>
        </div>
      </div>
    }
  } @else {
    <div class="table-body">
      <div class="dataContainer">
        <div class="m-3">
        </div>
      </div>
    </div>
  }
</div>
