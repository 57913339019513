@if (project) {
  <div class="project-item-container" (click)="handleClick(project)">
    <div class="project">
      <div class="imgContainer">
        @if(dataService.setCircleLetters(project).length > 2) {
          <img class="rounded-circle" [src]="dataService.setCircleLetters(project)" alt="">
        } @else {
          <h4 class="rounded-circle">{{dataService.setCircleLetters(project)}}</h4>
        }
      </div>
      <div class="dataContainer">
        <h5 class="projectName">{{ project.name }}</h5>
        <div id="scroll-container">
          <div id="scroll-text">
            <h6 class="projectDes">{{ project.description }}</h6>
          </div>
        </div>
      </div>
      <div class="project-dropdown-container">
        <button (click)="stopPropagate($event)" [matMenuTriggerFor]="projMenu" class="project-settings">
          <fa-icon [class]="getUser().id === project.created_by ? 'ownedBars' : 'normalBars'" [icon]="faBars" size="2x"></fa-icon>
        </button>
        <mat-menu class="text-black" #projMenu="matMenu">
          <button mat-menu-item [disabled]="getUser().id !== project.created_by" (click)="headerService.openUpdateProjectDialog(project)"><span>{{ "EDIT" | translate }}</span></button>
          <button mat-menu-item (click)="headerService.duplicateProject(project)"><span>{{ "DUPLICATE" | translate }}</span></button>
          <button mat-menu-item [disabled]="getUser().id !== project.created_by" (click)="headerService.openProjectMembersDialog(project)"><span>{{ "MANAGE MEMBERS" | translate }}</span></button>
          <button mat-menu-item [disabled]="getUser().id !== project.created_by" (click)="headerService.removeProject(project)"><span>{{ "DELETE" | translate }}</span></button>
        </mat-menu>
      </div>
    </div>
  </div>
}
