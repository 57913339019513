import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { getToken, getUser, isPrivateServer, getApiAURL } from 'src/app/share/utils';
import { User } from 'src/app/share/user';
import { Report } from 'src/app/share/report';
import { ReportsService } from 'src/app/services/reports.service';
import { baseDomain } from '../../share/baseurl';
import { StoreService } from 'src/app/services/store.service';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import {CommonModule} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faDownload} from '@fortawesome/free-solid-svg-icons';
import {ToastService} from '../../services/toast.service';

const ELEMENT_DATA: Report[] = [];

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatDividerModule,
    MessageBoxComponent,
    MatTableModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    CommonModule,
    FontAwesomeModule
  ],
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  domain: string = baseDomain;
  user: User;
  displayedColumns: string[] = ['select', 'name', 'actions'];

  dataSource = new MatTableDataSource<Report>(ELEMENT_DATA);
  selection = new SelectionModel<Report>(true, []);
  searchValue: string;

  @ViewChild(MatTable, {static: true}) reporttable: MatTable<any>;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  constructor(
    private dialog: MatDialog,
    private reportService: ReportsService,
    private translate: TranslateService,
    public store: StoreService,
    public toastService: ToastService,
  ) { }

  ngOnInit() {
    this.user = getUser();
    const token = getToken();
    if (isPrivateServer()) {
      if (getApiAURL() !== null) {
        this.domain = getApiAURL();
      }
    }
    this.store.showLoading();
    this.reportService.getReports(this.user.id, token).subscribe(res => {
      let data = this.dataSource.data;
      data = [];
      for (const report of res) {
        data.push(report);
      }
      this.dataSource.data = data;
      this.store.hideLoading();
    },
    err => {
      this.toastService.errorToast(err);
      this.store.hideLoading();
    });
  }

  navigateToUrl(downloadUrl: string): void {
    const url = `${this.domain}${downloadUrl}`;
    window.location.href = url;
  }

  OpenDeleteReportConfirmation(element) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        message: this.translate.instant("THIS REPORT WILL BE DEFINITELY REMOVED"),
        title:  this.translate.instant("DELETE REPORT") + " " + element.filename + "?"
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const token = getToken();

        this.store.showLoading();
        this.reportService.deleteReport(element, token).subscribe(
          res => {
            const data = this.dataSource.data;
            const newData = data.filter(report => report.id !== res.id);
            this.dataSource.data = newData;
            this.reporttable.renderRows();
            this.store.hideLoading();
          },
          errmes => {
            this.store.hideLoading();
            this.toastService.errorToast(errmes);
          }
        );
      }
    });
  }

  removeSelectedReport() {
    if (this.selection.selected.length <= 0) {
      const message = 'Select at least one report to delete.';
      this.toastService.errorToast(message);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        message: this.translate.instant("DO YOU WANT TO DELETE THE SELECTED REPORTS?"),
        title: this.translate.instant("DELETE REPORTS?")
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {

        const token = getToken();
        this.store.showLoading();
        this.selection.selected.map(element => {
          this.reportService.deleteReport(element, token).subscribe(
            res => {
              const data = this.dataSource.data;
              const newData = data.filter(report => report.id !== res.id);
              this.dataSource.data = newData;
              this.reporttable.renderRows();
              this.store.hideLoading();
            },
            errmes => {
              this.store.hideLoading();
              this.toastService.errorToast(errmes);
            }
          );
        });
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.map(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Report): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.filename + 1}`;
  }


  protected readonly faDownload = faDownload;
}

