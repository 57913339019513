import {Injectable, ViewChild} from '@angular/core';
import {
  getAdminToken,
  getToken, getUser,
  persistLastMenu,
  removeCurrentFeaturesUri,
  removeFieldToShowOnMap,
  removeSearchValue,
} from '../share/utils';
import {Token} from '../share/token';
import {MessageBoxComponent} from '../components/message-box/message-box.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CreateTemplateComponent} from '../components/dialogs/create-template/create-template.component';
import { IMPORT_DATA_MODE, SCHEMA_MODE, StoreService} from './store.service';
import {ConfirmationDialogComponent} from '../components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {DataService} from './data.service';
import {TitleCasePipe} from '@angular/common';
import {ImportTemplateFromDomainComponent} from '../components/dialogs/import-template-from-domain/import-template-from-domain.component';
import {CreateProjectComponent} from '../components/dialogs/create-project/create-project.component';
import {ImportProjectComponent} from '../components/dialogs/import-project/import-project.component';
import {ImportProjectFromDomainComponent} from '../components/dialogs/import-project-from-domain/import-project-from-domain.component';
import {Project} from '../share/projects';
import {ToastService} from './toast.service';
import {ProjectMemberDialogComponent} from '../components/dialogs/project-member-dialog/project-member-dialog.component';
import {ReportsService} from './reports.service';
import {TranslateService} from '@ngx-translate/core';



@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  constructor(
    public store: StoreService,
    public dialog: MatDialog,
    public titlecasePipe:TitleCasePipe,
    public dataService: DataService,
    private translate: TranslateService,
    public reportsService: ReportsService,
    public toastService: ToastService,
  ) {}

  username: string;
  updatingForm: boolean;
  templateNameWidth: number;
  messageBoxWidth: number;
  proClass: string = 'Projects';
  currentClass: ActiveClass = ActiveClass.NONE;


  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  searchValue: string;

  openProjectMembersDialog(project: Project) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '700px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      project
    };
    this.dialog.open(ProjectMemberDialogComponent, dialogConfig);
  }

  openEditTemplateDialogAfterCheck(template) {
    // const selectedTemplate = this.dataService.template.getValue();
    if (template === null) {
      this.toastService.errorToast('Select a Form')
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      project: this.dataService.project,
      template: template,
      mode: {
        text: this.translate.instant('UPDATE'),
        function: 'Update'
      },
    };
    this.dialog.open(CreateTemplateComponent, dialogConfig);
  }


  deleteTemplateAfterCheck(template) {
    // const selectedTemplate = this.dataService.template.getValue();
    if (template === undefined || template === null) {
      this.toastService.errorToast('No template is selected, please select the template to be deleted')
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant("REMOVE FORM") + " " + template.name + "?",
        message: this.translate.instant("WHEN YOU REMOVE THE FORM, ALL DATA RELATED TO IT IS LOST")
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '600px',
          data: {
            title: this.translate.instant("REMOVE FORM") + " " + template.name + "?",
            message: this.translate.instant("PLEASE THINK TWICE, THIS ACTION CANNOT BE UNDONE!") + "\n" + this.translate.instant("ARE YOU REALLY SURE YOU WANT TO DELETE THE FORM AND ITS DATA?")
          }
        });

        confirmDialogRef.afterClosed().subscribe(res  => {
          if (res) {
            this.deleteTemplateOnServer(template);
          }
        });
      }
    });
  }

  deleteTemplateOnServer(template) {
    // const selectedTemplate = this.dataService.template.getValue();
    let token = getToken();
    let isMyCumulusAdministrator =  false;

    if(token === null || token === undefined) {
      token = getAdminToken();
      if(token !== null && token !== undefined) {
        isMyCumulusAdministrator =  true;
      }
    }

    const project = this.dataService.project;

    this.store.showLoading();
    this.dataService.deleteTemplate(template, project.id, token, isMyCumulusAdministrator).subscribe(
      res => {
        this.dataService.deleteTemplateLocally(res);
        this.store.hideLoading();
        this.toastService.successToast('Template deleted successfully.');
      },
      errmes => {
        this.dataService.deleteTemplateLocally(template);
        this.store.hideLoading();
        this.toastService.errorToast(errmes)
      }
    );
  }

  duplicateTemplateAfterCheck(template) {
    // const selectedTemplate = this.dataService.template.getValue();
    if (template === undefined || template === null) {
      this.toastService.errorToast('No template is selected, please select the template to be duplicated')
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        message: this.translate.instant("A NEW FORM WILL BE MADE, RECORDS WILL NOT BE COPIED"),
        title: this.translate.instant("DUPLICATE FORM?"),
        formDuplication: true
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const token = getToken();
        this.sendDuplicateTemplateRequest(template);
      }
    });
  }

  sendDuplicateTemplateRequest(template) {
    this.dataService.testIfUpdatingForm(() => {
      this.sendDuplicateTemplateRequestAfterCheck(template);
    });
  }

  sendDuplicateTemplateRequestAfterCheck(templateToDupe) {
    // const selectedTemplate = this.dataService.template.getValue();
    const template = {
      name: templateToDupe.name + ' Copy',
      description: templateToDupe.description,
      project_id: templateToDupe.project_id,
      template_id: templateToDupe.id
    };
    let token = getToken();
    let isMyCumulusAdministrator = false;
    if(token === null || token === undefined){
      token = getAdminToken();
      if(token) {
        isMyCumulusAdministrator = true;
      }
    }
    const tempToken = {
      key: "key",
      value: token.key
    }
    const project = this.dataService.project;
    this.store.showLoading();
    this.dataService.duplicateTemplate(template, tempToken, isMyCumulusAdministrator).subscribe(
      res => {
        this.dataService.createTemplateLocally(res);
        this.store.hideLoading();
      },
      err => {
        this.toastService.errorToast(err);
        this.store.hideLoading();
      });
  }


  openAddTemplateFromDomainDialogAfterCheck() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = false;
    dialogConfig.hasBackdrop = true;

    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      mode: {
        text: this.translate.instant('CREATE'),
        function: 'Create'
      },
      proClass: 'domain'
    };
    this.dialog.open(ImportTemplateFromDomainComponent, dialogConfig);
  }

  openAddTemplateFromFileDialogAfterCheck() {
    persistLastMenu('import');
    //this.dataService.readTemplate(null);
    this.store.formMode = IMPORT_DATA_MODE;
    this.store.importDataInNewForm = true;
    this.dataService.showImportDataInTemplate();

    // this.dialog.open(ImportFileDialogComponent, dialogConfig);
  }

  openAddTemplateFromIMKLFileAfterCheck(type) {
    persistLastMenu('import');
    this.dataService.hideTemplate();
    this.store.typeOfIMKLFile.next(type);
    this.dataService.readTemplate(null);
    this.store.formMode = IMPORT_DATA_MODE;
    this.store.importDataInNewForm = true;
    this.dataService.showImportIMKLData(type);
  }

  openAddTemplateFromExcelFileAfterCheck(type) {
    persistLastMenu('import');
    this.dataService.hideTemplate();
    this.store.typeOfExcelFile.next(type);
    this.dataService.readTemplate(null);
    this.store.formMode = IMPORT_DATA_MODE;
    this.store.importDataInNewForm = true;
    this.dataService.showImportExcelData(type);
  }

  openAddTemplateFromShapeFileAfterCheck(type) {
    persistLastMenu('import');
    this.dataService.hideTemplate();
    this.store.typeOfIMKLFile.next(type);
    this.dataService.readTemplate(null);
    this.store.formMode = IMPORT_DATA_MODE;
    this.store.importDataInNewForm = true;
    this.dataService.showImportShapeFile(type);
  }

  openCreateProjectDialogAfterCheck() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    this.dialog.open(CreateProjectComponent, dialogConfig);
  }


  openImportDomainProjectDialogAfterCheck() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = false;
    dialogConfig.hasBackdrop = true;

    dialogConfig.autoFocus = true;
    ;
    let dialogRef = null;
    if(this.store.isMCAdmin) {
      dialogRef = this.dialog.open(ImportProjectFromDomainComponent, dialogConfig);
    } else {
      dialogRef = this.dialog.open(ImportProjectFromDomainComponent, dialogConfig);
    }
    dialogRef.afterClosed().subscribe(res  => {
      if(res !== null && res !== undefined && res !== "") {
        this.openTemplatePage(res);
      }
    });
  }

  getTemplates(p) {
    const token: Token = JSON.parse(localStorage.getItem('token'));

    this.store.showLoading();
    this.dataService.requestTemplates(token, p.id).subscribe(
      res => {
        // this.templates = res;
        this.dataService.readTemplates(res);
        this.store.hideLoading();
      },
      errmess => {
        this.store.hideLoading();
        this.toastService.errorToast(errmess);
      });
  }

  openTemplatePage(p) {

    removeFieldToShowOnMap();
    removeCurrentFeaturesUri();
    this.reportsService.lastExportedTemplate = null;
    this.store.features = null;
    this.dataService.readProject(p);
    this.dataService.template.next(null);
    this.dataService.selectedTemplate.next('');
    this.store.selectedFieldSet.next('');
    if(p !== null && p !== undefined) {
      this.getTemplates(p);
      this.dataService.showTemplates();
    } else {
      this.dataService.hideTemplate();
    }
  }

  openImportProjectDialogAfterCheck() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = false;
    dialogConfig.hasBackdrop = true;

    dialogConfig.autoFocus = true;
    this.dialog.open(ImportProjectComponent, dialogConfig);
  }

  openUpdateProjectDialog(project: Project) {
    this.dataService.testIfUpdatingForm(() => {
      const user = getUser();

      if (project){
        if(user.id !== project.created_by) {
          this.toastService.errorToast(`You do not have permissions to edit the selected ${project.project_class}`);
          return;
        } else {
          this.openUpdateProjectDialogAfterCheck(project);
        }
      } else {
        this.toastService.errorToast(`No ${project.project_class} is selected, please select the project to be updated`);
        return;
      }
    });
  }

  openUpdateProjectDialogAfterCheck(project: Project) {
    if (project === undefined || project === null) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`No ${projectClass} is selected, please select the project to be updated`);
      return;
    }

    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`Archived projects cannot be changed`);
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      project: project,
      mode: {
        text: this.translate.instant('UPDATE'),
        function: 'Update'
      },
    };
    this.dialog.open(CreateProjectComponent, dialogConfig);
  }

  removeProject(project: Project) {
    if(this.store.features && this.store.features.length > 0) {
      this.toastService.errorToast(`Deleting the project or form is not possible if any records are selected`);
      return;
    }
    this.dataService.testIfUpdatingForm(() => {
      const user = getUser();
      if (user.id === project.created_by) {
        this.removeProjectAfterCheck(project);
      }
    });
  }

  removeProjectAfterCheck(project: Project) {
    const proClass = this.titlecasePipe.transform(this.store.proClass);
    if (project === undefined || project === null) {
      this.toastService.errorToast(`No ${proClass} is selected, please select the project to be deleted`);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: this.translate.instant("\REMOVE PROJECT") + " " + project.name + "?",
        message: this.translate.instant("WHEN YOU REMOVE THIS PROJECT, ALL FORMS AND DATA WILL BE LOST")
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const secondDialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '600px',
          data: {
            title: this.translate.instant("ARE YOU SURE YOU WANT TO REMOVE THE PROJECT") + " " + project.name + "?",
            message: this.translate.instant("PLEASE THINK TWICE, THIS ACTION CANNOT BE UNDONE!") + "\n" + this.translate.instant("ARE YOU REALLY SURE YOU WANT TO DELETE THE PROJECT, ALL FORMS AND THE DATA?")
          }
        });

        secondDialogRef.afterClosed().subscribe(res  => {
          if (res) {
            this.deleteProjectOnServer(project);
          }
        });
      }
    });
  }

  deleteProjectOnServer(project: Project) {
    let token = getToken();
    let proToken = {
      key: '',
      value: ''
    };
    let isMyCumulusAdministrator = false;
    if(this.store.isMCAdmin) {
      token = getAdminToken();
      if(token) {
        proToken = {
          key: 'X-Admin-Auth-Token',
          value: token.key
        };
        isMyCumulusAdministrator = true;
      }
    } else {
      proToken = {
        key: 'X-Auth-Token',
        value: token.key
      };
    }

    this.store.showLoading();
    this.dataService.deleteProject(project, proToken, isMyCumulusAdministrator).subscribe(
      res => {
        this.dataService.deleteProjectLocally(res);
        project = null;
        this.store.hideLoading();
        window.location.reload();
      },
      errmes => {
        this.store.hideLoading();
        this.toastService.errorToast(errmes);
      }
    );
  }

  duplicateProject(project: Project) {
    this.dataService.testIfUpdatingForm(() => {
      this.duplicateProjectAfterCheck(project);
    });
  }

  duplicateProjectAfterCheck(project: Project) {
    // Do you really want to duplicate the project?
    // If yes, a new project will be made containing the same forms as the original project.
    // The records in the forms will not be copied!

    if (project === undefined || project === null) {
      this.toastService.errorToast('No Project | Template | Domain is selected, please select the project to be deleted');
      return;
    }
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`Please you cannot duplicate archived  ${projectClass}`);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        message: this.translate.instant("A NEW PROJECT WILL BE MADE, BUT THE RECORDS IN THE FORMS WILL NOT BE COPIED!"),
        title: this.translate.instant("DUPLICATE PROJECT?"),
        formDuplication: true
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        this.createProjectAndTemplates(project);
      }
    });
  }

  createProjectAndTemplates(project: Project) {
    this.dataService.testIfUpdatingForm(() => {
      this.createProjectAndTemplatesAfterCheck(project);
    });
  }

  createProjectAndTemplatesAfterCheck(newProject: Project) {
    const newProjName = newProject + ' Copy 1';
    const project = {
      project_id: newProject.id,
      name: newProject.name,
      description: newProject.description
    };
    let isMyCumulusAdministrator = false;
    let token = getToken();
    if(!token) {
      token = getAdminToken();
      isMyCumulusAdministrator = true;
    }
    const proToken = {
      'key': 'key',
      value: token.key
    };
    const oldProject = newProject;
    this.store.showLoading();

    this.dataService.duplicateProject(project, proToken, isMyCumulusAdministrator).subscribe(
      newProject => {
        this.dataService.createDuplicateProject(newProject);
        this.store.hideLoading();
      },
      error => {
        if (error.status === '409') {
          const names = newProjName.split('Copy');
          const num = +names[names.length - 1] + 1;
          const project = {
            project_id: newProject.id,
            name: newProject.name + ' Copy ' + num,
            description: newProject.description
          };
          const token = getToken();
          const proToken = {
            'key': 'key',
            value: token.key
          };
          const oldProject = newProject;
          this.store.showLoading();
          this.dataService.duplicateProject(project, proToken).subscribe(
            res3 => {
              const newProject = res3;
              this.dataService.createProjectLocally(res3);
              this.store.hideLoading();
            },
            err4 => {
              if (err4.status === '409') {
                this.toastService.errorToast(`You or one of your collaborator has a project with the same name`);
                this.store.hideLoading();
              }
              this.toastService.errorToast(err4);
              this.store.hideLoading();
            });
        } else {
          this.toastService.errorToast(error);
        }

        this.store.hideLoading();
        return;
      });
  }

}

export enum ActiveClass {
  NONE,
  PROJECT,
  DOMAIN,
  TEMPLATE,
  APROJECT,
  ADOMAIN,
  ATEMPLATE
}
