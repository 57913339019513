import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioButton, MatRadioChange } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { getToken } from 'src/app/share/utils';
import { ToastService } from 'src/app/services/toast.service';
import { StoreService } from 'src/app/services/store.service';
import { DataService } from 'src/app/services/data.service';
import {MatDialog, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import { ImportErrorDlgComponent } from '../import-error-dlg/import-error-dlg.component';
import { Project } from 'src/app/share/projects';
import { Template } from 'src/app/share/template';
import { User } from 'src/app/share/user';
import {ReportsService} from '../../../services/reports.service';
import {TranslateModule} from '@ngx-translate/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-import-shape-file',
  standalone: true,
  imports: [
    MatStepperModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioButton,
    FormsModule,
    MatButtonModule,
    MatDialogClose,
    TranslateModule,
    FaIconComponent
  ],
  templateUrl: './import-shape-file.component.html',
  styleUrls: ['./import-shape-file.component.scss', '../dialog-btns.component.scss']
})
export class ImportShapeFileComponent  implements OnInit{
  @Output('readProjectTemplate')
  readProjectTemplateEmitter = new EventEmitter<any>();

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }


  importDialogTitle: string;
  project: Project;
  template: Template;
  @Input() user: User;
  @Input() mode = {
    text: 'Create',
    function: 'Create'
  };
  @Input() typeOfFile: string;

  loading = false;
  loadingText = 'Loading importation.....';

  model: string;
  encoding = 'UTF8';
  type: string;
  fileInBase64: string;

  fileName: string;

  fileToUpload: File;
  importShape = false;
  isValidFile = true;
  importFileForm: FormGroup;
  importDataError: any;
  isFileProcessing: boolean = false;

  crsList = [
    {value: 'Geodetic', text: 'Geodetic'},
    {value: 'Lambert72', text: 'Lambert 72'},
    {value: 'Lambert2008', text: 'Lambert 2008'},
    {value: 'RDNAP', text: 'RD'},
    {value: 'UTM', text: 'UTM'},
    {value: 'BGS2005', text: 'BGS2005'}
  ];

  fields = [];
  showFieldContainer = false;

  errMsg: string;
  templates: any[];
  noFileIsSelected = false;



  message = 'Test of message';
  showMessage = false;

  @ViewChild('fileInput', { static: false}) fileInput: ElementRef;
  @ViewChild('fform', { static: false}) importFileFormDirective;

  constructor(
    public toastService: ToastService,
    private fb: FormBuilder,
    public store: StoreService,
    private dataService: DataService,
    protected elementRef: ElementRef,
    private reportService: ReportsService,
    public cookieService: CookieService,
    private dialogRef: MatDialogRef<ImportShapeFileComponent>,
    private dialog: MatDialog) {
      this.isValidFile = false;
      this.importDialogTitle = 'Import Shape';
      this.model = 'shape';
  }


  ngOnInit() {
    if (this.store.importIKMLFile) {
      this.importShape = this.store.importIKMLFile;
    }

    this.project = this.dataService.project;
    this.createForm();
  }

  showErrorMessage(msg) {
    this.message = msg;
    this.showMessage = true;
    this.toastService.errorToast(msg);
  }

  createForm() {
    const crsValue = this.cookieService.get('CRSValue') || null;

    if (this.mode.function === 'Create') {
      this.importFileForm = this.fb.group({
        shapeFile: ['', Validators.required],
        crs: [crsValue, Validators.required],
        template: ['', Validators.required]
      });
    }
  }

  closePanel() {
    this.showMessage = false;
  }

  showErrorDlg(): void {
    const dialogRef = this.dialog.open(ImportErrorDlgComponent, {
      width: '500px',
      data: {
        error: this.importDataError
      }
    });
    dialogRef.afterClosed().subscribe(result  => {

    });
  }

  onSubmit() {
    if(this.importFileForm.value['template'].trim().length === 0) {
      this.toastService.errorToast("Name is empty");
      return;
    }
    this.isFileProcessing = true;
    if (!this.fileToUpload && !this.fileInBase64) {
      this.noFileIsSelected = true;
      this.toastService.errorToast('File not selected');
      this.isFileProcessing = false;
      return;
    } if (!this.fileInBase64) {
      this.reportService.convertFileToBase64(this.fileToUpload).then(base64String => {
        this.fileInBase64 = base64String;
      }).catch(err => {
        this.toastService.errorToast(err);
        this.isFileProcessing = false;
        return;
      })
    }
    if (this.fileInBase64.length <= 0 ) {
      this.toastService.errorToast('File not selected or is too large');
      this.isFileProcessing = false;
      return;
    }
    this.showMessage = false;
    const importData = this.importFileForm.value;
    if (!importData['crs']) {
      this.toastService.errorToast("No CRS is selected");
      this.isFileProcessing = false;
      return
    }

    let p = this.dataService.project;

    const value = {
      project_id: this.project.id,
      base64_encoded_content: this.fileInBase64,
      template: importData['template'],
      crs: importData['crs'],
      model : this.model,
      encoding: 'UTF8',
      type: this.type
    };
    this.store.showLoading();
    this.loadingText = 'Sending import data';
    const token = getToken();
    this.dataService.importShapeInTemplate(value, token, p.id).subscribe(
      res => {
        this.showFieldContainer = false;
        this.isFileProcessing = false;
        const result_features = res?.features;
        if(result_features?.success) {
          this.toastService.successToast(`${result_features?.success} from Shape file in form: ${res?.template?.name}` );
        }
        this.dataService.createTemplateLocally(res?.template);

        if(result_features.errors) {
          this.showMessage = true;
          this.message = result_features?.failed;
          this.importDataError = result_features?.errors;
        } else {
          this.fileToUpload = null;
          this.dialogRef.close();
          this.dataService.readTemplate(res?.template);
        }

        this.store.hideLoading();
      },
      error => {
        this.loading = false;
        this.isFileProcessing = false;
        this.store.hideLoading();

        this.importDataError = error;

        let msg = `The import operation failed, please try again`;
        if(error && error['message']) {
          msg += ' : ' + error['message'];
        } else if (typeof(error) === 'string') {
          msg = error;
        }
        this.importDataError = [msg];
        this.showErrorMessage(msg);
      },
      () => {
        this.loading = false;
        this.dataService.hideTemplate();
        this.getTemplates(p);
        this.store.hideLoading();
      }
    );
  }

  getTemplates(p): void {
    this.dataService.getTemplates(p);
    this.dataService.showTemplates();
  }

  setFileData(event): void {
    event.preventDefault();

    this.showFieldContainer = false;
    if (event.target.files && event.target.files.length > 0) {
      const tempFile = event.target.files[0];

      this.isValidFile = true;
      const filename = tempFile.name;
      this.fileName = tempFile.name;
      const filenameParts = filename ? filename.split('.') : [];
      if (filenameParts && filenameParts.length > 1) {
        this.type = filenameParts[filenameParts.length -1];
        this.importFileForm.patchValue({
          template: filenameParts[0]
        });
      }

      this.fileToUpload = event.target.files[0];
      this.reportService.convertFileToBase64(this.fileToUpload).then(base64String => {
        this.fileInBase64 = base64String;
      }).catch(err => {
        this.toastService.errorToast(err);
      })
      this.showFieldContainer = true;
    } else {
      this.isValidFile = false;
    }
    event.stopPropagation();
  }

  protected readonly faSpinner = faSpinner;
}
