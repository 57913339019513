import { Field } from './field';

export class FieldsetArray extends Field {
  on_same_screen_as_parent: boolean;

  fields: Field[];

  constructor() {
    super();
    this._class = 'fieldset-array';
    this.on_same_screen_as_parent = false;
  }
}
