import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { User } from 'src/app/share/user';
import { getUser, getMarxactUsername, getMarxactPassword, getMarxactUserConnected, getToken, removeUser, persistUser } from 'src/app/share/utils';
import { MarxactService } from 'src/app/services/marxact.service';
import { StoreService } from 'src/app/services/store.service';
import { UserService } from 'src/app/services/user.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../services/toast.service';

export interface MarxactDevice {
  device_name: string;
  device_id: string;
}

const ELEMENT_DATA: MarxactDevice[] = [];

@Component({
  standalone: true,
  imports: [TranslateModule,
    MessageBoxComponent,
    MatDialogModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonModule,
  ],
  selector: 'app-marxact-page',
  templateUrl: './marxact-page.component.html',
  styleUrls: ['./marxact-page.component.scss']
})
export class MarxactPageComponent implements OnInit, AfterViewInit {
  user: User;
  collaboratorUser: any;
  displayedColumns: string[] = ['select', 'device_name', 'device_id'];
  devicesToBeAssigned: any[];
  devicesToBeRemoved: any[];
  userdevices: any[];
  dataSource = new MatTableDataSource<MarxactDevice>(ELEMENT_DATA);
  selection = new SelectionModel<MarxactDevice>(true, []);
  searchValue: string;
  private messageBox: MessageBoxComponent;
  @ViewChild(MatTable, {static: true}) marxactdevtable: MatTable<any>;

  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;


  constructor(
    private marxactService: MarxactService,
    private dialogRef: MatDialogRef<MarxactPageComponent>,
    private userService: UserService,
    public store: StoreService,
    public toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data
    ) {
      if (data !== undefined && data !== null) {
        this.user = data.user;
        this.collaboratorUser = data.user;
      } else {
        this.user = getUser();
      }
      this.devicesToBeAssigned = [];
      this.devicesToBeRemoved = [];
   }

  ngOnInit() {

    this.userdevices = [];
    if (this.user.mc_marxact !== null && this.user.mc_marxact !== undefined) {
      this.userdevices = this.user.mc_marxact.devices;
    }
  }

// created_date: Date Sat Oct 29 2016 11:27:34 GMT+0000 (heure moyenne de Greenwich)
// email: "kajke.vertommen@hotmail.be"
// gnss_source: "MX-103"
// id: "581487a6e4b045d83770ed9f"
// may_create_projects: true
// name: "Kaj"
// owned_projects: 1

  getCollaborator(collaboratorId) {
    this.store.showLoading();
    this.userService.getCollaboratorUsers(this.store.user, this.store.token).subscribe(
      res => {
        for (const item of res) {
          if (item.id === collaboratorId) {
            this.user = item;
            this.collaboratorUser = item;
            if (this.user.mc_marxact !== null && this.user.mc_marxact !== undefined) {
              this.userdevices = this.user.mc_marxact.devices;
            }
            this.getUserMarxActDeviceList();
            break;
          }
        }
        this.store.hideLoading();
      },
      err => {
        this.store.hideLoading();
      }
    );
  }

  ngAfterViewInit() {
    if (this.collaboratorUser !== null && this.collaboratorUser !== undefined) {
      this.getCollaborator(this.collaboratorUser.id);
    } else {
      this.getUserMarxActDeviceList();
    }
  }

  getUserMarxActDeviceList() {
    const username = getMarxactUsername();
    const password = getMarxactPassword();
    const marxactuserconnected = getMarxactUserConnected();
    if ((password === null || password === '') && (username === null || username === '')) {
      this.toastService.errorToast('No marxact username available');
      return;
    }
    if (!marxactuserconnected) {
      this.toastService.errorToast('No user is connected');
      return;
    }
    const token = getToken();
    this.store.showLoading();
    this.marxactService.getMarxactAccountDevices(token, username, password).subscribe(
      res => {
        const devices = res;
        if (devices !== null && devices !== undefined) {
          this.dataSource.data = devices;
          this.dataSource.data.map(element => {
            if (this.checkIfDeviceAlreadyAssigned(element)) {
              this.selection.select(element);
            }
          });
          this.marxactdevtable.renderRows();
        }
        this.store.hideLoading();
      },
      err => {
        this.toastService.errorToast(err);
        this.store.hideLoading();
      }
    );
  }

  checkIfDeviceAlreadyAssigned(row) {
    const u = this.user;
    const marxact = u.mc_marxact;
    if (marxact !== null && marxact !== undefined) {
      const devices = marxact.devices;
      if (devices !== null && devices !== undefined) {
        for (const device of devices) {
          if (device.device_id === row.device_id) {
            return true;
          }
        }
      }
    }
    return false;
  }

  devicesToAssigned(element) {
    let itemExist = false;
    if (this.selection.isSelected(element)) {
      itemExist = false;
      for (const row of this.devicesToBeRemoved) {
        if (row.device_id === element.device_id) {
          itemExist = true;
        }
      }
      if (!itemExist) {
        this.devicesToBeRemoved.push(element);
      }
      this.devicesToBeAssigned = this.devicesToBeAssigned.filter(item => item.device_id !== element.device_id);
    } else {
      itemExist = false;
      for (const row of this.devicesToBeRemoved) {
        if (row.device_id === element.device_id) {
          itemExist = true;
        }
      }
      if (!itemExist) {
        this.devicesToBeAssigned.push(element);
      }
      this.devicesToBeRemoved = this.devicesToBeRemoved.filter(item => item.device_id !== element.device_id);
    }
    this.selection.toggle(element);
    /*
    if (this.selection.selected.length === 0) {
      this.selection.clear();
    }
    */
  }

  assignAndOrDeassignDevices() {
    if (this.devicesToBeAssigned.length === 0 && this.devicesToBeRemoved.length === 0) {
      return;
    }
    const username = getMarxactUsername();
    const password = getMarxactPassword();
    const token = getToken();
    const devices = [];

    this.devicesToBeAssigned.map(device => {
      devices.push(device.device_id);
    });
    if (this.devicesToBeAssigned.length > 0) {
      this.store.showLoading();
      this.marxactService.assignMarxactDeviceToMCUser(token, this.user.id, username, password, devices).subscribe(
          res => {
            this.updateCurrentUser();
            this.store.hideLoading();
          },
          err => {
            this.toastService.errorToast(err);
            this.store.hideLoading();
        });
    }

    if (this.devicesToBeRemoved.length > 0) {
      this.devicesToBeRemoved.map(device => {
        this.store.showLoading();
        this.marxactService.removeMarxactDeviceFromMCUser(
          token, this.user.id, device.device_id).subscribe(
            res => {
              this.updateCurrentUser();
              this.store.hideLoading();
            },
            err => {
              this.toastService.errorToast(err);
              this.store.hideLoading();
              return;
          });
      });
    }
  }

  updateCurrentUser() {
    this.store.showLoading();
    const token = getToken();
    this.userService.getUser(token, token.user_id).subscribe(
      res => {
        this.store.user = res;
        removeUser();
        persistUser(res);
        this.dialogRef.close(res);
        this.store.hideLoading();
      },
      err => {
        this.store.hideLoading();
      }
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.map(row => this.selection.select(row));
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: MarxactDevice): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.device_name + 1}`;
  }
}
