import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import { Project } from '../../share/projects';
import { DataService, PROJECTS } from '../../services/data.service';
import { Template } from 'src/app/share/template';

import { StoreService } from 'src/app/services/store.service';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { ProjectItemComponent } from '../project-item/project-item.component';
import { MatButtonModule } from '@angular/material/button';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {getUser, persistProject} from '../../share/utils';
import {ActiveClass, HeaderService} from '../../services/header.service';
import {CommonModule, NgStyle} from '@angular/common';
import {ProjectSearchItemComponent} from '../project-search-item/project-search-item.component';
import {MatInput} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ToastService} from '../../services/toast.service';
import {MatTooltip} from '@angular/material/tooltip';
import {MatIcon, MatIconModule} from '@angular/material/icon';

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatListModule,
    MatCardModule,
    ProjectItemComponent,
    MatButtonModule,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    NgStyle,
    ProjectSearchItemComponent,
    MatInput,
    FontAwesomeModule,
    ReactiveFormsModule,
    CommonModule,
    MatTooltip,
    MatIconModule,
    MatIcon,
    CommonModule
  ],
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  projects: Project[];
  project: Project;
  templates: Template[];
  projectImageUrl: string;
  protected readonly ActiveClass = ActiveClass;
  projectSearchActive: boolean = false;
  menuPosition = { top: 0, left: 0 };
  isSearchActive: boolean = false;
  foundProjects = [];
  typedSearch: string = "";


  constructor(
    public store: StoreService,
    public dataService: DataService,
    public headerService: HeaderService,
    public toastService: ToastService,
    public translation: TranslateService,
    ) { }

  ngOnInit() {
    this.projects = PROJECTS;
    this.projectImageUrl = this.getImageFileUrl('P');
    this.dataService.currentProject = this.dataService.project;
  }

  getImageFileUrl(initial: string) {
    return 'https://placehold.it/70/009688/ffffff&text=' + initial;
  }

  disabledAddCreateProjectTemplateBtn() {
    return !this.store?.isMCAdmin &&  this.headerService.currentClass === ActiveClass.TEMPLATE;
  }

//   Menu functionality:
  openCreateProjectDialog() {
    if(this.store.archived) {
      const projectClass = this.headerService.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is not allowed on an archived ${projectClass}`)
      return;
    }
    this.dataService.testIfUpdatingForm(() => {
      const user = getUser();
      const permission = user.permissions;
      if (permission !== null && permission !== undefined) {
        const projPerm = permission.projects;
        if (projPerm[0] === "create") {
          this.headerService.openCreateProjectDialogAfterCheck();
        }
      } else {
        this.headerService.openCreateProjectDialogAfterCheck();
      }
    });
  }

  resetTemplateSelection() {
    if(this.dataService.template.getValue()){
      this.dataService.selectProject(this.dataService.project);
    }
  }

  openImportDomainProjectDialog() {
    if(this.store.archived) {
      const projectClass = this.headerService.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is not allowed on an archived ${projectClass}`)
      return;
    }
    this.dataService.testIfUpdatingForm(() => {
      const user = getUser();
      const permission = user.permissions;
      if (permission !== null && permission !== undefined) {
        const projPerm = permission.projects;
        if (projPerm[0] === "create") {
          this.headerService.openImportDomainProjectDialogAfterCheck();
        }
      } else {
        this.headerService.openImportDomainProjectDialogAfterCheck();
      }
    });
  }

  openImportProjectDialog() {
    if(this.store.archived) {
      const projectClass = this.headerService.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is not allowed on an archived ${projectClass}`)
      return;
    }
    this.dataService.testIfUpdatingForm(() => {
      const user = getUser();
      const permission = user.permissions;
      if (permission !== null && permission !== undefined) {
        const projPerm = permission.projects;
        if (projPerm[0] === "create") {
          this.headerService.openImportProjectDialogAfterCheck();
        }
      } else {
        this.headerService.openImportProjectDialogAfterCheck();
      }
    });
  }

  @ViewChild('searchDiv') searchRef!: ElementRef;
  @ViewChild('openSearch') openSearch!: ElementRef;

  // Close the window when clicking outside of it
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if(this.searchRef) {
      if (this.projectSearchActive && !this.searchRef.nativeElement.contains(event.target) && !this.openSearch.nativeElement.contains(event.target)) {
        this.projectSearchActive = false;
      }
    }
  }

  @ViewChild('buttonRef') buttonRef!: ElementRef<HTMLButtonElement>;

  toggleProjectSearch() {
    this.projectSearchActive = !this.projectSearchActive;
    if (this.projectSearchActive) {  // Update position only if we're showing the div
      this.updateMenuPosition();
    }
  }

  updateMenuPosition() {
    const rect = this.buttonRef.nativeElement.getBoundingClientRect();
    this.menuPosition = {
      top: rect.top - 126,
      left: window.scrollX + rect.left - 5.5
    };
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.projectSearchActive) {
      this.updateMenuPosition();  // Ensure position updates with window resizing
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (this.projectSearchActive) {
      this.updateMenuPosition();  // Update position when the page scrolls
    }
  }

  applySearch(searchInput: string) {
    this.typedSearch = searchInput;
    if (searchInput === "") {
      this.isSearchActive = false;
    }
    else {
      searchInput = searchInput.toLowerCase();
      this.isSearchActive = true;

      this.foundProjects = this.dataService.projects.filter(project =>
        project.name.toLowerCase().includes(searchInput) ||
        project.description.toLowerCase().includes(searchInput)
      );
    }
  }

  onSelected(p, event) {
    event.preventDefault();
    this.dataService.selectProject(p);
    persistProject(p.id);
    if(this.store.copiedFeatures && this.store.copiedFeatures.length > 0) {
      this.store.destinationProject = p;
    }
    event.stopPropagation();
  }

  protected readonly getUser = getUser;
}
