<div id="adminInfoCard">
    @if(showMessage) {
    <app-message-box #messageBox [message]="message"></app-message-box>

    }

    <div id="contextMenu" style="display: none;">
        <table cellpadding="0" cellspacing="0" width="100px">
            <tr>
                <td><a href="#" class="contextItem"><span>{{ "VIEW" | translate }}</span></a></td>
            </tr>
            <tr>
                <td><a href="#" class="contextItem"><span>{{ "EDIT" | translate }}</span></a></td>
            </tr>
            <tr>
                <td><a href="#" class="contextItem"><span>{{ "DELETE" | translate }}</span></a></td>
            </tr>
        </table>
    </div>

    <div class="content">
        <div class="top-buttons">
            <button mat-button mat-flat-button color="primary" class="btn-border" [hidden]="editUserEmail"
                (click)="editProfile()"><span>{{ "CHANGE EMAIL" | translate }}</span></button>
            <button mat-button mat-flat-button color="primary" class="btn-border" [hidden]="editUserEmail"
                (click)="openGnssSourceLogin()"><span>{{ "GNSS SOURCES" | translate }}</span></button>
            @if (editUserEmail) {
            <button mat-button mat-flat-button color="primary" class="btn-border" (click)="updateProfile()"><span>{{ "UPDATE EMAIL" | translate }}</span></button>
            }
            @if (editUserEmail) {
            <button mat-button mat-flat-button class="btn-border" (click)="cancelProfile()"><span>{{ "CANCEL" | translate }}</span></button>
            }
        </div>
        <div class="info-section" [hidden]="editUserEmail">
            <div class="row">
                <div class="col item-border">
                    <h4>Name:</h4>
                    <span>{{adminName}}</span>
                </div>
                <div class="col item-border">
                    <h4>Email:</h4>
                    <span>{{adminEmail}}</span>
                </div>
                <div class="col item-border">
                    <h4>User Type:</h4>
                    <span>{{adminType}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col item-border">
                    <h4>Server:</h4>
                    <span>{{ getServer() }}</span>
                </div>
                <div class="col item-border">
                    <h4>Backend Version:</h4>
                    <span>{{ serverVersion }}</span>
                </div>
                <div class="col item-border">
                    <h4>Api Version:</h4>
                    <span>{{ apiVersion }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col item-border">
                    <h4>Web app Version:</h4>
                    <span>{{ getWebAppVersion() }}</span>
                </div>
            </div>
            @if(marxactDevicesList) {
            <div class="row">
                <div class="col item-border">
                    <h4>GNSS Sources:</h4>
                    @for (device of marxactDevicesList; track device) {
                    <span class="badge">{{device.device_name}}</span>
                    }
                </div>
            </div>
            }
        </div>
        @if (editUserEmail) {
        <div class="row">
            <div class="col">
                <form novalidate [formGroup]="updateEmailForm" (ngSubmit)="updateProfile()">
                    <mat-form-field class="full-width">
                        <mat-label><span>{{ "NEW EMAIL" | translate }}</span></mat-label>
                        <input matInput autocomplete="off" formControlName="new_email" [placeholder]="'NEW EMAIL' | translate"
                            type="email" required>
                    </mat-form-field>
                </form>
            </div>
        </div>
        }
    </div>
</div>
