<div fxFlexLayout="row">
  <div fxFlex>
    <h3>{{mode.text}} {{ "CONSTRAINT" | translate }}</h3>
    @if (choiceOrMultChoice) {
      <form novalidate [formGroup]="addConstraintForm" (ngSubmit)="onSubmit()">
        <mat-dialog-content>
          <p>
            <span class="half-width"><span>{{ "CONSTRAINT TYPE" | translate }}</span></span>
            <mat-select class="ml-3 half-width" formControlName="_class" (selectionChange)="constraintSelected($event.value)" [placeholder]="'CONSTRAINT TYPE' | translate">
              <mat-option></mat-option>
              @for (constType of constraintTypes; track constType) {
                <mat-option [value]="constType.value">{{ constType.text }}</mat-option>
              }
            </mat-select>
          </p>
          <p>
            <mat-form-field class="full-width">
              <input matInput autocomplete="off" formControlName="values" placeholder="{{valuePlaceHolder}}" type="text" required>
              @if (addConstraintForm.get('values').hasError('required') && addConstraintForm.get('values').touched) {
                <mat-error><span>{{ "CONSTRAINT VALUE IS REQUIRED" | translate }}</span></mat-error>
              }
            </mat-form-field>
          </p>
          <p>
            <mat-form-field class="full-width">
              <input matInput autocomplete="off" formControlName="error_message" [placeholder]="'ERROR MESSAGE' | translate" type="text" required>
              @if (addConstraintForm.get('error_message').hasError('required') && addConstraintForm.get('error_message').touched) {
                <mat-error><span>{{ "CONSTRAINT ERROR MESSAGE IS REQUIRED" | translate }}</span></mat-error>
              }
            </mat-form-field>
          </p>
        </mat-dialog-content>
        <mat-dialog-actions class="button-group">
          <button mat-button class="btn cancel-btn" (click)="closeDialog($event)"><span>{{ "CANCEL" | translate }}</span></button>
          <button mat-button type="submit" class="btn submit-btn" [disabled]="addConstraintForm.invalid">{{mode.text}}</button>
        </mat-dialog-actions>
      </form>
    } @else {
      <form novalidate [formGroup]="addConstraintForm" (ngSubmit)="onSubmit()">
        <mat-dialog-content>
          <p>
            <span class="half-width"><span>{{ "CONSTRAINT TYPE" | translate }}</span></span>
            <mat-select (selectionChange)="constraintSelected($event.value)" formControlName="_class" [placeholder]="'CONSTRAINT TYPE' | translate">
              <mat-option></mat-option>
              @for (constType of constraintTypes; track constType) {
                <mat-option [value]="constType.value">{{ constType.text }}</mat-option>
              }
            </mat-select>
          </p>
          <p>
            <mat-form-field class="full-width">
              <input matInput autocomplete="off" formControlName="value" placeholder="{{valuePlaceHolder}}" type="text" required>
              @if (addConstraintForm.get('value').hasError('required') && addConstraintForm.get('value').touched) {
                <mat-error><span>{{ "CONSTRAINT VALUE IS REQUIRED" | translate }}</span></mat-error>
              }
            </mat-form-field>
          </p>
          <p>
            <mat-form-field class="full-width">
              <input matInput autocomplete="off" formControlName="error_message" [placeholder]="'ERROR MESSAGE' | translate" type="text" required>
              @if (addConstraintForm.get('error_message').hasError('required') && addConstraintForm.get('error_message').touched) {
                <mat-error><span>{{ "CONSTRAINT ERROR MESSAGE IS REQUIRED" | translate }}</span></mat-error>
              }
            </mat-form-field>
          </p>
        </mat-dialog-content>
        <mat-dialog-actions class="button-group">
          <button mat-button class="btn cancel-btn" (click)="closeDialog($event)"><span>{{ "CANCEL" | translate }}</span></button>
          <button mat-button type="submit" class="btn submit-btn" [disabled]="addConstraintForm.invalid || dataInvalid">{{mode.text}}</button>
        </mat-dialog-actions>
      </form>
    }

  </div>
</div>
