import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StyleResult, StylesResult } from '../share/result';
import { Style } from '../share/style';
import { getPrivateApiAURL } from '../share/utils';
import { ProcessHttpmsgService } from './process-httpmsg.service';

@Injectable({
  providedIn: 'root'
})
export class StyleServiceService {

  constructor(private http: HttpClient,
    private processHttpMsgService: ProcessHttpmsgService) { }

    deleteStyle(style, token, isMyCumulusAdministrator = false): Observable<Style> {
      let httpOptions = {
        headers: new HttpHeaders({
          'X-Auth-Token': token.key
        })
      };
      if(isMyCumulusAdministrator) {
        httpOptions = {
          headers: new HttpHeaders({
            'X-Admin-Auth-Token': token.key
          })
        };
      }

      return this.http.delete<StyleResult>(getPrivateApiAURL() + 'styles/' + style.id, httpOptions).pipe(
        map(res => {
          return res.style;
        })
      )
      .pipe(catchError(this.processHttpMsgService.handleError));
    }

    updateStyle(style, styleId, token, isMyCumulusAdministrator=false): Observable<Style> {
      let httpOptions = {
        headers: new HttpHeaders({
          'X-Auth-Token': token.value
        })
      };
      if(isMyCumulusAdministrator) {
        httpOptions = {
          headers: new HttpHeaders({
            'X-Admin-Auth-Token': token.value
          })
        };
      }

      return this.http.put<StyleResult>(getPrivateApiAURL() + 'styles/' + styleId, style, httpOptions)
      .pipe(
        map(res => {
          return res.style;
        })
      )
      .pipe(catchError(this.processHttpMsgService.handleError));
    }

    createStyle(style, token, isMyCumulusAdministrator=false): Observable<Style> {
      let httpOptions = {
        headers: new HttpHeaders({
          'X-Auth-Token': token.value
        })
      };
      if(isMyCumulusAdministrator) {
        httpOptions = {
          headers: new HttpHeaders({
            'X-Admin-Auth-Token': token.value
          })
        };
      }
      return this.http.post<StyleResult>(getPrivateApiAURL() + 'styles', style, httpOptions).pipe(
        map(res => {
          return res.style;
        })
      )
      .pipe(catchError(this.processHttpMsgService.handleError));
    }

    getStyle(styleId, token, isMyCumulusAdministrator=false): Observable<Style> {
      //  ;
      let httpOptions = {
        headers: new HttpHeaders({
          'X-Auth-Token': token.key
        })
      };
      if(isMyCumulusAdministrator) {
        httpOptions = {
          headers: new HttpHeaders({
            'X-Admin-Auth-Token': token.key
          })
        };
      }
      return this.http.get<StyleResult>(getPrivateApiAURL() + 'styles/' + styleId, httpOptions).pipe(
        map(res => {
          return res.style;
        })
      )
      .pipe(catchError(this.processHttpMsgService.handleError));
    }

    getStyles(token, visibility, isMyCumulusAdministrator = false): Observable<Style[]> {
      let httpOptions = {
        headers: new HttpHeaders({
          'X-Auth-Token': token.key
        }),
        params: new HttpParams()
      };
      if(visibility !== null && visibility !== undefined) {
        httpOptions = {
          headers: new HttpHeaders({
            'X-Auth-Token': token.key
          }),
          params: new HttpParams()
            .set('visibility', visibility)
        };
      }

      if(isMyCumulusAdministrator) {
        httpOptions = {
          headers: new HttpHeaders({
            'X-Admin-Auth-Token': token.key
          }),
          params: new HttpParams()
        };
        if(visibility !== null && visibility !== undefined) {
          httpOptions = {
            headers: new HttpHeaders({
              'X-Admin-Auth-Token': token.key
            }),
            params: new HttpParams()
              .set('visibility', visibility)
          };
        }
      }

      return this.http.get<StylesResult>(getPrivateApiAURL() + 'styles', httpOptions)
      .pipe(map(result => {
        return result.styles.instances;
      }))
      .pipe(
        catchError(this.processHttpMsgService.handleError)
        );
    }

    getCompatibleStyles(token, visibility, templateId, isMyCumulusAdministrator = false): Observable<Style[]> {
      let httpOptions = {
        headers: new HttpHeaders({
          'X-Auth-Token': token.key
        }),
        params: new HttpParams()
          .set('visibility', visibility)
      };
      if(isMyCumulusAdministrator) {
        httpOptions = {
          headers: new HttpHeaders({
            'X-Admin-Auth-Token': token.key
          }),
          params: new HttpParams()
            .set('visibility', visibility)
        };

        return this.http.get<StylesResult>(getPrivateApiAURL() + 'styles', httpOptions)
        .pipe(map(result => {
          return result.styles.instances;
        }))
        .pipe(catchError(this.processHttpMsgService.handleError));
      }

      return this.http.get<StylesResult>(getPrivateApiAURL() + 'template/' + templateId + '/styles', httpOptions)
      .pipe(map(result => {
        return result.styles.instances;
      }))
      .pipe(catchError(this.processHttpMsgService.handleError));
    }
}
