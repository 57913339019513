<div fxFlexLayout="row" style="margin-bottom: 0px !important;">
    <div fxFlex style="margin-bottom: 0px !important;">
        <h2><span>{{ "STEDIN REPORT" | translate }}</span></h2>
        <hr />
        <app-message-box #messageBox *ngIf="showMessage" message="{{message}}"></app-message-box>
        <span [hidden]="templateloaded">Loading report template ..., please wait </span>
        <div class="button-group">
            <button mat-stroked-button class="mr-2 mt-2 submit-btn" type="submit" (click)="submitResearchData() "><span>{{ "EXPORT" | translate }}</span></button>
            <button mat-stroked-button class="mr-2 mt-2 cancel-btn" type="submit" (click)="closeDialog()"><span>{{ "CANCEL" | translate }}</span></button>
        </div>
    </div>
</div>
