<div fxFlexLayout="row">
  <div fxFlex fxFlexOffset="20px">
    <h3>{{ "EDIT DATA" | translate }}</h3>
    <form novalidate [formGroup]="editDataForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      <mat-dialog-content>
        @if (type === 'boolean') {
          <p style="margin-top: 15px; height: 35px;">
            <mat-radio-group class="full-width" formControlName="name" color="primary" aria-label="Select an option">
              <mat-radio-button value="1">True</mat-radio-button>
              <mat-radio-button value="2">False</mat-radio-button>
            </mat-radio-group>
          </p>
        } @else {
          <p>
            <mat-form-field class="full-width">
              <input #textInput matInput autocomplete="off" formControlName="name" [placeholder]="item.key" type="text">
              @if (editDataForm.get('name').touched || editDataForm.get('name').hasError('pattern')) {
                <mat-error>
                  {{errMsg}}
                </mat-error>
              }
            </mat-form-field>
          </p>
        }

      </mat-dialog-content>
      <app-dialog-actions [mode]="mode" [formCheck]="editDataForm"></app-dialog-actions>
    </form>
  </div>
</div>
