<div class="container">
  <div class="formContainer">
    <form class="signup-form" (ngSubmit)="onSubmit(checkbox, repeat)" [formGroup]="form">
      <input #checkbox type="checkbox" name="terms_and_conditions" value="1" class="internal" tabindex="-1" autocomplete="off">
      <input #repeat type="text" [placeholder]="'REPEAT EMAIL' | translate" name="repeat_email" value="" class="internal" tabindex="-1" autocomplete="off">
      <h2></h2>
      <div class="innerForm">
        <input formControlName="username" type="text" [placeholder]="'USERNAME' | translate" name="username" required>
        <input formControlName="email" type="email" [placeholder]="'EMAIL' | translate" name="email" required>
        <div *ngIf="form.get('email').invalid && (form.get('email').dirty || form.get('email').touched)">
          <div *ngIf="form.get('email').errors.required" class="warning">
            Email is required.
          </div>
          <div *ngIf="form.get('email').errors.email" class="warning">
            Invalid email address.
          </div>
        </div>
        <input formControlName="company" type="text" [placeholder]="'COMPANY' | translate" name="company" required>
        <div class="password-fields">
          <input formControlName="password" class="medium-text left-text" type="password" [placeholder]="'PASSWORD' | translate" name="password" required>
          <input formControlName="confirmPassword" class="medium-text right-text" type="password" [placeholder]="'REPEAT PASSWORD' | translate" name="repassword" required>
        </div>
        <div class="warning" *ngIf="form && form.errors && form.errors['mismatch'] && form.touched"><span>{{ "PASSWORDS MUST MATCH" | translate }}</span></div>
        <select formControlName="country" name="country" required>
          <mat-label>{{"COUNTRY" | translate}}</mat-label>
          <option value="" disabled>{{"COUNTRY" | translate}}</option>
          <option *ngFor="let country of countries" [selected]="country.name.common === preSelectedDomain">{{ country.name.common }}</option>
        </select>
        <select formControlName="industry" name="industry" required>
          <mat-label><span>{{ "INDUSTRY" | translate }}</span></mat-label>
          <option value="" disabled>{{"INDUSTRY" | translate}}</option>
          <option *ngFor="let domain of domains" [selected]="domain.name === preSelectedDomain">{{ domain.name }}</option>
        </select>
      </div>

      <div class="formDelivery">
        <button type="submit" [ngClass]="isLoading ? 'loadingbutton' : ''" [disabled]="form.invalid || isLoading">
          <ng-container *ngIf="!isLoading"><span>{{ "SIGN UP" | translate }}</span></ng-container>
          <ng-container *ngIf="isLoading">
            <mat-spinner diameter="28"></mat-spinner>
          </ng-container>
        </button>

      </div>
      <div class="login">
        <a type="submit" (click)="openLogin($event)">Login here >></a>
      </div>

    </form>
  </div>
</div>
