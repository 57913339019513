<div class="dialog-content" fxLayout="column">
  <div mat-dialog-title fxFlex="10">
    <h4><span>{{ "UPDATE COORDINATES" | translate }}</span></h4>
  </div>
  <div mat-dialog-content class="mb-2" fxFlex="80">
    @if (loading) {
      <span>Loading ...</span>
    }
    @if (error_occured) {
      <span>An error occur while processing the change, please try again later, or contact MyCumulus Team.</span>
    }
    <table class="table table-sm table-striped table-bordered">
      <tr>
        <th>{{ latitude }}</th>
        <th>{{ longitude }}</th>
        <th>{{ altitude }}</th>
        <th>{{ accuracy }}</th>
      </tr>
      @if (!geodetic) {
        @for (item of coordinates; track item; let i = $index) {
          <tr>
            <td>
              <mat-form-field class="full-width">
                <input [disabled]="!hasWritePermissions" matInput autocomplete="off" type="text"
                       (change)="updateElementValue($event, i, 'X', $event.target.value)"
                       [value]="item.X">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="full-width">
                <input [disabled]="!hasWritePermissions" matInput autocomplete="off" type="text"
                       (change)="updateElementValue($event, i, 'Y', $event.target.value)"
                       [value]="item.Y">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="full-width">
                <input [disabled]="!hasWritePermissions" matInput autocomplete="off" type="text"
                       (change)="updateElementValue($event, i, 'Z', $event.target.value)"
                       [value]="item.Z">
              </mat-form-field>
            </td>
            <td></td>
          </tr>

        }
      }
      @if (geodetic) {
        @for (item of coordinates; track item; let i = $index) {
          <tr>
            <td>
              <mat-form-field class="full-width">
                <input [disabled]="!hasWritePermissions" matInput autocomplete="off" type="text"
                       (change)="updateElementValue($event, i, 'latitude', $event.target.value)"
                       [value]="item.latitude">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="full-width">
                <input [disabled]="!hasWritePermissions" matInput autocomplete="off" type="text"
                       (change)="updateElementValue($event, i, 'longitude', $event.target.value)"
                       [value]="item.longitude">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field class="full-width">
                <input [disabled]="!hasWritePermissions" matInput autocomplete="off" type="text"
                       (change)="updateElementValue($event, i, 'altitude', $event.target.value)"
                       [value]="item.altitude">
              </mat-form-field>
            </td>
            <td>
              @if (geodetic) {
                <mat-form-field class="full-width">
                  <input [disabled]="!hasWritePermissions" matInput autocomplete="off" type="text"
                         (change)="updateElementValue($event, i, 'accuracy', $event.target.value)"
                         [value]="item.accuracy">
                </mat-form-field>
              }
            </td>
          </tr>

        }
      }
    </table>
  </div>
  <div fxFlex="10" mat-dialog-actions class="button-group">
    <button mat-raised-button mat-dialog-close class="btn cancel-btn"><span>{{ "CLOSE" | translate }}</span></button>
    <button mat-raised-button [disabled]="!hasWritePermissions" (click)="updateCoordinates()" class="btn submit-btn"><span>{{ "UPDATE" | translate }}</span></button>
  </div>
</div>
