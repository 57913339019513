import {Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef} from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {
  getToken, removeToken, logoutUser,
  getUser, getAdminInfo,
  getAdminToken, removeAdminToken,
  isCollaborator, getApiAURL, getAtStartup
} from 'src/app/share/utils';
import { Router, RouterModule } from '@angular/router';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { SettingCoordinatesReferenceDialogComponent }
from '../dialogs/setting-coordinates-reference-dialog/setting-coordinates-reference-dialog.component';
import { SysAdminUserServiceService } from 'src/app/services/sys-admin-user-service.service';
import { StoreService } from 'src/app/services/store.service';
import { DataService } from 'src/app/services/data.service';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ProjectToProjectTemplateComponent } from '../dialogs/project-to-project-template/project-to-project-template.component';
import { ProjectToDomaineComponent } from '../dialogs/project-to-domaine/project-to-domaine.component';
import { FeatureService } from 'src/app/services/feature.service';
import { EnexisExportCritDialogComponent } from '../dialogs/enexis-export-crit-dialog/enexis-export-crit-dialog.component';
import { ReportsService } from 'src/app/services/reports.service';
import { ExportStedinCritDialogComponent } from '../dialogs/export-stedin-crit-dialog/export-stedin-crit-dialog.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import {ActiveClass, HeaderService} from '../../services/header.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faCaretDown} from '@fortawesome/free-solid-svg-icons';
import {ToastService} from '../../services/toast.service';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {SearchResult} from '../../share/result';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MatSelect} from '@angular/material/select';
import {TranslationService} from '../../services/translation.service';
import {ExportDataCriteriaDialogComponent} from '../dialogs/export-data-criteria-dialog/export-data-criteria-dialog.component';
import {ImportCSVDialogComponent} from '../dialogs/import-data-dialog/import-CSV-dialog.component';
import {ImportImklFileComponent} from '../dialogs/import-imkl-file/import-imkl-file.component';
import {ImportExcelFileComponent} from '../dialogs/import-excel-file/import-excel-file.component';
import {ImportShapeFileComponent} from '../dialogs/import-shape-file/import-shape-file.component';
import {CookieService} from 'ngx-cookie-service';
import {MatTooltip} from '@angular/material/tooltip';

@Component({
  standalone: true,
  imports: [
    MatToolbarModule,
    RouterModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    CommonModule,
    FormsModule,
    MatProgressBarModule,
    FontAwesomeModule,
    MessageBoxComponent,
    MatFormFieldModule,
    MatAutocomplete,
    MatAutocompleteTrigger,
    MatOption,
    TranslateModule,
    MatSelect,
    MatTooltip
  ],
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  username: string;
  myCumulusAdmin: boolean;
  collaboratorUser: boolean;
  fields: any;
  templateNameWidth: number;
  messageBoxWidth: number;
  permission: boolean;
  mcAdminToken: any;
  proClass: string = 'Projects';
  inArchivedMode: boolean = false;

  @ViewChild('toolbar') toolbar: ElementRef;

  matInput: string;

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  searchValue: string;
  currentTextSearch: string;

  constructor(
    public store: StoreService,
    private userService: UserService,
    private titleCasePipe:TitleCasePipe,
    private featureService: FeatureService,
    private reportService: ReportsService,
    private mcUserService: SysAdminUserServiceService,
    private router: Router,
    public dataService: DataService,
    private cookieService: CookieService,
    public dialog: MatDialog,
    public headerService: HeaderService,
    public toastService: ToastService,
    public translate: TranslateService,
    public translation: TranslationService,
    private cdRef: ChangeDetectorRef
  ) {
    this.store.user = getUser();
    const mcAdminToken = getAdminToken();
    if (mcAdminToken) {
      this.store.isMCAdmin = true;
      this.mcAdminToken = mcAdminToken;
      this.headerService.currentClass = ActiveClass.TEMPLATE;
    } else {
      this.store.isMCAdmin = false;
      this.mcAdminToken = null;
      if((this.store.user !== null &&
        this.store.user !== undefined)
        && isCollaborator(this.store.user)) {
          this.collaboratorUser = true;
      } else {
        this.collaboratorUser = false;
      }
      this.headerService.currentClass = ActiveClass.PROJECT;
    }
  }

  isProjectsPage(): boolean {
    const currentUrl = window.location.href;
    return currentUrl.endsWith("/projects");
  }

  showCSVImportPage() {
    this.dialog.open(ImportCSVDialogComponent, {
      maxWidth: '50vw',
      maxHeight: '70vh',
      width: '50vw',
      height: 'auto',
      disableClose: true,
      data: {
        template: this.dataService.template.getValue(),
        headers: this.reportService.tableHeaders
      }
    });
  }

  showIMLKImportPage() {
    this.dialog.open(ImportImklFileComponent, {
      maxWidth: '50vw',
      maxHeight: '70vh',
      width: '50vw',
      height: 'auto',
      disableClose: true,
      data: {
        template: this.dataService.template.getValue(),
        headers: this.reportService.tableHeaders
      }
    });
  }

  showExcelImportPage() {
    this.dialog.open(ImportExcelFileComponent, {
      maxWidth: '50vw',
      maxHeight: '70vh',
      width: '50vw',
      height: 'auto',
      disableClose: true,
      data: {
        template: this.dataService.template.getValue(),
        headers: this.reportService.tableHeaders
      }
    });
  }

  showShapeImportPage() {
    this.dialog.open(ImportShapeFileComponent, {
      maxWidth: '50vw',
      maxHeight: '70vh',
      width: '50vw',
      height: 'auto',
      disableClose: true,
      data: {
        template: this.dataService.template.getValue(),
        headers: this.reportService.tableHeaders
      }
    });
  }

  filteredResults: object[];
  results: SearchResult;

  tempClick(value, searchBar) {
    searchBar.value = this.currentTextSearch;

    let selectedProject = this.dataService.projects.find(project => project.id === value.identifiers.projectid);

    this.dataService.selectProject(selectedProject).then(() => {
      // After the project is fully selected and templates are updated
      if (this.store.copiedFeatures && this.store.copiedFeatures.length > 0) {
        this.store.destinationProject = selectedProject;
      }

      let selectedTemplate = this.dataService.templates.getValue().find(template => template.id === value.identifiers.templateid);
      this.dataService.triggerSearch({ temp: selectedTemplate });
      this.dataService.selectTemplate(selectedTemplate)
    }).catch(error => {
      console.error('Error while selecting the project:', error);
    });
  }

  trackByFn(index: number, item: any): number {
    return item.identifiers.projectid;  // or return some unique identifier (e.g., result.id or result.text)
  }
  reload(event: Event): void {
    event.preventDefault();  // Prevent the default router behavior
    window.location.reload();  // Reload the page
  }

  filter(event){
    this.currentTextSearch = event.target.value;
    this.store.foundProjects = null;

    if(this.currentTextSearch.length > 0) {
      this.dataService.searchProject(this.currentTextSearch, this.store.token).subscribe(
        (result) => {
          if(result.templates.length <= 0 && result.projects.length <= 0 && result.features.length <= 0) {
            this.filteredResults = [];
            this.toastService.warningToast("No results found")
          }
          else {
            this.filteredResults = result.templates
              .map(template => {
                const project = result.projects.find(project => project.id === template.project_id);

                return {
                  text: `${project.name} > ${template.name}`, // Concatenating project name and template name
                  identifiers: {
                    projectid: project.id,  // Project ID
                    templateid: template.id // Template ID
                  }
                };
              })
              .sort((a, b) => a.text.localeCompare(b.text)); // Sorting alphabetically based on 'text'
            this.results = result;
          }
        },
        (error) => {
          this.toastService.errorToast(error)
        }
      );
    } else {
      this.filteredResults = [];
    }

  }

  applySearch() {
    if(this.searchValue && this.searchValue.length > 0) {
      this.store.showLoading();
      this.store.foundProjects = null;
      this.dataService.searchProject(this.searchValue, this.store.token).subscribe(
        (result) => {
          this.store.hideLoading();
          this.store.foundProjects = result;
          this.store.searchMode = true;
          this.dataService.template.next(null);
          this.redirectTo('/search');
        },
        (error) => {
          this.toastService.errorToast('Error while searching data')
          this.store.hideLoading();
        }
      );
    } else {
      this.goToProjects();
    }

  }

  /**
   * Init element
   */
  ngOnInit() {
    const pc = this.store.proClass;
    if(pc === "project") {
      this.proClass = 'Projects';
    } else if(pc === "template") {
      this.proClass = 'Project Templates';
    } else if (pc === "domain") {
      this.proClass = 'Domains';
    } else {
      this.proClass = 'Projects';
    }

    if(!this.cookieService.get('CRSValue')) {
      this.cookieService.set('CRSValue', 'Geodetic', 365);
    }

    if(!this.cookieService.get('appLang')) {
      this.cookieService.set('appLang', 'en', 365);

    }

    let u = getUser();
    this.store.user = getUser();
    const mcAdminToken = getAdminToken();
    if (mcAdminToken) {
      this.store.isMCAdmin = true;
      this.mcAdminToken = mcAdminToken;
    } else {
      this.store.isMCAdmin = false;
      this.mcAdminToken = null;
    }
    if (u !== null && u !== undefined) {
      this.username = u.name;
    } else {
      u = getAdminInfo();
      if (u !== null && u !== undefined) {
        this.username = u.name;
      } else {
        this.username = null;
      }
    }
    this.permission = true;
    this.templateNameWidth = 80;
    this.messageBoxWidth = 20;
    this.showMessage = false;
    this.store.hideLoading();

    // Trigger change detection after the initial changes
  }


  // ngAfterContentInit() {
  //   if (this.toolbar) {
  //     this.toolbar.nativeElement.classList.remove('mat-toolbar-multiple-rows');
  //   }
  // }

  goToProjects() {
    this.dataService.testIfUpdatingForm(() => {
      this.dataService.projects = [];
      this.dataService.templates.next([]);
      this.proClass = 'Projects';
      this.headerService.currentClass = ActiveClass.PROJECT;

      this.store.changeProClassVisArchi('project', this.store.visibility, false);
      this.store.visibleProject.next('active project');
      this.inArchivedMode = false;
      this.store.searchMode = false;
      this.searchValue = '';
      this.redirectTo('/projects');
    });
  }

  goToArchivedProjects() {
    this.dataService.testIfUpdatingForm(() => {
      this.dataService.projects = [];
      this.dataService.templates.next([]);
      this.proClass = 'Archived Projects';
      this.inArchivedMode = true;
      this.headerService.currentClass = ActiveClass.APROJECT;

      this.store.changeProClassVisArchi('project', this.store.visibility, true);
      this.store.visibleProject.next('Archived project');
      this.store.searchMode = false;
      this.router.navigateByUrl('/projects', { skipLocationChange: false }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  goToUserDomains() {
    this.dataService.testIfUpdatingForm(() => {
      this.dataService.projects = [];
      this.dataService.templates.next([]);
      this.proClass = 'Domains';
      this.headerService.currentClass = ActiveClass.DOMAIN;
      this.store.changeProClassVisArchi('domain', this.store.visibility, false);
      this.store.visibleProject.next('current domain');
      this.inArchivedMode = false;
      this.store.searchMode = false;
      this.router.navigateByUrl('/projects', { skipLocationChange: false }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  goToUserArchivedDomains() {
    this.dataService.testIfUpdatingForm(() => {
      this.dataService.projects = [];
      this.dataService.templates.next([]);
      this.proClass = 'Archived Domains';
      this.headerService.currentClass = ActiveClass.ADOMAIN;

      this.store.changeProClassVisArchi('domain', this.store.visibility, true);
      this.store.visibleProject.next('Archived domain');
      this.store.searchMode = false;
      this.router.navigateByUrl('/projects', { skipLocationChange: false }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  goToUserProjectsTemplate() {
    this.dataService.testIfUpdatingForm(() => {
      this.dataService.projects = [];
      this.dataService.templates.next([]);
      this.proClass = 'Project Templates';
      this.headerService.currentClass = ActiveClass.TEMPLATE;
      this.store.changeProClassVisArchi('template', this.store.visibility, false);
      this.store.visibleProject.next('current Project template');
      this.inArchivedMode = false;
      this.store.searchMode = false;
      this.router.navigateByUrl('/projects', { skipLocationChange: false }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  goToUserArchivedProjectsTemplate() {
    this.dataService.testIfUpdatingForm(() => {
      this.dataService.projects = [];
      this.dataService.templates.next([]);
      this.proClass = 'Arch Project Templates';
      this.headerService.currentClass = ActiveClass.ATEMPLATE;
      this.store.changeProClassVisArchi('template', this.store.visibility, true);
      this.store.visibleProject.next('Archived Project Templates');
      this.inArchivedMode = true;
      this.store.searchMode = false;
      this.router.navigateByUrl('/projects', { skipLocationChange: false }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  goToProjectsTemplates() {
    this.dataService.testIfUpdatingForm(() => {
      this.dataService.projects = [];
      this.dataService.templates.next([]);
      this.proClass = 'Project Templates';
      this.headerService.currentClass = ActiveClass.TEMPLATE;
      this.inArchivedMode = false;

      this.store.changeProClassVisArchi('template', 'public', false);
      this.store.visibleProject.next('Public active project template');
      this.store.searchMode = false;
      this.router.navigateByUrl('/projects', { skipLocationChange: false }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  goToArchivedProjectsTemplates() {
    this.dataService.testIfUpdatingForm(() => {
      this.dataService.projects = [];
      this.dataService.templates.next([]);
      this.proClass = 'Arch Project Templates';
      this.headerService.currentClass = ActiveClass.ATEMPLATE;
      this.inArchivedMode = true;

      this.store.changeProClassVisArchi('template', 'public', true);
      this.store.visibleProject.next('Public Archived Project Templates');
      this.store.searchMode = false;
      this.router.navigateByUrl('/projects', { skipLocationChange: false }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  convertProjectToDomain() {
    this.dataService.testIfUpdatingForm(() => {
      this.convertProjectToDomainAfterCheck();
    });
  }

  convertProjectToDomainAfterCheck() {
    const currentProject = this.dataService.project;
    const proClass = this.titleCasePipe.transform(this.store.proClass);
    if (currentProject === undefined || currentProject === null) {
      this.toastService.errorToast(`No ${proClass} is selected, please select the ${proClass} to be copied`);
      return;
    }

    if (this.inArchivedMode) {
      this.toastService.errorToast(`Archived ${proClass} cannot be copied`);
      return;
    }

    this.openProjectToDomainDialog();
    return;
  }

  convertProjectToTemplate() {
    this.dataService.testIfUpdatingForm(() => {
      this.convertProjectToTemplateAfterCheck();
    });
  }

  convertProjectToTemplateAfterCheck() {
    const currentProject = this.dataService.project;
    const proClass = this.titleCasePipe.transform(this.store.proClass);
    if (currentProject === undefined || currentProject === null) {
      this.toastService.errorToast(`No ${proClass} is selected, please select the ${proClass} to be copied`);
      return;
    }

    if (this.inArchivedMode) {
      this.toastService.errorToast(`Archived ${proClass} cannot be copied`);
      return;
    }

    this.openProjectToProjectTemplateDialog();
    return;
  }

  copySelectedFeatures() {
    this.store.destinationProject = null;
    this.store.destinationTemplate = null;
    if(this.store.features && this.store.features.length > 0) {
       this.store.copiedFeatures = this.store.features;
    } else {
      this.toastService.errorToast('No records selected. Please choose records to copy or duplicate.')
    }
  }

  deleteSelectedFeatures() {
    if((this.store.copiedFeatures && this.store.copiedFeatures.length > 0) ||
      (this.store.features && this.store.features.length > 0)) {
        const size = this.store.features.length;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '400px',
          data: {
            message: this.translate.instant("THESE RECORDS WILL BE DELETED ON THE SERVER"),
            title: this.translate.instant("DELETE") + " " + size + " " + this.translate.instant("RECORD(S)?")
          }
        });
        dialogRef.afterClosed().subscribe(result  => {

          if (result) {
            const token = getToken();
            this.store.showLoading();
            const ids = [];
            const features = this.store.copiedFeatures && this.store.copiedFeatures.length > 0 ? this.store.copiedFeatures : this.store.features;
            for (const feature of features) {
              ids.push(feature.id);
            }
            const project = this.store.destinationProject ? this.store.destinationProject : this.dataService.project;
            const template = this.store.destinationTemplate ? this.store.destinationTemplate : this.dataService.template.getValue();
            this.featureService.deleteFeatures(ids, template, project, token).subscribe(
              res => {
                this.store.features = null;
                this.toastService.successToast(size + ' record(s) deleted')
                if(this.store.searchMode) {
                  this.applySearch();
                } else {
                  this.dataService.template.next(template);
                }
                this.store.hideLoading();
              },
              errmes => {
                this.store.hideLoading();
                this.toastService.errorToast('Error while deleting the features' + errmes)
              }
            );
          }
        });
    } else {
      this.toastService.errorToast('No records selected. Please choose records to delete')
    }
  }

  pasteCopiedFeaturesAction(project, template) {
    const features = this.store.copiedFeatures;
    const featuresId = [];
    for(const feature of this.store.copiedFeatures) {
        featuresId.push(feature.id);
    }

    const token = getToken();
    this.store.showLoading();
    this.featureService.duplicateFeatures(featuresId, template, project, token, true).subscribe(
      (result) => {
        if(this.store.deleteFeaturesAfterPasting) {
          const fIds = [];
          for(const f of features) {
            fIds.push(f.id);
          }
          this.featureService.deleteFeatures(fIds, template, project, token).subscribe(
            (res) => {
              this.store.hideLoading();
              this.store.copiedFeatures = [];
              this.toastService.successToast("Records pasted successfully!")
              this.redirectTo('/projects');
            },
            (err) => {
              this.store.hideLoading();
            }
          );
        } else {
          const feat = result['features'];
          if(feat && feat.length > 0) {
            if(feat.length === this.store.copiedFeatures.length) {
              this.store.copiedFeatures = [];
              if(!this.store.searchMode) {
                this.dataService.template.next(template);
              } else {
                this.applySearch();
              }
              this.toastService.successToast(`${feat.length} Records pasted successfully!`)
            } else {
              this.toastService.errorToast('Pasting records failed, verify that source and destination forms have the same structure')
            }
          } else {
            this.toastService.errorToast('Pasting records failed, verify that source and destination forms have the same structure')
          }
          this.store.hideLoading();
        }
      },
      (error) => {
        this.store.hideLoading();
        this.toastService.errorToast('Pasting records failed, verify that source and destination forms have the same structure')
      }
    )
  }

  pasteCopiedFeatures() {
    //if(this.store.searchMode) {
      // return;
    //}
    if(this.store.copiedFeatures && this.store.copiedFeatures.length > 0) {
      let project = this.store.destinationProject;
      let template = this.store.destinationTemplate;
      const templateId = this.store.copiedFeatures[0]['template_id'];

      if(this.store.destinationProject || this.store.destinationTemplate) {
        let project = this.store.destinationProject ? this.store.destinationProject : this.dataService.project;
        let template = this.store.destinationTemplate ? this.store.destinationTemplate : this.dataService.template.getValue();
        if(templateId === template.id) {
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '400px',
            data: {
              message: this.translate.instant("PASTING INTO THE SAME FORM WILL DUPLICATE THE RECORDS. DO YOU WISH TO CONTINUE?"),
              title: this.translate.instant("DUPLICATE RECORD(S) ALERT")
            }
          });
          dialogRef.afterClosed().subscribe(result  => {
            if (result) {
              if(!project) {
                project = this.dataService.project;
              }
              if(!template) {
                template = this.dataService.template.getValue();
              }
              this.pasteCopiedFeaturesAction(project, template);
              if(this.store.searchMode) {
                this.applySearch();
              }
            }
          });
        } else {
          this.pasteCopiedFeaturesAction(project, template);
        }

      } else {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '400px',
          data: {
            message: this.translate.instant("PASTING INTO THE SAME FORM WILL DUPLICATE THE RECORDS. DO YOU WISH TO CONTINUE?"),
            title: this.translate.instant("DUPLICATE RECORD(S) ALERT")
          }
        });
        dialogRef.afterClosed().subscribe(result  => {
          if (result) {
            if(!project) {
              project = this.dataService.project;
            }
            if(!template) {
              template = this.dataService.template.getValue();
            }
            this.pasteCopiedFeaturesAction(project, template);
            if(this.store.searchMode) {
              this.applySearch();
            }
          }
        });
      }
    } else {
      this.toastService.errorToast('Please copy records before attempting to paste.')
    }
  }

  duplicateSelectedFeatures() {
    if(this.store.features && this.store.features.length > 0){
      this.copySelectedFeatures();
      this.pasteCopiedFeatures();
    } else {
      this.toastService.errorToast('No records selected. Please choose records to copy or duplicate.')
    }

  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    this.router.navigate([uri]));
 }

  goToProjectsDomains() {
    this.dataService.testIfUpdatingForm(() => {
      this.proClass = 'Domains';
      this.headerService.currentClass = ActiveClass.DOMAIN;
      this.inArchivedMode = false;

      this.store.changeProClassVisArchi('domain', this.store.visibility, false);
      this.store.visibleProject.next('current domain');
      this.router.navigateByUrl('/projects', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  goToArchivedProjectsDomains() {
    this.dataService.testIfUpdatingForm(() => {
      this.proClass = 'Archived Domains';
      this.headerService.currentClass = ActiveClass.ADOMAIN;
      this.inArchivedMode = true;


      this.store.changeProClassVisArchi('domain', this.store.visibility, true);
      this.store.visibleProject.next('Archived private domain');
      this.router.navigateByUrl('/projects', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/projects']);
      });
    });
  }

  goToProjectMembers() {
    this.dataService.testIfUpdatingForm(() => {
      this.router.navigate(['/users'], { queryParams: { type: 'projects-members' } });
      this.store.hideProjects();
    });
  }


  goToReportTemplates() {
    this.dataService.testIfUpdatingForm(() => {
      let token = getToken();
      if (token !== null && token !== undefined) {
          this.router.navigate(['/report-templates']);
      } else {
        token = getAdminToken();
        if (token !== null && token !== undefined) {
          this.router.navigate(['/mcadmins_report_templates']);
        }
      }
      this.store.hideProjects();
    });
  }

  goToTemplatestyle() {
    this.dataService.testIfUpdatingForm(() => {
      let token = getToken();
      if(token === null || token === undefined) {
        token = getAdminToken();
      }
      if (token !== null && token !== undefined) {
          this.router.navigate(['/templates_style']);
      }
      this.store.hideProjects();
    });
  }

  goToReports() {
    this.dataService.testIfUpdatingForm(() => {
      this.router.navigate(['/reports']);
      this.store.hideProjects();
    });
  }

  goToAccountInfo() {
    this.dataService.testIfUpdatingForm(() => {
      if (this.store.isMCAdmin) {
        this.router.navigate(['/mcadmins']);
      } else {
        this.router.navigate(['/users'], { queryParams: { type: 'user-info' } });
        this.store.hideProjects();
      }
    });
  }

  /**
   * This method open the account setting page
   */
  openCRSSettingPage() {
    let crs = this.cookieService.get('CRSValue');
    let data = {};
    if (crs !== null && crs !== undefined && crs !== '') {
      data = {
        crs
      };
    }
    this.dialog.open(SettingCoordinatesReferenceDialogComponent, {
      width: '500px',
      data
    });
  }

  /**
   * This method send logout request to MyCumulus server and when succeed
   * the user information is cleared from the local storage and also
   * in the IAppState.
   */
  logout() {
    // Send logout request to the server and clear the token from the localstorage
    // Also Reset AppState in Reset
    this.searchValue = null;
    this.dataService.testIfUpdatingForm(() => {
      let token = getToken();
      this.store.showLoading();
      if (token !== null && token !== undefined) {
        this.userService.logout(token).subscribe(
          res => {
            logoutUser();
            removeToken();
            this.store.logout();
            this.proClass = "Projects";
            this.setProjectClass();
            this.router.navigate(['/login']);
            this.store.hideLoading();

            logoutUser();
            this.dataService.initializeTemplateService();
            this.dataService.initializeProjectService();
          },
          err => {
            logoutUser();
            this.store.logout();
            this.proClass = "Projects";
            this.setProjectClass();
            this.router.navigate(['/login']);
            this.store.hideLoading();

            logoutUser();
            this.dataService.initializeTemplateService();
            this.dataService.initializeProjectService();
          }
        );
      } else {
        token = getAdminToken();
        this.mcUserService.logout(token).subscribe(
          res => {
            logoutUser();
            removeAdminToken();
            this.store.logout();
            this.setProjectClass();
            this.proClass = "Projects";
            this.router.navigate(['/login']);
            this.store.hideLoading();

            logoutUser();
            this.dataService.initializeTemplateService();
            this.dataService.initializeProjectService();
          },
          err => {
            logoutUser();
            this.store.logout();
            this.proClass = "Projects";
            this.setProjectClass();
            this.router.navigate(['/login']);
            this.store.hideLoading();

            logoutUser();
            this.dataService.initializeTemplateService();
            this.dataService.initializeProjectService();
          }
        );
      }
      this.myCumulusAdmin = false;
    });
  }

  setProjectClass() {
    this.headerService.currentClass = ActiveClass.PROJECT;
    this.inArchivedMode = false;
  }

  archivedDomain() {
    this.dataService.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.dataService.project;
      let isMyCumulusAdministrator = false;
      if (project !== null && project !== undefined) {
        let token = getToken();
        let proToken = {
          key: '',
          value: ''
        };
        if(this.store.isMCAdmin) {
          token = getAdminToken();
          proToken = {
            key: 'X-Admin-Auth-Token',
            value: token.key
          };
          isMyCumulusAdministrator = true;
        } else {
          proToken = {
            key: 'X-Auth-Token',
            value: token.key
          };
        }
        this.store.showLoading();
        let currentProject = this.dataService.project;
        currentProject.archived = true;
        this.dataService.updateProject(currentProject, proToken, isMyCumulusAdministrator).subscribe(
          res => {
            this.dataService.deleteProjectLocally(res);

            currentProject = null;
            this.store.hideLoading();
          },
          errmes => {
            this.store.hideLoading();
            this.toastService.errorToast(errmes);
          }
        );
      } else {
        const proClass = this.titleCasePipe.transform(this.store.proClass);
        this.toastService.errorToast(`No ${proClass} is selected. Please select the ${proClass} that needs to be archived.`);
        return;
      }
    });
  }

  archivedProjectTemplate() {
    this.dataService.testIfUpdatingForm(() => {
      const user = getUser();
      let isMyCumulusAdministrator = false;
      const project = this.dataService.project;
      if (project !== null && project !== undefined) {
        let token = getToken();
        let proToken = {
          key: '',
          value: ''
        };
        if(this.store.isMCAdmin) {
          token = getAdminToken();
          proToken = {
            key: 'X-Admin-Auth-Token',
            value: token.key
          };
          isMyCumulusAdministrator = true;
        } else {
          proToken = {
            key: 'X-Auth-Token',
            value: token.key
          };
        }
        this.store.showLoading();
        let currentProject = this.dataService.project;
        currentProject.archived = true;
        this.dataService.updateProject(currentProject, proToken, isMyCumulusAdministrator).subscribe(
          res => {
            this.dataService.deleteProjectLocally(res);

            currentProject = null;
            this.store.hideLoading();
          },
          errmes => {
            this.store.hideLoading();
            this.toastService.errorToast(errmes);
          }
        );
      } else {
        this.toastService.errorToast(`No Project Template is selected. Please select the Project Template that needs to be archived.`);
        return;
      }
    });
  }

  archivedProject() {
    this.dataService.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.dataService.project;
      if (project !== null && project !== undefined) {
        this.archivedProjectAfterCheck();
      } else {
        const proClass = this.titleCasePipe.transform(this.store.proClass);
        this.toastService.errorToast(`No ${proClass} is selected. Please select the ${proClass} that needs to be archived.`);
        return;
      }
    });
  }

  archivedProjectAfterCheck() {
    let currentProject = this.dataService.project;
    const proClass = this.titleCasePipe.transform(this.store.proClass);
    if (currentProject === undefined || currentProject === null) {
      this.toastService.errorToast(`No ${proClass} is selected. Please select the ${proClass} that needs to be archived.`);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        title: this.translate.instant("ARCHIVE") + " " + proClass + " " + currentProject.name + "?",
        message: this.translate.instant("THE ARCHIVED") + " " + proClass + " " + this.translate.instant("WILL NOT BE VISIBLE ANYMORE USERS OF THE MOBILE APP")
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        let token = getToken();
        let proToken = {
          key: '',
          value: ''
        };
        if(this.store.isMCAdmin) {
          token = getAdminToken();
          proToken = {
            key: 'X-Admin-Auth-Token',
            value: token.key
          };
        } else {
          proToken = {
            key: 'X-Auth-Token',
            value: token.key
          };
        }
        this.store.showLoading();
        currentProject.archived = true;
        this.dataService.updateProject(currentProject, proToken).subscribe(
          res => {
            this.dataService.deleteProjectLocally(res);

            currentProject = null;
            this.store.hideLoading();
          },
          errmes => {
            this.store.hideLoading();
            this.toastService.errorToast(errmes);
          }
        );
      }
    });
  }

  unArchivedProject() {
    this.dataService.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.dataService.project;
      if (project !== null && project !== undefined && user.id === project.created_by) {
        this.unArchivedProjectAfterCheck();
      } else {
        const proClass = this.titleCasePipe.transform(this.store.proClass);
        this.toastService.errorToast(`No ${proClass} is selected. please select the ${proClass} to be restored?`);
        return;
      }
    });
  }

  unArchivedProjectAfterCheck() {
    const currentProject = this.dataService.project;
    const proClass = this.titleCasePipe.transform(this.store.proClass);
    if (currentProject === undefined || currentProject === null) {
      this.toastService.errorToast(`No ${proClass} is selected, please select the ${proClass} to be restored?`);
      return;
    }
    let token = getToken();
    let proToken = {
      key: '',
      value: ''
    };
    let isMyCumulusAdministrator = false;
    if(this.store.isMCAdmin) {
      token = getAdminToken();
      proToken = {
        key: 'X-Admin-Auth-Token',
        value: token.key
      };
      isMyCumulusAdministrator = true;
    } else {
      proToken = {
        key: 'X-Auth-Token',
        value: token.key
      };
    }
    currentProject.archived = false;
    this.dataService.updateProject(currentProject, proToken, isMyCumulusAdministrator).subscribe(
      res => {
        this.dataService.deleteProjectLocally(res);
      },
      errmes => {
        this.toastService.errorToast(errmes);
      }
    );
  }

  openProjectToDomainDialog() {
    if(this.store.archived) {
      const projectClass = this.titleCasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is not allowed on an archived ${projectClass}`);
      return;
    }
    this.dataService.testIfUpdatingForm(() => {
      const user = getUser();
      const permission = user.permissions;
      if (permission !== null && permission !== undefined) {
        const projPerm = permission.projects;
        if (projPerm[0] === "create") {
          this.openCopyProjectToDomainDialogAfterCheck();
        }
      } else {
        this.openCopyProjectToDomainDialogAfterCheck();
      }
    });
  }

  openCopyProjectToDomainDialogAfterCheck() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    const currentProject = this.dataService.project;
    dialogConfig.data = {
      project: currentProject,
      mode: {
        text: this.translate.instant('COPY'),
        function: 'Copy'
      }
    };
    if(this.store.isMCAdmin) {
      this.dialog.open(ProjectToDomaineComponent, dialogConfig);
    } else {
      this.dialog.open(ProjectToDomaineComponent, dialogConfig);
    }
  }

  openProjectToProjectTemplateDialog() {
    if(this.store.archived) {
      const projectClass = this.titleCasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is not allowed on an archived ${projectClass}`);
      return;
    }
    this.dataService.testIfUpdatingForm(() => {
      const user = getUser();
      const permission = user.permissions;
      if (permission !== null && permission !== undefined) {
        const projPerm = permission.projects;
        if (projPerm[0] === "create") {
          this.openCopyProjectToProjectTemplateDialogAfterCheck();
        }
      } else {
        this.openCopyProjectToProjectTemplateDialogAfterCheck();
      }
    });
  }

  openCopyProjectToProjectTemplateDialogAfterCheck() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.closeOnNavigation = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.autoFocus = true;
    const currentProject = this.dataService.project;
    dialogConfig.data = {
      project: currentProject,
      mode: {
        text: this.translate.instant('COPY'),
        function: 'Copy'
      }
    };
    ;
    if(this.store.isMCAdmin) {
      this.dialog.open(ProjectToProjectTemplateComponent, dialogConfig);
    } else {
      this.dialog.open(ProjectToProjectTemplateComponent, dialogConfig);
    }
  }

  showCustomExport() {
    if(this.dataService.templateData) {
      const dialogRef = this.dialog.open(ExportDataCriteriaDialogComponent, {
        maxWidth: '50vw',
        maxHeight: '70vh',
        width: '50vw',
        height: 'auto',
        disableClose: true,
        data: {
          template: this.dataService.template.getValue(),
          headers: this.reportService.createTableHeaderArrayWithinTemplate(this.dataService.template.getValue())
        }
      });

      dialogRef.afterClosed().subscribe(result  => {
        if (result !== null && result !== undefined && result.event === 'search') {
          this.reportService.submitSelectedItems(result.advanced_request);
        }
      });

    } else {
      this.toastService.errorToast("That action is only available in data view mode")
    }
  }

  showEnexisExportDialog() {
    const project = this.dataService.project;
    if(!project) {
      this.toastService.errorToast(`Please choose a project`);
      return;
    }
    const dialogRef = this.dialog.open(EnexisExportCritDialogComponent, {
      maxWidth: '50vw',
      maxHeight: '80vh',
      width: '50vw',
      height: 'auto',
      disableClose: true,
      data: null
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null && result !== undefined && result.event === 'search') {
        this.submitReport(result.advanced_request);
      }
    });
  }

  showStedinExportDialog() {
    const project = this.dataService.project;
    if(!project) {
      this.toastService.errorToast(`Please choose a project`);
      return;
    }
    const dialogRef = this.dialog.open(ExportStedinCritDialogComponent, {
      maxWidth: '50vw',
      maxHeight: '80vh',
      width: '50vw',
      height: 'auto',
      disableClose: true,
      data: null
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null && result !== undefined && result.event === 'search') {
        this.submitReport(result.advanced_request);
      }
    });
  }

  submitReport(data) {
    const aggregate = data.aggregateBody;
    const output = data.outputType;
    const splitType = data.splitType;
    const report_template_id = data.report_template_id;
    const report_template_rtid = data.report_template_rtid;
    const placesHolders = data.place_holders;
    let aggregation = [];

    if (aggregate !== null && aggregate.length > 0) {
      aggregation = aggregate;

      this.generateEnexisReport(report_template_rtid, aggregation, output, splitType, placesHolders);
    }
  }

  generateEnexisReport(report_template_rtid, operators, reportType, splitType, placesHolders) {
    const token = getToken();
    const body = JSON.stringify({
      configuration: {
        format: reportType,
        split_type: splitType
      },
      report_template_rtid: report_template_rtid,
      place_holders: placesHolders,
      queries: [{
        collection: 'templates',
        stages: operators,
      }]
    });
    this.store.showLoading();
    this.reportService.createEnexisReport(token, body).subscribe(
      res => {
        this.store.hideLoading();
        this.toastService.successToast('The request to create the report has been sent.');
      },
      err => {
        //err message is not working correctly
        this.message = err;
        this.toastService.errorToast(err.message)
        this.store.hideLoading();
      }
    );

  }

  protected readonly ActiveClass = ActiveClass;
  protected readonly faCaretDown = faCaretDown;
  protected readonly localStorage = localStorage;
    protected readonly getUser = getUser;
  protected readonly getApiAURL = getApiAURL;
  protected readonly getAtStartup = getAtStartup;
}
