<div fxFlexLayout="row">
  <div fxFlex>
    <h3><span>{{ "URL PRIVATE SERVER" | translate }}</span></h3>
    <form novalidate [formGroup]="configUrlForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      <mat-dialog-content>
        <p>
          <mat-form-field class="full-width">
            <input matInput autocomplete="off" formControlName="url" [placeholder]="'YOUR PRIVATE URL' | translate" type="text" required>
            @if (configUrlForm.get('url').hasError('required') && configUrlForm.get('url').touched && setStartup) {
              <mat-error><span>{{ "PRIVATE URL IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
          <mat-checkbox color="primary" #setStartupCheck (click)="$event.stopPropagation()" (change)="setPrivateStartup(setStartupCheck.checked)"
          checked name="enabled" [checked]="setStartup" class="mr-2">{{"USE PRIVATE SERVER AT STARTUP" | translate}}</mat-checkbox>
        </p>
        <!-- <small *ngIf="urlEmpty" style="color: red; margin-top: -25px !important;"><span>{{ "URL IS REQUIRED AND SHOULD BE NOT EMPTY" | translate }}</span></small> -->

      </mat-dialog-content>
      <mat-dialog-actions class="button-group">
        <span class="flex-spacer"></span>
        @if (configUrlForm.get('url').hasError('required') && setStartup) {
        <button mat-button type="submit" class="btn submit-btn" disabled="true"><span>{{ "OK" | translate }}</span></button>
        }
        @else {
        <button mat-button type="submit" class="btn submit-btn"><span>{{ "OK" | translate }}</span></button>
        }
      </mat-dialog-actions>
    </form>
  </div>
</div>
