import { Injectable } from '@angular/core';
import { User, InvitationCode, Collaborator } from '../share/user';
import { Token } from '../share/token';
import { TokenResult, LogoutResult, CollaboratorsResult } from '../share/result';
import { UserResult } from '../share/result';
import { Observable } from 'rxjs';
import { map, catchError  } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { getPrivateApiAURL() } from '../share/getPrivateApiAURL()';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { getPrivateApiAURL } from '../share/utils';

// const getPrivateApiAURL() = getPrivateApiAURL();

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private processHttpMsgService: ProcessHttpmsgService
  ) { }

  logout(token): Observable<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key
      })
    };
    return this.http.post<LogoutResult>(getPrivateApiAURL() + 'users/logout', null, httpOptions)
    .pipe(map(result => {
      return result.message;
    }))
    .pipe(
      map(error => {
        return error;
      })
    );
  }

  loginUser(user): Observable<Token> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const loginBody = {
      credentials: {
        name: user.name,
        password: user.password,
      },
      remember: user.remember
    };

    const url = getPrivateApiAURL();

    return this.http.post<TokenResult>(url + 'users/login', loginBody, httpOptions)
    .pipe(map(result => {
      return result.token;
    }))
    .pipe(
      catchError(this.processHttpMsgService.handleError));
  }

  signupTokenlessUser(user) {
    return this.http.post<object>(getPrivateApiAURL() + 'users/clientSignup', user, { observe: 'response' })
  }

  deleteUser(user, token): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key
      })
    };
    return this.http.delete<User>(getPrivateApiAURL() + 'managedusers/' + user.id, httpOptions)
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  updateUserNameAndEmail(user, token, email=''): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    const data = {
      name: user.name,
      email
    };
    return this.http.put<UserResult>(getPrivateApiAURL() + 'users/' + user.id + '/update', data, httpOptions)
    .pipe(map(result => {
      return result.user;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  addingCollaboratorUser(email, token): Observable<InvitationCode> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    const data = {
      email
    };
    return this.http.post<InvitationCode>(getPrivateApiAURL() + 'users/invite', data, httpOptions)
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  signupCollaborator(name, email, password, userType, token): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const data = {
      email,
      name,
      password,
      user_type: userType
    };
    return this.http.post<UserResult>(getPrivateApiAURL() + 'users/signupmanaged?token=' + token, data, httpOptions)
    .pipe(map(result => {
      return result.user;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  changePassword(password, new_password, token): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    const data = {
      password,
      new_password
    };

    return this.http.put<UserResult>(getPrivateApiAURL() + 'users/changepassword', data, httpOptions)
    .pipe(map(result => {
      return result.user;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getUser(token, user_id, isMyCumulusAdministrator=false): Observable<User> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    if (isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Admin-Auth-Token': token.key
        })
      }
    }
    return this.http.get<UserResult>(getPrivateApiAURL() + 'users/' + user_id, httpOptions)
    .pipe(map(result => {
      return result.user;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getCollaboratorUsers(user, token): Observable<Collaborator[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    return this.http.get<CollaboratorsResult>(getPrivateApiAURL() + 'users/' + user.id + '/managed', httpOptions)
    .pipe(map(result => {
      return result.users.instances;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  grantCollaboratorPermissionToCreateProject(managedUserId, token): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    const data = {
      permissions: {
        projects: [
          'create'
        ]
      }
    };

    return this.http.put<UserResult>(getPrivateApiAURL() + 'users/' + managedUserId + '/grant', data, httpOptions)
    .pipe(map(result => {
      return result.user;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  revokeCollaboratorPermissionToCreateProject(managedUserId, token): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    const data = {
      permissions: {
        projects: [
          'create'
        ]
      }
    };

    return this.http.put<UserResult>(getPrivateApiAURL() + 'users/' + managedUserId + '/revoke', data, httpOptions)
    .pipe(map(result => {
      return result.user;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  activateAccount(body: object): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put<any>( getPrivateApiAURL() + 'users/activate', body, httpOptions)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  resetPassword(email: any, token = null): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Admin-Auth-Token': token ? token.key : null
      }),
      params: new HttpParams()
        .set('email', email)
    };
    return this.http.post<any>(getPrivateApiAURL() + 'users/initpasswordreset', null, httpOptions)
    .pipe(catchError(this.processHttpMsgService.handleError));
    /*
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Admin-Auth-Token': token ? token.key : null
        })
      };
      return this.http.post<any>(getPrivateApiAURL() + 'users/initpasswordreset?email=' + email, httpOptions)
      .pipe(catchError(this.processHttpMsgService.handleError));
    */
  }
}
