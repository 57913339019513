import { TranslateModule } from '@ngx-translate/core';
import {Component, OnInit, Input, ViewChild, Output, EventEmitter, Inject, HostListener} from '@angular/core';
import { Project } from 'src/app/share/projects';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { User } from 'src/app/share/user';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { getAdminToken, getToken, equalsIgnoringCase } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { Template } from 'src/app/share/template';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import {DialogActionsComponent} from '../dialog-actions/dialog-actions.component';

@Component({
  standalone: true,
  imports: [TranslateModule,
    MessageBoxComponent,
    ReactiveFormsModule,
    MatDialogContent,
    MatRadioButton,
    MatOptionModule,
    MatDialogActions,
    MatSelectModule,
    MatDialogModule,
    MatButtonModule,
    DialogActionsComponent, MatRadioGroup,
  ],
  selector: 'app-import-template-from-domain',
  templateUrl: './import-template-from-domain.component.html',
  styleUrls: ['./import-template-from-domain.component.scss', '../dialog-btns.component.scss']
})
export class ImportTemplateFromDomainComponent implements OnInit {
  @Input() domain: Project;
  formDialogForm: FormGroup;

  domain_id: string;
  user_id: string;
  project_class: string;
  description: string;
  domains: Project[];
  forms: Template[];
  users: User[];
  visibility: string = "public";
  @Output() invalidIconDropped = new EventEmitter<boolean>();
  @Input() user: User;
  @Input() mode = {
    text: 'Create',
    function: 'Create'
  };
  @ViewChild('pjtIcon') img;
  @ViewChild('fform') formFormDirective;

  metadata: any;


  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;

  constructor(
    private store: StoreService,
    private fb: FormBuilder,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ImportTemplateFromDomainComponent>,
    public toastService: ToastService,
  ) {
    this.visibility = "public";
    this.domains = [];
    this.forms = [];
    this.users = [];
    this.project_class = this.store.proClass;
    this.createForm();
  }

  ngOnInit() {
    this.getDomainsOnVisibilityChange(null);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  onCancel(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close();
  }

  createForm() {
    this.formDialogForm = this.fb.group({
      visibility: this.visibility,
      domain_id: ['', Validators.required],
      form_id: ['']
    });
  }

  onSubmit() {

    const temp = this.formDialogForm.value;
    const formId = temp['form_id'];
    let selectedForm = {
      name: '',
      description: ''
    };
    if(formId !== null && formId !== undefined) {
      selectedForm = this.forms.find(f => f.id == formId);
    }
    const project = this.dataService.project;
    const token = getToken();
    const form = {
      user_id: token.user_id,
      project_id: project.id,
      template_id: temp.form_id,
    };
    this.store.showLoading();
    this.dataService.copyFromDomain(form, token).subscribe(
      res => {

        this.dataService.createTemplateLocally(res);
        this.dataService.showTemplateInfo();
        this.store.hideLoading();
        this.dialogRef.close();
      },
      errmess => {
        this.toastService.errorToast(errmess);
        this.store.hideLoading();
    });
  }

  getDomainsOnVisibilityChange(obj) {

    this.visibility = obj !== null && obj !== undefined ? obj.value : 'public';

    if(this.visibility === null || this.visibility === undefined) {
      this.visibility = "public";
    }
    let isMyCumulusAdministrator = false;
    let token = getToken();
    let proToken = {
      key: 'X-Auth-Token',
      value: token.key
    };
    const proClass = "domain";
    if(token === null || token === undefined) {
      token = getAdminToken();
      if(token !== null && token !== undefined) {
        isMyCumulusAdministrator = true;
        proToken = {
          key: 'X-Admin-Auth-Token',
          value: token.key
        };
      }
    }

    const archived = false;
    this.store.showLoading();
    this.dataService.getProjects(proToken, proClass, this.visibility, archived, isMyCumulusAdministrator).subscribe(
      res => {
        if(res !== null) {
          this.domains = res.sort(
            (a, b) => {
              return equalsIgnoringCase(a.name, b.name);
            });
        }
        this.store.hideLoading();
      },
      errmess => {
        this.toastService.errorToast(errmess);
        this.store.hideLoading();
    });
  }

  getFormOnChange(obj) {
    const formId = obj.value;
    if(formId !== null && formId !== undefined){
      const form = this.forms.find(f => f.id === formId);
      this.description = form.description;
    }
  }

  getDomainsOnChange(obj) {

    const domainId = obj.value;
    if(domainId !== null && domainId !== undefined) {


      let isMyCumulusAdministrator = false;
      let token = getToken();

      const proClass = "domain";
      if(token === null || token === undefined) {
        token = getAdminToken();
        if(token !== null && token !== undefined) {
          isMyCumulusAdministrator = true;

        }
      }
      this.store.showLoading();
      this.dataService.requestTemplates(token, domainId, isMyCumulusAdministrator).subscribe(
        res => {

          if(res !== null) {
            this.forms = res.sort(
              (a, b) => {
                return equalsIgnoringCase(a.name, b.name);
              });
          }
          this.store.hideLoading();
        },
        errmess => {
          this.toastService.errorToast(errmess);
          this.store.hideLoading();
      });
    }
  }

}
