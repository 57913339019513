<div class="dialog-content" fxLayout="column">
  <div fxFlex="10">
    <h4>{{ "COORDINATES" | translate }}</h4>
  </div>
  <div class="mb-2 mt-3" fxFlex="80">
    <table mat-table multiTemplateDataRows matSort [dataSource]="dataSource" class="table table-striped table-bordered">
      @for (column of displayedColumns; track column) {
        <ng-container [matColumnDef]="column">
          <th *matHeaderCellDef> {{column}} </th>
          <td *matCellDef="let element">
            <span >{{element[column]}}</span>
          </td>
        </ng-container>
      }
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div fxFlex="10" mat-dialog-actions class="button-group">
    @if (coordModified) {
      <button mat-raised-button (click)="updateCoordinates()" class="submit-btn" cdkFocusInitial><span>{{ "UPDATE" | translate }}</span></button>
    }
    <button mat-raised-button (click)="closeDialog()" style="color: black !important;" class="text-floral-white cancel-btn" cdkFocusInitial><span>{{ "CLOSE" | translate }}</span></button>
  </div>
</div>
