
export class Field {
  _class: string;
  name: string;
  label: string;
  description: string;
  enabled: boolean;
  visible: boolean;
  required: boolean;
  use_previous_value: boolean
  constraints: object[];
  event_actions: object;
  default_value_expression: string;
  default_value: string;
  show_value_on_map:boolean;
}
