import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import {ToastService} from '../../../services/toast.service';
import {DataService} from '../../../services/data.service';


@Component({
  standalone: true,
  imports: [TranslateModule,
    MatDialogModule,
    MatButtonModule,
  ],
  selector: 'app-unsaved-changes-dialog',
  templateUrl: './unsaved-changes-dialog.html',
  styleUrls: ['./unsaved-changes-dialog.scss', '../dialog-btns.component.scss']
})
export class UnsavedChangesDialogComponent {
  formName: string;
  title: string = this.translate.instant("UNSAVED CHANGES ON FORM");
  formDuplication: boolean;
  description: string;

  constructor(
    public dialogRef: MatDialogRef<UnsavedChangesDialogComponent>,
    public toastService: ToastService,
    private translate: TranslateService,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data
    ) {
      if (data) {
        this.formName = data.formName;
        this.description = this.translate.instant("THERE ARE SOME CHANGES TO THE FORM") + " " + this.formName + ". " + this.translate.instant("WHAT WOULD YOU LIKE TO DO WITH THEM?");
        this.formDuplication = false;
      } else {
        toastService.errorToast("No data provided to the dialog");
        this.dialogRef.close();
      }
    }

  cancel(event) {
    event.preventDefault();
    this.dialogRef.close(null);
    event.stopPropagation();
  }

  saveAndExit(event) {
    event.preventDefault();
    this.dataService.buildFeatureSchema();
    this.dialogRef.close({
      response: false
    });
  }

  exitWithoutSaving(event): void {
    event.preventDefault();
    this.toastService.warningToast('Left without saving changes')
    this.dialogRef.close({
      response: true
    });
  }
}
