import { TranslateModule } from '@ngx-translate/core';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import { FileServiceService } from 'src/app/services/file-service.service';
import { StoreService } from 'src/app/services/store.service';
import { StyleServiceService } from 'src/app/services/style-service.service';
import { Style } from 'src/app/share/style';
import { User } from 'src/app/share/user';
import { getAdminToken, getToken } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { CommonModule } from '@angular/common';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';

@Component({
  standalone: true,
  imports: [TranslateModule,
    MessageBoxComponent,
    CommonModule,
    MatFormField,
    ReactiveFormsModule,
    MatDialogContent,
    MatError,
    MatDialogActions,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
  ],
  selector: 'app-style-new',
  templateUrl: './style-new.component.html',
  styleUrls: ['./style-new.component.scss', '../dialog-btns.component.scss']
})
export class StyleNewComponent implements OnInit {

  @Input() style: Style;
  private addedStyle: Style;
  styleDialogForm: FormGroup;
  image: string;
  name: string;
  description: string;
  @Input() user: User;
  @Input() mode = {
    text: 'Create',
    function: 'Create'
  };


  fileToUpload: File;
  errMsg: string;


  noFileIsSelected = false;
  styleBody: any;

  @ViewChild('fform') styleFormDirective;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;

  constructor(
    private store: StoreService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private fileService: FileServiceService,
    private styleService: StyleServiceService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<StyleNewComponent>,
    public toastService: ToastService,
  ) {
    if (data !== undefined && data !== null) {

      this.style = data.style;
      this.mode = data.mode;
    }
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.styleDialogForm = this.fb.group({
      userFile: ['', Validators.required]
    });
  }

  setFileData(event): void {
    event.preventDefault();
    if (event.target.files && event.target.files.length > 0) {
      const tempFile = event.target.files[0];
      this.noFileIsSelected = false;
      this.parseJson(tempFile).then(res =>{
        this.styleBody = res;
        this.fileToUpload = tempFile;
        this.noFileIsSelected = false;
      }, err => {
        this.noFileIsSelected = true;
      });
      this.store.updatedForm = true;
      event.stopPropagation();
    }
  }

  parseJson(file: File) {
    // Always return a Promise
    return new Promise((resolve, reject) => {
      let content = '';
      const reader = new FileReader();
      // Wait till complete
      reader.onloadend = function(e: any) {
        content = reader.result as string;
        resolve(content);
      };
      // Make sure to handle error states
      reader.onerror = function(e: any) {
        reject(e);
      };
      reader.readAsText(file);
    });
  }

  onSubmit() {
    if (!this.fileToUpload && !this.styleBody) {
      this.noFileIsSelected = true;
      this.toastService.errorToast('No file selected');
      return;
    }
    this.showMessage = false;
    this.store.showLoading();
    let token = getToken();
    let isMyCumulusAdministrator = false;
    if(token === null || token === undefined) {
      token = getAdminToken();
      isMyCumulusAdministrator = true;
    }
    const proToken = {
      value: token.key
    }
    try{
      const body = JSON.parse(this.styleBody);
      if(this.mode.function === 'Create') {
        this.styleService.createStyle(body, proToken, isMyCumulusAdministrator).subscribe(
          res => {
            this.toastService.successToast('Style created successfully');
            const result = {
              event: 'Add',
              data: res
            };
            setTimeout(() => {
              this.dialogRef.close(result);
            }, 3000);
            this.store.hideLoading();
          },
          error => {
            this.store.hideLoading();

            this.store.updatedForm = false;
            let msg = error ? `Error: ${error}` : `Importation failed, please retry later`;
            if (error instanceof Array) {
              const record = error.length > 1 ? 'records' : 'record';
              msg = `${error.length} ${record} could not be imported. No data of CSV has been imported` ;
            }
            this.toastService.errorToast(msg);
          }
        );
      } else {
        this.styleService.updateStyle(body, this.style.id, proToken, isMyCumulusAdministrator).subscribe(
          res => {
            this.toastService.successToast('Style updated successfully');
            const result = {
              event: 'Update',
              data: res
            };
            setTimeout(() => {
              this.dialogRef.close(result);
            }, 3000);
            this.store.hideLoading();
          },
          error => {
            this.store.hideLoading();

            this.store.updatedForm = false;
            let msg = error ? `Error: ${error}` : `Importation failed, please retry later`;
            if (error instanceof Array) {
              const record = error.length > 1 ? 'records' : 'record';
              msg = `${error.length} ${record} could not be imported. No data of CSV has been imported` ;
            }
            this.toastService.errorToast(msg);
          }
        );
      }
    } catch(e) {
      this.store.hideLoading();
      this.toastService.errorToast('Error while parsing the submitted file');
    }

  }
}
