import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import { Project } from 'src/app/share/projects';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { User } from 'src/app/share/user';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { getAdminToken, getToken } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import {DialogActionsComponent} from '../dialog-actions/dialog-actions.component';

@Component({
  standalone: true,
    imports: [TranslateModule,
        MessageBoxComponent,
        ReactiveFormsModule,
        MatDialogContent,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatDialogActions,
        MatDialogModule,
        MatButtonModule,
        DialogActionsComponent,
    ],
  selector: 'app-project-to-project-template',
  templateUrl: './project-to-project-template.component.html',
  styleUrls: ['./project-to-project-template.component.scss', '../dialog-btns.component.scss']
})
export class ProjectToProjectTemplateComponent implements OnInit {
  @Input() project: Project;
  private addedProject: Project;
  projectDialogForm: FormGroup;
  image: string;
  name: string;
  description: string;

  deleteProjectAfterCopy: boolean = false;
  @Output() invalidIconDropped = new EventEmitter<boolean>();
  @Input() user: User;
  @Input() mode = {
    text: 'Create',
    function: 'Create'
  };
  @ViewChild('pjtIcon') img;
  @ViewChild('fform') projectFormDirective;
  private messageBox: MessageBoxComponent;


  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;

  constructor(
    private store: StoreService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dataService: DataService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ProjectToProjectTemplateComponent>,
    public toastService: ToastService,
  ) {
    if (data !== undefined && data !== null) {
      this.project = data.project;
      this.mode = data.mode;
      this.name = data.project.name;
      this.description = data.project.description;

    }

    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    let name = '';
    let des = '';

    if (this.project !== undefined && this.project !== null) {
      name = this.project.name;
      des = this.project.description;

    }
    this.projectDialogForm = this.fb.group({
      name: [name, Validators.required],
      description: des
    });
  }

  onSubmit() {
    const temp = this.projectDialogForm.value;
    let isMyCumulusAdministrator = false;

    let token = getToken();
    if(token === null || token === undefined) {
      token = getAdminToken();
      isMyCumulusAdministrator = true;
    }
    const proToken = {
      'key': 'key',
      'value': token.key
    }

    if (this.deleteProjectAfterCopy) {
      const secondDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: {
          title: this.translate.instant("DO YOU REALLY WANT TO DELETE THE PROJECT") + " " + temp.name + "?",
          message: this.translate.instant("PLEASE THINK TWICE, THIS ACTION CANNOT BE UNDONE!") + " \n" + this.translate.instant("ARE YOU REALLY SURE YOU WANT TO DELETE THE PROJECT, ALL FORMS AND THE DATA?")
        }
      });

      secondDialogRef.afterClosed().subscribe(res  => {
        if (res) {
          this.project = {
            ...this.dataService.project,
            name: temp.name + " Project Template",
            description: temp.description,
            project_class: "template"
          };
          this.store.showLoading();
          this.dataService.updateProject(this.project, proToken, isMyCumulusAdministrator).subscribe(
            res => {
              this.dataService.deleteProjectLocally(res);
              this.toastService.successToast("Project moved to 'Project Templates");
              this.dataService.templates.next([]);
              this.dialogRef.close();
              this.store.hideLoading();
            },
            errmess => {
              this.toastService.errorToast(errmess);
              this.store.hideLoading();
          });
        } else {
          return;
        }
      });
    } else {
      this.store.showLoading();
      const currentProject = this.dataService.project;
      const project = {
        project_id: currentProject.id,
        name: temp.name,
        description: temp.description,
      }

      this.dataService.duplicateProject(project, proToken, isMyCumulusAdministrator).subscribe(
        res => {
          const p = {
            ...res,
            name: res.name + " Project Template",
            project_class: 'template'
          };
          this.dataService.updateProject(p, proToken, isMyCumulusAdministrator).subscribe(updateRes => {
            this.toastService.successToast("A copy of the project is moved to 'Project templates'");
            this.store.hideLoading();
            setTimeout(() => {
              this.dialogRef.close();
            }, 2000);

          }, err => {
            this.store.hideLoading();
            this.toastService.errorToast(err);
          });
        },
        errmes => {
          this.store.hideLoading();
          this.toastService.errorToast(errmes);
        }
      );
    }
  }

  deleteProjectAfter(event) {
    this.deleteProjectAfterCopy = event.checked;
  }
}
