import { TranslateModule } from '@ngx-translate/core';
import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialogContent, MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [TranslateModule,
    ReactiveFormsModule,
    MessageBoxComponent,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    MatDialogActions,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
  ],
  selector: 'app-dialog-actions',
  templateUrl: './dialog-actions.html',
  styleUrls: ['./dialog-actions.component.scss', '../dialog-btns.component.scss']
})
export class DialogActionsComponent {
  @Input() formCheck: FormGroup;
  @Input() mode = {
    text: '',
    function: ''
  };


}
