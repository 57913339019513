import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/share/user';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SysAdminUserServiceService } from 'src/app/services/sys-admin-user-service.service';
import { persistAdminAuthToken, persistAdminInfo } from 'src/app/share/utils';
import { ConfigServerUrlComponent } from '../config-server-url/config-server-url.component';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {ToastService} from '../../../services/toast.service';

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatCardModule,
    MatToolbarModule,
    MessageBoxComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
  ],
  selector: 'app-mcadmin-login-dialog',
  templateUrl: './mcadmin-login-dialog.component.html',
  styleUrls: ['./mcadmin-login-dialog.component.scss', '../dialog-btns.component.scss']
})
export class McadminLoginDialogComponent implements OnInit {

  user: User;
  mcAdminLoginForm: FormGroup;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private router: Router,
    private mcAdminService: SysAdminUserServiceService,
    private dialog: MatDialog,
    private route: Router,
    public toastService: ToastService,
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.mcAdminLoginForm = this.fb.group({
      name: ['', Validators.required],
      password: ['', Validators.required],
      remember: false
    });
  }

  closeDialog(event) {
    event.preventDefault();
    // this.dialogRef.close();
    event.stopPropagation();
  }

  onSubmit() {
    const data = this.mcAdminLoginForm.value;
    // TODO: Login admin user and when logged with success open MyCumulus Administration
    // page
    this.store.showLoading();
    this.mcAdminService.loginUser(data).subscribe(
      res => {
        persistAdminAuthToken(res);
        this.store.saveToken(res);
        this.store.isMCAdmin = true;
        this.getMyCumulusAdminUser(res);
        // this.route.navigate(['/mcadmins']);
        // this.dialogRef.close();
      },
      err => {
        this.toastService.errorToast(err);
        // this.dialogRef.close();
        this.store.hideLoading();
      }
    );
  }

  getMyCumulusAdminUser(token) {
    this.mcAdminService.getMyCumulusUser(token).subscribe(
      res => {
        persistAdminInfo(res);
        this.route.navigate(['/mcadmins']);
        this.store.persistUser(res);
        this.store.hideLoading();
        // this.dialogRef.close();
      },
      err => {
        this.toastService.errorToast('Error logging MyCumulus Admin');
        this.store.hideLoading();
        // this.dialogRef.close();
      }
    );
  }

  openPrivateServer(event) {
    event.preventDefault();
    const dialogRef = this.dialog.open(ConfigServerUrlComponent, {
      width: '400px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
      }
    });
    event.stopPropagation();
  }

  openMCAdminPasswordResetingPage(event) {
    event.preventDefault();
    this.router.navigate(['/reset-password']);
    event.stopPropagation();
  }
}
