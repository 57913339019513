import { TranslateModule } from '@ngx-translate/core';
import {Component, OnInit, ViewChild, Input, Output, ElementRef, EventEmitter, HostListener} from '@angular/core';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Template } from 'src/app/share/template';
import { DataService } from 'src/app/services/data.service';
import { StoreService } from 'src/app/services/store.service';
import { getToken } from 'src/app/share/utils';
import { Project } from 'src/app/share/projects';
import { User } from 'src/app/share/user';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import {HeaderService} from '../../../services/header.service';
import {MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {ImportFileDialogComponent} from '../import-file-dialog/import-file-dialog.component';
import {ReportsService} from '../../../services/reports.service';

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule, MatDialogClose,
  ],
  selector: 'app-import-imkl-file',
  templateUrl: './import-imkl-file.component.html',
  styleUrls: ['./import-imkl-file.component.scss', '../dialog-btns.component.scss']
})
export class ImportImklFileComponent implements OnInit {

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  @Output('readProjectTemplate')
  readProjectTemplateEmitter = new EventEmitter<any>();

  importDialogTitle: string;
  project: Project;
  template: Template;

  @Input() user: User;
  @Input() mode = {
    text: 'Create',
    function: 'Create'
  };
  @Input() typeOfFile: string;

  loading = false;
  loadingText = '';

  model: string;
  encoding = 'UTF8';
  type: string;
  fileInBase64: string;
  fileName: string;
  fileToUpload: File;
  importIMKL = false;
  isValidFile = true;
  importFileForm: FormGroup;
  importDataError: any;
  fields = [];
  showFieldContainer = false;
  errMsg: string;
  templates: any[];
  noFileIsSelected = false;


  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = 'Test of message';
  showMessage = false;

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fform') importFileFormDirective;

  constructor(
    private fb: FormBuilder,
    public store: StoreService,
    private dataService: DataService,
    public toastService: ToastService,
    private reportService: ReportsService,
    private dialogRef: MatDialogRef<ImportFileDialogComponent>,
    private headerService: HeaderService,
    ) {
      this.isValidFile = false;
      if(this.store.typeOfIMKLFile.value === 'Klip') {
        this.importDialogTitle = 'Import KLIC/KLIP';
        this.model = 'imkl_vl';
      } else {
        this.importDialogTitle = 'Import KLIC/KLIP';
        this.model = 'imkl_nl';
      }
      this.store.typeOfIMKLFile.subscribe(val => {
        if(val === 'Klip') {
          this.importDialogTitle = 'Import KLIC/KLIP';
          this.model = 'imkl_vl';
        } else {
          this.importDialogTitle = 'Import KLIC/KLIP';
          this.model = 'imkl_nl';
        }
      });
  }


  ngOnInit() {
    if (this.store.importIKMLFile) {
      this.importIMKL = this.store.importIKMLFile;
    }

    this.project = this.dataService.project;
    this.createForm();
  }

  createForm() {
    this.model = this.store.typeOfIMKLFile?.value === 'Klip' ? 'imkl_vl' : 'imkl_nl'

    if (this.mode.function === 'Create') {
      this.importFileForm = this.fb.group({
        imklFile: ['', Validators.required]
      });
    }
  }

  closePanel() {
    this.showMessage = false;
  }


  onSubmit() {
    if (!this.fileToUpload && !this.fileInBase64) {
      this.noFileIsSelected = true;
      this.toastService.errorToast('No file is selected');
      return;
    }
    this.showMessage = false;
    const importData = this.importFileForm.value;
    const fileNamesParts = importData.imklFile.split('.');
    let fileType = '';
    if (fileNamesParts && fileNamesParts.length > 0) {
      fileType = fileNamesParts[fileNamesParts.length - 1];
    }
    const p = this.dataService.project;

    const value = {
      project_id: this.project.id,
      base64_encoded_content: this.fileInBase64,
      model : this.model,
      encoding: 'UTF8',
      type: this.type
    };
    this.store.showLoading();
    this.loadingText = 'Sending importation data';
    const token = getToken();
    this.dataService.importIKMLFile(value, token, p.id).subscribe(
      res => {
        this.toastService.successToast(`Successfully imported form.`);

        this.fileToUpload = null;
        this.showFieldContainer = false;
        this.fileInBase64 = null;
        this.dataService.hideTemplate();
        this.headerService.getTemplates(p);
        this.store.hideLoading();
        this.dialogRef.close();
      },
      error => {
        this.loading = false;
        this.store.hideLoading();

        this.importDataError = error;

        let msg = `Importation failed, please retry later`;
        if(error && error['message']) {
          msg += ' : ' + error['message'];
        }
        this.importDataError = [msg];
        this.toastService.errorToast(msg);
      }
    );
  }

  setFileData(event): void {
    event.preventDefault();

    this.showFieldContainer = false;

    if (event.target.files && event.target.files.length > 0) {
      const tempFile = event.target.files[0];

      this.isValidFile = true;
      const filename = tempFile.name;
      this.fileName = tempFile.name;
      const filenameParts = filename ? filename.split('.') : [];
      if (filenameParts && filenameParts.length > 1) {
        this.type = filenameParts[filenameParts.length -1];
      }

      //
      this.fileToUpload = event.target.files[0];
      this.reportService.convertFileToBase64(this.fileToUpload).then(base64String => {
        this.fileInBase64 = base64String;
      }).catch(err => {
        this.toastService.errorToast(err);
      })
      this.showFieldContainer = true;
    } else {
      this.isValidFile = false;
    }
    event.stopPropagation();
  }

}
