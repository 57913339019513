import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableModule} from '@angular/material/table';
import { FeatureService } from 'src/app/services/feature.service';
import {
  getToken,
  url,
  getCurrentDomain,
  persistSearchValue,
  removeSearchValue,
  getSearchValue,
  getCurrentFeaturesUri,
  removeCurrentFeaturesUri,
  persistCurrentFeaturesUri,
  getAdminToken,
  saveTemplateFieldsNameAndTypes, getUser
} from 'src/app/share/utils';
import { ExportDataCriteriaDialogComponent } from '../dialogs/export-data-criteria-dialog/export-data-criteria-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Attribute } from 'src/app/share/feature/attributes';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AttributesetarrayDialogComponent } from '../dialogs/attributesetarray-dialog/attributesetarray-dialog.component';
import { GeomTypeCoordinatesDialogComponent } from '../dialogs/geom-type-coordinates-dialog/geom-type-coordinates-dialog.component';
import { MediaShowingDialogComponent } from '../dialogs/media-showing-dialog/media-showing-dialog.component';
import { EditFeatureValueComponent } from '../dialogs/edit-feature-value/edit-feature-value.component';
import { ReportsService } from 'src/app/services/reports.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { StoreService } from 'src/app/services/store.service';
import $ from 'jquery';
import {CommonModule, TitleCasePipe} from '@angular/common';
import { ViewDataCriteriaDialogComponent } from '../dialogs/view-data-criteria-dialog/view-data-criteria-dialog.component';
import { EditGeomOrCoordValuesComponent } from '../dialogs/edit-geom-or-coord-values/edit-geom-or-coord-values.component';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faEdit, faEye} from '@fortawesome/free-solid-svg-icons';
import {ImportCSVDialogComponent} from '../dialogs/import-data-dialog/import-CSV-dialog.component';
import {ToastService} from '../../services/toast.service';
import {DataService} from '../../services/data.service';
import {MatOption, MatSelect} from '@angular/material/select';
import {Subject, Subscription, takeUntil} from 'rxjs';


const COORDINATES = 'coordinates';
const ATTRIBUTESETARRAY = 'arrayattributeset';

const _SELECT = '_select';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    MatTableModule,
    MessageBoxComponent,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    FormsModule,
    MatCheckboxModule,
    FontAwesomeModule,
    CommonModule,
    MatSelect,
    MatOption,
  ],
  selector: 'app-feature-data',
  templateUrl: './feature-data.component.html',
  styleUrls: ['./feature-data.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class FeatureDataComponent implements OnInit, OnDestroy {
  GEOMETRY_TYPE = 'Geometry_Type';
  searchValue: string;
  templateName: string;
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatTable, {static: false}) featuretable: MatTable<any>;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }

  initHeaders: any[];
  arrayHeadersAndSize: any[];
  allCoordinates: any[];
  featureAttrib: any[] = [];
  allArrayAttributeSet: any[] = [];
  allArrayAttributeSetElemment: any[] = [];
  allCoordinatesFields: any[] = [];
  allCoordinatesFieldsElemment: any[] = [];
  fetchMore: boolean;
  searchCriteria: any;
  numindex: number;
  templateChanged: string;

  constructor(
    private featureService: FeatureService,
    private dialog: MatDialog,
    private titlecasePipe: TitleCasePipe,
    public store: StoreService,
    public toastService: ToastService,
    public dataService: DataService,
    public reportService: ReportsService,
    public translate: TranslateService,
  ) {

  }

  private templateSubscription: Subscription;
  private textSubscription: Subscription;
  private destroy$ = new Subject<void>();


  ngOnInit() {
    const template = this.dataService.template.getValue();
    this.reportService.columnsValues = [];
    this.searchValue = '';
    const searchVal = getSearchValue();
    if (searchVal) {
      this.searchValue = searchVal;
      this.initElements(this.reportService.template);
      this.searchText(searchVal);
      return;
    }
    const searchObject = this.reportService.lastExportedTemplate;
    if (searchObject && searchObject['template'] === template["id"]) {
      this.initElements(this.reportService.template);
      this.reportService.submitSelectedItems(searchObject);
      return;
    } else {
      this.reportService.columnsValues = [];
    }

    const getSavedUri = getCurrentFeaturesUri();
    if (getSavedUri) {
      this.initElements(this.reportService.template);
      this.loadFeatures(getSavedUri);
      return;
    }

    //CAUSE
    this.templateSubscription = this.dataService.template
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.templateChanged += this.templateChanged ? ' again' : '';
        this.reportService.template = res;
        this.initElements(res);
        this.selection.clear();
        this.selection = new SelectionModel<Element>(true, []);
        this.updateTemplateFeature(res);
      });
  }

  ngOnDestroy() {
    if (this.templateSubscription) {
      this.templateSubscription.unsubscribe();
    }
    if(this.textSubscription) {
      this.textSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackByFn(index: number, item: any): any {
    return item.key || index; // or a unique identifier for your item
  }


  initElements(template) {
    if (template) {
      this.templateName = template.name;
    }
    this.reportService.nbrPage = 40;
    this.reportService.initValue = 0;
    this.fetchMore = false;
    this.reportService.attHeaders = [];
    this.reportService.tableHeaders = [];
    this.reportService.featureData = [];
    this.reportService.instances = [];
    this.allCoordinates = [];
    this.allCoordinatesFields = [];
    this.allCoordinatesFieldsElemment = [];
    this.allArrayAttributeSet = [];
    this.allArrayAttributeSetElemment = [];
    this.searchCriteria = {};
    this.numindex = 0;
    this.reportService.resultsLength = 100;
    this.reportService.dataSource.paginator = this.paginator;
    this.reportService.dataSource.sort = this.sort;
    this.reportService.doingReport = false;
    this.reportService.allPages = [];
    this.reportService.pages = 0;
    this.reportService.activePage = 0;
    this.reportService.selectedPage = '';
  }

  updateTemplateFeature(template) {
    if (!template) {
      return;
    }
    this.initElements(template);
    this.reportService.uri = 'templates/' + template.id + '/' + 'features?perPage=' + this.reportService.nbrPage;
    this.reportService.lastExportedTemplate = null;
    this.loadFeatures(this.reportService.uri);
  }

  loadFeatures(uri) {
    const project = this.dataService.project;
    let token = getToken();
    if(token === null || token === undefined) {
      token = getAdminToken();
    }
    this.store.showLoading();
    this.templateSubscription = this.featureService.getFeatures(token, uri, project).subscribe(
      res => {
        persistCurrentFeaturesUri(uri);
        this.updateDataFormServer(res);
        this.store.hideLoading();

      },
      error => {
        const msg = error.message ? error.message : error;
        this.toastService.errorToast(msg);
        this.store.hideLoading();
      });
  }

  searchText(value: string) {
    const project = this.dataService.project;
    const token = getToken();
    this.store.showLoading();
    this.textSubscription = this.featureService.textSearch(token, this.reportService.template, project, value).pipe(takeUntil(this.destroy$))
      .subscribe(
      res => {
        this.store.features = res['instances'];
        removeSearchValue();
        this.reportService.lastExportedTemplate = null;
        removeCurrentFeaturesUri();
        persistSearchValue(value);
        this.updateDataFormServer(res);
        this.store.hideLoading();
      },
      error => {
        const msg = error.message ? error.message : error;
        this.toastService.errorToast(msg);
        this.store.hideLoading();
      });
  }

  isMorePages(): boolean {
    return this.reportService.pages > 1;
  }

  openPage(item: string) {
    let linkValue = '';
    if (this.reportService.nextLink !== null && this.reportService.nextLink !== undefined) {
      linkValue = this.reportService.nextLink;
    } else if (this.reportService.prevLink !== null && this.reportService.prevLink !== undefined) {
      linkValue = this.reportService.prevLink;
    }

    if (linkValue !== '') {
      for (let i = 0; i < 4; i++) {
        linkValue = linkValue.substring(1);
      }

      const links = linkValue.split('?');
      const values = links[1].split('&');
      const vals = values[0].split('=');
      const uri = links[0] + '?' + vals[0] + '=' + item.trim() + '&' + values[1];

      this.loadFeatures(uri);
    }

  }

  /**
   * updating the data in the table after any request for fetching or searching data.
   */
  updateDataFormServer(features) {
    this.reportService.featuresSelected = [];
    this.reportService.allPages = [];
    const template = this.dataService.template.getValue();
    if (template !== null && template !== undefined) {
      this.reportService.template = template;
      this.reportService.featureSchema = template.feature;
      this.saveTemplateFieldsNameAndType(template);

    }
    const searchObject = this.reportService.lastExportedTemplate;
    if (searchObject === null
      || searchObject === undefined
      || searchObject['template'] !== template["id"]) {
      this.reportService.columnsValues = [];
    }

    this.reportService.metadata = features.metadata;
    this.reportService.featureData = [];
    this.reportService.resultsLength = features.length;

    this.reportService.instances = features.instances;

    const headers = this.reportService.createHeaderArrayWithinTemplate(template);
    this.reportService.tableHeaders = this.reportService.createTableHeaderArrayWithinTemplate(template);
    const duplicateHeaders = this.findDuplicates(this.reportService.tableHeaders);
    if(duplicateHeaders && duplicateHeaders.length > 0) {

      const headersAsString = duplicateHeaders.join(', ');
      this.toastService.errorToast(`The form contains duplicate Label [${headersAsString}]`);
      setTimeout(() => {
      }, 1000)

      return;
    }

    this.reportService.attHeaders = headers;
    if (this.reportService.instances.length > 0) {
      const nextUri = features.metadata.uris.next;
      const prevUri = features.metadata.uris.prev;

      this.reportService.pages = features.metadata.pages;
      this.reportService.activePage = features.metadata.page;

      if (this.reportService.pages > 0) {
        for (let v = 1; v <= this.reportService.pages; v++) {
          this.reportService.allPages.push(v + ' ');
        }
      }

      const data = this.reportService.createFeatureData();

      let listGeometryHeader = null;
      if (this.reportService.instances[0]['geometry'] !== null && this.reportService.instances[0]['geometry'] !== undefined) {
        listGeometryHeader = Object.keys(this.reportService.instances[0]['geometry']);
      }
      if (this.reportService.columnsValues.length > 0) {
        this.reportService.displayedColumns = [];
        this.reportService.displayedColumns.push(_SELECT);
        const index = this.reportService.columnsValues.indexOf('Geometry Type');
        if (listGeometryHeader !== null && listGeometryHeader !== undefined && index !== -1) {
          this.reportService.displayedColumns.push(this.GEOMETRY_TYPE);
        }
        for (const b of this.reportService.columnsValues) {
          const val = this.reportService.tableHeaders[this.reportService.attHeaders.indexOf(b)];
          if (val !== 'Geometry Type') {
            this.reportService.displayedColumns.push(val);
          }
        }
      } else if (this.reportService.tableHeaders !== null && this.reportService.tableHeaders !== undefined && this.reportService.tableHeaders.length > 0) {
        this.reportService.displayedColumns = [];
        this.reportService.displayedColumns.push(_SELECT);
        if (listGeometryHeader !== null && listGeometryHeader !== undefined) {
          this.reportService.displayedColumns.push(this.GEOMETRY_TYPE);
        }
        for (const h of this.reportService.tableHeaders) {
          this.reportService.displayedColumns.push(h);
        }
      }

      const value = (features.metadata.page - 1) * this.reportService.nbrPage;
      if (features.metadata.page === 1 && features.instances.length > 0) {
        this.reportService.initValue = 1;
      } else if (features.instances.length === 0) {
        this.reportService.initValue = 0;
      } else {
        this.reportService.initValue = value + 1;
      }

      if ((nextUri === null || nextUri === undefined || nextUri === '')) {
        this.reportService.nextDisabled = true;
      } else {
        this.reportService.nextDisabled = false;
        this.reportService.nextLink = nextUri;
      }

      if (prevUri === null || prevUri === undefined || prevUri === '') {
        this.reportService.prevDisabled = true;
      } else {
        this.reportService.prevDisabled = false;
        this.reportService.prevLink = prevUri;
      }
      this.reportService.pageFeatures = features.metadata.page_count + value;
      this.reportService.totalFeatures = features.metadata.total_count;

      const $items = $('.bleuPage');
      const classHighlight = 'selectedPage';
      $items.removeClass(classHighlight);
      this.reportService.selectedPage = this.reportService.activePage + ' ';
      this.reportService.dataSource.data = data;
      this.featuretable?.renderRows();
    } else {
      this.reportService.initValue = 0;
      this.reportService.pageFeatures = 0;
      this.reportService.totalFeatures = 0;

      this.reportService.displayedColumns = [];
      this.reportService.dataSource.data = [];
      this.featuretable?.renderRows();
    }
    this.dataService.selectingTemplate = false;
  }


  findDuplicates(headers: any[]): any[] {
    const duplicates: any[] = [];
    headers.map((value, index) => {
      if (headers.indexOf(value, index + 1) > -1 && duplicates.indexOf(value) === -1) {
        duplicates.push(value);
      }
    });
    return duplicates;
  }

  loadPrevFeatures() {
    if (!this.reportService.prevLink || this.reportService.prevLink === '') {
      return;
    }

    for (let i = 0; i < 4; i++) {
      this.reportService.prevLink = this.reportService.prevLink.substring(1);
    }

    this.loadFeatures(this.reportService.prevLink);
  }

  loadNextFeatures() {
    if (this.reportService.nextLink === undefined || this.reportService.nextLink === null || this.reportService.nextLink === '') {
      return;
    }

    for (let i = 0; i < 4; i++) {
      this.reportService.nextLink = this.reportService.nextLink.substring(1);
    }

    this.loadFeatures(this.reportService.nextLink);
  }

  removeSelectedFeature() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`);
      return;
    }
    const size = this.selection.selected.length;
    if (size <= 0) {
      this.toastService.errorToast('No records selected. Please choose records to delete');
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        message: this.translate.instant("THESE RECORDS WILL BE DELETED ON THE SERVER"),
        title: this.translate.instant("DELETE") + " " + size + " " + this.translate.instant("RECORD(S)?")
      }
    });
    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const token = getToken();
        this.store.showLoading();
        const ids = [];
        for (const feature of this.selection.selected) {
          ids.push(feature.id);
        }
        const project = this.dataService.project;
        this.featureService.deleteFeatures(ids, this.reportService.template, project, token).subscribe(
          res => {
            this.store.features = null;
            this.loadFeatures(this.reportService.uri);
            this.toastService.successToast(size + ' record(s) deleted')
            this.selection.clear();
            this.store.hideLoading();
          },
          errmes => {
            this.store.hideLoading();
            this.toastService.errorToast('Error while deleting the records' + errmes);
          }
        );
      }
    });
  }

  getValue(value: string) {
    $('.has-clear input[type="text"]').on('input propertychange', function() {
      const $this = $(this);
      const visible = Boolean($this.val());
      $this.siblings('.form-control-clear').toggleClass('hidden', !visible);
    }).trigger('propertychange');

    $('.form-control-clear').click(function() {
      $(this).siblings('input[type="text"]').val('')
        .trigger('propertychange').focus();
    });

    this.applyFilter();
  }

  applyFilter() {
    const value = this.searchValue.trim().toLowerCase();

    if (value && value !== '') {
      this.searchText(value);
    } else {
      this.updateTemplateFeature(this.dataService.template.getValue());
    }
  }

  displayItemValue(column, value) {
    column = this.reportService.attHeaders[this.reportService.tableHeaders.indexOf(column)];
    const fieldsAndType = this.reportService.fields_and_name_type;
    const type = fieldsAndType[column];
    if (type === COORDINATES) {
      return COORDINATES;
    } else if (type === ATTRIBUTESETARRAY) {
      return this.displayAttSetArrayValue(column);
    } else {
      const data = this.checkFiles(value);
      if (this.checkIfIsUrl(data)) {
        return '';
      } else if (type === 'date' && this.dataService.checkIfDate(data)) {
        return data.split('T')[0];
      }
      return data;
    }
  }

  displayAttSetArrayValue(column) {
    const index = column.indexOf('.');
    if (index > 0) {
      const values = column.split('.');
      return values[values.length - 1];
    }
    return column;
  }

  checkIfColumnIsGeometry(column) {
    return column === this.GEOMETRY_TYPE;
  }

  checkIfCoordinatesField(column) {
    const fieldsAndType = this.reportService.fields_and_name_type;
    const i = this.reportService.tableHeaders.indexOf(column);
    const type = fieldsAndType[this.reportService.attHeaders[i]];
    return type === COORDINATES;
  }

  checkIfAttributesetArray(column) {
    const fieldsAndType = this.reportService.fields_and_name_type;
    const i = this.reportService.tableHeaders.indexOf(column);
    const type = fieldsAndType[this.reportService.attHeaders[i]];
    return type === ATTRIBUTESETARRAY;
  }

  selectFeature(event, element): void {
    this.selection.toggle(element);
    const feature = this.reportService.instances.find(f => f.id === element.id);
    if (event.checked) {
      if (this.store.features) {
        this.store.features.push(feature);
      } else {
        this.store.features = [feature];
      }
    } else {
      if (this.store.features) {
        this.store.features = this.store.features.filter(f => f.id !== element.id);
      }
    }
  }

  isSelectFeatureHeader(column) {
    return column === '_select';
  }

  isSelectFeatureData(column, element) {
    return column === '_select';
  }

  openAttributesetArrayDialog(column, element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '700px';
    dialogConfig.height = 'auto';

    const id = element['id']
    let feature = null;
    let featureIndex = -1;
    if (id !== null && id !== undefined) {
      feature = this.reportService.instances.filter(f => f.id === id)[0];
      featureIndex = this.reportService.instances.indexOf(feature);
    }
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      attributesetarray: element[column],
      path: column,
      attname: this.reportService.attHeaders[this.reportService.tableHeaders.indexOf(column)],
      feature,
      column
    };
    const attsetarrayRef = this.dialog.open(AttributesetarrayDialogComponent, dialogConfig);
    attsetarrayRef.afterClosed().subscribe(result => {
      if (result !== null && result !== undefined) {
        if  (featureIndex >= 0) {
          this.reportService.instances[featureIndex] = result;
          this.reportService.dataSource.data = this.reportService.createFeatureData();
          this.featuretable?.renderRows();
        }
      }
    });
  }

  displayCoordinates(column, element) {
    const geomType = element[column];
    const coordinates = element[COORDINATES];
    let gpscoord = false;
    if (coordinates) {
      gpscoord = !(coordinates[0] instanceof Object);
    }
    this.openCoordinateDialog(geomType, coordinates, gpscoord, element);
  }

  displayGPSCoordinates(column, element) {
    const geomType = element[column];
    const coordinates = element[COORDINATES];
    let gpscoord = false;
    if (coordinates) {
      gpscoord = !(coordinates[0] instanceof Object);
    }
    this.openCoordinateDialog(null, geomType, gpscoord);
  }

  openCoordinateDialog(geometryType, coordinates, gpscoord = false, element = null) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    let feature = null;

    if(element) {
      feature = this.reportService.instances.find(f => f.id === element.id);
    }
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      feature: feature,
      element: element,
      geometry_type: geometryType,
      gpscoord,
      coordinates,
      hasWritePermissions: (geometryType === 'Point' || !geometryType) && (getUser().id === this.dataService.template.getValue().created_by || getUser().permissions?.projects.includes('create'))
    };

    const dialogRef = this.dialog.open(EditGeomOrCoordValuesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getAttributeType(attributes, column): string {
    let type = '';

    if (attributes.length > 0) {
      for (let i = 0; i < attributes.length; i++) {
        const attribute = attributes[i];

        if(attribute._class === 'attributeset'){
          type = this.getAttributeType(attribute.attributes, column);
          if(type !== '')
            return type;
        }

        if(attribute.name === column){
          type = attribute.type;
          return type;
        }
      }
    }
    return type;
  }

  valueExistInArray(value, array): boolean {
    if(array.length <= 0){
      return false;
    }

    for(let i = 0; i < array.length; i++){
      if(array[i] === value)
        return true;
    }

    return false;
  }

  openEditFeatureValue(column, element) {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`);
      return;
    }
    const label = column;
    column = this.reportService.attHeaders[this.reportService.tableHeaders.indexOf(column)];
    const dialogConfig = new MatDialogConfig();
    const columnValues = column.split('.');
    let newColumn = '';
    if(columnValues.length > 0)
      newColumn = columnValues[columnValues.length - 1];
    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    const id = element['id']
    let feature = null;
    let featureIndex = -1;
    if (id !== null && id !== undefined) {
      feature = this.reportService.instances.filter(f => f.id === id)[0];
      featureIndex = this.reportService.instances.indexOf(feature);
    }

    const attributes = this.reportService.template.feature.attributes;

    const type = this.getAttributeType(attributes, newColumn);

    const value = element[label];
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      column,
      feature,
      element,
      value,
      type
    };

    if(type === 'file' || type === undefined || type === '')
      return;

    const dialogRef = this.dialog.open(EditFeatureValueComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== undefined) {
        if (result.event === 'updated') {
          const updatedFeature = result.feature;
          const project = this.dataService.project;
          const token = getToken();
          this.store.showLoading();

          this.reportService.template.feature.geometry_type = updatedFeature.geometry ?
            updatedFeature.geometry.type : null;
          const attributes = this.reportService.template.feature.attributes as Attribute[];
          const attrKeys = Object.keys(updatedFeature.attributes);
          const attrNames = [];

          attributes.map(attribute => {
            attrNames.push(attribute.name);
            if (!this.valueExistInArray(attribute.name, attrKeys)) {
              const tpe = attribute.type;
              let val = null;
              if (tpe === 'file') { val = []; }
              updatedFeature.attributes[attribute.name] = val;
            }
          });

          attrKeys.map( val => {
            if (!this.valueExistInArray(val, attrNames)) {
              delete updatedFeature.attributes[val];
            }
          });

          this.featureService.updateFeature(updatedFeature, project, token)
            .subscribe(
              res => {
                if  (featureIndex >= 0) {
                  this.reportService.instances[featureIndex] = res;
                  this.reportService.dataSource.data = this.reportService.createFeatureData();
                  this.featuretable?.renderRows();
                }
                this.store.hideLoading();
              },
              err => {
                this.toastService.errorToast(err);
                this.store.hideLoading();
              });
        }
      }
    });
  }

  isDataIsFile(column, data): boolean {
    const fieldsAndType = this.reportService.fields_and_name_type;
    if (fieldsAndType[column] === ATTRIBUTESETARRAY) {
      return false;
    }
    if (fieldsAndType[column] === COORDINATES) {
      return false;
    }
    const temp = this.checkFiles(data);
    return this.checkIfIsUrl(temp);
  }

  checkIfIsUrl(data) {
    const pat = /^https?:\/\//i;
    let values = [];

    if (data === undefined || data === null) {
      return false;
    }
    let str;
    if ((data instanceof Object) && (data instanceof Array) && data.length > 0) {
      str = data[0];
    } else {
      str = data;
    }
    if (isNaN(str)) {
      if (typeof(str) === 'string') {
        values = str.split('/');
      }
    }
    const currentDomain = getCurrentDomain();
    return pat.test(str) && values.includes(currentDomain) && values.includes('v1') && values.includes('files');
  }

  getHeaderInSplitMode(h): string {
    const parts = h.split('.');
    if (parts && parts.length > 0) {
      let tempHeader = '';
      for(const part of parts) {
        tempHeader = tempHeader === '' ? part : `${tempHeader} <br /> ${part}`;
      }

      //DANGER DANGER
      if(tempHeader === "Geometry_Type") {
        tempHeader = "Geometry";
      }
      return tempHeader;
    }
    return h;
  }

  saveTemplateFieldsNameAndType(template) {
    const feature = template.feature;
    const attributes = feature.attributes;
    const parent = '';
    let fieldsAndType = {};

    for (const attribute of attributes) {
      if (attribute._class === 'attributeset' ) {
        const temparent = (parent)
          ? `${parent}.${attribute.name}`
          : attribute.name;
        fieldsAndType = Object.assign(fieldsAndType, this.reportService.saveAttributesetNamesAndType(attribute, temparent));
      } else if (attribute._class === 'arrayattributeset') {
        const name = (parent)
          ? `${parent}.${attribute.name}`
          : attribute.name;
        fieldsAndType[name] = 'arrayattributeset';
      } else {
        const attname = (parent)
          ? `${parent}.${attribute.name}`
          : attribute.name;
        fieldsAndType[attname] = attribute['type'];
      }
    }
    this.reportService.schemaHeader = Object.keys(fieldsAndType);
    this.reportService.fields_and_name_type = fieldsAndType;
    saveTemplateFieldsNameAndTypes(fieldsAndType);
  }

  /**
   * getting the values of nested attributes.
   */
  parseAttributesObject(attributes, path) {
    const propertyNames = Object.getOwnPropertyNames(attributes);
    const size = propertyNames.length;

    for (let i = 0; i < size; i++) {
      const name = propertyNames[i];
      const attribute = attributes[name];
      let currentPath = path === '' ? '' : path + '.';
      currentPath = currentPath + name;
      if (!this.checkArrayAttributeSet(currentPath)) {
        if (attribute instanceof Object) {
          if (attribute instanceof Array) {
            if (!this.checkCoordinateField(currentPath)) {
              this.parseArrayObject(attribute, currentPath);
            }
          } else {
            this.parseAttributesObject(attribute, currentPath);
          }
        }
      }
    }
  }

  parseArrayObject(attribute, path) {
    const size = attribute.length;
    const tempSize = this.findArrayMaxSize(path, size);
    if (this.findObjectInStoredArraySizes(path) <= 0) {
      const element =  {
        name: path,
        size: tempSize
      };
      this.arrayHeadersAndSize.push(element);
    }

    this.replaceHeaderInHeaders(path, tempSize);
    for (let i = 0; i < size; i++) {
      const data = attribute[i];
      const arrPath = path + '[' + i + ']';
      if (data instanceof Object) {
        if (data instanceof Array) {
          this.parseArrayObject(data, arrPath);
        } else {
          this.parseAttributesObject(data, arrPath);
        }
      } else {

      }
    }
  }

  // If the template contains file types find the longest array in order to fill others with empty space.
  findArrayMaxSize(path, size) {
    let tempSize = size;
    for (const instance of this.reportService.instances) {
      const attributes = instance.attributes;
      const findSize = this.findArrayMaxSizeInObject(attributes, path, '', tempSize);
      if (findSize > 0) {
        tempSize = findSize;
      }
    }
    return tempSize;
  }

  checkCoordinateField(path) {
    const fieldAndTypes = this.reportService.fields_and_name_type;
    const type = fieldAndTypes[path];
    return type === 'coordinates';


  }

  checkArrayAttributeSet(path) {
    const fieldAndTypes = this.reportService.fields_and_name_type;
    const type = fieldAndTypes[path];
    return type === 'arrayattributeset';


  }

  findArrayMaxSizeInObject(attribute, searchPath, path, size) {
    const propertyNames = Object.getOwnPropertyNames(attribute);

    for (const name of propertyNames) {
      const tempAttribute = attribute[name];
      let currentPath = path === '' ? '' : path + '.';
      currentPath = currentPath + name;

      if (tempAttribute instanceof Object) {
        if (tempAttribute instanceof Array) {
          if (searchPath === currentPath) {
            if (tempAttribute.length > size) {
              return tempAttribute.length;
            }
          }
        } else {
          this.findArrayMaxSizeInObject(tempAttribute, searchPath, currentPath, size);
        }
      }
    }
    return 0;
  }

  // adding [] to array's type in the header of the table.
  replaceHeaderInHeaders(element, size) {
    if (size === 0) {
      return;
    }

    for (let i = 0; i < this.reportService.attHeaders.length; i++) {
      if (this.reportService.attHeaders[i] === element) {
        let elements = '';
        for (let j = 0; j < size; j++) {
          elements = elements === '' ? '' : elements + ' ';
          elements = elements + element + '[' + j + ']';
        }
        const splitElements = elements.split('');
        for (let k = 0; k < splitElements.length; k++) {
          if (k === 0) {

            this.reportService.attHeaders.splice(i + k, 1, splitElements[k]);
          } else {
            this.reportService.attHeaders.splice(i + k, 0, splitElements[k]);
          }
        }
        return;
      }
    }
  }

  // buiding values of nested objects and arrays
  buildObjectData(attributes, dataArray, path, oldValues = null) {
    let propertyNames = [];
    this.initHeaders = this.splitHeaderValues(this.initHeaders);
    if (oldValues === null) {
      propertyNames = this.initHeaders;
    } else {
      propertyNames = Object.getOwnPropertyNames(attributes);
    }
    const size = propertyNames.length;

    for (let i = 0; i < size; i++) {
      let name = propertyNames[i];
      const value = name.split('.');
      if (value.length > 1) {
        name = value[0];
        value.shift();
      }
      const attribute = attributes[name];
      let currentPath = path === '' ? '' : path + '.';
      currentPath = currentPath + name;
      if (attribute === null || attribute === undefined) {
        if (this.findObjectInStoredArraySizes(currentPath) > 0) {
          this.buildArrayData(attribute, dataArray, currentPath);
        } else {
          this.featureAttrib.push(name);
          dataArray.push('');
        }
      } else {
        if (attribute instanceof Object) {
          if (attribute instanceof Array) {
            if (this.checkArrayAttributeSet(currentPath)) {
              this.allArrayAttributeSet.push(attribute);

              const elt = currentPath + '_' + this.allArrayAttributeSetElemment.length;
              this.allArrayAttributeSetElemment.push(elt);
              dataArray.push(elt);
            } else if (this.checkCoordinateField(currentPath)) {
              const coordinate = {
                x: attribute[0],
                y: attribute[1],
                z: attribute[2],
                Accuracy: attribute[3]
              };
              this.allCoordinatesFields.push(coordinate);
              const elt = currentPath + '_' + this.allCoordinatesFieldsElemment.length;
              this.allCoordinatesFieldsElemment.push(elt);
              dataArray.push(elt);
            } else {
              this.buildArrayData(attribute, dataArray, currentPath);
            }
          } else {
            this.buildObjectData(attribute, dataArray, currentPath, value);
          }
        } else if (attribute instanceof Array) {
          if (this.checkArrayAttributeSet(currentPath)) {
            this.allArrayAttributeSet.push(attribute);

            const elt = currentPath + '_' + this.allArrayAttributeSetElemment.length;
            this.allArrayAttributeSetElemment.push(elt);
            dataArray.push(elt);
          }
        } else {
          this.featureAttrib.push(name);
          if (this.checkIfValueInArray(name)) {
            let data = this.checkFiles(attributes[name]);
            if (this.checkIfIsUrl(data)) {
              this.reportService.linksArray.push(data);
            } else if (this.dataService.checkIfDate(data)) {
              data = data.split('T')[0];
            }
            dataArray.push(data);
          }
        }
      }
    }
  }

  /*
  * check if value is a media file and return the complete URL.
  * @param: the value to check
  * @return: the URL or the string
  */
  checkFiles(value) {
    if (value instanceof Array && value.length > 0) {
      const firstVal = value[0];
      return this.dataService.checkFile(firstVal);
    } else {
      return value;
    }
  }

  getFilesUrl(value) {
    const filesUrls = [];
    if (value instanceof Array && value.length > 0) {
      value.map(element => {
        filesUrls.push(this.checkFiles(element));
      });
    } else {
      return value;
    }
    return filesUrls;
  }

  // split array data build in string in to elements of an array.
  splitHeaderValues(headers) {
    const values = [];
    if (headers.length === 0) {
      return;
    }

    for (const header of headers) {
      const value = header.split('.');
      if (!values.includes(value[0])) {
        values.push(value[0]);
      }
    }
    return values;
  }

  checkIfValueInArray(value) {
    if (this.reportService.attHeaders.length > 0) {
      for (const attHeader of this.reportService.attHeaders) {
        if (this.getNameOfHeader(attHeader) === value) {
          return true;
        }
      }
      return false;
    }
  }

  isValidImage(item: string): boolean {
    const validExtensions = ['.jpeg', '.png', '.jpg', '.gif', '.pdf'];
    return validExtensions.some(ext => item.endsWith(ext));
  }



  getNameOfHeader(value) {
    const values = value.split('.');
    if (values.length > 0) {
      return values[values.length - 1];
    }
    return value;
  }

  // building the data of arrays(media files values).
  buildArrayData(attribute, dataArray, path = '') {
    const highestLength = this.findObjectInStoredArraySizes(path);
    let size = 0;
    if (attribute !== null && attribute !== undefined) {
      size = attribute.length;
    }

    if (size === 0 && highestLength === 0) {
      dataArray.push('');
    }

    for (let i = 0; i < size; i++) {
      let data = attribute[i];

      if (data instanceof Object) {
        if (data instanceof Array) {
          this.buildArrayData(data, dataArray);
        } else {
          this.buildObjectData(data, dataArray, '');
        }
      } else {
        data = this.checkFiles(data);
        if (this.checkIfIsUrl(data)) {
          this.reportService.linksArray.push(data);
          dataArray.push(data);
        } else {
          dataArray.push(data);
        }
      }
    }
    if (highestLength > size) {
      const remainSize = highestLength - size;
      for (let j = 0; j < remainSize; j++) {
        dataArray.push('');
      }
    }
  }

  findObjectInStoredArraySizes(path) {
    for (const header of this.arrayHeadersAndSize) {
      if (header.name === path) {
        return header.size;
      }
    }
    return 0;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.reportService.dataSource.data.map(row => this.selection.select(row));
  }

  selectAllFeatures(event) {
    this.masterToggle();
    this.store.features = null;
    if (event.checked) {
      this.store.features = this.reportService.instances;
    }
  }

  showViewDialog() {
    const dialogRef = this.dialog.open(ViewDataCriteriaDialogComponent, {
      maxWidth: '50vw',
      maxHeight: '70vh',
      width: '50vw',
      height: '70vh',
      disableClose: true,
      data: {
        template: this.dataService.template.getValue(),
        headers: this.reportService.tableHeaders
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null && result !== undefined && result.event === 'search') {
        this.reportService.submitSelectedItems(result.advanced_request);
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.reportService.dataSource.data.length;
    return numSelected === numRows;
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }

  protected readonly faEdit = faEdit;
  protected readonly faEye = faEye;
}
