<div fxFlexLayout="row"  class="dialog-content" style="margin-bottom: 0px !important;">
  <div fxFlex style="margin-bottom: 0px !important;">
    <mat-horizontal-stepper id="criteria_elements"
      style="margin-bottom: 0px !important; padding-bottom: 0px !important;">
      @if (showMessage) {
        <app-message-box #messageBox message="{{message}}"></app-message-box>
      }
      <mat-step [label]="translate.instant('FIELDS')" style="margin-bottom: 0px !important; padding-bottom: 0px !important;">
        <div fxLayout="column">
          <h4 class="text-left mb-3" fxFlex="10"><span>{{ "SELECT FIELDS" | translate }}</span></h4>
          <div fxFlex="10">
            <mat-checkbox
              color="primary"
              style="display: block;"
              [checked]="selectOrDeselectAll"
              (change)="handleSelectAll($event)"
            name="enabled">Select / Deselect All</mat-checkbox>

          </div>
          <div id="columnsContainer" class="pl-2" fxFlex="80">
            @for (header of columnItems; track header) {
              <mat-checkbox
                color="primary" style="display: block;" [checked]="header.check"
              (change)="handleSelect(header)" name="enabled">{{header.value}}</mat-checkbox>
            }
          </div>
        </div>
        <div class="button-group">
          <button mat-stroked-button type="submit" class="mr-2 mt-2" (click)="closeDialog()"><span>{{ "CANCEL" | translate }}</span></button>
          <button mat-stroked-button class="mr-2 mt-2" matStepperNext><span>{{ "NEXT" | translate }}</span></button>
        </div>
      </mat-step>
      <mat-step [label]="translate.instant('SELECTION CRITERIA')">
        <div fxLayout="row" class="mt-2">
          <div fxFlex>
            <div><span>{{ "LENGTH" | translate }}</span></div>
            <form [formGroup]="criteriaForm" (ngSubmit)="addCriteria()">
              <p fxLayout="row">
                <mat-form-field class="mr-3" fxFlex="30">
                  <mat-select formControlName="column_name" [placeholder]="'FIELDS' | translate">
                    <mat-option></mat-option>
                    @for (h of headers; track h) {
                      <mat-option [value]="h">{{h}}</mat-option>
                    }
                  </mat-select>
                  @if (criteriaForm.get('column_name').hasError('required') && criteriaForm.get('column_name').touched) {
                    <mat-error><span>{{ "SELECT FIELD NAME" | translate }}</span></mat-error>
                  }
                </mat-form-field>
                <mat-form-field class="mr-3" fxFlex="30">
                  <mat-select formControlName="operator" [placeholder]="'OPERATOR' | translate">
                    <mat-option></mat-option>
                    @for (o of optrs; track o) {
                      <mat-option [value]="o.value">{{o.text}}</mat-option>
                    }
                  </mat-select>
                  @if (criteriaForm.get('operator').hasError('required') && criteriaForm.get('operator').touched) {
                    <mat-error><span>{{ "SELECT OPERATOR" | translate }}</span></mat-error>
                  }
                </mat-form-field>
                <mat-form-field class="mr-3" fxFlex="30">
                  <input matInput autocomplete="off" formControlName="column_value" [placeholder]="'ENTER FIELD VALUE' | translate" type="text">
                  @if (criteriaForm.get('column_value').hasError('required') && criteriaForm.get('column_value').touched) {
                    <mat-error><span>{{ "ENTER FIELD VALUE" | translate }}</span></mat-error>
                  }
                </mat-form-field>
                <span fxFlex="10" fxLayout="column">
                  <span fxFlex="10">
                    <button  mat-stroked-button type="submit" [disabled]="criteriaForm.invalid">+</button>
                  </span>
                  <span fxFlex="90">

                  </span>
                </span>
              </p>
            </form>
            <span fxLayout="row">
              <span fxFlex="90"  class="mr-3">
                <table mat-table [dataSource]="dataSource" class="table table-striped table-bordered full-width">
                  <!-- Checkbox Column -->
                  <ng-container matColumnDef="select">
                    <th  *matHeaderCellDef>
                      <mat-checkbox  color="primary" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                      </mat-checkbox>
                    </th>
                    <td  *matCellDef="let row">
                      <mat-checkbox  color="primary"(click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                      </mat-checkbox>
                    </td>
                  </ng-container>

                  <!-- Text Column -->
                  <ng-container matColumnDef="criteria">
                    <th *matHeaderCellDef>
                      <span class="ml-2"><span>{{ "CRITERIA" | translate }}</span></span>
                    </th>
                    <td *matCellDef="let element">
                      <span class="ml-2">
                        {{element.criteria}}
                      </span>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </span>
              <span fxFlex="10" fxLayout="column">
                <span fxFlex="10">
                  <button  mat-stroked-button type="submit" (click)="removeCriteria()" [disabled]="selection.selected.length==0">-</button>
                </span>
                <span fxFlex="90">

                </span>
              </span>
            </span>
            <div>
              <mat-checkbox color="primary" #fromDateCheckedbox [(ngModel)]="fromDateValue" (click)="$event.stopPropagation()" (change)="fromDateChecked(fromDateCheckedbox.checked)" checked name="enabled" class="mr-2">From Date:</mat-checkbox>
              <mat-form-field class="mr-4">
                <input matInput autocomplete="off" (dateChange)="showFromDateValue($event)" [(ngModel)]="fromDateValue" [matDatepicker]="fromdate" [disabled]="!fromDateEnabled" [placeholder]="'FROM DATE' | translate">
                <mat-datepicker-toggle matSuffix [for]="fromdate" [disabled]="!fromDateEnabled"></mat-datepicker-toggle>
                <mat-datepicker [disabled]="!fromDateEnabled" #fromdate></mat-datepicker>
              </mat-form-field>

              <mat-checkbox color="primary" #toDateCheckedbox [(ngModel)]="toDateValue" (click)="$event.stopPropagation()" (change)="toDateChecked(toDateCheckedbox.checked)" checked name="enabled" class="mr-2">To Date:</mat-checkbox>
              <mat-form-field>
                <input matInput autocomplete="off" (dateChange)="showToDateValue($event)" [(ngModel)]="toDateValue" [matDatepicker]="todate" [disabled]="!toDateEnabled" [placeholder]="'TO DATE' | translate">
                <mat-datepicker-toggle matSuffix [for]="todate" [disabled]="!toDateEnabled"></mat-datepicker-toggle>
                <mat-datepicker #todate [disabled]="!toDateEnabled"></mat-datepicker>
              </mat-form-field>

            </div>

            <div>
              <mat-form-field class="mr-4">
                <mat-label class="mr-2">Sorting Field 1</mat-label>
                <mat-select [(value)]="selectedSortColumn">
                  <mat-option><span>{{ "NONE" | translate }}</span></mat-option>
                  @for (headerc1 of headers; track headerc1) {
                    <mat-option [value]="headerc1">{{headerc1}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-checkbox color="primary" #ascendingCheckedbox [disabled]="!selectedSortColumn" (change)="ascendingChecked(ascendingCheckedbox.checked)" [checked]="ascendingValue" name="enabled" class="mr-5 "><span>{{ "ASCENDING" | translate }}</span></mat-checkbox>
              <mat-checkbox color="primary" #descendingCheckedbox [disabled]="!selectedSortColumn" (change)="descendingChecked(descendingCheckedbox.checked)" [checked]="descendingValue" name="enabled " class="mr-2 "><span>{{ "DESCENDING" | translate }}</span></mat-checkbox>
            </div>
            <div>
              <mat-form-field class="mr-4 ">
                <mat-label class="mr-2 ">Sorting Field 2</mat-label>
                <mat-select [(value)]="selectedSortColumn2">
                  <mat-option><span>{{ "NONE" | translate }}</span></mat-option>
                  @for (headerc2 of headers; track headerc2) {
                    <mat-option [value]="headerc2 ">{{headerc2}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <mat-checkbox color="primary" #ascendingCheckedbox2 [disabled]="!selectedSortColumn2" (change)="ascendingChecked2(ascendingCheckedbox2.checked)" [checked]="ascendingValue2" name="enabled" class="mr-5 "><span>{{ "ASCENDING" | translate }}</span></mat-checkbox>
              <mat-checkbox color="primary" #descendingCheckedbox2 [disabled]="!selectedSortColumn2" (change)="descendingChecked2(descendingCheckedbox2.checked)" [checked]="descendingValue2" name="enabled" class="mr-2 "><span>{{ "DESCENDING" | translate }}</span></mat-checkbox>

            </div>
          </div>
        </div>
        <div class="button-group">
          <button mat-stroked-button class="mr-2 mt-2" matStepperPrevious><span>{{ "BACK" | translate }}</span></button>
          <button class="mr-2 mt-2 cancel-btn" mat-stroked-button type="submit" (click)="closeDialog() "><span>{{ "CANCEL" | translate }}</span></button>
          <button mat-stroked-button class="mr-2 mt-2 submit-btn" type="submit" (click)="submitResearchData() "><span>{{ "VIEW" | translate }}</span></button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
