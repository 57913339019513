import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';
import { Project } from 'src/app/share/projects';
import { getUser } from 'src/app/share/utils';
import { StoreService } from 'src/app/services/store.service';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {CommonModule} from '@angular/common';
import {DataService} from '../../services/data.service';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {HeaderService} from '../../services/header.service';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {ReportsService} from '../../services/reports.service';

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatIconModule,
    MatTooltipModule,
    CommonModule,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger, FaIconComponent
  ],
  selector: 'app-project-search-item',
  templateUrl: './project-search-item.component.html',
  styleUrls: ['./project-search-item.component.scss'],
})

export class ProjectSearchItemComponent implements OnInit {
  @Input() project: Project;
  errMsg: string;
  ownProject: boolean;
  selectedProject: any;

  constructor(
    public store: StoreService,
    public dataService: DataService,
    private reportService: ReportsService,
    public headerService: HeaderService
  ) {}

  ngOnInit() {
    if (this.project) {
      this.ownProject = getUser().id === this.project.created_by;
    }
  }

  handleClick(proj) {
    this.dataService.selectProject(proj)
    this.selectedProject = proj.name;
  }

  stopPropagate(event) {
    event.stopPropagation();
  }

  protected readonly getUser = getUser;
  protected readonly faBars = faBars;
}
