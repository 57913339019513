<div class="import-dialog-container">
  @if (showMessage) {
    <div class="alert-box">
      <strong>{{message}}</strong><br/>
      <!-- <strong>{{importDataError[0]}}</strong> -->
      <button type="button" class="close-btn" (click)="closePanel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <!-- <span class="btn btn-default close" style="cursor: pointer;" (click)="showErrorDlg()"><span>{{ "CLICK HERE FOR MORE DETAILS" | translate }}</span></span> -->
    </div>
  }
  <div class="main-content">
    <h3 class="title">{{importDialogTitle}}</h3>
    <div class="container">
      <div class="card">
        <div class="card-body">
          <form novalidate [formGroup]="importFileForm" #fform="ngForm">
            <p>
              <mat-form-field class="full-width">
                <input matInput class="h4" [placeholder]="'FILE TO IMPORT (ZIP OR XML)' | translate" readonly>
                <input type="file" class="file-input" id="userFile" (change)="setFileData($event)" #fileInput accept=".xml, .zip">
              </mat-form-field>

              @if (noFileIsSelected) {
                <mat-error><span>{{ "FILE IS REQUIRED" | translate }}</span></mat-error>
              }
            </p>
            <div class="button-group">
              <button mat-stroked-button mat-dialog-close type="button" class="cancel-btn"><span>{{ "CANCEL" | translate }}</span></button>
              <button mat-stroked-button class="submit-btn" (click)="onSubmit()" [disabled]="!isValidFile"><span>{{ "IMPORT DATA" | translate }}</span></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
