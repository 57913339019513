import { TranslateModule } from '@ngx-translate/core';
import { Component, Input } from '@angular/core';
import { Project } from 'src/app/share/projects';
import { StoreService } from 'src/app/services/store.service';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {CommonModule} from '@angular/common';
import {DataService} from '../../services/data.service';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import { HeaderService} from '../../services/header.service';
import {getUser} from '../../share/utils';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatIconModule,
    MatTooltipModule,
    CommonModule,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem, FaIconComponent
  ],
  selector: 'app-project-item',
  templateUrl: './project-item.component.html',
  styleUrls: ['./project-item.component.scss'],
})

export class ProjectItemComponent {
  @Input() project: Project;
  errMsg: string;
  ownProject: boolean;

  constructor(
    public store: StoreService,
    public dataService: DataService,
    public headerService: HeaderService
    ) {}

  stopFormUnSelect(event) {
    event.preventDefault();
    event.stopPropagation();
  }


  protected readonly getUser = getUser;
  protected readonly faBars = faBars;
}
