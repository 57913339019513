<div fxFlexLayout="row"  class="dialog-content">
  <div fxFlex>
    <h3>Collaborators of user {{admin.name}}</h3>
    @if (loading) {
      <span>Loading, please wait .......</span>
    }
    <mat-dialog-content>
      <table mat-table [dataSource]="dataSource" class="table table-striped table-bordered full-width">
        <!-- collaborator name Column -->
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef>
            <span class="ml-2"><span>{{ "NAME" | translate }}</span></span>
          </th>
          <td *matCellDef="let element">
            <span class="ml-2"> {{element.name}} </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-dialog-content>
    <mat-dialog-actions class="button-group">
      <span class="flex-spacer"></span>
      <button mat-button (click)="closeDialog()"   class="btn submit-btn"><span>{{ "OK" | translate }}</span></button>
    </mat-dialog-actions>
  </div>
</div>
