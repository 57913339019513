import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { Component, OnInit, Input, Inject, AfterViewInit } from '@angular/core';
import { Field } from 'src/app/share/form/field';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { StoreService } from 'src/app/services/store.service';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';

@Component({
  standalone: true,
  imports: [TranslateModule,
    ReactiveFormsModule,
    MatDialogContent,
    MatOptionModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogActions,
    MatSelectModule,
    MatButtonModule,
  ],
  selector: 'app-constraint-dialog',
  templateUrl: './constraint-dialog.component.html',
  styleUrls: ['./constraint-dialog.component.scss', '../dialog-btns.component.scss']
})
export class ConstraintDialogComponent implements OnInit, AfterViewInit {
  addConstraintForm: FormGroup;
  constraint: any;
  parent: Field;
  @Input() field: Field;
  @Input() constraints: any[];
  choiceOrMultChoice: boolean;
  @Input() mode = {
    text: '',
    function: ''
  };
  index: number;
  valuePlaceHolder: string;
  valueType: string;
  dataInvalid = false;

  constraintTypes = [
    {value: 'minimum', text: 'Minimum'},
    {value: 'maximum', text: 'Maximum'}
  ];

  constructor(
    private dialogRef: MatDialogRef<ConstraintDialogComponent>,
    private fb: FormBuilder,
    private store: StoreService,
    public toastService: ToastService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.choiceOrMultChoice = true;
    if (data !== undefined && data !== null) {
      this.field = data.field;
      this.constraints = data.constraints;
      this.mode = data.mode;
      this.index = data.constraintIndex;
    }
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.dataInvalid = false;
    this.choiceOrMultChoice = true;
    if (this.field._class === 'autoincrementintegerfield' || this.field._class === 'integerfield' || this.field._class === 'decimalfield') {
      this.constraintTypes = [
        {value: 'minimum', text: 'Minimum'},
        {value: 'maximum', text: 'Maximum'},
        {value: 'choice', text: 'Choice'}
      ];
    } else {
      this.constraintTypes = [
        {value: 'length', text: this.translate.instant("LENGTH")},
        {value: 'choice', text: this.translate.instant("SINGLE CHOICE LIST")},
        {value: 'multiple_choice', text: this.translate.instant("MULTIPLE CHOICE LIST")}
      ];
    }
    this.valuePlaceHolder = 'Value';
    this.valueType = 'text';
    this.createForm();
  }

  createForm() {
    let klass = '';
    let values = null;
    let message = '*';
    if (this.mode.function === 'Update') {
      const tempconstraint = this.constraints[this.index];
      if (tempconstraint !== null && tempconstraint !== undefined) {
        klass = tempconstraint['_class'];
        if (Object.keys(tempconstraint).indexOf('value') >= 0) {
          values = tempconstraint['value'];
        } else {
          if (tempconstraint['values'] instanceof Object) {
            values = tempconstraint['values'].join(';');
          } else {
            values = tempconstraint['values'];
          }
        }
        message = tempconstraint['error_message'];
      }
    } else {
      if(this.choiceOrMultChoice) {
        klass = 'choice';
      }
    }
    if (this.choiceOrMultChoice) {
      this.addConstraintForm = this.fb.group({
        _class: [klass, Validators.required],
        values: [values, Validators.required],
        error_message: [message, Validators.required]
      });
      this.valuePlaceHolder = 'Separate each value with a semicolon (;).';
    } else {
      this.addConstraintForm = this.fb.group({
        _class: [klass, Validators.required],
        value: [values, Validators.required],
        error_message: message
      });
      this.valuePlaceHolder = 'Value';
    }
  }

  constraintSelected(value) {
    if (value === 'choice' || value === 'multiple_choice') {
      this.valuePlaceHolder = 'Values separated by semi colon';
    } else if (value === 'length' || value === 'minimum' || value === 'maximum') {
      this.valuePlaceHolder = 'Value';
      this.valueType = 'number';
    }
  }

  onSubmit() {
    this.dataInvalid = false;
    const data = this.addConstraintForm.value;
    let value = data['value'];
    let values = data['values'];

    if ((value === null || value === undefined) && values) {
      const processedValues = this.processValues(values);
      this.dataInvalid = processedValues === null;
      if (this.dataInvalid) {
        return;
      }

      if (processedValues !== null) {
        if (['length', 'minimum', 'maximum'].includes(data['_class'])) {
          value = processedValues;
        } else {
          data['values'] = processedValues;
        }
      }
    }

    const constraint = ['length', 'minimum', 'maximum'].includes(data['_class'])
      ? {
        _class: data['_class'],
        value: `${value}`,
        error_message: data['error_message'],
      }
      : data;

    const result = {
      event: this.mode.function,
      constraint,
    };
    this.dialogRef.close(result);
  }

  private processValues(values: string): string | null {
    const valuesArray = values
      .split(';')
      .map((val) => val.trim())
      .filter((val) => val.length > 0);

    for (const val of valuesArray) {
      if (!this.validateValue(val)) {
        this.toastService.errorToast("Invalid data")
        return null;
      }
    }

    return valuesArray.join(';');
  }

  private validateValue(val: string): boolean {
    const fieldClass = this.field._class;

    if (fieldClass === 'autoincrementintegerfield' || fieldClass === 'integerfield') {
      return this.validateInteger(val);
    } else if (fieldClass === 'decimalfield') {
      return this.validateDecimal(val);
    }

    // Add more field classes if needed
    return true;
  }

  private validateInteger(val: string): boolean {
    if (this.isInteger(val)) {
      return true;
    }

    const valParts = val.split('>');
    if (valParts.length > 1 && this.isInteger(valParts[1])) {
      return true;
    }

    return false;
  }

  private validateDecimal(val: string): boolean {
    if (this.isDecimal(val)) {
      return true;
    }

    const valParts = val.split('>');
    if (valParts.length > 1 && this.isDecimal(valParts[1])) {
      return true;
    }

    return false;
  }


  closeDialog(event) {
    event.preventDefault();
    this.dialogRef.close();
    event.stopPropagation();
  }

  isInteger(val): boolean {
    if(val === "*") {
      return true;
    }
    const convData = Number.parseInt(val);
    return Number.isInteger(convData);
  }

  isDecimal(val): boolean {
    if(val === "*") {
      return true;
    }
    if (Number.isNaN(Number.parseFloat(val))) {
      return false;
    }
    return true;
  }
}
