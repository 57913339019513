<div class="container">
  <div class="message-container">
    <ng-container *ngIf="showMessage">
      <app-message-box #messageBox [message]="message"></app-message-box>
    </ng-container>
  </div>

  <mat-checkbox color="primary"  class="mr-2" #showpntnbrcheckbox name="shwpntnbr"
    [checked]="show_point_numbers"
    (click)="$event.stopPropagation()"
    (change)="setShowPointNumbers(showpntnbrcheckbox.checked)">
    {{"SHOW POINT NUMBERS" | translate}}
  </mat-checkbox>
  <mat-checkbox color="primary"  class="mr-2" #showvaluecheckbox name="shwval"
    [checked]="show_value_on_map"
    (click)="$event.stopPropagation()"
    (change)="setShowFieldValuesOnMap(showvaluecheckbox.checked)">
    {{"SHOW FIELD VALUES" | translate}}
  </mat-checkbox>
  <div class="button-group">
    <button (click)="showListOfGeometryForms()" mat-button class="btn-border"><span>{{ "FORMS" | translate }}</span></button>
  </div>
</div>

<div #map style="height: 90%; margin-bottom: 0px !important;"></div>
