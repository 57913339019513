<div fxFlexLayout="row">
  <div fxFlex>
    <h1 mat-dialog-title><span>{{ "DATA IMPORTATION ERROR LOG" | translate }}</span></h1>
    <mat-dialog-content>
      @for (err of error; track err) {
        <div class="alert alert-danger">
          {{err}}
        </div>
      }
    </mat-dialog-content>
    <mat-dialog-actions class="button-group">
      <button mat-raised-button (click)="closeDialog()" class="btn btn-default" cdkFocusInitial><span>{{ "CLOSE" | translate }}</span></button>
    </mat-dialog-actions>
  </div>
</div>
