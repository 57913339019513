import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, Inject } from '@angular/core';
import { Project } from 'src/app/share/projects';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { User } from 'src/app/share/user';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogModule, MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { getAdminToken, equalsIgnoringCase } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { SysAdminUserServiceService } from 'src/app/services/sys-admin-user-service.service';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatSelectModule,
    MessageBoxComponent,
    ReactiveFormsModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
  ],
  selector: 'app-import-project',
  templateUrl: './import-project.component.html',
  styleUrls: ['./import-project.component.scss', '../dialog-btns.component.scss']
})
export class ImportProjectComponent implements OnInit {
  @Input() project: Project;
  projectDialogForm: FormGroup;

  project_id: string;
  user_id: string;
  project_class: string;
  description: string;
  projects: Project[];
  users: User[];
  @Output() invalidIconDropped = new EventEmitter<boolean>();
  @Input() user: User;
  @Input() mode = {
    text: 'Create',
    function: 'Create'
  };
  @ViewChild('pjtIcon', {static: false}) img;
  @ViewChild('fform', { static: false}) projectFormDirective;

  metadata: any;
  moreUsers: any;

  private messageBox: MessageBoxComponent;


  @ViewChild('messageBox', {static: false}) set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;

  constructor(
    private store: StoreService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private toastService: ToastService,
    private mcUserService: SysAdminUserServiceService,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ImportProjectComponent>
  ) {
    this.projects = [];
    this.users = [];
    this.project_class = this.store.proClass;
    this.createForm();
  }

  ngOnInit() {
    this.loadUsers("users");
  }


  getProjectOnProClassChange(obj) {

    const userId = obj.value;
    let customerId = null;
    if(userId !== null && userId !== undefined) {
      const user = this.users.find(u => u.id == userId);
      if(user !== null && user !== undefined) {
        customerId = user.customer_id;
      }
    }
    let token = getAdminToken();
    this.store.showLoading();

    let proClass = "project";
    if(this.project_class) {
      proClass = this.project_class;
    }
    const visibility = "private";
    const archived = false;

    const proToken = {
      key: 'X-Admin-Auth-Token',
      value: token.key
    };
    if(customerId !== null) {
      this.dataService.getProjects(proToken, proClass, visibility, archived, true).subscribe(
        res => {
          if(res !== null) {
            this.projects = res.filter(p => p.customer_id === customerId).sort(
              (a, b) => {
                return equalsIgnoringCase(a.name, b.name);
              }
            );
          }
          this.store.hideLoading();
        },
        errmess => {
          this.toastService.errorToast(errmess);
          this.store.hideLoading();
      });
    }
  }



  getProjectOnChange(obj) {

    const projectId = obj.value;
    if(projectId !== null && projectId !== undefined) {
      const project = this.projects.find(p => p.id == projectId);
      if(project !== null && project !== undefined) {
        this.description = project.description;
      }
    }
  }

  displayUsers(res){
    this.metadata = res.metadata;
    if(this.metadata.uris.next !== null && this.metadata.uris.next !== undefined){
      this.moreUsers = false;
    } else {
      this.moreUsers = true;
    }
    for (const collaborator of  res.instances) {
      this.users.push(collaborator);
    }
    this.users = this.users.sort(
      (a, b) => {
        return equalsIgnoringCase(a.name, b.name);
      });
    this.store.hideLoading();
  }

  loadUsers(uri){
    const token = getAdminToken();
    this.store.showLoading();
    this.mcUserService.getAdminUsers(token, uri).subscribe(
      res => {
        this.displayUsers(res);

        if(this.moreUsers === false)
          this.loadMoreUsers();
      },
      err => {
        this.toastService.errorToast(err);
        this.store.hideLoading();
      });
  }

  loadMoreUsers(){
    if(this.metadata !== null && this.metadata !== undefined){
      const uris = this.metadata.uris;

      if(uris.next !== null && uris.next !== undefined){
        let linkValue = uris.next;
        for (let i = 0; i < 4; i++) {
          linkValue = linkValue.substring(1);
        }

        this.loadUsers(linkValue);
      }
    }
  }

  initializeFormData(p: Project) {
    this.project_id = p.id;
    this.user_id = p.created_by;
    this.project_class = p.project_class;
  }

  createForm() {
    this.projectDialogForm = this.fb.group({
      user_id: ['', Validators.required],
      project_id: ['', Validators.required],
      description: ''
    });
  }

  onSubmit() {
    const temp = this.projectDialogForm.value;
    let isMyCumulusAdministrator = true;
    const projectId = temp['project_id'];
    let selectedProject = {
      name: '',
      description: ''
    };
    if(projectId !== null && projectId !== undefined) {
      selectedProject = this.projects.find(p => p.id == projectId);
    }
    let token = getAdminToken();
    const proToken = {
      'key': 'key',
      'value': token.key
    }
    const project = {
      project_id: temp.project_id,
      name: selectedProject.name,
      description: selectedProject.description
    };
    this.store.showLoading();
    this.dataService.duplicateProject(project, proToken, isMyCumulusAdministrator).subscribe(
      res => {

        res.project_class = this.project_class;
        res.visibility = "public";
        this.dataService.updateProject(res, proToken, isMyCumulusAdministrator).subscribe(
          p => {
            this.dataService.createProjectLocally(p);
            this.dataService.templates.next([]);
            this.dataService.showTemplates();
            this.dialogRef.close();
            this.store.hideLoading();
          },
          errormsg => {
            this.toastService.errorToast(errormsg);
            this.store.hideLoading();
          }
        );
      },
      errmess => {
        this.toastService.errorToast(errmess);
        this.store.hideLoading();
    });
  }

  onCancel(event) {
    this.dialogRef.close();
    this.store.hideLoading();
  }

  openCreateProject() {
    this.dialog.open(ImportProjectComponent, {width: '500px', height: 'auto'});
  }
}
