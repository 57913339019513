<div fxFlexLayout="row">
  <div fxFlex fxFlexOffset="20px">
    <h3>{{ mode.text }} {{ "REPORT TEMPLATE" | translate}}</h3>
    @if (showMessage) {
      <app-message-box #messageBox message="{{message}}"></app-message-box>
    }
    <form novalidate [formGroup]="reportTemplateForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      <mat-dialog-content>
        <p>
          <mat-form-field class="full-width" appearance="outline">
            <input matInput formControlName="name"  appearance="outline" [placeholder]="'NAME' | translate" type="text" required>
            @if (reportTemplateForm.get('name').hasError('required') && reportTemplateForm.get('name').touched) {
              <mat-error><span>{{ "REPORT TEMPLATE NAME IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="full-width" appearance="outline">
              <input matInput class="h4" [placeholder]="'FILE TO IMPORT (HTML, CSV, Excel, DOCX, PDF)' | translate" readonly>
              <input type="file" class="btn btn-small mb-0" id="userFile" (change)="onFileChange($event)" #fileInput accept=".html, .csv, .docx, .xlsx, .xls, .pdf">
          </mat-form-field>

          @if(noFileIsSelected) {
            <mat-error><span>{{ "FILE IS REQUIRED" | translate }}</span></mat-error>
          }
        </p>

        @if(orientationAndDocFormatNeeded){
          <p>
            <span class="half-width"><span>{{ "FORMAT" | translate }}</span></span>
            <mat-select class="ml-3" class="half-width"  appearance="outline" formControlName="default_page_size" [placeholder]="'FORMAT' | translate" required>
              @for (f of formats; track f) {
                <mat-option [value]="f.value">{{ f.text }}</mat-option>
              }
            </mat-select>
            @if (reportTemplateForm.get('default_page_size').hasError('required') && reportTemplateForm.get('default_page_size').touched) {
              <mat-error><span>{{ "REPORT TEMPLATE FORMAT IS REQUIRED" | translate }}</span></mat-error>
            }
          </p>

          <p>
            <span class="half-width"><span>{{ "ORIENTATION" | translate }}</span></span>
            <mat-select class="ml-3" class="half-width"  appearance="outline" formControlName="orientation" [placeholder]="'ORIENTATION' | translate">
              @for (o of orientations; track o) {
                <mat-option [value]="o.value">{{ o.text }}</mat-option>
              }
            </mat-select>
            @if (reportTemplateForm.get('orientation').hasError('required') && reportTemplateForm.get('orientation').touched) {
              <mat-error><span>{{ "REPORT TEMPLATE ORIENTATION IS REQUIRED" | translate }}</span></mat-error>
            }
          </p>
          <div>
            <table mat-table #placeholderslist [dataSource]="dataSource" class="table table-striped table-bordered full-width">
              <!-- place holder name Column -->
              <ng-container matColumnDef="name">
                <th *matHeaderCellDef>
                  <span class="ml-2"><span>{{ "PLACE HOLDER" | translate }}</span></span>
                </th>
                <td *matCellDef="let element; let i = index">
                  <span class="ml-2">
                    <input matInput value="{{element.name}}" type="text">
                  </span>
                </td>
              </ng-container>

              <!-- place holder default value Column -->
              <ng-container matColumnDef="default_value">
                <th *matHeaderCellDef>
                  <span class="ml-2"><span>{{ "DEFAULT VALUE" | translate }}</span></span>
                </th>
                <td *matCellDef="let element; let i = index">
                  <span class="ml-2">
                    <input matInput #defaultValue value="{{element.default_value}}" (change)="defaultValueChanged($event, element, defaultValue.value)" type="text">
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th *matHeaderCellDef>
                  <span class="ml-2"><span>{{ "ACTIONS" | translate }}</span></span>
                </th>
                <td *matCellDef="let element; let i = index">
                  <span class="ml-2">
                    <button class="btn-border ml-2 buttons" mat-button (click)="OpenDeletePlaceHolder(i)">
                      <mat-icon class="icons">delete</mat-icon>
                    </button>
                  </span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        }



        @if (isReTeAdmin) {
          <p>
            <span class="half-width"><span>{{ "ACCESS TYPE" | translate }}</span></span>
            <mat-select class="ml-3" class="half-width" formControlName="visible_to" [placeholder]="'ACCESS TYPE' | translate">
              @for (at of accesstypes; track at) {
                <mat-option [value]="at.value">{{ at.text }}</mat-option>
              }
            </mat-select>
            @if (reportTemplateForm.get('visible_to').hasError('required') && reportTemplateForm.get('visible_to').touched) {
              <mat-error><span>{{ "ACCESS TYPE OF THE REPORT TEMPLATE IS REQUIRED" | translate }}</span></mat-error>
            }
          </p>
        }
      </mat-dialog-content>
      <app-dialog-actions [mode]="mode" [formCheck]="reportTemplateForm"></app-dialog-actions>
    </form>
  </div>
</div>
