import { Template } from './template';

const READ = 'read';
const CREATE = 'create';
const UPDATE = 'update';
const DELETE = 'delete';

class Perm {
  read: boolean;
  create: boolean;
  update: boolean;
  delete: boolean;

  constructor(permission_type: string[]) {
    if (permission_type !== null && permission_type !== undefined && permission_type.length > 0) {
      const notGranted = this.getNotGrantedPerm(permission_type);
      for (const perm of permission_type) {
        if (perm === READ) {
          this.read = true;
        } else if (perm === CREATE) {
          this.create = true;
        } else if (perm === UPDATE) {
          this.update = true;
        } else if (perm === DELETE) {
          this.delete = true;
        }
      }

      for (const perm of notGranted) {
        if (perm === READ) {
          this.read = false;
        } else if (perm === CREATE) {
          this.create = false;
        } else if (perm === UPDATE) {
          this.update = false;
        } else if (perm === DELETE) {
          this.delete = false;
        }
      }
    } else {
      this.create = false;
      this.read = false;
      this.update = false;
      this.delete = false;
    }
  }

  getNotGrantedPerm(granted: string[]): string[]{
    const notGranted = [];
    const perms = [READ, CREATE, UPDATE, DELETE];

    for(let i = 0; i < perms.length; i++){
      if(granted.indexOf(perms[i]) === -1)
        notGranted.push(perms[i]);
    }

    return notGranted;
  }

  selectRead(grant: boolean) {
    this.read = grant;
  }

  selectCreate(grant: boolean) {
    this.create = grant;
  }

  selectUpdate(grant: boolean) {
    this.update = grant;
  }

  selectDelete(grant: boolean) {
    this.delete = grant;
  }

  //Function being used, code does not detect it.
  selectAll(grant: boolean) {
    this.read = grant;
    this.create = grant;
    this.update = grant;
    this.delete = grant;
  }

}

export class TemplatePermission {
  template: Template;
  permissions: Perm;

  constructor(template: Template, permissions: string[]) {
    this.initializePerm(template, permissions);
  }

  initializePerm(template: Template, permission_types: string[]) {
    this.template = template;
    this.permissions = new Perm(permission_types);
  }
}
