import { TranslateModule } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { getToken, getAdminToken, isTokenValid, logoutUser } from './share/utils';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { StoreService } from './services/store.service';
import { HeaderComponent } from './components/header/header.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FooterComponent } from './components/footer/footer.component';

@Component({
  standalone: true,
  imports: [TranslateModule, 
    HeaderComponent,
    MatSidenavModule,
    RouterOutlet,
    FooterComponent,
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // @select() token: Observable<Token>;
  // @select() showprojects: Observable<boolean>;
  showHeaderFooter: boolean = true;

  title = 'mycumulus-web-application-v2';

  constructor(
    private store: StoreService,
    private router: Router,
  ) {
    let token = getToken();
    if (token) {
      if (isTokenValid(token)) {
        this.store.saveToken(token);
        this.router.navigate(['/projects']);
        this.store.hideProjects();
      } else {
        logoutUser();
        this.store.initializeStore();
      }
    } else {
      token = getAdminToken();
      if (token) {
        if (isTokenValid(token)) {
          this.store.saveToken(token);
          this.router.navigate(['/mcadmins']);
          return;
        } else {
          logoutUser();
          this.store.initializeStore();
          this.store.initializeStore();
          this.router.navigate(['/mcadmin-login']);
          return;
        }
      } else {
        logoutUser();
        this.store.initializeStore();
        //this.router.navigate(['/login']);
        return;
      }
      // this.store.hideProjects();
      // this.store.hideLoading();
    }
  }
  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeaderFooter = !(event.urlAfterRedirects.includes('/signup'));
      }
    });
  }
}
