import { TranslateModule } from '@ngx-translate/core';
import {Component, OnInit, Input, ViewChild, Inject, HostListener} from '@angular/core';
import { IntegerField } from 'src/app/share/form/integer-field';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { FieldSet } from 'src/app/share/form/fieldset';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import { StoreService } from 'src/app/services/store.service';
import { isFieldAlreadyExist, removeSpecialCharacters } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import {Field} from '../../../share/form/field';
import {ToastService} from '../../../services/toast.service';
import {DialogActionsComponent} from '../dialog-actions/dialog-actions.component';

@Component({
  standalone: true,
    imports: [TranslateModule,
        ReactiveFormsModule,
        MessageBoxComponent,
        MatDialogContent,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatOptionModule,
        MatDialogActions,
        MatCheckboxModule,
        MatDialogModule,
        MatButtonModule,
        DialogActionsComponent,
    ],
  selector: 'app-create-integer-field',
  templateUrl: './create-integer-field.component.html',
  styleUrls: ['./create-integer-field.component.scss', '../dialog-btns.component.scss']
})
export class CreateIntegerFieldComponent implements OnInit {
  integerField: IntegerField;
  integerFieldForm: FormGroup;
  initialData: Field;
  @Input() parent: FieldSet;
  @Input() mode = {
    text: 'Create',
    function: 'Create'
  };
  eventActionslist = [
    'OnRD_Message->Gain',
    'OnRD_Message->Depth',
    'OnRD_Message->Current',
    'OnRD_Message->Decibels',
    'OnRD_Message->FaultSignal',

    'OnLocationChange->NumSVs',
    'OnLocationChange->Gnss.Satellites.Used',
    'OnLocationChange->Gnss.Status.value'
  ];

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  @ViewChild('fform') integerFieldFormDirective;
  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  nameLengthExceed10 = false;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CreateIntegerFieldComponent>,
    public toastService: ToastService,
  ) {
    if (data !== undefined && data !== null) {
      this.initialData = data;
      this.parent = data.parent;
      this.mode = data.mode;
      this.integerField = data.field;
    }
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    if (this.mode.function === 'Create') {
      this.integerFieldForm = this.fb.group({
        name: ['', Validators.required],
        label: ['', Validators.required],
        description: '',
        event_actions: [],
        default_value: '',
        use_previous_value: false,
        enabled: true,
        required: false,
        show_value_on_map: false
      });
    } else {
      this.integerFieldForm = this.fb.group({
        name: [this.integerField.name, Validators.required],
        label: [this.integerField.label, Validators.required],
        description: this.integerField.description,
        default_value: this.integerField.default_value,
        event_actions: this.integerField.event_actions,
        use_previous_value: this.integerField.use_previous_value,
        enabled: this.integerField.enabled,
        required: this.integerField.required,
        show_value_on_map: this.integerField.show_value_on_map
      });
    }
  }

  onLabelChange() {
    this.integerFieldForm.controls.name.setValue(removeSpecialCharacters(this.integerFieldForm.value.label));
    if (this.integerFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  onNameChange() {
    if (this.integerFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  onSubmit() {
    if (this.integerField) {
      this.integerField = {
        ...this.integerField,
        ...this.integerFieldForm.value
      };
    } else {
      this.integerField = this.integerFieldForm.value;
    }

    // this.integerField = this.integerFieldForm.value;
    this.integerField._class = 'integerfield';

    if (this.integerField.name === this.parent.name) {
      this.toastService.errorToast('Field cannot take the same name as the active fieldset');
      return;
    }
    const result = {
      action: 'Create',
      field: this.integerField,
      initialValues: this.initialData,
    }
    if (this.mode.function === 'Create') {
      if (isFieldAlreadyExist(this.integerField, this.parent)) {
        this.toastService.errorToast('Another field with the same name/label already exists');
        return;
      }
      // this.parent.fields.push(this.stringField);
      this.store.addField(this.integerField);
    } else {
      result.action = 'Update';
      this.store.addField(this.integerField);
    }
    this.dialogRef.close(result);
  }
}
