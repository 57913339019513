import { TranslateModule } from '@ngx-translate/core';
import {Component, OnInit, ViewChild, Input, Inject, HostListener} from '@angular/core';
import { AutoIncrementField } from 'src/app/share/form/auto-increment-field';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { FieldSet } from 'src/app/share/form/fieldset';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import { StoreService } from 'src/app/services/store.service';
import { isFieldAlreadyExist, removeSpecialCharacters } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import {DialogActionsComponent} from '../dialog-actions/dialog-actions.component';

@Component({
  standalone: true,
    imports: [TranslateModule,
        ReactiveFormsModule,
        MessageBoxComponent,
        MatDialogContent,
        MatFormFieldModule,
        MatInputModule,
        MatDialogActions,
        MatCheckboxModule,
        MatDialogModule,
        MatButtonModule,
        DialogActionsComponent,
    ],
  selector: 'app-create-autoincrement-field',
  templateUrl: './create-autoincrement-field.component.html',
  styleUrls: ['./create-autoincrement-field.component.scss', '../dialog-btns.component.scss']
})
export class CreateAutoincrementFieldComponent {
  autoIncrementField: AutoIncrementField;
  autoIncrementFieldForm: FormGroup;
  @Input() parent: FieldSet;
  @Input() mode = {
    text: 'Create',
    function: 'Create'
  };
  @ViewChild('fform') autoIncrementFieldFormDirective;
  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  nameLengthExceed10 = false;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CreateAutoincrementFieldComponent>,
    public toastService: ToastService,
  ) {
    if (data) {
      this.parent = data.parent;
      this.mode = data.mode;
      this.autoIncrementField = data.field;
    }
    this.createForm();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  createForm() {
    if (this.mode.function === 'Create') {
      this.autoIncrementFieldForm = this.fb.group({
        name: ['', Validators.required],
        label: ['', Validators.required],
        description: [''],
        initial_value: [1, Validators.required],
        step: [1, Validators.required],
        enabled: true,
        show_value_on_map: false
      });
    } else {
      const step = this.autoIncrementField && this.autoIncrementField?.step || 1;
      const initialValue = this.autoIncrementField && this.autoIncrementField?.initial_value || 1
      this.autoIncrementFieldForm = this.fb.group({
        name: [this.autoIncrementField.name, Validators.required],
        label: [this.autoIncrementField.label, Validators.required],
        description: [this.autoIncrementField.description],
        initial_value: [initialValue, Validators.required],
        step: [step, Validators.required],
        enabled: this.autoIncrementField.enabled,
        show_value_on_map: this.autoIncrementField.show_value_on_map
      });
    }

  }

  onNameChange() {
    if (this.autoIncrementFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  onLabelChange() {
    this.autoIncrementFieldForm.controls.name.setValue(removeSpecialCharacters(this.autoIncrementFieldForm.value.label));
    if (this.autoIncrementFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  onSubmit() {
    if (this.autoIncrementField) {
      this.autoIncrementField = {
        ...this.autoIncrementField,
        ...this.autoIncrementFieldForm.value
      };
    } else {
      this.autoIncrementField = this.autoIncrementFieldForm.value;
    }
    // this.autoIncrementField = this.autoIncrementFieldForm.value;
    this.autoIncrementField._class = 'autoincrementintegerfield';

    if (this.autoIncrementField.name === this.parent.name) {
      this.toastService.errorToast('Field cannot take the same name as the active fieldset');
      return;
    }
    const result = {
      action: 'Create',
      field: this.autoIncrementField
    };
    if (this.mode.function === 'Create') {
      if (isFieldAlreadyExist(this.autoIncrementField, this.parent)) {
        this.toastService.errorToast('Another field with the same name/label already exists');
        return;
      }
      this.store.addField(this.autoIncrementField);
    } else if (this.mode.function === 'Update') {
      result.action = 'Update';
    }
    this.dialogRef.close(result);
  }
}
