import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { StoreService } from 'src/app/services/store.service';
import { SysAdminUserServiceService } from 'src/app/services/sys-admin-user-service.service';
import { UserService } from 'src/app/services/user.service';
import {
  getAdminToken,
  getMarxactPassword,
  getMarxactUsername,
  getPrivateApiAURL,
  getToken, getUser,
  persistAdminInfo,
  persistUser,
  removeAdminInfo,
  removeUser
} from 'src/app/share/utils';
import { environment } from 'src/environments/environment';
import { MarxactLoginPageComponent } from '../dialogs/marxact-login-page/marxact-login-page.component';
import { MarxactPageComponent } from '../marxact-page/marxact-page.component';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../services/toast.service';

@Component({
  standalone: true,
  imports: [TranslateModule, 
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MessageBoxComponent,
    MatButtonModule,
  ],
  selector: 'app-admin-info-page',
  templateUrl: './admin-info-page.component.html',
  styleUrls: ['./admin-info-page.component.scss']
})
export class AdminInfoPageComponent implements OnInit {
  user: any;
  newEmail: string;
  adminName: string;
  adminEmail: string;
  adminType: string;
  editUserEmail: boolean;
  usermarxactDevicesName: string;
  adminMCMarxact: any;
  userHasMarxactAccount = false;
  marxactDevicesList: any[];
  private messageBox: MessageBoxComponent;
  updateEmailForm: FormGroup;
  serverVersion: string;
  apiVersion: string;

  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    public store: StoreService,
    private mcUserService: SysAdminUserServiceService,
    private dialog: MatDialog,
    public toastService: ToastService,
  ) { }

  ngOnInit() {
    let token = null;
    this.user = this.store.user;
    if (this.user['user_type'] !== null && this.user['user_type'] !== undefined) {
      token = getToken();
      if (token === null || token === undefined) {
        this.router.navigate(['/']);
        return;
      }
      this.editUserEmail = false;
      this.usermarxactDevicesName = '';
      this.userHasMarxactAccount = this.checkAdminHasMarxactDevice();
      this.marxactDevicesList = this.getCurrentUserDevices();
    } else if (this.user['admin_type'] !== null && this.user['admin_type'] !== null) {
      token = getAdminToken();
    }
    this.serverVersion = "";
    this.apiVersion = "";
    this.refreshAdminValues();
    this.getApiVersion();
  }

  getServer(): string {
    const server = getPrivateApiAURL();

    return server.split('v1')[0].slice(0, -1);
  }

  getApiVersion() {
    this.mcUserService.getApiVersion().subscribe(
      res => {
        this.serverVersion = res["backend_version"]
        this.apiVersion = res["api_version"]
      },
      _error => {
      }
    )
    return environment.apiVersion;
  }

  getWebAppVersion() {
    return environment.webAppVersion;
  }

  editProfile() {
    this.editUserEmail = true;
    this.createForm();
  }

  getCurrentUserDevices() {
    const curUser = this.store.user;
    let deviceList = [];
    const marxactdev = curUser.mc_marxact;
    if (marxactdev !== null && marxactdev !== undefined) {
      const devices = marxactdev.devices;
      if (devices !== null && devices !== undefined) {
        for (const device of devices) {
          this.usermarxactDevicesName = this.usermarxactDevicesName === ''
            ? device.device_name
            : `${this.usermarxactDevicesName}, ${device.device_name}`;
        }
        deviceList = deviceList.concat(devices);
      }
    }

    return deviceList;
  }



  /**
   * This method verify if the user is connected to the marxact account
   */
  openGnssSourceLogin() {
    if (this.user['user_type'] === null || this.user['user_type'] === undefined) {
      return;
    }

    const marxactuserconnected = this.checkIfUserConnected();
    if (!marxactuserconnected) {
      const dialogRef = this.dialog.open(MarxactLoginPageComponent, {
        width: '400px'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== null) {
          if (result.marxact_connected) {
            this.openMarxactDeviceSettingDialog();
          }
        }
      });
    } else {
      this.openMarxactDeviceSettingDialog();
    }
  }

  checkIfUserConnected() {
    const username = getMarxactUsername();
    const password = getMarxactPassword();
    if (username !== null && username !== undefined && username !== '') {
      return true;
    }
    return false;
  }

  openMarxactDeviceSettingDialog() {
    const dialogRef = this.dialog.open(MarxactPageComponent, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.userHasMarxactAccount = this.checkAdminHasMarxactDevice();
      this.marxactDevicesList = this.getCurrentUserDevices();
    });
  }

  checkAdminHasMarxactDevice() {
    const adminInfo = getUser();
    if (adminInfo.mc_marxact !== null && adminInfo.mc_marxact !== undefined) {
      return true;
    }
    return false;
  }

  getUserType(inType): string {
    switch (inType) {
      case 'admin_user':
        return 'Admin User';
      case 'collaborator':
        return 'Collaborator';
      case 'mc_test_temporary':
        return 'MC Test Temporary User';
      case 'mc_test_permanent':
        return 'MC Test Permanent User';
      case 'users_and_partners':
        return 'Users And Partners';
      case 'super_user':
        return 'Super Administrator';
      case 'system_administrator':
        return 'System Administrator';
      case 'signup_administrator':
        return 'Signup Administrator';
      case 'mail_sender_administrator':
        return 'Email Sender Administrator';
      case 'report_template_administrator':
        return 'Report Template Administrator';
      case 'administrator':
        return 'Administrator';
      default:
        return inType;
    }

  }

  refreshAdminValues() {
    this.adminName = this.user.name;
    this.adminEmail = this.user.email;
    if (this.user.user_type !== null && this.user.user_type !== undefined) {
      this.adminType = this.getUserType(this.user.user_type);
    } else {
      this.adminType = this.getUserType(this.user.admin_type);
    }
  }

  updateProfile() {
    const data = this.updateEmailForm.value;
    if (data.new_email === this.user.email) {
      this.toastService.errorToast('The new email and old email are the same');
      return;
    }
    this.newEmail = data.new_email;
    if (this.user !== null) {
      let token;
      if (this.user.user_type !== null && this.user.user_type !== undefined) {
        token = getToken();
        this.userService.updateUserNameAndEmail(this.user, token, this.newEmail).subscribe(
          res => {
            this.user = res;
            this.store.persistUser(res);
            removeUser();
            persistUser(res);
            this.refreshAdminValues();
            this.editUserEmail = false;
          },
          err => {
            this.toastService.errorToast(err);
          }
        );
      } else {
        token = getAdminToken();
        this.mcUserService.updateMCNameAndEmail(this.user, token).subscribe(
          res => {
            this.user = res;
            removeAdminInfo();
            persistAdminInfo(res);
            this.refreshAdminValues();
          },
          err => {
            this.toastService.errorToast(err);
          }
        );
      }
    }
  }

  createForm() {
    this.updateEmailForm = this.fb.group({
      new_email: [this.adminEmail, Validators.required],
    });
  }

  cancelProfile() {
    this.editUserEmail = false;
  }

  view() {

  }

  delete() {

  }

  edit() {

  }

}
