import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  standalone: true,
  selector: 'app-activated',
  templateUrl: './activated.component.html',
  imports: [
    TranslateModule
  ],
  styleUrls: ['./activated.component.scss']
})
export class ActivatedComponent implements OnInit {
  constructor(
    private userService: UserService,
  ) {}

  ngOnInit() {
    const baseUrl = document.URL;
    const values = baseUrl.split('?');
    const importantValues = values[1].split('&')

    const body = {
      temporary_user: true,
      activation_code: "",
      company: "",
      country: "",
      industry: "",
    };

    for(let i = 0; i < importantValues.length; i++)
    {
      const tempValues = importantValues[i].split('=');
      switch (tempValues[0])
      {
        case 'token':
          body.activation_code = tempValues[1];
          break;

        case 'company':
          body.company = tempValues[1];
          break;

        case 'country':
          body.country = tempValues[1];
          break;

        case 'industry':
          body.industry = tempValues[1];
          break;
      }
    }

    this.userService.activateAccount(body).subscribe({
    });
  }
}
