import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Template } from 'src/app/share/template';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { getToken, persistEnexisSetting } from 'src/app/share/utils';
import { ReportTemplateService } from 'src/app/services/report-template.service';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { DataService } from 'src/app/services/data.service';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import {ReportsService} from '../../../services/reports.service';
import {CommonModule} from '@angular/common';

export interface Criteria {
  criteria: string;
}

export interface PlaceHolder {
  name: string;
  value: string;
}

const ELEMENT_DATA: Criteria[] = [];
const RPH_ELEMENT_DATA: PlaceHolder[] = [];

@Component({
  standalone: true,
  imports: [TranslateModule,
    MessageBoxComponent,
    MatButtonModule,
    CommonModule
  ],
  selector: 'app-export-stedin-crit-dialog',
  templateUrl: './export-stedin-crit-dialog.component.html',
  styleUrls: ['./export-stedin-crit-dialog.component.scss', '../dialog-btns.component.scss']
})
export class ExportStedinCritDialogComponent implements OnInit {

  headers: string[] = [];

  crs: any[] = [
    {
      name: 'Geodetic',
      title: 'Geodetic',
      selected: false
    },
    {
      name: 'Lambert72',
      title: 'Lambert 72',
      selected: false
    },
    {
      name: 'Lambert2008',
      title: 'Lambert 2008',
      selected: false
    },
    {
      name: 'RDNAP',
      title: 'RD',
      selected: true
    },
    {
      name: 'UTM',
      title: 'UTM',
      selected: false
    },
    {
      name: 'BGS2005',
      title: 'BGS2005',
      selected: false
    }
  ];

  displayedColumns: string[] = ['select', 'criteria'];

  dataSource = new MatTableDataSource<Criteria>(ELEMENT_DATA);
  selection = new SelectionModel<Criteria>(true, []);
  @ViewChild(MatTable, {static: true}) criteriatable: MatTable<any>;

  rptdataSource = new MatTableDataSource<PlaceHolder>(RPH_ELEMENT_DATA);

  @ViewChild(MatTable, {static: true}) placeholderstable: MatTable<any>;

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  template: Template;
  criteriaForm: FormGroup;
  criteriaList: string[];
  selectedColumns: any[] = [];
  criteriaObjects: any[] = [];

  datesValues: any;
  criteriasArray: any[] = [] ;
  outPut: any =   'CSV';
  splitType: any = 'single_file';

  sortingType: any;
  sortingType2: any;
  sortingObject: any;

  templateId: string;
  selectedReportTemplate: any;
  reportsTemplates: any[];
  criteriaDelete: any[];
  value: any;
  pdfOrHtml: boolean;
  csvFile: boolean;
  templateloaded: boolean;
  templates = []

  publicEnexisReport: boolean = false;

  events: string[] = [];
  constructor(
    private fb: FormBuilder,
    private reportTempalteService: ReportTemplateService,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ExportStedinCritDialogComponent>,
    private reportsService: ReportsService,
    public toastService: ToastService,
  ) {
    if (data !== null) {
      this.publicEnexisReport = false;
      this.template = data.template;
    } else {
      const project = this.dataService.project;
      const token = getToken();
      this.publicEnexisReport = true;
      this.dataService.requestTemplates(token, project.id).subscribe(
        (res) => {
          this.templates = res;
        },
        (error) => {
          this.templates = [];
        }
      );
    }
    this.createForm();
  }

  createForm() {
    this.criteriaForm = this.fb.group({
      column_name: '',
      operator: '',
      column_value: '',
    });
  }

  closeDialog() {
    this.resetDialog();
    this.dialogRef.close();
  }

  eventListerner(event) {
    const key = event.key; // Or const {key} = event; in ES6+

    if (key === 'Escape') {
      this.closeDialog();
    }
  }

  ngOnInit() {
    document.addEventListener('keydown', event => this.eventListerner(event));

    this.pdfOrHtml = true;
    this.getUserReportTemplateById('6601abffe91d95587f6c563d');
  }

  resetDialog() {
    this.dataSource.data = [];
    this.initializeDialog();
    this.selection.clear();
  }

  initializeDialog() {
    this.dataSource.data = [];

    if (this.criteriatable) {
      this.criteriaDelete = [];
    }
    this.criteriaObjects = [];
    this.criteriaList = [];
    this.criteriasArray = [];
    this.selection.clear();
  }

  getReportTemplateById(chooseId) {
    this.reportsService.displayReportTemplatePlaceholders('');
    for (let i = 0; i < this.reportsTemplates.length; i++) {
      if (this.reportsTemplates[i].id === chooseId) {
        // alert(JSON.stringify(this.reportsTemplates[i]));
        this.selectedReportTemplate = this.reportsTemplates[i];
        // this.updatePlaceHolderTable(this.reportsTemplates[i]);
        break;
      }
    }
  }

  getUserReportTemplateById(templateId) {
    const token = getToken() || {};
    this.templateloaded = false;
    this.reportTempalteService.getReportTemplateById(templateId, token).subscribe(
      res => {
        this.reportsTemplates = [res];
        this.selectedReportTemplate = res;
        this.outReportTemplateChanged(templateId);
        this.templateloaded = true;
      },
      err => {
        this.templateloaded = true;
        this.toastService.errorToast(err);

      });
  }

  outReportTemplateChanged(selectedRepTem) {
    this.getReportTemplateById(selectedRepTem);
  }

  /**
   * building the object of the colums' name to show and the search criteria to apply to the data
   */
  complete_request(aggrBody) {
    let request_pipeline = {};

    request_pipeline = {
      "$lookup": {
          "from": "features",
          "let": {
              "templateIdStr": {
                "$toString": "$_id"
              }
          },
          "pipeline": [
              {
                "$match": {
                  "$expr": {
                    "$eq": [
                        "$template_id",
                        "$$templateIdStr"
                    ]
                  }
                }
              }
          ],
          "as": "relatedFeatures"
        }
    };

    const unwind = {
      "$unwind": "$relatedFeatures"
    };
    const resplaceroot = {
        "$replaceRoot": {
          "newRoot": "$relatedFeatures"
        }
    }
    aggrBody.push(request_pipeline);
    aggrBody.push(unwind);
    aggrBody.push(resplaceroot);
  }

  submitResearchData() {
    let outputType;
    let splitType;

    outputType = this.outPut;
    splitType = this.splitType;
    const project = this.dataService.project;

    const aggregateBody =[];
    const tempObj = {
      $match: {
        project_id: project.id,
      }
    };


    aggregateBody.push(tempObj);
    this.complete_request(aggregateBody)
    let reportTemplate = {};
    const placeHolder = {};
    const holders = [];

    let reportTemplatePlaceholders;

    if (this.selectedReportTemplate !== undefined && this.selectedReportTemplate !== null) {
      reportTemplatePlaceholders = this.selectedReportTemplate.place_holders;
      const data = this.rptdataSource.data;
      if (data !== null && data !== undefined) {
        for (const el of data) {
          const  key = el.name;
          let val = el.value;
          const obj = {};
          if (val === null || val === undefined || val === '') {
            val = '';
          }
          obj[key] = val;
          holders.push(obj);
        }
      }
      reportTemplate = {
        report_template_id: this.selectedReportTemplate.id
      };

    }

    const templateId = '';

    if(!this.selectedReportTemplate) {
      this.toastService.warningToast('Report template required');
      return;
    }

    const objectSend = {
      template: templateId,
      aggregateBody,
      outputType,
      splitType,
      report_template_id: this.selectedReportTemplate.id,
      report_template_rtid: "csv.public.stedin",
      place_holders: holders
    };

    persistEnexisSetting(holders)
    this.reportsService.lastExportedTemplate = objectSend;
    const result = {
      event: 'search',
      advanced_request: objectSend
    };
    this.resetDialog();
    this.dialogRef.close(result);
  }
}
