<div fxFlexLayout="row" style="margin-bottom: 0px !important;">
  <div fxFlex style="margin-bottom: 0px !important;">
    <h2><span>{{ "ENEXIS REPORT" | translate }}</span></h2>
    <hr />
    @if (showMessage) {
      <app-message-box #messageBox message="{{message}}"></app-message-box>
    }
    <span [hidden]="templateloaded">Loading report template ..., please wait </span>
    <div id="placeHoldersContainer">
      <mat-form-field class=" full-width">
        <input matInput autocomplete="off" [(ngModel)]="caseNumber" [placeholder]="'CASE NUMBER' | translate" type="text">
      </mat-form-field>
      <table mat-table [dataSource]="rptdataSource" class="table table-striped table-bordered full-width">
        <!-- place holder name Column -->
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef>
            <span class="ml-2"><span>{{ "PLACE HOLDER" | translate }}</span></span>
          </th>
          <td *matCellDef="let element; let i = index">
            <span matInput readonly="true" type="text">{{element.name}}</span>
          </td>
        </ng-container>

        <!-- place holder default value Column -->
        <ng-container matColumnDef="value">
          <th *matHeaderCellDef>
            <span class="ml-2"><span>{{ "VALUE" | translate }}</span></span>
          </th>
          <td *matCellDef="let element; let i = index">
            <input matInput autocomplete="off" #placeholdervalue (change)="updatePlaceHolderValue($event, element, placeholdervalue.value)" value="{{element.value}}" type="text">
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="rptdisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: rptdisplayedColumns;"></tr>
      </table>
    </div>

    <div class="button-group">
      <button mat-stroked-button class="mr-2 mt-2 submit-btn" type="submit" (click)="submitResearchData() "><span>{{ "EXPORT" | translate }}</span></button>
      <button mat-stroked-button class="mr-2 mt-2 cancel-btn" type="submit" (click)="closeDialog()"><span>{{ "CANCEL" | translate }}</span></button>
    </div>
  </div>
</div>
