import { Injectable } from '@angular/core';
import { Template } from '../share/template';
import { Observable } from 'rxjs';
import { map, catchError  } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { FeaturesResult,ListFeature, FeatureResult } from '../share/result';
import { Feature } from '../share/feature/feature';
import { API_URL, getPrivateApiAURL } from '../share/utils';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  constructor(
    private http: HttpClient,
    private processHttpMsgService: ProcessHttpmsgService) {
  }

  duplicateFeatures(features: any[], template: Template, project: any, token: any, pasteFeature = false) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': project.id
      })
    };

    const body = {
      feature_ids : features,
      paste_features: pasteFeature
    };
    return this.http.post<FeatureResult>(getPrivateApiAURL() + 'templates/' + template.id + '/features/duplicate', body, httpOptions)
    .pipe(map(res => {
      return res;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  deleteFeatures(features, template, project, token): Observable<Feature[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': project.id
      }),
      body: {
        ids: features
      }
    };
    return this.http.delete<FeaturesResult>(getPrivateApiAURL() + 'templates/' + template.id + '/features', httpOptions)
    .pipe(map(result => {
      return result.features.instances;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  updateFeature(feature, project, token): Observable<Feature> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': project.id
      })
    };

    return this.http.put<FeatureResult>(getPrivateApiAURL() + 'templates/' + feature.template_id + '/features/' + feature.id, feature, httpOptions)
    .pipe(map(res => {
      return res.feature;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getFeatures(token, uri, project, uriContainsVersion = false): Observable<ListFeature> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': project.id
      })
    };
    const url = uriContainsVersion ? API_URL + uri : getPrivateApiAURL() + uri;

    return this.http.get<FeaturesResult>(url, httpOptions)
    .pipe(map(result => {
      return result.features;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  aggregateSearch(token, request): Observable<ListFeature> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    return this.http.post<FeaturesResult>(getPrivateApiAURL() + 'templates/aggregationsearch', request, httpOptions).pipe(
      map(res => {
        return res.features;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  countFeatures(template, project, token): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': project.id
      })
    };
    return this.http.get<any>(getPrivateApiAURL() + 'templates/' + template.id + '/features/count/', httpOptions).pipe(
      map(res => {
        return res;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  textSearch(token, template, project, request): Observable<ListFeature> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain; charset=utf-8',
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': project.id
      })
    };
    return this.http.post<FeaturesResult>(getPrivateApiAURL() + 'templates/' + template.id + '/features/textsearch', request, httpOptions).pipe(
      map(res => {
        return res.features;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }
}
