import { TranslateModule } from '@ngx-translate/core';
import {Component, OnInit, Input, ViewChild, Inject, HostListener} from '@angular/core';
import { PhotoField } from 'src/app/share/form/photo-field';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { FieldSet } from 'src/app/share/form/fieldset';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import { StoreService } from 'src/app/services/store.service';
import { isFieldAlreadyExist, removeSpecialCharacters } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import {DialogActionsComponent} from '../dialog-actions/dialog-actions.component';

@Component({
  standalone: true,
    imports: [TranslateModule,
        ReactiveFormsModule,
        MessageBoxComponent, MatDialogContent,
        MatFormFieldModule,
        MatInputModule,
        MatOption,
        MatDialogActions,
        MatSelectModule,
        MatCheckboxModule,
        MatDialogModule,
        MatButtonModule, DialogActionsComponent,
    ],
  selector: 'app-create-photo-field',
  templateUrl: './create-photo-field.component.html',
  styleUrls: ['./create-photo-field.component.scss', '../dialog-btns.component.scss']
})
export class CreatePhotoFieldComponent {

  photoField: PhotoField;
  photoFieldForm: FormGroup;
  @Input() parent: FieldSet;
  @Input() mode = {
    text: 'Create',
    function: 'Create'
  };
  photoSizeList = [
    {text: 'Small', value: 'small'},
    {text: 'Medium', value: 'medium'},
    {text: 'Large', value: 'large'},
    {text: 'Full Size', value: 'full_size'}
  ];
  photoQualityList = [
    {text: 'Low', value: 'low'},
    {text: 'Medium', value: 'medium'},
    {text: 'High', value: 'high'},
    {text: 'Maximum', value: 'maximum'}
  ];

  @ViewChild('fform') photoFieldFormDirective;
  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  nameLengthExceed10 = false;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CreatePhotoFieldComponent>,
    public toastService: ToastService,
  ) {
    if (data !== undefined && data !== null) {
      this.parent = data.parent;
      this.mode = data.mode;
      this.photoField = data.field;
    }
    this.createForm();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  createForm() {
    if (this.mode.function === 'Create') {
      this.photoFieldForm = this.fb.group({
        name: ['', Validators.required],
        label: ['', Validators.required],
        description: '',
        enabled: true,
        required: false,
        photo_size: 'medium',
        photo_quality: 'medium'
      });
    } else {
      this.photoFieldForm = this.fb.group({
        name: [this.photoField.name, Validators.required],
        label: [this.photoField.label, Validators.required],
        description: this.photoField.description,
        enabled: this.photoField.enabled,
        required: this.photoField.required,
        photo_size: this.photoField.photo_size,
        photo_quality: this.photoField.photo_quality
      });
    }
  }

  onNameChange() {
    if (this.photoFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  onLabelChange() {
    this.photoFieldForm.controls.name.setValue(removeSpecialCharacters(this.photoFieldForm.value.label));
    if (this.photoFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  closeDialog(event) {
    event.preventDefault();
    const result =  {
      action: 'Cancel'
    };
    this.dialogRef.close(result);
    event.stopPropagation();
  }

  onSubmit() {
    if (this.photoField) {
      this.photoField = {
        ...this.photoField,
        ...this.photoFieldForm.value
      };
    } else {
      this.photoField = this.photoFieldForm.value;
    }
    // this.photoField = this.photoFieldForm.value;
    this.photoField._class = 'photofield';


    if (this.photoField.name === this.parent.name) {
      this.toastService.errorToast('Field cannot take the same name as the active fieldset');
      return;
    }

    if (this.mode.function === 'Create') {
      if (isFieldAlreadyExist(this.photoField, this.parent)) {
        this.toastService.errorToast('Another field with the same name/label already exists');
          return;
      }
      this.store.addField(this.photoField);
    } else {
      this.store.updateField(this.photoField);
    }

    const result = {
      action: 'Create',
      field: this.photoField
    };
    if (this.mode.function === 'Create') {
      // this.parent.fields.push(this.stringField);
    } else {
      result.action = 'Update';
    }
    this.dialogRef.close(result);
  }

  protected readonly open = open;
}
