<div id="projectsContainer" [style.height.px]="scrHeight" class="row">
  @if (projects) {
    <div id="projectsCont" class="projects-content" [hidden]="store?.hideProjectsContainer">
      <div class="column">
        <div id="projectsContent">
          @for (p of projects; track p) {
            <app-project-item id="projectItem" (click)="onSelected(p, $event)" [project]="p"></app-project-item>
          }
        </div>
      </div>
    </div>
  }

  @if (store?.listFoundTemplates !== null && store?.listFoundTemplates.length > 0) {
    <div id="templatesContent" class="items-container templates-content" [hidden]="store?.hideTemplatesContainer">
      <div class="column">
        <div id="templatesitems">
          @for (t of store?.listFoundTemplates; track t) {
            <app-template-item (click)="onSelectedTemplate(t, $event)" [template]="t"></app-template-item>
          }
        </div>
      </div>
    </div>
  }
  
  @if (store?.listFoundFeatures && store?.listFoundFeatures.length > 0) {
    <div class="projectTemplatesContainer">
      <app-found-features [template]="template"></app-found-features>
    </div>
  }
</div>
