import {Injectable, EventEmitter, ElementRef, ViewChild} from '@angular/core';
import { Token } from '../share/token';
import { Project, ProjectToCopy, ProjectToDuplicate } from '../share/projects';
import {BehaviorSubject, firstValueFrom, Observable, Subject} from 'rxjs';
import { map, catchError  } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import {ProjectsResult, ProjectResult, ProjectsOfCollaborators, SearchResult, TemplateResult, TemplatesResult} from '../share/result';
import {
  getToken,
  getPrivateApiAURL,
  getAdminToken,
  removeFieldToShowOnMap,
  removeSearchValue,
  removeCurrentFeaturesUri,
  removeGeomFormToShow,
  persistGeomFormToShow, url, persistLastMenu, getFieldToShowOnMap, persistFieldToShowOnMap, getUser
} from '../share/utils';
import {ConfirmationDialogComponent} from '../components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {DATA_MODE, MAP_MODE, SCHEMA_MODE, StoreService} from './store.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Template, TemplateToDuplicate} from '../share/template';
import {FieldSet} from '../share/form/fieldset';
import {ToastService} from './toast.service';
import {UnsavedChangesDialogComponent} from '../components/dialogs/unsaved-changes-dialog/unsaved-changes-dialog.component';
import {AttributeSet} from '../share/feature/attributes';
import {ReportsService} from './reports.service';
import {TranslateService} from '@ngx-translate/core';
import {MediaShowingDialogComponent} from '../components/dialogs/media-showing-dialog/media-showing-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public projectAdded: EventEmitter<Project>;
  public projectUpdated: EventEmitter<Project>;
  public projectDeleted: EventEmitter<Project>;
  updatingForm: boolean;
  currentProject: Project;

  message = ' ';
  proClass: string = null;

  constructor(
    private http: HttpClient,
    public store: StoreService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private toastService: ToastService,
    private processHttpMsgService: ProcessHttpmsgService) {
    this.initializeTemplateService();
    this.initializeProjectService();

    this.projectAdded = new EventEmitter();
    this.projectUpdated = new EventEmitter();
    this.projectDeleted = new EventEmitter();
  }

  initializeTemplateService() {
    this.templateInfo = false;
    this.templateData = false;
    this.templateDataMap = false;
    this.importtemplateData = false;
    this.recordtemplateData = false;
    this.template = new BehaviorSubject<Template>(null);
    this.selectedTemplate = new BehaviorSubject<string>('');
    this.templates = new BehaviorSubject<Template[]>(null);
  }

  initializeProjectService() {
    this.project = null;
    this.projects = null;
  }

  public deleteProject(project, proToken: ProjectToken, isMyCumulusAdministrator = false): Observable<Project> {
    let httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': proToken.value
      }),
      params: new HttpParams({

      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'X-Admin-Auth-Token': proToken.value
        }),

        params: new HttpParams({

        })
      };
    }
    // httpOptions.headers.append(proToken.key, proToken.value);
    return this.http.delete<ProjectResult>(getPrivateApiAURL() + 'projects/' + project.id, httpOptions)
    .pipe(map(
      res => {
      return res.project;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  updateProject(project, proToken: ProjectToken, isMyCumulusAdministrator = false): Observable<Project> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': proToken.value
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Admin-Auth-Token': proToken.value
        })
      };
    }

    return this.http.put<ProjectResult>(getPrivateApiAURL() + 'projects/' + project.id,
              project, httpOptions).pipe(map(
      res => {
      return res.project;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  createProject(project, proToken: ProjectToken, isMyCumulusAdministrator = false): Observable<Project> {
    let httpOptions = {};
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Admin-Auth-Token': proToken.value
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Auth-Token': proToken.value
        })
      };
    }

    // httpOptions.headers.append(proToken.key, proToken.value);
    return this.http.post<ProjectResult>(getPrivateApiAURL() + 'projects', project, httpOptions)
    .pipe(map(
      res => {
      return res.project;
    }))
    .pipe(
      catchError(this.processHttpMsgService.handleError)
      );

    /*
    .pipe(
      map(
        result => {
          // this.add(result.project);
          return result.project;
        })
    )
    */
  }

  searchProject(strValue: string, token: Token): Observable<SearchResult> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain; charset=utf-8',
        'X-Auth-Token': token.key
      })
    };

    return this.http.post<SearchResult>(getPrivateApiAURL() + 'globalsearch/features', strValue, httpOptions)
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getProject(project, proToken: ProjectToken, isMyCumulusAdministrator=false): Observable<Project> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': proToken.value
      }),
      params: new HttpParams({

      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Admin-Auth-Token': proToken.value
        }),

        params: new HttpParams({

        })
      };
    }

    // httpOptions.headers.append(proToken.key, proToken.value);
    return this.http.post<Project>(getPrivateApiAURL() + 'projects' + project.id, httpOptions)
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getProjects(proToken: ProjectToken, proClass, visibility, archived, isMyCumulusAdministrator = false): Observable<Project[]> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': proToken.value
      }),
      params: new HttpParams()
        .set('proClass', proClass)
        .set('visibility', visibility)
        .set('archived', archived)
        .set('perPage', '500')
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Admin-Auth-Token': proToken.value
        }),
        params: new HttpParams()
          .set('proClass', proClass)
          .set('visibility', visibility)
          .set('archived', archived)
          .set('perPage', '500')
      };
    }

    return this.http.get<ProjectsResult>(getPrivateApiAURL() + 'projects', httpOptions)
    .pipe(map(result => {
      return result.projects.instances;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getCollaboratorProjects(token, proClass, visibility, archived): Observable<Project[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      }),
      params: new HttpParams()
        .set('proClass', proClass)
        .set('visibility', visibility)
        .set('archived', archived)
        .set('perPage', '500')
    };

    return this.http.get<ProjectsOfCollaborators>(getPrivateApiAURL() + 'projects', httpOptions)
    .pipe(map(result => {
      if(result['projects']) {
        return result['projects'].instances;
      } else {
        return result.project_members.instances;
      }

    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  duplicateProject(project: ProjectToDuplicate, proToken: ProjectToken, isMyCumulusAdministrator = false): Observable<Project> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': proToken.value
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Admin-Auth-Token': proToken.value
        })
      };
    }
    // httpOptions.headers.append(proToken.key, proToken.value);
    return this.http.post<ProjectResult>(getPrivateApiAURL() + 'project/duplicate', project, httpOptions)
    .pipe(map(
      res => {
      return res.project;
    }))
    .pipe(
      catchError(this.processHttpMsgService.handleError)
      );
  }

  copyProjectFromTemplate(project: ProjectToCopy, proToken: ProjectToken, isMyCumulusAdministrator = false): Observable<Project> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': proToken.value
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Admin-Auth-Token': proToken.value
        })
      };
    }
    // httpOptions.headers.append(proToken.key, proToken.value);
    return this.http.post<ProjectResult>(getPrivateApiAURL() + 'project/template/copy', project, httpOptions)
    .pipe(map(
      res => {
      return res.project;
    }))
    .pipe(
      catchError(this.processHttpMsgService.handleError)
      );
  }

  public list(): Project[] {
    return this.projects;
  }

  private add(project: Project) {
    this.projects.push(project);
    this.projectAdded.emit(project);
  }

  public update(project: Project) {
    const projectIndex = this.projects.indexOf(project);
    // Update element project
  }

  public delete(project: Project) {
    // Remove element from the list of project
  }

  selectProject(p): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const templ = this.template.getValue();

        if (templ && !templ.form) {
          const message = `The form "${templ.name}" should contain at least one field. \nContinue?`;
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '400px',
            data: { title: 'MyCumulus', message }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.openTemplatePage(p).then(() => resolve());
            } else {
              const olp = this.project;
              this.store.selectedProject.next(olp.name);
              resolve();  // Resolve if the user declines
            }
          });
        } else {
          this.updatingForm = this.store.updatedForm;
          if (this.updatingForm) {
            if (templ === null || templ === undefined) {
              const dlgRef = this.dialog.open(ConfirmationDialogComponent, {
                width: '400px',
                data: {
                  title: this.translate.instant("CANCEL IMPORT?"),
                  message: ''
                }
              });

              dlgRef.afterClosed().subscribe(result => {
                if (result) {

                  this.store.updatedForm = false;
                  this.store.changeFormUpdatingState(false);
                  this.openTemplatePage(p).then(() => resolve());
                } else {
                  resolve();  // Resolve if the user cancels
                }
              });
            } else {
              const dialogRef = this.dialog.open(UnsavedChangesDialogComponent, {
                data: {
                  message: this.translate.instant("SOME CHANGES TO THE FORM") + " " + templ.name + " " + this.translate.instant("HAVE BEEN MADE") + ". \n" + this.translate.instant("DO YOU WANT TO CONTINUE WITHOUT SAVING THE CHANGES?"),
                  title: this.translate.instant('SAVE CHANGES?')
                }
              });

              dialogRef.afterClosed().subscribe(result => {
                if (result) {

                  this.store.updatedForm = false;
                  this.store.changeFormUpdatingState(false);
                  this.openTemplatePage(p).then(() => resolve());
                } else {
                  const olp = this.project;
                  this.store.selectedProject.next(olp.name);
                  resolve();  // Resolve if user doesn't save
                }
              });
            }
          } else {
            this.openTemplatePage(p).then(() => resolve());
          }
        }
      } catch (error) {
        reject(error);
      }
    });
  }


  getTemplates(p): Promise<void> {
    return new Promise(async (resolve, reject) => {
      let isMyCumulusAdministrator = false;
      let token = getToken();
      if (token === null || token === undefined) {
        token = getAdminToken();
        isMyCumulusAdministrator = true;
      }

      this.store.showLoading();

      try {
        // Using firstValueFrom to convert observable to a promise
        const res = await firstValueFrom(this.requestTemplates(token, p.id, isMyCumulusAdministrator));

        // After getting response, update templates
        this.readTemplates(res);
        this.saveDefaultFormsToShow();
        this.store.hideLoading();
        resolve();  // Resolve the promise once the templates are processed
      } catch (errmess) {
        this.store.hideLoading();
        this.showMessageDialog(errmess);
        reject(errmess);  // Reject in case of an error
      }
    });
  }


  showMessageDialog(msg: string) {
    this.message = msg;
    this.store.showMessage = true;
    setTimeout(() => {
      this.store.showMessage = false;
    }, (10000));
  }

  openTemplatePage(p): Promise<void> {
    return new Promise((resolve) => {
      // Clear various states

      removeFieldToShowOnMap();
      // this.reportsService.lastExportedTemplate = null;
      removeSearchValue();
      removeCurrentFeaturesUri();
      this.store.features = null;

      // Update the current project
      this.currentProject = p;
      this.readProject(p);

      // Reset template-related states
      this.template.next(null);
      this.selectedTemplate.next('');
      this.store.selectedFieldSet.next('');

      if (p !== null && p !== undefined) {
        // Check if getTemplates and showTemplates are asynchronous
        this.getTemplates(p).then(() => {
          this.showTemplates();
          resolve(); // Resolve the promise after templates are shown
        }).catch(() => {
          resolve(); // Resolve even if there's an error to avoid hanging
        });
      } else {
        this.hideTemplate();
        resolve(); // Resolve immediately if no project is provided
      }
    });
  }

  saveDefaultFormsToShow() {
    const labels = [];

    for (const key of this.templates.getValue()) {
      labels.push(key['id']);
    }
    const obj = {};
    if(labels && labels.length > 0) {
      for(const templName of labels) {
        obj[templName] = true;
      }
      removeGeomFormToShow();
      if(obj) {
        persistGeomFormToShow(obj);
      }
    }
  }

  setCircleLetters(item: Project | Template) {
    if (item.image_file_url) {
      return url(item.image_file_url);
    }

    // Regex to detect emojis
    const emojiRegex = /(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)/u;

    if(item.name) {
      const firstTwoChars = item.name.slice(0, 2) || "";

      if (emojiRegex.test(firstTwoChars)) {
        return firstTwoChars;
      }
      return item.name.trim()[0];
    }

    return "";
  }

  checkIfTime(item: any): boolean {
    if (typeof item !== 'string') {
      return false;
    }
    const timeRegex = /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;

    return timeRegex.test(item);
  }

  checkIfDate(value: any): boolean {
    if (typeof value !== 'string') {
      return false;
    }

    // Regular expressions for different date formats

    // ISO 8601 date format (YYYY-MM-DD)
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;

    // ISO 8601 datetime formats (with optional fractional seconds and timezone offset)
    const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(Z|[\+\-]\d{2}:\d{2})?$/;

    // US date format (MM/DD/YYYY)
    const usDateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

    // European date format (DD/MM/YYYY)
    const euDateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

    // Combine all regex patterns using alternation
    const dateRegex = new RegExp(
      `(${isoDateRegex.source})|(${isoDateTimeRegex.source})|(${usDateRegex.source})|(${euDateRegex.source})`
    );

    if (!dateRegex.test(value)) {
      return false;
    }

    // Attempt to parse the date
    const date = new Date(value);

    // Check if the date is valid
    return !isNaN(date.getTime());
  }


  //Template
  deleteTemplate(template, projectId, token, isMyCumulusAdministrator = false): Observable<Template> {
    let httpOptions = {
      headers: new HttpHeaders({
        'X-Working-Project-Id': projectId,
        'X-Auth-Token': token.key
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'X-Working-Project-Id': projectId,
          'X-Admin-Auth-Token': token.key
        })
      };
    }

    return this.http.delete<TemplateResult>(getPrivateApiAURL() + 'templates/' + template.id, httpOptions).pipe(
      map(res => {
        return res.template;
      })
    )
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  updateTemplate(template, projectId, token: TemplateToken, isMyCumulusAdministrator=false): Observable<Template> {
    let httpOptions = {
      headers: new HttpHeaders({
        'X-Working-Project-Id': projectId,
        'X-Auth-Token': token.value
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'X-Working-Project-Id': projectId,
          'X-Admin-Auth-Token': token.value
        })
      };
    }

    return this.http.put<TemplateResult>(getPrivateApiAURL() + 'templates/' + template.id, template, httpOptions)
      .pipe(
        map(res => {
          return res.template;
        })
      )
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  createTemplate(template, projectId, token: TemplateToken, isMyCumulusAdministrator=false): Observable<Template> {
    let httpOptions = {
      headers: new HttpHeaders({
        'X-Working-Project-Id': projectId,
        'X-Auth-Token': token.value
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'X-Working-Project-Id': projectId,
          'X-Admin-Auth-Token': token.value
        })
      };
    }

    return this.http.post<TemplateResult>(getPrivateApiAURL() + 'templates', template, httpOptions).pipe(
      map(res => {
        return res.template;
      })
    )
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  importDataInTemplate(data, token, projectId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': projectId
      })
    };

    return this.http.post<TemplateResult>(getPrivateApiAURL() + 'imports/data', data, httpOptions).pipe(
      map(res => {
        if (res['template']) {
          return res['template'];
        } else if (res['features']) {
          return res['features'];
        }
      })
    )
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  importIKMLFile(data, token, projectId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': projectId
      })
    };

    return this.http.post<any>(getPrivateApiAURL() + 'imports/imkl', data, httpOptions).pipe(
      map(res => {
        return res;
      })
    )
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  importShapeInTemplate(data, token, projectId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': projectId
      })
    };

    return this.http.post<TemplateResult>(getPrivateApiAURL() + 'imports/shape', data, httpOptions).pipe(
      map(res => {
        return res;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }


  //FLAW REQUEST
  requestTemplates(token, projectId, isMyCumulusAdministrator = false): Observable<Template[]> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Working-Project-Id': projectId,
        'X-Auth-Token': token.key
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Working-Project-Id': projectId,
          'X-Admin-Auth-Token': token.key
        })
      };
    }

    return this.http.get<TemplatesResult>(getPrivateApiAURL() + 'projects/' + projectId + '/templates', httpOptions)
      .pipe(map(result => {
        return result.templates.instances;
      }))
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  duplicateTemplate(template: TemplateToDuplicate, token: TemplateToken, isMyCumulusAdministrator = false): Observable<Template> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Working-Project-Id': template.project_id,
        'X-Auth-Token': token.value
      })
    };
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Working-Project-Id': template.project_id,
          'X-Admin-Auth-Token': token.value
        })
      };
    }

    return this.http.post<TemplateResult>(getPrivateApiAURL() + 'template/duplicate', template, httpOptions).pipe(
      map(res => {
        return res.template;
      })
    )
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  copyFromDomain(form, token): Observable<Template> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };

    return this.http.post<TemplateResult>(getPrivateApiAURL() + 'form/template/copy', form, httpOptions).pipe(
      map(res => {
        return res.template;
      })
    )
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  private eventSource = new Subject<any>();
  event$ = this.eventSource.asObservable();

  emitEvent(data: any, parent: any) {
    this.eventSource.next({data: data, parent: parent});  // Emit event data to all subscribers
  }

  buildFeatureSchema() {
    const rootFS = this.store.transformToFields(this.store.changedRoot) as FieldSet
    let activeTemplate = this.template.getValue();

    if (activeTemplate.form) {
      activeTemplate.form.fields = rootFS.fields;
    } else {
      const form = {
        fields: rootFS.fields
      };
      activeTemplate.form = form;
    }
    const set = this.buildAttributeSet(activeTemplate.form.fields);

    activeTemplate.feature.attributes = set.attributes;
    this.store.showLoading();
    let token = getToken();
    let tempToken = {
      key: 'X-Auth-Token',
      value: ''
    };
    let isMyCumulusAdministrator = false;
    if (token !== null) {
      tempToken = {
        key: 'X-Auth-Token',
        value: token.key
      };
    } else {
      token = getAdminToken();
      if(token !== null) {
        tempToken = {
          key: 'X-Admin-Auth-Token',
          value: token.key
        };
        isMyCumulusAdministrator = true;
      }
    }

    this.updateTemplate(activeTemplate, activeTemplate.project_id, tempToken, isMyCumulusAdministrator).subscribe(
      res => {

        this.updateTemplateLocally(res);
        // this.initializeTemplateAndField(res);
        this.store.changeFormUpdatingState(false);
        this.store.hideLoading();
        this.toastService.successToast('Changes to schema have been saved')
      },
      err => {
        if (err === 'feature structure are empty.') {
          this.toastService.errorToast('The form must contain at least one field')
        } else {
          this.toastService.errorToast(err)
        }
        this.store.hideLoading();
      });
  }

  buildAttributeSet(fields, attributesettype = '') {
    const set_type = attributesettype !== '' ? attributesettype : 'attributeset';
    const set = {
      _class: set_type,
      attributes: []
    };

    fields.map((field) => {
      if (field._class === 'fieldset') {
        let attributeset = this.buildAttributeSet(field.fields);
        attributeset = Object.assign({}, attributeset, {
          ...attributeset,
          name: field.name
        });

        set.attributes.push(attributeset);

      } else if (field._class === 'arrayfieldset') {
        let arrayattributeset = this.buildAttributeSet(field.fields, 'arrayattributeset');
        arrayattributeset = Object.assign({}, arrayattributeset, {
          ...arrayattributeset,
          name: field.name
        });

        set.attributes.push(arrayattributeset);
      } else {
        let attribute = {
          _class: 'attribute'
        };

        attribute = Object.assign({}, attribute, {
          ...attribute,
          name: field.name
        });
        // attribute.name = field.name;

        switch (field._class) {
          case 'autoincrementintegerfield':
          case 'integerfield':
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'integer'
            });
            break;

          case 'decimalfield':
            // attribute.type = 'double';
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'double'
            });
            break;

          case 'booleanfield':
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'boolean'
            });
            // attribute.type = 'boolean';
            break;

          case 'photofield':
          case 'videofield':
          case 'audiofield':
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'file'
            });
            // attribute.type = 'file';
            break;

          case 'datefield':
            // attribute.type = 'date';
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'date'
            });
            break;

          case 'timefield':
            // attribute.type = 'time';
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'time'
            });
            break;

          case 'gpscoordinatesfield':
            // attribute.type = 'coordinates';
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'coordinates'
            });
            break;

          case 'arrayfield':
            // attribute.type = 'array';
            // attribute._class = 'arrayattribute';
            // attribute.element_type = field.element_type;
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'array',
              _class: 'arrayattribute',
              element_type: field.element_type
            });
            break;

          default:
            // attribute.type = 'string';
            attribute = Object.assign({}, attribute, {
              ...attribute,
              type: 'string'
            });
            break;
        }
        attribute['restrictions'] = [];
        set.attributes.push(attribute);
      }
    });

    return set;
  }

  //NewFunctionalityFromTemplates
  private itemTriggerSearch = new Subject<any>();

  // Observable that components can subscribe to
  triggerSearch$ = this.itemTriggerSearch.asObservable();

  // Method to trigger the event
  triggerSearch(value: any) {
    this.itemTriggerSearch.next(value);
  }

  selectTemplate(template: Template) {
    if(template === null) {
      this.selectedTemplate = new BehaviorSubject<string>('');
    }
    else {
      this.checkIfFormIsUpdated(() => {
        this.onSelectedTemplateAfterCheck(template);
      });

      this.selectedTemplate.next(template.name);
    }
  }

  selectingTemplate = false;

  onSelectedTemplateAfterCheck(template: Template) {
    this.selectingTemplate = true;
    this.store.features = [];

    this.readTemplate(template);
    const obj = {};
    let templId: string = "0";

    if(template) {
      templId = template['id'];
      obj[templId] = true;
    }

    if(obj) {
      removeGeomFormToShow();
      persistGeomFormToShow(obj);
    }
    this.store.features = null;
    let menu = this.store.formMode;
    if(this.store.proClass === "template" || this.store.proClass === "domain") {
      menu = SCHEMA_MODE;
    } else if(template && (template.feature.geometry_type === null || template.feature.geometry_type === undefined)) {
      menu = DATA_MODE;
    }
    // this.reportsService.lastExportedTemplate = null;
    removeSearchValue();
    removeCurrentFeaturesUri();

    if (menu === SCHEMA_MODE) {
      this.showTemplateInfo();
    } else if (menu === MAP_MODE) {
      this.hideTemplate();
      this.openMapData();
    } else {
      this.openFeatureData();
      if(this.store.copiedFeatures && this.store.copiedFeatures.length > 0) {
        this.store.destinationTemplate = template;
      }
    }
  }

  openFeatureData() {
    if(this.store.proClass === "template" || this.store.proClass === "domain") {
      return;
    }
    this.testIfUpdatingForm(() => {
      this.openFeatureDataAfterCheck();
    });

  }

  openMapData() {
    if(this.store.proClass === "template" || this.store.proClass === "domain") {
      return;
    }
    this.testIfUpdatingForm(() => {
      this.openMapDataAfterCheck();
    });

  }

  openTemplateSchema(event) {
    event.stopPropagation();
    this.testIfUpdatingForm(() => {
      if (getUser().id === this.project.created_by) {
        this.openFormSchemaAfterCheck();
      }
    });
  }

  checkIfFormIsUpdated(action) {
    const templ = this.template.getValue();
    const updatedForm = this.store.updatedForm;
    if ((templ) && (updatedForm)) {
      const dialogRef = this.dialog.open(UnsavedChangesDialogComponent, {
        data: {
          formName: templ.name,
        }
      });

      dialogRef.afterClosed().subscribe(result  => {
        if (result) {

          this.store.updatedForm = false;
          this.store.changeFormUpdatingState(false);
          action();
        } else {
          this.selectedTemplate.next(templ.name);
        }
      });
    } else {
      action();
    }
  }

  //Migration From Store (Templates)
  templates: BehaviorSubject<Template[]>;
  template: BehaviorSubject<Template>;
  selectedTemplate: BehaviorSubject<string>;
  templateInfo: boolean;
  templateData: boolean;
  importtemplateData: boolean;
  recordtemplateData: boolean;
  templateDataMap: boolean;


  createTemplateLocally(newTem) {
    if (!this.templates.getValue().find( t => t.id === newTem.id)) {
      this.templates.next(this.templates.getValue().concat(Object.assign({}, newTem)));
      this.selectTemplate(newTem);
      this.templates.getValue().sort(
        (a, b) => {
          return this.store.equalsIgnoringCase(a.name, b.name);
        });
    }

    this.showTemplateFeatureData();
  }

  showTemplateFeatureData() {
    this.hideTemplate();
    this.templateData = true;
  }

  updateTemplateLocally(newTemplate) {
    const templ = this.templates.getValue().find(t => t.id === newTemplate.id);
    const index = this.templates.getValue().indexOf(templ);
    this.template.next(newTemplate);
    this.templates.next([
      ...this.templates.getValue().slice(0, index),
      Object.assign({}, newTemplate),
      ...this.templates.getValue().slice(index + 1)
    ].sort(
      (a, b) => {
        return this.store.equalsIgnoringCase(a.name,  b.name);
      }));
    this.readTemplate(newTemplate);
  }

  readTemplates(res) {
    this.templates.next(res.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })));
  }

  readTemplate(templ) {
    this.template.next(templ);
    let fields = [];
    if (templ !== null && templ !== undefined && templ.form !== undefined && templ.form !== null) {
      fields = templ.form.fields;
    }
    const parentFieldSet = {
      ...this.store.parentFieldSet,
      fields
    };
    this.store.parentFieldSet = parentFieldSet;
    this.store.fieldSet = {
      ...this.store.fieldSet,
      fields
    };
    this.store.fields = fields;
  }

  deleteTemplateLocally(t) {
    this.templates.next(this.templates.getValue().filter(temp => temp.id !== t.id));
    this.template.next(null);
    this.showTemplates();
  }

  showTemplates() {
    this.templateInfo       = false;
    this.templateData       = false;
    this.templateDataMap    = false;
    this.recordtemplateData = false;
    this.importtemplateData = false;
    this.store.showmultipleformsonmap = false;
  }

  isSelectedTemplate(t): boolean {
    if (t.name === this.selectedTemplate.value) {
      return true;
    }
    return false;
  }

  hideTemplate() {
    this.templateInfo = false;
    this.templateData = false;
    this.templateDataMap = false;
    this.importtemplateData = false;
    this.recordtemplateData = false;
    this.store.showmultipleformsonmap = false;
    this.store.importIKMLFile = false;
    this.store.importExcelFile = false;
    this.store.importShapeFile = false;
    this.store.customExport.next(false);
  }

  showTemplateInfo() {
    this.hideTemplate();
    this.templateInfo = true;
    persistLastMenu('info');
  }

  showImportDataInTemplate() {
    this.hideTemplate();
    this.importtemplateData = true;
  }

  showImportIMKLData(typeOfFile) {
    this.store.typeOfIMKLFile.next(typeOfFile);
    this.hideTemplate();

    this.store.importIKMLFile = true;
  }
  showImportExcelData(typeOfFile) {
    this.store.typeOfExcelFile.next(typeOfFile);
    this.hideTemplate();

    this.store.importExcelFile = true;
  }



  showImportShapeFile(typeOfFile) {
    this.store.typeOfShapeFile.next(typeOfFile);
    this.hideTemplate();
    this.store.importShapeFile = true;
  }

  showMultipleFormsOnMap() {
    this.hideTemplate();
    this.store.showmultipleformsonmap = true;
  }

  //Migration From Store(Project)
  projects: Project[];
  project: Project;

  readProjects(projects: Project[]) {
    if(projects && projects.length === 0) {
      this.projects = projects || [];
      return this.projects;
    }
    this.projects = projects.sort(
      (a, b) => {
        return this.store.equalsIgnoringCase(a.name, b.name);
      });
  }

  readProject(p: Project) {
    this.project = p;
  }

  createDuplicateProject(p: Project) {
    this.projects.push(p);
    this.selectProject(p);
    this.projects = this.projects.sort(
      (a, b) => {
        return this.store.equalsIgnoringCase(a.name, b.name);
      });
  }

  createProjectLocally(p) {
    this.projects.push(p);
    this.projects = this.projects.sort(
      (a, b) => {
        /*if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;*/
        return this.store.equalsIgnoringCase(a.name, b.name);
      });
    this.readProject(p);
  }

  updateProjectLocally(proj) {
    this.project = this.projects.find(p => p.id === proj.id);
    const index = this.projects.indexOf(this.project);
    // this.projects = [];
    this.projects = [
      ...this.projects.slice(0, index),
      Object.assign({}, proj),
      ...this.projects.slice(index + 1)
    ].sort(
      (a, b) => {
        return this.store.equalsIgnoringCase(a.name, b.name);
      });
    // this.readProject(proj);
  }

  deleteProjectLocally(p) {
    this.projects = this.projects.filter(project => p.id !== project.id);
    this.project = null;
    this.templates.next(null);
    this.templateInfo = false;
    this.templateData = false;
    this.templateDataMap = false;
    this.template.next(null);
  }

  showTemplateFeatureDataMap() {
    this.hideTemplate();

    // this.templateDataMap = true;
    this.store.showmultipleformsonmap = true;
  }

  //Migration From HeaderService
  fields: any;

  openFeatureDataAfterCheck() {
    // TODO show template feature data
    persistLastMenu('data');
    this.showTemplateFeatureData();
    this.store.formMode = DATA_MODE;
  }

  openMapDataAfterCheck() {
    const selectedTemplate = this.template.getValue();
    const fields = getFieldToShowOnMap();

    if (fields && fields[0].template_to_show === this.template.getValue().id) {
      this.showTemplateFeatureDataMap();
      this.store.formMode = MAP_MODE;
      return;
    } else {
      if(selectedTemplate) {
        const obj = {};
        const templName = selectedTemplate['id'];
        obj[templName] = true;

        if(obj) {
          removeGeomFormToShow();
          persistGeomFormToShow(obj);
        }

        this.saveFieldsAndLabels(this.template.getValue().feature.attributes);
        const labels = [];
        const keys = Object.keys(this.fields);
        for (const key of keys) {
          const value = this.fields[key];
          if (value['template_to_show'] !== null) {
            const fieldname = value['label'];
            labels.push(fieldname);
          }
        }
        this.store.setFieldToShowOnMap(labels);
        persistFieldToShowOnMap(this.fields);
      }
    }
    persistLastMenu('map');
    this.store.formMode = MAP_MODE;
    this.showTemplateFeatureDataMap();
  }

  saveFieldsAndLabels(attributes: any[]) {
    this.fields = [];
    this.fields.push({
      template_to_show: this.template.getValue().id
    });
    this.saveFields(attributes);
  }

  saveFields(attributes: any, parent= '') {
    const name = 'name';
    const _class = '_class';
    for (const attribute of attributes) {
      const typeAttr = attribute[_class];
      if (typeAttr === 'attribute') {
        const value = parent === '' ? attribute[name] : `${parent}.${attribute[name]}`;
        const label = {
          label: value
        };
        this.fields.push(label);
      } else if (typeAttr === 'attributeset') {
        const nested_parent = parent === '' ? attribute[name] : `${parent}.${attribute[name]}`;
        this.saveFields((attribute as AttributeSet).attributes, nested_parent);
      }
    }
  }

  testIfUpdatingForm(action) {
    // this.updatingForm = getFormUpdating();
    this.updatingForm = this.store.updatedForm;
    if (this.updatingForm !== undefined &&
      this.updatingForm !== null &&
      this.updatingForm === true) {
      const templ = this.template.getValue();
      const dialogRef = this.dialog.open(UnsavedChangesDialogComponent, {
        data: {
          message: this.translate.instant("THERE ARE SOME CHANGES TO THE FORM") + " " + templ.name + ". " + this.translate.instant("WHAT WOULD YOU LIKE TO DO WITH THEM?"),
          title: this.translate.instant('SAVE CHANGES?')
        }
      });

      dialogRef.afterClosed().subscribe(result  => {
        if (result) {

          this.store.updatedForm = false;
          this.store.changeFormUpdatingState(false);
          action();
        }
      });
    } else {
      action();
    }
  }

  openFormSchemaAfterCheck() {
    if (this.template.getValue()) {
      persistLastMenu('info');
      this.showTemplateInfo();
      this.store.formMode = SCHEMA_MODE;
    }
  }

  checkFile(value) {
    const convValue = String(value);
    const splitValues = convValue.split('.');
    const types = ['jpg', 'png', 'jpeg', 'pdf', 'gif', 'mp3', 'wav', 'raw', 'ogg', 'mp4', '3gp', 'avi', 'vob', 'flv'];
    let type = splitValues[splitValues.length - 1];
    type = type.toLowerCase();
    if (types.includes(type)) {
      return url(value);
    } else {
      return value;
    }
  }


  openMediaShowingDialog(event, link) {
    event.preventDefault();
    event.stopPropagation();
    const mediaLink = this.checkFile(link);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = 'auto';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      medialink: mediaLink
    };

    return this.dialog.open(MediaShowingDialogComponent, dialogConfig);
  }

  getIcon(itemURL: string): string {
    const imageExtensions = ['jpeg', 'png', 'jpg', 'gif', 'pdf'];
    const videoExtensions = ['mp4', '3gp', 'avi', 'vob', 'flv'];
    const audioExtensions = ['mp3', 'wav', 'raw', 'ogg'];

    let uri: string;

    if (imageExtensions.some(ext => itemURL.endsWith(ext))) {
      uri = '/assets/images/photo_display.png';
    } else if (videoExtensions.some(ext => itemURL.endsWith(ext))) {
      uri = '/assets/images/video_display.png';
    } else if (audioExtensions.some(ext => itemURL.endsWith(ext))) {
      uri = '/assets/images/audio_play.png';
    }

    return uri;
  }
}
export const PROJECTS: Project[] = [
];

export interface ProjectToken {
  key: string;
  value: string;
}

export interface TemplateToken {
  key: string;
  value: string;
}
