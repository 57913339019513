<div class="dialog-content" fxLayout="column">
  <div fxFlex="10">
    <h4><span>{{path}}</span></h4>
  </div>
  <div class="mb-2 mt-3" fxFlex="80">
    <table mat-table matSort [dataSource]="dataSource" class="table table-striped table-bordered">
      @for (column of displayedColumns; track column) {
        <ng-container [matColumnDef]="column">
          <th *matHeaderCellDef> {{column}} </th>
          <td *matCellDef="let element; let i = index" class="align-middle">
            @if (isDataIsFile(element[column])) {
              @for (item of getFilesUrl(element[column]); track item) {
                <a href="{{item}}" (click)="openMediaShowingDialog($event, item)" alt="">
                  <img src="{{dataService.getIcon(item)}}">
                </a>
              }
            } @else {
              @if (checkIfCoordinatesField(column)) {
                <span class="geomelement" (click)="displayGPSCoordinates(column, element)">{{column}}</span>
                <span class="ml-2 " color="primary"  (click)="displayEditGPSCoordinates(column, element, i)"><fa-icon [icon]="faEdit"></fa-icon></span>
              } @else {
                <span>{{ displayItemValue(element[column]) }}</span>
              }
            }
          </td>
        </ng-container>
      }

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div fxFlex="10" mat-dialog-actions class="button-group">
     <button mat-raised-button (click)="closeDialog()" style="color: black !important;" class="text-floral-white cancel-btn" cdkFocusInitial><span>{{ "CLOSE" | translate }}</span></button>
  </div>
</div>
