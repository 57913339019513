import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { SystemAdminPageComponent } from './components/system-admin-page/system-admin-page.component';
import { UserProjectsPageComponent } from './components/user-projects-page/user-projects-page.component';
import { ReportTemplatesComponent } from './components/report-templates/report-templates.component';
import { ReportsComponent } from './components/reports/reports.component';
import { McadminPageComponent } from './components/mcadmin-page/mcadmin-page.component';
import { TemplatesReportsPageComponent } from './components/templates-reports-page/templates-reports-page.component';
import { AuthGuardGuard } from './guards/auth-guard.guard';
import { SignupComponent } from './components/signup/signup.component';
import { McadminLoginDialogComponent } from './components/dialogs/mcadmin-login-dialog/mcadmin-login-dialog.component';
import { ForgotPasswordPageComponent } from './components/forgot-password-page/forgot-password-page.component';
import { TemplateStyleListComponent } from './components/template-style-list/template-style-list.component';
import { FoundProjectsComponent } from './components/found-projects/found-projects.component';
import {ActivatedComponent} from './components/activated-page/activated.component';

const routes: Routes = [
  {path: 'signup/:caller', component: SignupComponent},
  {path: 'signup', component: SignupComponent},
  {path: 'login', component: LoginComponent},
  {path: 'activated', component: ActivatedComponent},
  {path: 'reset-password', component: ForgotPasswordPageComponent},
  {path: 'mcadmin-login', component: McadminLoginDialogComponent},
  {path: 'projects', component: ProjectsComponent, canActivate: [AuthGuardGuard]},
  {path: 'search', component: FoundProjectsComponent, canActivate: [AuthGuardGuard]},
  {path: 'mcadmins', component: McadminPageComponent, canActivate: [AuthGuardGuard]},
  {path: 'reports', component: ReportsComponent, canActivate: [AuthGuardGuard]},
  {path: 'report-templates', component: ReportTemplatesComponent, canActivate: [AuthGuardGuard]},
  {path: 'templates_style', component: TemplateStyleListComponent, canActivate: [AuthGuardGuard]},
  {path: 'mcadmins_report_templates', component: TemplatesReportsPageComponent, canActivate: [AuthGuardGuard]},
  {path: 'users', component: SystemAdminPageComponent, canActivate: [AuthGuardGuard]},
  {path: 'project-members', component: UserProjectsPageComponent, canActivate: [AuthGuardGuard]},
  {path: '', component: ProjectsComponent, canActivate: [AuthGuardGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
