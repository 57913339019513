<div id="projectsPage" fxLayout="column" fxLayoutGap="2px">
  <div class="ml-2" fxFlex="20">
    @if (showMessage) {
      <app-message-box #messageBox message="{{message}}"></app-message-box>
    }
    <div fxLayout="row" class="mt-2">
      <span fxFlex class="text-left">
        <div class="form-group has-clear">
          <mat-form-field class="half-width">
            <input matInput autocomplete="off" class="full-width" [placeholder]="'SEARCH...' | translate" type="text" value="{{searchValue}}">
          </mat-form-field>
        </div>
      </span>
    </div>
  </div>
  <div class="ml-2 mr-5" fxFlex="80">
    <table class=" mr-3" mat-table [dataSource]="dataSource" class="table table-striped table-bordered full-width">
      <!-- Name Column -->
      <ng-container matColumnDef="projects">
        <th *matHeaderCellDef>
          <span class="ml-2 mt-0 mb-0 table-cells"><span>{{ "PROJECTS" | translate }}</span></span>
        </th>
        <td *matCellDef="let element" class="table-cells">
          <span class="ml-2 mt-0 mb-0" class="table-cells">
            {{element.name}}
          </span>
        </td>
      </ng-container>

      <!-- Members Column -->
      <ng-container id="membersColumn" matColumnDef="members" style="width: 150px !important;" class="text-right">
        <th *matHeaderCellDef style="width: 150px !important; padding-left: 10px !important;">
          <span class="ml-2 mt-0 mb-0"><span>{{ "PROJECT MEMBERS" | translate }}</span></span>
        </th>
        <td *matCellDef="let element" class="action-link table-cells">
          <span class="ml-2 mt-0 mb-0 table-cells">
            <button style="font-weight: bold; color: white !important;" class="btn-border mt-0 mb-0" mat-button (click)="headerService.openProjectMembersDialog(element)"><span>{{ "MEMBERS" | translate }}</span></button>
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"> </tr>
    </table>
  </div>
</div>
