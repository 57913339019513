<div id="collaboratorListPage" class="dialog-content">
  <div class="header">
    <div class="header-left">
      <h3><span>{{ "LIST OF CONSTRAINTS" | translate }}</span></h3>
    </div>
    <div class="header-right">
      <button class="btn-border" (click)="addConstraintToField()"><mat-icon>add</mat-icon></button>
      <button class="btn-border" (click)="updateConstraint()"><mat-icon>edit</mat-icon></button>
      <button class="btn-border" (click)="removeConstraint()"><mat-icon>delete</mat-icon></button>
    </div>
  </div>
  <div class="content">
    <mat-dialog-content>
      <table mat-table [dataSource]="dataSource" class="table table-striped table-bordered">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef><span>{{ "SELECT" | translate }}</span></th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- Constraint name Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef><span>{{ "TYPE" | translate }}</span></th>
          <td mat-cell *matCellDef="let element">{{element.type}}</td>
        </ng-container>
        <!-- Constraint values Column -->
        <ng-container matColumnDef="values">
          <th mat-header-cell *matHeaderCellDef><span>{{ "VALUE" | translate }}</span></th>
          <td mat-cell *matCellDef="let element">{{element.values}}</td>
        </ng-container>
        <!-- Constraint message Column -->
        <ng-container matColumnDef="message">
          <th mat-header-cell *matHeaderCellDef><span>{{ "MESSAGE" | translate }}</span></th>
          <td mat-cell *matCellDef="let element">{{element.message}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-dialog-content>
    <mat-dialog-actions class="button-group">
      <span class="flex-spacer"></span>
      <button mat-button class="btn cancel-btn" [mat-dialog-close]="true" (click)="cancelChanges()"><span>{{ "CANCEL" | translate }}</span></button>
      <button mat-button type="submit" (click)="saveChanges()" class="btn submit-btn"><span>{{ "SAVE" | translate }}</span></button>
    </mat-dialog-actions>
  </div>
</div>
