import { TranslateModule } from '@ngx-translate/core';
import {Component, OnInit, ViewChild, Inject, Input} from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ReportTemplateService } from 'src/app/services/report-template.service';
import { getToken, getAdminToken, getUser } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import {CommonModule} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {ToastService} from '../../../services/toast.service';
import {DialogActionsComponent} from '../dialog-actions/dialog-actions.component';


export interface PlaceHolder {
  name: string;
  default_value: string;
}

const ELEMENT_DATA: PlaceHolder[] = [];

@Component({
  standalone: true,
    imports: [TranslateModule,
      MessageBoxComponent,
      ReactiveFormsModule,
      MatDialogContent,
      MatFormFieldModule,
      MatInputModule,
      MatOptionModule,
      MatTableModule,
      MatIconModule,
      MatDialogActions,
      MatSelectModule,
      MatButtonModule,
      CommonModule,
      FontAwesomeModule,
      DialogActionsComponent
    ],
  selector: 'app-report-template-dialog',
  templateUrl: './report-template-dialog.component.html',
  styleUrls: ['./report-template-dialog.component.scss', '../dialog-btns.component.scss']
})
export class ReportTemplateDialogComponent implements OnInit {
  displayedColumns: string[] = ['name', 'default_value', 'action'];
  dataSource = new MatTableDataSource<PlaceHolder>(ELEMENT_DATA);
  selection = new SelectionModel<PlaceHolder>(true, []);
  placeholdername: string;

  reportTemplateForm: FormGroup;
  reportTemplate: any;
  reportTemplateType: string;
  isReTeAdmin: boolean;
  repTemplateFileType: string;
  reportTemplateId: string;


  @Input() mode = {
    text: 'Create',
    function: 'Create'
  };

  formats = [
    { value: 'A4', text: 'A4' },
    { value: 'A3', text: 'A3' },
    { value: 'A2', text: 'A2' }
  ];

  orientations = [
    { value: 'portrait', text: 'Portrait' },
    { value: 'landscape', text: 'Landscape' }
  ];

  accesstypes = [
    { value: 'all', text: 'Public' },
    { value: 'mc_admins', text: 'MC Administrator' }
  ];

  noFileIsSelected: boolean = true;

  selectedFile: File | null = null;
  placeholders: string[] = [];
  reportTemplateFormat: string;
  orientationAndDocFormatNeeded: boolean = true;
  isPlaceholdersNeeded: boolean = true;

  @ViewChild('fform') reportTemplateFormirective;
  @ViewChild('placeholderslist') placeholderslist: MatTable<any>;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private reportTemplateService: ReportTemplateService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ReportTemplateDialogComponent>,
    public toastService: ToastService,
  ) {
    if (data !== undefined && data !== null) {
      this.reportTemplate = data.reportTemplate;
      this.reportTemplateId = data?.reportTemplate?.id;
      this.mode = data.mode;
      this.reportTemplateType = data.reportTemplateType;
    }
  }

  ngOnInit() {
    this.orientationAndDocFormatNeeded = true;
    this.isPlaceholdersNeeded = true;
    this.createForm();
    const user = getUser();
    let userType = user.user_type;

    if(userType === undefined || userType === null)
      userType = user.admin_type;

    if(userType === 'report_template_administrator'){
      this.isReTeAdmin = true;
    } else {
      this.isReTeAdmin = false;
    }
  }

  createForm() {
    if (this.mode.function === 'Create') {
      this.reportTemplateForm = this.fb.group({
        name: ['', Validators.required],
        default_page_size: 'A4',
        orientation: 'portrait',
        visible_to: '',
        place_holders: []
      });
      //this.dataSource.data = [];
    } else {

      const holders = this.reportTemplate.place_holders;
      if (holders !== null && holders !== undefined && holders.length > 0) {
        this.orientationAndDocFormatNeeded = true;
        let data = this.dataSource.data;
        data = [];
        for (const obj of holders) {
          const keys = Object.keys(obj);
          for (const key of keys) {
            const holder = {
              name: key,
              default_value: obj[key]
            };
            data.push(holder);
          }
        }
        this.dataSource.data = data;
        this.placeholderslist?.renderRows();
      }
      this.reportTemplateForm = this.fb.group({
        name: [this.reportTemplate.name, Validators.required],
        default_page_size: [this.reportTemplate.default_page_size, Validators.required],
        orientation: [this.reportTemplate.orientation, Validators.required],
        visible_to: [this.reportTemplate.visible_to, Validators.required],
        place_holders: [this.reportTemplate.place_holders],
      });
    }
  }

  closeDialog() {
    const result = {
      event: 'Cancel'
    };
    this.dialogRef.close(result);
  }

  defaultValueChanged(event, element, value){

    event.preventDefault();
    element['default_value'] = value;
    event.stopPropagation();
  }

  determineFileType(file: File): string {
    const fileName = file.name.toLowerCase();
    if (fileName.endsWith('.html')) {
      this.orientationAndDocFormatNeeded = true;
      return 'HTML';
    } else if (fileName.endsWith('.docx')) {
      this.orientationAndDocFormatNeeded = false;
      return 'DOCX';
    } else if (fileName.endsWith('.xlsx') || fileName.endsWith('.xls')) {
      this.orientationAndDocFormatNeeded = false;
      return 'XLSX';
    } else if (fileName.endsWith('.pdf')) {
      this.orientationAndDocFormatNeeded = false;
      return 'PDF';
    } else {
      return 'unknown';
    }

  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.selectedFile = input.files?.[0] || null;

    if (this.selectedFile) {
      this.reportTemplateFormat = this.determineFileType(this.selectedFile);
      this.repTemplateFileType = this.selectedFile.type;

      const reader = new FileReader();

      if (this.repTemplateFileType === 'text/html') {
        reader.onload = () => {
          const textContent = reader.result as string;
          this.extractPlaceholdersFromHtml(textContent);

          // After reading as text, read as ArrayBuffer
          reader.onload = () => {
            const arrayBuffer = reader.result as ArrayBuffer;
            // Process the ArrayBuffer as needed
          };
          reader.readAsArrayBuffer(this.selectedFile);
        };

        // Ensure you read the file as text
        reader.readAsText(this.selectedFile, 'UTF-8');
      } else {
        reader.readAsArrayBuffer(this.selectedFile);
      }

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };
      this.noFileIsSelected = false;
    }
  }

  extractPlaceholdersFromHtml(htmlContent: string): void {
    const regex = /%%\s*(\w+)\s*%%/g;
    const matches = [];
    let match;
    const data = [];

    while ((match = regex.exec(htmlContent))) {
      matches.push(match[1].trim());

      const placeholder = {
        name: match[1].trim(),
        default_value: ''
      };

      data.push(placeholder);
    }
    this.placeholders = matches;
    this.dataSource.data = data;
    this.placeholderslist.renderRows();
  }

  extractPlaceholdersFromText(textContent: string): void {
    const regex = /{{(.*?)}}/g;
    const matches = [];
    let match;
    while ((match = regex.exec(textContent))) {
      matches.push(match[1].trim());
    }
    this.placeholders = matches;
  }

  onSubmit() {
    let placeHolders = null;
    const holders = this.dataSource.data;
    if (holders !== null  && holders !== undefined && holders.length >= 0) {
      placeHolders = this.createPlaceholderList(holders);
    }
    if (this.mode.function === 'Create') {
      this.reportTemplate = this.reportTemplateForm.value;
      this.reportTemplate.place_holders = placeHolders;
    } else {
      const newData = this.reportTemplateForm.value;
      if (this.reportTemplate.name !== newData.name) {
        this.reportTemplate.name = newData.name;
      }
      if (this.reportTemplate.default_page_size !== newData.default_page_size) {
        this.reportTemplate.default_page_size = newData.default_page_size;
      }
      if (this.reportTemplate.orientation !== newData.orientation) {
        this.reportTemplate.orientation = newData.orientation;
      }
      if (this.reportTemplate.place_holders !== placeHolders) {
        this.reportTemplate.place_holders = placeHolders;
      }
    }
    if ( this.reportTemplateType === 'user_report_template') {
      this.createOrUpdateReportTemplate();
    }  else if (this.reportTemplateType === 'mc_admin_report_template') {
      this.createOrUpdateMCAdminReportTemplate();
    }
  }


  createOrUpdateReportTemplate() {
    const token = getToken();
    this.store.showLoading();
    if (this.mode.function === 'Create') {
      if(this.noFileIsSelected) {
        this.reportTemplateService.createReportTemplate(this.reportTemplate, token).subscribe(
          res => {
            if(this.noFileIsSelected) {
              const resultTemp = {
                event: 'Add',
                data: res
              };
              this.dialogRef.close(resultTemp);
              this.store.hideLoading();
            }
          },
          err => {
            this.toastService.errorToast(err);
            this.store.hideLoading();
          }
        );
      } else {
          const originalFile: File = this.selectedFile; // Original file

          const originalExtension = originalFile.name.split('.').pop();
          const newFileName = `${this.reportTemplate.name}.${originalExtension}`;
          const newFile = new File([originalFile], newFileName, {
            type: originalFile.type, // Keep the same MIME type
            lastModified: originalFile.lastModified // Keep the same last modified date
          });
         this.reportTemplateService.createUserCustomReportTemplateWithFile(this.reportTemplate, newFile, token, "all_users")
         .subscribe(
            (result) => {
              const resultTemp = {
                event: 'Add',
                data: result
              };
              this.dialogRef.close(resultTemp);
              this.store.hideLoading();
            },
            (error) => {
              this.toastService.errorToast(error);
              const resultTemp = {
                event: 'Error'
              };
              this.dialogRef.close(resultTemp);
              this.store.hideLoading();
            }
         );
      }

    } else {
      if(this.noFileIsSelected) {
        this.reportTemplateService.updateReportTemplate(this.reportTemplate, token).subscribe(
          res => {
            const result = {
              event: 'Update',
              data: res
            };
            this.dialogRef.close(result);
            this.store.hideLoading();
          },
          err => {
            if(err.error.error)
              this.toastService.errorToast(err.error.error.message);

            else if(typeof err.message === 'string')
              this.toastService.errorToast(err.message);
            else
              this.toastService.errorToast("Backend Error while processing creation of report template, please try again.");

            this.store.hideLoading();
          }
        );
      } else {
        const originalFile: File = this.selectedFile; // Original file

          const originalExtension = originalFile.name.split('.').pop();
          const newFileName = `${this.reportTemplate.name}.${originalExtension}`;
          const newFile = new File([originalFile], newFileName, {
            type: originalFile.type, // Keep the same MIME type
            lastModified: originalFile.lastModified // Keep the same last modified date
          });
        this.reportTemplateService.updateUserCustomReportTemplate(this.reportTemplateId, this.reportTemplate, newFile, token, "all_users").subscribe(
          (updatedTemplate) => {
            const result = {
              event: 'Update',
              data: updatedTemplate
            };
            this.dialogRef.close(result);
            this.store.hideLoading();
          },
          (error) => {
            if(error?.error?.error)
              this.toastService.errorToast(error?.error?.error?.message);

            else if(typeof error.message === 'string')
              this.toastService.errorToast(error.message);
            else
              this.toastService.errorToast("Backend Error while processing update of report template, please try again.");
            this.store.hideLoading();
          }
        );
      }
    }
  }

  createOrUpdateMCAdminReportTemplate() {
    const token = getAdminToken();
    this.store.showLoading();
    if (this.mode.function === 'Create') {
      this.reportTemplateService.createMCAdminReportTemplate(this.reportTemplate, token).subscribe(
        res => {
          const result = {
            event: 'Add',
            data: res
          };
          this.dialogRef.close(result);
          this.store.hideLoading();
        },
        err => {
          this.toastService.errorToast(err);
          this.store.hideLoading();
        }
      );
    } else {
      this.reportTemplateService.updateMCAdminReportTemplate(this.reportTemplate.id, this.reportTemplate, token).subscribe(
        res => {
          const result = {
            event: 'Update',
            data: res
          };
          this.dialogRef.close(result);
          this.store.hideLoading();
        },
        err => {
          this.toastService.errorToast(err);
          this.store.hideLoading();
        }
      );
    }
  }

  OpenDeletePlaceHolder(index){

    this.dataSource.data.splice(index, 1);
    this.placeholderslist.renderRows();
  }

  addPlaceHolder(event) {
    event.preventDefault();
    const data = this.dataSource.data;
    const index = data.findIndex(el => el.name === this.placeholdername);
    if (index >= 0) {
      this.toastService.warningToast('Placeholder name already exists');
      return;
    }

    if (this.placeholdername === null || this.placeholdername === undefined || this.placeholdername === '') {
      event.stopPropagation();
      return;
    }
    const placeholder = {
      name: this.placeholdername,
      default_value: ''
    };

    data.push(placeholder);
    this.placeholdername = '';
    this.dataSource.data = data;
    this.placeholderslist.renderRows();
    event.stopPropagation();
  }

  createPlaceholderList(placeholderNameAndDefaults: PlaceHolder[]) {
    const holders = [];
    for (const pl of placeholderNameAndDefaults) {
      const val = {};
      val[pl.name] = pl.default_value;
      holders.push(val);
    }

    return holders;
  }


  protected readonly faPlus = faPlus;
}
