import {TranslateModule} from '@ngx-translate/core';
import {Component, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {Project} from 'src/app/share/projects';
import {ProjectMemberService} from 'src/app/services/project-member.service';
import {DataService} from 'src/app/services/data.service';
import {getToken} from 'src/app/share/utils';
import {Collaborator} from 'src/app/share/user';
import {ProjectMember} from 'src/app/share/project-member';
import {TemplatePermission} from 'src/app/share/permission';
import {StoreService} from 'src/app/services/store.service';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import {forkJoin, Subject, Subscription, takeUntil} from 'rxjs';

const  CREATE  = 'create';
const  READ    = 'read';
const  UPDATE  = 'update';
const  DELETE  = 'delete';

const ELEMENT_DATA: TemplatePermission[] = [];

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatDialogContent,
    MatTableModule,
    MatCheckboxModule,
    MatDialogActions,
    MatDialogModule,
    MatButtonModule,
  ],
  selector: 'app-templates-permissions-dialog',
  templateUrl: './templates-permissions-dialog.component.html',
  styleUrls: ['./templates-permissions-dialog.component.scss', '../dialog-btns.component.scss']
})
export class TemplatesPermissionsDialogComponent implements OnInit {
  users: Collaborator[];
  project: Project;
  projectMember: ProjectMember;
  displayedColumns: string[] = ['name', 'read', 'create', 'update', 'delete', 'all'];
  permissionsToGrant: TemplatePermission[];
  permissionsToRevoke: TemplatePermission[];
  existingPermissions: TemplatePermission[];
  userPermissions: TemplatePermission[];
  dataSource = new MatTableDataSource<TemplatePermission>(ELEMENT_DATA);
  selection = new SelectionModel<TemplatePermission>(true, []);
  searchValue: string;
  allExistings: any[];

  constructor(
    private dialog: MatDialogRef<TemplatesPermissionsDialogComponent>,
    private store: StoreService,
    private projectMemberService: ProjectMemberService,
    private dataService: DataService,
    public toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    if (data !== undefined && data !== null) {
      this.project = data.project;
      this.users = data.user;
    }
  }

  private projectMemberInfo: Subscription;
  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.permissionsToGrant = [];
    this.permissionsToRevoke = [];
    this.userPermissions = [];
    this.existingPermissions = [];
    this.allExistings = [];
    const token = getToken();
    if(this.users.length > 1){
      this.buildExistingPermissions();
      return;
    }

    this.projectMemberInfo = this.projectMemberService.getProjectMemberInfo(token, this.project.id, this.users[0].id).pipe(takeUntil(this.destroy$))
      .subscribe(
      res => {
        this.projectMember = res;
        /**
         * Get the template of this project
         */
        this.dataService.requestTemplates(token, this.project.id).subscribe(
          result => {
            if (result.length > 0) {
              const userslist = []; //this.dataSource.data;

              if (this.projectMember.permissions && this.projectMember.permissions.features) {
                const features = this.projectMember.permissions.features;
                for (const template of result) {
                  let found = false;
                  if (features != null && features !== undefined && features.length > 0) {
                    for (const feature of features) {
                      if (feature.template_id === template.id) {
                        userslist.push(new TemplatePermission(template, feature.permission_types));
                        this.existingPermissions.push(new TemplatePermission(template, feature.permission_types));
                        found = true;
                        break;
                      }
                    }
                    if (!found) {
                      userslist.push(new TemplatePermission(template, null));
                      this.existingPermissions.push(new TemplatePermission(template, null));
                    }
                  } else {
                    userslist.push(new TemplatePermission(template, null));
                    this.existingPermissions.push(new TemplatePermission(template, null));
                  }
                }
              }

              this.dataSource.data = userslist;
              this.userPermissions = userslist;
            }
            this.store.hideLoading();
          },
          err => {
            this.toastService.errorToast(err);
            this.store.hideLoading();
          }
        );
      },
      err => {
        this.toastService.errorToast(err);
        this.store.hideLoading();
      }
    );
  }


  buildExistingPermissions() {
    const token = getToken();
    this.existingPermissions = [];
    this.allExistings = [];

    this.users.forEach(user => {
      const projectMember$ = this.projectMemberService.getProjectMemberInfo(token, this.project.id, user.id);
      const templates$ = this.dataService.requestTemplates(token, this.project.id);

      // Combine both observables and handle errors in a single place
      forkJoin([projectMember$, templates$]).subscribe(
        ([projectMember, templates]) => {
          const features = projectMember.permissions?.features || [];

          this.existingPermissions = templates.map(template => {
            const feature = features.find(f => f.template_id === template.id);
            return new TemplatePermission(template, feature?.permission_types || null);
          });

          this.allExistings.push({
            userId: user.id,
            exPermisssions: this.existingPermissions
          });

          this.store.hideLoading();
        },
        err => this.handleError(err)
      );
    });

    this.loadEmptyPermissions();
  }

  handleError(error: any) {
    this.toastService.errorToast(error);
    this.store.hideLoading();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dialog.close();
    }
  }



  loadEmptyPermissions(){
    const token = getToken();
    this.dataService.requestTemplates(token, this.project.id).subscribe(
      result => {
        const userslist = [];

        for (const template of result) {
          userslist.push(new TemplatePermission(template, null));
          //this.existingPermissions.push(new TemplatePermission(template, null));
        }
        this.dataSource.data = userslist;
        this.userPermissions = userslist;
        this.store.hideLoading();
      },

      err => {
        this.store.hideLoading();
      }
    );
  }

  initializeAllPermission(element) {
    return element.permissions.read && element.permissions.create &&
            element.permissions.update && element.permissions.delete;
  }

  initializePermission(element, permission) {
    let result = false;
    let features = null;

    if (this.projectMember.permissions && this.projectMember.permissions.features) {
      features = this.projectMember.permissions.features;
      for (const featurePerm of features) {
        if (element.id === featurePerm.template_id && featurePerm.permission_types.indexOf(permission) >= 0) {
          result = true;
          break;
        }
      }
    }
    return result;
  }

  setAllPermissions(isChecked) {
    let index = 0;
    for (const element of this.dataSource.data) {
      this.setAllPermission(element, index++, isChecked);
    }
  }

  initializeAllPermissions() {
    const allPermission = true;
    for (const element of this.dataSource.data) {
      if (!this.initializeAllPermission(element)) {
        return false;
      }
    }
    return allPermission;
  }

  updatePermission(element, permissionType, access) {
    // let alreadyExist = false;
    const index = this.permissionsToGrant.indexOf(element);
    this.grantPerm(element, permissionType, access);

    if (index >= 0) {
      this.permissionsToGrant[index] = element;
    } else {
      this.permissionsToGrant.push(element);
    }
  }

  grantPerm(element: TemplatePermission, permission_type, access) {
    if (permission_type === READ) {
      element.permissions.selectRead(access);
    } else if (permission_type === CREATE) {
      element.permissions.selectCreate(access);
    } else if (permission_type === UPDATE) {
      element.permissions.selectUpdate(access);
    } else if (permission_type === DELETE) {
      element.permissions.selectDelete(access);
    }
  }

  revokePermission(element, permissionType) {

  }

  setCreatePermission(element, isChecked: boolean) {
    this.updatePermission(element, CREATE, isChecked);
  }

  setUpdatePermission(element, isChecked: boolean) {
    this.updatePermission(element, UPDATE, isChecked);
  }

  setDeletePermission(element, isChecked: boolean) {
    this.updatePermission(element, DELETE, isChecked);
  }

  setDeleteAllColPermission(isChecked: boolean) {
    for (const element of this.dataSource.data) {
      this.setDeletePermission(element, isChecked);
    }
  }

  setUpdateAllColPermission(isChecked: boolean) {
    for (const element of this.dataSource.data) {
      this.setUpdatePermission(element, isChecked);
    }
  }

  setReadAllColPermission(isChecked: boolean) {
    for (const element of this.dataSource.data) {
      this.setReadPermission(element, isChecked);
    }
  }

  getAllColReadPermission(): boolean {
    for (const element of this.dataSource.data) {
      if (!element.permissions.read){
        return false;
      }
    }
    return true;
  }

  getAllColCreatePermission(): boolean {
    for (const element of this.dataSource.data) {
      if (!element.permissions.create){
        return false;
      }
    }
    return true;
  }

  getAllColUpdatePermission(): boolean {
    for (const element of this.dataSource.data) {
      if (!element.permissions.update){
        return false;
      }
    }
    return true;
  }

  getAllColDeletePermission(): boolean {
    for (const element of this.dataSource.data) {
      if (!element.permissions.delete){
        return false;
      }
    }
    return true;
  }

  setCreateAllColPermission(isChecked: boolean) {
    for (const element of this.dataSource.data) {
      this.setCreatePermission(element, isChecked);
    }
  }

  setReadPermission(element, isChecked: boolean) {
    this.updatePermission(element, READ, isChecked);
  }

  setAllPermission(element, index, isChecked: boolean) {
    element.permissions.selectAll(isChecked);
    this.permissionsToGrant.push(element);
  }

  getTemplatePermission(permis: TemplatePermission[], permi: TemplatePermission): any {
    if(permis.length > 0){
      for (const perm of permis){
        if(perm.template.id === permi.template.id){
          return perm;
        }
      }
    }

    return null;
  }

  onSubmit() {
    const token = getToken();
    this.store.showLoading();

    if(this.users.length <= 0){
      return;
    }

    this.users.map(user => {
      let grantFeatures = [];
      let revokeFeature = [];

      this.allExistings.map(perm => {
        if(perm.userId === user.id){
          this.existingPermissions = perm.exPermisssions;
          return;
        }
      });

      for (const permission of this.permissionsToGrant) {
        const extPerm = this.getTemplatePermission(this.existingPermissions, permission);
        const perm =  this.createPermissions(permission, extPerm);
        if (perm.grantPermission !== null) {
          grantFeatures.push(perm.grantPermission);
        }

        if (perm.revokePermission !== null) {
          revokeFeature.push(perm.revokePermission);
        }
      }

      //When a permission is clicked on and off, the original item becomes undefined/null, which gives a length >1. This removes these items.
      grantFeatures = grantFeatures.filter(item => item != null);
      revokeFeature = revokeFeature.filter(item => item != null);


      if (grantFeatures.length <= 0 && revokeFeature.length <= 0) {
        this.toastService.warningToast("No permissions changed")
        this.store.hideLoading();
        this.dialog.close();
      }


      if(grantFeatures.length > 0){
        const grandData = {
          user_id: user.id,
          permissions: {
            'features': grantFeatures
          }
        };

        this.projectMemberService.grantTemplatesPermissionToCollaborator(token, user.id, this.project.id, grandData).subscribe(
          res => {
            this.dialog.close();
            this.store.hideLoading();
          },
          err => {
            this.dialog.close();
            this.store.hideLoading();
        });
      }

      if(revokeFeature.length > 0){
        const revokeData = {
          user_id: user.id,
          permissions: {
            'features': revokeFeature
          }
        };

        this.projectMemberService.revokeTemplatesPermissionToCollaborator(token, user.id, this.project.id, revokeData).subscribe(
          res => {
            this.dialog.close();
            this.store.hideLoading();
          },
          err => {
            this.dialog.close();
            this.store.hideLoading();
        });
      }
    });

  }

  createPermissions (templatePermission: TemplatePermission, existPerms: TemplatePermission): any {
    const readPermission = templatePermission.permissions.read;
    const createPermission = templatePermission.permissions.create;
    const updatePermission = templatePermission.permissions.update;
    const deletePermission = templatePermission.permissions.delete;

    const readExist = existPerms.permissions.read;
    const createExist = existPerms.permissions.create;
    const updateExist = existPerms.permissions.update;
    const deleteExist = existPerms.permissions.delete;

    const permission = {};
    let permission_types = [];

    if (readPermission || createPermission || updatePermission || deletePermission) {

      if (readPermission && !readExist) {
        permission_types.push('read');
      }
      if (createPermission && !createExist) {
        permission_types.push('create');
      }
      if (updatePermission && !updateExist) {
        permission_types.push('update');
      }
      if (deletePermission && !deleteExist) {
        permission_types.push('delete');
      }
      if(permission_types.length > 0){
        permission['grantPermission'] = {
          template_id: templatePermission.template.id,
          permission_types
        };
      } else {
        permission['grantPermission'] = null;
      }


    }

    if (!readPermission || !createPermission || !updatePermission || !deletePermission) {
      permission_types = [];
      if (!readPermission && readExist) {
        permission_types.push('read');
      }
      if (!createPermission && createExist) {
        permission_types.push('create');
      }
      if (!updatePermission && updateExist) {
        permission_types.push('update');
      }
      if (!deletePermission && deleteExist) {
        permission_types.push('delete');
      }

      if(permission_types.length > 0){
        permission['revokePermission'] = {
          template_id: templatePermission.template.id,
          permission_types
        };
      } else {
        permission['revokePermission'] = null;
      }

    }

    return permission;
  }
}
