import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog, MatDialogActions} from '@angular/material/dialog';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { StoreService } from 'src/app/services/store.service';
import {getCurrentDomain, getUser, removeSpecialCharacters, url} from 'src/app/share/utils';
import { GeomTypeCoordinatesDialogComponent } from '../geom-type-coordinates-dialog/geom-type-coordinates-dialog.component';
import { MediaShowingDialogComponent } from '../media-showing-dialog/media-showing-dialog.component';
import { Feature } from 'src/app/share/feature/feature';
import { EditGeomOrCoordValuesComponent } from '../edit-geom-or-coord-values/edit-geom-or-coord-values.component';
import {CommonModule} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {ToastService} from '../../../services/toast.service';
import {DataService} from '../../../services/data.service';
import {MatButton} from '@angular/material/button';

const ELEMENT_DATA: any[] = [];

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatTableModule,
    FontAwesomeModule,
    CommonModule, MatDialogActions, MatButton
  ],
  selector: 'app-attributesetarray-dialog',
  templateUrl: './attributesetarray-dialog.component.html',
  styleUrls: ['./attributesetarray-dialog.component.scss', '../dialog-btns.component.scss']
})
export class AttributesetarrayDialogComponent implements OnInit, AfterViewInit {
  attributesetarray: any[];
  currentAttributeSetStructure: any;
  currentFieldSetStructure: any;
  attName: string;
  path: string;
  headers =  [];
  names = [];
  geodetic: boolean;
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  feature: Feature;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private storeService: StoreService,
    private dialog: MatDialog,
    public toastService: ToastService,
    public dataService: DataService,
    private dialogRef: MatDialogRef<AttributesetarrayDialogComponent>) {
    if (data !== undefined && data !== null) {
      this.attributesetarray = data.attributesetarray;
      this.path = data.path;
      this.attName = data.attname;
      this.feature = data.feature;
    }
  }

  ngOnInit() {
    this.createData();
  }

  ngAfterViewInit() {

  }

  displayEditGPSCoordinates(column, element, index) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.autoFocus = true;

    const coordinates = element[column];
    let gpscoord = false;
    if (coordinates !== null && coordinates !== undefined && coordinates.length > 0) {
      if (coordinates[0] instanceof Object) {
        gpscoord = false;
      } else {
        gpscoord = true;
      }
      dialogConfig.data = {
        feature: this.feature,
        column: `${this.path} ${column}`,
        isCoordAttributes: true,
        coordAttributes: coordinates,
        is_fieldset_array: true,
        fsa_value_index : index,
        hasWritePermissions: getUser().id === this.dataService.template.getValue().created_by || getUser().permissions?.projects.includes('create')
      };

      const editCoordRef = this.dialog.open(EditGeomOrCoordValuesComponent, dialogConfig);
      editCoordRef.afterClosed().subscribe(result => {
        if (result !== null && result !== undefined) {
          this.dialogRef.close(result);
        }
      });
    } else {
      this.toastService.successToast('This coordinates field does not contain values');
    }
  }

  displayGPSCoordinates(column, element) {
    const coordinates = element[column];
    let gpscoord = false;
    if (coordinates !== null && coordinates !== undefined && coordinates.length > 0) {
      if (coordinates[0] instanceof Object) {
        gpscoord = false;
      } else {
        gpscoord = true;
      }
    }
    this.openCoordinateDialog(null, coordinates, gpscoord);
    return;
  }

  openCoordinateDialog(geometryType, coordinates, gpscoord = true) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      geometry_type: geometryType,
      gpscoord,
      coordinates
    };
    const dialogRef = this.dialog.open(GeomTypeCoordinatesDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  checkIfCoordinatesField(column) {
    let name = '';
    for(const f of this.currentFieldSetStructure) {
      if(f['label'] === column) {
        name = f['name'];
        break;
      }
    }
    const col = removeSpecialCharacters(column);
    for ( const obj of this.currentAttributeSetStructure) {
      if (obj['name'] === name) {
        if (obj['type'] === 'coordinates') {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  }

  // building the attributeset with nested attributes header
  buildAttributesetHeader(attributes, headerArray, prefix = '') {
    const prefixEmpty = prefix === '' || prefix === null || prefix === undefined;

    const size = attributes.length;

    for (let i = 0; i < size; i++) {
      const attribute = attributes[i];
      if (attribute._class === 'attributeset') {
        prefix = prefixEmpty ? attribute.name : prefix + '.' + attribute.name;
        this.buildAttributesetHeader(attribute.attributes, headerArray, prefix);
      } else if (attribute._class === 'arrayattributeset') {
        const curHeader = prefixEmpty ? attribute.name : prefix + '.' + attribute.name;
        headerArray.push(curHeader);
      } else {
        const curHeader = prefixEmpty ? attribute.name : prefix + '.' + attribute.name;
        headerArray.push(curHeader);
      }
    }
  }

  createData() {
    const data = [];
    if (this.attributesetarray === null || this.attributesetarray === undefined || this.attributesetarray.length === 0) {
      return;
    }
    const template = this.dataService.template.getValue();
    const featureSchema = template.feature;
    const form = template.form;
    const attributes = featureSchema['attributes'];
    const fields = form.fields;
    const parts = this.path.split('.');
    const attnames = this.attName.split('.');

    let attributeset = null;
    let fieldset = null;
    if(parts && parts.length > 0) {
      // attributeset = attributes[parts[0]];
      for(let y = 0; y < fields.length; y++) {
        const absAtt = attributes[y];
        const absField = fields[y];
        if(absField.label === parts[0]) {
          attributeset = absAtt;
          fieldset = absField;
          break;
        }
      }
      if(parts.length > 1) {
        for(let i=1; i < parts.length; i++) {
          const key = parts[i];
          attributeset = attributeset[key];
          fieldset = fieldset[attnames[i]];
        }
      }
    }

    // const listGeometryHeader = Object.keys(this.attributesetarray[0]['geometry']);
    // const listDataHeader = Object.keys(attributeset);
    this.headers =  [];
    this.names = [];
    this.buildAttributesetHeader(attributeset.attributes, this.names, '');
    this.buildAttributesetHeaderLabel(fieldset.fields, this.headers, '');
    if (this.headers !== null && this.headers !== undefined && this.headers.length > 0) {
      this.displayedColumns = this.headers;
    }

    this.attributesetarray.map(inst => {
      const dataItem = this.createDataArray(inst, '');
      data.push(dataItem);
    });

    const currentTemplate = this.dataService.template.getValue();
    const features = currentTemplate.feature;
    const schema = features.attributes;
    const paths = this.path.split('.');
    this.currentAttributeSetStructure = schema;
    this.currentFieldSetStructure = currentTemplate.form['fields'];
    if (paths instanceof Object) {
      if (paths.length > 0) {
        for (const itemKey of paths) {
          for (const obj of this.currentAttributeSetStructure) {
            if (obj['name'] === itemKey) {
              if (obj['attributes'] !== null && obj['attributes'] !== undefined) {
                this.currentAttributeSetStructure = obj['attributes'];
              }
            }
          }

          for (const obj of this.currentFieldSetStructure) {
            if (obj['name'] === itemKey) {
              if (obj['fields']) {
                this.currentFieldSetStructure = obj['fields'];
              }
            }
          }
        }
      }
    }
    this.dataSource.data = data;
    this.table?.renderRows();
  }

  buildAttributesetHeaderLabel(fields, headerArray, prefix = '') {

    const prefixEmpty = prefix === '' || prefix === null || prefix === undefined;

    const size = fields.length;

    for (let i = 0; i < size; i++) {
      const field = fields[i];

      if (field._class === 'fieldset') {
        prefix = prefixEmpty ? field.label : prefix + '.' + field.label;
        this.buildAttributesetHeaderLabel(field.fields, headerArray, prefix);
      } else if (field._class === 'arrayfieldset') {
        const curHeader = prefixEmpty ? field.label : prefix + '.' + field.label;
        headerArray.push(curHeader);
      } else {
        const curHeader = prefixEmpty ? field.label : prefix + '.' + field.label;
        headerArray.push(curHeader);
      }
    }
  }

  createDataArray(attributeset: any, parent = ''): any {

    let result = {};
    const keys = Object.keys(attributeset);
    for (const key of keys) {
      const absAttribute = attributeset[key];
      const path = parent === '' ? key : `${parent}.${key}`;
      if (absAttribute instanceof Object) {
        if (absAttribute instanceof Array) {
          const i = this.names.indexOf(path);
          result[this.headers[i]] = absAttribute;
        } else {
          const tempResult = this.createDataArray(attributeset[key], path);
          result = Object.assign(result, tempResult);
        }
      } else {
        const i = this.names.indexOf(path);
        result[this.headers[i]] = attributeset[key];
      }
    }

    return result;
  }

  isDataIsFile(data): boolean {
    const temp = this.checkFiles(data);
    return this.checkIfIsUrl(temp);
  }

  checkIfIsUrl(data) {
    const pat = /^https?:\/\//i;
    let values = [];

    if (data === undefined || data === null) {
      return false;
    }
    let str;
    if ((data instanceof Object) && (data instanceof Array) && data.length > 0) {
      str = data[0];
    } else {
      str = data;
    }
    if (isNaN(str)) {
      if (typeof(str) === 'string') {
        values = str.split('/');
      }
    }
    const currentDomain = getCurrentDomain();
    if (pat.test(str) && values.includes(currentDomain) && values.includes('v1') && values.includes('files')) {
      return true;
    }
    return false;
  }

   /*
    * check if value is a media file and return the complete URL.
    * @param: the value to check
    * @return: the URL or the string
    */
   checkFiles(value) {
    if (value instanceof Array && value.length > 0) {
      const firstVal = value[0];
      return this.dataService.checkFile(firstVal);
    } else {
      return value;
    }
  }

  openMediaShowingDialog(event, link) {
    event.preventDefault();
    const medialink = this.dataService.checkFile(link);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    dialogConfig.data = {
      medialink
    };

    const dialogRef = this.dialog.open(MediaShowingDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // if (result.event === 'Add') {
      // }
    });
    event.stopPropagation();
  }

  getFilesUrl(value) {
    const filesUrls = [];
    if (value instanceof Array && value.length > 0) {
      value.map(element => {
        filesUrls.push(this.checkFiles(element));
      });
    } else {
      return value;
    }
    return filesUrls;
  }

  displayItemValue(value) {
      if (this.dataService.checkIfDate(value)) {
        return value.split('T')[0];
      }
      return value;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  protected readonly faEdit = faEdit;
}
