<div id="userspage" class="column-layout">
  <div class="ml-2" style="flex: 10;">
    <div class="row-layout full-width mt-2" style="padding-right: 15px !important; width: 100% !important;">
      <span class="text-left" style="flex: 60;">
        @if (showMessage) {
          <app-message-box #messageBox message="{{message}}"></app-message-box>
        }
        <div class="form-group has-clear">
          <mat-form-field class="full-width">
            <input matInput autocomplete="off" class="full-width" (keyup)="applyFilter($event)"  [placeholder]="'SEARCH...' | translate" type="text" value="{{searchValue}}">
          </mat-form-field>
        </div>
      </span>
      <div class="text-left" style="flex: 22;">
        <mat-checkbox color="primary" (change)="showOnlyAdmins($event)" [checked]="onlyAdmins" class="text-left"><span>{{ "ONLY ADMIN USERS" | translate }}</span></mat-checkbox>
      </div>
      <span class="text-right" style="flex: 18;">
        <button mat-button (click)="openConfirmDeleteUserDialog()" mat-flat-button color="primary" class="btn-border mr-2"><span>{{ "DELETE" | translate }}</span></button>
        <button mat-button (click)="openAddUserDialog()" mat-flat-button color="primary" class="btn-border mr-2"><span>{{ "ADD" | translate }}</span></button>
      </span>
    </div>
  </div>
  <div class="ml-2 mr-3" style="flex: 90; padding-right: 5px;">
    <mat-paginator style="margin-top: 0 !important;" [pageSizeOptions]="[50, 100, 200]"
      showFirstLastButtons
      aria-label="Select page of users elements">
    </mat-paginator>
    <table mat-table [dataSource]="dataSource" class="table table-striped table-bordered">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th *matHeaderCellDef>
          <mat-checkbox color="primary" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td *matCellDef="let row">
          <mat-checkbox color="primary" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef>
          <span class="ml-2"><span>{{ "NAME" | translate }}</span></span>
        </th>
        <td *matCellDef="let element">
          <span class="ml-2">
            {{element.name}}
          </span>
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th *matHeaderCellDef>
          <span class="ml-2"><span>{{ "EMAIL" | translate }}</span></span>
        </th>
        <td *matCellDef="let element">
          <span class="ml-2">
            {{element.email}}
          </span>
        </td>
      </ng-container>

      <!-- Created date Column -->
      <ng-container matColumnDef="created_date">
        <th *matHeaderCellDef>
          <span class="ml-2"><span>{{ "CREATED DATE" | translate }}</span></span>
        </th>
        <td *matCellDef="let element">
          <span class="ml-2">
            {{element.created_date | date: 'yyyy-MM-dd'}}
          </span>
        </td>
      </ng-container>

      <!-- Created date Column -->
      <ng-container matColumnDef="administrator">
        <th *matHeaderCellDef>
          <span class="ml-2"><span>{{ "ADMINISTRATOR" | translate }}</span></span>
        </th>
        <td *matCellDef="let element">
          <span class="ml-2">
            {{element.administrator}}
          </span>
        </td>
      </ng-container>

      <!-- Managed users Column -->
      <ng-container matColumnDef="managed_users">
        <th *matHeaderCellDef>
          <span class="ml-2"><span>{{ "MANAGED USERS" | translate }}</span></span>
        </th>
        <td *matCellDef="let element">
          @if (element.not_collaborator !== 'collaborator') {
            <span class="ml-2 blueItem" (click)="openCollaboratorList(element)" style="cursor: pointer;">
              {{element.managed_users}}
            </span>
          }
        </td>
      </ng-container>

      <!-- GNSS Source Column -->
      <ng-container matColumnDef="gnss_source">
        <th *matHeaderCellDef>
          <span class="ml-2"><span>{{ "GNSS SOURCE" | translate }}</span></span>
        </th>
        <td *matCellDef="let element">
          <span class="ml-2">
            @if (isMarxactDevices(element)) {
              <span> {{element.gnss_source}} <span class="btn-pointer"> ...</span> </span>
            }
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="row text-center more-btn" >
      <button [disabled]="moreUsers" mat-icon-button (click)="loadMoreUsers()"><span>{{ "MORE" | translate }}</span></button>
    </div>

  </div>
</div>
