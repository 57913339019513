import {Injectable} from '@angular/core';
import Toastify from 'toastify-js'


@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor() {
  }

  errorToast(msg) {
    Toastify({
      text: msg,
      duration: 5000,
      newWindow: true,
      close: true,
      gravity: 'bottom',
      position: 'right',
      stopOnFocus: true,
      style: {
        background: '#e23636'
      },
      onClick: function(){
        navigator.clipboard.writeText(this.text)
      }
    }).showToast();
  }

  warningToast(msg) {
    Toastify({
      text: msg,
      duration: 5000,
      newWindow: true,
      close: true,
      gravity: 'bottom',
      position: 'right',
      stopOnFocus: true,
      style: {
        background: '#edb95e'
      },
      onClick: function(){
        navigator.clipboard.writeText(this.text)
      }
    }).showToast();
  }

  successToast(msg) {
    Toastify({
      text: msg,
      duration: 5000,
      newWindow: true,
      close: true,
      gravity: 'bottom',
      position: 'right',
      stopOnFocus: true,
      style: {
        background: '#82dd55'
      },
      onClick: function(){
        navigator.clipboard.writeText(this.text)
      }
    }).showToast();
  }
}
