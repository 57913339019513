<div fxFlexLayout="row">
  <div fxFlex fxFlexOffset="20px">
    <h3>Import a Project of a user as {{project_class}}</h3>
    @if (showMessage) {
      <app-message-box #messageBox message="{{message}}"></app-message-box>
    }
    <form novalidate [formGroup]="projectDialogForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      <mat-dialog-content>
        <p>
          <span class="half-width"><span>{{ "USER" | translate }}</span></span>
          <mat-select class="ml-3" class="half-width" (selectionChange)="getProjectOnProClassChange($event)" required formControlName="user_id" [placeholder]="'SELECT USER' | translate">
            <mat-option></mat-option>
            @for (user of users; track user) {
              <mat-option [value]="user.id">{{ user.name }}</mat-option>
            }
          </mat-select>
        </p>
        <p>
          <span class="half-width"><span>{{ "PROJECT" | translate }}</span></span>
          <mat-select class="ml-3" class="half-width" required (selectionChange)="getProjectOnChange($event)" formControlName="project_id" [placeholder]="'SELECT PROJECT' | translate">
            <mat-option></mat-option>
            @for (project of projects; track project) {
              <mat-option [value]="project.id">{{ project.name }}</mat-option>
            }
          </mat-select>
        </p>
        <p>
          <mat-form-field class="full-width">
            <input matInput autocomplete="off" formControlName="description" value="{{description}}" [placeholder]="'DESCRIPTION' | translate" type="text">
          </mat-form-field>
        </p>
      </mat-dialog-content>
      <mat-dialog-actions class="button-group">
        <span class="flex-spacer"></span>
        <button id="addPrjtBtn" mat-button type="submit" class="btn submit-btn" [disabled]="projectDialogForm.invalid"><span>{{ "IMPORT PROJECT" | translate }}</span></button>
        <button mat-button mat-dialog-close class="btn cancel-btn" (click)="onCancel($event)"><span>{{ "CANCEL" | translate }}</span></button>
      </mat-dialog-actions>
    </form>
  </div>
</div>
