import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef } from '@angular/material/dialog';

import { FileServiceService } from 'src/app/services/file-service.service';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { MatCardModule } from '@angular/material/card';
import {ToastService} from '../../../services/toast.service';
import {DataService} from '../../../services/data.service';

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatDialogContent,
    MatCardModule,
    MessageBoxComponent,
  ],
  selector: 'app-media-showing-dialog',
  templateUrl: './media-showing-dialog.component.html',
  styleUrls: ['./media-showing-dialog.component.scss', '../dialog-btns.component.scss']
})
export class MediaShowingDialogComponent implements OnInit {
  medialink: string;
  imageLing: string;
  imageFile: boolean;
  audioFile: boolean;
  videoFile: boolean;
  extension: string;
  filetype: string;
  fileloading: boolean;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  constructor(
    public store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private fileService: FileServiceService,
    public toastService: ToastService,
    public dataService: DataService,
    private dialogRef: MatDialogRef<MediaShowingDialogComponent>) {
      if (data !== null && data !== undefined) {
        this.fileloading = true;
        this.medialink = data.medialink;
      }
  }

  ngOnInit() {
    this.checkMediaType();
    this.filetype = this.getMime();
    const token = this.store.token;
    const project = this.dataService.project;

    this.store.showLoading();
    this.fileService.getPrivateFile(this.medialink, token, project).subscribe(
      res => {
        this.loadImage(res);
        this.store.hideLoading();
      },
      err => {
        this.fileloading = false;
        this.toastService.errorToast(err);
        this.store.hideLoading();
      });
  }

  closeDialog(event) {
    event.preventDefault();
    this.dialogRef.close();
  }

  getMime(): string {
    let t = '';
    if (this.audioFile) {
      t = `audio/${this.extension}`;
    } else if (this.videoFile) {
      t = `video/${this.extension}`;
    }
    return t;
  }

  checkMediaType() {
    const fileAndType = this.medialink.split('.');
    if (fileAndType !== null && fileAndType.length > 1) {
      this.extension = fileAndType[fileAndType.length - 1];

      if (this.ifMediaIsImage(this.medialink)) {
        this.imageFile = true;
        this.audioFile = false;
        this.videoFile = false;
      } else if (this.ifMediaIsVideo(this.medialink)) {
        this.imageFile = false;
        this.audioFile = false;
        this.videoFile = true;
      } else if (this.ifMediaIsAudio(this.medialink)) {
        this.imageFile = false;
        this.audioFile = true;
        this.videoFile = false;
      }
    }
  }

  ifMediaIsImage(value) {
    const reg = value.match(/\.(jpeg|jpg|gif|png)$/);
    return(reg !== null);
  }

  ifMediaIsAudio(value) {
   const reg = value.match(/\.(mp3|wav|raw|ogg)$/);
   return(reg !== null);
  }

  ifMediaIsVideo(value) {
    const reg = value.match(/\.(mp4|3gp|avi|vob|flv|mpeg)$/);
    return(reg !== null);
  }

  loadImage(response) {
    let image = null ;
    if (this.imageFile) {
      image = document.getElementById('myImage');
    } else if (this.audioFile) {
      image = document.getElementById('audioPlayer');
    } else if (this.videoFile) {
      image = document.getElementById('myVideo');
    }

    if (this.audioFile || this.videoFile) {

      image.src = window.URL.createObjectURL(response);
      image.load();
      image.loop = true;
      this.imageLing = window.URL.createObjectURL(response);
    } else {
      const reader = new FileReader();
      reader.onload = ( (i) => {
        return (e) => {
          if (i != null ) {
            i.src = e.target.result;
            this.imageLing = i.src;
          }
          // this.$.mediaDialog.style.height = i.height + 10;
          // this.$.mediaDialog.center();
        };
      })(image);
      reader.readAsDataURL(response);
    }
    this.fileloading = false;
  }
}
