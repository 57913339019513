<div class="admin-page-container">
  <div id="admin-container-div" class="admin-container">
    <div class="sidebar-admin">
      <div class="admin-home"><span>{{ "ADMINISTRATION" | translate }}</span></div>
      <ul id="navAdmin">
        <li class="user-info-admin">
          <a id="admininfo" class="menu-link" [ngClass]="{'selected-admin': selectedMenu === 'USER_INFORMATION'}" (click)="displayAdminInfo()">
            <span class="menu-text"><span>{{ "USER INFORMATION" | translate }}</span></span>
          </a>
        </li>
        <li class="password-reset-admin">
          <a id="resetpassword" class="menu-link" [ngClass]="{'selected-admin': selectedMenu === 'RESET_PASSWORD'}" (click)="displayResetPassword()">
            <span class="menu-text"><span>{{ "RESET PASSWORD" | translate }}</span></span>
          </a>
        </li>
          @if (hasManagedUsers) {
            <li class="managed-users-admin">
              <a id="users" class="menu-link" [ngClass]="{'selected-admin': selectedMenu === 'MANAGE_USER'}" (click)="displayAdminUsers()">
               <span>{{ "MANAGE USERS" | translate }}</span>
              </a>
            </li>
          }
        </ul>

      </div>
      <div class="divider"></div>
      <div id="adminContent" class="admin-content">
        @if (showUsersPage) {
          <app-mc-users-page></app-mc-users-page>
        }
        @if (showResetPasswordPage) {
          <app-reset-password-page (hideResetPassword)="hideResetPassword($event)"></app-reset-password-page>
        }
        @if (showAdminInfoPage) {
          <app-admin-info-page></app-admin-info-page>
        }
      </div>
    </div>
</div>
