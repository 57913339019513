import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { AfterContentInit, AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Attribute, AttributeSet } from 'src/app/share/feature/attributes';
import { FeatureSchema } from 'src/app/share/schema/schema';
import { Template } from 'src/app/share/template';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { FeatureService } from 'src/app/services/feature.service';
import {CommonModule, TitleCasePipe} from '@angular/common';
import { StoreService } from 'src/app/services/store.service';
import { getCurrentDomain, getTemplateFieldsNameAndTypes, getToken, saveTemplateFieldsNameAndTypes, url } from 'src/app/share/utils';
import { GeomTypeCoordinatesDialogComponent } from '../dialogs/geom-type-coordinates-dialog/geom-type-coordinates-dialog.component';
import { AttributesetarrayDialogComponent } from '../dialogs/attributesetarray-dialog/attributesetarray-dialog.component';
import { MediaShowingDialogComponent } from '../dialogs/media-showing-dialog/media-showing-dialog.component';
import $ from 'jquery';
import { FieldSet } from 'src/app/share/form/fieldset';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EditFeatureValueComponent } from '../dialogs/edit-feature-value/edit-feature-value.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { faClose, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ToastService} from '../../services/toast.service';
import {DataService} from '../../services/data.service';
import {ReportsService} from '../../services/reports.service';
import {MatTooltip} from '@angular/material/tooltip';
const ELEMENT_DATA: AttributeSet[] = [];

const COORDINATES = 'coordinates';
const ATTRIBUTESETARRAY = 'arrayattributeset';

const _SELECT = '_select';
@Component({
  standalone: true,
  imports: [TranslateModule,
    MatCheckboxModule,
    MessageBoxComponent,
    MatTableModule,
    MatIconModule,
    FontAwesomeModule,
    CommonModule,
    MatButtonModule, MatTooltip,
  ],
  selector: 'app-found-features',
  templateUrl: './found-features.component.html',
  styleUrls: ['./found-features.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FoundFeaturesComponent implements OnInit, AfterViewInit, AfterContentInit {

  GEOMETRY_TYPE = 'Geometry_Type';
  searchValue: string;
  displayedColumns: string[] = [];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  templateName: string;
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatTable) foundfeaturetable: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }

  @Input()
  template: Template;

  instances: any[] = [];

  featureSchema: FeatureSchema;
  initHeaders: any[];
  arrayHeadersAndSize: any[];
  geomHeader: any;
  attHeaders: any[];
  schemaHeader: any[];
  allCoordinates: any[];
  featureAttrib: any[] = [];
  missIndexes: any[] = [];

  allArrayAttributeSet: any[] = [];
  allArrayAttributeSetElemment: any[] = [];
  allCoordinatesFields: any[] = [];
  allCoordinatesFieldsElemment: any[] = [];
  arrayAttSetData: any[] = [];
  linksArray: any[];
  ColumsValues: any[] = [];
  columnsValues: any[];
  columnsName: any[] = [];
  advancedSearchCriteria: any;
  aggregation: any[] = [];
  fromDate: any;
  toDate: any;
  sortedObject: any;
  tableheaderobject: any;
  featureData: any[] = [];
  nbrPage: number;
  fetchMore: boolean;
  searchCriteria: any;
  numindex: number;
  templateId: string;
  nextLink: string;
  prevLink: string;
  pageFeatures: number;
  totalFeatures: number;
  featuresSelected: any[];
  operators: any[];
  doingReport: boolean;
  metadata: object;
  more: boolean;
  resultsLength = 0;
  initValue = 0;
  uri: string;
  pages = 0;
  allPages: string[];
  selectedPage: string;
  activePage: number;

  message = '';
  showMessage = false;
  constructor(
    private dialog: MatDialog,
    private titlecasePipe: TitleCasePipe,
    private featureService: FeatureService,
    public store: StoreService,
    private translate: TranslateService,
    public reportsService: ReportsService,
    public dataService: DataService,
    public toastService: ToastService,
  ) { }

  ngOnInit() {
    if(this.store.archived) {
      // return;
    }

    // this.store.selectedFoundTemplate.subscribe(t=> {
    //   this.template = t;
    //   this.columnsValues = [];

    //   this.initElements(this.template);
    //   this.loadFeatures();
    // });

  }

  ngAfterContentInit() {
    this.columnsValues = [];
    if(this.store.archived) {
      // return;
    }

    this.initElements(this.template);
    this.store.features = null;
    this.loadFeatures();

    this.store.selectedFoundTemplate.subscribe(t=> {
      this.store.features = null;
      this.template = t;
      this.columnsValues = [];

      this.initElements(this.template);
      this.loadFeatures();
    });
  }

  ngAfterViewInit() {

  }

  resetSelectedFeature() {
    this.store.features = null;
    this.selection.clear();
    this.store.copiedFeatures = [];
  }

  deleteSelectedFeatures() {
    if((this.store.copiedFeatures && this.store.copiedFeatures.length > 0) ||
      (this.store.features && this.store.features.length > 0)) {
        const size = this.store.features.length;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '400px',
          data: {
            message: this.translate.instant("THESE RECORDS WILL BE DELETED ON THE SERVER"),
            title: this.translate.instant("DELETE") + " " + size + " " + this.translate.instant("RECORD(S)?")
          }
        });
        dialogRef.afterClosed().subscribe(result  => {

          if (result) {
            const token = getToken();
            this.store.showLoading();
            const ids = [];
            const features = this.store.copiedFeatures && this.store.copiedFeatures.length > 0 ? this.store.copiedFeatures : this.store.features;
            for (const feature of features) {
              ids.push(feature.id);
            }
            const project = this.store.destinationProject ? this.store.destinationProject : this.dataService.project;
            const template = this.store.destinationTemplate ? this.store.destinationTemplate : this.dataService.template.getValue();
            this.featureService.deleteFeatures(ids, template, project, token).subscribe(
              res => {
                this.store.features = null;
                this.toastService.successToast(size + ' record(s) deleted');
                this.selection.clear();
                this.instances = this.instances.filter(f => ids.indexOf(f.id) < 0);
                this.updateDataFormServer(this.instances);
                this.store.hideLoading();
              },
              errmes => {
                this.store.hideLoading();
                this.toastService.errorToast('Error while deleting the features' + errmes);
              }
            );
          }
        });
    } else {
      this.toastService.errorToast('No records selected');
    }
  }

  loadFeatures() {
    try{
      this.store.showLoading();

      this.store.listFoundFeatures = this.store.foundProjects['features'].filter(f => f.template_id === this.template.id);

      this.instances = this.store.foundProjects['features'].filter(f => f.template_id === this.template.id);
      this.updateDataFormServer(this.instances);
    } catch (e){
      this.store.hideLoading();
    }

  }


  initElements(template) {
    if (template) {
      this.templateName = template.name;
    }

    this.nbrPage = 100;
    this.initValue = 0;
    this.fetchMore = false;
    this.attHeaders = [];
    this.featureData = [];
    this.instances = [];
    this.allCoordinates = [];
    this.allCoordinatesFields = [];
    this.allCoordinatesFieldsElemment = [];
    this.allArrayAttributeSet = [];
    this.allArrayAttributeSetElemment = [];
    this.searchCriteria = {};
    this.numindex = 0;
    this.resultsLength = 100;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.doingReport = false;
    this.allPages = [];
    this.pages = 0;
    this.activePage = 0;
    this.selectedPage = '';
  }


  updateDataFormServer(features) {
    this.featuresSelected = [];
    this.allPages = [];
    const template = this.template;

    if (template !== null && template !== undefined) {
      this.template = template;
      this.featureSchema = template.feature;
      this.saveTemplateFieldsNameAndType(template);
    }

    this.featureData = [];
    this.resultsLength = features.length;

    const headers = this.reportsService.createHeaderArrayWithinTemplate(template);
    this.attHeaders = headers;
    if (this.instances.length > 0) {
      const data = this.reportsService.createFeatureData();

      let listGeometryHeader = null;
      if (this.instances[0]['geometry'] !== null && this.instances[0]['geometry'] !== undefined) {
        listGeometryHeader = Object.keys(this.instances[0]['geometry']);
      }
      if (this.columnsValues.length > 0) {
        this.displayedColumns = [];
        this.displayedColumns.push(_SELECT);
        const index = this.columnsValues.indexOf('Geometry Type');
        if (listGeometryHeader !== null && listGeometryHeader !== undefined && index !== -1) {
          this.displayedColumns.push(this.GEOMETRY_TYPE);
        }
        for (const val of this.columnsValues) {
          if (val !== 'Geometry Type') {
            this.displayedColumns.push(val);
          }
        }
      } else if (headers !== null && headers !== undefined && headers.length > 0) {
        this.displayedColumns = [];
        this.displayedColumns.push(_SELECT);
        if (listGeometryHeader !== null && listGeometryHeader !== undefined) {
          this.displayedColumns.push(this.GEOMETRY_TYPE);
        }
        for (const h of headers) {
          this.displayedColumns.push(h);
        }
      }
      this.initValue = 1;
      this.pageFeatures =  this.instances.length;
      this.totalFeatures =  this.instances.length;
      const $items = $('.bleuPage');
      const classHighlight = 'selectedPage';
      $items.removeClass(classHighlight);
      this.selectedPage = this.activePage + ' ';
      this.dataSource.data = data;
      this.foundfeaturetable.renderRows();
    } else {
      this.initValue = 0;
      this.pageFeatures = 0;
      this.totalFeatures = 0;

      this.displayedColumns = [];
      this.dataSource.data = [];
      this.foundfeaturetable.renderRows();
    }
    this.store.hideLoading();
  }


  initializeAttributeSet(fieldset: FieldSet): any {
    const attSet = {};
    for (const field of fieldset.fields) {
      if (field._class === 'fieldset' || field._class === 'arrayfieldset' || field._class === 'fieldsetarray' ) {
        if (field._class === 'fieldset') {
          attSet[field.name] = this.initializeAttributeSet(field as FieldSet);
        } else {
          attSet[field.name] = [];
        }
      } else {
        const val = field.default_value ? field.default_value : null;
        attSet[field.name] = val;
      }
    }
    return attSet;
  }



  showMessageDialog(msg) {
    this.message = msg;
    this.store.showLoading();
    setTimeout(() => {
      this.store.hideLoading();
    }, (10000));
  }

  displayItemValue(column, value) {
    const fieldsAndType = getTemplateFieldsNameAndTypes();
    const type = fieldsAndType[column];
    if (type === COORDINATES) {
      return COORDINATES;
    } else if (type === ATTRIBUTESETARRAY) {
      return this.displayAttSetArrayValue(column);
    } else {
      const data = this.checkFiles(value);
      if (this.checkIfIsUrl(data)) {
        return '';
      } else if (type === 'date' && this.dataService.checkIfDate(data)) {
        return data.split('T')[0];
      }
      return data;
    }
    return '';
  }

  displayAttSetArrayValue(column) {
    const index = column.indexOf('.');
    if (index > 0) {
      const values = column.split('.');
      return values[values.length - 1];
    }
    return column;
  }

  checkIfColumnIsGeometry(column) {
    return column === this.GEOMETRY_TYPE;
  }

  checkIfCoordinatesField(column) {
    const fieldsAndType = getTemplateFieldsNameAndTypes();
    const type = fieldsAndType[column];
    return type === COORDINATES;
  }

  checkIfAttributesetArray(column) {
    const fieldsAndType = getTemplateFieldsNameAndTypes();
    const type = fieldsAndType[column];
    return type === ATTRIBUTESETARRAY;
  }

  selectFeature(event, element): void {

    this.selection.toggle(element);
    const feature = this.instances.find(f => f.id === element.id);
    if (event.checked) {
      if (this.store.features) {
        this.store.features.push(feature);
      } else {
        this.store.features = [feature];
      }
    } else {
      if (this.store.features) {
        this.store.features = this.store.features.filter(f => f.id !== element.id);
      }
    }
  }

  openMediaShowingDialog(event, link) {
    event.preventDefault();
    const medialink = this.dataService.checkFile(link);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    dialogConfig.data = {
      medialink
    };

    const dialogRef = this.dialog.open(MediaShowingDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // if (result.event === 'Add') {
      // }
    });
    event.stopPropagation();
  }

  openEditFeatureValue(column, element) {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.showMessageDialog(`Please, this operation is only available for active  ${projectClass}`);
      return;
    }
    const dialogConfig = new MatDialogConfig();
    const columnValues = column.split('.');
    let newColumn = '';
    if(columnValues.length > 0)
      newColumn = columnValues[columnValues.length - 1];
    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    const id = element['id']
    let feature = null;
    let featureIndex = -1;
    let type = '';
    if (id !== null && id !== undefined) {
      feature = this.instances.filter(f => f.id === id)[0];
      featureIndex = this.instances.indexOf(feature);
    }

    const attributes = this.template.feature.attributes;

    type = this.getAttributeType(attributes, newColumn);
    const value = element[column];
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      column,
      feature,
      element,
      value,
      type
    };
    if(type === 'file' || type === undefined || type === '')
      return;

    const dialogRef = this.dialog.open(EditFeatureValueComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== undefined) {
        if (result.event === 'updated') {
          const updatedFeature = result.feature;
          const project = this.dataService.project;
          const token = getToken();
          this.store.showLoading();

          this.template.feature.geometry_type = updatedFeature.geometry ?
          updatedFeature.geometry.type : null;
          const attributes = this.template.feature.attributes as Attribute[];
          const attrKeys = Object.keys(updatedFeature.attributes);
          const attrNames = [];

          attributes.map(attribute => {
            attrNames.push(attribute.name);
            if (!this.valueExistInArarray(attribute.name, attrKeys)) {
              const tpe = attribute.type;
              let val = null;
              if (tpe === 'file') { val = []; }
              updatedFeature.attributes[attribute.name] = val;
            }

          });

          attrKeys.map( val => {
            if (!this.valueExistInArarray(val, attrNames)) {
              delete updatedFeature.attributes[val];
            }
          });
          this.featureService.updateFeature(updatedFeature, project, token)
          .subscribe(
            res => {
              const tempFeat = (feat) => feat.id === res.id;
              if  (featureIndex >= 0) {
                this.instances[featureIndex] = res;
                const data = this.reportsService.createFeatureData();
                this.dataSource.data = data;
                this.foundfeaturetable.renderRows();
              }
              this.store.hideLoading();
            },
            err => {
              this.toastService.errorToast(err);
              this.store.hideLoading();
            });
        }
      }
    });
  }

  isSelectFeatureHeader(column) {
    return column === '_select';
  }

  isSelectFeatureData(column, element) {
    return column === '_select';
  }

  openAttributesetArrayDialog(column, element) {
    const attributesetarray = element[column];
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '700px';
    dialogConfig.height = 'auto';
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      attributesetarray: element[column],
      path: column
    };

    const dialogRef = this.dialog.open(AttributesetarrayDialogComponent, dialogConfig);
  }

  displayCoordinates(column, element) {
    const geomType = element[column];
    const coordinates = element[COORDINATES];
    let gpscoord = false;
    if (coordinates !== null && coordinates !== undefined && coordinates.length > 0) {
      if (coordinates[0] instanceof Object) {
        gpscoord = false;
      } else {
        gpscoord = true;
      }
    }
    this.openCoordinateDialog(geomType, coordinates, gpscoord);
  }

  displayGPSCoordinates(column, element) {
    const geomType = element[column];
    const coordinates = element[COORDINATES];
    let gpscoord = false;
    if (coordinates !== null && coordinates !== undefined && coordinates.length > 0) {
      if (coordinates[0] instanceof Object) {
        gpscoord = false;
      } else {
        gpscoord = true;
      }
    }
    this.openCoordinateDialog(null, geomType, gpscoord);
  }

  openCoordinateDialog(geometryType, coordinates, gpscoord = false) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '600px';
    dialogConfig.height = 'auto';
    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      geometry_type: geometryType,
      gpscoord,
      coordinates
    };

    const dialogRef = this.dialog.open(GeomTypeCoordinatesDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  getAttributeType(attributes, column): string {
    let type = '';

    if (attributes.length > 0) {
      for (let i = 0; i < attributes.length; i++) {
        const attribute = attributes[i];

        if(attribute._class === 'attributeset')
          type = this.getAttributeType(attribute.attributes, column);

        if(attribute.name === column){
          type = attribute.type;
          return type;
        }
      }
    }
    return type;
  }

  valueExistInArarray(value, array): boolean{
    if(array.length <= 0){
      return false;
    }

    for(let i = 0; i < array.length; i++){
      if(array[i] === value)
        return true;
    }

    return false;
  }

  isDataIsFile(column, data): boolean {
    const fieldsAndType = getTemplateFieldsNameAndTypes();
    if (fieldsAndType[column] === ATTRIBUTESETARRAY) {
      return false;
    }
    if (fieldsAndType[column] === COORDINATES) {
      return false;
    }
    const temp = this.checkFiles(data);
    return this.checkIfIsUrl(temp);
  }

  checkIfIsUrl(data) {
    const pat = /^https?:\/\//i;
    let values = [];

    if (data === undefined || data === null) {
      return false;
    }
    let str;
    if ((data instanceof Object) && (data instanceof Array) && data.length > 0) {
      str = data[0];
    } else {
      str = data;
    }
    if (isNaN(str)) {
      if (typeof(str) === 'string') {
        values = str.split('/');
      }
    }
    const currentDomain = getCurrentDomain();
    if (pat.test(str) && values.includes(currentDomain) && values.includes('v1') && values.includes('files')) {
      return true;
    }
    return false;
  }

  // constructing the header of the table using the attributes of the template.

  /**
   *  building the header of the geometry part of templates based on the highest length of features.
   */
  buildGeometryHeader(geometryType, size) {
    let geomTypeheaderStr = this.buildGeometryType(geometryType);
    const geometryHeader = [];
    switch (geometryType) {
      case 'Point':
        geomTypeheaderStr =  geomTypeheaderStr + '' + this.buildPointHeader();
        // geometryHeader.push(geometryType);
        break;

      case 'LineString':
        geomTypeheaderStr =  geomTypeheaderStr + '' + this.buildGeometryObjHeader(size);
        // geometryHeader.push(geometryType);
        break;

      case 'Polygon':
        geomTypeheaderStr =  geomTypeheaderStr + '' + this.buildGeometryObjHeader(size);
        // geometryHeader.push(geometryType);
        break;

      case 'MultiPoint':
        geomTypeheaderStr =  geomTypeheaderStr + '' + this.buildMultiPointHeader();
        // geometryHeader.push(geometryType);
        break;

      case 'MultiLineString':
        geomTypeheaderStr =  geomTypeheaderStr + '' + this.buildMultiLineStringHeader();
        // geometryHeader.push(geometryType);
        break;

      case 'MultiPolygon':
        geomTypeheaderStr =  geomTypeheaderStr + '' + this.buildMultiPolygonHeader();
        // geometryHeader.push(geometryType);
        break;
    }
    this.geomHeader = geomTypeheaderStr.split('');

    if (geometryType !== '' && geometryType !== null && geometryType !== undefined) {
      geometryHeader.push('Geometry Type');
    }

    return geometryHeader;
  }

  buildGeometryType(type) {
    return 'geometry.type';
  }

  buildPointHeader() {
    return 'x y z Accuracy';
  }

  buildGeometryObjHeader(size) {
    let polygonHeader = '';
    if (size > 0) {
      for (let i = 1; i <= size; i++) {
        if (polygonHeader === '') {
          polygonHeader = polygonHeader + 'p' + i + '.x p' + i + '.y p' + i + '.z p' + i + '.Accuracy';
        } else {
          polygonHeader = polygonHeader + ' p' + i + '.x p' + i + '.y p' + i + '.z p' + i + '.Accuracy';
        }

      }
      return polygonHeader;
    }
    return null;
  }

  buildMultiPointHeader() {
    return null;
  }

  buildMultiLineStringHeader() {
    return null;
  }

  buildMultiPolygonHeader() {
    return null;
  }

  // building the attributes header
  buildAttributesHeader(attribs, prefix = '') {
    const tempAttributesHeaderArray = [];
    this.initHeaders = [];
    this.buildAttributesetHeader(attribs, tempAttributesHeaderArray);

    for (const item of tempAttributesHeaderArray) {
        this.initHeaders.push(item);
    }
    return tempAttributesHeaderArray;
  }

  // building the attributeset with nested attributes header
  buildAttributesetHeader(attributes, headerArray, prefix = '') {
    const prefixEmpty = prefix === '' || prefix === null || prefix === undefined;

    const size = attributes.length;

    for (let i = 0; i < size; i++) {
      const attribute = attributes[i];
      if (attribute._class === 'attributeset') {
        prefix = prefixEmpty ? attribute.name : prefix + '.' + attribute.name;
        this.buildAttributesetHeader(attribute.attributes, headerArray, prefix);
      } else if (attribute._class === 'arrayattributeset') {
        const curHeader = prefixEmpty ? attribute.name : prefix + '.' + attribute.name;
        headerArray.push(curHeader);
      } else {
        const curHeader = prefixEmpty ? attribute.name : prefix + '.' + attribute.name;
        headerArray.push(curHeader);
      }
    }
  }

  getHeaderInSplitMode(h): string {
    const parts = h.split('.');
    if (parts && parts.length > 0) {
      let tempHeader = '';
      for(const part of parts) {
        tempHeader = tempHeader === '' ? part : `${tempHeader} <br /> ${part}`;
      }
      return tempHeader;
    }
    return h;
  }

  // building the values of features to display in the table.

  // send the request for fetching features from the server.
  fetchFeatures() {
    this.linksArray = [];
    if (this.nbrPage === 0) {
      this.nbrPage = 100;
    }

    if (this.template.id === undefined || this.template.id === null || this.template.id === '') {
      return;
    }

  }

  saveAttributesetNamesAndValue(attributeset, parent, fieldsAndValues) {
    const keys = Object.keys(attributeset);
    for (const attName of keys) {
      // let attName = keys[i];
      const obj = attributeset[attName];
      if (typeof(obj) === 'object') {
        const parentname = (parent !== null && parent !== undefined && parent !== '')
                              ? parent + '.' + attName
                              : attName;
        // this.saveAttributesetNamesAndType(obj, parentname, fieldsAndValues);
      } else {
        // this.saveAttributesetNamesAndType(obj, parent, fieldsAndValues);
      }
    }
  }

  saveTemplateFieldsNameAndType(template) {
    const feature = template.feature;
    const attributes = feature.attributes;
    const parent = '';
    let fieldsAndType = {};
    const set = {};

    for (const attribute of attributes) {
      // let attribute = attributes[i];
      if (attribute._class === 'attributeset' ) {
        const temparent = (parent !== null && parent !== undefined && parent !== '')
                              ? `${parent}.${attribute.name}`
                              : attribute.name;
        fieldsAndType = Object.assign(fieldsAndType, this.reportsService.saveAttributesetNamesAndType(attribute, temparent));
      } else if (attribute._class === 'arrayattributeset') {
        const name = (parent !== null && parent !== undefined && parent !== '')
                              ? `${parent}.${attribute.name}`
                              : attribute.name;
        fieldsAndType[name] = 'arrayattributeset';
      } else {
        const attname = (parent !== null && parent !== undefined && parent !== '')
                              ? `${parent}.${attribute.name}`
                              : attribute.name;
        const type = attribute['type'];
        fieldsAndType[attname] = type;
      }
    }
    this.schemaHeader = Object.keys(fieldsAndType);
    saveTemplateFieldsNameAndTypes(fieldsAndType);
  }

  readToken(path, token) {
    const tempValue = path.split(token);
    if (tempValue.length > 1) {
      return tempValue;
    }
    return null;
  }

  // check if the template is geometry or no geometry.
  checkGeometryObject(value) {
    const size = this.geomHeader.length;
    for (let i = 0; i < size; i++) {
      if (this.geomHeader[i] === value) {
        return true;
      }
    }
    return false;
  }

  // building coordinates elements from features.
  buildGeometryData(geometry) {
    const tempGeomArray = [];
    const newCoordinates = [];
    const coordValue = [];

    if (geometry !== null && geometry !== undefined) {
      this.buildGeometryObjectData(geometry, tempGeomArray, '');
      for (let i = 1; i < tempGeomArray.length; i += 4) {
        const coordinate = {
          x: tempGeomArray[i],
          y: tempGeomArray[i + 1],
          z: tempGeomArray[i + 2],
          Accuracy: tempGeomArray[i + 3]
        };
        newCoordinates.push(coordinate);
      }
      this.allCoordinates.push(newCoordinates);
    } else {
      // tempGeomArray.push('No geometry');
      // coordValue.push('No geometry');
    }
    return coordValue;
  }

  // building the features' values of geometry types to display in the table.
  buildGeometryObjectData(geometry, dataArray, path) {
    const type = geometry.type;

    const propertyNames = Object.getOwnPropertyNames(geometry);
    const size = propertyNames.length;

    for (let i = 0; i < size; i++) {
      const name = propertyNames[i];
      let attribute = geometry[name];
      let currentPath = path === '' ? '' : path + '.';
      if (name === 'coordinates' && type === 'LineString') {
        this.buildPolygonData(attribute, dataArray);
      } else if (name === 'coordinates' && type === 'Polygon') {
        attribute = attribute[0];
        this.buildPolygonData(attribute, dataArray);
      } else {
        currentPath = currentPath + name;
        if (attribute === null || attribute === undefined) {
          if (this.findObjectInStoredArraySizes(currentPath) > 0) {
            this.buildArrayData(attribute, dataArray, currentPath);
          }
        } else {
          if (attribute instanceof Object) {
            if (attribute instanceof Array) {
              this.buildArrayData(attribute, dataArray, currentPath);
            } else {
              this.buildObjectData(attribute, dataArray, currentPath);
            }
          } else {
            dataArray.push(geometry[name]);
          }
        }
      }
    }
  }

  buildPolygonData(coordinates, dataArray) {
    let highestLength = 0;
    for (const item of this.arrayHeadersAndSize) {
      if (item.name === 'coordinates') {
        highestLength = item.size;
        break;
      }
    }
    let size = 0;
    if (coordinates !== null && coordinates !== undefined) {
      size = coordinates.length;
    }

    for (let i = 0; i < size; i++) {
      const data = coordinates[i];

      if (data instanceof Object) {
        if (data instanceof Array) {
          for (const item of data) {
            dataArray.push(item);
          }
        }
      } else {
        dataArray.push(data);
      }
    }
    if (highestLength > size) {
      const remainSize = highestLength - size;
      for (let l = 0; l < remainSize; l++) {
        dataArray.push('');
        dataArray.push('');
      }
    }
  }

  // building attributes values putting values in array.
  buildAttributesData(attributes) {
    const tempAttributesArray = [];
    this.featureAttrib = [];
    this.missIndexes = [];
    this.buildObjectData(attributes, tempAttributesArray, '', null);
    return tempAttributesArray;
  }

  // parsing features to get the highest feature in the features.
  parseFeatureInstance(highestAttributeIndex) {
    if (highestAttributeIndex >= 0) {
      const featureInstance = this.instances[highestAttributeIndex];
      this.parseAttributesObject(featureInstance.attributes, '');
    }
  }

  /**
   * getting the values of nested attributes.
   */
  parseAttributesObject(attributes, path) {
    const propertyNames = Object.getOwnPropertyNames(attributes);
    const size = propertyNames.length;

    for (let i = 0; i < size; i++) {
      const name = propertyNames[i];
      const attribute = attributes[name];
      let currentPath = path === '' ? '' : path + '.';
      currentPath = currentPath + name;
      if (!this.checkArrayAttributeSet(currentPath)) {
        if (attribute instanceof Object) {
          if (attribute instanceof Array) {
            if (!this.checkCoordinateField(currentPath)) {
              this.parseArrayObject(attribute, currentPath);
            }
          } else {
            this.parseAttributesObject(attribute, currentPath);
          }
        }
      }
    }
  }

  parseArrayObject(attribute, path) {
    const size = attribute.length;
    const tempSize = this.findArrayMaxSize(path, size);
    if (this.findObjectInStoredArraySizes(path) <= 0) {
      const element =  {
        name: path,
        size: tempSize
      };
      this.arrayHeadersAndSize.push(element);
    }

    this.replaceHeaderInHeaders(path, tempSize);
    for (let i = 0; i < size; i++) {
      const data = attribute[i];
      const arrPath = path + '[' + i + ']';
      if (data instanceof Object) {
        if (data instanceof Array) {
          this.parseArrayObject(data, arrPath);
        } else {
          this.parseAttributesObject(data, arrPath);
        }
      } else {

      }
    }
  }

  // If the template contains file types find the longest array in order to fill others with empty space.
  findArrayMaxSize(path, size) {
    let tempSize = size;
    for (const instance of this.instances) {
      const attributes = instance.attributes;
      const findSize = this.findArrayMaxSizeInObject(attributes, path, '', tempSize);
      if (findSize > 0) {
        tempSize = findSize;
      }
    }
    return tempSize;
  }

  checkCoordinateField(path) {
    const fieldAndTypes = getTemplateFieldsNameAndTypes();
    const type = fieldAndTypes[path];
    if (type === 'coordinates') {
      return true;
    }

    return false;
  }

  checkArrayAttributeSet(path) {
    const fieldAndTypes = getTemplateFieldsNameAndTypes();
    const type = fieldAndTypes[path];
    if (type === 'arrayattributeset') {
      return true;
    }

    return false;
  }

  findArrayMaxSizeInObject(attribute, searchPath, path, size) {
    const propertyNames = Object.getOwnPropertyNames(attribute);

    for (const name of propertyNames) {
      // const name = propertyNames[j];
      const tempAttribute = attribute[name];
      let currentPath = path === '' ? '' : path + '.';
      currentPath = currentPath + name;

      if (tempAttribute instanceof Object) {
        if (tempAttribute instanceof Array) {
          if (searchPath === currentPath) {
            if (tempAttribute.length > size) {
              return tempAttribute.length;
            }
          }
        } else {
          this.findArrayMaxSizeInObject(tempAttribute, searchPath, currentPath, size);
        }
      }
    }
    return 0;
  }

  // adding [] to array's type in the header of the table.
  replaceHeaderInHeaders(element, size) {
    if (size === 0) {
      return;
    }

    for (let i = 0; i < this.attHeaders.length; i++) {
      if (this.attHeaders[i] === element) {
        let elements = '';
        for (let j = 0; j < size; j++) {
          elements = elements === '' ? '' : elements + ' ';
          elements = elements + element + '[' + j + ']';
        }
        const splitElements = elements.split('');
        for (let k = 0; k < splitElements.length; k++) {
          if (k === 0) {

            this.attHeaders.splice(i + k, 1, splitElements[k]);
          } else {
            this.attHeaders.splice(i + k, 0, splitElements[k]);
          }
        }
        return;
      }
    }
  }

  // buiding values of nested objects and arrays
  buildObjectData(attributes, dataArray, path, oldValues = null) {
    let propertyNames = [];
    this.initHeaders = this.splitHeaderValues(this.initHeaders);
    if (oldValues === null) {
      propertyNames = this.initHeaders; // Object.getOwnPropertyNames(attributes);
    } else {
      propertyNames = Object.getOwnPropertyNames(attributes);
    }
    // localStorage.setItem('template_properties' JSON.stringify(propertyNames));
    const fieldsAndType = getTemplateFieldsNameAndTypes();
    const size = propertyNames.length;

    for (let i = 0; i < size; i++) {
      let name = propertyNames[i];
      const nameType = fieldsAndType [name];
      const value = name.split('.');
      if (value.length > 1) {
        name = value[0];
        value.shift();
      }
      const attribute = attributes[name];
      let currentPath = path === '' ? '' : path + '.';
      currentPath = currentPath + name;
      if (attribute === null || attribute === undefined) {
        if (this.findObjectInStoredArraySizes(currentPath) > 0) {
          this.buildArrayData(attribute, dataArray, currentPath);
        } else {
          this.featureAttrib.push(name);
          dataArray.push('');
        }
      } else {
        if (attribute instanceof Object) {
          if (attribute instanceof Array) {
            if (this.checkArrayAttributeSet(currentPath)) {
              this.allArrayAttributeSet.push(attribute);

              const elt = currentPath + '_' + this.allArrayAttributeSetElemment.length;
              this.allArrayAttributeSetElemment.push(elt);
              dataArray.push(elt);
            } else if (this.checkCoordinateField(currentPath)) {
              const coordinate = {
                x: attribute[0],
                y: attribute[1],
                z: attribute[2],
                Accuracy: attribute[3]
              };
              this.allCoordinatesFields.push(coordinate);
              const elt = currentPath + '_' + this.allCoordinatesFieldsElemment.length;
              this.allCoordinatesFieldsElemment.push(elt);
              dataArray.push(elt);
            } else {
              this.buildArrayData(attribute, dataArray, currentPath);
            }
          } else {
            this.buildObjectData(attribute, dataArray, currentPath, value);
          }
        } else if (attribute instanceof Array) {
          if (this.checkArrayAttributeSet(currentPath)) {
            this.allArrayAttributeSet.push(attribute);

            const elt = currentPath + '_' + this.allArrayAttributeSetElemment.length;
            this.allArrayAttributeSetElemment.push(elt);
            dataArray.push(elt);
          }
        } else {
          this.featureAttrib.push(name);
          if (this.checkIfValueInArray(name)) {
            let data = this.checkFiles(attributes[name]);
            if (this.checkIfIsUrl(data)) {
              this.linksArray.push(data);
            } else if (this.dataService.checkIfDate(data)) {
              data = data.split('T')[0];
            }
            dataArray.push(data);
          }
        }
      }
    }
  }

  // After displaying data in the table replace the coordinates with the links to be clicked.

  // display the coordinates dialog.

  // display the coordinates dialog.

  // After displaying data in the table replace the Array AttributeSet with the links to be clicked.

  createAttributesetElementData(attributes, data = []) {
    const size = attributes.length;

    for (let i = 0; i < size; i++) {
      const obj = attributes[i];
      data = [];
      const tempProperties = Object.keys(obj);
      for (const tempProperty of tempProperties) {
        const element = obj[tempProperty];
        if (element instanceof Object) {
          if (element instanceof Array) {
            let eltData = '';
            const s = element.length;
            if (s > 0) {
              for (let k = 0; k < s; k++) {
                eltData += element[k] + '\n';
              }
            }
            data.push(eltData);
          } else {
            this.createAttributesetElementData(element, data);
          }
        } else {
          data.push(element);
        }
      }
      this.arrayAttSetData.push(data);
    }
  }

  getElementText(value) {
    let name = value;

    const elts = value.split('_');
    if (elts.length > 0) {
      name = elts[0];
    }

    return name;
  }

    /*
    * check if value is a media file and return the complete URL.
    * @param: the value to check
    * @return: the URL or the string
    */
  checkFiles(value) {
    if (value instanceof Array && value.length > 0) {
      const firstVal = value[0];
      return this.dataService.checkFile(firstVal);
    } else {
      return value;
    }
  }

  getFilesUrl(value) {
    const filesUrls = [];
    if (value instanceof Array && value.length > 0) {
      value.map(element => {
        filesUrls.push(this.checkFiles(element));
      });
    } else {
      return value;
    }
    return filesUrls;
  }


  // split array data build in string in to elements of an array.
  splitHeaderValues(headers) {
    const values = [];
    if (headers.length === 0) {
      return;
    }

    for (const header of headers) {
      const value = header.split('.');
      if (!values.includes(value[0])) {
        values.push(value[0]);
      }
    }
    return values;
  }

  // Check if the header items are the attributes of the template to display empty space in the features
  // collected before create that field.

  splitArrayValues(value) {
    const values = value.split('[');
    if (values.length > 1) {
      return true;
    }
    return false;
  }

  checkAttributes(value) {
    if (this.featureAttrib.length > 0) {
      for (const attrib of this.featureAttrib) {
        if (this.getNameOfHeader(value) === attrib) {
          return true;
        }
      }
      return false;
    }
  }

  checkIfValueInArray(value) {
    if (this.attHeaders.length > 0) {
      for (const attHeader of this.attHeaders) {
        if (this.getNameOfHeader(attHeader) === value) {
          return true;
        }
      }
      return false;
    }
  }

  getNameOfHeader(value) {
    const values = value.split('.');
    if (values.length > 0) {
      return values[values.length - 1];
    }
    return value;
  }

  // building the data of arrays(media files values).
  buildArrayData(attribute, dataArray, path = '') {
    const highestLength = this.findObjectInStoredArraySizes(path);
    let size = 0;
    if (attribute !== null && attribute !== undefined) {
      size = attribute.length;
    }

    if (size === 0 && highestLength === 0) {
      dataArray.push('');
    }

    for (let i = 0; i < size; i++) {
      let data = attribute[i];

      if (data instanceof Object) {
        if (data instanceof Array) {
          this.buildArrayData(data, dataArray);
        } else {
          this.buildObjectData(data, dataArray, '');
        }
      } else {
        data = this.checkFiles(data);
        if (this.checkIfIsUrl(data)) {
          this.linksArray.push(data);
          dataArray.push(data);
        } else {
          dataArray.push(data);
        }
      }
    }
    if (highestLength > size) {
      const remainSize = highestLength - size;
      for (let j = 0; j < remainSize; j++) {
        dataArray.push('');
      }
    }
  }

  findObjectInStoredArraySizes(path) {
    for (const header of this.arrayHeadersAndSize) {
      if (header.name === path) {
        return header.size;
      }
    }
    return 0;
  }

  countObjectDataLength(attributes, path = '') {
    const propertyNames = Object.getOwnPropertyNames(attributes);
    const size = propertyNames.length;
    let currentSize = size;
    for (let i = 0; i < size; i++) {
      const name = propertyNames[i];
      const attribute = attributes[name];
      let currentPath = path === '' ? '' : path + '.';
      currentPath = path + name;
      if (attribute instanceof Object) {
        if (attribute instanceof Array) {
          currentSize = currentSize + this.countArrayDataLength(attribute, currentPath);
        } else {
          currentSize = currentSize + this.countObjectDataLength(attribute, currentPath);
        }
      }
    }

    return currentSize;
  }

  countArrayDataLength(attribute, path = '') {
    const size = attribute.length;
    let currentSize = size;
    for (let i = 0; i < size; i++) {
      const data = attribute[i];
      if (data instanceof Object) {
        if (data instanceof Array) {
          currentSize = currentSize + this.countArrayDataLength(data);
        } else {
          currentSize = currentSize + this.countObjectDataLength(data);
        }
      }
    }
    return currentSize;
  }

  /*************************************************************/

  isEmpty(obj) {
    if (obj === null || obj === undefined) {
      return true;
    }

    for (const prop of Object.keys(obj)) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
    }

    return true && JSON.stringify(obj) === JSON.stringify({});
  }

  // manage the choices selected by user in advanced searching.

  showAllColumns() {
    // $('#featureData thead th #featureData tbody td').show();
  }

  // hiding unselected columns in the table.

  // display all the columns in the table.

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
        this.dataSource.data.map(row => this.selection.select(row));
  }

  selectAllFeatures(event) {

    this.masterToggle();
    this.store.features = null;
    if (event.checked) {
      this.store.features = this.instances;
      /*
      this.dataSource.data.map(row => {
        const feature = this.instances.find(f => f.id === row.id);
        this.store.features.push(row);
      });
      */
    }
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }


  protected readonly faTrash = faTrash;

  protected readonly faClose = faClose;
}
