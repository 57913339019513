import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatDialogModule,
    MatButtonModule,
  ],
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss', '../dialog-btns.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  message: string;
  title: string;
  formDuplication: boolean;
  constraintList: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
    ) {
      if (data) {
        this.message = data.message;
        this.title = data.title;
        const formDupl = data.formDuplication;
        this.constraintList = data.constraintList;
        if (formDupl !== null && formDupl !== undefined) {
          this.formDuplication = data.formDuplication;
        } else {
          this.formDuplication = false;
        }
      }
    }

  ngOnInit() {
  }

  confirmChanges(event) {
    event.preventDefault();
    event.stopPropagation();
    const res = {
      response: 'Yes'
    };
    this.dialogRef.close(res);
  }

  onNoClick(event): void {
    event.preventDefault();
    this.dialogRef.close(null);
    event.stopPropagation();
  }
}
