<div fxFlexLayout="row">
  <div fxFlex fxFlexOffset="20px">
    <h3><span>{{ "EDIT DATA" | translate }}</span></h3>
    <form novalidate [formGroup]="editDataForm" #fform="ngForm" (ngSubmit)="onSubmit()" (keydown)="onKeySubmit($event)">
      <mat-dialog-content>
        @if (type === 'boolean') {
          <p style="margin-top: 15px; height: 35px;">
            <mat-radio-group class="full-width" formControlName="name" color="primary" (change)="onmychange($event)" aria-label="Select an option">
              <mat-radio-button value="1">True</mat-radio-button>
              <mat-radio-button value="2">False</mat-radio-button>
            </mat-radio-group>
          </p>
        } @else {
          <p>
            <mat-form-field class="full-width">
              <input matInput autocomplete="off" (change)="onmychange($event)" formControlName="name" [placeholder]="'NAME' | translate" type="text">
              @if (editDataForm.get('name').touched || editDataForm.get('name').hasError('pattern')) {
                <mat-error>
                  {{errMsg}}
                </mat-error>
              }
            </mat-form-field>
          </p>
        }

      </mat-dialog-content>
      <app-dialog-actions [mode]="mode" [formCheck]="editDataForm"></app-dialog-actions>
    </form>
  </div>
</div>
