import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef} from '@angular/material/dialog';
import {removeCSRSetting} from 'src/app/share/utils';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import {DialogActionsComponent} from '../dialog-actions/dialog-actions.component';
import {TranslationService} from '../../../services/translation.service';
import {CookieService} from 'ngx-cookie-service';

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatSelectModule,
    MatDialogActions,
    MatDividerModule,
    ReactiveFormsModule,
    MatDialogContent,
    MatButtonModule,
    DialogActionsComponent, MatDialogClose,
  ],
  selector: 'app-setting-coordinates-reference-dialog',
  templateUrl: './setting-coordinates-reference-dialog.component.html',
  styleUrls: ['./setting-coordinates-reference-dialog.component.scss', '../dialog-btns.component.scss']
})
export class SettingCoordinatesReferenceDialogComponent {

  crsList = [
    {value: 'Geodetic', text: 'Geodetic'},
    {value: 'Lambert72', text: 'Lambert 72'},
    {value: 'Lambert2008', text: 'Lambert 2008'},
    {value: 'RDNAP', text: 'RD'},
    {value: 'UTM', text: 'UTM'},
    {value: 'BGS2005', text: 'BGS2005'}
  ];

  mode = {
    function: 'Ok',
    text: this.translate.instant('OK')
  }

  @ViewChild('fform') crsSettingFormDirective;

  constructor(
    public translation: TranslationService,
    public cookieService: CookieService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
  }

  switchCrs(value) {
    this.cookieService.set('CRSValue', value, 365);
  }
}

