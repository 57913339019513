<div id="userspage" fxLayout="column" fxLayoutGap="2px">
  <div fxFlex="10">
    <h3>Marxact Device(s) assigned to <span>{{user.name}}</span></h3>
  </div>
  <div class="ml-2" fxFlex="20">
    @if (showMessage) {
      <app-message-box #messageBox message="{{message}}"></app-message-box>
    }
  </div>
  <div class="ml-2" fxFlex="70">
    <mat-dialog-content>
      <table mat-table [dataSource]="dataSource" class="table table-striped table-bordered full-width">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th *matHeaderCellDef>
            <mat-checkbox color="primary" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>

          <!-- #namecheckbox name="read"
          [(checked)]="element.permissions.read"
          (click)="$event.stopPropagation()"
          (change)="setReadPermission(element, namecheckbox.checked)" -->

          <td *matCellDef="let row">
            <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="$event ? devicesToAssigned(row) : null" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Device name Column -->
        <ng-container matColumnDef="device_name">
          <th *matHeaderCellDef>
            <span class="ml-2"><span>{{ "MARXACT DEVICE NAME" | translate }}</span></span>
          </th>
          <td *matCellDef="let element">
            <span class="ml-2">
              {{element.device_name}}
            </span>
          </td>
        </ng-container>

        <!-- Device Id Column -->
        <ng-container matColumnDef="device_id">
          <th *matHeaderCellDef>
            <span class="ml-2"><span>{{ "MARXACT DEVICE ID" | translate }}</span></span>
          </th>
          <td *matCellDef="let element">
            <span class="ml-2">
              {{element.device_id}}
            </span>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>
    </mat-dialog-content>
    <mat-dialog-actions class="button-group">
      <span class="flex-spacer"></span>
      <button mat-button class="btn-border" mat-dialog-close><span>{{ "CLOSE" | translate }}</span></button>
      <button mat-button type="submit" (click)="assignAndOrDeassignDevices()" class="background-primary text-floral-white"><span>{{ "OK" | translate }}</span></button>

    </mat-dialog-actions>
  </div>
</div>
