import { TranslateModule } from '@ngx-translate/core';
import {Component, Inject, ViewChild, HostListener, OnInit, OnDestroy, ChangeDetectorRef, ElementRef} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogActions,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { UserService } from 'src/app/services/user.service';
import { TemplatesPermissionsDialogComponent } from '../templates-permissions-dialog/templates-permissions-dialog.component';
import {getToken, getUser} from 'src/app/share/utils';
import { Project } from 'src/app/share/projects';
import { ProjectMemberService } from 'src/app/services/project-member.service';
import { Collaborator } from 'src/app/share/user';
import { StoreService } from 'src/app/services/store.service';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import {MatInput} from '@angular/material/input';
import {MatList, MatListItem} from '@angular/material/list';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {forkJoin, of, Subject, Subscription, takeUntil} from 'rxjs';
import {TemplatePermission} from '../../../share/permission';
import {DataService} from '../../../services/data.service';

const ELEMENT_DATA: Collaborator[] = [];


@Component({
  standalone: true,
  imports: [
    TranslateModule,
    MatSelectModule,
    MatDialogContent,
    MatTableModule,
    MatCheckboxModule,
    MatDialogActions,
    MatDialogModule,
    MatButtonModule,
    MatInput,
    MatList,
    MatListItem,
    FaIconComponent,
  ],
  selector: 'app-project-member-dialog',
  templateUrl: './project-member-dialog.component.html',
  styleUrls: ['./project-member-dialog.component.scss', '../dialog-btns.component.scss']
})
export class ProjectMemberDialogComponent implements OnDestroy {
  project: Project;
  actions = ['Permissions', 'Remove Members'];
  displayedColumns: string[] = ['select', 'project_members', 'forms'];

  projectMembers: Collaborator[] = [];
  nonProjectMembers: Collaborator[] = [];

  isMembersSearch: boolean = false;
  isNonMembersSearch: boolean = false;

  membersSearch: Collaborator[] = [];
  nonMembersSearch: Collaborator[] = [];

  isNonMembersSelected = false;
  isMembersSelected = false;

  isPermissionsOpen = false;

  selectedNonMembers: Collaborator[] = [];
  selectedMembers: Collaborator[] = [];

  dataSource = new MatTableDataSource<Collaborator>(ELEMENT_DATA);
  selection = new SelectionModel<Collaborator>(true, []);
  searchValue: string;

  token = getToken();

  collaboratorPermissions = [];
  private collaboratorSubscription: Subscription;
  private destroy$ = new Subject<void>();
  private existingPermissions: TemplatePermission[];

  @ViewChild('memberSearchBar') memberSearchBar: ElementRef;
  @ViewChild('nonMemberSearchBar') nonMemberSearchBar: ElementRef;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ProjectMemberDialogComponent>,
    public store: StoreService,
    public dataService: DataService,
    private userService: UserService,
    private projectMemberService: ProjectMemberService,
    @Inject(MAT_DIALOG_DATA) data,
    public toastService: ToastService,
  ) {
    if (data !== undefined && data !== null) {
      this.project = data.project;
    }
    this.loadMembers();
  }


  ngOnDestroy() {
    if (this.collaboratorSubscription) {
      this.collaboratorSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && !this.isPermissionsOpen) {
      this.dialogRef.close();
    }
  }


  isFilteredMembersSelected = false;
  isFilteredNonMembersSelected = false;

  selectAllNonMembers() {
    if(this.isNonMembersSearch) {
      if(!this.isFilteredNonMembersSelected) {
        //[...array] creates a deep copy instead of a reference
        this.selectedNonMembers = [...this.nonMembersSearch];
        this.isFilteredNonMembersSelected = true;
      } else {
        this.selectedNonMembers = [];
        this.isFilteredNonMembersSelected = false;
      }
    }
    else {
      if(!this.isNonMembersSelected) {
        //[...array] creates a deep copy instead of a reference
        this.selectedNonMembers = [...this.nonProjectMembers];
        this.isNonMembersSelected = true;
      } else {
        this.selectedNonMembers = [];
        this.isNonMembersSelected = false;
      }
    }
  }

  selectAllMembers() {
    if(this.isMembersSearch) {
      if(!this.isFilteredMembersSelected) {
        //[...array] creates a deep copy instead of a reference
        this.selectedMembers = [...this.membersSearch];
        this.isFilteredMembersSelected = true;
      } else {
        this.selectedMembers = [];
        this.isFilteredMembersSelected = false;
      }
    }
    else {
      if(!this.isMembersSelected) {
        //[...array] creates a deep copy instead of a reference
        this.selectedMembers = [...this.projectMembers];
        this.isMembersSelected = true;
      } else {
        this.selectedMembers = [];
        this.isMembersSelected = false;
      }
    }

  }

  moveToMembers() {
    if(this.selectedNonMembers.length <= 0) {
      this.toastService.warningToast("No collaborators not belonging to the project selected")
    } else {
      for (const collab of this.selectedNonMembers) {
        this.store.showLoading();
        this.projectMemberService.addUserToProject(this.token, this.project.id, collab.id).subscribe(
          _res => {
            this.store.hideLoading();

            this.selectedNonMembers.map(value => {
              value.permissionSum = {
                create: 0,
                read: 0,
                update: 0,
                delete: 0
              }
            })

            this.projectMembers = this.projectMembers.concat(this.selectedNonMembers).sort((a, b) => a.name.localeCompare(b.name));

            this.nonProjectMembers = this.nonProjectMembers.filter(member =>
              !this.selectedNonMembers.includes(member)
            );

            this.selectedNonMembers = [];
            this.resetMemberSearch();
            this.resetNonMemberSearch();
            this.toastService.successToast('User(s) added to project successfully');
          },
          err => {
            this.toastService.errorToast(err);
            this.store.hideLoading();
            return;
          }
        );
      }
    }
  }

  moveToNonMembers() {
    if(this.selectedMembers.length <= 0) {
      this.toastService.warningToast("No collaborators belonging to the project selected")
    } else {
      for (const user of this.selectedMembers) {
        this.store.showLoading();
        this.projectMemberService.removeProjectMembers(user, this.project, this.token).subscribe(
          res => {
            this.store.hideLoading();
            this.nonProjectMembers = this.nonProjectMembers.concat(this.selectedMembers).sort((a, b) => a.name.localeCompare(b.name));

            this.projectMembers = this.projectMembers.filter(member =>
              !this.selectedMembers.includes(member)
            );

            this.selectedMembers = [];
            this.resetMemberSearch();
            this.resetNonMemberSearch();
            this.toastService.successToast('User(s) removed from project successfully');
          },
          err => {
            this.store.hideLoading();
            this.toastService.errorToast(err);
          }
        );
      }
    }
  }

  checkboxChanged(checked: boolean, list: Collaborator[], user: Collaborator) {
    if(checked && !list.find(x => x.id === user.id)) {
      list.push(user);
    }
    else if(!checked && list.find(x => x.id === user.id)) {
      list.splice(list.findIndex(x => x.id === user.id), 1)
    }

    //Check non filtered
    this.isNonMembersSelected = this.arraysAreEqual(this.selectedNonMembers, this.nonProjectMembers);
    this.isMembersSelected = this.arraysAreEqual(this.selectedMembers, this.projectMembers);

    //Check filtered
    this.isFilteredMembersSelected = this.arraysAreEqual(this.selectedMembers, this.membersSearch);
    this.isFilteredNonMembersSelected = this.arraysAreEqual(this.selectedNonMembers, this.nonMembersSearch);


  }

  arraysAreEqual(arr1: Collaborator[], arr2: Collaborator[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();

    return sortedArr1.every((value, index) => value === sortedArr2[index]);
  }

  nonMembersSearchbarChange(event) {
    const searchTerm = event.target.value.trim().toLowerCase();

    if (!searchTerm) {
      this.isFilteredNonMembersSelected = false;
      this.isNonMembersSearch = false;
      return;
    }

    this.isFilteredNonMembersSelected = this.arraysAreEqual(this.selectedNonMembers, this.nonMembersSearch);
    const searchResults = this.nonProjectMembers.filter(user =>
      user.name.toLowerCase().startsWith(searchTerm)
    );

    if (searchResults.length > 0) {
      this.nonMembersSearch = searchResults;
      this.isNonMembersSearch = true;
    } else {
      this.isNonMembersSearch = false;
    }
  }


  membersSearchbarChange(event) {
    const searchTerm = event.target.value.trim().toLowerCase();

    if (!searchTerm) {
      this.isFilteredMembersSelected = false;
      this.isMembersSearch = false;
      return;
    }

    this.isFilteredMembersSelected = this.arraysAreEqual(this.selectedMembers, this.membersSearch);
    const searchResults = this.projectMembers.filter(user =>
      user.name.toLowerCase().startsWith(searchTerm)
    );

    if (searchResults.length > 0) {
      this.membersSearch = searchResults;
      this.isMembersSearch = true;
    } else {
      this.isMembersSearch = false;
    }
  }

  loadMembers() {
    // Load members belonging to the project
    this.projectMemberService.getListOfMemberOfProject(this.token, this.project.id).subscribe(
      res => {
        this.projectMembers = res.sort((a, b) => a.name.localeCompare(b.name)); // All members that belong to the project
        this.dataSource.data = res.filter(u => u.id !== this.project.created_by); // Optional: Filter by project creator
        this.projectMembers.forEach(u => {
          const projectMember$ = this.projectMemberService.getProjectMemberInfo(this.token, this.project.id, u.id);
          const templates$ = this.dataService.requestTemplates(this.token, this.project.id);

          // Combine both observables and handle errors in a single place
          forkJoin([projectMember$, templates$]).subscribe(
            ([projectMember, templates]) => {
              const features = projectMember.permissions?.features || [];

              this.existingPermissions = templates.map(template => {
                const feature = features.find(f => f.template_id === template.id);
                return new TemplatePermission(template, feature?.permission_types || null);
              });

              this.collaboratorPermissions.push({
                userId: u.id,
                exPermissions: this.existingPermissions
              });

              this.calculatePermissions(this.collaboratorPermissions);
            },
            err => this.toastService.errorToast(err)
          );
        });

        this.loadCollaborators(); // Now that projectMembers are loaded, load collaborators
        this.store.hideLoading();
      },
      err => {
        this.toastService.errorToast(err);
        this.store.hideLoading();
      }
    );
  }


  loadOnlyMembers() {
    // Load members belonging to the project
    this.projectMemberService.getListOfMemberOfProject(this.token, this.project.id).subscribe(
      res => {
        this.projectMembers = res.sort((a, b) => a.name.localeCompare(b.name)); // All members that belong to the project
        this.dataSource.data = res.filter(u => u.id !== this.project.created_by); // Optional: Filter by project creator
        this.projectMembers.forEach(u => {
          const projectMember$ = this.projectMemberService.getProjectMemberInfo(this.token, this.project.id, u.id);
          const templates$ = this.dataService.requestTemplates(this.token, this.project.id);

          // Combine both observables and handle errors in a single place
          forkJoin([projectMember$, templates$]).subscribe(
            ([projectMember, templates]) => {
              const features = projectMember.permissions?.features || [];

              this.existingPermissions = templates.map(template => {
                const feature = features.find(f => f.template_id === template.id);
                return new TemplatePermission(template, feature?.permission_types || null);
              });

              this.collaboratorPermissions.push({
                userId: u.id,
                exPermissions: this.existingPermissions
              });

              this.calculatePermissions(this.collaboratorPermissions);
            },
            err => this.toastService.errorToast(err)
          );
        });
      },
      err => {
        this.toastService.errorToast(err);
      }
    );
  }

  loadCollaborators() {
    const user = getUser();
    this.store.showLoading();

    // Load all possible collaborators and filter out project members
    this.collaboratorSubscription = this.userService.getCollaboratorUsers(user, this.token).pipe(takeUntil(this.destroy$))
      .subscribe(
      res => {
        this.nonProjectMembers = res.filter(u => !this.projectMembers.some(member => member.id === u.id)).sort((a, b) => a.name.localeCompare(b.name)); // Filter out members in the project
        this.dataSource.data = this.nonProjectMembers.filter(u => u.id !== this.project.created_by); // Optional: Filter by project creator
      },
      err => {
        this.toastService.errorToast(err);
      }
    );
  }


  calculatePermissions(data) {
    return data.map(user => {
      const permissionCounts = {
        create: 0,
        update: 0,
        read: 0,
        delete: 0,
      };

      // Loop through each permission object in exPermisssions
      user.exPermissions.forEach(permissionObj => {
        const permissions = permissionObj.permissions;
        permissionCounts.create += permissions.create ? 1 : 0;
        permissionCounts.update += permissions.update ? 1 : 0;
        permissionCounts.read += permissions.read ? 1 : 0;
        permissionCounts.delete += permissions.delete ? 1 : 0;
      });

      let position = this.projectMembers.findIndex(member => member.id === user.userId);
      this.projectMembers[position]['permissionSum'] = permissionCounts
    });
  }

  openFormsPermissionsDialog(collaborators: Collaborator[]) {
    if(collaborators.length <= 0) {
      this.toastService.warningToast("No project members selected");
      return;
    }

    this.assignPermissions(collaborators);
  }

  assignPermissions(users){
    if(users.length <= 0){
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '700px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data =  {
      project: this.project,
      user: users
    };

    this.isPermissionsOpen = true;
    const dialogRef = this.dialog.open(TemplatesPermissionsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      result => {
        this.selectedMembers = [];
        this.selectedNonMembers = [];
        this.isMembersSelected = false;
        this.isNonMembersSelected = false;
        this.resetMemberSearch();
        this.resetNonMemberSearch();

        this.isPermissionsOpen = false;
        this.loadOnlyMembers();
      }
    );
  }

  resetMemberSearch() {
    this.membersSearch = [];
    this.isMembersSearch = false;
    this.memberSearchBar.nativeElement.value = '';
  }

  resetNonMemberSearch() {
    this.nonMembersSearch = [];
    this.isNonMembersSearch = false;
    this.nonMemberSearchBar.nativeElement.value = '';
  }

  protected readonly faArrowRight = faArrowRight;
  protected readonly faArrowLeft = faArrowLeft;
}
