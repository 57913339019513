<div class="import-shape-container">
    @if (showMessage) {
      <div class="alert-box">
        <strong>{{message}}</strong><br/>
        @if (importDataError && importDataError.length > 0) {
          <button type="button" class="close-btn" (click)="closePanel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <span class="details-btn" (click)="showErrorDlg()">Click here for more details</span>
        }
      </div>
    }

    <div class="main-content">
        <h3 class="pb-3 pl-2 ml-2">{{importDialogTitle}}</h3>

        <div class="pb-3 pl-2 ml-2 mr-2 card mb-2">
            <div class="card-body">
                @if (!store?.loading) {
                    <div>
                        <span>{{loadingText}}</span>
                    </div>
                }
                <form novalidate [formGroup]="importFileForm" #fform="ngForm" >
                    <h4 style="font-weight: 600;">
                        File to import in ZIP format
                    </h4>
                    <p>
                        <mat-form-field class="full-width">
                            <input matInput class="h4"  readonly>
                            <input type="file" class="btn btn-small mb-0" id="userFile" (change)="setFileData($event)" #fileInput accept=".zip">
                        </mat-form-field>
                        @if (noFileIsSelected) {
                            <mat-error>
                                File is required
                            </mat-error>
                        }

                    </p>
                    <h4 style="font-weight: 600;">
                        Form
                    </h4>
                    <p>
                        <mat-form-field class="full-width">
                            <input matInput autocomplete="off" formControlName="template" [placeholder]="'FORM NAME' | translate" type="text" required>

                            @if (importFileForm.get('template').hasError('required') && importFileForm.get('template').touched) {
                                <mat-error>
                                  {{ "FORM NAME IS REQUIRED" | translate }}
                                </mat-error>
                            }
                        </mat-form-field>
                    </p>
                    <h4 style="font-weight: 600;">
                      {{'COORDINATE REFERENCE SYSTEM' | translate}}
                    </h4>

                    <p class="mb-2" style="margin-bottom: 20px !important;">
                        <mat-select class="ml-3 mb-2 full-width" formControlName="crs" [placeholder]="'COORDINATE REFERENCE SYSTEM' | translate">
                            <mat-option [value]="null">None</mat-option>
                            @for (coordRef of crsList; track coordRef) {
                                <mat-option  [value]="coordRef.value">{{ coordRef.text }}</mat-option>
                            }
                        </mat-select>
                    </p>
                    <div class="button-group">
                        <button mat-dialog-close class="close-btn" mat-stroked-button type="button">Close</button>
                        @if (!isFileProcessing) {
                            <button mat-button [disabled]="!isValidFile || importFileForm.get('template').hasError('required')" (click)="onSubmit()" class="submit-btn"><span>{{ "IMPORT DATA" | translate }}</span></button>
                        }
                        @if (isFileProcessing) {
                            <button  mat-button [disabled]="isFileProcessing" class="submit-btn"><fa-icon class="fa fa-spinner fa-spin" [icon]="faSpinner"></fa-icon><span class="import-data-text">{{ "IMPORT DATA" | translate }}</span></button>
                         }

                    </div>
                </form>
            </div>
        </div>

    </div>
  </div>
