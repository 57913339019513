import { TranslateModule } from '@ngx-translate/core';
import {Component, HostListener, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { StoreService } from 'src/app/services/store.service';
import { getUser } from 'src/app/share/utils';

@Component({
  standalone: true,
  imports: [TranslateModule,
    ReactiveFormsModule,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    MatDialogActions,
    MatDialogModule,
    MatButtonModule,
  ],
  selector: 'app-collaborators-dialog',
  templateUrl: './collaborators-dialog.component.html',
  styleUrls: ['./collaborators-dialog.component.scss', '../dialog-btns.component.scss']
})
export class CollaboratorsDialogComponent implements OnInit {
  addCollaboratorForm: FormGroup;
  @ViewChild('fform') addCollaboratorFormDirective;

  action: string;
  local_data: any;
  isAdminUser: boolean;
  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    public dialogRef: MatDialogRef<CollaboratorsDialogComponent>,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.local_data = {...data};
      this.action = this.local_data.action;
      this.createForm();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  createForm() {
      this.addCollaboratorForm = this.fb.group({
        name: ['', Validators.required],
        email: ['', Validators.required],
        password: ''
      });
  }

  ngOnInit() {
    const user = getUser();
    let userType = user.user_type;

    if (userType === undefined || userType === null) {
      userType = user.admin_type;
    }
    if (userType === 'admin_user') {
      this.isAdminUser = true;
    } else {
      this.isAdminUser = false;
    }
  }

  onSubmit() {
    this.local_data = this.addCollaboratorForm.value;
    this.action = 'Add';
    this.dialogRef.close({event: this.action, data: this.local_data});
  }

  closeDialog(event) {
    event.preventDefault();
    this.action = 'Cancel';
    this.dialogRef.close({event: this.action});
    event.stopPropagation();
  }
}
