<div fxFlexLayout="row">
  <div fxFlex fxFlexOffset="20px">
    <h3>{{ mode.text }} Form</h3>
    <form novalidate [formGroup]="templateDialogForm" #fform="ngForm" (ngSubmit)="onSubmit()">
      <mat-dialog-content>
        <p id="dropbox">
          <app-image-drop [image]="image" #imgIcon [width]="100" [height]="100" (invalidIconDropped)="invalidIconDrop($event)"></app-image-drop>
        </p>
        <p>
          <mat-form-field class="full-width">
            <input matInput autocomplete="off" formControlName="name" [placeholder]="'NAME' | translate" type="text" required>
            @if (templateDialogForm.get('name').hasError('required') && templateDialogForm.get('name').touched) {
              <mat-error><span>{{ "TEMPLATE NAME IS REQUIRED" | translate }}</span></mat-error>
            }
          </mat-form-field>
        </p>
        <p>
          <span class="half-width">{{ "GEOMETRY TYPE" | translate }}: </span>
          <mat-select class="ml-3 half-width" (selectionChange)="getGeometryTypeOnChange($event)"
            formControlName="geometry_type" [placeholder]="'GEOMETRY TYPE' | translate">
            <mat-option [value]="null"></mat-option>
            @for (geom of geomTypeList; track geom) {
              <mat-option [value]="geom">{{ geom }}</mat-option>
            }
          </mat-select>
        </p>

        <p>
          <mat-form-field class="full-width">
            <input matInput autocomplete="off" formControlName="description" [placeholder]="'DESCRIPTION' | translate" type="text">
          </mat-form-field>
        </p>
        @if (styleName) {
          <p>
            <mat-form-field class="full-width">
              <input matInput autocomplete="off" readonly value="{{styleName.name}}" [placeholder]="'STYLE NAME' | translate" type="text">
            </mat-form-field>
          </p>
        }
      </mat-dialog-content>

      <mat-dialog-actions class="style-button-group">
        <div class="buttons-style">
          @if (geometryTemplate && mode.function !== 'Create') {
            <button type="button" mat-button class="btn-border text-left" (click)="addDefaultStyleToTemplate()" >{{ translate.instant(styleCaption.toUpperCase()) }}</button>
          }
          @if (styleName) {
            <button type="button" mat-button class="btn-pointer cancel-btn" (click)="removeDefaultStyle()">{{ "DELETE" | translate }}</button>
          }
        </div>

        <div class="buttons-submit">
          <button type="button" mat-button class="cancel-btn" mat-dialog-close (click)="onCancel($event)"><span>{{ "CANCEL" | translate }}</span></button>
          <button mat-button type="submit" cdkFocusRegionstart class="submit-btn" [disabled]="templateDialogForm.invalid">{{ mode.text }}</button>
        </div>
      </mat-dialog-actions>
    </form>
  </div>
</div>
