<div class="dialog-content">
  <div style="margin-bottom: 0 !important;">
    <app-message-box #messageBox *ngIf="showMessage" message="{{message}}"></app-message-box>
    <mat-horizontal-stepper class="stepper-elements">

      <mat-step [label]="translate.instant('FIELDS')">
        <div fxLayout="column">
          <h4 class="text-left" fxFlex="10"><span>{{ "SELECT FIELDS" | translate }}</span></h4>
          <div fxFlex="10">
            <mat-checkbox
              color="primary"
              style="display: block;"
              [checked]="selectOrDeselectAll"
              (change)="handleSelectAll($event)"
              name="enabled">Select / Deselect All
            </mat-checkbox>
          </div>
          <div id="columnsContainer" class="pl-2" fxFlex="80">
            <mat-checkbox *ngFor="let header of columnItems"
                          color="primary" style="display: block;" [checked]="containsField(header)"
                          (change)="handleSelect(header, $event)" name="enabled">{{ header.value }}
            </mat-checkbox>
          </div>
        </div>
        <div class="button-group">
          <button mat-stroked-button type="submit" class="mr-2 mt-2 cancel-btn" (click)="closeDialog()">
            <span>{{ "CANCEL" | translate }}</span></button>
          <button mat-stroked-button class="mr-2 mt-2" matStepperNext><span>{{ "NEXT" | translate }}</span></button>
        </div>
      </mat-step>
      <mat-step [label]="translate.instant('SELECTION CRITERIA')">
        <div>
          <div>
            <form [formGroup]="criteriaForm" (ngSubmit)="addCriteria()" class="criteria-form">
              <mat-form-field>
                <mat-select formControlName="column_name" [placeholder]="'FIELDS' | translate">
                  <mat-option></mat-option>
                  <mat-option *ngFor="let h of headers" [value]="h">{{ h }}</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="criteriaForm.get('column_name').hasError('required') && criteriaForm.get('column_name').touched">
                  <span>{{ "SELECT COLUMN NAME" | translate }}</span>
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-select formControlName="operator" [placeholder]="'OPERATOR' | translate">
                  <mat-option></mat-option>
                  <mat-option *ngFor="let o of optrs" [value]="o.value">{{ o.text }}</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="criteriaForm.get('operator').hasError('required') && criteriaForm.get('operator').touched">
                  <span>{{ "SELECT OPERATOR" | translate }}</span>
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <input matInput autocomplete="off" formControlName="column_value"
                       [placeholder]="'ENTER FIELD VALUE' | translate" type="text">
                <mat-error
                  *ngIf="criteriaForm.get('column_value').hasError('required') && criteriaForm.get('column_value').touched">
                  <span>{{ "ENTER COLUMN VALUE" | translate }}</span>
                </mat-error>
              </mat-form-field>

              <button mat-stroked-button type="submit" [disabled]="criteriaForm.invalid">+</button>
            </form>

            <div class="criteria-table-container">
              <div class="table-container">
                <table mat-table [dataSource]="dataSource" class="table table-striped table-bordered full-width">
                  <!-- Checkbox Column -->
                  <ng-container matColumnDef="select">
                    <th class="crit-header" *matHeaderCellDef>
                      <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                      </mat-checkbox>
                    </th>
                    <td *matCellDef="let row">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)"
                                    [aria-label]="checkboxLabel(row)">
                      </mat-checkbox>
                    </td>
                  </ng-container>

                  <!-- Text Column -->
                  <ng-container matColumnDef="criteria">
                    <th *matHeaderCellDef>
                      <span>{{ "CRITERIA" | translate }}</span>
                    </th>
                    <td *matCellDef="let element">
                      {{ element.criteria }}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>

              <div class="button-group">
                <button mat-stroked-button type="button" (click)="removeCriteria()"
                        [disabled]="selection.selected.length == 0">-
                </button>
              </div>
            </div>

            <div class="date-picker-row">
              <mat-checkbox color="primary" #fromCreatedDateCheckbox [(ngModel)]="fromCreatedDateValue"
                            (click)="$event.stopPropagation()"
                            (change)="fromDateChecked(fromCreatedDateCheckbox.checked, 'create')" name="enabled">From
                Created Date:
              </mat-checkbox>
              <mat-form-field>
                <input matInput autocomplete="off" (dateChange)="showFromDateValue($event)"
                       [(ngModel)]="fromCreatedDateValue"
                       [matDatepicker]="fromCreatedDate" [disabled]="!fromCreatedDateChecked"
                       [placeholder]="'FROM DATE' | translate">
                <mat-datepicker-toggle matSuffix [for]="fromCreatedDate"
                                       [disabled]="!fromCreatedDateChecked"></mat-datepicker-toggle>
                <mat-datepicker [disabled]="!fromCreatedDateChecked" #fromCreatedDate></mat-datepicker>
              </mat-form-field>

              <mat-checkbox color="primary" #toCreatedDateCheckbox [(ngModel)]="toCreatedDateValue"
                            (click)="$event.stopPropagation()"
                            (change)="toDateChecked(toCreatedDateCheckbox.checked, 'create')" name="enabled">To Created
                Date:
              </mat-checkbox>
              <mat-form-field>
                <input matInput autocomplete="off" (dateChange)="showToDateValue($event)"
                       [(ngModel)]="toCreatedDateValue"
                       [matDatepicker]="toCreatedDate" [disabled]="!toCreatedDateChecked"
                       [placeholder]="'TO DATE' | translate">
                <mat-datepicker-toggle matSuffix [for]="toCreatedDate"
                                       [disabled]="!toCreatedDateChecked"></mat-datepicker-toggle>
                <mat-datepicker #toCreatedDate [disabled]="!toCreatedDateChecked"></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="date-picker-row">
              <mat-checkbox color="primary" #fromUpdatedDateCheckbox [(ngModel)]="fromUpdatedDateValue"
                            (click)="$event.stopPropagation()"
                            (change)="fromDateChecked(fromUpdatedDateCheckbox.checked, 'update')" name="enabled">From
                Updated Date:
              </mat-checkbox>
              <mat-form-field>
                <input matInput autocomplete="off" (dateChange)="showFromUpdatedDateValue($event)"
                       [(ngModel)]="fromUpdatedDateValue"
                       [matDatepicker]="fromUpdatedDate" [disabled]="!fromUpdatedDateChecked"
                       [placeholder]="'FROM DATE' | translate">
                <mat-datepicker-toggle matSuffix [for]="fromUpdatedDate"
                                       [disabled]="!fromUpdatedDateChecked"></mat-datepicker-toggle>
                <mat-datepicker [disabled]="!fromUpdatedDateChecked" #fromUpdatedDate></mat-datepicker>
              </mat-form-field>

              <mat-checkbox color="primary" #toUpdatedDateCheckbox [(ngModel)]="toUpdatedDateValue"
                            (click)="$event.stopPropagation()"
                            (change)="toDateChecked(toUpdatedDateCheckbox.checked, 'update')" name="enabled">To Updated
                Date:
              </mat-checkbox>
              <mat-form-field>
                <input matInput autocomplete="off" (dateChange)="showToUpdatedDateValue($event)"
                       [(ngModel)]="toUpdatedDateValue"
                       [matDatepicker]="toUpdatedDate" [disabled]="!toUpdatedDateChecked"
                       [placeholder]="'TO DATE' | translate">
                <mat-datepicker-toggle matSuffix [for]="toUpdatedDate"
                                       [disabled]="!toUpdatedDateChecked"></mat-datepicker-toggle>
                <mat-datepicker #toUpdatedDate [disabled]="!toUpdatedDateChecked"></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="sorting-row">
              <mat-form-field>
                <mat-label>Sorting Field 1</mat-label>
                <mat-select [(value)]="selectedSortColumn">
                  <mat-option><span>{{ "NONE" | translate }}</span></mat-option>
                  <mat-option *ngFor="let headerc1 of headers" [value]="headerc1">{{ headerc1 }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-checkbox color="primary" #ascendingCheckedbox [disabled]="!selectedSortColumn"
                            (change)="ascendingChecked(ascendingCheckedbox.checked)" [checked]="ascendingValue"
                            name="enabled"><span>{{ "ASCENDING" | translate }}</span></mat-checkbox>
              <mat-checkbox color="primary" #descendingCheckedbox [disabled]="!selectedSortColumn"
                            (change)="descendingChecked(descendingCheckedbox.checked)" [checked]="descendingValue"
                            name="enabled"><span>{{ "DESCENDING" | translate }}</span></mat-checkbox>
            </div>

            <div class="sorting-row">
              <mat-form-field>
                <mat-label>Sorting Field 2</mat-label>
                <mat-select [(value)]="selectedSortColumn2">
                  <mat-option><span>{{ "NONE" | translate }}</span></mat-option>
                  <mat-option *ngFor="let headerc2 of headers" [value]="headerc2">{{ headerc2 }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-checkbox color="primary" #ascendingCheckedbox2 [disabled]="!selectedSortColumn2"
                            (change)="ascendingChecked2(ascendingCheckedbox2.checked)" [checked]="ascendingValue2"
                            name="enabled"><span>{{ "ASCENDING" | translate }}</span></mat-checkbox>
              <mat-checkbox color="primary" #descendingCheckedbox2 [disabled]="!selectedSortColumn2"
                            (change)="descendingChecked2(descendingCheckedbox2.checked)" [checked]="descendingValue2"
                            name="enabled"><span>{{ "DESCENDING" | translate }}</span></mat-checkbox>
            </div>
          </div>
        </div>

        <div class="button-group">
          <button mat-stroked-button matStepperPrevious><span>{{ "BACK" | translate }}</span></button>
          <button mat-stroked-button class="cancel-btn" type="button" (click)="closeDialog()">
            <span>{{ "CANCEL" | translate }}</span></button>
          <button mat-stroked-button matStepperNext><span>{{ "NEXT" | translate }}</span></button>
        </div>
      </mat-step>

      <mat-step [label]="translate.instant('OUTPUT')">
        <div>
          <mat-form-field class="full-width">
            <mat-label class="mr-2 ">CRS *</mat-label>
            <mat-select [(value)]="coordinateRef" (selectionChange)="crsChanged($event.value)">
              <mat-option *ngFor="let c of crs" name="c.name" [value]="c.name">{{ c.title }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="mr-5 full-width">
            <mat-label class="mr-2 ">{{ 'OUTPUT FORMAT' | translate }} *</mat-label>
            @if (template.feature.geometry_type) {
              <mat-select [(value)]="outPut" (selectionChange)="outPutChanged($event.value)">
                <mat-option><span>{{ "NONE" | translate }}</span></mat-option>
                <mat-option *ngFor="let output of outputs" [value]="output.format_id">{{ output.format_id }}
                </mat-option>
              </mat-select>
            } @else {
              <mat-select [(value)]="outPut" (selectionChange)="outPutChanged($event.value)">
                <mat-option><span>{{ "NONE" | translate }}</span></mat-option>
                <mat-option *ngFor="let output of outputsNoGeometry" [value]="output.format_id">{{ output.format_id }}
                </mat-option>
              </mat-select>
            }

          </mat-form-field>
        </div>
        <div *ngIf="hasTemplate" id="outputContent">
          <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="handleCheckbox(Visibility.PRIVATE)"
                        [checked]="currentVisibility === Visibility.PRIVATE" name="enabled" class="mr-5 ">
            <span>{{ "PRIVATE TEMPLATE" | translate }}</span></mat-checkbox>
          <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="handleCheckbox(Visibility.PUBLIC)"
                        [checked]="currentVisibility === Visibility.PUBLIC" name="enabled" class="mr-2 ">
            <span>{{ "PUBLIC TEMPLATE" | translate }}</span></mat-checkbox>
          <span [hidden]="templateloaded">Loading report template ..., please wait </span>
          <ng-container *ngIf="reportsTemplates && reportsTemplates.length > 0">
            <mat-form-field class="mr-4 full-width">
              <mat-label class="mr-2 "><span>{{ "SELECT REPORT TEMPLATE" | translate }}</span></mat-label>
              <mat-select [(value)]="selectedReportTemplateName"
                          (selectionChange)="outReportTemplateChanged($event.value)">
                <mat-option><span>{{ "NONE" | translate }}</span></mat-option>
                <mat-option *ngFor="let rep_temp of reportsTemplates" [value]="rep_temp.name">{{ rep_temp.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <div id="placeHoldersContainer">
            <table mat-table [dataSource]="rptdataSource" class="table table-striped table-bordered full-width">
              <!-- place holder name Column -->
              <ng-container matColumnDef="name">
                <th *matHeaderCellDef>
                  <span class="ml-2"><span>{{ "PLACE HOLDER" | translate }}</span></span>
                </th>
                <td *matCellDef="let element; let i = index">
                  <span matInput readonly="true"
                        type="text">{{ element.name }}</span>
                </td>
              </ng-container>
              <!-- place holder default value Column -->
              <ng-container matColumnDef="value">
                <th *matHeaderCellDef>
                  <span class="ml-2"><span>{{ "VALUE" | translate }}</span></span>
                </th>
                <td *matCellDef="let element; let i = index">
                  <input matInput autocomplete="off" #placeholdervalue
                         (change)="updatePlaceHolderValue($event, element, placeholdervalue.value)"
                         value="{{element.value}}" type="text">
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="rptdisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: rptdisplayedColumns;"></tr>
            </table>
          </div>
        </div>
        <div class="button-group">
          <button mat-stroked-button matStepperPrevious><span>{{ "BACK" | translate }}</span></button>
          <button mat-stroked-button class="cancel-btn" type="submit" (click)="closeDialog()">
            <span>{{ "CANCEL" | translate }}</span></button>
          <button mat-stroked-button class="submit-btn" type="submit" (click)="submitResearchData() ">
            <span>{{ "EXPORT" | translate }}</span></button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
