<div id="projectsContainer" class="projects-container">
  <div #leftSide class="left-side resizable">
    @if (dataService?.projects) {
      <div id="projectsCont" class="projects-content" [hidden]="store?.hideProjectsContainer">
        <app-project></app-project>
      </div>
    }
    @if (dataService?.templates.getValue()) {
      <div id="templatesContent" class="templates-content" [hidden]="store?.hideTemplatesContainer">
        <app-templates></app-templates>
      </div>
    }
    <div class="resizer" (mousedown)="startResizing($event)"></div>
  </div>
  @if (dataService?.template) {
    <div #rightSide class="right-side">
      @if (dataService?.templateInfo) {
        <app-template-schema class="mt-3"></app-template-schema>
      }
      @if (dataService?.templateData) {
        <app-feature-data class="mt-3"></app-feature-data>
      }
      @if (dataService?.recordtemplateData) {
        <app-record-data-form class="mt-3"></app-record-data-form>
      }
      @if (store?.showmultipleformsonmap) {
        <app-feature-multiple-form-data-map class="mt-3"></app-feature-multiple-form-data-map>
      }
    </div>
  }
</div>
