import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EmailSenderService } from 'src/app/services/email-sender.service';
import { StoreService } from 'src/app/services/store.service';
import { SIGNUP_ADMIN_LOGIN, SIGNUP_ADMIN_PASS, SysAdminUserServiceService } from 'src/app/services/sys-admin-user-service.service';
import { UserService } from 'src/app/services/user.service';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../services/toast.service';

@Component({
  standalone: true,
  imports: [TranslateModule, 
    MatCardModule,
    MatToolbarModule,
    MessageBoxComponent,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
  ],
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent implements OnInit {
  @ViewChild('fform') feedFormDirectives: any;

  errMsg: string;
  initPassSent = false;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  user: any = {
    email: ''
  };





  constructor(
    private store: StoreService,
    private router: Router,
    private userService: UserService,
    private mcUserService: SysAdminUserServiceService,
    private emailSenderService: EmailSenderService,
    private dialog: MatDialog,
    public toastService: ToastService,
    ) { }

  ngOnInit() {
  }

  onSubmit() {

    this.store.showLoading();

    this.mcUserService.login(SIGNUP_ADMIN_LOGIN, SIGNUP_ADMIN_PASS).subscribe(
      token => {
        this.store.showLoading();
        this.userService.resetPassword(this.user.email, token).subscribe(
          res => {
            this.emailSenderService.loginMCEmailSenderAdmin().subscribe(
              res2 => {
               const activationCode = res.password_reset_code;
               const body = 'https://www.web.mycumulus.com/resetPassword.html?token=' + activationCode;
               const emailFrom = 'info@mycumulus.com';
               const emailSubject = 'Init password reset';
               const emailBody = body;
               const emailTemplateId = 3;
               this.emailSenderService.sendEmail(res2, this.user.email, emailBody, emailTemplateId).subscribe(
                  res3 => {
                    this.store.hideLoading();
                    this.initPassSent = res3.succeed;
                  },
                  err3 => {
                    this.toastService.errorToast(err3);
                    this.store.hideLoading();
                  }
                );
              },
              err2 => {
                this.toastService.errorToast(err2);
                this.store.hideLoading();
              }
            );
          },
          errmess => {
            this.store.hideLoading();
            this.showMessage = true;
            if (errmess === 'invalid credentials.') {
              this.toastService.errorToast('Invalid username or password');
            } else {
              this.toastService.errorToast(errmess);
            }
          }
        );
      },
      err => {
        this.toastService.errorToast(err);
      }
    );
  }

  closePan() {
    this.router.navigate(['/login']);
  }

  cancelResetPassword(): void {
    this.router.navigate(['/login']);
  }
}
