<div fxFlexLayout="row" class="mb-2">
  <div fxFlex fxFlexOffset="20px">
    <h3>{{ title }}</h3>
    <mat-dialog-content>
      <p>
        <!-- Include the image drag and drop elements here-->
      </p>
      <p>
        {{ message }}
      </p>
      <p>
      </p>
    </mat-dialog-content>
    <mat-dialog-actions class="button-group">
      @if (formDuplication) {
        <button mat-button mat-dialog-close (click)="onNoClick($event)" class="btn cancel-btn"><span>{{ "NO" | translate }}</span></button>
        <button mat-button (click)="confirmChanges($event)" cdkFocusRegionstart class="btn submit-btn"><span>{{ "YES" | translate }}</span></button>
      } @else if (constraintList) {
        <button mat-button [mat-dialog-close]="true" class="btn cancel-btn"><span>{{ "CANCEL" | translate }}</span></button>
        <button mat-button (click)="onNoClick($event)" cdkFocusRegionstart class="btn submit-btn"><span>{{ "SAVE" | translate }}</span></button>
      } @else {
        <button mat-button (click)="onNoClick($event)" cdkFocusRegionstart class="btn cancel-btn"><span>{{ "NO" | translate }}</span></button>
        <button mat-button [mat-dialog-close]="true" class="btn submit-btn"><span>{{ "YES" | translate }}</span></button>
      }
    </mat-dialog-actions>
  </div>
</div>
