import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {DialogActionsComponent} from '../dialog-actions/dialog-actions.component';
import {ToastService} from '../../../services/toast.service';

@Component({
  standalone: true,
  imports: [TranslateModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDialogContent,
    MatRadioButton,
    MatDialogActions,
    MatButtonModule,
    MatRadioGroup,
    DialogActionsComponent,
  ],
  selector: 'app-edit-feature-value',
  templateUrl: './edit-map-value.html',
  styleUrls: ['./edit-map-value.scss', '../dialog-btns.component.scss']
})
export class EditMapValueComponent implements OnInit {
  editDataForm: FormGroup;
  value: any;
  type: string;
  errMsg: string;
  item: any;
  dateRegEx = /^\d{4}-\d{2}-\d{2}$/;
  timeRegEx = /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])?$/;
  canWeLeave: boolean = true;
  isThisRequired: boolean;
  mode = {
    function: 'Update',
    text: this.translate.instant('UPDATE')
  }

  @ViewChild('fform') editDataFormDirective;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data,
    public toastService: ToastService,
    private dialogRef: MatDialogRef<EditMapValueComponent>) {
    if (data) {
      this.isThisRequired = data.template.form.fields.find(x => x.name === data.item.key)?.required ?? true;
      this.item = data.item;
      this.value = data.item.value;
      this.type = data.type;
    }
  }

  ngOnInit() {
    this.createForm();
  }

  requiredPatternValidator(isRequired: boolean, pattern: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      // If the field is required and empty, return 'required' error
      if (isRequired && !value) {
        return { required: true };
      }

      // If the field is empty and not required, it's valid
      if (!value) {
        return null;
      }

      // If the field has a value, validate against the pattern
      const isValid = pattern.test(value);
      return isValid ? null : { pattern: { requiredPattern: pattern.toString(), actualValue: value } };
    };
  }


  private myValidator = [Validators.required];

  createForm() {
    // Pre-process the value based on the type
    if (this.type === 'boolean') {
      this.value = this.value ? '1' : '2';
    } else if (this.type === 'date' && this.value) {
      this.value = this.value.split('T')[0];
    }

    // Determine the regex pattern based on the type
    let pattern: RegExp;
    if (this.type === 'date') {
      this.errMsg = 'Format is invalid. Must be a valid "YYYY-MM-DD"';
      pattern = this.dateRegEx;
    } else if (this.type === 'time') {
      this.errMsg = 'Format is invalid. Must be a valid "HH:mm:ss"';
      pattern = this.timeRegEx;
    }

    if(pattern) {
      // Create the form control with the custom validator
      this.editDataForm = this.fb.group({
        name: [
          this.value,
          [
            this.requiredPatternValidator(this.isThisRequired, pattern),
            // Include other validators if needed
          ],
        ],
      });
    } else {
      this.editDataForm = this.fb.group({
        name: [this.value]
      });
    }
  }

  @ViewChild('textInput') textInput: ElementRef;

  isValidDate(dateString: string): boolean {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return false;
    }
    const [year, month, day] = dateString.split('-').map(Number);
    return (
      date.getUTCFullYear() === year &&
      date.getUTCMonth() + 1 === month && // getUTCMonth() is zero-indexed, so add 1
      date.getUTCDate() === day
    );
  }

  onSubmit() {
    if (this.value && this.type === 'date') {
      this.canWeLeave = this.isValidDate(this.textInput.nativeElement.value);
      if(!this.isValidDate(this.textInput.nativeElement.value)) {
        this.toastService.warningToast("Introduced date does not exist")
        return;
      }
      if(this.textInput.nativeElement.value === null || this.textInput.nativeElement.value === "") {
        this.toastService.warningToast("Date fields can not be empty");
        return;
      }
    } else if (this.value && this.type === 'time') {
      this.canWeLeave = this.timeRegEx.test(this.textInput.nativeElement.value);
      if(this.textInput.nativeElement.value === null || this.textInput.nativeElement.value === "") {
        this.toastService.warningToast("Time fields can not be empty");
        return;
      }
    }
    if ((this.textInput.nativeElement.value === null || this.textInput.nativeElement.value === "") && this.isThisRequired) {
      this.canWeLeave = false;
      this.toastService.warningToast("This field is required and can not be empty");
      return;
    }

    let res = {
      output: this.textInput.nativeElement.value,
      isRes: true
    }

    const decimalRegex = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;

    if(this.type === 'integer') {
      if(decimalRegex.test(res.output) && res.output !== '') {
        res.output = parseInt(res.output);
        this.canWeLeave = true;
      } else if(res.output === '')
      {
        res.output = null;
        this.canWeLeave = true;
      }
      else {
        this.canWeLeave = false;
        this.toastService.warningToast("The format is not correct, Update not possible");
        return
      }
    }
    else if(this.type === 'double' || this.type === 'decimal' || this.type === 'float') {
      if(decimalRegex.test(res.output) && res.output !== '') {
        res.output = parseFloat(res.output);
        this.canWeLeave = true;
      } else if(res.output === '') {
        res.output = null;
        this.canWeLeave = true;
      }
      else {
        this.canWeLeave = false;
        this.toastService.warningToast("The format is not correct, Update not possible");
        return
      }
      res.output = parseFloat(res.output);
    }

    if(this.canWeLeave) {
      if(this.value !== this.textInput.nativeElement.value){
        this.toastService.successToast("Element has been updated");
        this.dialogRef.close(res);
      } else {
        this.toastService.warningToast("No changes were saved");
        this.dialogRef.close(res)
      }
    } else {
      this.toastService.warningToast("The format is not correct, Update not possible");
    }
  }
}
