<section>
  <div class="form-box">
      <div class="form-value">
          <form class="loginForm" novalidate #loginForm="ngForm" (ngSubmit)="onSubmit()">
            <div class="login-header">
              <img class="loginLogo" src="/assets/images/login-icon.png"/>
              <p class="loginTitle"><span>{{ "WELCOME TO MYCUMULUS" | translate }}</span></p>
            </div>

            <div class="login-body">
              <div class="inputbox inputbox-top">
                  <input type="text" [(ngModel)]="user.name" [placeholder]="'EMAIL OR USERNAME' | translate" name="name" required>
              </div>
              <div class="inputbox">
                <input type="password"  [(ngModel)]="user.password" [placeholder]="'PASSWORD' | translate" name="password" required>
              </div>
              <div class="login-btn login-remember">
                <button type="submit" class="submit" [disabled]="loginForm.form.invalid">Login >></button>
<!--                <div class="remember">-->
<!--                  <input type="checkbox" [(ngModel)]="user.remember" name="remember"><span>{{ "REMEMBER ME" | translate }}</span>-->
<!--                </div>-->
              </div>
              <a class="password-forget" (click)="openPasswordResetingPage($event)"><span>{{ "I FORGOT MY PASSWORD" | translate }}</span></a>
            </div>

            <div class="register">
              <p>{{"DON'T HAVE AN ACCOUNT?" | translate}}</p>
              <a type="submit" (click)="openSignUp($event)">{{"SIGN UP HERE" | translate}} >></a>
              <p>{{"PREFER THE OLD ONE?" | translate}}</p>
              <a href="https://old.webapp.mycumulus.com/#/login">{{"GO TO OLD VERSION" | translate}} >></a>
            </div>

            <div class="private-server">
              <button (click)="openPrivateServer($event)"><img class="signup-icon" src="/assets/images/signup-icon-grey.png"/><p>{{ "USE PRIVATE SERVER" | translate }}</p></button>
            </div>
            <div class="web-app-version mt-1" >
              <p style="font-weight: 40 !important; font-size: 8px; text-align:right;">WebApp .. v. {{getWebAppVersion()}} <!--running on REST API {{getApiVersion()}} --></p>
            </div>
          </form>
      </div>
  </div>
</section>
