import { TranslateModule } from '@ngx-translate/core';
import {Component, OnInit, Input} from '@angular/core';
import { Template } from 'src/app/share/template';
import { TemplateForm } from 'src/app/share/form/template-form';
import { FeatureSchema } from 'src/app/share/schema/schema';
import {getUser} from 'src/app/share/utils';
import $ from 'jquery';
import { StoreService } from 'src/app/services/store.service';
import { MatTooltipModule} from '@angular/material/tooltip';
import {HeaderService} from '../../services/header.service';
import {CommonModule, TitleCasePipe} from '@angular/common';
import {DataService} from '../../services/data.service';
import {ToastService} from '../../services/toast.service';
import {Subscription} from 'rxjs';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faEllipsisVertical} from '@fortawesome/free-solid-svg-icons';

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatTooltipModule,
    CommonModule, MatMenu, MatMenuItem, MatMenuTrigger, FaIconComponent,
  ],
  selector: 'app-template-item',
  templateUrl: './template-item.component.html',
  styleUrls: ['./template-item.component.scss']
})
export class TemplateItemComponent implements OnInit {
  @Input() template: Template;
  form: TemplateForm;
  schema: FeatureSchema;
  selectedTemplate: string;

  constructor(
    public store: StoreService,
    public headerService: HeaderService,
    private titlecasePipe:TitleCasePipe,
    public dataService: DataService,
    public toastService: ToastService,
  ) { }

  tooltipPosition = { x: 0, y: 0 };

  onMouseMove(event: MouseEvent) {
    const tooltipOffset = 10; // offset to avoid tooltip overlapping with the cursor

    this.tooltipPosition = {
      x: event.clientX + tooltipOffset,
      y: event.clientY + tooltipOffset
    };

    const tooltipElement = document.querySelector('.mat-tooltip') as HTMLElement;
    if (tooltipElement) {
      tooltipElement.style.left = `${this.tooltipPosition.x}px`;
      tooltipElement.style.top = `${this.tooltipPosition.y}px`;
      tooltipElement.style.position = 'fixed';
    }
  }

  private searchSubscription: Subscription;

  ngOnInit() {
    this.searchSubscription = this.dataService.triggerSearch$.subscribe(
      data => {
        this.handleTemplateClicked(data.temp, null)
      }
    );

    this.form = this.template.form;
    this.schema = this.template.feature;

    if (this.dataService.template.getValue() !== null && this.dataService.template.getValue() !== undefined
     && this.template.name === this.dataService.template.getValue().name) {
      this.chooseTemplate(this.template);
    }
  }

  chooseTemplate(temp) {
    this.selectedTemplate = temp.name;
    const $items = $('.template-item');
    const classHighlight = 'templatehighlight';
    $items.removeClass(classHighlight);
    this.selectedTemplate = temp.name;
  }

  deleteTemplate(event) {
    event.stopPropagation();
    if(this.store.features && this.store.features.length > 0) {
      this.toastService.errorToast(`Deleting the project or form is not possible if any records are selected. Please deselect them`)
      return;
    }
    this.dataService.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.dataService.project;
      if(user.id === project.created_by){
        this.headerService.deleteTemplateAfterCheck(this.template);
      }
    });
  }

  duplicateTemplate(event) {
    event.stopPropagation();
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is not allowed on an archived ${projectClass}`)
      return;
    }
    this.dataService.testIfUpdatingForm(() => {
      this.headerService.duplicateTemplateAfterCheck(this.template);
    });
  }

  editTemplate(event) {
    event.stopPropagation();
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is not allowed on an archived ${projectClass}`)
      return;
    }

    this.dataService.testIfUpdatingForm(() => {
      const user = getUser();
      const project = this.dataService.project;
      if(user.id === project.created_by){
        this.headerService.openEditTemplateDialogAfterCheck(this.template);
      }

    });
  }

  test(event) {
    event.stopPropagation();
  }



  handleTemplateClicked(temp, event) {
    if (event)
      event.preventDefault();

    this.dataService.selectedTemplate.next(temp.name);
    this.selectedTemplate = this.dataService.selectedTemplate.getValue();
  }

  protected readonly faEllipsisVertical = faEllipsisVertical;
  protected readonly getUser = getUser;
}
