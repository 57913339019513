<div class="admin-page">
  <div id="admin-container-div" class="admin-container">
    <div class="content-row">
      <div id="adminMenuSide" class="admin-menu">
        <div class="admin-home"><span>{{ "REPORTS" | translate }}</span></div>
        <ul id="navAdmin"></ul>
      </div>
      <div class="vertical-divider"></div>
      <div id="adminContent" class="admin-content">
        <div id="userspage" class="users-page">
          <div class="search-section">
            <div class="search-row">
              <span class="search-input">
                @if(showMessage) {
                  <app-message-box #messageBox [message]="message"></app-message-box>
                }
                <div class="form-group has-clear"></div>
              </span>
              <span class="text-right">
                <button mat-button (click)="removeSelectedReport()" color="primary" class="btn-border top-btn">
                  <mat-icon>delete</mat-icon>
                </button>
              </span>
            </div>
          </div>
          <div class="table-section">
            <div class="table-row">
              <table mat-table [dataSource]="dataSource" class="table table-striped table-bordered">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef class="header-cell">
                        <mat-checkbox class="table-cells" color="primary" (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox class="table-cells" color="primary" (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="header-cell"><span>{{ "NAME" | translate }}</span></th>
                    <td mat-cell *matCellDef="let element" class="table-cells">
                        <span class="ml-2">{{element.filename}}</span>
                    </td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="header-cell"><span>{{ "ACTIONS" | translate }}</span></th>
                    <td mat-cell *matCellDef="let element" class="action-link table-cells">
                        <button class="btn-border buttons" mat-button (click)="navigateToUrl(element.download_url)">
                          <fa-icon [icon]="faDownload"></fa-icon>
                        </button>
                        <button class="btn-border buttons" mat-button (click)="OpenDeleteReportConfirmation(element)">
                            <mat-icon class="icons delete-icon">delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
