<div id="loginContainer">
  <mat-toolbar id="loginHeader">
    <span id="loginTitle"><span>{{ "MARXACT LOGIN PAGE" | translate }}</span></span>
  </mat-toolbar>
  @if (showMessage) {
    <app-message-box #messageBox message="{{message}}"></app-message-box>
  }
  <form id="loginForm" novalidate [formGroup]="marxactLoginForm" #fform="ngForm" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
      <mat-form-field style="width: 100% !important;">
        <input matInput formControlName="username" [placeholder]="'USERNAME' | translate" type="text" required>
        @if (marxactLoginForm.get('username').hasError('required') && marxactLoginForm.get('username').touched) {
          <mat-error><span>{{ "NAME IS REQUIRED" | translate }}</span></mat-error>
        }
      </mat-form-field>
      <mat-form-field style="width: 100% !important;">
        <input matInput formControlName="password" [placeholder]="'PASSWORD' | translate" type="password" required>
        @if (marxactLoginForm.get('password').hasError('required') && marxactLoginForm.get('password').touched) {
          <mat-error><span>{{ "PASSWORD IS REQUIRED" | translate }}</span></mat-error>
        }
      </mat-form-field>
    </mat-dialog-content>
    <app-dialog-actions [mode]="mode" [formCheck]="marxactLoginForm"></app-dialog-actions>
  </form>
</div>
