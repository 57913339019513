<section>
  <div class="form-box">
      <div class="form-value">
          <form id="loginForm" novalidate [formGroup]="mcAdminLoginForm" (ngSubmit)="onSubmit()">
              <img src="/assets/images/logo-mycumulus.svg"/>

              @if (showMessage) {
                <app-message-box #messageBox message="{{message}}"></app-message-box>
              }

              <div class="inputbox">
                  <input matInput autocomplete="off" formControlName="name" [placeholder]="'EMAIL OR USERNAME' | translate" type="text" required>
                  <label><span>{{ "EMAIL OR USERNAME" | translate }}</span></label>
              </div>
              <div class="inputbox">
                <input matInput autocomplete="off" formControlName="password" [placeholder]="'PASSWORD' | translate" type="password" required>
                <label><span>{{ "PASSWORD" | translate }}</span></label>
              </div>

              <div class="login-btn">
                <button class="submit" mat-button type="submit" [disabled]="mcAdminLoginForm.invalid">Login >></button>
              </div>

              <div class="remember-forget">
                <div class="remember">
                  <input type="checkbox" color="primary" class="full-width" formControlName="remember" name="remember">
                  <label><span>{{ "REMEMBER ME" | translate }}</span></label>
                </div>
                <a (click)="openMCAdminPasswordResetingPage($event)" style="font-weight: 500 !important; color: #337ab7;" class="text-links ml-3">Forgot Password?</a>
              </div>
              <div class="mt-3 private-server">
                <button (click)="openPrivateServer($event)"><span>{{ "USE PRIVATE SERVER" | translate }}</span></button>
              </div>
          </form>
      </div>
  </div>
</section>
