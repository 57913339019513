<div fxLayout="column" fxLayoutGap="0px">
  <div class="mt-3 mr-2" fxFlex="10">
    @if (showMessage) {
      <app-message-box #messageBox style="margin-left: -1px !important;" message="{{message}}"></app-message-box>
    }
  </div>

  <div id="titleContainer" fxFlex="4">
    <span fxLayout="row" style="height: 40px;">
      <h3 class="m-3" fxFlex>
        Record Data of {{activeTemplate.name}}
      </h3>
    </span>
  </div>
  <div fxFlex="76" class="mt-3">
    <div id="fieldsContainer" fxLayout="column">
      <div fxFlex>
        <ng-container>
          <app-fieldset-data (closeForm)="closeForm()" (saveData)="uploadRecord(featureData)" [fieldset]="rootFieldset"></app-fieldset-data>
        </ng-container>
      </div>
    </div>
  </div>
</div>