import { TranslateModule } from '@ngx-translate/core';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { StoreService } from 'src/app/services/store.service';
import { URL, getApiAURL, getAtStartup, persistApiURL, removeApiURL } from 'src/app/share/utils';
import {CookieService} from 'ngx-cookie-service';

@Component({
  standalone: true,
  imports: [TranslateModule,
    ReactiveFormsModule,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDialogActions,
    MatDialogModule,
    MatButtonModule,
  ],
  selector: 'app-config-server-url',
  templateUrl: './config-server-url.component.html',
  styleUrls: ['./config-server-url.component.scss', '../dialog-btns.component.scss']
})
export class ConfigServerUrlComponent implements OnInit {

  configUrlForm: FormGroup;
  urlEmpty: boolean;
  @ViewChild('fform') audioFieldFormDirectives;
  setStartup: boolean;

  constructor(
    private fb: FormBuilder,
    public store: StoreService,
    public cookieService: CookieService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ConfigServerUrlComponent>
  ) { }

  ngOnInit() {
    this.createForm();
    this.setStartup = this.cookieService.get("privateServerEnabled") === 'true';
  }

  createForm() {
    this.urlEmpty = false;
    let currentApiUrl = this.cookieService.get("lastPrivateServer");
    if (URL === currentApiUrl) {
      currentApiUrl = '';
    }
    this.configUrlForm = this.fb.group({
      url: [currentApiUrl, [Validators.required, Validators.min(8)]]
    });
  }

  setPrivateStartup(value: boolean){
    this.setStartup = value;
    value ? this.cookieService.set("privateServerEnabled",  'true', 365) : this.cookieService.set("privateServerEnabled",  'false', 365);
  }

  onSubmit() {
    this.urlEmpty = false;
    const value = this.configUrlForm.value;
    this.cookieService.set("lastPrivateServer", value['url'], 365);

    if(!this.setStartup) {
      this.cookieService.set("privateServerEnabled",  'false', 365);
      removeApiURL();
      this.dialogRef.close();
      return;
    }

    if (value) {
      const url = value['url'];
      if (url) {
        // Save the url in the localstorage
        if(url.trim().length <= 0) {
          this.urlEmpty = true;
          return;
        } else {
          persistApiURL(url.trim(), this.setStartup);
          this.dialogRef.close();
        }

      } else {
        removeApiURL();
      }
    } else {
      removeApiURL();
    }
    this.dialogRef.close();
  }

}
