import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { getUser, getAdminToken } from 'src/app/share/utils';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { CollaboratorsDialogComponent } from '../dialogs/collaborators-dialog/collaborators-dialog.component';
import { SysAdminUserServiceService, SIGNUP_ADMIN_LOGIN, SIGNUP_ADMIN_PASS } from 'src/app/services/sys-admin-user-service.service';
import { CollaboratorListDialogComponent } from '../dialogs/collaborator-list-dialog/collaborator-list-dialog.component';
import { UserService } from 'src/app/services/user.service';
import { StoreService } from 'src/app/services/store.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../services/toast.service';

export interface AdminUser {
  id: string;
  name: string;
  email: string;
  created_date: string;
  managed_users: string;
  gnss_source: string;
  not_collaborator: boolean;
  administrator: string;
}

const ELEMENT_DATA: AdminUser[] = [];

@Component({
  standalone: true,
  imports: [TranslateModule,
    CommonModule,
    MessageBoxComponent,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatPaginator,
    MatTableModule,
    MatButtonModule,
  ],
  selector: 'app-mc-users-page',
  templateUrl: './mc-users-page.component.html',
  styleUrls: ['./mc-users-page.component.scss']
})

export class McUsersPageComponent implements OnInit {
  user: any;
  collaborator: AdminUser;
  displayedColumns: string[] = ['select', 'name', 'email', 'created_date', 'administrator', 'managed_users', 'gnss_source'];

  dataSource = new MatTableDataSource<AdminUser>(ELEMENT_DATA);
  selection = new SelectionModel<AdminUser>(true, []);
  searchValue: string;
  message = '';
  showMessage: boolean;
  metadata: any;
  moreUsers: boolean;
  onlyAdmins: boolean;

  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }

  constructor(
    private dialog: MatDialog,
    public store: StoreService,
    private mcUserService: SysAdminUserServiceService,
    private userService: UserService,
    private translate: TranslateService,
    public toastService: ToastService,
  ) { }

  ngOnInit() {
    this.dataSource.data = [];
    this.moreUsers = true;
    this.onlyAdmins = true;
    this.loadUsers('users', []);
  }

  displayUsers(res, firstPage){
    let data = this.dataSource.data;
    data = [];
    if(firstPage.length > 0)
      data = firstPage;

    this.metadata = res.metadata;

    if(this.metadata.uris.next !== null && this.metadata.uris.next !== undefined){
      this.moreUsers = false;
    } else {
      this.moreUsers = true;
    }
    for (const collaborator of  res.instances) {
      let gnssSource = '';
      if (collaborator.mc_marxact !== null && collaborator.mc_marxact !== undefined) {
        const devices = collaborator.mc_marxact.devices;
        if (devices !== null) {
          if (devices.length > 0) {
              for (const device of devices) {
                gnssSource = (gnssSource === null || gnssSource === '') ? device.device_name : gnssSource + ',' + device.device_name;
              }
          }
        }
      }

      let el;
      let managedUserCnt = 0;
      if (collaborator.managed_users_ids !== null && collaborator.managed_users_ids !== undefined) {
        managedUserCnt = collaborator.managed_users_ids.length;
      }
      el = {
        id: collaborator.id,
        name: collaborator.name,
        email: collaborator.email,
        created_date: new Date(collaborator.created_at),
        administrator: this.getUser(collaborator, res.instances),
        managed_users: managedUserCnt,
        users: collaborator.managed_users_ids,
        gnss_source: gnssSource,
        not_collaborator: this.getUsertype(collaborator)
      };

      if(this.onlyAdmins){
        if(this.getUsertype(collaborator) !== 'collaborator')
          data.push(el);
      } else {
        data.push(el);
      }
    }
    this.dataSource.data = data;
    this.table.renderRows();
    this.store.hideLoading();
  }

  showOnlyAdmins(event){
    this.onlyAdmins = event.checked;
    this.loadUsers('users', []);
  }

  openCollaboratorList(collaborator){
    if(collaborator.managed_users > 0){
      const dialogRef = this.dialog.open(CollaboratorListDialogComponent, {
        width :'400px',
        height : 'auto',
        data : {
          admin: collaborator
        }
      });
    }
  }

  getUsertype(collaborator): string {
    let userType = collaborator['user_type'];
    if(userType === null || userType === 'collaborator'){
      const manager = collaborator['manager_id'];
      if(manager !== undefined && manager !== null && manager !== '')
        userType = 'collaborator';
    }

    return userType;
  }

  getUser(collaborator, users): string {
    let id = collaborator.id;
    let managerName = "";
    const userType = collaborator['user_type'];
    if(userType === null || userType === 'collaborator'){
      const managerId = collaborator['manager_id'];
      if(managerId !== undefined && managerId !== null && managerId !== '') {
        const manager = users.find(user => {
          return user.id === managerId;
        } );
        if(manager && manager.id === managerId) {
          managerName = manager['name'];
        }
        id = managerId;
      }
    } else if (userType === 'admin_user') {
      managerName = collaborator.name;
    }
    return managerName;
  }

  loadUsers(uri, firstPage){
    this.user = getUser();
    const token = getAdminToken();
    this.store.showLoading();
    this.mcUserService.getAdminUsers(token, uri).subscribe(
      res => {
        this.displayUsers(res, firstPage);

        if(this.moreUsers === false)
          this.loadMoreUsers();
      },
      err => {
        this.toastService.errorToast(err);
        this.store.hideLoading();
      });
  }

  loadMoreUsers(){
    if(this.metadata !== null && this.metadata !== undefined){
      const uris = this.metadata.uris;

      if(uris.next !== null && uris.next !== undefined){
        let linkValue = uris.next;
        for (let i = 0; i < 4; i++) {
          linkValue = linkValue.substring(1);
        }

        this.loadUsers(linkValue, this.dataSource.data);
      }
    }
  }

  isMarxactDevices(element): boolean {
    if (element.gnss_source !== null && element.gnss_source !== undefined && element.gnss_source !== '') {
        return true;
    }
    return false;
  }

  applyFilter(event) {
    event.preventDefault();
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    event.stopPropagation();
  }

  /**
   * This method allow to create new collaborator user
   */
  openAddUserDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(CollaboratorsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Add') {
        // User created add it to the table and refresh the table
        this.store.showLoading();
        const userData = result.data;
        userData.user_type = 'admin_user';
        delete userData.may_create_projects;
        const token = getAdminToken();

        this.mcUserService.login(SIGNUP_ADMIN_LOGIN, SIGNUP_ADMIN_PASS).subscribe(
          result => {
            this.mcUserService.signupUser(userData, result).subscribe(
              res => {
                this.userService.activateAccount({activation_code: res.activation_code}).subscribe(
                  resu => {
                    this.loadUsers('users', []);
                    this.store.hideLoading();
                  },
                  er => {
                    this.store.hideLoading();
                    this.toastService.errorToast(er);
                  }
                );
              },
              err => {
                this.store.hideLoading();
                this.toastService.errorToast(err);
              }
            );
          },

          error => {
            this.store.hideLoading();
            this.toastService.errorToast(error);
          }
        );

      }
    });
  }

  openConfirmDeleteUserDialog() {
    if (this.selection.selected.length <= 0) {
      this.toastService.errorToast('No user selected');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        message: this.translate.instant("DO YOU REALLY WANT TO DELETE THE SELECTED USERS?"),
        title: this.translate.instant("DELETE USERS?")
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const user = this.selection.selected[0];
        const token = getAdminToken();
        while (this.selection.selected.length > 0) {
          const tempU = this.selection.selected.splice(0, 1)[0];
          this.deleteUser(tempU.id, token);
          this.selection.toggle(tempU);
        }
        /*
        for (const selectedUser of this.selection.selected) {
          this.deleteUser(selectedUser.id, token);
        }
        */
      }
    });
  }

  deleteUser(userId, token) {
    this.store.showLoading();
    this.mcUserService.deleteAdminUser(userId, token).subscribe(
      res => {
        const data = this.dataSource.data;
        const newdata = data.filter(f => f.id !== userId);
        this.dataSource.data = newdata;
        this.table.renderRows();
        this.store.hideLoading();
      },
      errmes => {
        this.store.hideLoading();
        this.toastService.errorToast(errmes);
      }
    );
  }

    /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.map(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: AdminUser): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }
}
