import { TranslateModule } from '@ngx-translate/core';
import {Component, OnInit, Input, ViewChild, Inject, HostListener} from '@angular/core';
import { VideoField } from 'src/app/share/form/video-field';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldSet } from 'src/app/share/form/fieldset';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import { StoreService } from 'src/app/services/store.service';
import { isFieldAlreadyExist, removeSpecialCharacters } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import {DialogActionsComponent} from '../dialog-actions/dialog-actions.component';

@Component({
  standalone: true,
    imports: [TranslateModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MessageBoxComponent,
        MatDialogContent,
        MatDialogActions,
        MatCheckboxModule,
        MatDialogModule,
        MatButtonModule,
        DialogActionsComponent,
    ],
  selector: 'app-create-video-field',
  templateUrl: './create-video-field.component.html',
  styleUrls: ['./create-video-field.component.scss', '../dialog-btns.component.scss']
})
export class CreateVideoFieldComponent {

  videoField: VideoField;
  videoFieldForm: FormGroup;
  @Input() parent: FieldSet;
  @Input() mode = {
    text: 'Create',
    function: 'Create'
  };
  errMsg: string;

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;
  nameLengthExceed10 = false;
  @ViewChild('fform') videoFieldFormDirective;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CreateVideoFieldComponent>,
    public toastService: ToastService,
    ) {
    if (data !== undefined && data !== null) {
      this.parent = data.parent;
      this.mode = data.mode;
      this.videoField = data.field;
    }
    this.createForm();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  createForm() {
    if (this.mode.function === 'Create') {
      this.videoFieldForm = this.fb.group({
        name: ['', Validators.required],
        label: ['', Validators.required],
        description: '',
        enabled: true,
        required: false
      });
    } else {
      this.videoFieldForm = this.fb.group({
        name: [this.videoField.name, Validators.required],
        label: [this.videoField.label, Validators.required],
        description: this.videoField.description,
        enabled: this.videoField.enabled,
        required: this.videoField.required
      });
    }

  }

  onNameChange() {
    if (this.videoFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  onLabelChange() {
    this.videoFieldForm.controls.name.setValue(removeSpecialCharacters(this.videoFieldForm.value.label));
    if (this.videoFieldForm.value.name.length > 10) {
      this.nameLengthExceed10 = true;
    } else {
      this.nameLengthExceed10 = false;
    }
  }

  onSubmit() {
    if (this.videoField) {
      this.videoField = {
        ...this.videoField,
        ...this.videoFieldForm.value
      };
    } else {
      this.videoField = this.videoFieldForm.value;
    }

    // this.videoField = this.videoFieldForm.value;
    this.videoField._class = 'videofield';


    if (this.videoField.name === this.parent.name) {
      this.toastService.errorToast('Field cannot take the same name as the active fieldset');
      return;
    }
    const result = {
      action: 'Create',
      field: this.videoField
    };
    if (this.mode.function === 'Create') {
      if (isFieldAlreadyExist(this.videoField, this.parent)) {
        this.toastService.errorToast('Another field with the same name/label already exists');
        return;
      }
      this.store.addField(this.videoField);
    } else {
      result.action = 'Update';
      this.store.updateField(this.videoField);
    }
    this.dialogRef.close(result);
  }
}
