<div class="layout-column layout-gap-0 formSchemaContainer">
  <div id="buttonsContainer" class="mt-1 flex-10 top-buttons-container">
    <span class="layout-row flex-offset-2 fieldsDialogButtons">
      <span class="btn-pointer flex" [matTooltip]="'AUTO INCREMENT' | translate" (click)="openAutoIntegerDialog()" mat-button>
        <fa-icon [icon]="faArrowUp91" size="3x"></fa-icon>
      </span>
      <mat-divider class="end-div"></mat-divider>
      <mat-divider class="start-div"></mat-divider>
      <span class="btn-pointer" [matTooltip]="'INTEGER VALUE' | translate" (click)="openIntegerDialog()" mat-button>
        <fa-icon [icon]="faZ" size="3x"></fa-icon>
      </span>
      <span class="btn-pointer flex" [matTooltip]="'DECIMAL VALUE' | translate" (click)="openDecimalDialog()" mat-button>
        <fa-icon [icon]="faPercent" size="3x"></fa-icon>
      </span>
      <span class="btn-pointer flex" [matTooltip]="'TEXT' | translate" (click)="openStringDialog()" mat-button>
        <fa-icon [icon]="faFont" size="3x"></fa-icon>
      </span>
      <span class="btn-pointer flex" [matTooltip]="'BOOLEAN' | translate" (click)="openBooleanFieldDialog()" mat-button>
        <fa-icon [icon]="faToggleOff" size="3x"></fa-icon>
      </span>
      <mat-divider class="end-div"></mat-divider>
      <mat-divider class="start-div"></mat-divider>
      <span class="btn-pointer flex" [matTooltip]="'BARCODE' | translate" (click)="openBarCodeDialog()" mat-button>
        <fa-icon [icon]="faBarcode" size="3x"></fa-icon>
      </span>
      <span class="btn-pointer flex" [matTooltip]="'PHOTO' | translate" (click)="openPhotoDialog()" mat-button>
        <fa-icon [icon]="faPhotoFilm" size="3x"></fa-icon>
      </span>
      <span class="btn-pointer flex" [matTooltip]="'VIDEO' | translate" (click)="openVideoDialog()" mat-button>
        <fa-icon [icon]="faVideo" size="3x"></fa-icon>
      </span>
      <span class="btn-pointer flex" [matTooltip]="'AUDIO' | translate" (click)="openAudioFieldDialog()" mat-button>
        <fa-icon [icon]="faFileWaveform" size="3x"></fa-icon>
      </span>
      <mat-divider class="end-div"></mat-divider>
      <mat-divider class="start-div"></mat-divider>

      <span class="btn-pointer flex" [matTooltip]="'DATE' | translate" (click)="openDateFieldDialog()" mat-button>
        <fa-icon [icon]="faCalendar" size="3x"></fa-icon>
      </span>
      <span class="btn-pointer flex" [matTooltip]="'TIME' | translate" (click)="openTimeFieldDialog()" mat-button>
        <fa-icon [icon]="faClock" size="3x"></fa-icon>
      </span>
      <mat-divider class="end-div"></mat-divider>
      <mat-divider class="start-div"></mat-divider>

      <span class="btn-pointer flex" [matTooltip]="'FIELD SET' | translate" (click)="openFieldsetDialog()" mat-button>
        <fa-icon [icon]="faObjectGroup" size="3x"></fa-icon>
      </span>
      <span class="btn-pointer flex" [matTooltip]="'FIELD SET ARRAY' | translate" (click)="openFieldSetArrayDialog()" mat-button>
        <fa-icon [icon]="faTableList" size="3x"></fa-icon>
      </span>
      <span class="btn-pointer flex" [matTooltip]="'GPS COORDINATE' | translate" (click)="openGPSCoordinatesDialog()" mat-button>
        <fa-icon [icon]="faLocationDot" size="3x"></fa-icon>
      </span>
      <span class="btn-pointer flex" [matTooltip]="'NOTE' | translate" (click)="openNoteDialog()" mat-button>
        <fa-icon [icon]="faStickyNote" size="3x"></fa-icon>
      </span>
    </span>

    <button [disabled]="!store.updatedForm" [class]="!store.updatedForm ? 'btn-save-disabled' : 'btn-save'" [matTooltip]="'SAVE' | translate" (click)="updateTemplate()" mat-button="">
        <fa-icon [icon]="faSquareCheck" size="3x"></fa-icon>
    </button>
  </div>


  <div [class]="!store.selectedItemId || store.selectedItemId === '' ? 'flex-4 fs-item titleContainer selectedFs' : 'flex-4 fs-item titleContainer'" [matTooltip]="'RESET SELECTED FIELDSET' | translate" (click)="handleRootFieldset($event)">
        <p class="name"><span>{{ "NAME" | translate }}</span></p>
        <p class="label"><span>{{ "LABEL" | translate }}</span></p>
        <p class="type"><span>{{ "TYPE" | translate }}</span></p>
        <p class="description"><span>{{ "DESCRIPTION" | translate }}</span></p>
        <p class="constraints"><span>{{ "CONSTRAINTS" | translate }}</span></p>
        <p class="actions"><span>{{ "ACTIONS" | translate }}</span></p>
  </div>

  <div class="content">
    <div class="layout-column rootContainer">
        <root-container [root]="root"></root-container>
    </div>
</div>

</div>
