import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CrsService } from 'src/app/services/crs.service';
import { StoreService } from 'src/app/services/store.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { getToken } from 'src/app/share/utils';
import { FeatureService } from 'src/app/services/feature.service';
import {ToastService} from '../../../services/toast.service';
import {MatButton} from '@angular/material/button';
import {MatFormField, MatInput} from '@angular/material/input';
import {DataService} from '../../../services/data.service';
import {UnsavedChangesDialogComponent} from '../unsaved-changes-dialog/unsaved-changes-dialog.component';
import {CookieService} from 'ngx-cookie-service';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-edit-geom-or-coord-values',
  templateUrl: './edit-geom-or-coord-values.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButton,
    MatDialogActions,
    MatDialogTitle,
    MatDialogContent,
    MatInput,
    ReactiveFormsModule,
    MatFormField,
    MatDialogClose,
  ],
  styleUrls: ['./edit-geom-or-coord-values.component.scss', '../dialog-btns.component.scss']
})


export class EditGeomOrCoordValuesComponent implements OnInit  {
  coordinates: any[];
  geodetic: boolean;
  isCoordAttributes: boolean = false;
  geomType: string;
  geometryData: any[];
  coordAttributesData: any[];
  displayedColumns: string[] = [];
  feature: any;
  coordModified: boolean = false;
  loading: boolean = false;
  error_occured: boolean = false;
  crs: any;
  latitude: string;
  longitude: string;
  altitude: string;
  accuracy: string;
  saved_accuraty: any;
  column_name: string;
  is_fieldset_array: boolean;
  fsa_value_index: number;
  hasWritePermissions: boolean = false;

  @ViewChild(MatTable, {static: true}) coordinatestable: MatTable<any>;

  constructor(
    private conversionCoordinateService: CrsService,
    private featureService: FeatureService,
    private cookieService: CookieService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
    public dataService: DataService,
    public toastService: ToastService,
    private dialogRef: MatDialogRef<EditGeomOrCoordValuesComponent>) {
      this.geomType = null;
      this.isCoordAttributes = null;
      if (data !== undefined && data !== null) {
        this.feature = data.feature;
        this.hasWritePermissions = data.hasWritePermissions;
        if (data.isCoordAttributes) {
          // In case of Coordinate field
          this.column_name = data.column;
          this.isCoordAttributes = data.isCoordAttributes;
          this.coordAttributesData = data.coordAttributes;
          this.is_fieldset_array = data.is_fieldset_array;
          this.fsa_value_index = data.fsa_value_index;
        } else {
          if (this.feature?.geometry){
            this.geometryData = data.feature.geometry.coordinates;
            this.geomType = data.feature.geometry.type;
          } else {
            this.geometryData = data.coordinates;
            this.geomType = data.geometry_type;
          }
        }
      }
  }

  ngOnInit() {
    this.error_occured = false;
    this.crs = this.cookieService.get('CRSValue');
    if(this.crs === null || this.crs === undefined || this.crs === '' || this.crs === 'Geodetic') {
      this.geodetic = true;
      this.latitude = 'Latitude (°)';
      this.longitude = 'Longitude (°)';
      this.altitude = 'Elevation (m)';
      this.accuracy = 'Accuracy ';
    } else {
      this.geodetic = false;
      this.latitude = 'X';
      this.longitude = 'Y';
      this.altitude = 'Z';
      this.accuracy = 'Accuracy';
    }
    if (this.isCoordAttributes) {
      this.convertCoordinates(this.coordAttributesData);
    } else if(this.geometryData) {
      this.convertCoordinates(this.geometryData);
    }
  }

  createTableHeaderArrayWithinTemplate(): any[] {
    const template = this.dataService.template.getValue();
    let result = [];
    const form = template.form;
    const fields = form.fields;
    for (const field of fields) {
      const attlabel = field['label'];
      const attname = field['name'];
      if (field['_class'] === 'fieldset' || field._class === 'arrayfieldset') {
        result = result.concat(this.createTableHeaderWithinAttributeSet(field, attname, attlabel));
      } else {
        const obj = {
          name: field['name'],
          label: field['label']
        }
        result.push(obj);
      }
    }
    return result;
  }

  createTableHeaderWithinAttributeSet(fieldset, nameparent, labelparent): string[] {
    let obj = [];
    let attsetName = fieldset['label'];
    if (attsetName === null || attsetName === undefined || attsetName === '') {
      attsetName = '';
    }

    for (const field of fieldset.fields) {
      if (field._class === 'fieldset' || field._class === 'arrayfieldset') {
        const label = `${labelparent} ${field.label}`;
        const name = `${nameparent}.${field.name}`
        obj = obj.concat(this.createTableHeaderWithinAttributeSet(field, name, label));
      } else {
        const attlabel = `${labelparent} ${field.label}`;
        const attname = `${nameparent}.${field.name}`;
        const temp_obj = {
          name: attname,
          label: attlabel
        }
        obj.push(temp_obj);
      }
    }
    return obj;
  }

  updateNestedObjectByPath(obj: any, path: string, newValue: any[], index = null) {
    if(obj && path) {
      // Split the path into an array of keys
      const keys = path.split('.');

      // Traverse through the keys to reach the target object
      let currentObj = obj;
      for (let i = 0; i < keys.length - 1; i++) {
        currentObj = currentObj[keys[i]];
        if (!currentObj) {
          return;
        }
      }

      // Update the value of the target key
      const targetKey = keys[keys.length - 1];
      if(index !== null && index !== undefined && Number(index) >= 0){
        currentObj[index][targetKey] = newValue;
      } else if (currentObj.hasOwnProperty(targetKey)) {
        currentObj[targetKey] = newValue;
      }
    }
  }

  updateCoordinates() {
    if(!this.feature) {
      this.toastService.warningToast("Feature temporarily disabled")
      return;
    }

    this.error_occured = false;
    const updatedFeature = this.feature;
    const project = this.dataService.project;
    const token = getToken();

    const template = this.dataService.template.getValue();

    if (this.crs === null || this.crs === undefined || this.crs === '' || this.crs === 'Geodetic') {
      this.crs = 'Geodetic';
      const new_coordinates = this.constructGeodeticNewCoordinates(this.coordinates, this.geomType);
      if(this.isCoordAttributes) {
          const attr_list = this.createTableHeaderArrayWithinTemplate();
          let attribute_column = '';
          for(const obj of attr_list) {
            if (obj['label'] === this.column_name) {
              attribute_column = obj['name'];
              break;
            }
          }
          const attributes = this.feature.attributes;
          if(this.is_fieldset_array) {
            this.updateNestedObjectByPath(attributes, attribute_column,new_coordinates, this.fsa_value_index);
          } else {
            this.updateNestedObjectByPath(attributes, attribute_column,new_coordinates);
          }

          updatedFeature.attributes = attributes;
      } else {

          //updatedFeature['geometry']['coordinates'] = new_coordinates;
          updatedFeature['geometry']['coordinates'] = new_coordinates;
      }

      this.loading = true;
      this.featureService.updateFeature(updatedFeature, project, token)
      .subscribe(
        res => {
          const tempFeat = (feat) => feat.id === res.id;
          this.loading = false;
          this.dialogRef.close(res);
        },
        err => {
          this.toastService.errorToast(err);
          this.loading = false;
      });
    } else {
      this.loading = true;
      this.conversionCoordinateService.convertCRSToGeodetic(token, this.crs, this.coordinates).subscribe(
        res => {
          this.geodetic = false;
          this.coordinates = res;
          const new_coordinates = this.constructNonGeodeticNewCoordinates(this.coordinates, this.geomType);

          if(this.isCoordAttributes) {
            const attr_list = this.createTableHeaderArrayWithinTemplate();
            let attribute_column = '';
            for(const obj of attr_list) {
              if (obj['label'] === this.column_name) {
                attribute_column = obj['name'];
                break;
              }
            }
            const attributes = this.feature?.attributes;
            if(this.is_fieldset_array) {
              this.updateNestedObjectByPath(attributes, attribute_column,new_coordinates, this.fsa_value_index);
            } else {
              this.updateNestedObjectByPath(attributes, attribute_column,new_coordinates);
            }
            updatedFeature.attributes = attributes;
          } else {
            updatedFeature['geometry']['coordinates'] = new_coordinates;
          }
          this.loading = true;
          this.featureService.updateFeature(updatedFeature, project, token)
          .subscribe(
            res => {
              this.loading = false;
              this.dialogRef.close(res);
            },
            err => {
              this.toastService.errorToast(err);
              this.loading = false;
          });

          this.loading = false;
        },
        err => {
          this.loading = false;
        }
      );
    }
  }

  constructGeodeticNewCoordinates(coordinates, type='Point'){
    const coord = [];
    if (!this.geomType) {
      for(let i = 0; i < coordinates.length; i++){
        const item = coordinates[i];
        const accuracy = item['accuracy'] ? parseFloat(item['accuracy']) : 0;
        coord.push(parseFloat(item['latitude']));
        coord.push(parseFloat(item['longitude']));
        coord.push(parseFloat(item['altitude']));
        coord.push(accuracy);
      }
      return coord;
    }
    if (type !== 'Point') {
      if (type === 'LineString' || type === 'Linestring'){
          for(let i = 0; i < coordinates.length; i++){
            const item = coordinates[i];
            const temp_coord = [];
            const accuracy = isNaN(item['accuracy']) ?  0 : parseFloat(item['accuracy']);
            temp_coord.push(parseFloat(item['latitude']));
            temp_coord.push(parseFloat(item['longitude']));
            temp_coord.push(parseFloat(item['altitude']));
            temp_coord.push(accuracy);
            coord.push(temp_coord);
          }
      } if (type === 'Polygon'){
        const polygon_coord = []
        for(let i = 0; i < coordinates.length; i++){
          const item = coordinates[i];
          const temp_coord = [];
          const accuracy = isNaN(item['accuracy']) ?  0 : parseFloat(item['accuracy']);
          temp_coord.push(parseFloat(item['longitude']));
          temp_coord.push(parseFloat(item['latitude']));
          temp_coord.push(parseFloat(item['altitude']));
          temp_coord.push(accuracy);
          polygon_coord.push(temp_coord);
        }
        coord.push(polygon_coord);
      }
    } else  {
      for(let i = 0; i < coordinates.length; i++){
        const item = coordinates[i];
        const accuracy = item['accuracy'] ? parseFloat(item['accuracy']) : 0;
        coord.push(parseFloat(item['latitude']));
        coord.push(parseFloat(item['longitude']));
        coord.push(parseFloat(item['altitude']));
        coord.push(accuracy);
      }
    }
    return coord;
  }

  constructNonGeodeticNewCoordinates(coordinates, type='Point'){
    const coord = [];
    if (this.geomType) {
      if (type !== 'Point') {
        if (type === 'LineString' || type === 'Linestring'){
            for(let i = 0; i < coordinates.length; i++){
              const item = coordinates[i];
              const temp_coord = [];
              temp_coord.push(parseFloat(item['latitude']));
              temp_coord.push(parseFloat(item['longitude']));
              temp_coord.push(parseFloat(item['altitude']));
              coord.push(temp_coord);
            }
        } if (type === 'Polygon'){
          const polygon_coord = []
          for(let i = 0; i < coordinates.length; i++){
            const item = coordinates[i];
            const temp_coord = [];
            temp_coord.push(parseFloat(item['longitude']));
            temp_coord.push(parseFloat(item['latitude']));
            temp_coord.push(parseFloat(item['altitude']));
            polygon_coord.push(temp_coord);
          }
          coord.push(polygon_coord);
        }
      } else {
        for(let i = 0; i < coordinates.length; i++){
          const item = coordinates[i];
          coord.push(parseFloat(item['latitude']));
          coord.push(parseFloat(item['longitude']));
          coord.push(parseFloat(item['altitude']));
          coord.push(parseFloat(this.saved_accuraty));
        }
      }
    } else {
      for(let i = 0; i < coordinates.length; i++){
        const item = coordinates[i];
        coord.push(parseFloat(item['latitude']));
        coord.push(parseFloat(item['longitude']));
        coord.push(parseFloat(item['altitude']));
        coord.push(parseFloat(this.saved_accuraty));
      }
    }

    return coord;
  }

  convertCoordinates(geodCoordinates) {
    this.coordinates = [];
    let crs = this.cookieService.get('CRSValue');
    if (this.geomType) {
      if (this.geomType === 'Point') {
        this.saved_accuraty  = 0.0;
        if (geodCoordinates.length >= 4) {
          if(typeof(geodCoordinates[3]) === 'string') {
            this.saved_accuraty = Number(geodCoordinates[3]);
          } else {
            this.saved_accuraty = geodCoordinates[3].toFixed(3);
          }
        }

        if(!(geodCoordinates[0] === 0 && geodCoordinates[1] === 0 && geodCoordinates[2]  === 0)) {
            const el = {
              latitude : geodCoordinates[0].toFixed(8),
              longitude : geodCoordinates[1].toFixed(8),
              altitude : geodCoordinates[2].toFixed(8),
              accuracy: this.saved_accuraty !== 0.0 ? this.saved_accuraty : '?'
            };
            this.coordinates.push(el);
        }
      } else if (this.geomType === 'LineString' || this.geomType === 'Linestring') {
        geodCoordinates.map(geodCoordinate => {
          let accuracy = 0.0;
          if (geodCoordinate.length >= 4) {
            if(typeof(geodCoordinate[3]) ==='string') {
              accuracy = Number(geodCoordinate[3]);
            } else {
              accuracy = geodCoordinate[3].toFixed(3);
            }
          }
          if(!(geodCoordinate[0] === 0 && geodCoordinate[1] === 0  && geodCoordinate[2]  === 0)) {
              const item = {
                latitude : geodCoordinate[0].toFixed(8),
                longitude : geodCoordinate[1].toFixed(8),
                altitude : geodCoordinate[2].toFixed(8),
                accuracy: accuracy !== 0.0 ? accuracy : '?'
              };
              this.coordinates.push(item);
          }
        });
      } else if (this.geomType === 'Polygon') {
        geodCoordinates.map(element => {
          element.map(geodCoordinate => {
            let accuracy = 0.0;
            if (geodCoordinate.length >= 4) {
              //accuracy = geodCoordinate[3].toFixed(2);
              if(typeof(geodCoordinate[3]) ==='string') {
                accuracy = Number(geodCoordinate[3]);
              } else {
                accuracy = geodCoordinate[3].toFixed(3);
              }
            }

            if(!(geodCoordinate[0] === 0 && geodCoordinate[1] === 0  && geodCoordinate[2]  === 0)) {
                const item = {
                  latitude : geodCoordinate[0].toFixed(8),
                  longitude : geodCoordinate[1].toFixed(8),
                  altitude : geodCoordinate[2].toFixed(8),
                  accuracy: accuracy !== 0.0 ? accuracy : '?'
                };
                this.coordinates.push(item);
            }
          });
        });
      }
    } else {
      this.saved_accuraty = 0.0;
      if (geodCoordinates.length >= 4) {
        if(typeof(geodCoordinates[3]) ==='string') {
          this.saved_accuraty = Number(geodCoordinates[3]).toFixed(3);
        } else {
          this.saved_accuraty = geodCoordinates[3].toFixed(3);
        }

      }
      if(!(geodCoordinates[0] === 0 && geodCoordinates[1] === 0  && geodCoordinates[2]  === 0)) {
          const el = {
            latitude : geodCoordinates[0].toFixed(8),
            longitude : geodCoordinates[1].toFixed(8),
            altitude : geodCoordinates[2].toFixed(8),
            accuracy: this.saved_accuraty
          };
          this.coordinates.push(el);
      }
    }

    if (crs === null || crs === undefined || crs === '' || crs === 'Geodetic') {
      crs = 'Geodetic';
      this.geodetic = true;
    } else {
      const token = getToken();
      this.loading = true;
      this.conversionCoordinateService.convertCRS(token, crs, this.coordinates).subscribe(
        res => {
          // if(this.geomType) {
            const data = [];
            for(const v of res) {
              const coord = {
                X: v['X'].toFixed(3),
                Y: v['Y'].toFixed(3),
                Z: v['Z'].toFixed(3)
              };
              data.push(coord);
            }
          this.coordinates = data;
          this.loading = false;
        },
        err => {
          this.loading = false;
        }
      );
    }
  }

  updateElementValue(event, index, element, value) {
    if(!value)
      value = event.target.value;
    this.coordModified = true;
    this.coordinates[index][element] = parseFloat(value);
  }
}
