<h3 style="cursor: move !important;" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle><span>{{templateName}}</span></h3>
<hr style="border: 1px solid #9e9d99 !important; background-color: #9e9d99 !important;" />

<div mat-dialog-content>
    <app-message-box #messageBox *ngIf="showMessage" message="{{message}}"></app-message-box>
    <table style="border-collapse: collapse !important; margin-top: -2px !important; width: 100% !important;">
        <tr style="margin-bottom: auto !important; border: 1px solid #aaa9a7 !important; background-color: #c7c7c4 !important;">
            <th style="width: 33% !important;" >
                <h6 class="mt-2" style="justify-content: start !important; margin-left: 2px;"><span>{{ "FIELD" | translate }}</span></h6>
            </th>
            <th style="width: auto !important; border-left: 1px solid #aaa9a7 !important;">
                <h6 class="mt-2" style="justify-content: start !important; margin-left: 6px;"><span>{{ "VALUE" | translate }}</span></h6>
            </th>
        </tr>
        <tr *ngFor="let item of items; let i = index;" style="margin-bottom: auto !important; border: 1px solid #aaa9a7 !important;">
            <td  style="width: 33% !important;  padding-right: 10px !important;">
                <h6 class="mt-2" style="justify-content: start !important; margin-left: 2px;">
                    {{item.key}}
                </h6>
            </td>
            <td (dblclick)="editFormValue(item)"  style="width: auto !important; border-left: 1px solid #aaa9a7 !important; padding-right: 10px !important;">
                <ng-template *ngIf="item.file; then fileContainer; else textContainer"></ng-template>
                <ng-template #textContainer>
                    <span class="text-left" style="float: left; margin-left: 6px;"> {{ getValue(item.value)}}</span>
                </ng-template>

                <ng-template #fileContainer>
                    <span *ngIf="item.fileloading">File loading ...., please wait</span>
                    <ng-template *ngIf="audioFile; then audiocontainer; else mimecontainer"></ng-template>
                    <ng-template #audiocontainer>
                        <audio controls>
                                <source [id]="item.key" src="" type="{{filetype}}" />
                            Your browser does not support the audio element.
                        </audio>
                    </ng-template>
                    <ng-template #mimecontainer>
                        <ng-template *ngIf="imageFile; then imagecontainer; else videocontainer"> </ng-template>
                        <ng-template #imagecontainer>
                            <div class="thumbnails">
                                <img [id]="item.key" width="150" style="margin-left: 15px !important; width: 150px !important; height:fit-content !important;" (click)="showImage(item.key)" mat-card-image ngSrc="" alt="" />
                                <br>
                                <small class="ml-2"><span>{{ "CLICK ON THE IMAGE TO ENLARGE" | translate }}</span></small>
                            </div>

                            <div class="fullscreen" [hidden]="!selectedImage" (click)="showImage(item.key)">
                                <img id="img_full"  src="" alt=""/>
                            </div>
                        </ng-template>
                        <ng-template #videocontainer>
                            <ng-template *ngIf="videoFile; then vidcontainer;"> </ng-template>
                            <ng-template #vidcontainer>
<!--                              [autoplay]="true" [preload]="true" [fullscreen]="true" [download]="false"-->
                              <video width="400" title="" color="accent" spinner="spin" poster="image.jpg">
                                    <source [id]="item.key" matVideoSource src="" type="video/mp4" />
                                </video>
                            </ng-template>
                        </ng-template>
                    </ng-template>
                </ng-template>

            </td>
        </tr>
    </table>

</div>

<div mat-dialog-actions>
    <span class="flex-spacer"></span>
    <button mat-button class="border" (click)="close()" ><span>{{ "CLOSE" | translate }}</span></button>
</div>
