<footer>
  @if (store?.showprojects) {
    <div id="directionButtons">
      <div>
        @if (projectsOpen) {
          <span (click)="openProjectsPageContainer()" mat-button class="btn-pointer first-pointer">&#62;&#62;</span>
        }
        <span (click)="closeProjectsPageContainer()" mat-button class="btn-pointer first-pointer" [hidden]="projectsOpen">&#60;&#60;</span>
      </div>
      <div>
        @if (templatesOpen) {
          <span (click)="openTemplatesPageContainer()" mat-button class="btn-pointer second-pointer">&#62;&#62;</span>
        }
        <span (click)="closeTemplatesPageContainer()" mat-button class="btn-pointer second-pointer" [hidden]="templatesOpen">&#60;&#60;</span>
      </div>
      <!--
      <div fxFlex style="text-align: right; margin-right: 15px;">
        <span style="text-align: end;">
          © MyCumulus.com &nbsp;• &nbsp;
          <a href="https://www.mycumulus.com/en/privacy-statement/"><span>{{ "PRIVACY STATEMENT" | translate }}</span></a>
          &nbsp;• &nbsp;
          <a href="https://www.mycumulus.com/en/terms-of-service/"><span>{{ "TERMS OF SERVICE" | translate }}</span></a>
        </span>
      </div>
      -->
    </div>
  }
</footer> 
