import { TranslateModule } from '@ngx-translate/core';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule  } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { getToken, getAdminToken } from 'src/app/share/utils';
import { SysAdminUserServiceService } from 'src/app/services/sys-admin-user-service.service';
import { StoreService } from 'src/app/services/store.service';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../services/toast.service';
import {CommonModule} from '@angular/common';

@Component({
  standalone: true,
  imports: [TranslateModule, 
    CommonModule,
    MessageBoxComponent,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
  ],
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss']
})
export class ResetPasswordPageComponent implements OnInit, AfterViewInit {
  @Output() hideResetPassword = new EventEmitter();

  resetPassForm: FormGroup;
  @ViewChild('cur_pass') currPass: ElementRef;

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';
  showMessage = false;

  constructor(
    private fb: FormBuilder,
    public store: StoreService,
    private userService: UserService,
    private mcUserService: SysAdminUserServiceService,
    public toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.createForm();
  }

  ngAfterViewInit() {
    // whithout this, angular throws Expression has changed after it was checked.
    // From https://stackoverflow.com/questions/41873893/angular2-autofocus-input-element

    window.setTimeout(() => {
      this.currPass.nativeElement.focus();
    });
  }

  createForm() {
    this.resetPassForm = this.fb.group({
      password: ['', Validators.required],
      new_password: ['', Validators.required],
      confirmpassword: ['', Validators.required]
    });
  }

  resetPassword() {
    const data = this.resetPassForm.value;
    if (data.new_password !== data.confirmpassword) {
      this.toastService.errorToast('Passwords do not match');
      return;
    }
    let token = getToken();
    if (token !== null && token !== undefined) {
      this.store.showLoading();
      this.userService.changePassword(data.password, data.new_password, token).subscribe(
        res => {
          this.toastService.successToast('Password is changed');
          this.store.hideLoading();
        },
        err => {
          this.store.hideLoading();
          if (err === 'The old password does not match the user password') {
            this.toastService.errorToast('The old password is not correct');
          } else {
            this.toastService.errorToast(err);
          }
        }
      );
    } else {
      token = getAdminToken();
      if (token !== null && token !== undefined) {
        // reset MyCumulus admin password
        this.store.showLoading();
        this.mcUserService.changePassword(data.password, data.newpassword, token).subscribe(
          res => {
            this.store.hideLoading();
            this.toastService.successToast('Password is changed');
          },
          err => {
            this.store.hideLoading();
            this.toastService.errorToast(err);
          }
        );
      }
    }
  }

  cancelResetPassword(event) {
    event.preventDefault();
    this.hideResetPassword.emit();
    event.stopPropagation();
  }
}
