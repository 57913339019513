import { Injectable } from '@angular/core';
import { Collaborator } from '../share/user';
import { Observable } from 'rxjs';
import { map, catchError  } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { getPrivateApiAURL() } from '../share/getPrivateApiAURL()';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { ProjectMemberResult, ProjectMembersResult, ProjectsOfMemberResult } from '../share/result';
import { Project } from '../share/projects';
import { ProjectMember } from '../share/project-member';
import { getPrivateApiAURL } from '../share/utils';
// import { TemplatePermissions } from '../components/dialogs/templates-permissions-dialog/templates-permissions-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ProjectMemberService {
  constructor(
    private http: HttpClient,
    private processHttpMsgService: ProcessHttpmsgService) {
  }

  addUserToProject(token, projectId, userId): Observable<ProjectMember> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': projectId
      })
    };

    const data = {
      user_id: userId
    };

    return this.http.post<ProjectMemberResult>(getPrivateApiAURL() + 'projects/' + projectId + '/members', data,  httpOptions)
    .pipe(map(
      res => {
        return res.project_member;
      }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getProjectMemberInfo(token, projectId, userId): Observable<ProjectMember> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
      })
    };

    return this.http.get<ProjectMemberResult>(getPrivateApiAURL() + 'projects/' + projectId + '/members/' + userId , httpOptions)
     .pipe(map(
      res => {
        return res.project_member;
      }))
    .pipe(catchError(this.processHttpMsgService.handleError));

  }

  getListOfMemberOfProject(token, projectId): Observable<Collaborator[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
      })
    };

    return this.http.get<ProjectMembersResult>(getPrivateApiAURL() + 'projects/' + projectId + '/members', httpOptions)
     .pipe(map(
      res => {
        return res.users.instances;
      }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getListOfProjectOfUser(token, userId): Observable<Project[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
      })
    };

    return this.http.get<ProjectsOfMemberResult>(getPrivateApiAURL() + 'users/' + userId + '/projects', httpOptions)
     .pipe(map(
      res => {
        return res.projects.instances;
      }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  grantTemplatesPermissionToCollaborator(token, userId: string, projectId: string, data): Observable<ProjectMember> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': projectId
      })
    };

    return this.http.put<ProjectMemberResult>(getPrivateApiAURL() + 'projects/' + projectId + '/grant', data, httpOptions)
     .pipe(map(
      res => {
        return res.project_member;
      }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  revokeTemplatesPermissionToCollaborator(token, userId: string, projectId: string, data): Observable<ProjectMember> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': projectId
      })
    };

    return this.http.put<ProjectMemberResult>(getPrivateApiAURL() + 'projects/' + projectId + '/revoke', data, httpOptions)
     .pipe(map(
      res => {
        return res.project_member;
      }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  removeProjectMembers(member, project, token): Observable<ProjectMember>{
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': project.id
      })
    };

    return this.http.delete<ProjectMember>(getPrivateApiAURL() + 'projects/' + project.id + '/members/' + member.id, httpOptions)
    .pipe(catchError(this.processHttpMsgService.handleError));
  }
}

