<div class="admin-page">
  <div id="admin-container-div" class="admin-container">
    <div class="content-row">
      <div id="adminMenuSide" class="admin-menu">
        <div class="sidebar-templates"><span>{{ "TEMPLATES STYLE" | translate }}</span></div>
        <ul id="navAdmin">
        </ul>
      </div>
      <div class="vertical-divider"></div>
      <div id="adminContent" class="admin-content">
        <div id="userspage" class="users-page">
          <div class="search-section">
            <div class="search-row">
              <span class="search-input">
                @if(showMessage){
                  <app-message-box #messageBox [message]="message"></app-message-box>
                }
                <div class="form-group has-clear">
                  <mat-form-field class="full-width dense-1">
                    <mat-label>{{"SEARCH..." | translate}}</mat-label>
                    <input matInput autocomplete="off" class="full-width" (keyup)="applyFilter($event)" [placeholder]="'SEARCH...' | translate" type="text" [value]="searchValue">
                  </mat-form-field>
                </div>
              </span>
              <span class="add-button">
                <button mat-button (click)="openCreateTemplateStyleDialog()" mat-flat-button color="primary" class="btn-border"><span>{{ "ADD" | translate }}</span></button>
              </span>
            </div>
          </div>
          <div class="table-section">
            <div class="table-row">
              <table mat-table [dataSource]="dataSource" class="table table-striped table-bordered">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef class="header-cell"><span>{{ "NAME" | translate }}</span></th>
                  <td mat-cell *matCellDef="let element" class="table-cells">{{element.name}}</td>
                </ng-container>

                <!-- Description Column -->
                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef class="header-cell"><span>{{ "DESCRIPTION" | translate }}</span></th>
                  <td mat-cell *matCellDef="let element" class="table-cells">{{element.description}}</td>
                </ng-container>

                <!-- Created date Column -->
                <ng-container matColumnDef="created_at">
                  <th mat-header-cell *matHeaderCellDef class="header-cell"><span>{{ "DATE" | translate }}</span></th>
                  <td mat-cell *matCellDef="let element" class="table-cells">{{element.created_at | date: 'yyyy-MM-dd'}}</td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef class="header-cell"><span>{{ "ACTIONS" | translate }}</span></th>
                  <td mat-cell *matCellDef="let element" class="action-link table-cells">
                    @if(canEditOrDelete(element)) {
                      <button class="btn-border buttons" mat-button (click)="openDeleteTemplateStyleConfirmation(element)">
                        <mat-icon class="icons">delete</mat-icon>
                      </button>
                      <button class="btn-border buttons" mat-button (click)="openUpdateTemplateStyleDialog(element)">
                        <mat-icon class="icons">edit</mat-icon>
                      </button>
                      <button class="btn-border buttons" mat-button (click)="downloadTemplateStyleDialog(element)">
                        <mat-icon class="icons">download</mat-icon>
                      </button>
                    }
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
