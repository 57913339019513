import {TranslateModule} from '@ngx-translate/core';
import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MessageBoxComponent} from '../../message-box/message-box.component';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {Template} from 'src/app/share/template';
import {DataService} from 'src/app/services/data.service';
import {StoreService} from 'src/app/services/store.service';
import {
  getChoosenCRSType,
  getImportExcelFileSetting,
  getToken,
  persitImportExcelFileSetting,
  removeImportExcelFileSetting
} from 'src/app/share/utils';
import {MatDialog, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {MatRadioButton, MatRadioChange, MatRadioGroup} from '@angular/material/radio';
import {MatStepper, MatStepperModule} from '@angular/material/stepper';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {Project} from 'src/app/share/projects';
import {User} from 'src/app/share/user';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs';

import * as XLSX from 'xlsx';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import {CommonModule} from '@angular/common';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {ReportsService} from '../../../services/reports.service';
import {CookieService} from 'ngx-cookie-service';
import {MatCheckbox} from '@angular/material/checkbox';


const ELEMENT_DATA: any[] = [];
@Component({
  standalone: true,
  imports: [
    TranslateModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioButton,
    FormsModule,
    MatButtonModule,
    MatRadioGroup,
    MatDialogClose,
    CommonModule,
    FaIconComponent,
    MatCheckbox
  ],
  selector: 'app-import-excel-file',
  templateUrl: './import-excel-file.component.html',
  styleUrls: ['./import-excel-file.component.scss', '../dialog-btns.component.scss']
})
export class ImportExcelFileComponent implements OnInit {
  project: Project;
  template: Template;
  @Input() user: User;
  @Input() mode = {
    text: 'Create',
    function: 'Create'
  };
  loading = false;
  loadingText = '';
  @Output() invalidIconDropped = new EventEmitter<boolean>();
  @ViewChild('imgIcon') img;
  @ViewChild('tabRef') private tabRef: MatStepper;


  selectedIndex: BehaviorSubject<number> = new BehaviorSubject<number>(null);


  fileInBase64: string;
  fileToUpload: File;
  importInNewTemplate = false;
  importInExistingTemplate = false;
  isValidFile = false;
  importFileForm: FormGroup;



  delimiter = ',';
  decimalSeparator = '.';
  utmZones = [];
  importDataError: any;


  crsList = [
    {value: 'Geodetic', text: 'Geodetic'},
    {value: 'Lambert72', text: 'Lambert 72'},
    {value: 'Lambert2008', text: 'Lambert 2008'},
    {value: 'RDNAP', text: 'RD'},
    {value: 'UTM', text: 'UTM'},
    {value: 'BGS2005', text: 'BGS2005'}
  ];

  fieldTypes = [
    {value: 'stringfield',  text: 'Text'},
    {value: 'integerfield', text: 'Integer'},
    {value: 'decimalfield', text: 'Decimal'},
    {value: 'booleanfield', text: 'Boolean'},
    {value: 'notefield',    text: 'Note'},
    {value: 'datefield', text: 'Date'},
    {value: 'timefield', text: 'Time'}
  ];

  coordsList = [
    'Latitude',
    'Longitude',
    'X', 'Y', 'Z',
    'N', 'E',
    'x','y','z',
    'n', 'e',
    'latitude',
    'longitude'
  ]


  fields = [];
  showFieldContainer = false;

  errMsg: string;
  isFileProcessing: boolean = false;

  templates: any[];
  noFileIsSelected = false;
  isCrsUTM: any = false;

  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatTable) dataToImportTable: MatTable<any>;

  displayedColumns: string[];
  tempTemplateName: string;
  headers_index: any[] = [];

  private messageBox: MessageBoxComponent;
  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  message = '';

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fform') importFileFormDirective;

  constructor(
    private fb: FormBuilder,
    public store: StoreService,
    private dataService: DataService,
    private cookieService: CookieService,
    private dialogRef: MatDialogRef<ImportExcelFileComponent>,
    private reportService: ReportsService,
    public toastService: ToastService,
  ) { }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }


  ngOnInit() {
    if (this.store.importDataInNewForm) {
      this.importInNewTemplate = this.store.importDataInNewForm;
    } else {
      this.importInExistingTemplate = !this.store.importDataInNewForm;
    }
    this.createForm();
    this.getListTemplates();
    this.selectedIndex.subscribe(res => {
    });
  }

  title = 'Excel File Preview';
  data: any[] = [];
  header: string[] = [];

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      let reader = new FileReader();
      this.reportService.convertFileToBase64(file).then(base64String => {
        this.fileInBase64 = base64String;
      }).catch(err => {
        this.toastService.errorToast(err);
      })
      reader.readAsArrayBuffer(file);
      reader.onload = (e: any) => {
        const workbook = XLSX.read(e.target.result, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        this.data = XLSX.utils.sheet_to_json(worksheet, {header: 1});

        this.data = this.data.filter((row: any[]) => row.some(cell => cell !== null && cell !== undefined && cell !== ''));
        this.header = this.data[0];
        this.headers_index = Object.keys(this.data[0]);
        this.data = this.data.slice(1);
        let checkConfig = false;
        const csvConfig = getImportExcelFileSetting();
        let saveFields = [];

        if (csvConfig) {
          const filename = csvConfig['filename'];
          if (filename && filename === this.fileToUpload.name) {
            checkConfig = true;
            saveFields = csvConfig['fields'];
          } else {
            removeImportExcelFileSetting();
          }
        }
        if (this.header && this.header.length > 0) {
          for (const i of this.header) {
            if (i) {
              if (this.onlyDigits(i)) {
                this.displayedColumns = [];
                this.dataSource.data = [];
                this.fields = [];
                this.showFieldContainer = false;
                this.isValidFile = false;
                break;
              } else {
                const val2 = i.toString().trim().toLowerCase();
                if (val2 === 'x' || val2 === 'y' || val2 === 'z' ||
                   val2 === 'lat' || val2 === 'lon' || val2 === 'latitude' ||
                   val2 === 'longitude' || val2 === 'n' || val2 === 'e') {
                  this.fields.push( {
                    name: val2,
                    label: val2,
                    description: val2,
                    _class: 'decimalfield',
                    isCoordinate: true,
                    typeCoordinate: val2
                  });
                } else {
                  let _class = 'stringfield';
                  if(checkConfig && saveFields.length > 0) {
                    for(const f of saveFields) {
                        if (f['name'] === i) {
                          _class = f['_class'];
                          break;
                        }
                    }
                  }
                  this.fields.push({
                    name: i,
                    label: i,
                    description: i,
                    _class: _class,
                    isCoordinate: false,
                    typeCoordinate: ''
                  });
                }
              }
            }
          }
        }
      };
    }
    if (event.target.files && event.target.files.length > 0) {
      const tempFile = event.target.files[0];
      this.isValidFile = true;
      const filename = tempFile.name;
      const filenameParts = filename ? filename.split('.') : [];
      if (filenameParts && filenameParts.length > 1) {
        this.importFileForm.patchValue({
          template: filenameParts[0]
        });
      }

      //
      this.fileToUpload = event.target.files[0];
      event.stopPropagation();
    }
  }

  typeTemplateChange(event: MatRadioChange, data) {
    if (data === 'new_template') {
      this.importInNewTemplate = true;
      this.importInExistingTemplate = false;
      if(this.tempTemplateName && this.tempTemplateName.length > 0) {
        this.importFileForm.patchValue({
          template: this.tempTemplateName
        });
      }
    } else {
      if(this.importFileForm.value && this.importFileForm.value['template']){
        this.tempTemplateName = this.importFileForm.value['template'];
      }
      const t = this.dataService.template.getValue();
      if(t !== null) {
        this.importFileForm.patchValue({
          template: t.name
        });
      } else {
        this.importFileForm.patchValue({
          template: null
        });
      }
      this.importInNewTemplate = false;
      this.importInExistingTemplate = true;
    }
  }

  createForm() {
    let templateName = this.importInExistingTemplate ? this.dataService.template?.getValue().name : '';
    const excelConfig = getImportExcelFileSetting();
    let crs = excelConfig?.['crs'] || this.cookieService.get('CRSValue') || 'Geodetic';

    this.importFileForm = this.fb.group({
      csvFile: ['', Validators.required],
      crs: [crs, Validators.required],
      template: [templateName],
      typetemplate: '',
    });
    this.showFieldContainer = true;
  }

  goToNextStep() {
    this.tabRef.next();
  }

  constructCoordinateColumnsNbr(): any[] {
    if (this.fields && this.fields.length > 0) {
      const tempCoordColumns = [];
      let index = 0;
      let xIndex = null;
      let yIndex = null;
      let zIndex = null;
      for (const field of this.fields) {
        ++index;
        if (field.isCoordinate) {
          if (field.typeCoordinate.toLowerCase() === 'lat' || field.typeCoordinate.toLowerCase() === 'latitude'
          || field.typeCoordinate.toLowerCase() === 'x' || field.typeCoordinate.toLowerCase() === 'n' ) {
            xIndex = index;
          } else if (field.typeCoordinate.toLowerCase() === 'lon' || field.typeCoordinate.toLowerCase() === 'longitude'
          || field.typeCoordinate.toLowerCase() === 'y' || field.typeCoordinate.toLowerCase() === 'e') {
            yIndex = index;
          } else if (field.typeCoordinate.toLowerCase() === 'z') {
            zIndex = index;
          }
        }
      }
      if (xIndex) {
        tempCoordColumns.push(xIndex);
      }
      if (yIndex) {
        tempCoordColumns.push(yIndex);
      }
      if (zIndex) {
        tempCoordColumns.push(zIndex);
      }
      return tempCoordColumns;
    }
    return null;
  }

  constructDataFields(): any[] {
    if (this.fields && this.fields.length > 0) {
      const locTempFields = [];
      for (const field of this.fields) {
        if (!field.isCoordinate) {
          const tempField = {
            _class: field._class,
            name: field.name,
            label: field.label,
            description: field.description,
            enabled: true,
            visible: true,
            required: false,
            use_previous_value: false,
            constraints: [],
            event_actions: null,
            default_value_expression: null
          };
          locTempFields.push(tempField);
        }
      }
      return locTempFields;
    }
    return [];
  }

  checkCoordinateType(event, field): void {
    for (const f of this.fields) {
      if (f.isCoordinate) {
        if (f.name !== field.name) {
          if (f.typeCoordinate === event) {
            this.toastService.warningToast('The selected coordinate type is already chosen');
            field.typeCoordinate = null;
            event = null;
            return;
          }
        }
      }
    }
  }

  onSubmit() {
    this.isFileProcessing = true;
    if (!this.fileToUpload && !this.fileInBase64) {
      this.noFileIsSelected = true;
      this.toastService.warningToast('No file is selected');
      return;
    }
    const importData = this.importFileForm.value;

    if(importData.template.trim().length === 0) {
      this.toastService.errorToast("Name is empty");
      return;
    }

    for(const temp of this.dataService.templates.getValue()) {
      if(temp['name'] === importData.template && this.importInNewTemplate) {
        this.toastService.warningToast('A form with the same name already exists within the selected project.');
        this.isFileProcessing = false;
        return;
      }
    }

    const fileNamesParts = importData.csvFile.split('.');
    let fileType = '';
    if (fileNamesParts && fileNamesParts.length > 0) {
      fileType = fileNamesParts[fileNamesParts.length - 1];
    }
    let p = this.dataService.project;
    const value = {
      template: {
        name: importData.template,
        description: importData.template,
        project_id: p.id,
        image_file_url: null
      },
      data: {
        base64_encoded_content: this.fileInBase64,
        data_separator : this.delimiter,
        encoding: 'UTF8',
        type: 'excel'
      }
    };
    const coordColumns = this.constructCoordinateColumnsNbr();
    value['data']['decimal_separator'] = this.decimalSeparator;

    if (coordColumns && coordColumns.length > 0 && importData.crs) {
      value['data']['geometry'] =  'Point';
      value['data']['crs'] =  importData.crs;
      value['data']['coordinate_columns'] =  coordColumns;
    } else {
      value['data']['geometry'] =  null;
    }

    const constructedfields = this.constructDataFields();
    if (this.importInNewTemplate) {
      value['data']['fields'] =  constructedfields;
    }
    const csvConfig = {};
    csvConfig['filename'] = importData.csvFile;
    const fs = [];
    if (constructedfields && constructedfields.length > 0) {
      for(const constfield of constructedfields) {
        const f = {
          name: constfield['name'],
          _class: constfield['_class']
        }
        fs.push(f);
      }
      csvConfig['fields'] = fs;
    }
    persitImportExcelFileSetting(csvConfig);
    this.store.showLoading();
    this.loadingText = 'Sending importation data';
    const token = getToken();
    this.dataService.importDataInTemplate(value, token, p.id).subscribe(
      res => {
        this.toastService.successToast(`Importation succeed for form: ${res.name}` );

        this.dataService.createTemplateLocally(res);
        this.dataService.readTemplate(res);
        this.store.hideLoading();
        this.isFileProcessing = false;
        this.dialogRef.close();
      },
      error => {
        this.loading = false;
        this.isFileProcessing = false;
        this.store.hideLoading();

        if (error instanceof Array) {
          this.importDataError = error;
        } else {
          this.importDataError = [error]
        }
        let msg = error ? `Error: ${error}` : `Importation failed, please retry later`;
        if (error instanceof Array) {
          const record = error.length > 1 ? 'records' : 'record';
          msg = `${error.length} ${record} could not be imported. No data of Excel has been imported` ;
        }
        this.toastService.errorToast(msg);
      }
    );
  }

  onlyDigits(str: string) {
    const pattern = /^\d+$/;
    return pattern.test(str);  // returns a boolean
  }




  changedFieldType(fieldType, f): void {
    if (fieldType && fieldType === 'decimalfield') {
      const name = f.name;
      const val = this.dataSource.data[0][name];
      if (val && val.indexOf(',') >= 0) {
        this.decimalSeparator = ',';
      } else {
        this.decimalSeparator = '.';
      }
    } else if (fieldType && fieldType === 'notefield') {
      f['html'] = '';
    }
  }

  getListTemplates(): void {
    const token = getToken();
    const prjt = this.dataService.project;
    this.loadingText = 'Loading existed templates ....';
    this.loading = true;
    this.dataService.requestTemplates(token, prjt.id).subscribe(
      res => {
        this.templates = res;
        this.loading = false;
      },
      err => {
        this.toastService.errorToast(err);
        this.loading = false;
      }
    );
  }

  protected readonly faSpinner = faSpinner;
}
