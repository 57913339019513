import { Injectable } from '@angular/core';
import { ReportTemplate } from '../share/report-template';
import { Observable } from 'rxjs';
import { map, catchError  } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { getPrivateApiAURL() } from '../share/getPrivateApiAURL()';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { ReportTemplatesResult, ReportTemplateResult } from '../share/result';
import { getPrivateApiAURL } from '../share/utils';

@Injectable({
  providedIn: 'root'
})
export class ReportTemplateService {
  constructor(
    private http: HttpClient,
    private processHttpMsgService: ProcessHttpmsgService) {
  }

  deleteReportTemplate(reportTemplate, token): Observable<ReportTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key,
      })
    };
    return this.http.delete<ReportTemplateResult>(getPrivateApiAURL() + 'reporttemplates/' + reportTemplate.id, httpOptions).pipe(
      map(res => {
        return res.report_template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  updateReportTemplate(reportTemplate, token): Observable<ReportTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    return this.http.put<ReportTemplateResult>(getPrivateApiAURL() + 'reporttemplates/' + reportTemplate.id, reportTemplate, httpOptions)
    .pipe(
      map(res => {
        return res.report_template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  updateReportTemplateUsingAPI2(reportTemplate: File, token, visible_to, format) : Observable<ReportTemplate>{
    const mimeType = reportTemplate.type;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': mimeType,
        'X-Auth-Token': token.key
      })
    };
    return this.http.put<ReportTemplateResult>(`${getPrivateApiAURL()}reports2/create_report_template/${format}/${visible_to}`, 
    reportTemplate, httpOptions).pipe(
      map(res => {
        return res.report_template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  
  createUserCustomReportTemplateWithFile(data: any, reportTemplate: File, token, visible_to: string): Observable<ReportTemplate> {
    const formData: FormData = new FormData();
    formData.append('visibleTo', visible_to);
    const jsonBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });
    formData.append('data', jsonBlob);
    formData.append('file', reportTemplate, reportTemplate.name);
  
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key
      })
    };
  
    return this.http.post<ReportTemplateResult>(`${getPrivateApiAURL()}reporttemplates/privatecustomrt`, formData, httpOptions).pipe(
        map(res => {
          return res.report_template;
        }),
        catchError(this.processHttpMsgService.handleError)
      );
  }

  updateUserCustomReportTemplate(reportTemplateId: string,  data: any, reportTemplateFile: File, token, visibleTo: string): Observable<ReportTemplate> {
    const formData: FormData = new FormData();
  
    // Append the 'visibleTo' parameter
    formData.append('visibleTo', visibleTo);
  
    // Append the 'data' parameter as a JSON Blob
    const jsonBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });
    formData.append('data', jsonBlob);
  
    // Append the file if provided
    if (reportTemplateFile) {
      formData.append('file', reportTemplateFile, reportTemplateFile.name);
    }
  
    // Set up the headers
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key
      })
    };
  
    // Make the PUT request to the update endpoint
    return this.http.put<ReportTemplateResult>(
      `${getPrivateApiAURL()}reporttemplates/customrt/${reportTemplateId}`,
      formData,
      httpOptions
    ).pipe(
      map(res => res.report_template),
      catchError(this.processHttpMsgService.handleError)
    );
  }
  

  createReportTemplate(reportTemplate, token): Observable<ReportTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    return this.http.post<ReportTemplateResult>(getPrivateApiAURL() + 'reporttemplates', reportTemplate, httpOptions).pipe(
      map(res => {
        return res.report_template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getReportTemplateById(templateId, token): Observable<ReportTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key
      })
    };
    return this.http.get<ReportTemplateResult>(getPrivateApiAURL() + 'reporttemplates/' + templateId, httpOptions).pipe(
      map(res => {
        return res.report_template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getReportTemplates(token): Observable<ReportTemplate[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    return this.http.get<ReportTemplatesResult>(getPrivateApiAURL() + 'reporttemplates', httpOptions)
    .pipe(map(result => {
      return result.report_templates.instances;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getCompatibleReportTemplates(form_id:string, isPublic:boolean, target_format:string, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };

    const body = {
      template_id: form_id,
      public: isPublic,
      target_format: target_format,
    }
    return this.http.post<ReportTemplatesResult>(getPrivateApiAURL() + 'reports2/template_compatible_rts', body, httpOptions).pipe(map(result => {
      return result.report_templates.instances;
    }))
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  /*
  * MyCumulus Admin Report templates
  */
  getMCAdminReportTemplates(adminToken): Observable<ReportTemplate[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Admin-Auth-Token': adminToken.key
      })
    };
    // /v1/public/reporttemplates
    return this.http.get<ReportTemplatesResult>(getPrivateApiAURL() + 'public/reporttemplates', httpOptions)
    .pipe(map(result => {
      return result.report_templates.instances;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  createMCAdminReportTemplate(reportTemplate, token): Observable<ReportTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Admin-Auth-Token': token.key
      })
    };
    return this.http.post<ReportTemplateResult>(getPrivateApiAURL() + 'reporttemplates?visibleTo=all_mc_users',
    reportTemplate, httpOptions).pipe(map(res => {
        return res.report_template;
      })
    ).pipe(catchError(this.processHttpMsgService.handleError));
  }

  updateMCAdminReportTemplate(id, reportTemplate, token): Observable<ReportTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Admin-Auth-Token': token.key
      })
    };
    return this.http.put<ReportTemplateResult>(getPrivateApiAURL() + 'reporttemplates/' + id, reportTemplate, httpOptions).pipe(
      map(res => {
        return res.report_template;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  deleteMCAdminReportTemplates(reptempId, adminToken): Observable<ReportTemplate> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Admin-Auth-Token': adminToken.key
      })
    };
    return this.http.delete<ReportTemplateResult>(getPrivateApiAURL() + 'reporttemplates/' + reptempId, httpOptions)
    .pipe(map(result => {
      return result.report_template;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getReportPublicTemplates(token): Observable<ReportTemplate[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    return this.http.get<ReportTemplatesResult>(getPrivateApiAURL() + 'public/reporttemplates', httpOptions)
    .pipe(map(result => {
      return result.report_templates.instances;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }
}
