import { Geometry } from './geometry';
import { AbsAttribute, Attribute, AttributeSet, AttributeType } from './attributes';
import { Document } from '../document';



export class Feature extends Document {
  template_id: string;
  geometry: Geometry;
  attributes: AbsAttribute[];

  constructor(feature: Feature) {
    super();
    this.id = feature.id;
    this.template_id = feature.template_id;
    this.geometry = feature.geometry;
    this.attributes = feature.attributes;
    this.created_at = feature.created_at;
    this.updated_at = feature.updated_at;
    this.deleted_at = feature.deleted_at;
    this.created_by = feature.created_by;
    this.updated_by = feature.updated_by;
    this.deleted_by = feature.deleted_by;
  }

  getAttributes(Simple: boolean, Types: AttributeType[]): {[key: string]: any} {
    const nameValue: {[key: string]: any} = {};
    this.forEachAttribute(this.attributes, Simple, Types, "", (name, value) => {
      nameValue[name] = value;
    });
    return nameValue;
  }
  

  forEachAttribute(attributes: AbsAttribute[], OnlyRoot: boolean,  types: AttributeType[],
    NameComplex: string,  process: (name: string, value: any) => void  ): void {
    if (OnlyRoot && attributes !== this.attributes) {
      return;
    }
    const propertyNames = Object.getOwnPropertyNames(attributes);

    for (let i = 0; i < propertyNames.length; i++) {
      const name = propertyNames[i];
      const attribute = attributes[name];
      if (attribute instanceof Object) {
        if (attribute instanceof Array) {
          if (OnlyRoot) {
            return;
          }
          // const setArray = attribute as AttributeSetArray;
          // for (const set of setArray.attributes) {
          //   this.forEachAttribute(set.attributes,  OnlyRoot, types, setArray.name,  process);
          // }
        } else {
          if (attributes === this.attributes) {
            this.forEachAttribute((attribute as AttributeSet).attributes, OnlyRoot, types, "", process );
          } else {
            this.forEachAttribute((attribute as AttributeSet).attributes, OnlyRoot, types, attribute.name, process);
          }
        }
      } else {
        const attrib = attribute as Attribute;
  
        let name = NameComplex;
        if (name === "") {
          name = attrib.name;
        } else {
          name = NameComplex + "." + attrib.name;
        }
        process(name, attrib.value);
      }
    }
  }
}
