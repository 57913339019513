<div class="container">
    <div class="titleContainer">
      <h3>{{project.name + ": "}}{{ "PERMISSIONS MANAGEMENT" | translate }}</h3>
    </div>
    <div class="bodyContainer">
      <div class="project-members-side">
        <h4>{{ "PROJECT MEMBERS" | translate }}</h4>
        <div class="searchAndButton">
          <input class="searchBar-right" #memberSearchBar [placeholder]="'SEARCH...' | translate" (keyup)="membersSearchbarChange($event)">
          <button class="permissionBtn btnStyle" (click)="openFormsPermissionsDialog(selectedMembers)">{{"PERMISSIONS" | translate}}</button>
        </div>

        @if(!isMembersSearch) {
          @if(!isMembersSelected) {
            <button class="selectBtn btnStyle" (click)="selectAllMembers()">{{ "Select All" | translate }}</button>
          } @else {
            <button class="selectBtn btnStyle" (click)="selectAllMembers()">{{ "Deselect All" | translate }}</button>
          }
        } @else {
          @if(!isFilteredMembersSelected) {
            <button class="selectBtn btnStyle" (click)="selectAllMembers()">{{ "Select All Filtered" | translate }}</button>
          } @else {
            <button class="selectBtn btnStyle" (click)="selectAllMembers()">{{ "Deselect All Filtered" | translate }}</button>
          }
        }


        @if(projectMembers.length > 0) {
          @if(!isMembersSearch) {
            <mat-list class="user-list">
              @for(user of projectMembers; track user) {
                <mat-list-item>
                  <div class="item-content">
                    <div>
                    <mat-checkbox [checked]="selectedMembers.includes(user)" (change)="checkboxChanged($event.checked, selectedMembers, user)"></mat-checkbox>
                    <span>{{user.name}}</span>
                    </div>
                    <div>
                      <span [class]="user?.permissionSum?.create === 0 ? 'invisible' : user?.permissionSum?.create === project.template_ids.length ? 'bold' : 'faint'">C</span>
                      <span [class]="user?.permissionSum?.read === 0 ? 'invisible' : user?.permissionSum?.read === project.template_ids.length ? 'bold' : 'faint'">R</span>
                      <span [class]="user?.permissionSum?.update === 0 ? 'invisible' : user?.permissionSum?.update === project.template_ids.length ? 'bold' : 'faint'">U</span>
                      <span [class]="user?.permissionSum?.delete === 0 ? 'invisible' : user?.permissionSum?.delete === project.template_ids.length ? 'bold' : 'faint'">D</span>
                    </div>
                  </div>
                </mat-list-item>
              }
            </mat-list>
          } @else {
            <mat-list class="user-list">
              @for(user of membersSearch; track user) {
                <mat-list-item>
                  <div class="item-content">
                    <div>
                    <mat-checkbox [checked]="selectedMembers.includes(user)" (change)="checkboxChanged($event.checked, selectedMembers, user)"></mat-checkbox>
                    <span>{{user.name}}</span>
                    </div>

                    <div>
                      <span [class]="user?.permissionSum?.create === 0 ? 'invisible' : user?.permissionSum?.create === project.template_ids.length ? 'bold' : 'faint'">C</span>
                      <span [class]="user?.permissionSum?.read === 0 ? 'invisible' : user?.permissionSum?.read === project.template_ids.length ? 'bold' : 'faint'">R</span>
                      <span [class]="user?.permissionSum?.update === 0 ? 'invisible' : user?.permissionSum?.update === project.template_ids.length ? 'bold' : 'faint'">U</span>
                      <span [class]="user?.permissionSum?.delete === 0 ? 'invisible' : user?.permissionSum?.delete === project.template_ids.length ? 'bold' : 'faint'">D</span>
                    </div>
                  </div>
                </mat-list-item>            }
            </mat-list>
          }
        } @else {
          <div class="emptyListPlaceholder">
            <span>{{ "LIST EMPTY" | translate }}</span>
          </div>
        }
        <div class="arrowRight">
          <button class="arrowBtn" (click)="moveToNonMembers()"><fa-icon [icon]="faArrowRight" size="2x"></fa-icon></button>
        </div>
      </div>

      <div class="non-project-members-side">
        <h4>{{ "NON PROJECT MEMBERS" | translate }}</h4>
        <div class="searchLeft">
          <input #nonMemberSearchBar class="searchBar" [placeholder]="'SEARCH...' | translate" (keyup)="nonMembersSearchbarChange($event)">
        </div>

        @if(!isNonMembersSearch) {
          @if(!isNonMembersSelected) {
            <button class="selectBtn btnStyle" (click)="selectAllNonMembers()">{{ "Select All" | translate }}</button>
          } @else {
            <button class="selectBtn btnStyle" (click)="selectAllNonMembers()">{{ "Deselect All" | translate }}</button>
          }
        } @else {
          @if(!isFilteredNonMembersSelected) {
            <button class="selectBtn btnStyle" (click)="selectAllNonMembers()">{{ "Select All Filtered" | translate }}</button>
          } @else {
            <button class="selectBtn btnStyle" (click)="selectAllNonMembers()">{{ "Deselect All Filtered" | translate }}</button>

          }
        }


        @if(nonProjectMembers.length > 0) {
          @if(!isNonMembersSearch) {
            <mat-list class="user-list">
              @for(user of nonProjectMembers; track user) {
                <mat-list-item>
                  <div class="item-content">
                    <div>
                      <mat-checkbox [checked]="selectedNonMembers.includes(user)" (change)="checkboxChanged($event.checked, selectedNonMembers, user)"></mat-checkbox>
                      <span>{{user.name}}</span>
                    </div>
                  </div>
                </mat-list-item>            }
            </mat-list>
          } @else {
            <mat-list class="user-list">
              @for(user of nonMembersSearch; track user) {
                <mat-list-item>
                  <div class="item-content">
                    <div>
                    <mat-checkbox [checked]="selectedNonMembers.includes(user)" (change)="checkboxChanged($event.checked, selectedNonMembers, user)"></mat-checkbox>
                    <span>{{user.name}}</span>
                    </div>
                  </div>
                </mat-list-item>            }
            </mat-list>
          }
        } @else {
          <div class="emptyListPlaceholder">
            <span>{{ "LIST EMPTY" | translate }}</span>
          </div>
        }

        <div class="arrowLeft">
          <button class="arrowBtn" (click)="moveToMembers()"><fa-icon [icon]="faArrowLeft" size="2x"></fa-icon></button>
        </div>
      </div>
    </div>

  <div class="bottomDiv">
    <button class="closeBtn btnStyle" mat-dialog-close>Close</button>
  </div>
</div>
