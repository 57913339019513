import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogActions} from '@angular/material/dialog';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { getToken } from 'src/app/share/utils';
import { CrsService } from 'src/app/services/crs.service';
import { StoreService } from 'src/app/services/store.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {MatSort} from '@angular/material/sort';
import {ToastService} from '../../../services/toast.service';
import {UnsavedChangesDialogComponent} from '../unsaved-changes-dialog/unsaved-changes-dialog.component';
import {CookieService} from 'ngx-cookie-service';

const ELEMENT_DATA: any[] = [];

@Component({
  standalone: true,
  imports: [TranslateModule,
    MatTableModule,
    MatButtonModule,
    MatDialogActions,
    MatSort,
  ],
  selector: 'app-geom-type-coordinates-dialog',
  templateUrl: './geom-type-coordinates-dialog.component.html',
  styleUrls: ['./geom-type-coordinates-dialog.component.scss', '../dialog-btns.component.scss']
})
export class GeomTypeCoordinatesDialogComponent implements OnInit, AfterViewInit {
  coordinates: any[];
  geodetic: boolean;
  isGPSCoord: boolean;
  geomType: string;
  geometryData: any[];
  displayedColumns: string[] = [];
  feature: any;
  coordModified: boolean = false;

  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  @ViewChild(MatTable, {static: true}) coordinatestable: MatTable<any>;

  constructor(
    private conversionCoordinateService: CrsService,
    private store: StoreService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private translate: TranslateService,
    public toastService: ToastService,
    private dialogRef: MatDialogRef<GeomTypeCoordinatesDialogComponent>) {
    if (data !== undefined && data !== null) {
      this.feature = data.feature;
      this.geometryData = data.coordinates;
      this.isGPSCoord = data.gpscoord;
      this.geomType = data.geometry_type;
    }
  }

  ngOnInit() {
    if(this.geometryData) {
      this.convertCoordinates(this.geometryData);
    }
  }

  ngAfterViewInit() {

  }

  updateCoordinates() {

  }

  convertCoordinates(geodCoordinates) {
    this.coordinates = [];
    let crs = this.cookieService.get('CRSValue');
    if (this.geomType !== null) {
      if (this.geomType === 'Point') {
        let accuracy = 0.0;
        if (geodCoordinates.length >= 4) {
          accuracy = geodCoordinates[3];
        }
        if(!(geodCoordinates[0] === 0 && geodCoordinates[1] === 0  && geodCoordinates[2] === 0)) {
            const el = {
              latitude : geodCoordinates[0],
              longitude : geodCoordinates[1],
              altitude : geodCoordinates[2],
              accuracy: accuracy !== 0.0 ? accuracy : '?'
            };
            this.coordinates.push(el);
        }
      } else if (this.geomType === 'LineString' || this.geomType === 'Linestring') {
        geodCoordinates.map(geodCoordinate => {
          let accuracy = 0.0;
          if (geodCoordinate.length >= 4) {
            accuracy = geodCoordinate[3];
          }
          if(!(geodCoordinates[0] === 0 && geodCoordinates[1] === 0
            && geodCoordinates[2]  === 0)) {
              const item = {
                latitude : geodCoordinate[0],
                longitude : geodCoordinate[1],
                altitude : geodCoordinate[2],
                accuracy: accuracy !== 0.0 ? accuracy : '?'
              };
              this.coordinates.push(item);
          }
        });
      } else if (this.geomType === 'Polygon') {
        geodCoordinates.map(element => {
          element.map(geodCoordinate => {
            let accuracy = 0.0;
            if (geodCoordinate.length >= 4) {
              accuracy = geodCoordinate[3];
            }
            if(!(geodCoordinates[0] === 0 && geodCoordinates[1] === 0
              && geodCoordinates[2]  === 0)) {
                const item = {
                  latitude : geodCoordinate[0],
                  longitude : geodCoordinate[1],
                  altitude : geodCoordinate[2],
                  accuracy: accuracy !== 0.0 ? accuracy : '?'
                };
                this.coordinates.push(item);
            }
          });
        });
      }
    } else {
      let accuracy = 0.0;
      if (geodCoordinates.length >= 4) {
        accuracy = geodCoordinates[3];
      }
      if(!(geodCoordinates[0] === 0 && geodCoordinates[1] === 0  && geodCoordinates[2]  === 0)) {
          const el = {
            latitude : geodCoordinates[0],
            longitude : geodCoordinates[1],
            altitude : geodCoordinates[2],
            accuracy
          };
          this.coordinates.push(el);
      }
    }

    if (crs === null || crs === undefined || crs === '' || crs === 'Geodetic') {
      crs = 'Geodetic';
      this.geodetic = true;
      this.createHeaderArray(this.geodetic);
      this.createDataArray();
    } else {
      const token = getToken();
      this.store.showLoading();
      this.conversionCoordinateService.convertCRS(token, crs, this.coordinates).subscribe(
        res => {
          this.geodetic = false;
          this.createHeaderArray(this.geodetic);
          this.coordinates = res;
          this.createDataArray();
          this.store.hideLoading();
        },
        err => {
          this.toastService.errorToast(err);
          this.store.hideLoading();
        }
      );
    }
  }

  createHeaderArray(geodetic) {
    this.displayedColumns = [];
    if (geodetic) {
      this.displayedColumns.push('Latitude');
      this.displayedColumns.push('Longitude');
      this.displayedColumns.push('Elevation');
      this.displayedColumns.push('Accuracy');
    } else {
      this.displayedColumns.push('X');
      this.displayedColumns.push('Y');
      this.displayedColumns.push('Z');
      this.displayedColumns.push('Accuracy');
    }
  }

  createDataArray() {
    const data = [];
    if (this.geodetic) {
      this.coordinates.map(coordinate => {
        let accuracy = 0.0;
        if (coordinate['accuracy'] !== null && coordinate['accuracy'] !== undefined && coordinate['accuracy'] !== '?') {
          accuracy = coordinate['accuracy'].toFixed(2);
        }
        const coord = {
          Latitude: coordinate['latitude'].toFixed(8) + '°',
          Longitude: coordinate['longitude'].toFixed(8) + '°',
          Elevation: coordinate['altitude'].toFixed(2) + 'm',
          Accuracy: accuracy !== 0.0 ? accuracy : '?'
        };
        data.push(coord);
      });
      // data = this.coordinates;
    } else {
      this.coordinates.map(coordinate => {
        let accuracy = 0.0;
        if (coordinate['accuracy'] !== null && coordinate['accuracy'] !== undefined && coordinate['accuracy'] !== '?') {
          accuracy = coordinate['accuracy'].toFixed(2);
        }
        const coord = {
          X: coordinate['X'].toFixed(2),
          Y: coordinate['Y'].toFixed(2),
          Z: coordinate['Z'].toFixed(2),
          Accuracy: accuracy !== 0.0 ? accuracy : '?'
        };
        data.push(coord);
      });
    }
    this.dataSource.data = data;
    this.coordinatestable.renderRows();
  }

  closeDialog() {
    if (this.coordModified) {
      const confirmRef = this.dialog.open(UnsavedChangesDialogComponent, {
        data: {
          message: this.translate.instant(`CHANGES ARE NOT SAVED, DO YOU WANT TO CLOSE THE DIALOG?`),
          title: this.translate.instant('SAVE CHANGES?')
        }
      });
      confirmRef.afterClosed().subscribe(result  => {
        if (result) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }

  }
}
