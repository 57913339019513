import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource, MatTable, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { CollaboratorsDialogComponent } from '../dialogs/collaborators-dialog/collaborators-dialog.component';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { getToken, getUser, getMarxactUsername, getMarxactPassword } from 'src/app/share/utils';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/share/user';
import { MarxactLoginPageComponent } from '../dialogs/marxact-login-page/marxact-login-page.component';
import { AssignmarxactdevicesComponent } from '../dialogs/assignmarxactdevices/assignmarxactdevices.component';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { ProjectMemberService } from 'src/app/services/project-member.service';
import { MarxactPageComponent } from '../marxact-page/marxact-page.component';
import { StoreService } from 'src/app/services/store.service';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {ToastService} from '../../services/toast.service';


export interface Collaborator {
  id: string;
  name: string;
  email: string;
  created_date: string;
  owned_projects: number;
  may_create_projects: boolean;
  gnss_source: string;
}

const ELEMENT_DATA: Collaborator[] = [];

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MessageBoxComponent,
    MatTableModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FontAwesomeModule,
    TranslateModule,
  ],
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss']
})
export class UsersPageComponent implements OnInit {
  user: User;
  collaborator: Collaborator;
  displayedColumns: string[] = ['select', 'name', 'email', 'created_date', 'owned_projects', 'may_create_projects', 'gnss_source'];

  dataSource = new MatTableDataSource<Collaborator>(ELEMENT_DATA);
  selection = new SelectionModel<Collaborator>(true, []);
  searchValue = '';
  message = '';
  showMessage: boolean;

  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }

  constructor(
    private dialog: MatDialog,
    public store: StoreService,
    private userService: UserService,
    private projectMemberService: ProjectMemberService,
    private translate: TranslateService,
    public toastService: ToastService,
  ) { }

  ngOnInit() {
    this.user = getUser();
    this.getCollaborator();
  }

  getCollaborator() {
    const token = getToken();
    this.store.showLoading();
    this.userService.getCollaboratorUsers(this.user, token).subscribe(
      res => {
        let data = this.dataSource.data;
        data = [];
        for (const collaborator of  res) {
          let gnssSource = null;
          if (collaborator.mc_marxact !== null) {
            const devices = collaborator.mc_marxact.devices;
            if (devices !== null) {
            if (devices.length > 0) {
                  for (const device of devices) {
                    gnssSource = (gnssSource === null) ? device.device_name : gnssSource + ',' + device.device_name;
                  }
                }
            }
          }
          let el;
          // const collaboratorProjects = 0;
          el = {
            id: collaborator.id,
            name: collaborator.name,
            email: collaborator.email,
            created_date: new Date(collaborator.created_at),
            owned_projects: 0,
            may_create_projects: this.canUserCreateProject(collaborator),
            gnss_source: gnssSource
          };
          data.push(el);
        }
        this.dataSource.data = data.sort((u1, u2) => {
          if (u1.name < u2.name) { return -1; }
          if (u1.name > u2.name) { return 1; }
          return 0;
        });
        data.map(item => {
          this.store.showLoading();
          this.projectMemberService.getListOfProjectOfUser(token, item.id).subscribe(
            projResult => {
              let ownedprojects = 0;
              projResult.map(proj => {
                if (proj.created_by === item.id) {
                  ownedprojects += 1;
                }
              });
              // item['owned_projects'] = projResult.length;
              item['owned_projects'] = ownedprojects;
              this.dataSource.data = data;
              this.table.renderRows();
            },
            projectError => {
              // return;
            }
          );
        });
        data.sort((u1, u2) => {
          if (u1.name < u2.name) { return -1; }
          if (u1.name > u2.name) { return 1; }
          return 0;
        });

        this.store.hideLoading();
      },
      err => {
        this.toastService.errorToast(err);
        this.store.hideLoading();
      });
  }

  canUserCreateProject(collaborator): boolean {
    if (collaborator !== null && collaborator !== undefined) {
      if (collaborator['permissions'] !== null
          && collaborator['permissions'] !== undefined) {
        const permissions = collaborator['permissions'];
        const projects = permissions['projects'];
        if (projects !== null && projects !== undefined && projects.length > 0) {
          const indexOfCreate = projects.indexOf('create');
          if (indexOfCreate >= 0) {
            return true;
          }
        }
      }
    }
    return false;
  }

  isMarxactDevices(element): boolean {
    if (element.gnss_source !== null && element.gnss_source !== undefined && element.gnss_source !== '') {
        return true;
    }
    return false;
  }

  revokeOrGrantPermission(event: MatCheckboxChange, user: any, status: boolean) {
    event.source.checked = status;
    if (status) {
        this.openGrantPermissionsDialog(user);
    } else {
        this.openRevokePermissionsDialog(user);
    }
}


  openGrantPermissionsDialog(user) {
    /*
    const dialogRef = this.dialog.open(UnsavedChangesDialogComponent, {
      width: '400px',
      data: {
        message: `Do you want to grant create project permission to user ${user.name}?`,
        title: 'Confirm User deletion'
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {

      }
    });
    */
    const token = getToken();
    this.store.showLoading();
    this.userService.grantCollaboratorPermissionToCreateProject(user.id, token).subscribe(
      res => {
        this.getCollaborator();
        this.store.hideLoading();
        this.toastService.successToast('Permission granted')
      },
      errmes => {
        this.store.hideLoading();
        this.toastService.errorToast(errmes);
      }
    );
  }

  openRevokePermissionsDialog(user) {
    const token = getToken();
    this.store.showLoading();
    this.userService.revokeCollaboratorPermissionToCreateProject(user.id, token).subscribe(
      res => {
        this.getCollaborator();
        this.store.hideLoading();
        this.toastService.successToast('Permission revoked')
      },
      errmes => {
        this.store.hideLoading();
        this.toastService.errorToast(errmes);
      }
    );
  }

  /**
   * This method verify if the user is connected to the marxact account
   */
  openGnssSourceLogin(element) {
    const marxactuserconnected = this.checkIfUserConnected();
    if (!marxactuserconnected) {
      const dialogRef = this.dialog.open(MarxactLoginPageComponent, {
        width: '400px'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== null) {
          if (result.marxact_connected ) {
            this.openMarxactDeviceSettingDialog(element);
          }
        }
      });
    } else {
      this.openMarxactDeviceSettingDialog(element);
    }
  }

  checkIfUserConnected() {
    const username = getMarxactUsername();
    const password = getMarxactPassword();
    if (username !== null && username !== undefined && username !== '') {
      return true;
    }
    return false;
  }


  openMarxactDeviceSettingDialog(element) {
    const dialogRef = this.dialog.open(MarxactPageComponent, {
      width: '600px',
      data: {
        user: element
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getCollaborator();
    });
  }

  /**
   * Open Marxact dialog
   */
  openMarxactPage() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(AssignmarxactdevicesComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /**
   * This method allow to create new collaborator user
   */
  openAddUserDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(CollaboratorsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Add') {
        // User created add it to the table and refresh the table
        const userData = result.data;
        const token = getToken();

        this.store.showLoading();
        this.userService.addingCollaboratorUser(userData.email, token).subscribe(
          res => {
            // This operation return an invitation code, with that code create new user and add it to the table
            this.createNewUser(userData.name, userData.email, userData.password, res.invitation_code);
          },
          err => {
            this.store.hideLoading();
            this.toastService.errorToast(err);
          }
        );

      } else if (result.event === 'Cancel') {
        this.toastService.warningToast('Cancelled');
      }
    });
  }

  createNewUser(name, email, password, invitationToken) {
    // this.store.loading = false;
    this.store.showLoading();
    this.userService.signupCollaborator(name, email, password, 'collaborator', invitationToken).subscribe(
      res => {
        const gnsssource = res.mc_marxact !== null ? res.mc_marxact.join(',') : null;
        this.collaborator = {
            id: res.id,
            name: res.name,
            email: res.email,
            created_date: res.created_at,
            owned_projects: 0,
            may_create_projects: false,
            gnss_source: gnsssource
        };
        const data = this.dataSource.data;
        data.push(this.collaborator);
        this.dataSource.data = data;
        this.table.renderRows();
        this.store.hideLoading();

        // const token = getToken();
        // this.userService.grantCollaboratorPermissionToCreateProject(res.id, token).subscribe(
        //   user => {
        //   },
        //   usererr => {
        //     this.store.hideLoading();
        //   }
        // );
      },
      err => {
        this.store.hideLoading();
        this.toastService.errorToast(err);
      }
    );
  }

  applyFilter(event) {
    event.preventDefault();
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    event.stopPropagation();
  }

  openConfirmDeleteUserDialog() {
    if (this.selection.selected.length <= 0) {
      this.toastService.errorToast('No user selected');
      return;
    }
    let username = ''; // this.selection.selected[0].name;
    for (const user of this.selection.selected) {
      username = username === '' ? user.name : `${username}, ${user.name}`;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        title: this.translate.instant("DELETE USER(S)") + "?",
        message: this.translate.instant("DO YOU REALLY WANT TO DELETE THE USER(S)") + " " + username
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        const token = getToken();
        const index = 0;
        while (this.selection.selected.length > 0) {
          this.store.showLoading();
          this.deleteCollaboratorUser(this.selection.selected.pop(), token);
        }
      }
    });
  }

  deleteCollaboratorUser(user, token) {
    this.userService.deleteUser(user, token).subscribe(
      res => {
        this.store.hideLoading();
        this.selection.deselect(user);
        this.getCollaborator();
      },
      errmes => {
        this.store.hideLoading();
        this.selection.deselect(user);
        this.toastService.errorToast(errmes);
      }
    );
  }

    /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.map(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Collaborator): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }


  protected readonly faPlus = faPlus;
}
