import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {CdkDrag, CdkDragDrop, CdkDragHandle, CdkDragPreview, CdkDropList, CdkDropListGroup, moveItemInArray} from '@angular/cdk/drag-drop';
import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {Item} from '../../../share/form/item';
import {CommonModule} from '@angular/common';
import {MatIcon} from '@angular/material/icon';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {
  faAngleDown,
  faAngleUp,
  faDeleteLeft,
  faGrip,
  faObjectGroup,
  faPenToSquare,
  faPlus,
  faThLarge
} from '@fortawesome/free-solid-svg-icons';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {persistSelectedFieldIndex} from '../../../share/utils';
import {CreateIntegerFieldComponent} from '../../dialogs/create-integer-field/create-integer-field.component';
import {CreateStringFieldComponent} from '../../dialogs/create-string-field/create-string-field.component';
import {CreateDecimalFieldComponent} from '../../dialogs/create-decimal-field/create-decimal-field.component';
import {CreateAutoincrementFieldComponent} from '../../dialogs/create-autoincrement-field/create-autoincrement-field.component';
import {CreateNoteFieldComponent} from '../../dialogs/create-note-field/create-note-field.component';
import {CreateBarcodeFieldComponent} from '../../dialogs/create-barcode-field/create-barcode-field.component';
import {CreateGpsCoordinatesFieldComponent} from '../../dialogs/create-gps-coordinates-field/create-gps-coordinates-field.component';
import {CreatePhotoFieldComponent} from '../../dialogs/create-photo-field/create-photo-field.component';
import {CreateVideoFieldComponent} from '../../dialogs/create-video-field/create-video-field.component';
import {CreateFieldsetComponent} from '../../dialogs/create-fieldset/create-fieldset.component';
import {CreateFieldsetArrayComponent} from '../../dialogs/create-fieldset-array/create-fieldset-array.component';
import {CreateAudioFieldComponent} from '../../dialogs/create-audio-field/create-audio-field.component';
import {CreateTimeFieldComponent} from '../../dialogs/create-time-field/create-time-field.component';
import {CreateDateFieldComponent} from '../../dialogs/create-date-field/create-date-field.component';
import {CreateBooleanFieldComponent} from '../../dialogs/create-boolean-field/create-boolean-field.component';
import {StoreService} from '../../../services/store.service';
import {DataService} from '../../../services/data.service';
import {ConfirmationDialogComponent} from '../../dialogs/confirmation-dialog/confirmation-dialog.component';
import {ConstraintListDialogComponent} from '../../dialogs/constraint-list-dialog/constraint-list-dialog.component';
import {MatTooltip} from '@angular/material/tooltip';
import {style} from '@angular/animations';

@Component({
  selector: 'list-item',
  templateUrl: './list-item.html',
  standalone: true,
  imports: [TranslateModule,
    CommonModule,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    CdkDragPreview,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    FaIconComponent,
    MatTooltip,
    MatButton,
    CdkDropListGroup
  ],
  styleUrls: ['./list-item.scss']
})
export class ListItemComponent {
  @Input() item: Item;
  @Input() parent: Item;
  @Input() index: number;
  @Input() connectedTo: string[];
  @Output() itemDrop: EventEmitter<CdkDragDrop<Array<Item>>>;
  isMouseDown: boolean = false;

  //Icons
  protected readonly faPlus = faPlus;
  protected readonly faPenToSquare = faPenToSquare;
  protected readonly faDeleteLeft = faDeleteLeft;
  protected readonly style = style;
  protected readonly faAngleDown = faAngleDown;
  protected readonly faAngleUp = faAngleUp;
  protected readonly faObjectGroup = faObjectGroup;
  protected readonly faThLarge = faThLarge;
  protected readonly faGrip = faGrip;

  constructor(
    private dialog: MatDialog,
    public store: StoreService,
    private dataService: DataService,
    public translate: TranslateService
  ) {
    this.itemDrop = new EventEmitter();
  }

  // trackBy function for ngFor loops
  trackById(index: number, item: Item): any {
    return item.uId;
  }

  trackByConstraint(index: number, constraint: any): any {
    return constraint['_class'] || index;
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalMouseDown(event: MouseEvent): void {
    this.isMouseDown = true;
  }

  @HostListener('document:mouseup', ['$event'])
  onGlobalMouseUp(event: MouseEvent): void {
    this.isMouseDown = false;
  }

  @HostListener('document:contextmenu', ['$event'])
  onRightClick(event: MouseEvent): void {
    if (this.isMouseDown) {
      event.preventDefault(); // Prevent right-click when left-click is pressed
    }
  }

  public onDragDrop(event: CdkDragDrop<Array<Item>>): void {
    this.itemDrop.emit(event);
  }

  selectFieldset() {
    this.store.selectedItemId = this.item.uId;
    this.store.currentFieldSet = this.item;
  }

  openFieldConstraintPage(field) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '600px';
    dialogConfig.height = 'auto';
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      field
    };

    const dialogRef = this.dialog.open(ConstraintListDialogComponent, dialogConfig);
  }

  isFieldHaveConstraint() {
    return this.item.constraints !== null &&
      this.item.constraints !== undefined &&
      this.item.constraints.length > 0;
  }

  getConstraintText(constraint) {
    switch (constraint) {
      case 'choice':
        return 'Single choice list';

      case 'multiple_choice':
        return 'Multiple choice list';

      case 'minimum':
        return 'Minimum';

      case 'maximum':
        return 'Maximum';

      case 'length':
        return 'Length';
    }
  }

  /**
   * This method open integer dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  updateFieldSetArray(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateFieldsetArrayComponent, dialogConfig);
    this.handleAfterClosed(dialogRef);
  }

  updateFieldSet(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateFieldsetComponent, dialogConfig);
    this.handleAfterClosed(dialogRef);
  }

  handleAfterClosed(dialogRef: MatDialogRef<CreateFieldsetArrayComponent, any> | MatDialogRef<CreateFieldsetComponent, any>) {
    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        if (result.action === 'Update') {
          if (this.parent !== null && this.parent !== undefined) {
            this.parent.children.splice(this.index, 1, result.field);
          }
        }
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open the appriopriate dialog for updatating
   * @param f The field we want to update
   */
  openUpdateField(f) {
    switch (f._class) {
      case 'fieldset':
        this.updateFieldSet(f);
        break

      case 'fieldsetarray':
        this.updateFieldSetArray(f);
        break;

      case 'arrayfieldset':
        this.updateFieldSetArray(f);
        break;

      case 'stringfield':
        this.openStringDialog(f);
        break;

      case 'integerfield':
        this.openIntegerDialog(f);
        break;

      case 'decimalfield':
        this.openDecimalDialog(f);
        break;

      case 'notefield':
        this.openNoteDialog(f);
        break;

      case 'barcodefield':
        this.openBarCodeDialog(f);
        break;

      case 'booleanfield':
        this.openBooleanFieldDialog(f);
        break;

      case 'arrayfield':
        //  return 'Array';
        break;

      case 'gpscoordinatesfield':
        this.openGPSCoordinatesDialog(f);
        break;

      case 'No Geometry':
        break;

      case 'computedfield':
        break;

      case 'datefield':
        this.openDateFieldDialog(f);
        break;

      case 'timefield':
        this.openTimeFieldDialog(f);
        break;

      case 'photofield':
        this.openPhotoDialog(f);
        break;

      case 'videofield':
        this.openVideoDialog(f);
        break;

      case 'audiofield':
        this.openAudioFieldDialog(f);
        break;

      case 'autoincrementintegerfield':
        this.openAutoIntegerDialog(f);
        break;
    }
  }

  /**
   * This method create and return the MatDialogConfig setting for the dialog to open
   * for updating a selected field
   * @param f The current field element
   * @param parent The parent fieldset of the selected field
   */
  setUpdateDialogConfig(f, parent): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      mode: {
        text: this.translate.instant("UPDATE"),
        function: 'Update'
      },
      field: this.store.transformToFields(f),
      index: this.index,
      parent: this.store.transformToFields(parent)
    };
    persistSelectedFieldIndex(this.index);
    return dialogConfig;
  }

  /**
   * This method open integer dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openIntegerDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateIntegerFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * Apply the change and update the field, and fieldset and also the UI
   * @param result the result given by the dialog
   */
  applyUpdate(result) {
    if (result.action === 'Update') {
      this.store.updatedForm = true;

      this.item = this.store.transformToItems(result.field);


      this.parent.children.splice(this.index, 1, this.item);
      this.dataService.emitEvent(this.item, this.parent);
    }
  }

  /**
   * This method open string field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openStringDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateStringFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open decimal field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openDecimalDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateDecimalFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open Integer field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openAutoIntegerDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateAutoincrementFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open note field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openNoteDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateNoteFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open barcode field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openBarCodeDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateBarcodeFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open GPSCoordinate Field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openGPSCoordinatesDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateGpsCoordinatesFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open photo field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openPhotoDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreatePhotoFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open video field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openVideoDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateVideoFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open audio field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openAudioFieldDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateAudioFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open video field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openTimeFieldDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateTimeFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open date field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openDateFieldDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateDateFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method open boolean field dialog to be updated, and return the updated field to
   * update method to be applied to the element in parent fieldset
   * @param f The current field element
   */
  openBooleanFieldDialog(f) {
    const dialogConfig = this.setUpdateDialogConfig(f, this.parent);
    const dialogRef = this.dialog.open(CreateBooleanFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.applyUpdate(result);
      }
    });
  }

  /**
   * This method call the confirmation button before delete a field
   * @param f The selected field
   */
  openDeleteConfirmationDialog(f) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        message: this.translate.instant("DO YOU WANT TO DELETE THE SELECTED FIELD?"),
        title: this.translate.instant("DELETE SELECTED FIELD?")
      }
    });

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        this.deleteField(f);
      }
    });
  }

  /**
   * This method delete the selected field from the list of fields in parent
   * If the index is lower than 0 or egal the length of the parent fields, nothing happen
   * @param f The selected field
   */
  deleteField(f) {
    if (this.index < 0 || this.index > this.parent.children.length - 1) {
      return;
    }
    this.store.updatedForm = true;
    this.parent.children.splice(this.index, 1);
    // this.deletedField.emit(f);

  }

  moveUp() {
    const currentIndex = this.parent.children.indexOf(this.item);
    if (currentIndex > 0) {
      const itemElement = document.getElementById(this.item.uId);
      const previousItemElement = document.getElementById(this.parent.children[currentIndex - 1].uId);

      if (itemElement && previousItemElement) {
        // Add transition and animation classes
        itemElement.style.transition = 'transform 0.3s ease-in-out';
        previousItemElement.style.transition = 'transform 0.3s ease-in-out';

        itemElement.classList.add('move-up');
        previousItemElement.classList.add('move-down');

        // Wait for animation to complete
        setTimeout(() => {
          // Swap the items in the array
          moveItemInArray(this.parent.children, currentIndex, currentIndex - 1);

          // Reset animation classes and styles
          itemElement.classList.remove('move-up');
          previousItemElement.classList.remove('move-down');

          itemElement.style.transition = '';
          previousItemElement.style.transition = '';
        }, 300); // Match transition duration

        this.store.updatedForm = true;
      }
    }
  }

  moveDown() {
    const currentIndex = this.parent.children.indexOf(this.item);
    if (currentIndex < this.parent.children.length - 1) {
      const itemElement = document.getElementById(this.item.uId);
      const nextItemElement = document.getElementById(this.parent.children[currentIndex + 1].uId);

      if (itemElement && nextItemElement) {
        // Add transition and animation classes
        itemElement.style.transition = 'transform 0.3s ease-in-out';
        nextItemElement.style.transition = 'transform 0.3s ease-in-out';

        itemElement.classList.add('move-down');
        nextItemElement.classList.add('move-up');

        // Wait for animation to complete
        setTimeout(() => {
          // Swap the items in the array
          moveItemInArray(this.parent.children, currentIndex, currentIndex + 1);

          // Reset animation classes and styles
          itemElement.classList.remove('move-down');
          nextItemElement.classList.remove('move-up');

          itemElement.style.transition = '';
          nextItemElement.style.transition = '';
        }, 300); // Match transition duration

        this.store.updatedForm = true;
      }
    }

  }
}
