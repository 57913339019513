<div class="container">
  <div id="admin-container-div" class="admin-container">
    <div class="sidebar-admin">
      @if(!showUserProjectsPage) {
      <ul id="navAdmin">
        <li class="user-info-admin">
          <a id="admininfo" class="menu-link" [ngClass]="{'selected-admin': selectedMenu === 'USER_INFORMATION'}"
            (click)="displayAdminInfo()">
            <fa-icon [icon]="faInfoCircle" size="xl"></fa-icon>
            <span class="menu-text"><span>{{ "USER INFORMATION" | translate }}</span></span>
          </a>
        </li>
        <li class="password-reset-admin">
          <a id="resetpassword" class="menu-link" [ngClass]="{'selected-admin': selectedMenu === 'RESET_PASSWORD'}"
            (click)="displayResetPassword()">
            <fa-icon [icon]="faRotateLeft" size="xl"></fa-icon>
            <span class="menu-text"><span>{{ "RESET PASSWORD" | translate }}</span></span>
          </a>
        </li>
        @if(isAdminUser) {
        <li class="managed-users-admin">
          <a id="users" class="menu-link" [ngClass]="{'selected-admin': selectedMenu === 'MANAGE_USER'}"
            (click)="displayUsers()">
            <fa-icon [icon]="faUserGroup" size="xl"></fa-icon>
            <span class="menu-text"><span>{{ "MANAGE USERS" | translate }}</span></span>
          </a>
        </li>
      }
      </ul>
      }
      @if(checkUserType(userType)) {
      <ul id="manageProjectsMenu">
        <li class="manage-projects">
          <a id="managedProjects" class="menu-link" [ngClass]="{'selected-admin': selectedMenu === 'MANAGE_MEMBER'}"
            (click)="displayProjects()">
            <span class="menu-text"><span>{{ "MANAGE MEMBERS" | translate }}</span></span>
          </a>
        </li>
      </ul>
      }
    </div>
    <div id="adminContent" class="admin-content">
      @if(showAdminInfoPage) {<app-admin-info-page></app-admin-info-page>}
      @if(showResetPasswordPage) {<app-reset-password-page
        (hideResetPassword)="hideResetPassword($event)"></app-reset-password-page>}
      @if(showUsersPage) {<app-users-page></app-users-page>}
      @if(showUserProjectsPage) {<app-user-projects-page></app-user-projects-page>}
    </div>
  </div>
</div>
