<div id="userspage" class="users-page">
  <div class="top-section">
    <div class="search-section full-width mt-2">
      <span class="text-left search-input">
        @if (showMessage) {
        <ng-container>
          <app-message-box #messageBox [message]="message"></app-message-box>
        </ng-container>
        }
        <div class="form-group has-clear">
          <mat-form-field class="full-width">
            <mat-label>{{"SEARCH..." | translate}}</mat-label>
            <input matInput autocomplete="off" (keyup)="applyFilter($event)" class="full-width"
              [placeholder]="'SEARCH...' | translate" [value]="searchValue">
          </mat-form-field>
        </div>
      </span>
      <span class="text-right button-group">
        <button mat-button (click)="openAddUserDialog()" mat-flat-button color="primary"
                class="btn-border mr-2"><span>{{ "ADD" | translate }}</span></button>
        <button mat-button (click)="openConfirmDeleteUserDialog()" mat-flat-button color="primary"
          class="btn-border mr-2"><span>{{ "DELETE" | translate }}</span></button>
      </span>
    </div>
  </div>
  <div class="table-section ml-2 mr-3">
    <table mat-table [dataSource]="dataSource" class="table table-striped table-bordered full-width">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="checkbox-column">
          <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" class="table-cells checkbox-column">
          <mat-checkbox color="primary" (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="name-column">
          <span class="ml-2"><span>{{ "NAME" | translate }}</span></span>
        </th>
        <td mat-cell *matCellDef="let element" class="table-cells">
          <span class="ml-2">
            {{element.name}}
          </span>
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef class="email-column">
          <span class="ml-2"><span>{{ "EMAIL" | translate }}</span></span>
        </th>
        <td mat-cell *matCellDef="let element" class="table-cells">
          <span class="ml-2">
            {{element.email}}
          </span>
        </td>
      </ng-container>

      <!-- Created date Column -->
      <ng-container matColumnDef="created_date">
        <th mat-header-cell *matHeaderCellDef class="created-date-column">
          <span class="ml-2"><span>{{ "CREATED DATE" | translate }}</span></span>
        </th>
        <td mat-cell *matCellDef="let element" class="table-cells">
          <span class="ml-2">
            {{element.created_date | date: 'yyyy-MM-dd'}}
          </span>
        </td>
      </ng-container>

      <!-- Owned Projects Column -->
      <ng-container matColumnDef="owned_projects">
        <th mat-header-cell *matHeaderCellDef class="text-center owned-projects-column">
          <span>{{ "OWN PROJECTS" | translate }}</span>
        </th>
        <td mat-cell *matCellDef="let element" class="text-right mr-2 table-cells">
          <span>
            {{element.owned_projects}}
          </span>
        </td>
      </ng-container>

      <!-- May Create Projects Column -->
      <ng-container matColumnDef="may_create_projects">
        <th mat-header-cell *matHeaderCellDef class="text-center may-create-projects-column">
          <span>{{ "MAY CREATE PROJECTS" | translate }}</span>
        </th>
        <td mat-cell *matCellDef="let element" class="action-link text-center table-cells">
          <span>
            <mat-checkbox #c color="primary" (change)="revokeOrGrantPermission($event, element, c.checked)"
              [checked]="element.may_create_projects">
            </mat-checkbox>
          </span>

        </td>
      </ng-container>

      <!-- GNSS Source Column -->
      <ng-container matColumnDef="gnss_source">
        <th mat-header-cell *matHeaderCellDef class="gnss-source-column">
          <span class="ml-2"><span>{{ "GNSS SOURCE" | translate }}</span></span>
        </th>
        <td mat-cell *matCellDef="let element" class="table-cells">
          <span class="ml-2">
            @if(isMarxactDevices(element)) {
            <span> {{ element.gnss_source }} <span class="btn-pointer" (click)="openGnssSourceLogin(element)">
                ...</span> </span>
            }
            @else {
            <button class="buttons gnss-source-btn" (click)="openGnssSourceLogin(element)" mat-button mat-flat-button>
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
            }
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
