import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MarxactService } from 'src/app/services/marxact.service';
import { getToken, persistMarxactAuthToken,
  persistMarxactUsername, persistMarxactPassword, persistMarxactUserConnected } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import {DialogActionsComponent} from '../dialog-actions/dialog-actions.component';

@Component({
  standalone: true,
    imports: [TranslateModule,
        MatToolbarModule,
        MessageBoxComponent,
        MatDialogContent,
        MatFormFieldModule,
        MatInputModule,
        MatDialogActions,
        ReactiveFormsModule,
        MatButtonModule,
        DialogActionsComponent,
    ],
  selector: 'app-marxact-login-page',
  templateUrl: './marxact-login-page.component.html',
  styleUrls: ['./marxact-login-page.component.scss', '../dialog-btns.component.scss']
})
export class MarxactLoginPageComponent implements OnInit {

  marxactLoginForm: FormGroup;
  message = '';
  showMessage: boolean;
  mode = {
    function: 'Ok',
    text: this.translate.instant('OK')
  }

  @ViewChild('fform') marxactLoginFormDirective;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }
  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) data,
    private marxactService: MarxactService,
    private dialogRef: MatDialogRef<MarxactLoginPageComponent>,
    public toastService: ToastService,
  ) {
    this.createForm();
   }

  ngOnInit() {
  }

  createForm() {
    this.marxactLoginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  closeDialog(event) {
    event.preventDefault();
    const result = null;
    this.dialogRef.close(result);
    event.stopPropagation();
  }

  onSubmit() {
    const token = getToken();
    const data = this.marxactLoginForm.value;
    const marxactusername = data['username'];
    const marxactpassword = data['password'];
    this.store.showLoading();
    this.marxactService.loginToMarxactBackend(token, data.username, data.password).subscribe(
      res => {
        const marxactToken = res.token;
        persistMarxactUsername(marxactusername);
        persistMarxactPassword(marxactpassword);
        persistMarxactUserConnected();
        persistMarxactAuthToken(marxactToken);
        this.store.hideLoading();
        const result = {
          marxact_connected: true
        };
        this.dialogRef.close(result);
      },
      err => {
        this.toastService.errorToast(err);
        this.store.hideLoading();
      }
    );
  }
}
