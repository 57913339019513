import { Injectable } from '@angular/core';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { getPrivateApiAURL } from '../share/utils';

@Injectable({
  providedIn: 'root'
})
export class FileServiceService {
  constructor(
    private http: HttpClient,
    private processHttpMsgService: ProcessHttpmsgService) {
  }

  getPrivateFile(link, token, project): Observable<Blob> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key,
        'X-Working-Project-Id': project.id
      })
    };
    httpOptions['responseType'] = 'blob';
    return this.http.get<Blob>(link, httpOptions)
    .pipe(map(res => res))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  uploadPrivateFile(content, token, isMyCumulusAdministrator= false): Observable<any> {
    let httpOptions;
    if(isMyCumulusAdministrator) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Admin-Auth-Token': token.key
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-Auth-Token': token.key
        })
      };
    }

    return this.http.post<any>(getPrivateApiAURL() + 'files/public', content, httpOptions)
    .pipe(map(res => res))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  deletePublicFile(token, fileId, fileName){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };

    const url = getPrivateApiAURL() + 'files/public/' + fileId + '/' + fileName;

    return this.http.delete<any>(url, httpOptions)
    .pipe(map(res => res))
    .pipe(catchError(this.processHttpMsgService.handleError))
  }
}
