import { TranslateModule } from '@ngx-translate/core';
import {Component, OnInit, Input, ViewChild, Output, EventEmitter, Inject, HostListener} from '@angular/core';
import { Project } from 'src/app/share/projects';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { User } from 'src/app/share/user';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { getAdminToken, getToken } from 'src/app/share/utils';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import { FileServiceService } from 'src/app/services/file-service.service';
import { CommonModule } from '@angular/common';
import { ImageDropComponent } from '../../image-drop/image-drop.component';
import { MatFormFieldModule } from '@angular/material/form-field';import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {ToastService} from '../../../services/toast.service';
import {DialogActionsComponent} from '../dialog-actions/dialog-actions.component';

@Component({
  standalone: true,
  imports: [TranslateModule,
    MessageBoxComponent,
    ReactiveFormsModule,
    CommonModule,
    MatDialogContent,
    ImageDropComponent,
    MatFormFieldModule,
    MatInputModule,
    MatDialogActions,
    MatDialogModule,
    MatButtonModule,
    DialogActionsComponent,
  ],
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss', '../dialog-btns.component.scss']
})
export class CreateProjectComponent implements OnInit {
  @Input() project: Project;
  private addedProject: Project;
  projectDialogForm: FormGroup;
  image: string;
  name: string;
  description: string;
  project_class: string;
  proClass: string;
  archived: boolean;
  visibility: string;
  @Output() invalidIconDropped = new EventEmitter<boolean>();
  @Input() user: User;
  @Input() mode = {
    text: 'Create',
    function: 'Create'
  };
  @ViewChild('pjtIcon') img;
  @ViewChild('fform') projectFormDirective;
  private messageBox: MessageBoxComponent;

  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;

  constructor(
    private store: StoreService,
    private fb: FormBuilder,
    private fileService: FileServiceService,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CreateProjectComponent>,
    public toastService: ToastService,
  ) {
    if (data !== undefined && data !== null) {
      this.project = data.project;
      this.mode = data.mode;
      this.name = data.project.name;
      this.description = data.project.description;
      this.project_class = data.project.project_class;
      this.archived = data.project.archived;
      this.proClass = this.project_class;
      if(this.project_class === "template") {
        this.proClass = "Project Template";
      }
    }
    this.dataService.projectAdded.subscribe(project => this.onProjectAdded(project));
    this.createForm();
  }

  ngOnInit() {
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dialogRef.close();
    }
  }

  onCancel(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close();
  }

  createForm() {
    let name = '';
    let des = '';
    this.project_class = this.store.proClass;

    let archived = false;
    this.visibility = 'private';
    if (this.project !== undefined && this.project !== null) {
      name = this.project.name;
      des = this.project.description;
      this.project_class = this.project['project_class'];
      archived = this.project['archived'];
      this.visibility= this.project['visibility'];
      this.image = this.project.image_file_url;
    }
    this.projectDialogForm = this.fb.group({
      name: [name, Validators.required],
      description: des,
      project_class: this.project_class,
      visibility: this.visibility,
      archived: archived
    });
  }

  uploadFile(token, project, isMyCumulusAdministrator = false){
    const content = {
      filename: this.getUniqueFilename(project.name, this.getExtensionFromDataURI(this.img.image)),
      base64_encoded_content : this.img.image.split(',')[1]
    }
    this.fileService.uploadPrivateFile(content, token, isMyCumulusAdministrator).subscribe(
      res => {
        const fileUrl = res.file.download_url;
        project.image_file_url = fileUrl;
        let proToken;
        if(isMyCumulusAdministrator) {
          proToken = {
            key: 'X-Admin-Auth-Token',
            value: token.key
          };
        } else {
          proToken = {
            key: 'X-Auth-Token',
            value: token.key
          };
        }

        if(this.mode.function === 'Create'){
          this.dataService.createProject(project, proToken, isMyCumulusAdministrator).subscribe(
            res => {
              this.dataService.createProjectLocally(res);
              this.onProjectAdded(res);
              this.dataService.templates.next([]);
              this.dataService.showTemplates();
              this.dialogRef.close();
              this.store.hideLoading();
            },
            errmess => {
              this.toastService.errorToast(errmess);
              this.store.hideLoading();
          });
        } else {
          this.dataService.updateProject(this.project, proToken, isMyCumulusAdministrator).subscribe(
            res => {
              this.dataService.updateProjectLocally(res);
              this.dialogRef.close();
              this.store.hideLoading();
            },
            errmess => {
              this.store.hideLoading();
              this.toastService.errorToast(errmess);
          });
        }
      },
      err => {
        this.toastService.errorToast(err);
        this.store.hideLoading();
      }
    );
  }

  getUniqueFilename(prefix, extension): string {
    return prefix.replace(/\s/g, '') + '_' + Date.now() + '.' + extension;
  };

  getExtensionFromDataURI(data){
    return data.split(';')[0].split('/')[1];
  };

  getIconFile(image){
    if(image.split(':')[1] === '//placehold.it/100x100/ffffff/9e9e9e' || image === '' || image === undefined){
      return '';
    } else {
      return this.project.image_file_url;
    }
  }

  onSubmit() {
    const temp = this.projectDialogForm.value;
    let isMyCumulusAdministrator = false;

    let token = getToken();
    if(token === null || token === undefined) {
      token = getAdminToken();
      isMyCumulusAdministrator = true;
    }
    const proToken = {
      'key': 'key',
      'value': token.key
    }
    this.store.showLoading();
    const image = this.img.image;
    if (this.mode.function  === 'Create') {
      this.project = temp;

      if(image !== '' && image.split(':')[0] !== 'https' && image.split(':')[0] !== 'http'){
        this.uploadFile(token, temp, isMyCumulusAdministrator);
      } else {
        this.dataService.createProject(this.project, proToken, isMyCumulusAdministrator).subscribe(
          res => {
            this.dataService.createProjectLocally(res);
            this.onProjectAdded(res);
            this.dataService.templates.next([]);
            this.dataService.showTemplates();
            this.dialogRef.close();
            this.store.hideLoading();
          },
          errmess => {
            this.toastService.errorToast(errmess);
            this.store.hideLoading();
        });
      }
    } else if (this.mode.function === 'Update') {
      const isCurrentProject = this.project.id === this.dataService.project.id;
      this.project.name = temp.name;
      this.project.description = temp.description;
      this.project.image_file_url = this.getIconFile(image);
      if(image !== '' && image.split(':')[0] !== 'https' && image.split(':')[0] !== 'http'){
        this.uploadFile(token, this.project, isMyCumulusAdministrator);
      } else {
        this.dataService.updateProject(this.project, proToken, isMyCumulusAdministrator).subscribe(
          res => {
            if(isCurrentProject) {
              this.dataService.updateProjectLocally(res);
            }

            this.dialogRef.close();
            this.store.hideLoading();
          },
          errmess => {
            this.store.hideLoading();
            this.toastService.errorToast(errmess);
        });
      }
    }
  }

  onProjectAdded(project: Project) {
    this.toastService.successToast('Project successfully added');
  }

  invalidIconDrop(icon: boolean) {
    this.invalidIconDropped.emit(icon);
    this.toastService.errorToast('Only images allowed in the icon space');
  }
}
