import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ProcessHttpmsgService} from './process-httpmsg.service';
import {ReportResult, ReportsResult} from '../share/result';
import {Report} from '../share/report';
import {
  getChoosenCRSType,
  getPrivateApiAURL,
  getToken,
  removeCurrentFeaturesUri,
  removeSearchValue,
  saveTemplateFieldsNameAndTypes
} from '../share/utils';
import {Template} from '../share/template';
import {ToastService} from './toast.service';
import {FeatureService} from './feature.service';
import {FeatureSchema} from '../share/schema/schema';
import $ from 'jquery';
import {MatTableDataSource} from '@angular/material/table';
import {DataService} from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private featureService: FeatureService,
    private dataService: DataService,
    private processHttpMsgService: ProcessHttpmsgService) {
  }

  public lastExportedTemplate: any = null;

  createNewReport(token, requestBody) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
      })
    };
    return this.http.post(getPrivateApiAURL() + 'reports2/generate_report', requestBody, httpOptions);
  }

  getOutputFormats(token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
      })
    };
    return this.http.get(getPrivateApiAURL() + 'reports2/get_report_formats', httpOptions);
  }

  createEnexisReport(token, request): Observable<Report> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key,
      })
    };
    return this.http.post<ReportResult>(getPrivateApiAURL() + 'reports2/generate_report', request, httpOptions).pipe(
      map(res => {
        return res.file;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  deleteReport(report, token): Observable<Report> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': token.key,
      })
    };
    return this.http.delete<ReportResult>(getPrivateApiAURL() + 'reports/' + report.id, httpOptions).pipe(
      map(res => {
        return res.file;
      })
    )
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getReports(userId, token): Observable<Report[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token.key
      })
    };
    return this.http.get<ReportsResult>(getPrivateApiAURL() + 'reports/' + userId, httpOptions)
    .pipe(map(result => {
      return result.public_files.instances;
    }))
    .pipe(catchError(this.processHttpMsgService.handleError));
  }

  createTableHeaderArrayWithinTemplate(template: Template): string[] {
    let result = [];
    const form = template.form;
    if(form) {
      const fields = form.fields;
      for (const field of fields) {
        const attname = field['label'];
        if (field['_class'] === 'fieldset') {
          result = result.concat(this.createTableHeaderWithinAttributeSet(field, attname));
        } else {
          result.push(attname);
        }
      }
    }
    return result;
  }

  createTableHeaderWithinAttributeSet(fieldset, parent): string[] {
    let obj = [];
    for (const field of fieldset.fields) {
      if (field._class === 'fieldset') {
        const name = `${parent}.${field.label}`;
        obj = obj.concat(this.createTableHeaderWithinAttributeSet(field, name));
      } else if (field._class === 'arrayfieldset') {
        const name = `${parent}.${field.label}`;
        obj.push(name);
      } else {
        const attname = `${parent}.${field.label}`;
        obj.push(attname);
      }
    }
    return obj;
  }



  //GEN REPORT
  template: Template;
  attHeaders: any[];
  tableHeaders: any[];
  instances: any[] = [];
  ColumsValues: any[] = [];
  columnsValues: any[];
  advancedSearchCriteria: any;
  fromDate: any;
  toDate: any;
  sortedObject: any;
  templateId: string;
  projectionObject: any;
  operators: any[];
  metadata: object;
  more: boolean;
  uri: string;
  pages = 0;
  nbrPage: number;
  linksArray: any[];

  message = '';
  showMessage = false;



  submitSelectedItems(selectedColumns: any): void {
    const datesValues = selectedColumns.datesValues;
    this.sortedObject = selectedColumns.sortingObject;
    const columnsSelected = selectedColumns.ColumsValues;
    this.aggregation = selectedColumns.aggregateBody;
    const output = selectedColumns.outputType;
    this.advancedSearchCriteria = null;
    this.columnsValues = [];

    if (output === 'view_web_app') {
      for(let i = 0; i < columnsSelected.length; i++) {
        const ind = this.tableHeaders.indexOf(columnsSelected[i]);
        this.columnsValues.push(this.attHeaders[ind]);
      }
    } else {
      this.columnsValues = columnsSelected;
    }

    const y = this.columnsValues.indexOf('Coordinates');

    if (y !== -1) {
      this.columnsValues.splice(y, 1);
      this.columnsValues.push('Geometry Type');
    }

    this.fromDate = datesValues.fromDate;
    this.toDate = datesValues.toDate;

    if (this.aggregation !== null && this.aggregation.length > 0) {
      if (output === 'view_web_app') {
        this.aggregationSearch(this.aggregation);
      } else {
        let placeholders = [];
        //Calculates placeholders into correct format:
        if (selectedColumns.reportObject.holders.length > 0) {
          placeholders = selectedColumns.reportObject.holders.map(obj => ({
            [obj.name]: obj?.value || ""
          }));
        }

        let report_template_id = null;

        if(selectedColumns.selectedFormat.show_report_templates)
          report_template_id = selectedColumns.reportObject.selectedReport.id

        const requestBody = {
          configuration: {
            format: selectedColumns.outputType,
            split_type: "single_file", // Other options not yet available
            crs: getChoosenCRSType(),
          },
          report_template_id: report_template_id,
          place_holders: placeholders,
          queries: [],
        }

        this.requestReport(output, requestBody);
      }
    } else {
      this.fetchFeatures();
    }
  }


  // send the request for fetching features from the server.
  fetchFeatures() {
    this.linksArray = [];
    if (this.nbrPage === 0) {
      this.nbrPage = 100;
    }

    if (this.template.id === undefined || this.template.id === null || this.template.id === '') {
      return;
    }
  }

  /**
   * This method send creation report request or get the
   * aggregation search to update the data
   * @param reportType the type of the report
   * @param requestBody report body for the request.
   */

  reportType: string;
  doingReport: boolean;
  aggregation: any[] = [];

  isEmpty(obj) {
    if (obj === null || obj === undefined) {
      return true;
    }

    for (const prop of Object.keys(obj)) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  requestReport(reportType: string, requestBody: any) {
    this.reportType = reportType;
    if (this.doingReport === false) {
      this.doingReport = true;
      if (this.reportType === 'Select Type') {
        this.toastService.errorToast('Please select the report type');
        return;
      }
      this.verifyReportObjects(this.sortedObject);
      this.operators = [];
      this.advancedSearchCriteria.template_id = this.template.id;
      if (this.fromDate !== undefined && this.fromDate !== null) {
        this.advancedSearchCriteria['created_at.value'] = { $gte : this.fromDate + 'T00:00:00Z'};
      }
      if (this.toDate !== undefined && this.toDate !== null) {
        this.advancedSearchCriteria['created_at.value']  = { $lte : this.toDate + 'T00:00:00Z'};
      }
      if (this.aggregation.length > 0) {
        this.operators.push(this.aggregation[0]);
      } else {
        this.operators.push({ '$match': this.advancedSearchCriteria });
      }

      if (!this.isEmpty(this.sortedObject)) {
        this.operators.push({ '$sort': this.sortedObject });
      }

      if (!this.isEmpty(this.projectionObject)) {
        const oderObject = this.rebuildFields(this.projectionObject);

        const orderProject2 = this.createProject(this.projectionObject);

        this.operators.push({ '$project': oderObject});

        this.operators.push({ '$project': orderProject2});
      }

      requestBody.queries = [{
        collection: 'features',
        stages: this.operators
      }]
      this.generateNewReport(requestBody)
    }
  }


  valueExistObject(obj, value) {
    if (this.isEmpty(obj)) {
      return false;
    }

    const keys = Object.keys(obj);
    if (keys.length > 0) {
      for (const val of keys) {
        if (value === val) {
          return true;
        }
      }
    }
    return false;
  }

  verifyReportObjects(
    sortedObject: any
  ) {
    let sortingValue;
    let sortingValue2;
    let sortingType = 1;
    let sortingType2 = 1;

    if (this.advancedSearchCriteria !== null && Object.keys(this.advancedSearchCriteria).length > 0) {
      const keys = Object.keys(this.advancedSearchCriteria);
      if (keys[0].split('.').length > 1) {
        return;
      }

      if (keys.length === 1 && keys[0] === 'template_id') {
        return;
      }

      if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          const value = this.advancedSearchCriteria[keys[i]];
          if (typeof value === 'object') {
            const ObjKey = Object.keys(value);
            let valKey = ObjKey[0];
            valKey = '$' + valKey;

            value[valKey] = value[ObjKey[0]];
            delete value[ObjKey[0]];
          }

          key = 'attributes.' + key + '.value';
          this.advancedSearchCriteria[key] = value;
          delete this.advancedSearchCriteria[keys[i]];
        }
      }
      this.advancedSearchCriteria.template_id = this.template.id;
    } else {
      this.advancedSearchCriteria = {};
      this.advancedSearchCriteria.template_id = this.template.id;
    }

    if (sortedObject !== null && Object.keys(sortedObject).length > 0) {
      if (sortedObject.column !== '') {
        sortingValue = 'attributes.' + sortedObject.column + '.value';
        if (sortedObject.sortingType === 'descending') {
          sortingType = -1;
        }

        this.sortedObject[sortingValue] = sortingType;
        delete this.sortedObject.column;
        delete this.sortedObject.sortingType;
      } else {
        delete this.sortedObject.column;
      }

      if (sortedObject.column2 !== '') {
        sortingValue2 = 'attributes.' + sortedObject.column2 + '.value';
        if (sortedObject.sortingType2 === 'descending') {
          sortingType2 = -1;
        }

        this.sortedObject[sortingValue2] = sortingType2;
        delete this.sortedObject.column2;
        delete this.sortedObject.sortingType2;
      } else {
        delete this.sortedObject.column2;
      }
    }

    if (this.columnsValues === null || this.columnsValues.length === 0) {
      this.columnsValues = this.attHeaders;
    }

    if (this.columnsValues !== null) {
      if (this.columnsValues.length > 0) {
        this.projectionObject = {};
        const sameValue = [];
        for(let j = 0; j < this.columnsValues.length; j++) {
          let value = this.columnsValues[j];
          value = value.trim();
          if (value.split('[').length > 1) {
            value = value.split('[')[0];
          }
          let value1 = null;
          if (value.split('.').length > 1) {
            value1 = value.split('.')[0];
            sameValue.push(value1);
          } else {
            value1 = value;
          }
          let attr = 'attributes.' + value;
          if (attr === 'attributes.Geometry Type') {
            attr = 'geometry';
            value = 'geometry';
          }

          if (!this.valueExistObject(this.projectionObject, attr)) {
            const values = value.split('.');
            this.projectionObject[attr] = '$' + values[values.length - 1];
          }
        }
      } else {
        this.projectionObject = {};
      }
    }
  }

  generateNewReport(requestBody: any) {
    this.createNewReport(getToken(), requestBody).subscribe(
      _res => {
        this.doingReport = false;
        this.toastService.successToast('The request to create the report has been sent.')
      },
      err => {
        if(typeof err.error.error.message === 'string')
          this.toastService.errorToast(err.error.error.message);
        else if(typeof err.message === 'string')
          this.toastService.errorToast(err.message);
        else
          this.toastService.errorToast("The request you sent was not correct, please try again");
        this.doingReport = false;
      }
    )
  }

  rebuildFields(projectObject: any): any {
    const newObject = {};
    if (!this.isEmpty(projectObject)) {
      newObject['_id'] = 0;
      const keys = Object.keys(projectObject);
      for (let i = 0; i < keys.length; i++){
        if (keys[i] === 'geometry') {
          newObject['geometry'] = '$' + keys[i];
        } else {
          const key = this.splitKeyValue(keys[i]);
          const vals = key.split(".");
          if(vals.length > 1){
            newObject[vals[0] + "." + "_class"] = '$' + this.concatenateClass(keys[i]);
          }
          newObject[key] = '$' + keys[i];
        }
      }
    }

    return newObject;
  }

  createProject(projectObject: any): any {
    const obj = {};
    if (!this.isEmpty(projectObject)) {
      const keys = Object.keys(projectObject);
      for (let i = 0; i < keys.length; i++){
        if (keys[i] === 'geometry') {
          obj['geometry'] = '$' + keys[i];
        } else {
          const val = this.splitKeyValue(keys[i]);
          const vals = val.split('.');
          if(vals.length > 1){
            obj['attributes.' + vals[0] + '._class'] = '$' + vals[0] + '._class';
          }
          const key = 'attributes.' + val;
          obj[key] = '$' + val;
        }
      }
    }

    return obj;
  }

  splitKeyValue(value: string): string {
    const elements = value.split('.');
    let key = "";

    if(elements[elements.length - 2] == 'attributes'){
      key = elements[elements.length - 1];
    } else {
      key = elements[elements.length - 2] + "." + elements[elements.length - 1];
    }

    return key;
  }

  concatenateClass(value: string): string {
    const parts = value.split('.');
    if (parts.length > 1) {
      parts[parts.length - 1] = '_class';
    }
    return parts.join('.');
  }

  aggregationSearch(aggregate: any[]): Observable<any> {
    if (aggregate.length <= 0) {
      return;
    }

    const token = getToken();
    let crs = getChoosenCRSType();
    if (!crs) {
      crs = 'Geodetic';
    }

    const body = JSON.stringify({
      format: ['json'],
      crs,
      queries: [{
        collection: 'features',
        stages: aggregate
      }]
    });
    this.featureService.aggregateSearch(token, body).subscribe(
      res => {
        removeSearchValue();
        removeCurrentFeaturesUri();
        this.updateDataFormServer(res);

      },
      err => {
        this.toastService.errorToast(err)
      }
    );
  }



  activePage: number;
  featuresSelected: any[];
  allPages: string[];
  featureSchema: FeatureSchema;
  schemaHeader: any[];
  fields_and_name_type: object;
  featureData: any[] = [];
  resultsLength = 0;
  displayedColumns: string[] = [];
  initValue = 0;
  prevDisabled: boolean;
  nextDisabled: boolean;
  nextLink: string;
  prevLink: string;
  pageFeatures: number;
  totalFeatures: number;
  selectedPage: string;
  dataSource = new MatTableDataSource<any>([]);

  saveTemplateFieldsNameAndType(template) {
    const feature = template.feature;
    const attributes = feature.attributes;
    const parent = '';
    let fieldsAndType = {};

    for (const attribute of attributes) {
      if (attribute._class === 'attributeset' ) {
        const temparent = (parent)
          ? `${parent}.${attribute.name}`
          : attribute.name;
        fieldsAndType = Object.assign(fieldsAndType, this.saveAttributesetNamesAndType(attribute, temparent));
      } else if (attribute._class === 'arrayattributeset') {
        const name = (parent)
          ? `${parent}.${attribute.name}`
          : attribute.name;
        fieldsAndType[name] = 'arrayattributeset';
      } else {
        const attname = (parent)
          ? `${parent}.${attribute.name}`
          : attribute.name;
        fieldsAndType[attname] = attribute['type'];
      }
    }
    this.schemaHeader = Object.keys(fieldsAndType);
    this.fields_and_name_type = fieldsAndType;
    saveTemplateFieldsNameAndTypes(fieldsAndType);
  }

  saveAttributesetNamesAndType(attributeset, parent) {
    let obj = {};
    let attsetName = attributeset['name'];
    if (attsetName === null || attsetName === undefined || attsetName === '') {
      attsetName = '';
    }
    const parentname = (parent !== null && parent !== undefined && parent !== '')
      ? `${parent}.${attsetName}`
      : attsetName;

    for (const attribute of attributeset.attributes) {

      if (attribute._class === 'attribute') {
        const attname = `${parent}.${attribute.name}`;
        obj[attname] = attribute['type'];
      } else if (attribute._class === 'arrayattributeset') {
        const name = `${parent}.${attribute.name}`;
        obj[name] = 'arrayattributeset';
      } else {
        obj = Object.assign(obj, this.saveAttributesetNamesAndType(attribute, parentname));
      }
    }
    return obj;
  }

  createHeaderWithinAttributeSet(attributeset, parent): string[] {
    let obj = [];
    for (const attribute of attributeset.attributes) {
      if (attribute._class === 'attribute') {
        const attname = `${parent}.${attribute.name}`;
        obj.push(attname);
      } else if (attribute._class === 'arrayattributeset') {
        const name = `${parent}.${attribute.name}`;
        obj.push(name);
      } else {
        const name = `${parent}.${attribute.name}`;
        obj = obj.concat(this.createHeaderWithinAttributeSet(attribute, name));
      }
    }
    return obj;
  }

  createHeaderArrayWithinTemplate(template: Template): string[] {
    let result = [];
    const schema = template?.feature;
    if(schema) {
      const attributes = schema.attributes;
      for (const attribute of attributes) {
        const attname = attribute['name'];
        if (attribute['_class'] === 'attribute') {
          result.push(attname);
        } else if (attribute['_class'] === 'arrayattributeset') {
          result.push(attname);
        } else {
          result = result.concat(this.createHeaderWithinAttributeSet(attribute, attname));
        }
      }
      return result;
    }
    return null;
  }

  findDuplicates(headers: any[]): any[] {
    const duplicates: any[] = [];
    headers.map((value, index) => {
      if (headers.indexOf(value, index + 1) > -1 && duplicates.indexOf(value) === -1) {
        duplicates.push(value);
      }
    });
    return duplicates;
  }

  createFeatureData(): any[] {
    const data = [];
    this.instances.map(inst => {
      let dataItem = this.createDataArray(inst['attributes'], '');
      dataItem['id'] = inst['id'];
      if (inst['geometry'] !== null && inst['geometry'] !== undefined) {
        const geomDataItem = this.createGeometryDataArray(inst['geometry']);
        if (geomDataItem !== null && geomDataItem !== undefined) {
          dataItem = Object.assign(geomDataItem, dataItem);
        }
      }
      data.push(dataItem);
    });
    return data;
  }

  createGeometryDataArray(geometry: any): any {
    return {
      Geometry_Type: geometry['type'],
      coordinates: geometry['coordinates']
    };
  }

  createDataArray(attributeset: any, parent = ''): any {
    let result = {};
    const keys = Object.keys(attributeset);
    for (const key of keys) {
      const absAttribute = attributeset[key];
      const path = parent === '' ? key : `${parent}.${key}`;
      const itemIndex = this.attHeaders.findIndex(header => header === path, 0);
      if (absAttribute instanceof Object) {
        if (absAttribute instanceof Array) {
          if(itemIndex >= 0) {
            result[this.tableHeaders[itemIndex]] = absAttribute;
          }
        } else {
          const tempResult = this.createDataArray(attributeset[key], path);
          result = Object.assign(result, tempResult);
        }
      } else {
        if(itemIndex >= 0) {
          result[this.tableHeaders[itemIndex]] = attributeset[key];
        }

      }
    }

    return result;
  }

  updateDataFormServer(features) {
    this.featuresSelected = [];
    this.allPages = [];
    const template = this.dataService.template.getValue();
    if (template !== null && template !== undefined) {
      this.template = template;
      this.featureSchema = template.feature;
      this.saveTemplateFieldsNameAndType(template);

    }
    const searchObject = this.lastExportedTemplate;
    if (searchObject === null
      || searchObject === undefined
      || searchObject['template'] !== template["id"]) {
      this.columnsValues = [];
    }

    this.metadata = features.metadata;
    this.featureData = [];
    this.resultsLength = features.length;

    this.instances = features.instances;

    const headers = this.createHeaderArrayWithinTemplate(template);
    this.tableHeaders = this.createTableHeaderArrayWithinTemplate(template);
    const duplicateHeaders = this.findDuplicates(this.tableHeaders);
    if(duplicateHeaders && duplicateHeaders.length > 0) {

      const headersAsString = duplicateHeaders.join(', ');
      this.toastService.errorToast(`The form contains duplicate Label [${headersAsString}]`);
      setTimeout(() => {
      }, 1000)

      return;
    }

    this.attHeaders = headers;
    if (this.instances.length > 0) {
      const nextUri = features.metadata.uris.next;
      const prevUri = features.metadata.uris.prev;

      this.pages = features.metadata.pages;
      this.activePage = features.metadata.page;

      if (this.pages > 0) {
        for (let v = 1; v <= this.pages; v++) {
          this.allPages.push(v + ' ');
        }
      }

      const data = this.createFeatureData();

      let listGeometryHeader = null;
      if (this.instances[0]['geometry'] !== null && this.instances[0]['geometry'] !== undefined) {
        listGeometryHeader = Object.keys(this.instances[0]['geometry']);
      }
      if (this.columnsValues.length > 0) {
        this.displayedColumns = [];
        this.displayedColumns.push('_select');
        const index = this.columnsValues.indexOf('Geometry Type');
        if (listGeometryHeader !== null && listGeometryHeader !== undefined && index !== -1) {
          this.displayedColumns.push('Geometry_Type');
        }
        for (const b of this.columnsValues) {
          const val = this.tableHeaders[this.attHeaders.indexOf(b)];
          if (val !== 'Geometry Type') {
            this.displayedColumns.push(val);
          }
        }
      } else if (this.tableHeaders !== null && this.tableHeaders !== undefined && this.tableHeaders.length > 0) {
        this.displayedColumns = [];
        this.displayedColumns.push('_select');
        if (listGeometryHeader !== null && listGeometryHeader !== undefined) {
          this.displayedColumns.push('Geometry_Type');
        }
        for (const h of this.tableHeaders) {
          this.displayedColumns.push(h);
        }
      }

      const value = (features.metadata.page - 1) * this.nbrPage;
      if (features.metadata.page === 1 && features.instances.length > 0) {
        this.initValue = 1;
      } else if (features.instances.length === 0) {
        this.initValue = 0;
      } else {
        this.initValue = value + 1;
      }

      if ((nextUri === null || nextUri === undefined || nextUri === '')) {
        this.nextDisabled = true;
      } else {
        this.nextDisabled = false;
        this.nextLink = nextUri;
      }

      if (prevUri === null || prevUri === undefined || prevUri === '') {
        this.prevDisabled = true;
      } else {
        this.prevDisabled = false;
        this.prevLink = prevUri;
      }
      this.pageFeatures = features.metadata.page_count + value;
      this.totalFeatures = features.metadata.total_count;

      const $items = $('.bleuPage');
      const classHighlight = 'selectedPage';
      $items.removeClass(classHighlight);
      this.selectedPage = this.activePage + ' ';
      this.dataSource.data = data;
      // this.featuretable.renderRows();
    } else {
      this.initValue = 0;
      this.pageFeatures = 0;
      this.totalFeatures = 0;

      this.displayedColumns = [];
      this.dataSource.data = [];
      // this.featuretable.renderRows();
    }
  }


  displayReportTemplatePlaceholders(reportTemplate) {
    let elements = '';
    if (reportTemplate === '') {
      return;
    }

    const placeHolders = reportTemplate.place_holders;

    for (let i = 0; i < placeHolders.length; i++) {
      const placeHolder = placeHolders[i];
      const placeHolderName = Object.keys(placeHolder)[0];
      const placeHolderValue = (placeHolder[placeHolderName] != null) ? placeHolder[placeHolderName] : '';

      elements += '<tr>' +
        '<td style="width: 27%; padding-right: 0; text-align: right">' +
        '<label style="margin-top: 42px; text-align: right; padding-right: 15px">' + placeHolderName + '</label>' +
        '</td>' +

        '<td>' +
        '<paper-input ' +
        'id="" ' +
        'class="placehoderData" ' +
        'key= "' +  placeHolderName + '" ' +
        'value= "' +  placeHolderValue + '" ' +
        'error-message$="" ' +
        'bind-value="" ' +
        'label="" always-float-label class="text-left" autofocus ' +
        'style="text-align: left; margin-left: 9px; margin-right: 26px;" >' +
        '</paper-input>' +
        '</td>' +
        '</tr>';

    }
  }

  convertFileToBase64(file: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result;
          if (typeof dataUrl === 'string') {
            const base64String = dataUrl.split(',')[1];
            resolve(base64String);
          } else {
            reject('Error reading file');
          }
        };
        reader.onerror = () => {
          reject('Error reading file');
        };
        reader.readAsDataURL(file);
      } else {
        reject('No file provided');
      }
    });
  }

}
