import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, ViewChild } from '@angular/core';
import { FieldSet } from 'src/app/share/form/fieldset';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateIntegerFieldComponent } from '../../dialogs/create-integer-field/create-integer-field.component';
import { CreateStringFieldComponent } from '../../dialogs/create-string-field/create-string-field.component';
import { CreateDecimalFieldComponent } from '../../dialogs/create-decimal-field/create-decimal-field.component';
import { CreateAutoincrementFieldComponent } from '../../dialogs/create-autoincrement-field/create-autoincrement-field.component';
import { CreateNoteFieldComponent } from '../../dialogs/create-note-field/create-note-field.component';
import { CreateBarcodeFieldComponent } from '../../dialogs/create-barcode-field/create-barcode-field.component';
import { CreateGpsCoordinatesFieldComponent } from '../../dialogs/create-gps-coordinates-field/create-gps-coordinates-field.component';
import { CreatePhotoFieldComponent } from '../../dialogs/create-photo-field/create-photo-field.component';
import { CreateVideoFieldComponent } from '../../dialogs/create-video-field/create-video-field.component';
import { CreateFieldsetComponent } from '../../dialogs/create-fieldset/create-fieldset.component';
import { CreateFieldsetArrayComponent } from '../../dialogs/create-fieldset-array/create-fieldset-array.component';
import { CreateAudioFieldComponent } from '../../dialogs/create-audio-field/create-audio-field.component';
import { CreateTimeFieldComponent } from '../../dialogs/create-time-field/create-time-field.component';
import { CreateBooleanFieldComponent } from '../../dialogs/create-boolean-field/create-boolean-field.component';
import { CreateDateFieldComponent } from '../../dialogs/create-date-field/create-date-field.component';
import { Template } from 'src/app/share/template';
import { DataService } from 'src/app/services/data.service';
import { Field } from 'src/app/share/form/field';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { StoreService } from 'src/app/services/store.service';
import  $ from 'jquery';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {CdkDrag, CdkDropList} from '@angular/cdk/drag-drop';
import {RootContainerComponent} from '../root-container/root-container.component';
import { MatTooltipModule} from '@angular/material/tooltip';
import {Item} from '../../../share/form/item';
import {
  faArrowUp91,
  faBarcode,
  faCalendar, faCheck, faClock, faDivide, faFileAudio, faFileWaveform,
  faFont,
  faImage, faLocationDot, faMapMarker, faObjectGroup, faPercent, faPhotoFilm, faSquareCheck,
  faStickyNote, faTableList, faThLarge, faToggleOff,
  faToggleOn,
  faVideo, faZ
} from '@fortawesome/free-solid-svg-icons';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {ToastService} from '../../../services/toast.service';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    MessageBoxComponent,
    MatDividerModule,
    MatIcon,
    CommonModule,
    MatButtonModule,
    CdkDropList,
    CdkDrag,
    RootContainerComponent,
    MatTooltipModule,
    FaIconComponent,
  ],
  selector: 'app-template-schema',
  templateUrl: './template-schema.component.html',
  styleUrls: ['./template-schema.component.scss']
})
export class TemplateSchemaComponent implements OnInit, OnChanges {
  @Output() templateChanged = new EventEmitter<Template>();
  fields: Field[];
  rootFieldset: FieldSet;
  @Input() activeTemplate: Template;
  activeFieldset: FieldSet;
  activeFieldIndex: number;
  selectedParent: string;
  root= { uId: '-1', name: 'root', children: [], isContainer: true, _class: 'fieldset' } as Item;

  private messageBox: MessageBoxComponent;


  //Icons
  protected readonly faFont = faFont;
  protected readonly faBarcode = faBarcode;
  protected readonly faVideo = faVideo;
  protected readonly faCalendar = faCalendar;
  protected readonly faClock = faClock;
  protected readonly faObjectGroup = faObjectGroup;
  protected readonly faStickyNote = faStickyNote;
  protected readonly faZ = faZ;
  protected readonly faArrowUp91 = faArrowUp91;


  @ViewChild('messageBox') set content(content: MessageBoxComponent) {
    if (content) {
      this.messageBox = content;
    }
  }

  message = '';
  showMessage = false;

  constructor(
    public store: StoreService,
    private titlecasePipe:TitleCasePipe,
    private dataService: DataService,
    private dialog: MatDialog,
    private translate: TranslateService,
    public toastService: ToastService,
  ) {
  }


  ngOnInit() {
    this.activeTemplate = this.dataService.template.getValue();
    this.initializeTemplateAndField(this.activeTemplate);
    this.dataService.template.subscribe(templ => {
        this.initializeTemplateAndField(templ);
    });
  }

  initializeTemplateAndField(templ) {
    this.activeTemplate = templ;
    this.rootFieldset = new FieldSet();
    this.selectedParent = 'rootFieldset';
    if (this.activeTemplate === null) {
      return;
    }
    if (this.activeTemplate.form !== undefined && this.activeTemplate.form !== null) {
      this.fields = this.activeTemplate.form.fields;
      this.activeFieldset = new FieldSet();
      this.activeFieldset.fields = this.activeTemplate.form.fields;
    } else {
      this.fields = [];
      this.activeFieldset = new FieldSet();
      this.activeFieldset.fields = this.fields;
    }
    this.rootFieldset.fields = this.fields;
    this.root = this.store.transformToItems(this.rootFieldset)
    this.activeFieldset = this.rootFieldset;
    this.store.currentFieldSet = this.store.transformToItems(this.activeFieldset);
  }

  updateTemplate() {
    this.dataService.buildFeatureSchema();
  }

  handleRootFieldset(event) {
    event.preventDefault();
    this.removeSelectedFieldset();
    this.store.selectedItemId = "";
    this.selectedParent = 'rootFieldset';
    this.activeFieldset = this.rootFieldset;
    this.store.selectedFieldSet.next('rootFieldset');
    this.store.currentFieldSet = this.store.transformToItems(this.activeFieldset);
    event.stopPropagation();
  }

  removeSelectedFieldset() {
    const items = $('highlightParent');
    const classHighlight = 'highlightParent';
    items.removeClass(classHighlight);
  }

  setCreateDialogConfig(): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const parent = this.store.currentFieldSet;

    dialogConfig.data = {
      mode: {
        text: this.translate.instant("CREATE"),
        function: 'Create'
      },
      parent: this.store.transformToFields(parent),
    };
    return dialogConfig;
  }

  openIntegerDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`)
      return;
    }
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateIntegerFieldComponent, dialogConfig);
    dialogRef.afterClosed().subscribe( result => {
      if (result !== null) {
        this.addOrUpdateField(result);
      }
    });
    // this.addResultOnDialogClose(dialogRef);
  }

  openStringDialog() {
    // this.formFieldset.openStringDialog();
    // Get the actual fieldset
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`)
      return;
    }
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateStringFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        this.addOrUpdateField(result);
      }
    });
  }

  addOrUpdateField(result) {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`)
      return;
    }

    if (result.action === 'Create') {
      //Old way of tracking current fieldset's newly added children. Commented because it makes a visual duplicate when adding a new field.
      //this.store.currentFieldSet.children.push(result.field);

      //Drag and Drop ADD
      const selectedItem = this.store.findItemById(this.root, this.store.selectedItemId)

      if (selectedItem) {
        selectedItem.children.push(this.store.transformToItems(result.field))
        this.store.changedRoot.children = this.root.children;
      }
      else {
        this.root.children.push(this.store.transformToItems(result.field))
        this.store.changedRoot.children = this.root.children;
      }
    } else  if (result.action === 'Update') {
      this.store.currentFieldSet.children.splice(this.activeFieldIndex, 1, result.field);
      this.store.changedRoot.children = this.root.children;

    }

    this.store.changeFormUpdatingState(true);
  }

  openDecimalDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`)
      return;
    }
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateDecimalFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openAutoIntegerDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`)
      return;
    }
    if (this.store.currentFieldSet['name']) {
      this.toastService.errorToast('An auto increment field is not allowed in a fieldset or fieldset array.');
      return;
    }
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateAutoincrementFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openNoteDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`);
      return;
    }
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateNoteFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openBarCodeDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`)
      return;
    }
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateBarcodeFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openGPSCoordinatesDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`);
      return;
    }
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateGpsCoordinatesFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result) {
        this.addOrUpdateField(result);
      }
    });
  }

  openPhotoDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`);
      return;
    }
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreatePhotoFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openVideoDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`);
      return;
    }
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateVideoFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openFieldsetDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`);
      return;
    }
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateFieldsetComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openFieldSetArrayDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`);
      return;
    }
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateFieldsetArrayComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== null) {
        this.addOrUpdateField(result);
      }
    });
  }

  openAudioFieldDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`);
      return;
    }
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateAudioFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openTimeFieldDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`);
      return;
    }
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateTimeFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openDateFieldDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`);
      return;
    }
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateDateFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  openBooleanFieldDialog() {
    if(this.store.archived) {
      const projectClass = this.titlecasePipe.transform(this.store.proClass);
      this.toastService.errorToast(`This operation is only available for active ${projectClass}`);
      return;
    }
    const dialogConfig = this.setCreateDialogConfig();
    const dialogRef = this.dialog.open(CreateBooleanFieldComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result  => {
      if (result !== null ) {
        this.addOrUpdateField(result);
      }
    });
  }

  // Implement the ngOnChanges for handling the template changes
  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    const mat = {};
    for (const prop in changes) {
      const t = changes[prop];
      if (!t.isFirstChange()) {
        this.initializeTemplateAndField(t);
      }
    }
  }

  protected readonly faPercent = faPercent;
  protected readonly faToggleOff = faToggleOff;
  protected readonly faPhotoFilm = faPhotoFilm;
  protected readonly faFileWaveform = faFileWaveform;
  protected readonly faTableList = faTableList;
  protected readonly faLocationDot = faLocationDot;
  protected readonly faSquareCheck = faSquareCheck;
}
